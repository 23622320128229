/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCallOut,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import { useProfile } from '@modules/profile-lib/hooks'
import { useSessionStorageBackedState } from '@modules/utils/hooks/useLocalStorage'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { useConfig } from '@modules/cui/ConfigContext'

import { getInstancesWithOverride } from '@/lib/stackDeployments/clusterInstances'
import {
  isAnyAutoscalingEnabledOnGet,
  isAutoscalingAvailable,
} from '@/lib/stackDeployments/selectors/autoscaling'
import { getDeploymentTopologyInstances } from '@/lib/stackDeployments/selectors/instances'
import { getCloudId } from '@/lib/stackDeployments/selectors/fundamentals'
import { isSliderInstanceTypeSupportedInTemplate } from '@/lib/sliders/support'

import DeploymentAlias from '../DeploymentAlias'
import DeploymentTags from '../DeploymentTags/DeploymentTags'
import DeploymentVersion from '../DeploymentVersion'
import CcsEditRemoteDeployments from '../CcsEditRemoteDeployments'
import { AppSearchDeploymentPageNotice } from '../../DeprecationNotices/AppsearchNotices'
import StackDeploymentName from '../../StackDeployments/StackDeploymentName'
import StackDeploymentNodesVisualization from '../../StackDeployments/StackDeploymentNodesVisualization'
import ResourceComments from '../../ResourceComments'
import AutoscalingStatusCallout from '../../Autoscaling/AutoscalingStatusCallout'
import IlmDeploymentMigrationCallout from '../../IlmMigration/IlmDeploymentMigrationCallout'
import FleetAvailableCallout from '../../Fleet/FleetAvailableCallout'
import InstanceOverrideCallout from '../InstanceOverrideCallout'
import AppSearchToEnterpriseSearchMigrationFlyout from '../../AppSearchToEnterpriseSearchMigration/AppSearchToEnterpriseSearchMigrationFlyout'
import { isCrossClusterSearch } from '../../../lib/deployments/ccs'
import CCSMigrationNotification from '../CCSMigrationNotification'
import ApplicationLinks from '../../StackDeployments/StackDeploymentApplicationLinks'
import DeploymentTemplate from '../../StackDeployments/DeploymentTemplate'
import DedicatedMastersUnnecessary from '../DedicatedMastersUnnecessary'
import SSOPortOverrideNotification from '../../StackDeploymentSlider/OverviewEnabled/SSOPortOverrideNotification'

import CloudId from './CloudId'

import type { FC, FunctionComponent } from 'react'
import type { Props } from './types'

import './overview.scss'

const DeploymentOverview: FunctionComponent<Props> = ({
  deployment,
  deploymentTemplate,
  showNativeMemoryPressure,
  version,
  stackDeployment,
  appSearchToEnterpriseSearchProgress,
}) => {
  const { regionId, plan, deploymentTemplateId, isSystemOwned } = deployment

  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const isTrialUser = useProfile({ enabled: !isAdminConsole })?.is_trial

  const billingDetails = useBillingDetails()

  const isCloudMonthlyBillingUser = billingDetails.data?.billing_model === `monthly`

  const shouldShowCustomerEngineerCallout = isTrialUser || isCloudMonthlyBillingUser

  if (!stackDeployment) {
    return (
      <EuiFlexGroup justifyContent='center' alignItems='center'>
        <EuiLoadingSpinner size='xl' />
      </EuiFlexGroup>
    )
  }

  // tally running ES, Kibana, and APM instances
  const instances = getDeploymentTopologyInstances({ deployment: stackDeployment })
  const totalInstances = instances.length

  const instancesWithOverride = deploymentTemplate
    ? getInstancesWithOverride({
        instanceSummaries: instances,
        deploymentTemplate,
      })
    : []

  const pendingEs = plan.isPending || plan.waitingForPending

  const ccs = isCrossClusterSearch({
    deploymentTemplate,
    deploymentTemplateId,
    systemOwned: isSystemOwned,
  })

  const cloudId = getCloudId({ deployment: stackDeployment })

  const supportsAppsearch = isSliderInstanceTypeSupportedInTemplate(`appsearch`, deploymentTemplate)

  const deploymentOverviewKey = `deploymentOverview-${regionId}-${deployment.id}`

  const displayAutoscalingStatus =
    isAutoscalingAvailable(version) && isAnyAutoscalingEnabledOnGet({ deployment: stackDeployment })

  return (
    <div key={deploymentOverviewKey}>
      <DedicatedMastersUnnecessary
        stackDeployment={stackDeployment}
        deploymentTemplate={deploymentTemplate}
      />
      <SSOPortOverrideNotification isDismissable={true} deployment={stackDeployment} />
      <IlmDeploymentMigrationCallout stackDeployment={stackDeployment} />
      <CCSMigrationNotification stackDeployment={stackDeployment} />
      <InstanceOverrideCallout instancesWithOverride={instancesWithOverride} />

      {supportsAppsearch && (
        <Fragment>
          <AppSearchDeploymentPageNotice deployment={stackDeployment} />
          <EuiSpacer size='m' />
        </Fragment>
      )}

      {shouldShowCustomerEngineerCallout && (
        <Fragment>
          <CustomerEngineerCallout />
          <EuiSpacer />
        </Fragment>
      )}

      {appSearchToEnterpriseSearchProgress?.showUI && (
        <AppSearchToEnterpriseSearchMigrationFlyout deployment={stackDeployment} />
      )}

      <FleetAvailableCallout deployment={stackDeployment} deploymentTemplate={deploymentTemplate} />

      {displayAutoscalingStatus && <AutoscalingStatusCallout stackDeployment={stackDeployment} />}

      <div>
        {pendingEs || (
          <EuiFlexGrid
            columns={3}
            className='overview-grid'
            data-test-id='deployment-overview-es-not-pending'
          >
            <EuiFlexItem>
              <StackDeploymentName deployment={stackDeployment} />
            </EuiFlexItem>

            <EuiFlexItem>
              <DeploymentAlias deployment={stackDeployment} />
            </EuiFlexItem>

            <EuiFlexItem>
              {!deployment.isStopped && version && (
                <Fragment>
                  <EuiSpacer size='xs' />
                  <DeploymentVersion deployment={stackDeployment} />
                </Fragment>
              )}
            </EuiFlexItem>
            <EuiFlexItem className='deploymentOverview-Applications'>
              <ApplicationLinks showTitle={true} deployment={stackDeployment} />
            </EuiFlexItem>
            <EuiFlexItem>
              <DeploymentTemplate deployment={stackDeployment} />
            </EuiFlexItem>
            {cloudId && (
              <EuiFlexItem>
                <CloudId cloudId={cloudId} />
              </EuiFlexItem>
            )}
            <EuiFlexItem>
              <DeploymentTags deployment={stackDeployment} />
            </EuiFlexItem>

            <EuiFlexItem>
              {/* eventually, we'll have _deployment level_ comments here,
               * and the ES ones will be relegated to just the ES cluster
               */}
              <ResourceComments
                spacerBefore={true}
                resourceType='elasticsearch'
                regionId={regionId}
                resourceId={deployment.id}
              />
            </EuiFlexItem>
          </EuiFlexGrid>
        )}
      </div>

      {ccs && (
        <Fragment>
          <EuiSpacer size='m' />
          <CcsEditRemoteDeployments regionId={regionId} deployment={stackDeployment} />
        </Fragment>
      )}

      <EuiSpacer size='xl' />

      {totalInstances === 0 || (
        <StackDeploymentNodesVisualization
          title={
            <EuiTitle size='s'>
              <h3 data-test-id='deploymentOverview-zonesAndNodes'>
                <FormattedMessage id='deployment-info.instances' defaultMessage='Instances' />
              </h3>
            </EuiTitle>
          }
          deployment={stackDeployment}
          showNativeMemoryPressure={showNativeMemoryPressure}
        />
      )}
    </div>
  )
}

const CustomerEngineerCallout: FC = () => {
  const [isCalloutVisible, setCalloutVisible] = useSessionStorageBackedState(
    `customerEngineerCalloutVisible`,
    true,
  )

  if (!isCalloutVisible) {
    return null
  }

  return (
    <EuiCallOut
      title={
        <FormattedMessage
          id='deployment-overview.customer-engineer-callout-title'
          defaultMessage='Need advice? Engage a Customer Engineer.'
        />
      }
      iconType='iInCircle'
      onDismiss={() => {
        setCalloutVisible(false)
      }}
    >
      <p>
        <FormattedMessage
          id='deployment-overview.customer-engineer-callout-description'
          defaultMessage='Use our <link>new online form</link> to get help with best practices, performance, and/or cost efficiency.'
          values={{
            link: (content) => <a href='https://www.elastic.co/contact/ce-help'>{content}</a>,
          }}
        />
      </p>
    </EuiCallOut>
  )
}

export default DeploymentOverview
