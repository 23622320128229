/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  updateEsMetadata,
  resetSetDeploymentResourceMetadata,
} from '@/actions/stackDeployments/metadata'
import { getFirstEsRefId } from '@/lib/stackDeployments/selectors/clusters'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getSnapshotRepositories } from '@/reducers/snapshotRepositories'
import {
  setDeploymentResourceMetadataRequest,
  fetchSnapshotRepositoriesRequest,
} from '@/reducers/asyncRequests/registry'

import { fetchSnapshotRepositories } from '../../../../actions/snapshotRepositories'

import UpdateSnapshotRepository from './UpdateSnapshotRepository'

import type { StateProps, DispatchProps, ConsumerProps } from './UpdateSnapshotRepository'

const mapStateToProps = (state, { deployment }: ConsumerProps): StateProps => {
  const esRefId = getFirstEsRefId({ deployment })
  const region = getRegionId({ deployment })

  return {
    snapshotRepositories: getSnapshotRepositories(state, region),
    fetchSnapshotRepositoriesRequest: fetchSnapshotRepositoriesRequest(state, region),
    setDeploymentResourceMetadataRequest: setDeploymentResourceMetadataRequest(
      state,
      deployment.id,
      `elasticsearch`,
      esRefId,
    ),
  }
}

const mapDispatchToProps = (dispatch, { deployment }: ConsumerProps): DispatchProps => {
  const esRefId = getFirstEsRefId({ deployment })
  return {
    fetchSnapshotRepositories: (regionId: string) => dispatch(fetchSnapshotRepositories(regionId)),
    resetSetDeploymentResourceMetadata: () =>
      dispatch(resetSetDeploymentResourceMetadata(deployment.id, `elasticsearch`, esRefId!)),
    updateMetadata: (args) => dispatch(updateEsMetadata(args)),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateSnapshotRepository)
