/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiSpacer, EuiText } from '@elastic/eui'

import {
  roleMappingKey,
  useGetRoleMappingsQuery,
  useUpdateRoleMappings,
} from '@modules/security-idp-lib/hooks'
import type { RoleMapping } from '@modules/cloud-api/v1/types'
import { addToast } from '@modules/cui/Toasts'
import { queryClient } from '@modules/query'

import OrganizationNoRolesEmptyPrompt from '@/components/Organization/OrganizationNoRolesEmptyPrompt'
import { organizationRoleMappingUrl, organizationSecurityUrl } from '@/apps/userconsole/urls'

import MappingRules from './MappingRules'
import RoleMappingDetails from './RoleMappingDetails'
import { messages } from './messages'
import { validateForm } from './lib'

import type { Props } from '../types'

const RoleMappingBody = (props: Props) => {
  const {
    permissions: { hasUpdateRoleMapping, hasViewRoleMapping },
    originalRoleMapping,
    organizationId,
  } = props

  const { formatMessage } = useIntl()
  const history = useHistory()
  const { data: roleMappingsResponse, isLoading: isLoadingRoleMappings } =
    useGetRoleMappingsQuery(organizationId)
  const { mutate: updateRoleMappings, isLoading: isLoadingUpdateRoleMappings } =
    useUpdateRoleMappings()

  if (!hasViewRoleMapping) {
    return <OrganizationNoRolesEmptyPrompt />
  }

  const onSubmit = (updatedRoleMapping: RoleMapping) => {
    const newRoleMappings =
      roleMappingsResponse?.mappings.filter(
        (mapping) => mapping.name !== originalRoleMapping?.name,
      ) || []

    newRoleMappings.push(updatedRoleMapping)

    updateRoleMappings(
      {
        organizationId,
        mappings: newRoleMappings,
      },
      {
        onSuccess: () => {
          addToast({
            family: 'organization.security.edit-role-mapping',
            color: 'success',
            title: formatMessage(
              originalRoleMapping ? messages.editRoleSuccessfully : messages.createRoleSuccessfully,
            ),
          })
          queryClient.setQueryData(roleMappingKey(organizationId), { mappings: newRoleMappings })
          history.push(`${organizationRoleMappingUrl()}/${updatedRoleMapping.name}`)
        },
        onError: () => {
          addToast({
            family: 'organization.security.edit-role-mapping',
            color: 'danger',
            title: formatMessage(messages.errorSavingRoleMapping),
          })
        },
      },
    )
  }

  const onCancel = () => {
    history.push(organizationSecurityUrl())
  }

  const isLoading = isLoadingRoleMappings || isLoadingUpdateRoleMappings

  const initialFormValues = originalRoleMapping ?? {
    name: '',
    enabled: true,
    rule: {
      all: [],
      any: [],
    },
    role_assignments: {},
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validate={validateForm({
        formatMessage,
        originalRoleMapping,
        roleMappings: roleMappingsResponse?.mappings,
      })}
    >
      {({ dirty }) => (
        <Form>
          <RoleMappingDetails organizationId={organizationId} roleMapping={originalRoleMapping} />
          <EuiSpacer size='l' />
          <MappingRules />
          <EuiSpacer size='l' />
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiButtonEmpty onClick={onCancel}>
              <EuiText>{formatMessage(messages.cancelButton)}</EuiText>
            </EuiButtonEmpty>
            <EuiButton
              fill={true}
              type='submit'
              disabled={!dirty || !hasUpdateRoleMapping}
              isLoading={isLoading}
            >
              <EuiText>{formatMessage(messages.saveButton)}</EuiText>
            </EuiButton>
          </EuiFlexGroup>
        </Form>
      )}
    </Formik>
  )
}

export default RoleMappingBody
