/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiTableHeaderCell, EuiText } from '@elastic/eui'

import type { Price } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui/Table'
import type { CuiTableColumn } from '@modules/cui/Table'

import type { ReactElement } from 'react'
import type { Props } from './types'

class PriceList extends PureComponent<Props> {
  render(): ReactElement {
    const { prices, isLoading } = this.props
    const columns = this.getGridColumns()

    return (
      <CuiTable
        getRowId={(item) => `${item.instance_type}_${item.valid_from}`}
        rows={this.getGridRows()}
        columns={columns.items}
        initialSort={columns.initialSort}
        initialSortDirection='desc'
        initialLoading={!prices || isLoading}
        data-test-id='stack-hosted-price-list'
      />
    )
  }

  renderPriceListGrid(): ReactElement {
    const { prices, isLoading } = this.props
    const columns = this.getGridColumns()

    return (
      <CuiTable
        getRowId={(item) => `${item.instance_type}_${item.valid_from}`}
        rows={this.getGridRows()}
        columns={columns.items}
        initialSort={columns.initialSort}
        initialSortDirection='desc'
        initialLoading={!prices || isLoading}
      />
    )
  }

  getGridColumns(): { initialSort: CuiTableColumn<Price>; items: Array<CuiTableColumn<Price>> } {
    const productColumn = {
      label: (
        <FormattedMessage id='stack-deployment-pricing-table.product' defaultMessage='Product' />
      ),
      render: (item: Price) => item.instance_type,
    }
    const standardLabel = (
      <FormattedMessage id='stack-deployment-pricing-table.standard' defaultMessage='Standard' />
    )
    const goldLabel = (
      <FormattedMessage id='stack-deployment-pricing-table.gold' defaultMessage='Gold' />
    )
    const platinumLabel = (
      <FormattedMessage id='stack-deployment-pricing-table.platinum' defaultMessage='Platinum' />
    )
    const enterpriseLabel = (
      <FormattedMessage
        id='stack-deployment-pricing-table.enterprise'
        defaultMessage='Enterprise'
      />
    )
    return {
      initialSort: productColumn,
      items: [
        productColumn,
        {
          label: standardLabel,
          render: (item: Price) => (
            <EuiText size='s'>
              <code>{item.standard}</code>
            </EuiText>
          ),
          textOnly: false,
          align: 'right',
          header: {
            renderCell: () => (
              <EuiTableHeaderCell
                className='stack-deployment-pricing-table-standard-header-cell'
                width='15%'
                align='right'
                key='stack-deployment-pricing-table-standard-header-cell'
              >
                {standardLabel}
              </EuiTableHeaderCell>
            ),
          },
        },
        {
          label: goldLabel,
          render: (item: Price) => (
            <EuiText size='s'>
              <code>{item.gold}</code>
            </EuiText>
          ),
          textOnly: false,
          align: 'right',
          header: {
            renderCell: () => (
              <EuiTableHeaderCell
                className='stack-deployment-pricing-table-gold-header-cell'
                width='15%'
                align='right'
                key='stack-deployment-pricing-table-gold-header-cell'
              >
                {goldLabel}
              </EuiTableHeaderCell>
            ),
          },
        },
        {
          label: platinumLabel,
          render: (item: Price) => (
            <EuiText size='s'>
              <code>{item.platinum}</code>
            </EuiText>
          ),
          textOnly: false,
          align: 'right',
          className: 'stack-deployment-pricing-table-platinum-header-cell',
          header: {
            renderCell: () => (
              <EuiTableHeaderCell
                className='stack-deployment-pricing-table-platinum-header-cell'
                width='15%'
                align='right'
                key='stack-deployment-pricing-table-platinum-header-cell'
              >
                {platinumLabel}
              </EuiTableHeaderCell>
            ),
          },
        },
        {
          label: enterpriseLabel,
          render: (item: Price) => (
            <EuiText size='s'>
              <code>{item.enterprise}</code>
            </EuiText>
          ),
          textOnly: false,
          align: 'right',
          header: {
            renderCell: () => (
              <EuiTableHeaderCell
                className='stack-deployment-pricing-table-enterprise-header-cell'
                width='15%'
                align='right'
                key='stack-deployment-pricing-table-enterprise-header-cell'
              >
                {enterpriseLabel}
              </EuiTableHeaderCell>
            ),
          },
        },
        {
          label: (
            <FormattedMessage id='stack-deployment-pricing-table.unit' defaultMessage='Unit' />
          ),
          render: (item: Price) => item.unit,
          textOnly: false,
          width: `120px`,
        },
      ],
    }
  }

  getGridRows(): Price[] {
    const { prices } = this.props

    return prices?.values || []
  }
}

export default PriceList
