/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import { EuiHeaderLogo, EuiHeaderSection, EuiHeaderSectionItem, keys } from '@elastic/eui'

import history from '@modules/utils/history'

import ElasticMark from './ElasticMark'

import './headerNavigation.scss'

interface Props {}

class HeaderNavigation extends PureComponent<Props> {
  render() {
    return (
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <EuiHeaderLogo
            data-test-id='header-logo'
            onClick={() => this.gotoHomePage()}
            onKeyDown={this.onHeaderLogoKeyDown}
            className='headerNavigation_logo'
            iconType='logoElastic'
            iconTitle=''
            aria-label='Elastic'
            role='link'
            tabIndex={0}
          >
            <ElasticMark className='headerNavigation_elasticMark' />
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    )
  }

  onHeaderLogoKeyDown = (event) => {
    if (event.key === keys.ENTER) {
      this.gotoHomePage()
    }
  }

  gotoHomePage = () => {
    history.push('/')
  }
}

export default HeaderNavigation
