/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import {
  EuiDatePicker,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSwitch,
  EuiSpacer,
} from '@elastic/eui'

import ProviderControl from './ProviderControl'
import RegionControl from './RegionControl'

import type { Moment } from 'moment'
import type { FunctionComponent } from 'react'
import type { Props } from './types'

const Filters: FunctionComponent<Props> = ({
  isLoading,
  onFiltersChange,
  providers,
  regions,
  provider,
  region,
  timestamp,
  include_deprecated_skus,
  is_marketplace,
  marketplace_pricing,
}) => {
  const shouldShowMarketplacePricingToggle = !!marketplace_pricing

  return (
    <div className='stack-deployment-pricing-table-page-content'>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <ProviderControl
                provider={provider}
                providers={providers}
                isLoading={isLoading}
                onSelectProvider={(providerId) => onFiltersChange({ provider: providerId })}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <RegionControl
                region={region}
                regions={regions}
                isLoading={isLoading}
                onSelectRegion={(regionId) => onFiltersChange({ region: regionId })}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow
            label={
              <FormattedMessage
                id='stack-deployment-pricing-table-page.date-picker-label'
                defaultMessage='Price as of'
              />
            }
          >
            <EuiDatePicker
              disabled={isLoading}
              selected={moment.utc(timestamp)}
              onChange={(timestamp: Moment) =>
                onFiltersChange({ timestamp: timestamp.utc().format() })
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        {shouldShowMarketplacePricingToggle && (
          <EuiFlexItem grow={false}>
            <EuiSwitch
              label={
                <FormattedMessage
                  id='stack-deployment-pricing-table-page.show-marketplace-label'
                  defaultMessage='Show marketplace pricing'
                />
              }
              checked={Boolean(is_marketplace)}
              onChange={(e) => onFiltersChange({ is_marketplace: e.target.checked })}
              disabled={isLoading}
              data-test-subj='show-marketplace-switch'
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label={
              <FormattedMessage
                id='stack-deployment-pricing-table-page.show-skus-label'
                defaultMessage='Show deprecated SKUs'
              />
            }
            checked={Boolean(include_deprecated_skus)}
            onChange={(e) => onFiltersChange({ include_deprecated_skus: e.target.checked })}
            disabled={isLoading}
            data-test-subj='include-deprecated-skus-switch'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

export default Filters
