/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormLabel,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { DeploymentGetResponse, DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import type { NodeConfiguration } from '@modules/ui-types'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'

import { getVisibleTemplatesFromV2 } from '@/lib/stackDeployments/defaultDeploymentTemplate'
import {
  getTopology,
  getTemplateTopology,
  getRegionId,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  getSupportedDeploymentTemplates,
  getDeploymentTemplateId,
  getNonVersionedTemplate,
  parseVersionFromTemplateId,
  getLatestVersion,
  isLegacyTemplate,
} from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { isHiddenTemplate } from '@/lib/deploymentTemplates/metadata'
import { isEnabledConfiguration } from '@/lib/deployments/conversion'

import DeploymentTemplateFlyout from './DeploymentTemplateFlyout'
import messages, { getTitleText, getTooltipText } from './messages'

import type { FunctionComponent } from 'react'
import type { Props, State } from './types'

type EditDeploymentTemplateProps = {
  disabled: boolean
  newerTemplate: DeploymentTemplateInfoV2 | null
  onEditDeploymentTemplate: () => void
}

const EditDeploymentTemplateButton: FunctionComponent<EditDeploymentTemplateProps> = ({
  disabled,
  newerTemplate,
  onEditDeploymentTemplate,
}) => (
  <EuiButtonEmpty
    flush='both'
    disabled={disabled}
    style={{ marginLeft: `6px` }}
    onClick={onEditDeploymentTemplate}
    data-test-id='deployment-template-edit-button'
  >
    {newerTemplate && (
      <EuiBadge
        color='primary'
        style={{ marginRight: `12px` }}
        data-test-id='deployment-template-new-version'
      >
        <FormattedMessage {...messages.newVersionAvailable} />
      </EuiBadge>
    )}
    <FormattedMessage {...messages.edit} />
  </EuiButtonEmpty>
)

class DeploymentTemplate extends Component<Props, State> {
  state = {
    pendingDeploymentTemplateId: undefined,
    isFlyoutOpen: false,
  }

  componentWillUnmount() {
    const { resetMigrateDeploymentTemplate, resetUpdateDeployment } = this.props
    const { pendingDeploymentTemplateId } = this.state

    resetUpdateDeployment()

    if (pendingDeploymentTemplateId) {
      resetMigrateDeploymentTemplate(pendingDeploymentTemplateId)
    }
  }

  render() {
    const {
      intl: { formatMessage },
      deployment,
      deploymentTemplates,
      updateDeploymentRequest,
      inTrial,
    } = this.props
    const { isFlyoutOpen, pendingDeploymentTemplateId } = this.state

    const hasLoadedTemplates = deploymentTemplates.length > 0
    const currentTemplateId = getDeploymentTemplateId({ deployment })
    const currentTemplate = deploymentTemplates.find(
      ({ id }) => currentTemplateId && currentTemplateId === id,
    )

    const supportedTemplates = getSupportedDeploymentTemplates(deploymentTemplates)?.filter(
      ({ id }) => id !== currentTemplateId,
    )
    const visibleTemplates = getVisibleTemplatesFromV2({
      deploymentTemplates: supportedTemplates,
    })

    let newerTemplate

    if (isLegacyTemplate(currentTemplateId)) {
      newerTemplate = this.checkForNewerTemplateLegacy(currentTemplateId, currentTemplate)
    } else {
      // check to see if the template has a version extension at the end
      const hasVersion = parseVersionFromTemplateId(currentTemplateId || ``) !== 0
      newerTemplate = hasVersion
        ? this.checkForNewerTemplateVersioned(currentTemplateId, currentTemplate)
        : this.checkForChangedTemplate(currentTemplate)
    }

    if (newerTemplate && visibleTemplates.every(({ id }) => id !== newerTemplate.id)) {
      visibleTemplates.push(newerTemplate)
    }

    const regionId = getRegionId({ deployment })

    return (
      <div>
        <EuiFlexGroup direction='row' gutterSize='none' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiFormLabel>{getTitleText()}</EuiFormLabel>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CuiToggleablePopoverForClassComp
              toggleButton={(togglePopoverFunc) => (
                <EuiButtonIcon
                  aria-label={formatMessage(messages.learnMore)}
                  onClick={togglePopoverFunc}
                  iconType='questionInCircle'
                  color='primary'
                />
              )}
              anchorPosition='rightCenter'
              panelPaddingSize='m'
            >
              <EuiFormHelpText style={{ maxWidth: `275px` }}>{getTooltipText()}</EuiFormHelpText>
            </CuiToggleablePopoverForClassComp>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <EuiFlexGroup gutterSize='none' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiText size='s' data-test-id='deployment-template-name'>
              {hasLoadedTemplates ? currentTemplate?.name : <EuiSkeletonText lines={1} />}
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            {hasLoadedTemplates && visibleTemplates.length > 0 && (
              <PermissionsGate
                permissions={[
                  {
                    type: 'deployment',
                    action: 'update',
                    id: deployment.id,
                  },
                  {
                    type: 'deployment',
                    action: 'migrate-template',
                    id: deployment.id,
                  },
                ]}
              >
                {({ hasPermissions }) => (
                  <EditDeploymentTemplateButton
                    disabled={!hasPermissions}
                    newerTemplate={newerTemplate}
                    onEditDeploymentTemplate={() => this.onEditDeploymentTemplate()}
                  />
                )}
              </PermissionsGate>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>

        {isFlyoutOpen && regionId && (
          <DeploymentTemplateFlyout
            onClose={() => this.setState({ isFlyoutOpen: false })}
            onChange={(templateId) => this.onTemplateChange(templateId)}
            onSave={() => this.onSave()}
            onSaveRequest={updateDeploymentRequest}
            selectedId={pendingDeploymentTemplateId}
            deployment={deployment}
            deploymentTemplates={visibleTemplates}
            currentTemplate={currentTemplate}
            newerTemplate={newerTemplate}
            migratedTemplatePayload={this.getMigratedDeploymentPayload()}
            migrateDeploymentTemplateRequest={this.getMigrateDeploymentTemplateRequest()}
            inTrial={inTrial}
            updateDeploymentRequest={updateDeploymentRequest}
            regionId={regionId}
          />
        )}
      </div>
    )
  }

  checkForNewerTemplateLegacy(currentTemplateId: string | null, currentTemplate) {
    const { deploymentTemplates } = this.props

    if (!currentTemplateId) {
      return null
    }

    return getLatestVersion(
      deploymentTemplates,
      currentTemplateId,
      currentTemplate !== undefined && isHiddenTemplate(currentTemplate),
    )
  }

  checkForNewerTemplateVersioned(currentTemplateId: string | null, currentTemplate) {
    const { deploymentTemplates } = this.props

    if (!currentTemplateId) {
      return null
    }

    return getNonVersionedTemplate(
      deploymentTemplates,
      currentTemplateId,
      currentTemplate !== undefined && isHiddenTemplate(currentTemplate),
    )
  }

  checkForChangedTemplate(
    currentTemplate?: DeploymentTemplateInfoV2,
  ): DeploymentTemplateInfoV2 | null {
    if (!currentTemplate) {
      return null
    }

    const deploymentTopos = this.getTopologyElementsForDeployment()
    const templateTopos = this.getTopologyElementsForTemplate(currentTemplate)

    if (templateTopos === null) {
      return null
    }

    const isThereADiff = Object.entries(deploymentTopos).some(([key, value]) => {
      if (key === `elasticsearch`) {
        // we handle ES specially as there are multiple topology elements
        return value.some((esTopo) => {
          if (isEnabledConfiguration(esTopo)) {
            const tempEsTopo = templateTopos[key]?.find((e) => e.id === esTopo.id)

            if (tempEsTopo) {
              return this.checkForADiff(esTopo, tempEsTopo, currentTemplate)
            }
          }

          return false
        })
      }

      const topoElement = value[0]
      // let's find the corresponding entry in the template
      const templateTopoElement = templateTopos[key]?.[0]

      if (topoElement && templateTopoElement) {
        if (this.checkForADiff(topoElement, templateTopoElement, currentTemplate)) {
          return true
        }
      }

      return false
    })

    return isThereADiff ? currentTemplate : null
  }

  checkForADiff(
    topoElement: NodeConfiguration,
    templateTopoElement: NodeConfiguration,
    currentTemplate: DeploymentTemplateInfoV2,
  ): boolean {
    if (topoElement.instance_configuration_id !== templateTopoElement.instance_configuration_id) {
      return true
    }

    if (this.checkForConfigVersionDiff(topoElement, currentTemplate)) {
      return true
    }

    return false
  }

  checkForConfigVersionDiff(
    topoElement: NodeConfiguration,
    currentTemplate: DeploymentTemplateInfoV2,
  ): boolean {
    const { deployment } = this.props

    // IC ids match, so we need to check versions next, to do this we need to grab the full IC information for both the deployment and the template
    const deploymentICs = (deployment as DeploymentGetResponse).instance_configurations
    const templateICs = currentTemplate.instance_configurations

    if (!deploymentICs) {
      return false
    }

    // find the current IC for this topology element
    const deploymentIC = deploymentICs.find((y) => topoElement.instance_configuration_id === y.id)
    const templateIC = templateICs.find((x) => topoElement.instance_configuration_id === x.id)

    if (!deploymentIC || !templateIC) {
      return false
    }

    // check for config_version
    // If the config_version is undefined, this means that the IC in question is still unversioned, therefore there can't be any version diffs
    // between the template and the deployment.
    if (deploymentIC.config_version === undefined || templateIC.config_version === undefined) {
      return false
    }

    if (deploymentIC.config_version !== templateIC.config_version) {
      return true
    }

    return false
  }

  getTopologyElementsForTemplate(
    currentTemplate: DeploymentTemplateInfoV2,
  ): Record<string, NodeConfiguration[]> | null {
    const template = currentTemplate.deployment_template

    if (!template.resources) {
      return null
    }

    const t: Record<string, NodeConfiguration[]> = {}

    for (const key of Object.keys(template.resources)) {
      const resource = template.resources[key]

      if (resource && resource.length > 0) {
        const first = resource[0]
        t[key] = getTemplateTopology({ resource: first })
      }
    }

    return t
  }

  getTopologyElementsForDeployment(): Record<string, NodeConfiguration[]> {
    const { deployment } = this.props
    const t: Record<string, NodeConfiguration[]> = {}

    for (const key of Object.keys(deployment.resources)) {
      const resource = deployment.resources[key]

      if (resource && resource.length > 0) {
        const first = resource[0]
        t[key] = getTopology({ resource: first })
      }
    }

    return t
  }

  getMigratedDeploymentPayload() {
    const { getMigratedDeploymentPayload } = this.props
    const { pendingDeploymentTemplateId } = this.state

    if (!pendingDeploymentTemplateId) {
      return null // none set
    }

    return getMigratedDeploymentPayload(pendingDeploymentTemplateId)
  }

  getMigrateDeploymentTemplateRequest() {
    const { getMigrateDeploymentTemplateRequest } = this.props
    const { pendingDeploymentTemplateId } = this.state

    if (!pendingDeploymentTemplateId) {
      return null // none set
    }

    return getMigrateDeploymentTemplateRequest(pendingDeploymentTemplateId)
  }

  onTemplateChange(templateId: string | undefined) {
    const { migrateDeploymentTemplate } = this.props

    this.setState({ pendingDeploymentTemplateId: templateId })

    if (templateId) {
      migrateDeploymentTemplate(templateId)
    }
  }

  onSave() {
    const { getMigratedDeploymentPayload, updateDeployment } = this.props
    const { pendingDeploymentTemplateId } = this.state

    if (!pendingDeploymentTemplateId) {
      return // sanity
    }

    const migratedTemplatePayload = getMigratedDeploymentPayload(pendingDeploymentTemplateId)

    if (!migratedTemplatePayload) {
      return // sanity
    }

    updateDeployment(migratedTemplatePayload)
  }

  onEditDeploymentTemplate() {
    this.setState({ isFlyoutOpen: true })
  }
}

export default injectIntl(DeploymentTemplate)
