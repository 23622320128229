/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getConfigForKey } from '@/selectors'
import { fetchProfile } from '@/apps/userconsole/actions/profile'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import {
  claimInstantStackDeploymentRequest,
  fetchStackDeploymentRequest,
  createStackDeploymentRequest,
} from '@/reducers/asyncRequests/registry'
import { createDeployment, resetCreateDeployment } from '@/actions/stackDeployments'
import { getActiveVersionStacks } from '@/reducers/elasticStack'
import { getRegion } from '@/reducers/regions'
import { isByokToggleEnabled } from '@/reducers/byokToggle'

import { redirectToStackGettingStarted } from '../../../../actions/clusters'

import CreateDeploymentButton from './CreateDeploymentButton'
import { StateProps, DispatchProps, ConsumerProps } from './types'

import type { ReduxState, ThunkDispatch } from '@/types/redux'

const mapStateToProps = (
  state: ReduxState,
  { editorState, claimedInstantStackDeploymentId }: ConsumerProps,
): StateProps => {
  const regionId = editorState.regionId

  return {
    profile: getProfile(state),
    region: regionId ? getRegion(state, regionId) : undefined,
    createStackDeploymentRequest: createStackDeploymentRequest(state),
    claimInstantStackDeploymentRequest: claimInstantStackDeploymentRequest(state),
    fetchStackDeploymentRequest: fetchStackDeploymentRequest(
      state,
      claimedInstantStackDeploymentId,
    ),
    stackVersions: regionId ? getActiveVersionStacks(state, regionId) : undefined,
    isUserconsole: getConfigForKey(state, `APP_NAME`) === `userconsole`,
    isByokToggleEnabled: isByokToggleEnabled(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  createDeployment: (params) => dispatch(createDeployment(params)),

  resetCreateDeployment: () => dispatch(resetCreateDeployment()),
  redirectToStackGettingStarted: (stackDeploymentId) =>
    dispatch(redirectToStackGettingStarted(stackDeploymentId)),
  fetchProfile: () => dispatch(fetchProfile()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDeploymentButton)
