/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiTextAlign } from '@elastic/eui'

import history from '@modules/utils/history'

import { createDeploymentUrl } from '@/lib/urlBuilder'

import LandingPageContainer from '../../../../../../components/LandingPageContainer'

import type { FunctionComponent } from 'react'

import '../../../../../../components/Login/EmailVerification/emailVerification.scss'

interface Props {
  image?: string
  darkImage?: string
}

const AccountVerified: FunctionComponent<Props> = ({ image, darkImage }) => (
  <LandingPageContainer
    title={
      <FormattedMessage
        id='password-update-via-email.title.verification-success'
        defaultMessage='Verification success!'
      />
    }
    subtitle={
      <div data-test-id='account-verification-success'>
        <FormattedMessage
          id='password-update-via-email.success'
          defaultMessage='Please login to Elastic Cloud to continue.'
        />
      </div>
    }
    image={image}
    darkImage={darkImage}
    panelProps={{
      hasShadow: false,
      className: 'cloud-landing-page-panel cloud-landing-page-form-panel email-verification-page',
      color: 'transparent',
    }}
  >
    <EuiTextAlign textAlign='center'>
      <EuiButton
        data-test-id='email-verification-login-button'
        fill={true}
        onClick={() => history.push(createDeploymentUrl())}
        minWidth='200px'
      >
        <FormattedMessage id='password-update-via-email.success.button' defaultMessage='Login' />
      </EuiButton>
    </EuiTextAlign>
  </LandingPageContainer>
)

export default AccountVerified
