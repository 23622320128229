/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { SimplifiedLineItem } from '@modules/cloud-api/v1/types'
import { isActiveItem } from '@modules/billing-lib/utils'

interface Balance {
  available: number
  remaining: number
}

export const getActiveBalance = (lineItems: SimplifiedLineItem[] = []): Balance => {
  const date = new Date()
  let available = 0
  let remaining = 0

  lineItems.forEach(({ start, end, ecu_quantity, ecu_balance }) => {
    if (isActiveItem({ start, end }, date)) {
      available += ecu_quantity
      remaining += ecu_balance
    }
  })

  return {
    available,
    remaining,
  }
}
