/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { of, map } from 'rxjs'
import { sha256 } from 'js-sha256'

import type { IAnalyticsClient } from '@elastic/ebt/client/src/analytics_client'

import { getSessionId } from './utils'

import type { Observable } from 'rxjs'

export class EbtAnalytics {
  private analytics: IAnalyticsClient

  constructor(analytics: IAnalyticsClient) {
    this.analytics = analytics
    this.registerSessionIdContext()
    this.registerBrowserInfoAnalyticsContext()
  }

  private registerSessionIdContext() {
    this.analytics.registerContextProvider({
      name: 'session-id',
      context$: of({ session_id: getSessionId() }),
      schema: {
        session_id: {
          type: 'keyword',
          _meta: { description: 'Unique session ID for every browser session' },
        },
      },
    })
  }

  private registerBrowserInfoAnalyticsContext() {
    this.analytics.registerContextProvider({
      name: 'browser info',
      context$: of({
        user_agent: navigator.userAgent,
        preferred_language: navigator.language,
        preferred_languages: navigator.languages,
      }),
      schema: {
        user_agent: {
          type: 'keyword',
          _meta: { description: 'User agent of the browser.' },
        },
        preferred_language: {
          type: 'keyword',
          _meta: { description: 'Preferred language of the browser.' },
        },
        preferred_languages: {
          type: 'array',
          items: {
            type: 'keyword',
            _meta: { description: 'List of the preferred languages of the browser.' },
          },
        },
      },
    })
  }

  public registerUserIdContext({ userId, orgId }: { userId: string; orgId?: string }) {
    this.analytics.registerContextProvider({
      name: 'user-info',
      context$: of({ raw_user_id: userId, user_id: sha256(userId), org_id: orgId }),
      schema: {
        user_id: {
          type: 'keyword',
          _meta: { description: 'Unique ID for every user hashed' },
        },
        raw_user_id: {
          type: 'keyword',
          _meta: { description: 'Unique ID for every user unhashed' },
        },
        org_id: {
          type: 'keyword',
          _meta: { description: 'Unique ID for every organization', optional: true },
        },
      },
    })
  }

  public registerAnalyticsContextProvider({ location$ }: { location$: Observable<string> }) {
    this.analytics.registerContextProvider({
      name: 'cloud page url',
      context$: location$.pipe(map((location) => ({ page_url: location }))),
      schema: {
        page_url: { type: 'text', _meta: { description: 'The cloud page url' } },
      },
    })
  }
}
