/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiHorizontalRule } from '@elastic/eui'

import { isValidDataRetentionConfiguration } from '@modules/project-lib/dataRetention'
import genericMessages from '@modules/project-lib/genericMessages'

import DataRetentionConfirmationModal from './DataRetentionConfirmationModal'
import DefaultDataRetentionPeriodSection from './DefaultDataRetentionPeriodSection'
import MaximumDataRetentionPeriodSection from './MaximumDataRetentionPeriodSection'
import { useDataRetentionValidationErrors } from './hooks'

import type { ReactNode, FC } from 'react'

type DataRetentionControlsFormProps = {
  currentMaximumRetentionDays: number
  currentDefaultRetentionDays: number
  onSubmit: (maximumRetentionDays: number, defaultRetentionDays: number) => void
  projectName: string
  error?: ReactNode
}

const messages = defineMessages({
  defaultDataRetentionPeriodHelpTextEnforced: {
    id: `defaultDataRetentionPeriodSection.defaultDataRetentionPeriodHelpText`,
    defaultMessage: ` The current maximum retention period is set to {amount} days.`,
  },
  defaultDataRetentionPeriodHelpTextNotEnforced: {
    id: `defaultDataRetentionPeriodSection.defaultDataRetentionPeriodHelpTextNotEnforced`,
    defaultMessage: `Maximum retention period is currently not enforced.`,
  },
})

const DataRetentionControlsForm: FC<DataRetentionControlsFormProps> = ({
  currentMaximumRetentionDays,
  currentDefaultRetentionDays,
  onSubmit,
  projectName,
  error,
}) => {
  const { formatMessage } = useIntl()

  const [maximumRetentionDays, setMaximumRetentionDays] = useState(currentMaximumRetentionDays)

  const [defaultRetentionDays, setDefaultRetentionDays] = useState(currentDefaultRetentionDays)

  const [isInvalidForm, setInvalidForm] = useState(false)

  const [showConfirmationModal, setShowConfirmationDialog] = useState(false)

  const {
    getDefaultDataRetentionValidationError,
    getMaximumDataRetentionValidationError,
    getDataRetentionValidationError,
  } = useDataRetentionValidationErrors(defaultRetentionDays, maximumRetentionDays)

  const helpText = Number.isFinite(maximumRetentionDays)
    ? formatMessage(messages.defaultDataRetentionPeriodHelpTextEnforced, {
        amount: maximumRetentionDays,
      })
    : formatMessage(messages.defaultDataRetentionPeriodHelpTextNotEnforced)

  return (
    <EuiForm
      component='form'
      name='data-retention-form'
      onSubmit={(e) => {
        e.preventDefault()

        if (isValidDataRetentionConfiguration(defaultRetentionDays, maximumRetentionDays)) {
          setInvalidForm(false)

          if (
            maximumRetentionDays !== currentMaximumRetentionDays &&
            Number.isFinite(maximumRetentionDays)
          ) {
            setShowConfirmationDialog(true)
          } else {
            onSubmit(maximumRetentionDays, defaultRetentionDays)
          }

          return
        }

        setInvalidForm(true)
      }}
      isInvalid={isInvalidForm}
      error={getDataRetentionValidationError()}
    >
      <EuiFlexGroup direction='column' gutterSize='xl'>
        <EuiFlexGroup direction='column' gutterSize='l'>
          <MaximumDataRetentionPeriodSection
            value={maximumRetentionDays}
            onChange={setMaximumRetentionDays}
            error={getMaximumDataRetentionValidationError() ?? undefined}
          />
          <EuiHorizontalRule margin='none' />
          <DefaultDataRetentionPeriodSection
            value={defaultRetentionDays}
            onChange={setDefaultRetentionDays}
            error={getDefaultDataRetentionValidationError() ?? undefined}
            helpText={helpText}
          />
        </EuiFlexGroup>
        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <EuiButton fill={true} type='submit'>
              {formatMessage(genericMessages.save)}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        {error}
      </EuiFlexGroup>
      {showConfirmationModal && (
        <DataRetentionConfirmationModal
          onCancel={() => setShowConfirmationDialog(false)}
          onConfirm={() => {
            onSubmit(maximumRetentionDays, defaultRetentionDays)
            setShowConfirmationDialog(false)
          }}
          projectName={projectName}
        />
      )}
    </EuiForm>
  )
}

export default DataRetentionControlsForm
