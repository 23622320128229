/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'
import { getFeed } from '@/reducers/feeds'
import { fetchFeedRequest } from '@/reducers/asyncRequests/registry'

import { fetchFeed } from '../../../../actions/feeds'

import TrainingTile from './TrainingTile'

import type { DispatchProps, StateProps, ConsumerProps } from './TrainingTile'

const mapStateToProps = (state: ReduxState, { inTrial }: ConsumerProps): StateProps => {
  const feed = inTrial ? `ess-training-trial` : `ess-training-paying`
  const version = `1.0.0`
  return {
    fetchFeedRequest: fetchFeedRequest(state, `${feed}-${version}`),
    feeds: getFeed(state, feed, version, 4),
  }
}

const mapDispatchToProps: DispatchProps = {
  fetchFeed,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TrainingTile)
