/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { IconType } from '@elastic/eui'

import type { CSP } from '@modules/cluster-user-api/v1/types'
import type { NonEmptyArray } from '@modules/ts-utils'

export type ProviderInfo = {
  id: CSP
  iconType: IconType
  title: string
  shortTitle: string
  beta?: boolean
}

const providers: NonEmptyArray<ProviderInfo> = [
  {
    id: `aws`,
    iconType: `logoAWS`,
    title: `Amazon Web Services`,
    shortTitle: `AWS`,
  },
  {
    id: `azure`,
    iconType: `logoAzure`,
    title: `Azure`,
    shortTitle: `Azure`,
  },
  {
    id: `gcp`,
    iconType: `logoGCP`,
    title: `Google Cloud`,
    shortTitle: `GCP`,
  },
]

export function getProviderInfoById(providerId: CSP): ProviderInfo {
  return providers.find(({ id }) => id === providerId) || providers[0]
}
