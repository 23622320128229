/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { CloudAppFamily, CloudAppName } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { getCurrentUser } from '@/reducers/currentUser'

import { isFeatureActivated, getConfigForKey } from '../../store'

import type { ReduxState } from '@/types/redux'

// TODO: this logic doesn't belong in here, it belongs in lib/ or whatever
export function hasUserSettings(state: ReduxState): boolean {
  const appFamily = getConfigForKey<CloudAppFamily>(`APP_FAMILY`)
  const appName = getConfigForKey<CloudAppName>(`APP_NAME`)
  const currentUser = getCurrentUser(state)

  return (isFeatureActivated(Feature.manageRbac) &&
    appName === 'adminconsole' &&
    appFamily === 'cloud-enterprise' &&
    currentUser) as boolean
}
