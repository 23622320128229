/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer, EuiTextAlign } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import history from '@modules/utils/history'
import { loginUrl } from '@modules/auth/urls'
import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import AppLoadingRoot from '@/components/AppLoadingRoot'
import SpinButton from '@/components/SpinButton'
import LandingPageContainer from '@/components/LandingPageContainer'
import verifyEmailDark from '@/files/illustration-check-email-dark.svg'
import verifyEmailLight from '@/files/illustration-check-email-light.svg'

import type { WrappedComponentProps } from 'react-intl'
import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  email: string
  resetPassword: (email: string) => void
  resetPasswordRequest: AsyncRequestState
}

const messages = defineMessages({
  title: {
    id: 'forgot-password.check-your-email',
    defaultMessage: 'Check your email',
  },
  titleV2: {
    id: 'forgot-password.please-check-your-email',
    defaultMessage: 'Please check your email',
  },
  subtitle: {
    id: 'forgot-password.sent-email-reset',
    defaultMessage:
      "Reset link sent to {email}. Don't see it? Not in your spam folder? You might have signed up with a different email address.",
  },
  subtitleV2: {
    id: 'forgot-password.sent-email-reset-v2',
    defaultMessage:
      'We just sent a reset link to {email}. If you haven’t received it after a few minutes, check your spam folder. If it’s not there, you may have signed up with a different email.',
  },
  resendEmail: {
    id: 'forgot-password.resend-email',
    defaultMessage: 'Resend email',
  },
  backToLogin: {
    id: 'forgot-password.back-to-login-page',
    defaultMessage: 'Back to login',
  },
})

const ForgotPasswordEmailSent: FunctionComponent<Props & LDProps> = ({
  email,
  resetPassword,
  resetPasswordRequest,
  intl: { formatMessage },
}) => {
  const resendResetPasswordLink = () => {
    resetPassword(email)
  }

  const [isFlagsUsable, flags] = useFlagsWhenLoaded()

  const isFlowV2 = flags?.signUpFlowV2

  if (!isFlagsUsable) {
    return <AppLoadingRoot />
  }

  if (isFlowV2) {
    return (
      <LandingPageOuterContainer isFlowV2={isFlowV2} pageContext={{ name: 'resetPassword' }}>
        <LandingPageInnerContainer
          isFlowV2={isFlowV2}
          title={formatMessage(messages.titleV2)}
          subtitle={formatMessage(messages.subtitleV2, { email: <strong>{email}</strong> })}
        >
          <SpinButton
            data-test-id='email-verification-resend-email-button'
            color='primary'
            fill={true}
            onClick={resendResetPasswordLink}
            spin={resetPasswordRequest.inProgress}
            buttonProps={{ fullWidth: true }}
          >
            {formatMessage(messages.resendEmail)}
          </SpinButton>

          <EuiSpacer size='m' />

          <EuiTextAlign textAlign='center'>
            <EuiButtonEmpty
              onClick={() => history.push(loginUrl())}
              data-test-id='email-verification-back-button'
            >
              {'← '}
              {formatMessage(messages.backToLogin)}
            </EuiButtonEmpty>
          </EuiTextAlign>

          {resetPasswordRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='danger' iconType='alert'>
                {resetPasswordRequest.error}
              </CuiAlert>
            </Fragment>
          )}
        </LandingPageInnerContainer>
      </LandingPageOuterContainer>
    )
  }

  return (
    <LandingPageContainer
      image={verifyEmailLight}
      darkImage={verifyEmailDark}
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.subtitle, { email: <strong>{email}</strong> })}
    >
      <SpinButton
        data-test-id='email-verification-resend-email-button'
        color='primary'
        fill={true}
        onClick={resendResetPasswordLink}
        spin={resetPasswordRequest.inProgress}
        buttonProps={{ fullWidth: true }}
      >
        {formatMessage(messages.resendEmail)}
      </SpinButton>

      <EuiSpacer size='l' />

      <EuiTextAlign textAlign='center'>
        <EuiButtonEmpty
          onClick={() => history.push(loginUrl())}
          data-test-id='email-verification-back-button'
        >
          {formatMessage(messages.backToLogin)}
        </EuiButtonEmpty>
      </EuiTextAlign>

      {resetPasswordRequest.error && (
        <React.Fragment>
          <EuiSpacer size='m' />
          <CuiAlert type='danger' iconType='alert'>
            {resetPasswordRequest.error}
          </CuiAlert>
        </React.Fragment>
      )}
    </LandingPageContainer>
  )
}

export default injectIntl(ForgotPasswordEmailSent)
