/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { CoordinatorCandidatesSummary } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'

import { FETCH_COORDINATOR_CANDIDATES } from '../../constants/actions'

import type { AsyncAction, ReduxState } from '@/types/redux'

export interface State {
  [regionId: string]: CoordinatorCandidatesSummary
}

interface FetchCoordinatorAction
  extends AsyncAction<typeof FETCH_COORDINATOR_CANDIDATES, CoordinatorCandidatesSummary> {
  meta: { regionId: string }
}

export default function coordinatorCandidatesReducer(
  state: State = {},
  action: FetchCoordinatorAction,
): State {
  if (action.type === FETCH_COORDINATOR_CANDIDATES && !action.error && action.payload) {
    const { regionId } = action.meta
    return {
      ...state,
      [regionId]: { candidates: action.payload.candidates },
    }
  }

  return state
}

const _getCoordinatorById = (state, regionId, runnerId) =>
  state[regionId]?.candidates.find((candidate) => candidate.name === runnerId)

export const getCoordinatorById = (state: ReduxState, regionId: RegionId, runnerId: string) =>
  _getCoordinatorById(state.coordinators, regionId, runnerId)
