/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormHelpText, EuiSpacer, EuiToolTip } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'
import { usePrettyDuration } from '@modules/utils/prettyTime'

import { getSnapshotSettings } from '@/lib/stackDeployments/selectors/snapshots'

import SnapshotSettings from '../SnapshotSettings'

import type { FunctionComponent } from 'react'

type Props = {
  canEditSettings: boolean
  isUserConsole: boolean
  stackDeployment?: StackDeployment | null
}

const SnapshotRetentionTime: FunctionComponent<Props> = ({
  isUserConsole,
  canEditSettings,
  stackDeployment,
}) => {
  const prettyDuration = usePrettyDuration()

  if (!stackDeployment) {
    return null
  }

  const snapshotSettings = getSnapshotSettings({ deployment: stackDeployment })!

  const retentionTime = prettyDuration({ milliseconds: snapshotSettings.maxAgeMs })

  const retentionMessage = (
    <FormattedMessage
      id='deployment-snapshot-status.retention-explained'
      defaultMessage='The current settings retain {snapshots} {snapshots, plural, one {snapshot} other {snapshots}} over a period of {retentionPeriod}.'
      values={{
        snapshots: snapshotSettings.snapshots,
        retentionPeriod: (
          <EuiToolTip
            position='bottom'
            content={
              <FormattedMessage
                id='deployment-snapshot-status.retention-tooltip'
                defaultMessage='The snapshot retention period is an estimate based on the snapshot frequency interval and the expected number of saved snapshots.'
              />
            }
          >
            <span>{retentionTime}</span>
          </EuiToolTip>
        ),
      }}
    />
  )

  return (
    <Fragment>
      <EuiSpacer size='s' />

      <EuiFormHelpText>
        {retentionMessage}
        {canEditSettings && (
          <Fragment>
            {` `}
            <SnapshotSettings deployment={stackDeployment} isUserConsole={isUserConsole} />
          </Fragment>
        )}
      </EuiFormHelpText>
    </Fragment>
  )
}

export default SnapshotRetentionTime
