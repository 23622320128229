/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import type { ApiKeyResponse } from '@modules/cloud-api/v1/types'

import messages from './messages'

import type { FunctionComponent } from 'react'

type Props = {
  apiKey: ApiKeyResponse
}

const GeneratedApiKeyFooter: FunctionComponent<Props> = (props) => {
  const { apiKey } = props
  const { key, description } = apiKey
  const blob = new window.Blob([key!], {
    type: `text/csv;charset=utf-8`,
  })
  const fileName = description.replace(/ /g, '-')
  const blobUrl = window.URL.createObjectURL(blob)
  const timestamp = moment().format(`YYYY-MMM-DD--HH_mm_ss`)
  const blobName = `${fileName}-${timestamp}.csv`

  return (
    <EuiButton href={blobUrl} download={blobName} fill={true} fullWidth={true}>
      <FormattedMessage {...messages.downloadKey} />
    </EuiButton>
  )
}

export default GeneratedApiKeyFooter
