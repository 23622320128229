/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { CALL_STORED_PROCEDURE, CLEAR_STORED_PROCEDURE } from '../../constants/actions'
import { replaceIn } from '../../lib/immutability-helpers'

import type { ReduxState } from '@/types/redux'
import type { ProcedureState, StoredProcedureAction } from './storedProcedureTypes'

export type State = { [procedureName: string]: ProcedureState }

export default function storedProcedures(state: State = {}, action: StoredProcedureAction) {
  switch (action.type) {
    case CALL_STORED_PROCEDURE:
      if (action.inProgress) {
        return replaceIn(state, [action.meta.procedureName, `result`], null)
      }

      if (!action.error && action.payload) {
        const withResponse = replaceIn(
          state,
          [action.meta.procedureName, `result`],
          action.payload.response,
        )
        const withSuccess = replaceIn(
          withResponse,
          [action.meta.procedureName, `ok`],
          action.payload.ok,
        )

        return withSuccess
      }

      return state

    case CLEAR_STORED_PROCEDURE:
      return replaceIn(state, action.payload.procedureName, { parameters: {}, result: null })

    default:
      return state
  }
}

function _getStoredProcedure(state: State, storedProcedureId: string) {
  return state[storedProcedureId]
}

export const getStoredProcedure = (state: ReduxState, storedProcedureId: string) =>
  _getStoredProcedure(state.storedProcedures, storedProcedureId)
