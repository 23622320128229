/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  KibanaClusterPlan,
  IntegrationsServerPlan,
  EnterpriseSearchPlan,
} from '@modules/cloud-api/v1/types'

import { hasCurrent, hasNext, isStateless } from './preconditions'
import { checkForIcChanges } from './esInstanceConfiguration'

import type { Difference, DifferenceCheck } from './types'

export const diffStatelessInstanceConfiguration: DifferenceCheck = {
  preconditions: [isStateless, hasCurrent, hasNext],
  check: ({ current, next, sliderInstanceType }) => {
    const currentStateless = current as
      | KibanaClusterPlan
      | IntegrationsServerPlan
      | EnterpriseSearchPlan
    const nextStateless = next as KibanaClusterPlan | IntegrationsServerPlan | EnterpriseSearchPlan
    const changes: Array<Difference<any>> = []

    if (!nextStateless.cluster_topology || !currentStateless.cluster_topology) {
      // this scenario is handled elsewhere
      return changes
    }

    const thisTypeInNext = nextStateless.cluster_topology[0]
    const thisTypeInCurrent = currentStateless.cluster_topology[0]

    checkForIcChanges(
      thisTypeInCurrent,
      thisTypeInNext,
      changes,
      sliderInstanceType,
      sliderInstanceType,
    )

    return changes
  },
}
