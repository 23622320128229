/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiBasicTableColumn } from '@elastic/eui'
import { EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type {
  ResourceType,
  RoleAssignmentsTableItem,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import { resourceRoleLabelByRoleId } from '@modules/role-assignments-lib/types'
import {
  deploymentRoleLabels,
  elasticsearchRoleLabels,
  ResourceTypeDisplay,
  observabilityRoleLabels,
  securityRoleLabels,
} from '@modules/role-assignments-lib/messages'
import { getProjectSpecificViewerRole } from '@modules/role-assignments-lib'
import type { ProjectType } from '@modules/ui-types/projects'
import { useConfig } from '@modules/cui/ConfigContext'
import { AdminCustomRoleBadge } from '@modules/role-assignments-admin-components/ProjectRoleAssignmentsSummary/AdminCustomRoleBadge'
import { UserCustomRoleBadge } from '@modules/role-assignments-user-components/ProjectRoleAssignmentsSummary/UserCustomRoleBadge'

import type { MessageDescriptor } from 'react-intl'
import type { FunctionComponent } from 'react'

const ProjectRoleAssignmentsTable: FunctionComponent<{
  items: Array<RoleAssignmentsTableItem<ResourceType>>
  customRolesEnabled: boolean
}> = ({ items, customRolesEnabled }) => {
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  return (
    <EuiBasicTable
      data-test-id='instance-role-assignments-table'
      noItemsMessage={
        <FormattedMessage
          id='roles-assignments.no-instance-found'
          defaultMessage='No instance found'
        />
      }
      items={items}
      columns={getColumns(customRolesEnabled, isAdminConsole)}
    />
  )
}

const getColumns = (
  customRolesEnabled: boolean,
  isAdminConsole: boolean,
): Array<EuiBasicTableColumn<RoleAssignmentsTableItem<ResourceType>>> => [
  {
    name: <FormattedMessage id='roles-assignments.name' defaultMessage='Name' />,
    field: 'name',
    sortable: ({ name }) => name.toLowerCase(),
    render: (name: string) => <EuiText size='s'>{name}</EuiText>,
    truncateText: true,
    width: '34%',
  },
  {
    name: <FormattedMessage id='roles-assignments.type' defaultMessage='Type' />,
    field: 'resourceType',
    render: (resourceType: ResourceType) => (
      <ResourceTypeDisplay resourceType={resourceType} capitalize={true} />
    ),
    width: '33%',
  },
  {
    name: (
      <FormattedMessage
        id={'roles-assignments.roleColumn'}
        defaultMessage={'{count, plural, one {Role} other {Roles}}'}
        values={{ count: customRolesEnabled ? 2 : 1 }}
      />
    ),
    field: 'roles',
    render: (
      roles: TableItemRoleDescriptor[],
      roleAssignmentItem: RoleAssignmentsTableItem<ResourceType>,
    ) => {
      const roleToProcess = customRolesEnabled ? roles : roles.slice(0, 1)
      const roleItems = roleToProcess.map((roleDescriptor) => {
        if (customRolesEnabled && roleDescriptor.isCustomRole) {
          return isAdminConsole ? (
            <AdminCustomRoleBadge
              key={roleAssignmentItem.id.concat(roleDescriptor.roleId)}
              item={roleAssignmentItem}
              roleDescriptor={roleDescriptor}
            />
          ) : (
            <UserCustomRoleBadge
              key={roleAssignmentItem.id.concat(roleDescriptor.roleId)}
              item={roleAssignmentItem}
              roleDescriptor={roleDescriptor}
              customRolesEnabled={customRolesEnabled}
            />
          )
        }

        // If we encoutner a custom role when custom roles is disabled,
        // override it a with the appropriate viewer predfined role
        let predefinedRoleId = roleDescriptor.roleId

        if (roleDescriptor.isCustomRole) {
          predefinedRoleId = getProjectSpecificViewerRole(
            roleAssignmentItem.resourceType as ProjectType,
          )
        }

        const roleLabel = resourceRoleLabelByRoleId[predefinedRoleId]
        let predefinedLabels: Record<string, MessageDescriptor> = deploymentRoleLabels

        if (roleAssignmentItem.resourceType === 'elasticsearch') {
          predefinedLabels = elasticsearchRoleLabels
        } else if (roleAssignmentItem.resourceType === 'observability') {
          predefinedLabels = observabilityRoleLabels
        } else if (roleAssignmentItem.resourceType === 'security') {
          predefinedLabels = securityRoleLabels
        }

        return (
          <EuiFlexItem grow={false} key={roleAssignmentItem.id.concat(roleDescriptor.roleId)}>
            <FormattedMessage {...predefinedLabels[roleLabel]} />
          </EuiFlexItem>
        )
      })

      return (
        <EuiFlexGroup direction='column' justifyContent='flexStart' gutterSize='xs'>
          {roleItems}
        </EuiFlexGroup>
      )
    },
    width: '33%',
  },
]

export default ProjectRoleAssignmentsTable
