/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import DomainClaims from './DomainClaims'
import UserAuthentication from './UserAuthentication'
import RoleMappings from './RoleMappings'
import { Props } from './types'

import './styles.scss'

const Security = (props: Props) => {
  const { organizationId, readonly } = props

  return (
    <div style={{ maxWidth: '1200px' }}>
      <UserAuthentication organizationId={organizationId} readonly={readonly} />
      <EuiSpacer size='l' />
      <DomainClaims organizationId={organizationId} readonly={readonly} />
      <EuiSpacer size='l' />
      <RoleMappings organizationId={organizationId} readonly={readonly} />
    </div>
  )
}

export default Security
