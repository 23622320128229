/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut } from '@elastic/eui'

import type { ProjectApiError } from '@modules/ui-types/projects'

import type { ReactNode } from 'react'

const ProjectApiErrorCallout: React.FunctionComponent<{
  error: ProjectApiError | null
  /**
   * A callback function to customize the rendered output from the default of
   * the verbatim `message`. Invoked once per item in the `errors` collection.
   */
  children?: (error: Exclude<ProjectApiError, string>['errors'][number] | undefined) => ReactNode
}> = ({ error: errorContainer, children }) => {
  if (!errorContainer) {
    return null
  }

  if (typeof errorContainer === 'string') {
    return <EuiCallOut color='danger'>{errorContainer}</EuiCallOut>
  }

  const { errors = [] } = errorContainer

  if (!errors.length) {
    return null
  }

  const title = (
    <FormattedMessage id='projectApi.errorTitle' defaultMessage='Something went wrong' />
  )

  if (children) {
    if (errors.length === 1) {
      return (
        <EuiCallOut color='danger' title={title}>
          {children(errors[0])}
        </EuiCallOut>
      )
    }

    return (
      <EuiCallOut color='danger' title={title}>
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{children(e)}</li>
          ))}
        </ul>
      </EuiCallOut>
    )
  }

  return (
    <EuiCallOut color='danger' title={title}>
      {errors.map((e) => e.message)}
    </EuiCallOut>
  )
}

export default ProjectApiErrorCallout
