/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { PureComponent } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiDescriptionList,
  EuiLink,
  EuiText,
  EuiIconTip,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import type { LineItem } from '@modules/cloud-api/v1/types'
import CuiPrice from '@modules/cui/formatters/CuiPrice'
import { getConsumptionTypeDisplayName } from '@modules/billing-lib/utils'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'

import { getConfigForKey } from '@/store'
import { numericDateTime } from '@/config/dates'

import type { ReactChild, ReactElement, ReactNode } from 'react'

interface Props {
  details: LineItem
}

class DetailsGridPopover extends PureComponent<Props> {
  render(): ReactElement {
    return (
      <CuiToggleablePopoverForClassComp
        toggleButton={this.renderPopoverButton}
        panelClassName='prepaid-account-details-popover'
        ownFocus={false}
      >
        <EuiDescriptionList
          className='prepaid-account-details-popover-list'
          listItems={this.getDetails()}
          css={css({ maxWidth: '440px' })}
          type='column'
        />
      </CuiToggleablePopoverForClassComp>
    )
  }

  renderPopoverButton(togglePopoverFunc: () => void): ReactElement {
    return (
      <EuiLink onClick={togglePopoverFunc} data-test-id='details-link'>
        <FormattedMessage id='prepaid-account-details-popover.details' defaultMessage='Details' />
      </EuiLink>
    )
  }

  renderDetailsTitle(title: ReactChild): ReactElement {
    return (
      <EuiText
        size='s'
        className='prepaid-account-details-popover-title'
        css={css({ paddingTop: 8 })}
      >
        {title}
      </EuiText>
    )
  }

  renderDetailsDescription(description: ReactChild): ReactElement {
    return (
      <EuiText
        size='s'
        className='prepaid-account-details-popover-description'
        css={css({ paddingTop: 8, textAlign: 'right' })}
      >
        {description}
      </EuiText>
    )
  }

  getDetails(): Array<{
    title: NonNullable<ReactNode>
    description: NonNullable<ReactNode>
  }> {
    const {
      details: {
        id,
        active,
        discount,
        ecu_price,
        currency,
        start,
        type,
        reason,
        sales_order_number,
        purchased_balance,
        external_reseller_id,
      },
    } = this.props

    const isCreditItem = type === 'credit'
    const isResellerItem = !!external_reseller_id

    const details = [
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.start-date'
            defaultMessage='Start date'
          />,
        ),
        description: this.renderDetailsDescription(
          <FormattedDate value={start} {...numericDateTime} />,
        ),
        isVisible: true,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.initial-balance'
            defaultMessage='Paid amount'
          />,
        ),
        description: this.renderDetailsDescription(
          <CuiPrice
            data-test-id='purchased-balance'
            value={purchased_balance!}
            currency={currency}
            unit='none'
          />,
        ),
        isVisible: !isCreditItem && !isResellerItem,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.list-price-at-purchase'
            defaultMessage='List price at purchase'
          />,
        ),
        description: this.renderDetailsDescription(
          <CuiPrice
            data-test-id='purchased-price'
            value={ecu_price!}
            unit='none'
            currency={currency}
          />,
        ),
        isVisible: !isCreditItem && !isResellerItem,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            data-test-id='discount-rate'
            id='prepaid-account-details-popover.discount-rate'
            defaultMessage='Discount rate'
          />,
        ),
        description: this.renderDetailsDescription(`${discount}%`),
        isVisible: !isCreditItem && !isResellerItem,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage id='prepaid-account-details-popover.status' defaultMessage='Status' />,
        ),
        description: this.renderDetailsDescription(this.getItemStatus({ active })),
        isVisible: true,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage
            id='prepaid-account-details-popover.id'
            defaultMessage='Sales Order Number'
          />,
        ),
        isVisible: !isCreditItem && Boolean(sales_order_number),
        description: this.renderDetailsDescription(sales_order_number!),
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage id='prepaid-account-details-popover.type' defaultMessage='Type' />,
        ),
        description: this.renderDetailsDescription(getConsumptionTypeDisplayName(type)),
        isVisible: true,
      },
      {
        title: this.renderDetailsTitle(
          <FormattedMessage id='prepaid-account-details-popover.reason' defaultMessage='Reason' />,
        ),
        description: this.renderDetailsDescription(reason!),
        isVisible: Boolean(reason),
      },
      {
        title: this.renderDetailsTitle(
          <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
            <EuiFlexItem grow={false}>
              <FormattedMessage
                id='prepaid-account-details-popover.internal_id'
                defaultMessage='Internal ID'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiIconTip
                type='eyeClosed'
                color='primary'
                content={
                  <FormattedMessage
                    id='admin-only-panel.message'
                    defaultMessage='Only visible in admin console'
                  />
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>,
        ),
        description: this.renderDetailsDescription(id!),
        isVisible: getConfigForKey(`APP_NAME`) === `adminconsole`,
      },
    ]

    return details.filter(({ isVisible }) => isVisible)
  }

  getItemStatus({ active }: { active: boolean }): ReactElement {
    return active ? (
      <FormattedMessage id='prepaid-account-details-popover.active' defaultMessage='Active' />
    ) : (
      <FormattedMessage id='prepaid-account-details-popover.inactive' defaultMessage='Inactive' />
    )
  }
}

export default DetailsGridPopover
