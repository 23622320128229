/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'
import type { AnyUserProject } from '@modules/project-user-api/types'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'
import usePermissions from '@modules/permissions-lib/hooks'

import type React from 'react'

type ChildProps = {
  projectsLength: number
  /**
   * Check before using metadata. False indicates a loading or failed state.
   */
  isReady: boolean
}

type Props = {
  children: (props: ChildProps) => React.ReactElement<ChildProps, any> | null
}

export const useProjectsMetadata = () => {
  const serverlessFeature = useConfig(Feature.serverless)

  const listElasticsearchProjectsPermission = usePermissions([
    { type: 'project-elasticsearch', action: 'list' },
  ])
  const listObservabilityProjectsPermission = usePermissions([
    { type: 'project-observability', action: 'list' },
  ])
  const listSecurityProjectsPermission = usePermissions([
    { type: 'project-security', action: 'list' },
  ])

  const elasticsearchQuery = useListElasticsearchProjectsQuery({
    enabled: serverlessFeature && listElasticsearchProjectsPermission.hasPermissions,
  })
  const observabilityQuery = useListObservabilityProjectsQuery({
    enabled: serverlessFeature && listObservabilityProjectsPermission.hasPermissions,
  })
  const securityQuery = useListSecurityProjectsQuery({
    enabled: serverlessFeature && listSecurityProjectsPermission.hasPermissions,
  })

  const queries = [elasticsearchQuery, observabilityQuery, securityQuery]
  const successfulQueries = queries.filter((query) => query.isSuccess)

  const permissionChecks = [
    listElasticsearchProjectsPermission,
    listObservabilityProjectsPermission,
    listSecurityProjectsPermission,
  ]

  const isLoading =
    queries.some((query) => query.isLoading) ||
    permissionChecks.some((permissionCheck) => permissionCheck.isLoading)

  const items = successfulQueries.reduce<AnyUserProject[]>(
    (acc, query) => [...acc, ...(query.data?.items || [])],
    [],
  )

  return { projectsLength: items.length, isReady: !isLoading }
}

const SupplyProjectsMetadata: React.FunctionComponent<Props> = ({ children }) => {
  const { projectsLength, isReady } = useProjectsMetadata()

  return children({ projectsLength, isReady })
}

export default SupplyProjectsMetadata
