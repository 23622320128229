/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  Organization,
  SaasOrganizationResponse,
  SaasUserResponse,
  SaasUserTrial,
} from '@modules/cloud-api/v1/types'
import {
  createOrganizationUrl,
  getSaasCurrentUserUrl,
  getSaasOrganizationUrl,
  refreshSaasCurrentUserUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'
import type { OrganizationFromApi, UserProfileFromApi } from '@modules/ui-types'

export const getUserProfile: () => Promise<{ user: UserProfileFromApi }> = () =>
  fetchAsJson<SaasUserResponse>(getSaasCurrentUserUrl(), {
    method: 'get',
    headers: {
      Authorization: '',
    },
  }).then((val) => ({
    user: {
      ...val.user,
      ...val.rules,
      ...val.subscription,
      trials: val.trials,
    } as UserProfileFromApi,
  }))

export const getOrganizationProfile = async ({ organizationId }: { organizationId: string }) =>
  fetchAsJson<{ organization: OrganizationFromApi; trials: SaasUserTrial[] }>(
    getSaasOrganizationUrl({ organizationId }),
    {
      method: 'get',
      headers: {
        Authorization: '',
      },
    },
  )

export const createOrganization = async ({ name }: { name?: string }) =>
  fetchAsJson<Organization>(createOrganizationUrl(), {
    method: 'post',
    body: JSON.stringify({
      name,
    }),
    headers: {
      Authorization: '',
    },
  })

export const postRefreshToken = async () =>
  fetchAsJson(refreshSaasCurrentUserUrl(), {
    method: 'post',
  })

export const getSaasUser: () => Promise<SaasUserResponse> = () =>
  fetchAsJson<SaasUserResponse>(getSaasCurrentUserUrl(), {
    method: 'get',
    headers: {
      Authorization: '',
    },
  })

export const getSaasOrganization: (organizationId: string) => Promise<SaasOrganizationResponse> = (
  organizationId,
) =>
  fetchAsJson<SaasOrganizationResponse>(getSaasOrganizationUrl({ organizationId }), {
    method: 'get',
    headers: {
      Authorization: '',
    },
  })
