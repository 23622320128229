/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'
import _ from 'lodash'

import euiVars from '@elastic/eui/dist/eui_theme_light.json'

import ChatIframe from '@/apps/userconsole/components/DriftChat/ChatIframe'

import type { CSSProperties } from 'react'
import type { AllProps } from './types'

type Props = Exclude<AllProps, 'profile'> & {
  profile: Exclude<AllProps['profile'], null> // require by the time this is rendered

  /** the chat widget is ready */
  onReady?: (chatApi: ChatApi) => void

  /** invoked when the playbook is fired */
  onPlaybookFired?: () => void

  /** styles set from outside, takes precedence over what an iframe sets */
  styles?: CSSProperties
}

export interface ChatApi {
  show: () => void
  hide: () => void
  toggle: () => void
}

interface State {
  // styles set by the iframe
  styles: CSSProperties
}

export class DriftChatIframe extends Component<Props, State> {
  isChatOpen: boolean = false

  state = {
    styles: {},
  }

  componentDidMount() {
    const { fetchDriftJwt } = this.props
    fetchDriftJwt()
  }

  // We need to avoid unnecessary re-rendering as it causes glitches in drift iframe component
  // PureComponent doesn't work because shallow comparison of `profile` property isn't working
  // `profile` has plenty of keys and deep comparison of all keys is an overkill here
  // so we compare only keys that are used in component logic
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextProps.chatUrl !== this.props.chatUrl) {
      return true
    }

    if (nextProps.fetchDriftJwt !== this.props.fetchDriftJwt) {
      return true
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      return true
    }

    if (nextProps.location.search !== this.props.location.search) {
      return true
    }

    const { profile: nextProfile } = nextProps
    const { profile } = this.props

    const isProfileChanged =
      nextProfile.user_id !== profile.user_id ||
      nextProfile.email !== profile.email ||
      nextProfile.domain !== profile.domain ||
      nextProfile.inTrial !== profile.inTrial ||
      !_.isEqual(nextProfile.trials, profile.trials) ||
      nextProfile.created !== profile.created ||
      nextProfile.conversion_timestamp !== profile.conversion_timestamp ||
      nextProfile.is_paying !== profile.is_paying

    if (isProfileChanged) {
      return true
    }

    if (!_.isEqual(nextState.styles, this.state.styles)) {
      return true
    }

    return false
  }

  render() {
    const { fetchDriftJwtRequestState, chatUrl } = this.props

    if (fetchDriftJwtRequestState.inProgress) {
      return null
    }

    return (
      <ChatIframe
        chatUrl={chatUrl}
        handleWindowMessage={this.handleWindowMessage}
        styles={this.getStyles()}
      />
    )
  }

  getContextAndAttributes() {
    const context = {
      window: {
        location: {
          hash: window.location.hash,
          host: window.location.host,
          hostname: window.location.hostname,
          href: window.location.href,
          origin: window.location.origin,
          pathname: window.location.pathname,
          port: window.location.port,
          protocol: window.location.protocol,
          search: window.location.search,
        },
        navigator: {
          language: window.navigator.language,
          userAgent: window.navigator.userAgent,
        },
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      },
      document: {
        title: document.title,
        referrer: document.referrer,
      },
    }
    const { profile } = this.props
    const userAttributes = {
      email: profile.email,
      cloud_user_domain: profile.domain,
      cloud_user_trial: profile.inTrial && profile.trials.length > 0,
      cloud_user_created_at: profile.created,
      cloud_user_conversion_date: profile.conversion_timestamp,
      cloud_user_paying: profile.is_paying,
    }

    return { context, userAttributes }
  }

  startTrackingUserAttributes(chatIframe: HTMLIFrameElement): void {
    // in case if props have been changed
    // between initial rendering and driftWidgetReady event
    this.trackUserAttributes(chatIframe)
  }

  trackUserAttributes(chatIframe: HTMLIFrameElement): void {
    if (!chatIframe.contentWindow) {
      return
    }

    chatIframe.contentWindow.postMessage(
      {
        type: 'driftSetUserAttributes',
        data: this.getContextAndAttributes(),
      },
      '*',
    )
  }

  handleWindowMessage = (event: MessageEvent, chatIframe: HTMLIFrameElement): void => {
    if (!chatIframe.contentWindow || event.source !== chatIframe.contentWindow) {
      return
    }

    const chatApi: ChatApi = {
      show: () => {
        chatIframe.contentWindow?.postMessage(
          {
            type: `driftShow`,
          },
          '*',
        )
        chatIframe.contentWindow?.postMessage(
          {
            type: `driftOpenChat`,
          },
          '*',
        )
        this.isChatOpen = true
      },
      hide: () => {
        chatIframe.contentWindow?.postMessage(
          {
            type: `driftHide`,
          },
          '*',
        )
        this.isChatOpen = false
      },
      toggle: () => {
        if (this.isChatOpen) {
          chatApi.hide()
        } else {
          chatApi.show()
        }
      },
    }

    const message = event.data

    switch (message.type) {
      case 'driftWidgetReady': {
        chatApi.hide()

        this.startTrackingUserAttributes(chatIframe)

        this.props.onReady?.(chatApi)

        if (hasPlaybookFiredOnce()) {
          this.props.onPlaybookFired?.()
        }

        break
      }

      case 'driftPlaybookFired': {
        this.props.onPlaybookFired?.()
        setPlaybookFiredOnce()
        break
      }

      case 'driftChatClosed': {
        chatApi.hide()

        break
      }

      case 'driftIframeReady': {
        const { profile } = this.props
        const { context, userAttributes } = this.getContextAndAttributes()
        const userData = { ...userAttributes, jwt: profile.driftJwt }
        chatIframe.contentWindow.postMessage(
          {
            type: 'driftSetContext',
            data: { context, user: userData },
          },
          '*',
        )
        break
      }

      case 'driftIframeResize': {
        const styles = message.data.styles || ({} as CSSProperties)
        // camelize to avoid style warnings from react
        const camelize = (s: string) => s.replace(/-./g, (x) => x[1]!.toUpperCase())
        const camelStyles = Object.keys(styles).reduce((acc, key) => {
          acc[camelize(key)] = styles[key]
          return acc
        }, {} as Record<string, string>) as CSSProperties
        this.setState((prevState) => ({
          styles: {
            ...prevState.styles,
            ...camelStyles,
          },
        }))
        break
      }

      default:
        break
    }
  }

  getStyles(): React.CSSProperties {
    return {
      ...this.state.styles,
      // override set z-index
      zIndex: `${euiVars.euiZMaskBelowHeader}`,
      // override with styles from outside
      ...this.props.styles,
    }
  }
}

// The `MESSAGE_PLAYBOOK_FIRED` event is only fired on the first interaction in a Drift session for a user,
// so we need to manually trigger the callback if the event has already fired for the current user
// to reduce a chance of user not being able to get back to the recently started conversation after a page reload
const { hasPlaybookFiredOnce, setPlaybookFiredOnce } = (() => {
  const key = `driftPlaybookFired`
  return {
    hasPlaybookFiredOnce: () => {
      try {
        return sessionStorage.getItem(key) === 'true'
      } catch (e) {
        return false
      }
    },
    setPlaybookFiredOnce: () => {
      try {
        sessionStorage.setItem(key, 'true')
      } catch (e) {
        // ignore
      }
    },
  }
})()
