/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'
import { EuiFlexItem, EuiText, EuiLink, withEuiTheme } from '@elastic/eui'

import type { FeaturesUsage } from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import { isElasticStaff } from '@modules/billing-lib/utils'

import SelectSubscriptionModal from '../../Billing/SelectSubscription'
import ReviewSubscriptionChangesModal from '../../Billing/ReviewSubscriptionChangesModal'
import { isUsageOutOfCompliance } from '../../../../../lib/usage'

import ReviewChangesLink from './ReviewChangesLink'
import { linkStyle } from './style'

import type { ReactElement } from 'react'
import type { FetchUsageDetailsProps } from '../../../actions/billing/details'

import './usageNotice.scss'

interface Props extends WithEuiThemeProps {
  usageDetails: FeaturesUsage | undefined
  fetchUsageDetails: (props: FetchUsageDetailsProps) => void
  accountDetails: UserProfile
  enableUsageNoticeElasticCheck: boolean
}

type State = {
  showChangeSubscriptionModal: boolean
  showReviewChangesModal: boolean
  upgradeLevel: string | undefined
}

class UsageNotice extends Component<Props, State> {
  state = {
    showChangeSubscriptionModal: false,
    showReviewChangesModal: false,
    upgradeLevel: undefined,
  }

  componentDidMount(): void {
    const {
      usageDetails,
      fetchUsageDetails,
      accountDetails: { organization_id: organizationId },
    } = this.props

    if (!usageDetails && organizationId) {
      fetchUsageDetails({ organizationId })
    }
  }

  render(): ReactElement | null {
    const { usageDetails, theme } = this.props
    const { upgradeLevel } = this.state

    if (!usageDetails) {
      return null
    }

    const { usage_level } = usageDetails
    const showUsageBanner = this.getShowUsageBanner()

    const usageNoticeStyle = css({
      backgroundColor: theme.euiTheme.colors.primary,
      color: theme.euiTheme.colors.emptyShade,
    })

    if (showUsageBanner) {
      return (
        <Fragment>
          <EuiFlexItem
            grow={false}
            data-test-id='usage-notice'
            className='usageNotice'
            css={usageNoticeStyle}
          >
            <EuiText textAlign='center' size='s'>
              {this.renderMessage()}
            </EuiText>
          </EuiFlexItem>
          {this.state.showChangeSubscriptionModal && (
            <SelectSubscriptionModal
              upgradeLevel={upgradeLevel}
              closeModal={() =>
                this.setState({ showChangeSubscriptionModal: false, upgradeLevel: undefined })
              }
              onSeeReviewChanges={() =>
                this.setState({ showChangeSubscriptionModal: false, showReviewChangesModal: true })
              }
            />
          )}
          {this.state.showReviewChangesModal && (
            <ReviewSubscriptionChangesModal
              closeModal={() => this.setState({ showReviewChangesModal: false })}
              onUpgrade={() => this.onUpgrade(usage_level)}
            />
          )}
        </Fragment>
      )
    }

    return null
  }

  renderMessage(): ReactElement {
    const {
      accountDetails: { contract_type },
      theme,
    } = this.props

    if (contract_type === `annual`) {
      return (
        <FormattedMessage
          data-test-id='annual-usage-notice'
          id='annual-usage-notice.message'
          defaultMessage={`Your subscription level doesn't match the features you are using. Talk to your sales representative to upgrade your subscription or {removeFeatures}.`}
          values={{
            removeFeatures: (
              <ReviewChangesLink onReviewChanges={() => this.onReviewChanges()} isAnnual={true} />
            ),
          }}
        />
      )
    }

    return (
      <FormattedMessage
        data-test-id='monthly-usage-notice'
        id='usage-notices.message'
        defaultMessage={`Your subscription level doesn't match the features that you're using. {changeSubscription} or {reviewChanges}.`}
        values={{
          changeSubscription: (
            <EuiLink
              onClick={() => this.onChangeSubscription()}
              className='changeSubscriptionLink'
              css={linkStyle({ theme })}
            >
              <FormattedMessage
                id='usage-notices.message.change-subscription'
                defaultMessage='Change your subscription'
              />
            </EuiLink>
          ),
          reviewChanges: <ReviewChangesLink onReviewChanges={() => this.onReviewChanges()} />,
        }}
      />
    )
  }

  onChangeSubscription(): void {
    this.setState({ showChangeSubscriptionModal: true })
  }

  onReviewChanges(): void {
    this.setState({ showReviewChangesModal: true })
  }

  onUpgrade(upgradeLevel: string | undefined): void {
    this.setState({
      showReviewChangesModal: false,
      showChangeSubscriptionModal: true,
      upgradeLevel,
    })
  }

  getShowUsageBanner(): boolean {
    const {
      usageDetails,
      accountDetails: { email, is_trial },
      enableUsageNoticeElasticCheck,
    } = this.props
    const outOfCompliance = isUsageOutOfCompliance({
      subscriptionLevel: usageDetails?.subscription_level,
      usageLevel: usageDetails?.usage_level,
    })

    if (enableUsageNoticeElasticCheck && isElasticStaff({ email })) {
      // We don't want to show the banner to elastic employees in
      // production environments.
      return false
    }

    return outOfCompliance && !is_trial
  }
}

export default withEuiTheme(UsageNotice)
