/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui'

import type { ElasticsearchClusterTrustSettings } from '@modules/cloud-api/v1/types'
import type { AnyTrustRelationship } from '@modules/ui-types'

import { getConfigForKey } from '@/store'
import { getOrganizationId } from '@/lib/stackDeployments/selectors/metadata'
import {
  getTrustRelationshipDisplayName,
  getTrustRelationshipFromDeployment,
  getTrustRelationshipTargetEnvironmentId,
} from '@/lib/stackDeployments/trustRelationships'

import { getTargetEnvironmentType } from '../helpers'

import type { AllProps } from './types'

class DeploymentTrustRelationshipHeader extends React.Component<AllProps> {
  componentDidMount(): void {
    const isEce = getConfigForKey(`APP_PLATFORM`) === 'ece'

    if (isEce) {
      this.props.fetchTrustRelationships()
    }
  }

  componentWillUnmount(): void {
    this.props.resetFetchTrustRelationships()
  }

  render(): JSX.Element | null {
    if (!this.props.stackDeployment) {
      return null // sanity
    }

    const trustRelationship = this.getTrustRelationshipUnderEdit()
    const targetEnvironmentType = getTargetEnvironmentType(trustRelationship)
    const targetEnvironmentId =
      trustRelationship && getTrustRelationshipTargetEnvironmentId({ trustRelationship })

    return (
      <EuiFlexGroup gutterSize='l' alignItems='center'>
        <EuiFlexItem>
          <EuiTitle size='l' className='deploymentTitle' data-test-id='deployment-title'>
            <h2>{this.renderTitle()}</h2>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiText size='s'>
            <FormattedMessage
              id='deployment-header.trust.environment.label'
              defaultMessage='Environment'
            />
            <br />
            <strong>{this.getEnvironment()}</strong>
          </EuiText>
        </EuiFlexItem>

        {targetEnvironmentId && (
          <EuiFlexItem grow={false}>
            <EuiText
              size='s'
              style={{
                maxWidth: `12rem`,
                overflow: `hidden`,
                textOverflow: `ellipsis`,
                whiteSpace: `nowrap`,
              }}
            >
              {targetEnvironmentType === 'ess' && (
                <FormattedMessage
                  id='deployment-header.trust.id.ess.label'
                  defaultMessage='Organization ID'
                />
              )}
              {targetEnvironmentType === 'ece' && (
                <FormattedMessage
                  id='deployment-header.trust.id.ece.label'
                  defaultMessage='Environment ID'
                />
              )}
              {targetEnvironmentType === 'self-managed' && (
                <FormattedMessage
                  id='deployment-header.trust.id.self-managed.label'
                  defaultMessage='Scope ID'
                />
              )}
              <br />
              <strong>{targetEnvironmentId}</strong>
            </EuiText>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  renderTitle(): JSX.Element {
    const {
      environmentTrustRelationships,
      match: {
        params: { trustRelationshipId },
      },
    } = this.props

    if (!trustRelationshipId) {
      return (
        <FormattedMessage
          id='deployment-header.trust.add'
          defaultMessage='Trust a new environment'
        />
      )
    }

    const trustRelationship = this.getTrustRelationshipUnderEdit()

    const displayName =
      trustRelationship &&
      getTrustRelationshipDisplayName(trustRelationship, environmentTrustRelationships)

    return (
      <FormattedMessage
        id='deployment-header.trust.edit'
        defaultMessage='Edit {displayName}'
        values={{
          displayName,
        }}
      />
    )
  }

  getEnvironment = (): JSX.Element | null => {
    const { match, stackDeployment } = this.props
    const isEce = getConfigForKey(`APP_PLATFORM`) === 'ece'

    const local = (
      <FormattedMessage id='deployment-header.trust.environment.local' defaultMessage='Local' />
    )
    const yourOrg = (
      <FormattedMessage
        id='deployment-header.trust.environment.your-org'
        defaultMessage='Your organization'
      />
    )
    const ess = (
      <FormattedMessage
        id='deployment-header.trust.environment.ESS'
        defaultMessage='Elastic Cloud'
      />
    )
    const ece = (
      <FormattedMessage
        id='deployment-header.trust.environment.ECE'
        defaultMessage='Elastic Cloud Enterprise'
      />
    )
    const selfManaged = (
      <FormattedMessage
        id='deployment-header.trust.environment.selfManaged'
        defaultMessage='Self-managed Elasticsearch'
      />
    )

    if (this.getRelationshipTypeFromUrl() === 'accounts') {
      if (isEce) {
        return local
      }

      return getOrganizationId({ deployment: stackDeployment! }) ===
        match.params.trustRelationshipId
        ? yourOrg
        : ess
    }

    if (this.getRelationshipTypeFromUrl() === 'external') {
      return ece
    }

    const targetEnvironmentType = getTargetEnvironmentType(this.getTrustRelationshipUnderEdit())

    switch (targetEnvironmentType) {
      case 'ess':
        return ess
      case 'ece':
        return ece
      case 'self-managed':
        return selfManaged
      default:
        return null // sanity
    }
  }

  getRelationshipTypeFromUrl = (): keyof ElasticsearchClusterTrustSettings | null => {
    const { match } = this.props

    if (match.url.includes('security/trust/accounts')) {
      return 'accounts'
    }

    if (match.url.includes('security/trust/external')) {
      return 'external'
    }

    if (match.url.includes('security/trust/direct')) {
      return 'direct'
    }

    return null
  }

  getTrustRelationshipUnderEdit = (): AnyTrustRelationship | null => {
    const {
      stackDeployment,
      match: {
        params: { trustRelationshipId },
      },
    } = this.props

    if (!trustRelationshipId) {
      return null
    }

    const trustRelationshipType = this.getRelationshipTypeFromUrl()

    if (!trustRelationshipType) {
      return null // sanity
    }

    return getTrustRelationshipFromDeployment({
      deployment: stackDeployment!,
      trustRelationshipType,
      trustRelationshipId: trustRelationshipId || '',
    })
  }
}

export default DeploymentTrustRelationshipHeader
