/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { type FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CuiRouterLinkButton } from '@modules/cui/RouterButton'
import { CuiThemedIcon } from '@modules/cui/ThemedIcon'
import Feature from '@modules/utils/feature'

import DocLink from '../../../../components/DocLink'
import { isFeatureActivated } from '../../../../store'
import { trafficFiltersUrl, trustManagementUrl, apiKeysUrl } from '../../urls'
import { deploymentExtensionsUrl } from '../../../../lib/urlBuilder'
import lock from '../../../../files/cloud-lock-white.svg'
import lockDark from '../../../../files/cloud-lock-dark.svg'

import DeploymentFeatureItem from './DeploymentFeatureItem'
import DeploymentFeaturesHeader from './DeploymentFeaturesHeader'

import type { DocLinkKey } from '../../../../components/DocLink'

/** @internal **/
const LearnMoreLink: FunctionComponent<{
  link: DocLinkKey
  context: string
}> = ({ link, context }) => {
  const { formatMessage } = useIntl()

  const docLinkAriaLabel = formatMessage(
    {
      id: 'deployment-features.learn-more.link.ariaLabel',
      defaultMessage: 'Learn more about {context}',
    },
    {
      context,
    },
  )

  return (
    <span style={{ textAlign: 'center' }}>
      <DocLink link={link} aria-label={docLinkAriaLabel}>
        <FormattedMessage id='deployment-features.learn-more.link' defaultMessage='Learn more' />
      </DocLink>
    </span>
  )
}

const DeploymentFeatures: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  const trafficFiltersLabel = formatMessage({
    id: 'deployment-features.traffic-filtering.title',
    defaultMessage: 'Traffic filters',
  })

  const apiKeysLabel = formatMessage({
    id: 'deployment-features.api-keys.title',
    defaultMessage: 'API keys',
  })

  const extensionsLabel = formatMessage({
    id: 'deployment-features.extensions.title',
    defaultMessage: 'Extensions',
  })

  return (
    <div>
      <DeploymentFeaturesHeader />

      <EuiSpacer size='m' />

      <EuiPanel color='subdued' paddingSize='l' className='panel-with-border'>
        <EuiFlexGroup alignItems='center' gutterSize='none'>
          <EuiFlexItem>
            <EuiTitle>
              <h2>{trafficFiltersLabel}</h2>
            </EuiTitle>

            <EuiSpacer size='m' />

            <EuiText color='subdued'>
              <FormattedMessage
                id='deployment-features.traffic-filtering.intro'
                defaultMessage='Control access to your deployments.'
              />

              <EuiSpacer size='m' />

              <FormattedMessage
                id='deployment-features.traffic-filtering-p1'
                defaultMessage='You can protect deployments from unwanted traffic by only allowing connections from specific IP addresses, IP ranges, or by setting up a VPC.'
              />
            </EuiText>

            <EuiSpacer size='l' />

            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem grow={false}>
                <CuiRouterLinkButton to={trafficFiltersUrl()} fill={true}>
                  <FormattedMessage
                    id='traffic-filtering.link'
                    defaultMessage='Add traffic filters'
                  />
                </CuiRouterLinkButton>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <LearnMoreLink
                  link='configureDeploymenTrafficFilters'
                  context={trafficFiltersLabel}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem>
            <CuiThemedIcon
              size='original'
              lightType={lock}
              darkType={lockDark}
              width={275}
              css={css({ margin: 'auto' })}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>

      <EuiSpacer size='xl' />

      <EuiFlexGroup>
        <EuiFlexItem>
          <DeploymentFeatureItem
            title={apiKeysLabel}
            description={
              <FormattedMessage
                id='deployment-features.api-keys.description'
                defaultMessage='Create keys for the RESTful API to provide access to deployment CRUD actions. {learnMore}'
                values={{
                  learnMore: <LearnMoreLink link='apiKeysDocLink' context={apiKeysLabel} />,
                }}
              />
            }
            linkButton={{
              href: apiKeysUrl(),
              text: (
                <FormattedMessage
                  id='deployment-features.api-keys.add-keys-btn'
                  defaultMessage='Add API keys'
                />
              ),
            }}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <DeploymentFeatureItem
            title={extensionsLabel}
            description={
              <FormattedMessage
                id='deployment-features.extensions.description'
                defaultMessage='Upload a custom bundle or script to extend the functionality of the deployment. {learnMore}'
                values={{
                  learnMore: (
                    <LearnMoreLink link='officialPluginsDocLink' context={extensionsLabel} />
                  ),
                }}
              />
            }
            linkButton={{
              href: deploymentExtensionsUrl(),
              text: (
                <FormattedMessage
                  id='deployment-features.api-keys.add-extensions-btn'
                  defaultMessage='Add extensions'
                />
              ),
            }}
          />
        </EuiFlexItem>

        {isFeatureActivated(Feature.crossEnvCCSCCR) && (
          <EuiFlexItem>
            <DeploymentFeatureItem
              title={
                <FormattedMessage id='deployment-features.trust.title' defaultMessage='Trust' />
              }
              description={
                <FormattedMessage
                  id='deployment-features.trust.description'
                  defaultMessage='Set up trust relationships between deployments and environments for cross-cluster search and replication.'
                />
              }
              linkButton={{
                href: trustManagementUrl(),
                text: (
                  <FormattedMessage
                    id='deployment-features.api-keys.setup-trust-btn'
                    defaultMessage='Set up trust'
                  />
                ),
              }}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  )
}

export default DeploymentFeatures
