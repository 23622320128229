/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  createInstanceConfigurationUrl,
  deleteInstanceConfigurationUrl,
  getInstanceConfigurationsUrl,
  getInstanceConfigurationUrl,
} from '@modules/cloud-api/v1/urls'
import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'
import history from '@modules/utils/history'

import {
  CREATE_INSTANCE_CONFIGURATION,
  DELETE_INSTANCE_CONFIGURATION,
  FETCH_INSTANCE_CONFIGURATION,
  FETCH_INSTANCE_CONFIGURATIONS,
} from '../../constants/actions'
import {
  topologyInstanceConfigurationsUrl,
  topologyViewInstanceConfigurationUrl,
} from '../../lib/urlBuilder'
import asyncRequest from '../asyncRequests'

export function fetchInstanceConfiguration(
  regionId: RegionId,
  id: string,
  { showMaxZones = false }: { showMaxZones?: boolean } = {},
  { configVersion }: { configVersion?: number } = {},
) {
  const url =
    configVersion != null
      ? getInstanceConfigurationUrl({
          regionId,
          id,
          showMaxZones,
          showDeleted: true,
          configVersion,
        })
      : getInstanceConfigurationUrl({ regionId, id, showMaxZones, showDeleted: true })

  return asyncRequest({
    type: FETCH_INSTANCE_CONFIGURATION,
    method: `GET`,
    url,
    meta: { regionId, id },
    crumbs: [regionId, id],
  })
}

export function fetchInstanceConfigurationIfNeeded(
  regionId: RegionId,
  id: string,
  options: { showMaxZones?: boolean } = {},
) {
  return (dispatch, getState) => {
    const state = getState().instanceConfigurations
    const exists = state?.[regionId]?.[id] != null

    if (exists) {
      return Promise.resolve()
    }

    return dispatch(fetchInstanceConfiguration(regionId, id, options))
  }
}

export function fetchInstanceConfigurations(
  regionId: RegionId,
  { showMaxZones = false }: { showMaxZones?: boolean } = {},
) {
  const url = getInstanceConfigurationsUrl({ regionId, showMaxZones, showDeleted: true })

  return asyncRequest({
    type: FETCH_INSTANCE_CONFIGURATIONS,
    method: `GET`,
    url,
    meta: { regionId },
    crumbs: [regionId],
  })
}

export function fetchInstanceConfigurationsIfNeeded(
  regionId: RegionId,
  options: { showMaxZones?: boolean } = {},
) {
  return (dispatch, getState) => {
    const shouldFetch = getState().instanceConfigurations[regionId] == null

    // IF we require max zones, we fetch as we can't gaurantee the prev request had them
    if (shouldFetch || options.showMaxZones) {
      return dispatch(fetchInstanceConfigurations(regionId, options))
    }

    return Promise.resolve()
  }
}

export function createInstanceConfiguration(
  regionId: RegionId,
  instanceConfiguration: InstanceConfiguration,
) {
  return (dispatch) => {
    const url = createInstanceConfigurationUrl({ regionId })

    return dispatch(
      asyncRequest({
        method: `POST`,
        type: CREATE_INSTANCE_CONFIGURATION,
        url,
        payload: instanceConfiguration,
        meta: { regionId, selfUrl: url },
        crumbs: [regionId],
      }),
    ).then((response) => {
      // See the instance configuration you just created.
      const {
        payload: { id: instanceId },
      } = response
      return history.push(topologyViewInstanceConfigurationUrl(regionId, instanceId))
    })
  }
}

export function deleteInstanceConfiguration(regionId: RegionId, id: string) {
  return (dispatch) => {
    const url = deleteInstanceConfigurationUrl({ id, regionId })

    return dispatch(
      asyncRequest({
        method: `DELETE`,
        type: DELETE_INSTANCE_CONFIGURATION,
        url,
        meta: { regionId, id },
        crumbs: [regionId, id],
      }),
    ).then(() => history.push(topologyInstanceConfigurationsUrl(regionId)))
  }
}
