/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge } from '@elastic/eui'

import type { FunctionComponent } from 'react'

type Props = {
  oldVersion: string
  currentVersion: string
  icId: string
  tierId: string
}

const InstanceConfigurationChange: FunctionComponent<Props> = ({
  oldVersion,
  currentVersion,
  icId,
  tierId,
}) => {
  const currentVersionBadge = <EuiBadge color='hollow'>{currentVersion}</EuiBadge>

  const oldVersionBadge = (
    <EuiBadge color='hollow'>
      <del>{oldVersion}</del>
    </EuiBadge>
  )

  const tierIdBadge = <EuiBadge color='hollow'>{tierId}</EuiBadge>

  const icIdBadge = <EuiBadge color='hollow'>{icId}</EuiBadge>

  const explainIcVersionChange = (
    <FormattedMessage
      id='explain-changes.change-ic-version'
      defaultMessage='Migrate {icIdBadge} for {tierIdBadge} from version {oldVersionBadge} to {currentVersionBadge}'
      values={{
        tierIdBadge,
        icIdBadge,
        oldVersionBadge,
        currentVersionBadge,
      }}
    />
  )

  return explainIcVersionChange
}

export default InstanceConfigurationChange
