/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'url'

import jwtDecode from 'jwt-decode'
import { omit } from 'lodash'
import { parse as parseQuery, stringify } from 'query-string'

import { loginUrl } from '@modules/auth/urls'
import type { SaasAuthResponse, SaasUserResponse } from '@modules/cloud-api/v1/types'
import type { OrganizationSsoInitUrlParams } from '@modules/cloud-api/v1/urls'
import {
  challengeSaasCurrentUserMfaFactorUrl,
  getSaasCurrentUserUrl,
  logoutSaasCurrentUserUrl,
  logoutUrl,
  methodsUrl,
  organizationSsoCallbackUrl,
  organizationSsoInitUrl,
  refreshSaasCurrentUserUrl,
  refreshTokenUrl,
  saasOpenidCallbackUrl,
  verifySaasCurrentUserMfaFactorUrl,
} from '@modules/cloud-api/v1/urls'
import type { MfaEnforcementRedirectToState } from '@modules/mfa-enforcement/MfaEnforcement'
import { invalidateGetUserProfileQuery } from '@modules/profile-lib/hooks'
import type { CloudAppConfig } from '@modules/ui-types'
import { post } from '@modules/utils/ajax'
import { captureApmError } from '@modules/utils/apm'
import Feature from '@modules/utils/feature'
import history from '@modules/utils/history'
import { rootUrl } from '@modules/utils/rootUrls'

import { applyGoogleTrackingV4ForLogin } from '@/apps/userconsole/lib/googleTracking'
import { setUpMfaUrl } from '@/apps/userconsole/urls'
import {
  AUTH_WITH_OPEN_ID,
  CHALLENGE_CURRENT_USER_MFA_FACTOR,
  EXCHANGE_ORGANIZATION_SSO,
  FETCH_AUTH_METHODS,
  FETCH_JWT_TOKEN,
  FETCH_SAAS_USER,
  INITIATE_ORGANIZATION_SSO,
  LOG_IN,
  LOG_OUT,
  REQUIRE_MFA,
  SAVE_AUTH_EXPIRATION,
  VERIFY_CURRENT_USER_MFA_FACTOR,
} from '@/constants/actions'
import { getCookie, setCookie } from '@/lib/cookies'
import { getMarketoTrackingParamsFromCookies } from '@/lib/marketo'
import { reqIdFactory } from '@/lib/reqId'
import { getConfigForKey, isFeatureActivated } from '@/selectors'
import { getRequiredConfig } from '@/store/store'

import getRandomValues from '../../lib/randomValues'
import { getConfigForKey as getStaticConfigForKey } from '../../store'
import asyncRequest, { asyncRequestActions, resetAsyncRequest } from '../asyncRequests'

import type { PyconsoleUserAuthResponse } from '@/actions/auth/types'
import type { MarketoParamsType, RegistrationSource } from '@/lib/urlUtils'
import type { ReduxState, ThunkAction, ThunkDispatch } from '@/types/redux'

interface TokenData {
  okta_session_id: string
}

export interface OpenIdLoginArgs extends MarketoParamsType {
  fromURI?: string
  settings?: string
  source?: RegistrationSource
  referrer: string
}

type ConfigKey = Feature | keyof CloudAppConfig

interface OpenIdConfigs {
  oktaClientIdKey: ConfigKey
  oktaIdpKey: ConfigKey
}

interface LoginArgs {
  oktaRedirectUrl?: string
  redirectTo?: string
  credentials: { email: string; password: string }
}

export interface AuthorizeOauthTokenParams {
  state: string | null
  code: string | null
  idp_id: string | null
  settings?: string
}

const ALLOWED_REDIRECT_DOMAINS = [
  window.location.hostname,
  'elastic.co',
  'foundit.no',
  'found.no',
  'elstc.co',
]

const oktaSessionRedirect = ({ oktaSessionId, redirectTo }) => {
  const oktaBaseUrl = getRequiredConfig(`OKTA_URL`)
  const oktaPath = `/login/sessionCookieRedirect?`
  const redirectUrl = createOktaRedirectUrl(redirectTo)

  const oktaQuery = stringify({
    token: oktaSessionId,
    redirectUrl,
  })

  const oktaURL = `${oktaBaseUrl}${oktaPath}${oktaQuery}`

  if (redirectTo) {
    const { hostname } = parse(redirectTo)

    // when redirectTo is not a relative path and contains a hostname, enforce MFA before the final redirect
    if (hostname !== null) {
      history.push(setUpMfaUrl(), <MfaEnforcementRedirectToState>{ redirectTo: oktaURL })
      return
    }
  }

  window.location.replace(oktaURL)
}

const createOktaRedirectUrl = (redirectTo?: string) => {
  if (redirectTo && parse(redirectTo).protocol) {
    return redirectTo
  }

  const { origin, protocol, hostname, port } = window.location
  const redirectUrl = origin || `${protocol}//${hostname}${port ? `:${port}` : ``}`
  return redirectTo ? `${redirectUrl}${redirectTo}` : redirectUrl
}

const handleOktaSessionRedirect = (
  dispatch,
  { token, okta_session_id, redirectTo, sessionExpirationTime },
) => {
  if (token) {
    dispatch(saveAuthExpiration(sessionExpirationTime))
    const decoded = jwtDecode<TokenData>(token)
    oktaSessionRedirect({ oktaSessionId: decoded.okta_session_id, redirectTo })
  } else if (okta_session_id) {
    dispatch(saveAuthExpiration(sessionExpirationTime))
    oktaSessionRedirect({ oktaSessionId: okta_session_id, redirectTo })
  }
}

const handleLoginRedirect = (dispatch, { redirectTo, sessionExpirationTime }) => {
  if (sessionExpirationTime) {
    dispatch(saveAuthExpiration(sessionExpirationTime))
    dispatch(redirectAfterLogin(redirectTo))
  }
}

export function checkForOktaAndHandleLogin({
  token,
  okta_session_id,
  session_expiration_time,
  email_verified,
  require_email_verification,
  redirectTo,
  oktaRedirectUrl,
}: Pick<
  SaasAuthResponse,
  | 'token'
  | 'okta_session_id'
  | 'session_expiration_time'
  | 'require_email_verification'
  | 'email_verified'
> & {
  redirectTo?: string
  oktaRedirectUrl?: string
}): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const oktaAuthenticationEnabled = isFeatureActivated(state, Feature.oktaAuthenticationEnabled)

    if (require_email_verification && !email_verified) {
      return Promise.resolve({ isUnverifiedUser: true })
    }

    if (oktaAuthenticationEnabled) {
      // In most use cases, a token would be returned
      // However @elastic.co users need to be verified to authenticate on ESS

      if (!token && okta_session_id && !oktaRedirectUrl) {
        return Promise.resolve({ isUnverifiedUser: true })
      }

      handleOktaSessionRedirect(dispatch, {
        token,
        okta_session_id,
        redirectTo: oktaRedirectUrl || redirectTo,
        sessionExpirationTime: session_expiration_time,
      })
    } else {
      handleLoginRedirect(dispatch, { redirectTo, sessionExpirationTime: session_expiration_time })
    }

    return Promise.resolve(null)
  }
}

const createRedirectPath = (path) =>
  path == null || parse(path).pathname === loginUrl() ? rootUrl() : path

export const redirectAfterLogin = (newPath) => () => {
  if (newPath) {
    const { hostname } = parse(newPath)

    // when newPath is not a relative path and contains a hostname, enforce MFA before the final redirect
    if (hostname !== null && ALLOWED_REDIRECT_DOMAINS.some((domain) => hostname.endsWith(domain))) {
      history.push(setUpMfaUrl(), <MfaEnforcementRedirectToState>{ redirectTo: newPath })
      return
    }
  }

  return history.replace(createRedirectPath(newPath))
}

export function saveAuthExpiration(expiration: string) {
  return {
    type: SAVE_AUTH_EXPIRATION,
    meta: {},
    payload: {
      expiration,
    },
  }
}

export function loginAndRedirect({
  oktaRedirectUrl,
  redirectTo,
  credentials,
}: LoginArgs): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const url = getConfigForKey(state, `LOGIN_URL`)

    return dispatch(
      // This weird payload type will go away when we finally dispose of pyconsole
      asyncRequest<typeof LOG_IN, SaasAuthResponse>({
        type: LOG_IN,
        method: `POST`,
        url,
        payload: credentials,
      }),
    ).then(({ payload = {} }) => {
      const {
        token,
        mfa_required,
        okta_session_id,
        user_id,
        session_expiration_time,
        require_email_verification,
        email_verified,
      } = payload
      const { email } = credentials
      invalidateGetUserProfileQuery()

      const googleTrackingIdV4Enabled = getConfigForKey(state, `GOOGLE_ANALYTICS_TRACKING_ID_V4`)

      if (googleTrackingIdV4Enabled) {
        const userId = String(user_id)
        applyGoogleTrackingV4ForLogin({ email, userId })
      }

      if (mfa_required) {
        dispatch({
          type: REQUIRE_MFA,
          meta: {},
          payload: {
            state_id: payload.okta_state_id,
            mfa_required: payload.mfa_required,
            mfa_devices: payload.mfa_devices,
          },
        })
      } else {
        return checkForOktaAndHandleLogin({
          token,
          okta_session_id,
          session_expiration_time,
          require_email_verification,
          email_verified,
          redirectTo,
          oktaRedirectUrl,
        })(dispatch, getState)
      }

      return payload
    })

    // Don't allow rejected promises to propagate. They have
    // a similar effect to uncaught exceptions in the integration tests.
    // .catch(noop)
  }
}

export const resetLoginRequest = () => resetAsyncRequest(LOG_IN)

export function challengeSaasCurrentUserMfaFactor({ device_id }: { device_id: string }) {
  const url = challengeSaasCurrentUserMfaFactorUrl({ deviceId: device_id })

  return asyncRequest({
    type: CHALLENGE_CURRENT_USER_MFA_FACTOR,
    method: `POST`,
    url,
    payload: {},
  })
}

export function verifySaasCurrentUserMfaFactor({
  device_id,
  pass_code,
}: {
  device_id: string
  pass_code: string
}) {
  const url = verifySaasCurrentUserMfaFactorUrl({ deviceId: device_id })

  return asyncRequest<typeof VERIFY_CURRENT_USER_MFA_FACTOR, SaasAuthResponse>({
    type: VERIFY_CURRENT_USER_MFA_FACTOR,
    method: `POST`,
    url,
    payload: { pass_code },
  })
}

export const resetVerifySaasCurrentUserMfaFactorRequest = () =>
  resetAsyncRequest(VERIFY_CURRENT_USER_MFA_FACTOR)

const clearAuthToken = () => ({
  type: LOG_OUT,
})

export function logout({
  fromURI,
  redirectTo,
  invalidateIdPSession,
}: {
  fromURI?: string
  redirectTo?: string
  invalidateIdPSession?: boolean
}) {
  return (dispatch, getState) => {
    const state = getState()
    const isAnyAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`
    const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
    const loggedOutUrl = isHeroku ? rootUrl() : getLoginUrl()

    /* We dispatch the browser logout action after we send the server logout request.
     * Logging out from the browser clears the auth token in Redux,
     * but that token is needed for the server logout request.
     */
    return serverLogout().then(browserLogout, browserLogoutAfterError)

    function serverLogout() {
      // On any adminconsole's side, just calling the server logout endpoint is enough
      if (isAnyAdminconsole) {
        return post(logoutUrl())
      }

      return post(logoutSaasCurrentUserUrl())
    }

    function browserLogoutAfterError(serverLogoutError) {
      captureApmError(serverLogoutError)
      browserLogout()
    }

    function browserLogout() {
      dispatch(clearAuthToken())

      /*If users are coming from Okta (there's a fromURI parameter),
       * and we can assume Okta has already checked and there wasn't a SSO session cookie for them.
       * So we just logout and redirect to logged out url with the fromURI parameter
       */
      if (
        isFeatureActivated(state, Feature.oktaAuthenticationEnabled) &&
        !fromURI &&
        invalidateIdPSession
      ) {
        logoutThroughOkta()
      } else {
        goToLoggedOutPage()
      }
    }

    function logoutThroughOkta() {
      const oktaBaseUrl = getRequiredConfig(`OKTA_URL`)
      const oktaSignoutQuery = stringify({
        fromURI: createOktaRedirectUrl(loggedOutUrl),
      })
      const oktaSignoutUrl = `${oktaBaseUrl}/login/signout?${oktaSignoutQuery}`

      window.location.replace(oktaSignoutUrl)
    }

    function goToLoggedOutPage() {
      // Reload to ensure the redux state is cleared
      window.location.replace(getLoggedOutUrl())
    }

    function getLoggedOutUrl() {
      if (!fromURI) {
        return loggedOutUrl
      }

      const query = stringify({ fromURI })
      return `${loggedOutUrl}?${query}`
    }

    function getLoginUrl() {
      if (!redirectTo) {
        return loginUrl()
      }

      const query = stringify({ redirectTo })
      return `${loginUrl()}?${query}`
    }
  }
}

export function refreshToken() {
  return (dispatch: ThunkDispatch, getState: () => ReduxState) => {
    const isUserconsole = getConfigForKey(getState(), `APP_NAME`) === `userconsole`
    const url = isUserconsole ? refreshSaasCurrentUserUrl() : refreshTokenUrl()

    return post(url).then(({ body: { session_expiration_time } }) => {
      dispatch(saveAuthExpiration(session_expiration_time))
    })
  }
}

export function fetchAuthMethods() {
  return asyncRequest({
    type: FETCH_AUTH_METHODS,
    method: `GET`,
    url: methodsUrl(),
  })
}

export function loginWithGoogle(
  openIdLoginArgs: OpenIdLoginArgs,
): (dispatch: any, getState: () => ReduxState) => void {
  return loginWithOpenId(openIdLoginArgs, {
    oktaClientIdKey: 'OKTA_GOOGLE_CLIENT_ID',
    oktaIdpKey: 'OKTA_GOOGLE_IDP',
  })
}

export function loginWithAzure(
  openIdLoginArgs: OpenIdLoginArgs,
): (dispatch: any, getState: () => ReduxState) => void {
  return loginWithOpenId(openIdLoginArgs, {
    oktaClientIdKey: 'OKTA_AZURE_CLIENT_ID',
    oktaIdpKey: 'OKTA_AZURE_IDP',
  })
}

export function extractIdpFromCookie(): string | null {
  const cookieState = getCookie('openIdState')

  if (!cookieState) {
    return null
  }

  const { oktaIdpKey } = parseQuery(cookieState)

  if (!oktaIdpKey) {
    return null
  }

  return getStaticConfigForKey(oktaIdpKey as ConfigKey)
}

function loginWithOpenId(
  openIdLoginArgs: OpenIdLoginArgs,
  { oktaClientIdKey, oktaIdpKey }: OpenIdConfigs,
) {
  return (dispatch) => {
    const oktaBaseUrl = getRequiredConfig(`OKTA_URL`)
    const clientId = getRequiredConfig(oktaClientIdKey)
    const idp = getRequiredConfig(oktaIdpKey)

    const domain = createOktaRedirectUrl()
    const nonceState = getRandomValues()

    const cookie = stringify({
      oktaIdpKey,
      nonceState,
      ...openIdLoginArgs,
    })

    const url = createOpenIdRedirectUrl({
      idp,
      clientId,
      oktaBaseUrl,
      domain,
      cookie,
    })

    dispatch(startAuthWithOpenId(cookie))
    window.location.assign(url)
  }
}

export function initiateOrganizationSso(params: OrganizationSsoInitUrlParams) {
  const url = organizationSsoInitUrl(params)

  return (dispatch) => {
    dispatch(
      asyncRequest({
        type: INITIATE_ORGANIZATION_SSO,
        method: `GET`,
        url,
        requestSettings: {
          request: {
            redirect: 'manual',
          },
        },
      }),
    )
  }
}

export const resetInitiateOrganizationSsoRequest = () =>
  resetAsyncRequest(INITIATE_ORGANIZATION_SSO)

export function initiateOrganizationSsoRedirect(params: OrganizationSsoInitUrlParams) {
  const url = organizationSsoInitUrl(params)
  window.location.assign(url)
}

export function exchangeIdToken(
  idToken: string,
  redirectTo: string,
  hasAcceptedTermsAndPolicies: boolean,
) {
  return (dispatch, getState) => {
    dispatch(
      asyncRequest({
        type: EXCHANGE_ORGANIZATION_SSO,
        method: `POST`,
        url: organizationSsoCallbackUrl(),
        payload: {
          id_token: idToken,
          has_accepted_terms_and_policies: hasAcceptedTermsAndPolicies,
        },
      }),
    ).then((response) => {
      const sessionExpirationTime = response.payload?.session_expiration_time

      return configureGoogleAnalytics(dispatch, getState).then(() => {
        handleLoginRedirect(dispatch, { redirectTo, sessionExpirationTime })
      })
    })
  }
}

function configureGoogleAnalytics(dispatch, getState): Promise<SaasUserResponse> {
  return dispatch(
    asyncRequest<typeof FETCH_SAAS_USER, SaasUserResponse>({
      type: FETCH_SAAS_USER,
      method: `GET`,
      url: getSaasCurrentUserUrl(),
    }),
  ).then((fetchSaasUserResponse) => {
    const googleTrackingIdV4Enabled = getConfigForKey(getState(), `GOOGLE_ANALYTICS_TRACKING_ID_V4`)

    if (googleTrackingIdV4Enabled && fetchSaasUserResponse.payload) {
      const { user_id, email } = fetchSaasUserResponse.payload.user

      if (user_id) {
        applyGoogleTrackingV4ForLogin({ email, userId: user_id.toString() })
      }
    }

    return fetchSaasUserResponse
  })
}

export const resetExchangeIdTokenRequest = () => resetAsyncRequest(EXCHANGE_ORGANIZATION_SSO)

function createOpenIdRedirectUrl({
  idp,
  clientId,
  domain,
  oktaBaseUrl,
  cookie,
}: {
  idp: string
  clientId: string
  domain: string
  oktaBaseUrl: string
  cookie: string
}) {
  const oktaPath = `/oauth2/default/v1/authorize`

  const queryString = stringify({
    idp,
    client_id: clientId,
    response_type: `code`,
    response_mode: `fragment`,
    scope: `openid email profile`,
    redirect_uri: `${domain}/login/oauth`,
    state: cookie,
  })

  return `${oktaBaseUrl}${oktaPath}?${queryString}`
}

const startAuthWithOpenId = (state: string) => {
  setCookie('openIdState', state, {
    settings: {
      sameSite: `Lax`,
    },
  })

  return {
    type: AUTH_WITH_OPEN_ID,
  }
}

export function authorizeSaasOauthToken(args: AuthorizeOauthTokenParams): ThunkAction | undefined {
  const { state, idp_id, code } = args

  if (!state || !code) {
    return
  }

  const query = parseQuery(state)

  const { nonceState: stateFromUrl } = query
  const cookieState = getCookie('openIdState')

  if (!cookieState) {
    return invalidState()
  }

  const { nonceState, fromURI, source, settings, rawOktaIdpKey, ...rest } = parseQuery(cookieState)
  const oktaIdpKey = Array.isArray(rawOktaIdpKey) ? rawOktaIdpKey[0] : rawOktaIdpKey
  const trackingData = oktaIdpKey ? omit(rest, oktaIdpKey) : rest

  if (stateFromUrl !== nonceState) {
    return invalidState()
  }

  const url = saasOpenidCallbackUrl()

  const reqPayload = {
    idp_id,
    code,
    redirect_uri: createOktaRedirectUrl(`/login/oauth`),
    ...(source ? { source } : {}),
    ...(settings ? { settings } : {}),
    tracking_data: {
      ...trackingData,
      ...getMarketoTrackingParamsFromCookies(),
    },
  }

  return (dispatch, getState) =>
    dispatch(
      asyncRequest<typeof FETCH_JWT_TOKEN, PyconsoleUserAuthResponse & SaasAuthResponse>({
        type: FETCH_JWT_TOKEN,
        method: `POST`,
        payload: reqPayload,
        url,
      }),
    ).then((response) => {
      const sessionExpirationTime = response.payload?.session_expiration_time

      return configureGoogleAnalytics(dispatch, getState).then(() => {
        handleLoginRedirect(dispatch, { redirectTo: fromURI, sessionExpirationTime })
      })
    })

  function invalidState() {
    const { failed } = asyncRequestActions({
      type: FETCH_JWT_TOKEN,
      reqId: reqIdFactory(FETCH_JWT_TOKEN)(),
    })

    return (dispatch) => dispatch(failed('Incorrect state'))
  }
}

export const resetAuthorizeSaasOauthTokenRequest = () => resetAsyncRequest(FETCH_JWT_TOKEN)
