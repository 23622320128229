/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
  EuiButtonEmpty,
} from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import { CuiTimeAgo } from '@modules/cui/TimeAgo'
import { CuiLink } from '@modules/cui/Link'
import { CuiAlert } from '@modules/cui/Alert'
import type { CuiTableColumn } from '@modules/cui/Table'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import { userOverviewUrl } from '@modules/urls/users'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import RoleBadges from '@/components/Organization/RoleBadges'
import { fetchOrganizationMemberships } from '@/actions/organizations'

import OrganizationMemberStatus from './OrganizationMemberStatus'
import { hasSameEmail } from './lib'
import OrganizationMemberRoleAssignments from './OrganizationMemberRoleAssignments'
import AddMemberButton from './AddMemberButton'
import OrganizationMemberActions from './OrganizationMemberActions'

import type { AllProps as Props, State, OrganizationMemberRow } from './types'
import type { ReactElement, ReactNode } from 'react'

class OrganizationMembers extends Component<Props, State> {
  state: State = {
    memberToEditRoleAssignments: null,
  }

  componentDidMount(): void {
    this.props.searchAllOrganizationDeployments()
  }

  render(): ReactElement {
    const { error, isLoading, organizationMemberRows } = this.props

    if (error) {
      return (
        <CuiAlert data-test-id='organization-members-error' type='error'>
          {error}
        </CuiAlert>
      )
    }

    const { columns, initialSort } = this.getGridColumns()

    return (
      <EuiPanel>
        {this.renderPanelTitle(organizationMemberRows, isLoading)}
        {this.renderMemberRoleAssignments()}

        <EuiSpacer />

        <PrivacySensitiveContainer>
          <CuiTable<OrganizationMemberRow>
            getRowId={({ email }) => email}
            rows={organizationMemberRows}
            columns={columns}
            initialLoading={isLoading}
            initialSort={initialSort}
            data-test-id='organization-members-table'
          />
        </PrivacySensitiveContainer>
      </EuiPanel>
    )
  }

  renderPanelTitle(
    organizationMemberRows: OrganizationMemberRow[],
    isLoading: boolean,
  ): ReactElement {
    const { organization, isAdminconsole } = this.props
    const { length: membersCount } = organizationMemberRows

    const title = isLoading ? (
      <div style={{ maxWidth: '250px' }}>
        <EuiSkeletonText lines={1} />
      </div>
    ) : (
      <FormattedMessage
        id='organization.organization-members.x-members'
        defaultMessage='{membersCount, plural, =0 {Members} one {# member} other {# members}}'
        values={{ membersCount }}
      />
    )

    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem grow={9}>
              <EuiTitle size='m' data-test-id='organization-members-title'>
                <h2>{title}</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              {!isLoading && isAdminconsole && organization && (
                <AddMemberButton organizationId={organization.id} />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderMemberRoleAssignments() {
    if (!this.props.organization || this.state.memberToEditRoleAssignments === null) {
      return null
    }

    return (
      <OrganizationMemberRoleAssignments
        organizationId={this.props.organization.id}
        member={this.state.memberToEditRoleAssignments}
        onClose={this.closeRoleAssignments}
        fetchOrganizationMembers={fetchOrganizationMemberships}
      />
    )
  }

  renderMemberName(organizationMemberRow: OrganizationMemberRow): ReactNode {
    const { profile, isAdminconsole, organizationId } = this.props
    const { id: userId, name, email, isInvitation } = organizationMemberRow

    const EditMemberRoleAssignmentsButton = ({ children }) => (
      <PermissionsGate
        permissions={[
          {
            organizationId,
            type: 'role-assignment',
            action: 'update',
          },
        ]}
      >
        {({ hasPermissions }) =>
          hasPermissions ? (
            <EuiButtonEmpty
              flush='left'
              onClick={() => {
                this.setState({
                  memberToEditRoleAssignments: organizationMemberRow,
                })
              }}
            >
              {children}
            </EuiButtonEmpty>
          ) : (
            children
          )
        }
      </PermissionsGate>
    )

    if (hasSameEmail(profile, organizationMemberRow)) {
      return (
        <EditMemberRoleAssignmentsButton>
          <FormattedMessage
            id='organization.organization-members.me'
            defaultMessage='{nameOrEmail} (me)'
            values={{ nameOrEmail: name ?? email }}
            data-test-id='organization.organization-members.me'
          />
        </EditMemberRoleAssignmentsButton>
      )
    }

    if (isInvitation) {
      return <span data-test-id='organization.organization-members.non-clickable'>{email}</span>
    }

    return isAdminconsole ? (
      <CuiLink
        data-test-id='organization.organization-members.clickable'
        to={userOverviewUrl(userId)}
      >
        {email}
      </CuiLink>
    ) : (
      <EditMemberRoleAssignmentsButton data-test-id='organization.organization-members.non-clickable'>
        {name ?? email}
      </EditMemberRoleAssignmentsButton>
    )
  }

  renderRemoveButton(organizationMemberRow: OrganizationMemberRow): ReactNode {
    const { organization, profile } = this.props

    if (!organization) {
      return null
    }

    return (
      <OrganizationMemberActions
        profile={profile}
        organization={organization}
        organizationMemberRow={organizationMemberRow}
        onEditRole={() => {
          this.setState({
            memberToEditRoleAssignments: organizationMemberRow,
          })
        }}
      />
    )
  }

  getGridColumns(): {
    initialSort: CuiTableColumn<OrganizationMemberRow>
    columns: Array<CuiTableColumn<OrganizationMemberRow>>
  } {
    const { organization } = this.props
    const nameColumn: CuiTableColumn<OrganizationMemberRow> = {
      label: (
        <FormattedMessage id='organization.organization-members.member' defaultMessage='Member' />
      ),
      render: (organizationMemberRow) => this.renderMemberName(organizationMemberRow),
      sortKey: ({ name, email }) => name?.toLocaleLowerCase() ?? email.toLocaleLowerCase(),
    }

    return {
      initialSort: nameColumn,
      columns: [
        nameColumn,
        {
          label: (
            <FormattedMessage
              id='organization.organization-members.status'
              defaultMessage='Status'
            />
          ),
          render: (organizationMemberRow) =>
            organization && (
              <OrganizationMemberStatus
                organizationId={organization.id}
                organizationMemberRow={organizationMemberRow}
              />
            ),
          sortKey: `isInvitation`,
        },
        {
          label: (
            <FormattedMessage id='organization.organization-members.roles' defaultMessage='Roles' />
          ),
          render: (organizationMemberRow) => (
            <RoleBadges roleAssignments={organizationMemberRow.roleAssignments} />
          ),
          width: '30%',
        },
        {
          label: (
            <FormattedMessage id='organization.organization-members.added' defaultMessage='Added' />
          ),
          render: ({ addedDate }) => <CuiTimeAgo date={addedDate} longTime={true} />,
          sortKey: `addedDate`,
          width: '10%',
        },
        {
          actions: true,
          align: 'center',
          label: (
            <FormattedMessage
              id='organization.organization-members.actions'
              defaultMessage='Actions'
            />
          ),
          render: (organizationMemberRow) => this.renderRemoveButton(organizationMemberRow),
          width: '10%',
        },
      ],
    }
  }

  closeRoleAssignments = () => this.setState({ memberToEditRoleAssignments: null })
}

export default OrganizationMembers
