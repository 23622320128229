/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFieldText, EuiForm } from '@elastic/eui'

import type { SaasUserUpdateDiscoveryQuestions } from '@modules/cloud-api/v1/types'
import { StepLayout } from '@modules/cui/Step/StepLayout'

import { ResourceType } from '../../discovery-questions-lib/utils'

import type { FunctionComponent } from 'react'

type Props = {
  fullName: SaasUserUpdateDiscoveryQuestions['full_name'] | undefined
  company: SaasUserUpdateDiscoveryQuestions['company'] | undefined
  onChangeFullName: (value: SaasUserUpdateDiscoveryQuestions['full_name']) => void
  onChangeCompany: (value: SaasUserUpdateDiscoveryQuestions['company']) => void
  resourceType: ResourceType
  setStep: () => void
}
const FullName: FunctionComponent<Props> = ({
  fullName,
  company,
  onChangeFullName,
  onChangeCompany,
  resourceType,
  setStep,
}: Props) => (
  <EuiForm
    onSubmit={(e) => {
      e.preventDefault()
      setStep()
    }}
    component={'form'}
  >
    <StepLayout
      title={
        <FormattedMessage
          id='discover-fullname.title'
          defaultMessage="Let's set up your free trial experience{exclamationPoint}"
          values={{
            exclamationPoint: resourceType === ResourceType.Serverless ? '!' : '',
          }}
        />
      }
      footer={
        <div>
          <EuiButton
            data-test-id='signup.discovery-questions.continue'
            fill={true}
            disabled={!fullName || !company}
            type={'submit'}
          >
            <FormattedMessage id='onboarding-fullname.continue' defaultMessage='Continue' />
          </EuiButton>
        </div>
      }
    >
      <EuiFieldText
        data-test-id='signup.discovery-questions.full-name'
        value={fullName}
        placeholder='Full name'
        onChange={(e) => onChangeFullName(e.target.value)}
        autoFocus={true}
      />

      <EuiFieldText
        data-test-id='signup.discovery-questions.company-name'
        value={company}
        placeholder='Company'
        onChange={(e) => onChangeCompany(e.target.value)}
      />
    </StepLayout>
  </EuiForm>
)

export default FullName
