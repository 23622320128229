/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiText,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { Product } from '@modules/billing-api/v1/types'
import type { SaasUserTrial } from '@modules/cloud-api/v1/types'

import {
  DIMENSION_GROUPS,
  getDimensionGroupTitle,
  getLineItemDescription,
  getLineItemRate,
  getLineItemsTotalEcu,
  getPliMetadataForLineItem,
  getTotalHourlyRate,
  groupLineItemsByDimensionGroup,
} from './lib'
import Total from './Total'

type Props = {
  trial: SaasUserTrial | undefined
  product: Product
}

const ProjectCosts: React.FunctionComponent<Props> = ({ trial, product }) => {
  const htmlId = useGeneratedHtmlId()

  const lineItemsByDimensionGroup = groupLineItemsByDimensionGroup(product.product_line_items)

  return (
    <Fragment>
      <EuiFlexGroup direction='column' gutterSize='m'>
        {DIMENSION_GROUPS.map((dimensionGroup) => {
          const lineItems = lineItemsByDimensionGroup[dimensionGroup]

          if (!lineItems) {
            return null
          }

          return (
            <EuiFlexItem key={dimensionGroup}>
              <EuiFlexGroup
                direction='column'
                gutterSize='none'
                justifyContent='spaceBetween'
                role='row'
              >
                <EuiFlexItem>
                  <EuiFlexGroup justifyContent='spaceBetween'>
                    <EuiFlexItem grow={false}>
                      <EuiText role='cell' size='s'>
                        <strong id={`${htmlId}-${dimensionGroup}`}>
                          <FormattedMessage {...getDimensionGroupTitle(dimensionGroup)} />
                        </strong>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText
                        role='cell'
                        size='s'
                        textAlign='right'
                        aria-describedby={`${htmlId}-${dimensionGroup}`}
                      >
                        <strong>{getLineItemsTotalEcu(lineItems)}</strong>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>

                <EuiFlexItem>
                  {lineItems.map((lineItem, i) => {
                    if (getPliMetadataForLineItem(lineItem)?.hideAsIndividualLine) {
                      return null
                    }

                    return (
                      <EuiFlexGroup key={i} justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                          <EuiText
                            role='cell'
                            size='s'
                            color='subdued'
                            aria-describedby={`${htmlId}-${dimensionGroup}`}
                          >
                            {getLineItemDescription(lineItem)}
                          </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiText
                            role='cell'
                            size='s'
                            color='subdued'
                            textAlign='right'
                            aria-describedby={`${htmlId}-${dimensionGroup}`}
                          >
                            {getLineItemRate(lineItem)}
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )
                  })}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          )
        })}
      </EuiFlexGroup>

      <EuiHorizontalRule margin='m' />

      <Total hourlyRate={getTotalHourlyRate({ trial, product })} />
    </Fragment>
  )
}

export default ProjectCosts
