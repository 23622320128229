/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiBeveledIcon } from '@modules/cui/BeveledIcon'

import SearchField from '@/components/Portal/components/DocumentationTile/SearchField'
import { supportPortalUrl } from '@/apps/userconsole/urls'

import PortalTile from '../PortalTile'
import ExternalLink from '../../../ExternalLink'

import type { FunctionComponent } from 'react'

const SupportTile: FunctionComponent = () => (
  <PortalTile>
    <EuiFlexGroup responsive={false} alignItems='center' gutterSize='s'>
      <EuiFlexItem grow={false}>
        <CuiBeveledIcon type='help' />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiTitle size='xs'>
          <h2>
            <ExternalLink color='text' href={supportPortalUrl}>
              <strong>
                <FormattedMessage id='cloud-portal.support-tile.title' defaultMessage='Support' />
              </strong>
            </ExternalLink>
          </h2>
        </EuiTitle>
      </EuiFlexItem>
    </EuiFlexGroup>

    <EuiSpacer size='l' />

    <SearchField />

    <EuiHorizontalRule margin='l' />

    <EuiText size='s'>
      <FormattedMessage
        id='cloud-portal.support-tile.content.no-cases'
        defaultMessage='New to Elastic? Check out our step-by-step {gettingStartedResources}. For advanced guidance, see our {documentation}.'
        values={{
          gettingStartedResources: (
            <EuiLink
              href='https://www.elastic.co/getting-started#stepbystep-guides'
              target='_blank'
            >
              <FormattedMessage
                id='cloud-portal.support-tile.content.getting-started-resources'
                defaultMessage='getting started resources'
              />
            </EuiLink>
          ),
          documentation: (
            <EuiLink href='https://ela.st/elastic-docs' target='_blank'>
              <FormattedMessage
                id='cloud-portal.support-tile.content.documentation'
                defaultMessage='documentation'
              />
            </EuiLink>
          ),
        }}
      />
    </EuiText>

    <EuiSpacer size='m' />

    <EuiButton size='s' href={supportPortalUrl} target='_blank'>
      <FormattedMessage
        id='cloud-portal.support-tile.contact-support'
        defaultMessage='Contact support'
      />
    </EuiButton>
  </PortalTile>
)

export default SupportTile
