/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiModalHeader,
  EuiModalBody,
  EuiSpacer,
} from '@elastic/eui'

import { isPrepaidConsumptionCustomer } from '@modules/billing-lib/billingDetails/utils'
import { getSubscriptionsWithHourlyRates } from '@modules/billing-lib/utils'

import { externalPricing } from '@/apps/userconsole/urls'
import ExternalLink from '@/components/ExternalLink'

import messages, { billingSubscriptions } from '../messages'

import SubscriptionCard from './SubscriptionCard'

import type { AllProps } from './types'

class SelectSubscriptionBody extends Component<AllProps> {
  componentWillUnmount() {
    const { resetUpdateBillingLevel } = this.props

    resetUpdateBillingLevel()
  }

  render() {
    const {
      intl: { formatMessage },
      activity,
      selectedSubscription,
      onSelectSubscription,
      billingDetails,
      disabledSubscriptions = [],
      usageMode,
    } = this.props

    const subscriptions = billingSubscriptions(formatMessage)
    const subscriptionsWithRates = subscriptions.map((subscription) =>
      getSubscriptionsWithHourlyRates({ subscription, activity }),
    )

    return (
      <Fragment>
        <EuiModalHeader>
          <EuiFlexGroup gutterSize='xs' direction='column'>
            <EuiFlexItem>
              <EuiText>
                <h3>
                  <FormattedMessage {...messages.modalTitle} />
                </h3>
              </EuiText>
            </EuiFlexItem>
            <EuiSpacer size='m' />
            <EuiFlexItem>
              <EuiText color='subdued' size='s'>
                <FormattedMessage
                  {...messages.modalChooseSubscriptionDescription}
                  values={{
                    docLink: (content) => (
                      <ExternalLink showExternalLinkIcon={false} href={externalPricing}>
                        <strong>{content}</strong>
                      </ExternalLink>
                    ),
                  }}
                />
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiSpacer size='xs' />
          <EuiFlexGroup gutterSize='m'>
            {subscriptionsWithRates.map((subscription, i) => (
              <EuiFlexItem key={i}>
                <SubscriptionCard
                  data-test-id={`subscription-card-${subscription.value}`}
                  onClick={() => onSelectSubscription(subscription)}
                  selected={selectedSubscription === subscription.value}
                  subscription={subscription}
                  showPrice={usageMode.mode === 'stateful'}
                  isDisabled={disabledSubscriptions.includes(subscription.value)}
                  isPrepaid={
                    billingDetails.data && isPrepaidConsumptionCustomer(billingDetails.data)
                  }
                />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </EuiModalBody>
      </Fragment>
    )
  }
}

export default injectIntl(SelectSubscriptionBody)
