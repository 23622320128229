/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getByokConfigurationParametersUrl } from '@modules/cloud-api/v1/urls'
import { ByokConfigurationParameters } from '@modules/cloud-api/v1/types'

import { ThunkDispatch } from '@/types/redux'

import { BYOK_TOGGLE_CHANGED, GET_BYOK_CONFIGURATION_PARAMETER } from '../constants/actions'

import asyncRequest from './asyncRequests'

import type { Action } from '@/types/redux'

export function changeByokToggle(value: boolean): Action<typeof BYOK_TOGGLE_CHANGED> {
  return {
    type: BYOK_TOGGLE_CHANGED,
    meta: {
      newValue: value,
    },
  }
}

export function getByokApplicationId(region: string) {
  return (dispatch: ThunkDispatch) => {
    const url = getByokConfigurationParametersUrl({
      region,
    })

    return dispatch(
      asyncRequest<typeof GET_BYOK_CONFIGURATION_PARAMETER, ByokConfigurationParameters>({
        type: GET_BYOK_CONFIGURATION_PARAMETER,
        method: `get`,
        url,
      }),
    )
  }
}
