/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

import { EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiTitle } from '@elastic/eui'

import type { ApiKeyResponse, RoleAssignments } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { asAjaxRequestError } from '@modules/utils/ajax'

import GenerateKeyForm, { GenerateKeyFormFooter } from './GenerateKeyForm'
import GeneratedApiKey from './GeneratedApiKey'
import messages from './messages'
import { diffFromExpirationToToday } from './lib'

import type { ExpirationOption } from './lib'
import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  onCancel: () => void
  onConfirm: () => void
  organizationId: string | undefined
  apiKeys: ApiKeyResponse[]
  generateApiKey: (
    name: string,
    expiration: string | null,
    roleAssignments: RoleAssignments | undefined,
  ) => Promise<any>
  generateKeyRequest: AsyncRequestState
  fetchApiKeys: () => Promise<any>
  defaultExpiration: ExpirationOption
  showRoleAssignments: boolean
  showNeverExpirationOption: boolean
}

type Step = 'create-step' | 'download-step'

type State = {
  apiKey: ApiKeyResponse | null
  authenticated: boolean
  currentStep: Step
  password: string
  authError: string | null
  passwordInvalid: boolean
  invalidKeyName: boolean
  name: string | null
  keyError: JSX.Element[]
  lastStep: boolean
  expiration: string | null
  roleAssignments: RoleAssignments
}

class GenerateKeyModal extends Component<Props, State> {
  mounted = false

  state: State = {
    apiKey: null,
    authenticated: false,
    currentStep: 'create-step',
    password: ``,
    authError: ``,
    passwordInvalid: false,
    invalidKeyName: false,
    name: ``,
    keyError: [],
    lastStep: false,
    roleAssignments: {},
    expiration: diffFromExpirationToToday(this.props.defaultExpiration, moment()),
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const {
      generateKeyRequest,
      defaultExpiration,
      showRoleAssignments,
      showNeverExpirationOption,
      organizationId,
      onCancel,
    } = this.props
    const { currentStep, invalidKeyName, keyError, apiKey, roleAssignments } = this.state

    const createStep = {
      title: messages.createApiKey,
      component: (
        <GenerateKeyForm
          organizationId={organizationId}
          roleAssignments={roleAssignments}
          onChangeName={this.onChangeName}
          onChangeExpiration={this.onChangeExpiration}
          onChangeRoleAssignments={this.onChangeRoleAssignments}
          onNext={this.generateApiKey}
          invalidKeyName={invalidKeyName}
          errors={keyError}
          defaultExpiration={defaultExpiration}
          showRoleAssignments={showRoleAssignments}
          showNeverExpirationOption={showNeverExpirationOption}
        />
      ),
      footer: (
        <GenerateKeyFormFooter
          generateKeyRequest={generateKeyRequest}
          onCancel={onCancel}
          onNext={this.generateApiKey}
          roleAssignments={roleAssignments}
          showRoleAssignments={showRoleAssignments}
        />
      ),
    }

    const step =
      currentStep === 'create-step' || apiKey === null
        ? createStep
        : {
            title: messages.createApiKey,
            component: <GeneratedApiKey apiKey={apiKey} />,
            footer: null,
          }

    const stepTitle =
      typeof step.title === `string` ? step.title : <FormattedMessage {...step.title} />

    return (
      <EuiFlyout maxWidth='57rem' onClose={onCancel}>
        <EuiFlyoutHeader data-test-id='generateKey-modal' hasBorder={true}>
          <EuiTitle>
            <h2>{stepTitle}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>{step.component}</EuiFlyoutBody>
        {step.footer && <EuiFlyoutFooter>{step.footer}</EuiFlyoutFooter>}
      </EuiFlyout>
    )
  }

  onChangeName = (e: React.SyntheticEvent) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeExpiration = (expiration: string | null): void => {
    this.setState({ expiration })
  }

  onChangeRoleAssignments = (roleAssignments) => {
    this.setState({
      roleAssignments,
    })
  }

  generateApiKey = () => {
    const { apiKeys, generateApiKey, fetchApiKeys, showRoleAssignments } = this.props
    const { name, expiration, roleAssignments } = this.state

    const isNameValid =
      apiKeys && apiKeys.length > 0
        ? apiKeys.filter((key) => key.description === name).length === 0
        : true

    if (!name || name.length < 1) {
      this.setState({
        name: null,
        invalidKeyName: true,
        keyError: [
          <FormattedMessage key={messages.keyNameEmptyError.id} {...messages.keyNameEmptyError} />,
        ],
      })
      return
    }

    if (!isNameValid) {
      this.setState({
        name: ``,
        invalidKeyName: true,
        keyError: [<FormattedMessage key={messages.keyNameError.id} {...messages.keyNameError} />],
      })
      return
    }

    generateApiKey(name, expiration, showRoleAssignments ? roleAssignments : undefined)
      .then((response) => {
        if (this.mounted) {
          this.setState({
            apiKey: response.payload,
            name: response.payload.description,
            currentStep: 'download-step',
          })
        }

        // Fetching the latest list of api keys here rather than in the
        // generateApiKey action because we need to response from the
        // generateApiKey action for the last modal step (download and view
        // the new api key)
        fetchApiKeys()
        return
      })
      .catch(() => {
        const { generateKeyRequest } = this.props
        const error = asAjaxRequestError(generateKeyRequest.error)?.body?.errors?.[0]?.message
        this.setState({
          name: ``,
          invalidKeyName: true,
          keyError: [error],
        })
        return
      })
  }
}

export default injectIntl(GenerateKeyModal)
