/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { CloudAppPlatform } from '@modules/ui-types'

import { getConfigForKey } from '@/store'

import ExternalLink from '../ExternalLink'

import type { FunctionComponent } from 'react'
import type { DocLinkProps } from './types'

import platformVersions from '../../config/platformVersions.json'

const { eceVersion } = platformVersions

const EceDocLink: FunctionComponent<DocLinkProps> = ({
  href,
  hash,
  showExternalLinkIcon,
  children,
  'aria-label': ariaLabel,
}) => (
  <ExternalLink
    showExternalLinkIcon={showExternalLinkIcon}
    href={getEceDocLinkUrl({ href, hash })}
    className='docLink'
    aria-label={ariaLabel}
  >
    {children}
  </ExternalLink>
)

function getEceDocLinkUrl({ href, hash }: Pick<DocLinkProps, 'href' | 'hash'>) {
  // ECE version numbers don't apply to the instance of ECE that is the SaaS
  // adminconsole -- so stick with `current` for that.
  const version =
    getConfigForKey<CloudAppPlatform>(`APP_PLATFORM`) === 'saas' ? 'current' : eceVersion

  const baseUrl = `https://www.elastic.co/guide/en/cloud-enterprise/${version}`

  if (href === `index.html`) {
    return `${baseUrl}/${href}`
  }

  const hashToDisplay = hash != null ? `#ece-${hash}` : ``
  return `${baseUrl}/ece-${href}${hashToDisplay}`
}

export default EceDocLink
