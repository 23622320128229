/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { parse } from 'query-string'

import AppLoadingRoot from '../../AppLoadingRoot'

import type { ReactElement } from 'react'
import type { AllProps as Props } from '@/components/Login/OAuthLogin/types'
import type { AuthorizeOauthTokenParams } from '@/actions/auth/auth'

class OAuthLogin extends Component<Props> {
  componentDidMount(): void {
    const { authorizeSaasOauthToken, idp, loginUrl, history } = this.props
    const { state, code, settings } = this.getHashParameters()

    if (!this.hasValidHashParameters({ state, code })) {
      history.replace({
        pathname: loginUrl,
        state: { oAuthLoginError: this.getErrorDetails() },
      })
      return
    }

    authorizeSaasOauthToken({ state: state!, code: code!, idp_id: idp, settings })
  }

  componentDidUpdate(): void {
    const { authorizeSaasOauthTokenRequest, loginUrl, history } = this.props

    if (authorizeSaasOauthTokenRequest.error) {
      history.replace(loginUrl)
      return
    }
  }

  render(): ReactElement | null {
    const hashParameters = this.getHashParameters()

    if (!this.hasValidHashParameters(hashParameters)) {
      return null
    }

    return <AppLoadingRoot />
  }

  getHashParameters(): Partial<AuthorizeOauthTokenParams> {
    const {
      history: { location },
    } = this.props
    const params = parse(location.hash.slice(1))

    return {
      code: typeof params.code === `string` ? params.code : null,
      state: typeof params.state === `string` ? params.state : null,
      settings: typeof params.settings === `string` ? params.settings : undefined,
    }
  }

  getErrorDetails(): {
    description: Array<string | null> | string | null
    error: Array<string | null> | string | null
  } {
    const {
      history: { location },
    } = this.props
    const { error, error_description } = parse(location.hash.slice(1))

    return {
      error: error ?? null,
      description: error_description ?? null,
    }
  }

  hasValidHashParameters({ state, code }: Partial<AuthorizeOauthTokenParams>): boolean {
    return state !== null && code !== null
  }
}

export default OAuthLogin
