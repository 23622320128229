/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment, PureComponent } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiFieldText, EuiForm, EuiFormRow, EuiSpacer } from '@elastic/eui'

import { submitButtonStyle } from '@modules/access-management-components/styles'

import GoogleReCaptchaProvider from '@/apps/userconsole/components/GoogleReCaptchaProvider'

import PasswordField from '../PasswordField'
import SpinButton from '../SpinButton'
import OpenIdSignUp from '../UserRegistration/OpenIdSignUp'
import ChangePasswordFormErrorCallout from '../../apps/userconsole/components/ChangePasswordForm/ErrorCallout'
import validateEmail from '../../lib/validateEmail'
import { getThemeColors } from '../../lib/theme'

import CreateAccountFormErrorCallout from './CreateAccountFormErrorCallout'
import TermsOfService from './TermsOfService'
import { getCreateUserPayload } from './lib'

import type { BaseSyntheticEvent, ReactNode, ReactChild } from 'react'
import type { StagedUserArgs, AllProps as Props } from './types'

interface State {
  email: string
  password: string
  error: string | null
  isValidPassword: boolean
  pristine: boolean
}

const messages = defineMessages({
  emailError: {
    id: `registration-page.email-error`,
    defaultMessage: `Please use a valid email`,
  },
})

class CreateAccountForm extends PureComponent<Props> {
  emailInput: HTMLInputElement | null = null

  state: State = this.getInitialState()

  getInitialState(): State {
    return {
      password: '',
      email: this.props.stagedUser.email ?? '',
      error: null,
      isValidPassword: false,
      pristine: true,
    }
  }

  componentDidMount() {
    if (this.emailInput != null) {
      this.emailInput.focus()
    }
  }

  render(): ReactNode {
    const { loginRequest, createUserRequest, isGovCloud, setInitialPasswordRequest, redirectTo } =
      this.props

    const { error, isValidPassword } = this.state

    return (
      <GoogleReCaptchaProvider action='registration' onVerifyCaptcha={this.createUserWithCaptcha}>
        {({ verifyCaptcha, isVerifyingCaptcha, captchaError }) => (
          <Fragment>
            <form
              onSubmit={(e) => this.onSubmit(e, verifyCaptcha)}
              className='create-account-form'
              data-test-id='create-account-form'
            >
              <EuiForm>
                <EuiFormRow
                  isInvalid={!!error}
                  error={error}
                  label={<FormattedMessage id='create-account-form.email' defaultMessage='Email' />}
                >
                  {this.renderEmailField()}
                </EuiFormRow>

                <EuiSpacer size='l' />

                <PasswordField
                  name='newPassword'
                  label={
                    <FormattedMessage
                      id='create-account-form.new-password'
                      defaultMessage='Password'
                    />
                  }
                  onChange={this.onChangePassword}
                  hasStrengthIndicator={true}
                  fullWidth={true}
                />
                {this.renderErrorCallout(captchaError)}

                <EuiSpacer size='l' />

                <EuiFormRow>
                  <SpinButton
                    type='submit'
                    disabled={!isValidPassword || !this.isValidEmail()}
                    spin={
                      createUserRequest.inProgress ||
                      loginRequest.inProgress ||
                      setInitialPasswordRequest.inProgress ||
                      isVerifyingCaptcha
                    }
                    buttonProps={{ fullWidth: true }}
                    data-test-id='create-account-form-button'
                    fill={true}
                    css={submitButtonStyle}
                  >
                    <FormattedMessage
                      id='create-account-form.create-button'
                      defaultMessage='Create account'
                    />
                  </SpinButton>
                </EuiFormRow>
              </EuiForm>
            </form>

            {!isGovCloud && <OpenIdSignUp redirectTo={redirectTo} />}

            <EuiSpacer size='m' />

            <TermsOfService />
          </Fragment>
        )}
      </GoogleReCaptchaProvider>
    )
  }

  renderErrorCallout(captchaError: ReactChild | null) {
    const { setInitialPasswordRequest, stagedUser } = this.props

    if (stagedUser.isReadOnly) {
      return <ChangePasswordFormErrorCallout error={setInitialPasswordRequest.error} />
    }

    return <CreateAccountFormErrorCallout captchaError={captchaError} />
  }

  renderEmailField() {
    const { stagedUser } = this.props
    const { error } = this.state
    const { euiColorMediumShade } = getThemeColors()

    if (stagedUser.isReadOnly) {
      return (
        <EuiFieldText
          readOnly={true}
          name='email'
          value={stagedUser.email || ''}
          style={{ color: euiColorMediumShade }}
          fullWidth={true}
        />
      )
    }

    return (
      <EuiFieldText
        isInvalid={!!error}
        onFocus={this.clearError}
        onBlur={this.validateField}
        onChange={this.onChangeEmail}
        name='email'
        inputRef={(el) => {
          this.emailInput = el
        }}
        value={this.state.email}
        fullWidth={true}
      />
    )
  }

  onChangePassword = (input, { isValidPassword }) => {
    this.setState({ password: input.value, isValidPassword })
  }

  onChangeEmail = ({ target: { value } }: BaseSyntheticEvent) => {
    this.setState({
      email: value,
    })
  }

  isValidEmail = () => {
    const { stagedUser } = this.props

    if (stagedUser.isReadOnly) {
      return true
    }

    return validateEmail(this.state.email)
  }

  clearError = () => {
    this.setState({
      error: null,
    })
  }

  validateField = (e) => {
    const {
      intl: { formatMessage },
    } = this.props

    const {
      target: { value },
    } = e

    if (value && !validateEmail(value)) {
      this.setState({
        error: formatMessage(messages.emailError),
      })
    }
  }

  onSubmit = (e, verifyCaptcha) => {
    e.preventDefault()
    verifyCaptcha()
  }

  createUserWithCaptcha = (captchaToken: string) => {
    const { stagedUser } = this.props

    if (stagedUser.isReadOnly) {
      return this.activateStagedUser(stagedUser)
    }

    return this.createUser(captchaToken)
  }

  activateStagedUser({ email, expires, activationHash }: StagedUserArgs) {
    const { password } = this.state

    if (!email) {
      return
    }

    if (!expires) {
      return
    }

    if (!activationHash) {
      return
    }

    this.props.setInitialPassword({
      email,
      expires,
      hash: activationHash,
      password,
    })
  }

  createUser(captchaToken: string) {
    const {
      createUser,
      loginAndRedirect,
      location: { search },
    } = this.props
    const { email, password, error } = this.state

    if (error) {
      this.setState({ pristine: false })
      return
    }

    createUser({
      password,
      email,
      captcha_token: captchaToken,
      ...getCreateUserPayload(search),
    }).then(() => loginAndRedirect({ credentials: { email, password } }))
  }
}

export default injectIntl(CreateAccountForm)
