/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import {
  EuiPanel,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTextColor,
  EuiText,
  withEuiTheme,
  EuiSpacer,
} from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import type { ReactNode, FunctionComponent } from 'react'

type Props = {
  children?: ReactNode
  title: ReactNode
  description?: ReactNode
}

interface PropsWithTheme extends Props, WithEuiThemeProps {}

const PermissionsRolePanel: FunctionComponent<PropsWithTheme> = ({
  title,
  description,
  children,
  theme,
}) => (
  <EuiPanel hasShadow={false} hasBorder={true}>
    <EuiFlexGroup direction='column'>
      <EuiFlexItem>
        <EuiFlexGroup
          css={css({
            margin: `-${theme.euiTheme.size.base}`,
            padding: theme.euiTheme.size.base,
            backgroundColor: 'transparent',
          })}
          gutterSize='m'
          responsive={false}
        >
          <EuiFlexItem>
            <EuiText size='m'>
              <h4>{title}</h4>
              {description && (
                <EuiTextColor color='subdued'>
                  <EuiText size='s'>{description}</EuiText>
                </EuiTextColor>
              )}
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems='flexStart'>
              <EuiFlexItem>
                <EuiIcon type='checkInCircleFilled' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      {children && (
        <EuiFlexItem>
          <EuiSpacer size='m' />
          {children}
          <EuiSpacer size='s' />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  </EuiPanel>
)

export default withEuiTheme(PermissionsRolePanel)
