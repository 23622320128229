/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import {
  listElasticsearchProjects,
  listObservabilityProjects,
  listSecurityProjects,
} from '@modules/project-admin-api/v1/callers'
import type { QueryFunctionReturnType, QueryHookOptions } from '@modules/query/types'

import { makeQueryKey } from './helpers'

export const useListElasticsearchProjectsQuery = (
  args: Exclude<Parameters<typeof listElasticsearchProjects>[0], undefined>['queryParameters'],
  options?: QueryHookOptions,
) =>
  useQuery<QueryFunctionReturnType<typeof listElasticsearchProjects>>(
    makeQueryKey('listElasticsearchProjects', args),
    () =>
      listElasticsearchProjects({
        queryParameters: args,
      }),
    options,
  )

export const useListObservabilityProjectsQuery = (
  args: Exclude<Parameters<typeof listElasticsearchProjects>[0], undefined>['queryParameters'],
  options?: QueryHookOptions,
) =>
  useQuery<QueryFunctionReturnType<typeof listObservabilityProjects>>(
    makeQueryKey('listObservabilityProjects', args),
    () =>
      listObservabilityProjects({
        queryParameters: args,
      }),
    options,
  )

export const useListSecurityProjectsQuery = (
  args: Exclude<Parameters<typeof listElasticsearchProjects>[0], undefined>['queryParameters'],
  options?: QueryHookOptions,
) =>
  useQuery<QueryFunctionReturnType<typeof listSecurityProjects>>(
    makeQueryKey('listSecurityProjects', args),
    () =>
      listSecurityProjects({
        queryParameters: args,
      }),
    options,
  )
