/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { useConfig } from '@modules/cui/ConfigContext'
import DeploymentCreationWizard from '@modules/deployment-creation-wizard/DeploymentCreationWizard'
import Feature from '@modules/utils/feature'
import Header from '@modules/cui/Header'

import { deploymentCreateCrumbs } from '@/lib/crumbBuilder'

import CreateStackDeployment from '../CreateStackDeployment'
import CreateStackDeploymentEditorDependencies from '../CreateStackDeploymentEditorDependencies'

import type { FunctionComponent } from 'react'
import type { CreateEditorComponentConsumerProps } from '../types'

const CreateStackDeploymentRoute: FunctionComponent = () => {
  const isShowDefaultSolutionViewEnabled = useConfig(Feature.showDefaultSolutionView)

  if (isShowDefaultSolutionViewEnabled) {
    return (
      <Fragment>
        <Header breadcrumbs={deploymentCreateCrumbs()} />
        <CreateStackDeploymentEditorDependencies>
          {(props: CreateEditorComponentConsumerProps) => <DeploymentCreationWizard {...props} />}
        </CreateStackDeploymentEditorDependencies>
      </Fragment>
    )
  }

  return <CreateStackDeployment />
}

export default CreateStackDeploymentRoute
