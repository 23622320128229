/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { fetchClusterHealth } from '@/actions/deployments/fetchRecoveryInfo'
import { fetchClusterHealthRequest } from '@/reducers/asyncRequests/registry'
import { getClusterHealth } from '@/reducers/clusters/clusterHealth'
import withPolling from '@/lib/withPolling'

import ClusterShards from './ClusterShards'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment: { id } }: ConsumerProps): StateProps => ({
  clusterHealth: getClusterHealth(state, id),
  fetchClusterHealthRequest: fetchClusterHealthRequest(state, id),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => ({
  fetchClusterHealth: () => dispatch(fetchClusterHealth(deployment)),
})

const PollingComponent = withPolling(ClusterShards, ({ fetchClusterHealth: onPoll }) => ({
  onPoll,
  pollImmediately: ['clusterHealth'],
}))

export default connect(mapStateToProps, mapDispatchToProps)(PollingComponent)
