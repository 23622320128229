/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import DocLink from '@/components/DocLink'

import featurePackedBoxIllustration from '../../../../../files/illustration-feature-packed-box-128.svg'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

type Props = WrappedComponentProps

const messages = defineMessages({
  panelIllustration: {
    id: 'create-deployment-prompt.illustration',
    defaultMessage: 'Feature packed illustration',
  },
  learnMoreAriaLabel: {
    id: 'create-deployment-prompt.learn-more.aria-label',
    defaultMessage: 'Learn more about hosted deployments',
  },
})

const CreateDeploymentPrompt: FunctionComponent<Props> = ({ intl: { formatMessage } }) => (
  <EuiFlexGroup>
    <EuiFlexItem grow={1}>
      <EuiImage
        size='s'
        src={featurePackedBoxIllustration}
        alt={formatMessage(messages.panelIllustration)}
      />
    </EuiFlexItem>
    <EuiFlexItem grow={4}>
      <EuiTitle size='s'>
        <h3>
          <FormattedMessage
            id='create-deployment-prompt.title'
            defaultMessage='You have no hosted deployments yet'
          />
        </h3>
      </EuiTitle>

      <EuiSpacer size='m' />

      <EuiText size='s'>
        <p>
          <FormattedMessage
            id='create-deployment-prompt.body'
            defaultMessage='Deploy an Elastic Stack with all Search, Observability, and Security solutions included. Customize its size and performance to your exact needs. <link>Learn more</link>'
            values={{
              link: (content) => (
                <DocLink
                  link='gettingStartedDocLink'
                  showExternalLinkIcon={true}
                  aria-label={formatMessage(messages.learnMoreAriaLabel)}
                >
                  {content}
                </DocLink>
              ),
            }}
          />
        </p>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default injectIntl(CreateDeploymentPrompt)
