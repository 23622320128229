/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  ElasticsearchInstanceMetricSeries,
  ElasticsearchRequestMetricSeries,
  SimpleElasticsearchMetrics,
} from '@modules/cloud-api/v1/types'

import { FETCH_METRICS } from '../../constants/actions'

import type { ReduxState } from '@/types/redux'
import type { Metrics, MetricViz } from './types'

interface Action {
  type: typeof FETCH_METRICS
  error?: boolean
  payload?: SimpleElasticsearchMetrics
  meta: {
    regionId: string
    stackDeploymentId: string
    etag?: string
  }
}

type State = {
  [regionAndClusterId: string]: {
    etag: string | undefined
    metrics: Metrics
  }
}

export default function metricsReducer(state: State = {}, action: Action) {
  if (action.type === FETCH_METRICS) {
    if (!action.error && action.payload) {
      const { regionId, stackDeploymentId, etag } = action.meta

      const key = createDescriptor(regionId, stackDeploymentId)
      const oldMetrics = state[key]

      if (oldMetrics != null && oldMetrics.etag !== etag) {
        return state
      }

      return {
        ...state,
        [key]: { etag, metrics: calculateMetrics(action.payload) },
      }
    }
  }

  return state
}

export function getMetrics(
  state: ReduxState,
  regionId: string,
  deploymentId: string,
): Metrics | null {
  const metricsState = state.metrics[createDescriptor(regionId, deploymentId)] // nosemgrep
  return metricsState == null ? null : metricsState.metrics
}

function createDescriptor(regionId: string, deploymentId: string): string {
  return `${regionId}/${deploymentId}`
}

function calculateMetrics(metrics: SimpleElasticsearchMetrics): Metrics {
  const requestMetrics = calculateRequestMetrics(metrics.request_metrics)
  const instanceMetrics = calculateInstanceMetrics(metrics.instance_metrics)

  return {
    cpu: {
      cpuUsage: instanceMetrics.cpuUsage,
      cpuCredits: instanceMetrics.cpuCredits,
    },
    request: requestMetrics,
    memory: {
      majorGCsPerNode: instanceMetrics.majorGCsPerNode,
      pressurePerNode: instanceMetrics.pressurePerNode,
      diskUsagePerNode: instanceMetrics.diskUsagePerNode,
    },
  }
}

function calculateRequestMetrics(allRequests: { [key: string]: ElasticsearchRequestMetricSeries }) {
  const requestCounts: MetricViz[] = []
  const statsPerAction: { index: MetricViz[]; search: MetricViz[] } = { index: [], search: [] }

  if (allRequests.index) {
    const rm = calculateRequestMetricsPerAction(`index`, allRequests.index, requestCounts)
    statsPerAction.index = rm.stats
    requestCounts.push(rm.counts)
  }

  if (allRequests.search) {
    const rm = calculateRequestMetricsPerAction(`search`, allRequests.search, requestCounts)
    statsPerAction.search = rm.stats
    requestCounts.push(rm.counts)
  }

  return {
    statsPerAction,
    requestCounts,
  }
}

function calculateRequestMetricsPerAction(
  action: string,
  requestsPerAction: ElasticsearchRequestMetricSeries,
  requestCounts: MetricViz[],
) {
  const _95ths: MetricViz = {
    name: `95th percentile`,
    x: [],
    y: [],
    showlegend: false,
    line: { width: 1 },
  }
  const _99ths: MetricViz = {
    name: `99th percentile`,
    x: [],
    y: [],
    showlegend: false,
    line: { width: 1 },
  }
  const averages: MetricViz = {
    name: `Average`,
    x: [],
    y: [],
    showlegend: false,
    line: { width: 1 },
  }
  const counts: MetricViz = {
    name: action,
    x: [],
    y: [],
    showlegend: false,
    line: { width: 1 },
  }

  requestsPerAction.values.forEach((timeBucket) => {
    for (const trace of [_95ths, _99ths, averages, counts]) {
      trace.x.push(new Date(timeBucket.timestamp))
    }

    counts.y.push(timeBucket.count || 0)
    _95ths.y.push(timeBucket.ms95th_percentile || 0)
    _99ths.y.push(timeBucket.ms99th_percentile || 0)
    averages.y.push(timeBucket.average_ms || 0)
  })

  requestCounts.push(counts)

  return {
    stats: [averages, _95ths, _99ths],
    counts,
  }
}

function calculateInstanceMetrics(instanceMetrics: {
  [key: string]: ElasticsearchInstanceMetricSeries
}) {
  const cpuUsage: MetricViz[] = []
  const cpuCredits: MetricViz[] = []
  const majorGCsPerNode: MetricViz[] = []
  const pressurePerNode: MetricViz[] = []
  const diskUsagePerNode: MetricViz[] = []

  Object.entries(instanceMetrics).forEach(([key, value]) => {
    const nodeName = key.replace(/-00+/, `-0`)

    const cpuNode: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const cpuCreditsNode: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      connectgaps: false,
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const gcForNode: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const memoryPressure: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      connectgaps: false,
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }
    const diskUsage: MetricViz = {
      name: nodeName,
      x: [],
      y: [],
      connectgaps: false,
      showlegend: false,
      line: { width: 1 },
      mode: `lines`,
    }

    value.values.forEach((timeBucket) => {
      const timestamp = new Date(timeBucket.timestamp)

      cpuNode.x.push(timestamp)
      cpuNode.y.push(timeBucket.cpu_usage_percent || 0)

      cpuCreditsNode.x.push(timestamp)
      cpuCreditsNode.y.push(timeBucket.cpu_credits || 0)

      gcForNode.x.push(timestamp)
      gcForNode.y.push(timeBucket.gc_overhead_old_gen_percent || 0)

      memoryPressure.x.push(timestamp)
      memoryPressure.y.push(timeBucket.memory_pressure_percent || 0)

      diskUsage.x.push(timestamp)
      diskUsage.y.push(timeBucket.disk_used_percent || 0)
    })

    cpuUsage.push(cpuNode)
    cpuCredits.push(cpuCreditsNode)
    majorGCsPerNode.push(gcForNode)
    pressurePerNode.push(memoryPressure)
    diskUsagePerNode.push(diskUsage)
  })

  return {
    cpuUsage,
    cpuCredits,
    majorGCsPerNode,
    pressurePerNode,
    diskUsagePerNode,
  }
}
