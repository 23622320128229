/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'querystring'

import { inRange } from 'lodash'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'

import { useGetDeploymentQuery } from '@modules/deployment-creation-api/hooks'
import { hasDeploymentSolutionViewFeature } from '@modules/deployment-lib'
import { putDiscoveryQuestions } from '@modules/discovery-questions-api/callers'
import { invalidateGetUserProfileQuery } from '@modules/profile-lib/hooks'
import type { QueryFunctionReturnType } from '@modules/query/types'
import { useTypedLocalStorage } from '@modules/utils/useTypedLocalStorage'

// eslint-disable-next-line import/no-restricted-paths

import { useSessionStorageBackedState } from '../utils/hooks/useLocalStorage'

import {
  getKibanaDeploymentDeepLink,
  getKibanaProjectDeepLink,
  isValidOnboardingToken,
  OnboardingToken,
} from './steps'
import { ResourceType } from './utils'

import type {
  ExperienceLevelOptionsType,
  ExperienceLevelOptionsValueType,
  TrialIntentOptionsType,
  TrialIntentOptionsValueType,
  UseCaseOptionsType,
  UseCaseOptionsValueType,
} from './utils'

export type DiscoveryQuestionsStateType = {
  full_name: string
  company: string
  experience_level: ExperienceLevelOptionsType
  use_case: UseCaseOptionsType
  trial_intent: TrialIntentOptionsType
}

export interface DiscoveryQuestionsPayload {
  full_name: string
  company: string
  experience_level: ExperienceLevelOptionsValueType
  trial_intent: TrialIntentOptionsValueType
  use_case: UseCaseOptionsValueType
  use_case_detail?: string
}

const ONBOARDING_TOKEN_VALIDITY_WINDOW = 4 * 60 * 60 * 1000 // 4 hours

export const usePutDiscoveryQuestionsMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof putDiscoveryQuestions>,
    { message },
    { body: DiscoveryQuestionsPayload }
  >({
    mutationFn: ({ body }: { body: DiscoveryQuestionsPayload }) => putDiscoveryQuestions(body),
    onSuccess: () => {
      invalidateGetUserProfileQuery()
    },
  })

export const useOnboardingToken = (): OnboardingToken | undefined => {
  const { search } = useLocation()
  const query = parse(search.slice(1))
  const onboardingTokenFromQuerystring = query.onboarding_token as string | undefined

  const onboardingToken = isValidOnboardingToken(onboardingTokenFromQuerystring)
    ? onboardingTokenFromQuerystring
    : undefined

  const [value, setValue] = useTypedLocalStorage(
    'onboardingToken',
    z.object({
      token: z.enum(getValues(OnboardingToken)).optional(),
      created: z.number(),
    }),
    { token: onboardingToken, created: new Date().getTime() },
  )

  // update stored value when a different onboarding token appears
  useEffect(() => {
    if (onboardingToken && onboardingToken !== value.token) {
      setValue({ token: onboardingToken, created: new Date().getTime() })
    }
  }, [onboardingToken, value, setValue])

  // return the token only if unexpired
  const elapsed = new Date().getTime() - value.created

  return inRange(elapsed, 0, ONBOARDING_TOKEN_VALIDITY_WINDOW) ? value.token : undefined
}

function getValues<T extends Record<string, any>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]]
}

const onboardingMetadataSchema = z.object({
  type: z.enum(getValues(ResourceType)).optional(),
  token: z.enum(getValues(OnboardingToken)).optional(),
  resource_id: z.string().optional(),
})

type OnboardingMetadata = z.infer<typeof onboardingMetadataSchema>

export const useOnboardingMetadataFromLocalStorage = (initialValue?: OnboardingMetadata) => {
  const result = useTypedLocalStorage(
    'ONBOARDING_METADATA',
    onboardingMetadataSchema,
    initialValue || {},
  )
  return result
}

export const useKibanaDeeplinkForCurrentResource = (id: string | undefined) => {
  const [onboardingMetadata] = useOnboardingMetadataFromLocalStorage()
  const { data: deployment } = useGetDeploymentQuery(id, { enabled: id !== undefined })

  if (id === undefined) {
    return // to ensure hook can be called unconditionally
  }

  const resourceType = onboardingMetadata.type

  let kibanaDeepLink: string | undefined

  if (
    !hasDeploymentSolutionViewFeature(deployment) &&
    resourceType &&
    id === onboardingMetadata.resource_id
  ) {
    if (resourceType === ResourceType.Serverless) {
      kibanaDeepLink = getKibanaProjectDeepLink(onboardingMetadata.token)
    } else {
      kibanaDeepLink = getKibanaDeploymentDeepLink(onboardingMetadata.token)
    }
  }

  return kibanaDeepLink
}

/**
 * Hook to manage the state of the answers to the discovery questions
 * Stores the answers in the session storage so that they are not lost when accidentally reloading the page
 * @param initialState - state coming from the onboarding token
 */
export const useAnswersState = ({
  initialState,
}: {
  initialState: Partial<DiscoveryQuestionsStateType>
}) => {
  const [answers, setAnswers] = useSessionStorageBackedState(
    'discovery-questions-answers',
    initialState,
  )

  return {
    answers,
    setAnswer: (answer: Partial<DiscoveryQuestionsStateType>) => {
      setAnswers({ ...answers, ...answer })
    },
  }
}
