/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { Component } from 'react'

import { portalUrl } from '@modules/utils/rootUrls'

import { deploymentsUrl } from '../../../../lib/urlBuilder'

import type { RouteComponentProps } from 'react-router'

type Props = RouteComponentProps & {
  isPortalFeatureEnabled: boolean
}

class UserconsoleRootRoute extends Component<Props> {
  componentDidMount() {
    this.handleRootRoute()
  }

  render() {
    return null
  }

  handleRootRoute() {
    const { isPortalFeatureEnabled, location, history } = this.props

    if (handleLegacyRoutes({ location, history })) {
      return
    }

    if (isPortalFeatureEnabled) {
      history.replace(portalUrl())
      return
    }

    history.replace(deploymentsUrl())
  }
}

export default UserconsoleRootRoute

// We handle a few legacy UCv1 routes where hash-based routing was supported
function handleLegacyRoutes({ location, history }): boolean {
  // TODO remove verifyRoute resetRoute once the new email template is in production
  const routes = [
    {
      pattern: new RegExp(`^#/?(?:verify)/(.*)`),
      replace: (match) => `/account/verify-email?${match[1]}`,
    },
    {
      pattern: new RegExp(`^#/?(?:reset)/(.*)`),
      replace: (match) => `/account/reset-password?${match[1]}`,
    },
    {
      pattern: new RegExp(`^#/partner/signup\\?(.*)`),
      replace: (match) => `/partner-signup?${match[1]}`,
    },
    {
      pattern: new RegExp(`^#/?change-email/(.*)`),
      replace: (match) => `/change-email?${match[1]}`,
    },
  ]

  const { pathname, hash } = location

  if (pathname !== `/`) {
    return false
  }

  for (const { pattern, replace } of routes) {
    const match = pattern.exec(hash)

    if (match) {
      history.replace(replace(match))
      return true
    }
  }

  return false
}
