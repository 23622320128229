/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getSaasTilesBlogUrl, getSaasTilesEventUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest from '../asyncRequests'
import { FETCH_BLOGS, FETCH_UPCOMING_EVENTS } from '../../constants/actions'

export function fetchBlogs(category) {
  const url = getSaasTilesBlogUrl(category)

  return asyncRequest({
    type: FETCH_BLOGS,
    url,
  })
}

export function fetchUpcomingEvents() {
  const url = getSaasTilesEventUrl({ count: 2 })

  return asyncRequest({
    type: FETCH_UPCOMING_EVENTS,
    url,
  })
}
