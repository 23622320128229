/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import type { OrganizationFromApi } from '@modules/ui-types'

import type { FunctionComponent } from 'react'

type OrganizationProfile = { organization: OrganizationFromApi }

interface Props {
  addToWaitList: () => void
  isLoading: boolean
  organizationProfile?: OrganizationProfile
}

const JoinServerlessWaitListButton: FunctionComponent<Props> = ({
  addToWaitList,
  isLoading,
  organizationProfile,
}) => {
  const addedToWaitList = (): boolean => {
    if (organizationProfile) {
      const {
        organization: { data },
      } = organizationProfile
      const waitListRequest = data?.serverless_waitlist_request

      // @ts-ignore
      return Boolean(waitListRequest?.timestamp) // Property 'timestamp' not generated for 'OrganizationProfile' type by swagger contracts
    }

    return false
  }

  if (isLoading) {
    return (
      <EuiButton isLoading={true}>
        <FormattedMessage
          id='join-waitlist-panel.button.loading-label'
          defaultMessage='Loading...'
        />
      </EuiButton>
    )
  }

  if (addedToWaitList()) {
    return (
      <EuiButton
        data-test-id='added-to-wait-list-button'
        isDisabled={addedToWaitList()}
        iconType='check'
      >
        <FormattedMessage
          id='join-waitlist-panel.button.joined-label'
          defaultMessage='You’ve been added'
        />
      </EuiButton>
    )
  }

  return (
    <EuiButton
      data-test-id='add-to-wait-list-button'
      color='success'
      fill={true}
      onClick={addToWaitList}
      data-test-subj='serv_wait_subm'
    >
      <FormattedMessage
        id='join-waitlist-panel.button.join-label'
        defaultMessage='Join the waitlist'
      />
    </EuiButton>
  )
}

export default JoinServerlessWaitListButton
