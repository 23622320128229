/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router'

import { EuiLink, EuiTextAlign } from '@elastic/eui'

import { loginUrl } from '@modules/auth/urls'

import { buildSignInLink, buildSignInQuery } from '@/lib/urlUtils'

const BackToLoginButton = ({
  onClick,
  redirectUrl,
}: {
  onClick?: () => void
  redirectUrl?: string
}) => {
  const history = useHistory()
  const { search } = useLocation()
  const signInQuery = buildSignInQuery({ search })
  const loginLink = buildSignInLink(redirectUrl ?? loginUrl(), signInQuery)

  const onClickAction = () => {
    if (onClick) {
      onClick()
    }

    history.push(loginLink)
  }

  return (
    <EuiTextAlign textAlign='center'>
      <EuiLink onClick={onClickAction}>
        {'← '}
        <FormattedMessage id='cloud-sso-page.back-to-login' defaultMessage='Go back' />
      </EuiLink>
    </EuiTextAlign>
  )
}

export default BackToLoginButton
