/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import {
  listElasticsearchProjects,
  listObservabilityProjects,
  listSecurityProjects,
} from '@modules/project-user-api/v1/callers'
import type { MultiErrorResponse } from '@modules/project-user-api/v1/types'
import type { QueryHook, QueryHookOptions } from '@modules/query/types'

import { makeQueryKey } from './helpers'

export const useListElasticsearchProjectsQuery: QueryHook<
  typeof listElasticsearchProjects,
  unknown,
  MultiErrorResponse
> = (options?: QueryHookOptions) =>
  useQuery(makeQueryKey('listElasticsearchProjects'), () => listElasticsearchProjects(), options)

export const useListObservabilityProjectsQuery: QueryHook<
  typeof listObservabilityProjects,
  unknown,
  MultiErrorResponse
> = (options?: QueryHookOptions) =>
  useQuery(makeQueryKey('listObservabilityProjects'), () => listObservabilityProjects(), options)

export const useListSecurityProjectsQuery: QueryHook<
  typeof listSecurityProjects,
  unknown,
  MultiErrorResponse
> = (options?: QueryHookOptions) =>
  useQuery(makeQueryKey('listSecurityProjects'), () => listSecurityProjects(), options)
