/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiStepsHorizontal } from '@elastic/eui'
import type { EuiStepsHorizontalProps } from '@elastic/eui'

import history from '@modules/utils/history'
import { resourceCreationManagedUrl } from '@modules/project-creation-lib/urls'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent, ReactElement } from 'react'

type Props = {
  children: ReactElement
  title?: ReactElement
  step: number
  intl: IntlShape
}

const stepsStyle = css`
  .euiStepHorizontal__title {
    font-size: 14px;
    width: 120px;
  }
`
const isCurrent = (currentStep: number, step: number) => currentStep === step
const isComplete = (currentStep: number, step: number) => currentStep > step

const getStatus = (currentStep: number, step: number) => {
  if (isCurrent(currentStep, step)) {
    return 'current'
  }

  if (isComplete(currentStep, step)) {
    return 'complete'
  }

  return 'disabled'
}

const ProjectCreationContainer: FunctionComponent<Props> = ({
  children,
  title,
  step,
  intl: { formatMessage },
}) => {
  const steps: EuiStepsHorizontalProps['steps'] = [
    {
      title: formatMessage({ id: 'creation-container-steps1', defaultMessage: 'Choose type' }),
      status: getStatus(step, 1),
      onClick: () => history.push(resourceCreationManagedUrl()),
    },
    {
      title: formatMessage({
        id: 'creation-container-steps2',
        defaultMessage: 'Review settings',
      }),
      status: getStatus(step, 2),
      onClick: () => null,
    },
    {
      title: formatMessage({
        id: 'creation-container-steps3',
        defaultMessage: 'Launch project',
      }),
      status: getStatus(step, 3),
      onClick: () => null,
    },
  ]

  return (
    <EuiFlexGroup direction='column' alignItems='center'>
      <EuiFlexItem>
        <EuiStepsHorizontal steps={steps} css={stepsStyle} size='s' />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction='column' alignItems='center' gutterSize='s'>
          <EuiFlexItem>{title}</EuiFlexItem>
          <EuiFlexItem>{children}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default injectIntl(ProjectCreationContainer)
