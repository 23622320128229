/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchClusterPlan } from '@modules/cloud-api/v1/types'

import { hasCurrent, hasNext, isElasticsearch } from './preconditions'

import type { Difference, DifferenceCheck } from './types'

export const diffEsInstanceConfiguration: DifferenceCheck = {
  preconditions: [isElasticsearch, hasCurrent, hasNext],
  check: ({ current, next, sliderInstanceType }) => {
    const currentEs = current as ElasticsearchClusterPlan
    const nextEs = next as ElasticsearchClusterPlan
    const changes: Array<Difference<any>> = []

    // go through and find all the unique IDs (types) in cluster topology for current elements (e.g. `hot_content`, `warm`)
    const uniqueTypes: string[] = []
    currentEs.cluster_topology.forEach((ct) => {
      if (ct.id) {
        uniqueTypes.push(ct.id)
      }
    })

    // go through each type and see if the IC has changed or not
    uniqueTypes.forEach((t) => {
      const thisTypeInNext = nextEs.cluster_topology.find((a) => a.id === t)
      const thisTypeInCurrent = currentEs.cluster_topology.find((b) => b.id === t)

      checkForIcChanges(thisTypeInCurrent, thisTypeInNext, changes, sliderInstanceType, t)
    })

    return changes
  },
}

export function checkForIcChanges(
  thisTypeInCurrent,
  thisTypeInNext,
  changes,
  sliderInstanceType,
  tierId,
) {
  if (thisTypeInNext?.instance_configuration_id !== thisTypeInCurrent?.instance_configuration_id) {
    changes.push({
      type: `ic-id-changed`,
      target: sliderInstanceType,
      meta: {
        currentId: thisTypeInCurrent?.instance_configuration_id,
        nextId: thisTypeInNext?.instance_configuration_id,
        tierId,
      },
    })
    // if the IC ID changes for a particular tier type, we don't care about any version changes
  } else if (
    thisTypeInNext?.instance_configuration_version !==
    thisTypeInCurrent?.instance_configuration_version
  ) {
    if (thisTypeInNext?.instance_configuration_version !== undefined) {
      changes.push({
        type: `ic-version-changed`,
        target: sliderInstanceType,
        meta: {
          currentVersion: thisTypeInCurrent?.instance_configuration_version || 0,
          nextVersion: thisTypeInNext?.instance_configuration_version,
          icId: thisTypeInCurrent?.instance_configuration_id,
          tierId: thisTypeInCurrent?.id || tierId,
        },
      })
    }
  }
}
