/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  createDeploymentUrl,
  deleteDeploymentUrl,
  getDeploymentUrl,
  postInstantDeploymentUrl,
  searchDeploymentsUrl,
  updateDeploymentUrl,
} from '@modules/cloud-api/v1/urls'
import { DeploymentGetResponse, DeploymentsSearchResponse } from '@modules/cloud-api/v1/types'
import type {
  DeploymentClaimRequest,
  DeploymentClaimResponse,
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  SearchRequest,
} from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'

import { addDeletedDeploymentToast } from '@/lib/toasts'
import {
  CLAIM_INSTANT_STACK_DEPLOYMENT,
  CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE,
  CREATE_STACK_DEPLOYMENT,
  DELETE_STACK_DEPLOYMENT,
  FETCH_STACK_DEPLOYMENT,
  SEARCH_STACK_DEPLOYMENTS,
  UPDATE_STACK_DEPLOYMENT,
  UPDATE_STACK_DEPLOYMENT_DRY_RUN,
} from '@/constants/actions'
import redirectToStackDeploymentActivity from '@/actions/clusters/redirectToStackDeploymentActivity'
import { shouldCreateOrganization } from '@/lib/organizations'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { createOrganization } from '../organizations'

import redirectToDeploymentsPage from './redirectToDeploymentsPage'

import type { ThunkDispatch, ThunkAction, Action } from '@/types/redux'
import type { RESET_ASYNC_REQUEST } from '@/constants/actions'

export function fetchDeployment({ deploymentId }: { deploymentId: string }) {
  return (dispatch: ThunkDispatch) => {
    const url = getDeploymentUrl(
      getDeploymentUrlParams({
        deploymentId,
      }),
    )

    return dispatch(
      asyncRequest<typeof FETCH_STACK_DEPLOYMENT, DeploymentGetResponse>({
        type: FETCH_STACK_DEPLOYMENT,
        method: `GET`,
        url,
        meta: { deploymentId },
        crumbs: [deploymentId],
      }),
    )
  }
}

function getDeploymentUrlParams({
  deploymentId,
  enrichWithTemplate = true,
}: {
  deploymentId: string
  enrichWithTemplate?: boolean
}): {
  deploymentId: string
  showSecurity?: boolean | null
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  showSystemAlerts?: number | null
  showSettings?: boolean | null
  convertLegacyPlans: boolean
  enrichWithTemplate?: boolean | null
} {
  return {
    deploymentId,
    showMetadata: true,
    showPlanDefaults: true,
    showPlanHistory: true,
    showPlanLogs: false,
    showSecurity: true,
    showSettings: true,
    convertLegacyPlans: true,

    // We don't separate alerts by type so 20 is a guess as to what will give us at least a few of each 99% of the time
    showSystemAlerts: 20,

    enrichWithTemplate,
  }
}

export function searchDeployments({ queryId, query }: { queryId: string; query: SearchRequest }) {
  const url = searchDeploymentsUrl()

  return asyncRequest<typeof SEARCH_STACK_DEPLOYMENTS, DeploymentsSearchResponse>({
    type: SEARCH_STACK_DEPLOYMENTS,
    method: `POST`,
    url,
    meta: { queryId },
    crumbs: [queryId],
    payload: query,
  })
}

function createDeploymentCall(deployment: DeploymentCreateRequest) {
  const url = createDeploymentUrl()

  return asyncRequest({
    type: CREATE_STACK_DEPLOYMENT,
    method: `POST`,
    url,
    payload: deployment,
  })
}

export function createDeployment({
  deployment,
  profile,
}: {
  deployment: DeploymentCreateRequest
  profile?: UserProfile | null
}) {
  return (dispatch: ThunkDispatch) => {
    if (shouldCreateOrganization({ profile })) {
      return dispatch(createOrganization({})).then(() => dispatch(createDeploymentCall(deployment)))
    }

    return dispatch(createDeploymentCall(deployment))
  }
}

export function claimInstantStackDeployment({ payload }: { payload: DeploymentClaimRequest }) {
  const url = postInstantDeploymentUrl()

  return asyncRequest<typeof CLAIM_INSTANT_STACK_DEPLOYMENT, DeploymentClaimResponse>({
    type: CLAIM_INSTANT_STACK_DEPLOYMENT,
    method: `POST`,
    url,
    payload,
  })
}

export function clearCreateDeploymentResponse({
  deploymentId,
}: {
  deploymentId: string
}): Action<typeof CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE> {
  return {
    type: CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE,
    meta: {
      deploymentId,
    },
  }
}

export function updateDeployment({
  deploymentId,
  deployment,
  redirect = true,
  dryRun = false,
}: {
  deploymentId: string
  deployment: DeploymentUpdateRequest
  redirect?: boolean
  dryRun?: boolean
}) {
  const url = updateDeploymentUrl({
    deploymentId,
    validateOnly: dryRun,
  })

  return (dispatch: ThunkDispatch) =>
    dispatch(
      asyncRequest({
        type: dryRun ? UPDATE_STACK_DEPLOYMENT_DRY_RUN : UPDATE_STACK_DEPLOYMENT,
        method: `PUT`,
        url,
        payload: deployment,
        meta: { deploymentId },
        crumbs: [deploymentId],
      }),
    ).then((actionResult): unknown => {
      // The return type is marked as `unknown` because we can return either 1 of 2 possible async action responses,
      // or indeed a new promise for a 3rd action type. Better to mark it unknown than provide accurate types
      // that someone might later rely on.

      if (dryRun) {
        return actionResult
      }

      // if the create request is still around, clean up to avoid ambiguity
      dispatch(clearCreateDeploymentResponse({ deploymentId }))

      const refetchAction = dispatch(fetchDeployment({ deploymentId }))

      if (!redirect) {
        return refetchAction
      }

      return dispatch(redirectToStackDeploymentActivity(deploymentId))
    })
}

export function deleteDeployment({ deploymentId }: { deploymentId: string }): ThunkAction {
  const url = deleteDeploymentUrl({
    deploymentId,
  })

  return (dispatch: ThunkDispatch) =>
    dispatch(
      asyncRequest({
        type: DELETE_STACK_DEPLOYMENT,
        method: `DELETE`,
        url,
        meta: { deploymentId },
        crumbs: [deploymentId],
      }),
    ).then(() => {
      addDeletedDeploymentToast()
      return dispatch(redirectToDeploymentsPage())
    })
}

export const resetCreateDeployment = (...crumbs: string[]): Action<typeof RESET_ASYNC_REQUEST> =>
  resetAsyncRequest(CREATE_STACK_DEPLOYMENT, crumbs)

export const resetSearchStackDeploymentsRequest = (
  ...crumbs: string[]
): Action<typeof RESET_ASYNC_REQUEST> => resetAsyncRequest(SEARCH_STACK_DEPLOYMENTS, crumbs)

export const resetUpdateDeployment = (...crumbs: string[]): Action<typeof RESET_ASYNC_REQUEST> =>
  resetAsyncRequest(UPDATE_STACK_DEPLOYMENT, crumbs)

export const resetUpdateDeploymentDryRun = (
  ...crumbs: string[]
): Action<typeof RESET_ASYNC_REQUEST> => resetAsyncRequest(UPDATE_STACK_DEPLOYMENT_DRY_RUN, crumbs)
