/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiTitle,
  EuiText,
  EuiDescriptionListDescription,
  EuiSkeletonText,
  EuiButton,
  EuiDescriptionList,
  EuiDescriptionListTitle,
} from '@elastic/eui'

import { CuiBeveledIcon } from '@modules/cui/BeveledIcon'

import ExternalLink from '@/components/ExternalLink'
import sanitizeHtml from '@/lib/sanitizeHtml'

import PortalTile from '../PortalTile'
import PortalThumbnails from '../PortalThumbnails'

import type { Props, CommunityEvent, UpcomingEvent } from './types'

class CommunityTile extends Component<Props> {
  componentDidMount() {
    const { fetchCommunityTileInfo, fetchUpcomingEvents } = this.props
    fetchCommunityTileInfo()
    fetchUpcomingEvents()
  }

  render() {
    return (
      <PortalTile data-test-id='cloud-portal-community-tile'>
        <EuiFlexGroup responsive={false} alignItems='center' gutterSize='s'>
          <EuiFlexItem grow={false}>
            <CuiBeveledIcon type='users' />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiTitle size='xs'>
              <h2>
                <ExternalLink
                  showExternalLinkIcon={false}
                  color='text'
                  data-test-id='portal-community-tile-title-link'
                  href='https://www.elastic.co/events/?tab=1'
                >
                  <strong>
                    <FormattedMessage
                      id='cloud-portal.community-tile-title'
                      defaultMessage='Community'
                    />
                  </strong>
                </ExternalLink>
              </h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <PortalThumbnails thumbnails={this.getPrimaryEventThumbnails()} />

        {this.renderUpcomingEvents()}

        <EuiSpacer />

        <EuiButton
          size='s'
          target='_blank'
          data-test-id='portal-community-tile-event-portal-link'
          href='https://www.elastic.co/events/?tab=1'
        >
          <FormattedMessage
            id='cloud-portal.community-tile-portal-link'
            defaultMessage='Events portal'
          />
        </EuiButton>

        <EuiHorizontalRule size='full' />

        {this.renderCommunityInfo()}
      </PortalTile>
    )
  }

  renderEvent = (event: UpcomingEvent) => {
    const { date, title, url } = event

    const txtDate = moment(date).format('MMMM D, HH:mm').toUpperCase()

    return (
      <Fragment key={url}>
        <EuiSpacer size='m' />

        <EuiDescriptionList>
          <EuiDescriptionListTitle>
            <EuiText size='s'>
              <ExternalLink href={url}>{title}</ExternalLink>
            </EuiText>
          </EuiDescriptionListTitle>

          <EuiSpacer size='xs' />

          <EuiDescriptionListDescription>
            <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiText size='xs' color='default'>
                  {txtDate}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiDescriptionListDescription>
        </EuiDescriptionList>
      </Fragment>
    )
  }

  renderUpcomingEvents() {
    const { upcomingEvents, isLoading } = this.props

    if (isLoading) {
      return (
        <Fragment>
          <EuiSpacer size='m' />
          <EuiSkeletonText lines={2} />
          <EuiSpacer size='m' />
          <EuiSkeletonText lines={2} />
        </Fragment>
      )
    }

    if (!upcomingEvents?.length) {
      return null
    }

    return upcomingEvents.map(this.renderEvent)
  }

  renderCommunityInfo() {
    const { communityInfo, isLoading } = this.props

    if (isLoading) {
      return <EuiSkeletonText lines={2} />
    }

    if (!communityInfo) {
      return null
    }

    return <EuiText size='s'>{sanitizeHtml(communityInfo)}</EuiText>
  }

  getPrimaryEventThumbnails() {
    const { isLoading, primaryEvent } = this.props

    if (isLoading) {
      return
    }

    if (!primaryEvent) {
      return []
    }

    const eventThumbnail = {
      description: primaryEvent.description,
      title: primaryEvent.title,
      isExternalLink: true,
      src: primaryEvent.imageUrl,
      url: primaryEvent.linkUrl,
      'data-test-id': `portal-community-tile-primary-event`,
      id: primaryEvent.id,
      type: `article` as const,
      footer: this.getPrimaryEventThumbnailFooter(primaryEvent),
      imgMaxWidth: 122,
    }

    return [eventThumbnail]
  }

  getPrimaryEventThumbnailFooter(event: CommunityEvent) {
    if (!event.date) {
      return null
    }

    const publishDate = moment(event.date)
    const txtPublishDate = publishDate.format('LL').toUpperCase()
    const isLive = moment().isSame(publishDate, 'days')
    return (
      <EuiDescriptionListDescription>
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiText size='xs' color='default'>
              {txtPublishDate}
            </EuiText>
          </EuiFlexItem>
          {isLive && (
            <EuiFlexItem grow={false}>
              <EuiText size='xs' color='success'>
                <FormattedMessage id='cloud-portal.community-tile-live' defaultMessage='Live' />
              </EuiText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiDescriptionListDescription>
    )
  }
}

export default CommunityTile
