/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { addToast } from '@modules/cui/Toasts'
import type { AsyncRequestState } from '@modules/ui-types'
import { asAjaxRequestError } from '@modules/utils/ajax'

import LandingPageContainer from '../../../../../../components/LandingPageContainer'

import ResendVerificationLinkButton from './ResendVerificationLinkButton'

import type { FC, ReactElement } from 'react'

interface Props {
  verifyAccountRequest: AsyncRequestState
  image?: string
  darkImage?: string
  title: ReactElement
  email: string
}

const VerificationFailed: FC<Props> = ({
  verifyAccountRequest,
  image,
  darkImage,
  title,
  email,
}) => {
  const errorCode = getErrorCode(verifyAccountRequest)

  return (
    <LandingPageContainer
      title={<Title fallbackTitle={title} errorCode={errorCode} />}
      subtitle={<Subtitle errorCode={errorCode} />}
      image={image}
      darkImage={darkImage}
      panelProps={{
        hasShadow: false,
        className: 'cloud-landing-page-panel cloud-landing-page-form-panel email-verification-page',
        color: 'transparent',
      }}
    >
      <ResendVerificationLinkButton
        email={email}
        onSuccess={showResendVerificationEmailSuccess}
        onError={showResendVerificationEmailFailure}
      />
    </LandingPageContainer>
  )
}

type TitleProps = {
  errorCode: string
  fallbackTitle: ReactElement
}

function Title({ errorCode, fallbackTitle }: TitleProps): ReactElement {
  if (errorCode === 'user.token_expired' || errorCode === 'user.token_already_used') {
    return (
      <FormattedMessage
        id='password-update-via-email.title-error'
        defaultMessage='This verification link has expired'
      />
    )
  }

  return fallbackTitle
}

type SubtitleProps = {
  errorCode: string
}

function Subtitle({ errorCode }: SubtitleProps): ReactElement {
  if (errorCode === 'user.token_expired') {
    return (
      <div data-test-id='fetch-details-failed-used'>
        <FormattedMessage
          id='password-update-via-email.failed-used'
          defaultMessage='This verification link has expired. You can request a new one by clicking the button below. The link is valid for 24 hours.'
        />
      </div>
    )
  }

  return (
    <div data-test-id='fetch-details-failed'>
      <FormattedMessage
        id='password-update-via-email.failed'
        defaultMessage="We couldn't find your details. Contact {link} for help"
        values={{
          link: (
            <a href='mailto:support@elastic.co?subject=Failed%20to%20verify%20account'>
              support@elastic.co
            </a>
          ),
        }}
      />
    </div>
  )
}

function getErrorCode(verifyAccountRequest: AsyncRequestState): string {
  return asAjaxRequestError(verifyAccountRequest.error)?.body?.errors?.[0]?.code
}

function showResendVerificationEmailFailure() {
  addToast({
    color: 'danger',
    title: (
      <FormattedMessage
        id='resend-verification-link.error-title'
        defaultMessage='Failed to resend verification link'
      />
    ),
    text: (
      <FormattedMessage
        id='resend-verification-link.error-text'
        defaultMessage='Something went wrong. Please try again later'
      />
    ),
  })
}

function showResendVerificationEmailSuccess() {
  addToast({
    color: 'success',
    title: (
      <FormattedMessage
        id='resend-verification-link.success-title'
        defaultMessage='Email verification link sent'
      />
    ),
    text: (
      <FormattedMessage
        id='resend-verification-link.success-text'
        defaultMessage={`Please check your inbox. If you don't see it, you may need to check your spam folder`}
      />
    ),
  })
}

export default VerificationFailed
