/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { parse } from 'query-string'

import {
  getProviderIdByRegion,
  getProvidersNames,
  getRegionIdsByProvider,
  getRegionsByProvider,
} from '@/reducers/providers'
import { fetchVersionsRequest } from '@/reducers/asyncRequests/registry'

import { fetchBasePricesIfNeeded } from '../../../actions/pricing'

import PricingSteps from './PricingSteps'

import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state, { region, location }: ConsumerProps): StateProps => {
  const { search } = location
  const { marketplace_pricing } = parse(search.slice(1), { parseBooleans: true })

  return {
    showMarketplacePricingToggle: marketplace_pricing,
    fetchVersionsRequest: fetchVersionsRequest(state, region ? region.id : null),
    getRegionsByProvider: (provider) => getRegionsByProvider(state, provider),
    getRegionIdsByProvider: (provider) => getRegionIdsByProvider(state, provider),
    getProviderIdByRegion: (regionId) => getProviderIdByRegion(state, regionId),
    providers: getProvidersNames(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  fetchBasePrices: fetchBasePricesIfNeeded,
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(PricingSteps),
)
