/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import type {
  DeploymentCreateRequest,
  DeploymentsSearchResponse,
  DeploymentTemplateInfoV2,
  GlobalDeploymentTemplateInfo,
  StackVersionConfig,
} from '@modules/cloud-api/v1/types'
import type {
  AsyncRequestState,
  ProfileState,
  Region,
  RegionId,
  StackDeploymentCreateRequest,
  VersionNumber,
} from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import type { DeepPartial } from '@modules/ts-essentials'
import type { SelectTemplateArgShape } from '@modules/deployment-creation-lib/types'

import { invalidateDefaultVersion } from '@/lib/stackDeployments/defaultVersion'
import { getDeploymentVersionSetter } from '@/lib/stackDeployments/crud'
import { createDeploymentFromTemplate } from '@/lib/stackDeployments/template'
import { getWhitelistedVersions } from '@/lib/stackDeployments/getWhitelistedVersions'
import { mergeDeep } from '@/lib/immutability-helpers'
import { getRegionIdForCreate } from '@/lib/stackDeployments/selectors/fundamentals'
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'
import { getSortedTemplates } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getDeploymentSettings } from '@/lib/stackDeployments/selectors/stackDeployment'
import { selectTemplateWithSameCategory } from '@/lib/stackDeployments/defaultDeploymentTemplate'

import type { ReactNode } from 'react'
import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'
import type { CreateDeploymentFromTemplateProps } from '@/lib/stackDeployments/template'
import type { CreateEditorComponentConsumerProps } from '../types'
import type { State as ProvidersState } from '../../../reducers/providers'

export type StateProps = {
  defaultRegionId: RegionId
  fetchRegionListRequest: AsyncRequestState
  inTrial: boolean
  profile?: ProfileState | null
  providers: ProvidersState
  regionIds?: RegionId[] | null
  searchTrialResults: DeploymentsSearchResponse | null
  searchTrialResultsRequest: AsyncRequestState
  showRegion: boolean
  showSnapshotVersions: boolean
  areVersionsWhitelisted: boolean
  getDeploymentTemplates: (
    regionId: string,
    version: string,
  ) => DeploymentTemplateInfoV2[] | undefined
  getRegion: (editorState: StackDeploymentCreateRequest) => Region
  getActiveVersionStacks: (
    editorState: StackDeploymentCreateRequest,
  ) => StackVersionConfig[] | undefined
  getVersionsRequest: (editorState: StackDeploymentCreateRequest) => AsyncRequestState
  getFetchSnapshotRepositoriesRequest: (
    editorState: StackDeploymentCreateRequest,
  ) => AsyncRequestState
  getFetchDeploymentTemplatesRequest: (
    regionId: string,
    version: string,
    showMaxZones: boolean,
  ) => AsyncRequestState
  getGlobalDeploymentTemplates: () => GlobalDeploymentTemplateInfo[] | null
  getDeploymentTemplate: (
    regionId: RegionId,
    templateId: string,
    stackVersion: VersionNumber | null,
  ) => DeploymentTemplateInfoV2 | undefined
}

export type DispatchProps = {
  clearVersions: () => void
  createDeployment: (settings: { regionId: RegionId; deployment: DeploymentCreateRequest }) => void
  fetchDeploymentTemplates: (settings: {
    regionId: RegionId
    stackVersion?: VersionNumber | null
    showMaxZones?: boolean
  }) => void
  fetchGlobalDeploymentTemplates: () => void
  fetchDeploymentTemplate: (
    regionId: RegionId,
    templateId: string,
    stackVersion: VersionNumber | null,
  ) => void
  fetchRegionListIfNeeded: () => void
  fetchRegion: (regionId: RegionId) => void
  fetchSnapshotRepositories: (regionId: RegionId) => void
  fetchVersions: (
    region: string | Region,
    settings: { showUnusable?: boolean; tossOldVersions?: boolean },
  ) => void
  searchTrialDeployments: () => void
}

export type ConsumerProps = {
  children: (props: CreateEditorComponentConsumerProps) => ReactNode
  createDeploymentFromTemplateProps?: Partial<CreateDeploymentFromTemplateProps>
  initialEditorState?: StackDeploymentCreateRequest
  selectTemplate: (args: SelectTemplateArgShape) => void
  isOnboardingFlow?: boolean
}

type Props = StateProps & DispatchProps & ConsumerProps & LDProps

type State = {
  editorState: StackDeploymentCreateRequest
}

class CreateStackDeploymentEditorDependencies extends Component<Props, State> {
  state: State = {
    editorState: cloneDeep(this.props.initialEditorState!),
  }

  static defaultProps: Partial<Props> = {
    initialEditorState: {
      deployment: {
        resources: {},
      },
    },
  }

  componentDidMount() {
    const {
      fetchRegionListIfNeeded,
      inTrial,
      searchTrialDeployments,
      fetchGlobalDeploymentTemplates,
    } = this.props

    fetchRegionListIfNeeded()

    if (inTrial) {
      searchTrialDeployments()
    }

    fetchGlobalDeploymentTemplates()

    this.fetchDependencies()
  }

  componentDidUpdate() {
    this.fetchDependencies()
  }

  render() {
    const {
      getDeploymentTemplates,
      getGlobalDeploymentTemplates,
      getFetchSnapshotRepositoriesRequest,
      fetchRegionListRequest,
      getRegion,
      regionIds,
      showRegion,
      getActiveVersionStacks,
      getVersionsRequest,
      children: renderEditorComponent,
      inTrial,
      searchTrialResults,
      areVersionsWhitelisted,
    } = this.props

    const { editorState } = this.state
    const regionId = editorState.regionId!
    const version = getUpsertVersion(editorState)!
    const deploymentTemplates = getDeploymentTemplates(regionId, version)
    const globalDeploymentTemplates = getGlobalDeploymentTemplates()
    const fetchSnapshotRepositoriesRequest = getFetchSnapshotRepositoriesRequest(editorState)
    const region = getRegion(editorState)
    const versionsRequest = getVersionsRequest(editorState)

    if (fetchRegionListRequest.error) {
      return (
        <CuiAlert type='error' details={fetchRegionListRequest.error}>
          <FormattedMessage
            id='stack-deployment-editor-dependencies.fetching-regions-failed'
            defaultMessage='Fetching regions failed'
          />
        </CuiAlert>
      )
    }

    if (versionsRequest.error) {
      return (
        <CuiAlert type='error' details={versionsRequest.error}>
          <FormattedMessage
            id='stack-deployment-editor-dependencies.fetching-elasticsearch-versions-failed'
            defaultMessage='Fetching Elasticsearch versions failed'
          />
        </CuiAlert>
      )
    }

    if (fetchSnapshotRepositoriesRequest.error) {
      return <CuiAlert type='error'>{fetchSnapshotRepositoriesRequest.error}</CuiAlert>
    }

    const versionStacks = getActiveVersionStacks(editorState)
    const availableVersions = this.getAvailableVersions()
    const whitelistedVersions = getWhitelistedVersions(versionStacks, areVersionsWhitelisted)

    const trialMaxedOut = Boolean(
      inTrial &&
        searchTrialResults &&
        searchTrialResults.match_count &&
        searchTrialResults.match_count > 0,
    )

    return renderEditorComponent({
      deploymentTemplates,
      globalDeploymentTemplates,
      availableVersions,
      showRegion,
      regionIds,
      region,
      editorState,
      onChange: this.onChange,
      setRegion: this.setRegion,
      setDeploymentTemplate: this.setDeploymentTemplate,
      setGlobalTemplate: this.setGlobalTemplate,
      whitelistedVersions,
      trialMaxedOut,
      showTrialExperience: inTrial,
      setSnapshotSettingsDisabled: this.setSnapshotSettingsDisabled,
    })
  }

  getAvailableVersions = (): string[] => {
    const { getActiveVersionStacks } = this.props
    const { editorState } = this.state
    const versionStacks = getActiveVersionStacks(editorState)

    if (!versionStacks) {
      return []
    }

    const availableVersions = versionStacks
      .map((versionStack) => versionStack.version!)
      .filter(Boolean)
    return availableVersions
  }

  getTemplateId = (
    regionId: string,
    globalTemplate: GlobalDeploymentTemplateInfo,
  ): string | null => {
    const template = globalTemplate.regions.find((region) => region.region_id === regionId)

    // Sanity
    if (!template) {
      return null
    }

    return template.deployment_template_id
  }

  fetchDependencies(): void {
    const {
      defaultRegionId,
      fetchDeploymentTemplates,
      fetchRegion,
      getDeploymentTemplates,
      getFetchDeploymentTemplatesRequest,
      getActiveVersionStacks,
      inTrial,
      providers,
      regionIds,
      searchTrialDeployments,
      searchTrialResults,
      searchTrialResultsRequest,
      showRegion,
      profile,
      flags,
      selectTemplate = selectTemplateWithSameCategory,
    } = this.props

    const { editorState } = this.state
    const { regionId, deployment, deploymentTemplate, globalDeploymentTemplate } = editorState

    const versionStacks = getActiveVersionStacks(editorState)
    const deploymentRegion = getRegionIdForCreate({ deployment })
    const deploymentVersion = getUpsertVersion({ deployment, _fromJolt: false })
    const version = getUpsertVersion(editorState)

    if (
      inTrial &&
      searchTrialResults == null &&
      !searchTrialResultsRequest.isDone &&
      !searchTrialResultsRequest.inProgress
    ) {
      searchTrialDeployments()
    }

    // showRegion is saas while !showRegion is ECE
    if ((showRegion && isEmpty(providers)) || (!showRegion && isEmpty(regionIds))) {
      // We need to wait for regions before we can fetch the other deps
      return
    }

    if (!regionId) {
      this.setRegion({
        regionId: defaultRegionId,
        stackVersion: undefined,
      })

      fetchRegion(defaultRegionId)
      return
    }

    if (deploymentRegion && deploymentRegion !== regionId) {
      fetchRegion(regionId)
    }

    // If we change region or version, we need to superimpose it on templates
    const fetchDeploymentTemplatesRequest = getFetchDeploymentTemplatesRequest(
      regionId,
      version!,
      true,
    )

    if (
      !fetchDeploymentTemplatesRequest.inProgress &&
      regionId &&
      version &&
      (deploymentRegion !== regionId || deploymentVersion !== version)
    ) {
      fetchDeploymentTemplates({
        regionId,
        stackVersion: version,
        showMaxZones: true,
      })
    }

    const setVersion = getDeploymentVersionSetter({ editorState, onChange: this.onChange })

    if (!versionStacks) {
      return
    }

    invalidateDefaultVersion({
      versionStacks,
      version,
      setVersion,
    })

    const deploymentTemplates = getDeploymentTemplates(regionId, version!)
    const sortedTemplates = getSortedTemplates({ profile, flags, deploymentTemplates })

    selectTemplate({
      deploymentTemplates: sortedTemplates,
      deploymentTemplate,
      globalDeploymentTemplate,
      setDeploymentTemplate: this.setDeploymentTemplate,
      regionChanged: deploymentRegion !== regionId,
      versionChanged: deploymentVersion !== version,
    })
  }

  setRegion = ({ regionId, stackVersion }: { regionId: string; stackVersion?: string }): void => {
    const {
      fetchVersions,
      fetchSnapshotRepositories,
      fetchDeploymentTemplates,
      showSnapshotVersions,
      isOnboardingFlow,
    } = this.props

    this.onChange({ regionId })

    fetchVersions(regionId, {
      showUnusable: Boolean(isOnboardingFlow && showSnapshotVersions),
      tossOldVersions: true,
    })
    fetchSnapshotRepositories(regionId)

    if (stackVersion) {
      fetchDeploymentTemplates({
        regionId,
        stackVersion,
        showMaxZones: true,
      })
    }
  }

  setDeploymentTemplate = (nextDeploymentTemplate: DeploymentTemplateInfoV2): void => {
    const {
      getRegion,
      getActiveVersionStacks,
      inTrial,
      createDeploymentFromTemplateProps = {},
      flags,
    } = this.props
    const { editorState } = this.state
    const { deployment, deploymentTemplate } = editorState

    const region = getRegion(editorState)
    const version = getUpsertVersion(editorState)
    const stackVersions = getActiveVersionStacks(editorState)

    if (!region) {
      return
    }

    if (deploymentTemplate === nextDeploymentTemplate) {
      return // sanity
    }

    this.onChange(
      {
        deploymentTemplate: nextDeploymentTemplate,
        deployment: createDeploymentFromTemplate({
          prevState: deployment,
          deploymentTemplate: nextDeploymentTemplate,
          region: region!,
          stackVersions: stackVersions!,
          version: version!,
          inTrial,
          flags,
          ...createDeploymentFromTemplateProps,
        }),
      },
      {
        shallow: true,
      },
    )
  }

  setGlobalTemplate = (globalTemplate: GlobalDeploymentTemplateInfo): void => {
    const { getRegion, fetchDeploymentTemplate } = this.props
    const { editorState } = this.state

    const region = getRegion(editorState)

    const templateId = this.getTemplateId(region.id, globalTemplate)

    this.onChange({
      globalDeploymentTemplate: globalTemplate,
    })

    if (templateId) {
      fetchDeploymentTemplate(region.id, templateId, null)
    }
  }

  setSnapshotSettingsDisabled = () => {
    const { editorState } = this.state
    const cloned = cloneDeep(editorState)
    const { deployment } = cloned
    const settings = getDeploymentSettings({ deployment })

    if (settings) {
      settings.snapshot = {
        enabled: false,
      }
      return this.setState({ editorState: cloned })
    }
  }

  onChange = (
    changes: DeepPartial<StackDeploymentCreateRequest>,
    { shallow = false }: { shallow?: boolean } = {},
  ): void => {
    if (shallow) {
      // NOTE: when doing shallow changes, ensure you actually send us whole objects!
      return this.onChangeShallow(changes as Partial<StackDeploymentCreateRequest>)
    }

    return this.onChangeDeep(changes)
  }

  onChangeShallow = (changes: Partial<StackDeploymentCreateRequest>): void => {
    const { editorState } = this.state

    const nextState: StackDeploymentCreateRequest = {
      ...editorState,
      ...changes,
    }

    this.setState({ editorState: nextState })
  }

  onChangeDeep = (changes: DeepPartial<StackDeploymentCreateRequest>): void => {
    const { editorState } = this.state
    const nextState = mergeDeep(editorState, changes)

    this.setState({ editorState: nextState })
  }
}

export default withLDConsumer()(CreateStackDeploymentEditorDependencies)
