/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { Provider } from '@modules/cloud-api/v1/types'
import { getSaasProvidersUrl } from '@modules/cloud-api/v1/urls'

import { ThunkAction, AsyncAction } from '@/types/redux'
import { fetchProvidersRequest } from '@/reducers/asyncRequests/registry'

import asyncRequest from '../asyncRequests'
import { FETCH_PROVIDERS } from '../../constants/actions'

export function fetchProviders() {
  return asyncRequest<typeof FETCH_PROVIDERS, Provider[]>({
    type: FETCH_PROVIDERS,
    url: getSaasProvidersUrl(),
  })
}

function shouldFetchProviders(state) {
  const { isDone, inProgress } = fetchProvidersRequest(state)
  return !isDone && !inProgress
}

export function fetchProvidersIfNeeded(): ThunkAction<
  Promise<AsyncAction<typeof FETCH_PROVIDERS, Provider[]>>
> {
  return (dispatch, getState) => {
    const shouldFetch = shouldFetchProviders(getState())

    if (!shouldFetch) {
      // To satisfy the type-checker, resolve to a vaguely-AsyncAction-shaped
      // object with a non-existent payload
      return Promise.resolve({
        type: FETCH_PROVIDERS,
        meta: {},
        payload: undefined,
      })
    }

    return dispatch(fetchProviders())
  }
}
