/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPanel, EuiSpacer, EuiText, EuiStat, EuiButtonGroup } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import { useGetCostsItemsV2 } from '@modules/billing-lib/hooks'
import { processQueryParams } from '@modules/billing-api/utils'
import { useServerlessGroupBy } from '@modules/billing-lib/featureflags'

import UsageStat from '../UsageStat'
import messages from '../../messages'

import { PeriodOptions } from './constants'
import ServerlessProjects from './ServerlessProjects'
import DedicatedDeployments from './DedicatedDeployments'
import { PeriodTab } from './types'
import {
  getPreviousMonthDates,
  getCurrentMonthDates,
  getPrettyCurrentMonthDates,
  getPrettyPreviousMonthDates,
  getProjectsTotalUsage,
  getDeploymentsTotalUsage,
  getDeploymentCosts,
  getProjectCosts,
} from './utils'

import type { FunctionComponent } from 'react'

interface Props {
  organizationId: string
}

const UsageCard: FunctionComponent<Props> = ({ organizationId }) => {
  const [selectedTab, setSelectedTab] = useState<PeriodTab>(PeriodTab.Current)
  const queryDates = {
    [PeriodTab.Current]: getCurrentMonthDates(),
    [PeriodTab.Previous]: getPreviousMonthDates(),
  }[selectedTab]
  const { data, isLoading, isError } = useGetCostsItemsV2({
    pathParameters: { organization_id: organizationId },
    queryParameters: processQueryParams({
      ...queryDates,
      serverless_group_by: useServerlessGroupBy(),
    }),
  })
  const deploymentCosts = useMemo(() => getDeploymentCosts(data?.products), [data?.products])
  const projectCosts = useMemo(() => getProjectCosts(data?.products), [data?.products])

  const renderFullyManagedProjects = () => {
    if (!projectCosts) {
      return null
    }

    return (
      <ServerlessProjects
        isLoading={isLoading}
        totalUsage={getProjectsTotalUsage(data?.products)}
        costs={projectCosts}
      />
    )
  }

  const getTabTitle = (): string =>
    ({
      [PeriodTab.Current]: getPrettyCurrentMonthDates(),
      [PeriodTab.Previous]: getPrettyPreviousMonthDates(),
    }[selectedTab])

  if (isError) {
    return (
      <CuiAlert type='error' data-test-id='error-message'>
        <FormattedMessage {...messages.genericErrorMessage} />
      </CuiAlert>
    )
  }

  return (
    <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} data-test-id='usage-card'>
      <EuiButtonGroup
        legend='Month Selection'
        name='monthSelection'
        options={PeriodOptions}
        idSelected={selectedTab}
        onChange={(selectedTabId: PeriodTab) => setSelectedTab(selectedTabId)}
        buttonSize='compressed'
      />

      <EuiSpacer size='m' />

      <EuiText size='s'>
        <h2>{getTabTitle()}</h2>
      </EuiText>

      <EuiSpacer size='l' />

      <EuiStat
        isLoading={isLoading}
        title={
          <UsageStat
            title={
              <CuiElasticConsumptionUnits
                value={data?.total_ecu || 0}
                unit='none'
                withSymbol={false}
              />
            }
            subText={<FormattedMessage {...messages.ecuUnit} />}
            data-test-id='usage-card.total'
          />
        }
        titleSize='l'
        description={
          <EuiText size='s'>
            <FormattedMessage {...messages.currentUsage} />
          </EuiText>
        }
        descriptionElement='div'
      />

      <EuiSpacer size='m' />

      {renderFullyManagedProjects()}

      <EuiSpacer size='m' />

      <DedicatedDeployments
        totalUsage={getDeploymentsTotalUsage(data?.products)}
        isLoading={isLoading}
        costs={deploymentCosts}
      />
    </EuiPanel>
  )
}

export default UsageCard
