/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiProgress,
  EuiSpacer,
  useEuiTheme,
  useIsWithinBreakpoints,
} from '@elastic/eui'

import type { css } from '@emotion/react'
import type { FC, ReactNode } from 'react'

type StepPanelProps = {
  onGoBack?: () => void
  step?: number
  totalSteps?: number
  style?: ReturnType<typeof css>
  hasShadow?: boolean
  header?: ReactNode
  isPanelWidthLarger?: boolean
  transparent?: boolean
}

const StepPanel: FC<StepPanelProps> = ({
  onGoBack,
  step,
  totalSteps,
  children,
  style,
  hasShadow,
  transparent = true,
  header,
  isPanelWidthLarger = false,
}) => {
  const {
    euiTheme: {
      breakpoint: { m: euiBreakpointM },
      size: { xl: euiSizeXL, xxxl: euiSizeXXXL },
    },
  } = useEuiTheme()

  const isLargeScreen = useIsWithinBreakpoints(['l', 'xl'])

  return (
    <EuiFlexGroup direction='column' justifyContent='center' gutterSize='l' alignItems='center'>
      <EuiFlexItem>
        <EuiPanel
          paddingSize='s'
          hasShadow={hasShadow ?? false}
          css={
            style ?? {
              width: '100%',
              maxWidth: isPanelWidthLarger ? '90%' : 625,
              alignSelf: 'center',
              ...(isLargeScreen && { minHeight: 600 }),
            }
          }
          grow={false}
          {...(transparent && { color: 'transparent' })}
        >
          <EuiFlexGroup
            direction='column'
            css={{
              [`@media(min-width: ${euiBreakpointM})`]: {
                height: '100%',
              },
            }}
            alignItems='flexStart'
          >
            {step !== undefined && totalSteps !== undefined && (
              <EuiFlexItem grow={false}>
                {header}
                <EuiSpacer size='m' />
                <EuiProgress
                  css={{ width: 300 }}
                  value={((step + 1) / totalSteps) * 100}
                  max={100}
                  size='m'
                />
                {header && <EuiSpacer size='xl' />}
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>{children}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      {step !== 0 && onGoBack && (
        <EuiFlexItem
          grow={false}
          css={{ height: '100px', position: 'fixed', bottom: euiSizeXXXL, left: euiSizeXXXL }}
        >
          <EuiFlexGroup alignItems='center'>
            <EuiButtonEmpty
              css={{ margin: `0 ${euiSizeXL}` }}
              onClick={() => {
                onGoBack()
              }}
            >
              <FormattedMessage id='step-panel.go-back' defaultMessage='Go back' />
            </EuiButtonEmpty>
          </EuiFlexGroup>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default StepPanel
