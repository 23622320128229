/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { useIntl } from 'react-intl'
import { Fragment, type FunctionComponent } from 'react'

import type { EuiListGroupItemProps } from '@elastic/eui'
import {
  useEuiTheme,
  EuiCard,
  EuiText,
  EuiHorizontalRule,
  EuiSpacer,
  EuiListGroup,
  EuiTitle,
} from '@elastic/eui'

import CuiPrice from '@modules/cui/formatters/CuiPrice'
import type { Subscription } from '@modules/ui-types/billing'

import messages from '../messages'

import SeePricingPage from './SeePricingPage'

export interface Props {
  subscription: Subscription
  selected: boolean
  onClick: (Subscription) => void
  showPrice?: boolean
  isDisabled?: boolean
  isPrepaid?: boolean
}

const SubscriptionCard: FunctionComponent<Props> = ({
  subscription,
  onClick,
  selected,
  showPrice = false,
  isDisabled,
  isPrepaid,
}) => {
  const {
    value,
    prettyName,
    serverlessFeatures,
    hostedFeatures,
    hostedDescription,
    recommended,
    hourlyRate,
    supportName,
    supportFeatures,
  } = subscription
  const { formatMessage } = useIntl()
  const { euiTheme } = useEuiTheme()

  const listGroupCss = css`
    .euiListGroupItem__text {
      font-weight: ${euiTheme.font.weight.regular};
    }
  `

  const renderPrice = () => {
    if (showPrice) {
      if (hourlyRate && hourlyRate > 0) {
        return (
          <EuiText color='subdued' size='xs' textAlign='left' data-test-id='hourly-rate'>
            {formatMessage(messages.from)}{' '}
            <span css={css({ fontWeight: euiTheme.font.weight.bold })}>
              <CuiPrice
                value={hourlyRate}
                unit='none'
                currency={isPrepaid ? 'ECU' : 'USD'}
                dp={4}
              />
              / hr
            </span>
          </EuiText>
        )
      }

      return <SeePricingPage level={subscription.value} />
    }

    return null
  }

  const getListItems = (labels: string[]): EuiListGroupItemProps[] =>
    labels.map((label) => ({
      label,
      iconType: 'check',
      iconProps: { color: 'success' },
    }))

  const renderSupportFeatures = () => (
    <Fragment>
      <EuiText
        color='subdued'
        size='xs'
        textAlign='left'
        css={css({ fontWeight: euiTheme.font.weight.bold })}
      >
        {supportName}
      </EuiText>

      <EuiSpacer size='m' />

      <EuiListGroup
        flush={true}
        listItems={getListItems(supportFeatures)}
        color='subdued'
        size='xs'
        wrapText={true}
        css={listGroupCss}
      />
    </Fragment>
  )

  const renderHostedFeatures = () => (
    <Fragment>
      <EuiText
        color='subdued'
        size='xs'
        textAlign='left'
        css={css({ fontWeight: euiTheme.font.weight.bold })}
      >
        {formatMessage(messages.hostedTitle)}
      </EuiText>

      <EuiSpacer size='m' />

      {hostedDescription && (
        <Fragment>
          <EuiText color='subdued' size='xs'>
            {hostedDescription}
          </EuiText>
          <EuiSpacer size='m' />
        </Fragment>
      )}

      <EuiListGroup
        flush={true}
        listItems={getListItems(hostedFeatures)}
        color='subdued'
        size='xs'
        wrapText={true}
        css={listGroupCss}
      />
    </Fragment>
  )

  const renderServerlessFeatures = () => (
    <Fragment>
      <EuiText
        color='subdued'
        size='xs'
        textAlign='left'
        css={css({ fontWeight: euiTheme.font.weight.bold })}
      >
        {formatMessage(messages.serverlessTitle)}
      </EuiText>

      <EuiSpacer size='m' />

      <EuiListGroup
        flush={true}
        listItems={getListItems(serverlessFeatures)}
        color='subdued'
        size='xs'
        wrapText={true}
        css={listGroupCss}
      />
    </Fragment>
  )

  return (
    <EuiCard
      // hacky but... 1. "Recommended" badge adds additional padding which looks weird 2. Disable state centers the title so I have to overwrite the EUI style
      css={css({
        paddingBlockStart: 16,
        textAlign: 'left',
        ...(selected && { borderColor: euiTheme.colors.primary }),
      })}
      data-test-id={`subscription-card-${subscription.value}`}
      hasBorder={true}
      title={
        <EuiTitle size='xs' css={css({ textAlign: 'left' })}>
          <span>{prettyName}</span>
        </EuiTitle>
      }
      selectable={{
        onClick: () => onClick(value),
        isSelected: selected,
      }}
      {...(recommended ? { betaBadgeProps: { label: formatMessage(messages.recommended) } } : {})}
      isDisabled={isDisabled}
    >
      {renderPrice()}
      <EuiHorizontalRule margin='m' />

      {renderSupportFeatures()}

      <EuiHorizontalRule margin='m' />

      {renderServerlessFeatures()}

      <EuiHorizontalRule margin='m' />

      {renderHostedFeatures()}

      <EuiSpacer size='m' />
    </EuiCard>
  )
}

export default SubscriptionCard
