/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { FoundUser } from '@modules/ui-types'

import { NEW_TEMP_SHIELD_USER_SUCCESS, RESET_TEMP_SHIELD_USER } from '../../constants/actions'

import type { ReduxState } from '@/types/redux'
import type { ShieldUserAction } from './clusterTypes'

export default function newTempShieldUser(
  state: FoundUser | null = null,
  action: ShieldUserAction,
): FoundUser | null {
  if (action.type === NEW_TEMP_SHIELD_USER_SUCCESS && action.payload != null) {
    return { ...action.payload }
  }

  if (action.type === RESET_TEMP_SHIELD_USER) {
    return null
  }

  return state
}

export const getNewTempShieldUser = (state: ReduxState) => state.newTempShieldUser
