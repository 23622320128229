/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getLowestSliderVersion } from '@/lib/stackDeployments/upgradesToSliders'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getUpgradeAssistantStatus } from '@/reducers/upgradeAssistantStatus'
import { getDeploymentTemplate } from '@/reducers/deploymentTemplates'
import { getVersionStacks, getVersionStack } from '@/reducers/elasticStack'
import { getDeploymentExtensions } from '@/reducers/deploymentExtensions'
import {
  updateStackDeploymentDryRunRequest,
  updateStackDeploymentRequest,
  fetchUpgradeAssistantStatusRequest,
} from '@/reducers/asyncRequests/registry'

import {
  fetchDeployment,
  resetUpdateDeployment,
  updateDeployment,
} from '../../../../../actions/stackDeployments'
import { startAppSearchToEnterpriseSearchMigration } from '../../../../../actions/appSearchToEnterpriseSearchMigration'
import { fetchExtensions } from '../../../../../actions/deploymentExtensions'
import { getConfigForKey } from '../../../../../store'

import DeploymentVersionUpgradeModal from './DeploymentVersionUpgradeModal'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const regionId = getRegionId({
    deployment,
  })!
  const templateId = getDeploymentTemplateId({
    deployment,
  })!

  // If a deployment is partially upgraded, we pick the lowest
  // found version found in each resource to upgrade from.
  const lowestVersion = getLowestSliderVersion({ deployment })!

  const versionStack = getVersionStack(state, regionId, lowestVersion)
  const versionStacks = getVersionStacks(state, regionId)

  return {
    availableVersions: versionStack?.upgradable_to,
    extensions: getConfigForKey(`APP_NAME`) === `userconsole` ? getDeploymentExtensions(state) : [],
    updateStackDeploymentRequest: updateStackDeploymentRequest(state, deployment.id),
    updateStackDeploymentDryRunRequest: updateStackDeploymentDryRunRequest(state, deployment.id),
    upgradeAssistantStatus: getUpgradeAssistantStatus(state, deployment.id),
    fetchUpgradeAssistantStatusRequest: fetchUpgradeAssistantStatusRequest(state, deployment.id),
    versionStacks,
    deploymentTemplate: getDeploymentTemplate(state, regionId, templateId, lowestVersion),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchExtensions: () => dispatch(fetchExtensions()),
  fetchDeployment: (args) => dispatch(fetchDeployment(args)),
  resetUpdateDeployment: (deploymentId: string) => dispatch(resetUpdateDeployment(deploymentId)),
  updateDeployment: (args) => dispatch(updateDeployment(args)),
  startAppSearchToEnterpriseSearchMigration: (args) =>
    dispatch(startAppSearchToEnterpriseSearchMigration(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentVersionUpgradeModal)
