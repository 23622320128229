/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { EuiPopover } from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'

import type { ReactElement } from 'react'

type CuiToggleablePopoverForClassCompProps = Omit<
  React.ComponentProps<typeof EuiPopover>,
  'button' | 'isOpen' | 'closePopover'
> & {
  toggleButton: (togglePopoverFunc: () => void) => ReactElement
}

export default function (props: CuiToggleablePopoverForClassCompProps) {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  const { toggleButton, children, ...popoverProps } = props

  return (
    <EuiPopover
      {...popoverProps}
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen(false)}
      button={toggleButton(togglePopover)}
    >
      {children}
    </EuiPopover>
  )
}
