/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalBody,
  EuiModalHeader,
  EuiModalFooter,
  EuiText,
  EuiSpacer,
  EuiHorizontalRule,
  EuiImage,
  EuiPanel,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui'

import type { DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import type { UserProfile, StackDeployment } from '@modules/ui-types'
import { isTrialNotStartedUser } from '@modules/billing-lib/utils'

import unpackFeaturesIllustration from '@/files/illustration-feature-packed.svg'
import unpackFeaturesIllustrationDark from '@/files/illustration-feature-packed-dark.svg'

import UsageSection from './UsageSection'
import HelperLinks from './HelperLinks'
import RemainingTrial from './RemainingTrial'
import SubscribeButton from './SubscribeButton'
import ExtendTrialSection from './ExtendTrialSection'

import type { FC } from 'react'

export interface Props {
  closeModal: () => void
  profile: UserProfile
  deployment: StackDeployment
  deploymentTemplate: DeploymentTemplateInfoV2
}

const TrialSummaryWithDeployment: FC<Props> = ({
  deployment,
  deploymentTemplate,
  profile,
  closeModal,
}) => {
  const { formatMessage } = useIntl()

  const { colorMode } = useEuiTheme()

  const { trialDaysRemaining, hasExpiredTrial, level } = profile

  return (
    <Fragment>
      <EuiModalHeader>
        <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='none'>
          <EuiFlexItem grow={false}>
            <EuiText
              size='s'
              data-test-id='trial-summary-modal.title'
              data-test-subj='trial-summary-with-deployment'
            >
              <h3>
                <ModalTitle hasExpiredTrial={hasExpiredTrial} />
              </h3>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <RemainingTrial trialDaysRemaining={trialDaysRemaining} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalHeader>

      <EuiSpacer size='s' />

      <EuiModalBody>
        <EuiFlexGroup gutterSize='xl' css={css({ height: '100%' })}>
          <EuiFlexItem grow={7}>
            <UsageSection
              deployment={deployment}
              deploymentTemplate={deploymentTemplate}
              level={level}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={5}>
            <EuiPanel grow={false}>
              <EuiTitle size='xxs'>
                <h5>
                  <FormattedMessage
                    id='trial-summary-modal.link-section.title'
                    defaultMessage='Adjust and manage price'
                  />
                </h5>
              </EuiTitle>
              <HelperLinks
                deploymentId={deployment.id}
                trialStarted={!isTrialNotStartedUser(profile)}
                trialExpired={profile.hasExpiredTrial}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup direction='column'>
          <EuiHorizontalRule margin='l' />

          <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='m'>
            <EuiFlexItem grow={false} css={css({ alignItems: 'center' })}>
              <EuiImage
                src={
                  { DARK: unpackFeaturesIllustrationDark, LIGHT: unpackFeaturesIllustration }[
                    colorMode
                  ]
                }
                width={62}
                height={62}
                alt={formatMessage({
                  id: 'trial-summary-modal.features-box.illustration',
                  defaultMessage: 'An illustration of a packed box with features.',
                })}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size='s' color='subdued'>
                <FormattedMessage
                  id='trial-summary-modal.promo-text'
                  defaultMessage='Love what you’re using? Want access to all the features? Subscribe now.'
                />
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <SubscribeButton onSendBillingDetailsSuccess={closeModal} />
            </EuiFlexItem>
          </EuiFlexGroup>

          {hasExpiredTrial && (
            <div css={css({ textAlign: 'center' })}>
              <EuiHorizontalRule margin='l' />
              <ExtendTrialSection profile={profile} onTrialExtensionSuccess={closeModal} />
            </div>
          )}
        </EuiFlexGroup>
      </EuiModalFooter>
    </Fragment>
  )
}

const ModalTitle: FC<{ hasExpiredTrial: boolean }> = ({ hasExpiredTrial }) => {
  if (hasExpiredTrial) {
    return (
      <FormattedMessage
        id='trial-summary-modal.expired-trial-title'
        defaultMessage='Expired trial overview'
      />
    )
  }

  return (
    <FormattedMessage id='trial-summary-modal.active-trial-title' defaultMessage='Trial overview' />
  )
}

export default TrialSummaryWithDeployment
