/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { css } from '@emotion/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonIcon, EuiPopover, EuiText } from '@elastic/eui'

import type {
  DeploymentGetResponse,
  DiscreteSizes,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import type {
  AnyTopologyElement,
  SliderInstanceType,
  SliderNodeType,
  VersionNumber,
} from '@modules/ui-types'
import { useToggle } from '@modules/utils/hooks/useToggle'

import { isEnabledConfiguration } from '@/lib/deployments/conversion'
import { getSliderDefinition } from '@/lib/sliders/definitions'
import { getTopologyElementName } from '@/lib/sliders/messages'
import {
  getSliderNodeTypeForTopologyElement,
  isData,
} from '@/lib/stackDeployments/selectors/nodeRoles'

import prettySize from '../../../../../../lib/prettySize'

import type { IntlShape } from 'react-intl'

// Most frozen tiers have a ratio of 80 between memory and disk storage. And generally we go for 5% cache on disk to actual data in blob storage.
// So we get 80 * 20 (from the 5%) and that gives us 1600
export const blobStorageMultiplier = 1600

export const getTopologyTitle = (
  topologyElement: AnyTopologyElement,
  instanceConfiguration: InstanceConfiguration,
  version: VersionNumber | null,
  formatMessage: IntlShape['formatMessage'],
) => {
  const sliderInstanceType = instanceConfiguration.instance_type

  const sliderName = getTopologyElementName(
    { sliderInstanceType, topologyElement, version },
    formatMessage,
  )
  const instanceNoun = isData({ topologyElement })
    ? formatMessage({
        id: 'deploymentInfrastructure-topologyElement-tier',
        defaultMessage: 'tier',
      })
    : formatMessage({
        id: 'deploymentInfrastructure-topologyElement-instances',
        defaultMessage: 'instances',
      })

  return formatMessage(
    {
      id: 'deploymentInfrastructure-topologyElement-name',
      defaultMessage: '{sliderName} {instanceNoun}',
    },
    {
      sliderName,
      instanceNoun,
    },
  )
}

const keyLabels = {
  memory: `RAM`,
  storage: `storage`,
  cpu: `vCPU`,
}

const maxSizeForBoosting = 8192

export const TopologyElementDescription: React.FunctionComponent<{
  topologyElement: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  descriptionOverride?: React.ReactNode
  version: VersionNumber | null
}> = ({ topologyElement, instanceConfiguration, descriptionOverride, version }) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  if (descriptionOverride) {
    return (
      <EuiText color='subdued' size='s'>
        {descriptionOverride}
      </EuiText>
    )
  }

  const sliderInstanceType = instanceConfiguration.instance_type

  const sliderNodeType = getSliderNodeTypeForTopologyElement({ topologyElement })

  const definition = getSliderDefinition({ sliderInstanceType, sliderNodeType, version })

  const description = <FormattedMessage {...definition.messages.instanceConfigurationDescription} />
  const extraDescription = definition.instanceConfigurationExtraDescription

  return (
    <EuiText color='subdued' size='s'>
      {description}
      {extraDescription ? (
        <EuiPopover
          panelPaddingSize='m'
          button={
            <EuiButtonIcon
              color='primary'
              onClick={togglePopover}
              iconType='iInCircle'
              aria-label='more information'
            />
          }
          isOpen={isPopoverOpen}
          closePopover={() => setPopoverOpen(false)}
        >
          <EuiText size='s' css={css({ maxWidth: `20rem` })}>
            {extraDescription}
          </EuiText>
        </EuiPopover>
      ) : null}
    </EuiText>
  )
}

export function getKeys({
  sliderInstanceType,
  instanceResource,
  storageMultiplier,
  sliderNodeType,
}: {
  instanceResource: DiscreteSizes['resource']
  sliderInstanceType?: SliderInstanceType
  storageMultiplier?: number
  sliderNodeType?: SliderNodeType
}): {
  primaryKey: DiscreteSizes['resource']
  secondaryKey?: DiscreteSizes['resource']
} {
  if (sliderNodeType === `tiebreaker`) {
    return {
      primaryKey: `memory`,
    }
  }

  // always render memory for storage-specified sizes
  if (instanceResource === `storage`) {
    return {
      primaryKey: `storage`,
      secondaryKey: `memory`,
    }
  }

  const showStorage = isStorageRelevant({
    sliderNodeType,
    sliderInstanceType,
  })

  if (instanceResource === `memory` && storageMultiplier && showStorage) {
    return {
      primaryKey: `storage`,
      secondaryKey: `memory`,
    }
  }

  // otherwise memory is the only relevant thing
  return {
    primaryKey: `memory`,
  }
}

export function getNumber({
  resourceIn: instanceResource,
  resourceOut: resourceType,
  totalSize,
  storageMultiplier,
  isBlobStorage = false,
}: {
  resourceIn: DiscreteSizes['resource']
  resourceOut: DiscreteSizes['resource']
  totalSize: number
  storageMultiplier: number | undefined
  isBlobStorage: boolean
}) {
  if (resourceType !== instanceResource) {
    if (resourceType === `memory` && storageMultiplier) {
      return totalSize / storageMultiplier
    }

    if (resourceType === `storage` && storageMultiplier) {
      return totalSize * (isBlobStorage ? blobStorageMultiplier : storageMultiplier)
    }
  }

  return totalSize
}

function isStorageRelevant({
  sliderInstanceType,
  sliderNodeType,
}: {
  sliderInstanceType?: SliderInstanceType
  sliderNodeType?: SliderNodeType
}) {
  // We don't care about storage for these instances
  if (
    sliderInstanceType &&
    (sliderInstanceType === `kibana` ||
      sliderInstanceType === `apm` ||
      sliderInstanceType === `enterprise_search` ||
      sliderInstanceType === `appsearch` ||
      sliderInstanceType === `integrations_server`)
  ) {
    return false
  }

  if (sliderNodeType === `ml` || sliderNodeType === `ingest` || sliderNodeType === `master`) {
    return false
  }

  // If all else fails, showing more information is best
  return true
}

export function getRawCpu({
  cpuMultiplier,
  totalSize,
  zoneCount,
  skipSizeCheck,
}: {
  cpuMultiplier: number
  totalSize: number
  zoneCount?: number
  skipSizeCheck: boolean // we check for size to see if boosting is required. By turning it off, we get the "base" value without boosting
}) {
  if (totalSize === 0) {
    return 0 // if the "size" variable is 0 then we don't want to be returning any non-zero value for vCPU
  }

  // Calculate boosted CPU values for instances <= 8GB
  if (!skipSizeCheck && totalSize <= maxSizeForBoosting) {
    const cpuValuePerZone = Math.floor(16 * cpuMultiplier * 10) / 10

    if (zoneCount) {
      const totalCpuValue = Math.floor(cpuValuePerZone * zoneCount * 10) / 10
      return totalCpuValue
    }

    return cpuValuePerZone
  }

  // Calculate CPU values for instances > 8GB
  const gbSize = totalSize / 1024
  const cpuValuePerZone = Math.floor(gbSize * cpuMultiplier * 10) / 10

  if (zoneCount) {
    const totalCpuValue = Math.floor(cpuValuePerZone * zoneCount * 10) / 10
    return `${totalCpuValue}`
  }

  return cpuValuePerZone
}

function getCpu({
  cpuMultiplier,
  totalSize,
  zoneCount,
}: {
  cpuMultiplier: number
  totalSize: number
  zoneCount?: number
}) {
  const rawCpu = getRawCpu({ cpuMultiplier, totalSize, zoneCount, skipSizeCheck: false })

  if (totalSize <= maxSizeForBoosting) {
    return `Up to ${rawCpu}`
  }

  return rawCpu
}

function getMLCpuDisplay({
  cpuMultiplier,
  totalSize,
  zoneCount,
}: {
  cpuMultiplier: number
  totalSize: number
  zoneCount?: number
}): string {
  const baseCpu = getRawCpu({
    cpuMultiplier,
    totalSize,
    zoneCount,
    skipSizeCheck: true,
  })
  const boostedCpu = getRawCpu({
    cpuMultiplier,
    totalSize,
    zoneCount,
    skipSizeCheck: false,
  })

  if (totalSize === 0) {
    return `0 vCPU`
  }

  if (totalSize <= maxSizeForBoosting) {
    return `${baseCpu} vCPU up to ${boostedCpu} vCPU`
  }

  return `${boostedCpu} vCPU`
}

export function getSizeOptionText({
  value,
  primaryKey,
  secondaryKey,
  instanceResource,
  storageMultiplier,
  cpuMultiplier,
  isBlobStorage,
  isMachineLearning,
}: {
  instanceResource: DiscreteSizes['resource']
  storageMultiplier?: number
  cpuMultiplier?: number
  value: number
  primaryKey: DiscreteSizes['resource']
  secondaryKey?: DiscreteSizes['resource']
  isBlobStorage: boolean
  isMachineLearning: boolean
}): string {
  const primaryText = `${prettySize(
    getNumber({
      resourceIn: instanceResource,
      storageMultiplier,
      totalSize: value,
      resourceOut: primaryKey,
      isBlobStorage,
    }),
  )} ${keyLabels[primaryKey || `memory`]}`

  const secondaryText =
    secondaryKey && storageMultiplier
      ? ` | ${prettySize(
          getNumber({
            resourceIn: instanceResource,
            storageMultiplier,
            totalSize: value,
            resourceOut: secondaryKey,
            isBlobStorage,
          }),
        )} ${keyLabels[secondaryKey]}`
      : ``

  const cpuText = getCpuDisplayText({
    totalSize: value,
    isMachineLearning,
    cpuMultiplier,
  })

  const displayCpuText = cpuText === `` ? `` : ` | ${cpuText}`

  return `${primaryText}${secondaryText}${displayCpuText}`
}

export function getCpuDisplayText({
  totalSize,
  zoneCount,
  isMachineLearning,
  cpuMultiplier,
}: {
  totalSize: number
  zoneCount?: number
  isMachineLearning: boolean
  cpuMultiplier?: number
}) {
  if (!cpuMultiplier) {
    return ``
  }

  if (isMachineLearning) {
    return getMLCpuDisplay({ cpuMultiplier, totalSize, zoneCount })
  }

  return `${getCpu({ cpuMultiplier, totalSize, zoneCount })} ${keyLabels.cpu}`
}

export function getRenderOptions({
  instanceResource,
  storageMultiplier,
  cpuMultiplier,
  value,
  primaryKey,
  secondaryKey,
  isBlobStorage,
  isMachineLearning,
}: {
  instanceResource: DiscreteSizes['resource']
  storageMultiplier: number | undefined
  cpuMultiplier: number | undefined
  value: number
  primaryKey: DiscreteSizes['resource']
  secondaryKey?: DiscreteSizes['resource']
  isBlobStorage: boolean
  isMachineLearning: boolean
}) {
  const primaryText = `${prettySize(
    getNumber({
      resourceIn: instanceResource,
      storageMultiplier,
      totalSize: value,
      resourceOut: primaryKey,
      isBlobStorage,
    }),
  )} ${keyLabels[primaryKey || `memory`]}`
  const secondaryText =
    storageMultiplier && secondaryKey
      ? `${prettySize(
          getNumber({
            resourceIn: instanceResource,
            storageMultiplier,
            totalSize: value,
            resourceOut: secondaryKey,
            isBlobStorage,
          }),
        )} ${keyLabels[secondaryKey]}`
      : ``

  const cpuText = getCpuDisplayText({
    totalSize: value,
    isMachineLearning,
    cpuMultiplier,
  })

  return {
    primary_key: primaryKey,
    secondary_key: secondaryKey,
    primary_text: primaryText,
    secondary_text: secondaryText,
    cpu_text: cpuText,
  }
}

/*
 * A function to check if there are any instances of type "topologyElement" in the deployment right now, BUT there shouldn't be as told by the _current_
 * plan. Returns true if both conditions are met.
 */
export function hasInstancesInDeploymentOfTypeButNotInCurrentPlan({
  deploymentUnderEdit,
  topologyElement,
  instanceConfiguration,
}: {
  deploymentUnderEdit?: DeploymentGetResponse
  topologyElement: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
}): boolean {
  if (!deploymentUnderEdit) {
    return false
  }

  // couple safety checks for when a genesis plan fails
  if (
    instanceConfiguration.instance_type &&
    (deploymentUnderEdit.resources[instanceConfiguration.instance_type].length === 0 ||
      !deploymentUnderEdit.resources[instanceConfiguration.instance_type][0]?.info.plan_info
        .current)
  ) {
    return false
  }

  // find the topologyElement in the _current_ plan, ie not the plan being edited right now by the user, but the actual plan in place for the deployment
  const allTopologyElementsInCurrentPlan: AnyTopologyElement[] | undefined =
    instanceConfiguration.instance_type
      ? deploymentUnderEdit.resources[instanceConfiguration.instance_type][0]?.info.plan_info
          .current?.plan?.cluster_topology
      : undefined
  const topologyElementInCurrentPlan = allTopologyElementsInCurrentPlan?.find(
    (instance) => instance.id === topologyElement.id,
  )

  // is this topology element enabled in the _current_ plan?
  const isEnabledInCurrentPlan = topologyElementInCurrentPlan
    ? isEnabledConfiguration(topologyElementInCurrentPlan)
    : false

  const currentInstances = instanceConfiguration.instance_type
    ? deploymentUnderEdit.resources[instanceConfiguration.instance_type][0]?.info.topology.instances
    : undefined

  if (!currentInstances) {
    return false
  }

  // are there any instances in the actual deployment of this type, irrespective of the plan
  const matchingInstances = currentInstances.filter(
    (instance) => instance.instance_set_id === topologyElement.id,
  )

  // we want to return true if there are some actual instances in the deployment of this type, BUT the _current_ plan doesn't call for them to exist
  return matchingInstances && matchingInstances.length > 0 && !isEnabledInCurrentPlan
}
