/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import ProjectInitializationScreen from '../discovery-questions-pages/ProjectInitializationScreen'
import DeploymentInitializationScreen from '../discovery-questions-pages/DeploymentInitializationScreen'

import OnboardingPage from '.'

import type { RouteConfig } from 'react-router-config'

export default function getOnboardingRoutes(): RouteConfig[] {
  return [
    {
      path: '/onboarding/:resourceType/:questionId?',
      component: OnboardingPage,
      title: 'Get Started with Elastic Cloud',
    },
    {
      path: '/onboarding/projects/create/:solution/:id/starting',
      component: ProjectInitializationScreen,
      title: 'Creating your new project',
    },
    {
      path: '/onboarding/stateful/create/:id/starting',
      component: DeploymentInitializationScreen,
      title: 'Creating your new deployment',
    },
  ]
}
