/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import { useExtendCurrentUserTrialMutation } from '@modules/profile-lib/hooks'
import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import { useGetEntitlementsV1 } from '@modules/billing-lib/hooks'
import { addToast } from '@modules/cui/Toasts'

type Props = {
  saasUser: SaasUserResponse
}

const ExtendTrial: React.FunctionComponent<Props> = ({ saasUser }) => {
  const organizationId = saasUser.user.organization_id

  const entitlementsQuery = useGetEntitlementsV1(organizationId, {
    enabled: Boolean(organizationId),
  })

  const mutation = useExtendCurrentUserTrialMutation()

  const onTrialExtended = () => {
    addToast({
      family: 'trialExtended',
      color: 'success',
      title: (
        <FormattedMessage id='trialSummary.trialExtendedToast' defaultMessage='Trial extended' />
      ),
    })
  }

  const isTrialExtendable = entitlementsQuery.data?.trial_extendable ?? false

  return (
    <EuiFlexGroup direction='column' gutterSize='m'>
      <EuiFlexItem>
        <EuiFlexGroup justifyContent='center' alignItems='baseline' gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiText size='xs'>
              <FormattedMessage
                id='trialSummary.needMoreTime'
                defaultMessage='Need more time? <link>Request a trial extension</link>'
                values={{
                  link: (msg: string) => (
                    /* eslint-disable-next-line @elastic/eui/href-or-on-click */
                    <EuiLink
                      onClick={() => {
                        if (!isTrialExtendable) {
                          return
                        }

                        mutation.mutate(undefined, { onSuccess: onTrialExtended })
                      }}
                      href={
                        isTrialExtendable
                          ? undefined
                          : `https://info.elastic.co/cloud-trial-extension.html`
                      }
                      target='_blank'
                      data-test-id={
                        isTrialExtendable
                          ? 'extend-trial.request-trial-link'
                          : 'extend-trial.request-trial-external-link'
                      }
                    >
                      {msg}
                    </EuiLink>
                  ),
                }}
              />
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {mutation.isError && (
        <EuiFlexItem>
          <CuiAlert type='error'>{mutation.error.errors[0]?.message}</CuiAlert>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default ExtendTrial
