/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { authorizeSaasOauthTokenRequest } from '@/apps/userconsole/reducers/registry'
import { buildLoginUrl } from '@/lib/urlUtils'
import { extractIdpFromCookie } from '@/actions/auth/auth'

import { authorizeSaasOauthToken, redirectAfterLogin } from '../../../actions/auth'

import OAuthLogin from './OAuthLogin'

import type { ConsumerProps, StateProps, DispatchProps } from '@/components/Login/OAuthLogin/types'

const mapStateToProps = (state, { history }: ConsumerProps): StateProps => {
  const { location } = history
  const locationQueryString = location.search

  return {
    authorizeSaasOauthTokenRequest: authorizeSaasOauthTokenRequest(state),
    idp: extractIdpFromCookie(),
    loginUrl: buildLoginUrl({ locationQueryString }),
    history,
  }
}

const mapDispatchToProps: DispatchProps = {
  authorizeSaasOauthToken,
  redirectAfterLogin,
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(OAuthLogin),
)
