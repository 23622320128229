/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { z } from 'zod'

import type { AuthContextData } from '@modules/auth/context'
import AuthContext from '@modules/auth/context'
import { useTypedLocalStorage } from '@modules/utils/useTypedLocalStorage'

import type { MiddlewareComponent } from '../types'

const LOCAL_STORAGE_KEY = 'authContext'

const schema = z.object({
  method: z.enum(['username-and-password', 'google', 'microsoft', 'byoidp']).nullable(),
}) satisfies z.ZodSchema<AuthContextData>

/**
 * Gets and sets matadata around the authentication process for the current session.
 */
const AuthMiddleware: MiddlewareComponent = ({ children }) => {
  const [authContext, setAuthContext] = useTypedLocalStorage(LOCAL_STORAGE_KEY, schema, {
    method: null,
  })

  return (
    <AuthContext.Provider value={{ authContext, setAuthContext }}>{children}</AuthContext.Provider>
  )
}

AuthMiddleware.middleware = {
  displayName: 'AuthMiddleware',
  dependencies: [],
}

export default AuthMiddleware
