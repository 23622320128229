/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { genSaltSync, hashSync } from 'bcryptjs'
import moment from 'moment'

import type { ClusterCredentials, DeploymentCreateResponse } from '@modules/cloud-api/v1/types'
import type { FoundUser } from '@modules/ui-types'

import { getCreatedResourceByRefId } from '@/lib/stackDeployments/selectors/creates'

export function getEsCredentialsFromCreateResponse({
  createResponse,
}: {
  createResponse: DeploymentCreateResponse | undefined
}): ClusterCredentials | undefined {
  const esCluster = createResponse
    ? getCreatedResourceByRefId({
        deployment: createResponse,
        refId: `main-elasticsearch`,
      })
    : undefined

  return esCluster?.credentials
}

export function createNewShieldUser({
  username,
  password = genSaltSync(10),
  validUntil = moment().add(1, 'hour'),
  roles = ['superuser'],
}: {
  username: string
  password?: string
  validUntil?: moment.Moment
  roles?: string[]
}): FoundUser {
  const cloudUsername = `cloud-internal-${username}`

  const hash = hashSync(password, genSaltSync(10))
  return {
    roles,
    password,
    password_hash: hash,
    username: cloudUsername,
    valid_until: validUntil.toISOString(),
  }
}
