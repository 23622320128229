/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl'

import {
  EuiAccordion,
  EuiButton,
  EuiCallOut,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiSteps,
  EuiText,
} from '@elastic/eui'

import CopyButton from '@modules/cui/CopyButton'
import type { StackDeployment } from '@modules/ui-types'
import type { AccountTrustRelationship } from '@modules/cloud-api/v1/types'

import { securityUrl } from '@/lib/urlBuilder'

import type { FunctionComponent } from 'react'

type Props = WrappedComponentProps & {
  deployment: StackDeployment
  trustRelationship: AccountTrustRelationship
}

const messages = defineMessages({
  step1Title: {
    id: `accountTrustRelationshipInstructions.step1.title`,
    defaultMessage: `Save the details of this environment`,
  },
  step2Title: {
    id: `accountTrustRelationshipInstructions.step2.title`,
    defaultMessage: `Set up trust from the other environment`,
  },
  step1OrganizationId: {
    id: `accountTrustRelationshipInstructions.step1.organizationId`,
    defaultMessage: `Organization ID`,
  },
  step1DeploymentId: {
    id: `accountTrustRelationshipInstructions.step1.deploymentId`,
    defaultMessage: `Deployment ID`,
  },
  step1DeploymentExplanation: {
    id: `accountTrustRelationshipInstructions.step1.deploymentExplanation`,
    defaultMessage: `Optional, if you want to specifically trust this deployment.`,
  },
})

const AccountRelationshipInstructions: FunctionComponent<Props> = ({
  deployment,
  intl: { formatMessage },
  trustRelationship,
}) => (
  <Fragment>
    <EuiCallOut color='success'>
      <EuiText>
        <FormattedMessage
          id='deploymentTrustManagement.direct-cert.success'
          defaultMessage='Trusted environment successfully added'
        />
      </EuiText>
    </EuiCallOut>
    <EuiSpacer size='l' />
    <EuiText>
      <p>
        <FormattedMessage
          id='deploymentTrustManagement.direct-cert.intro'
          defaultMessage='Have you already set up trust from the other environment?'
        />
      </p>
    </EuiText>
    <EuiSpacer size='l' />
    <EuiPanel>
      <EuiAccordion
        id={`instructions-yes`}
        buttonContent={
          <b>
            <FormattedMessage
              id='deploymentTrustManagement.direct-cert.yes'
              defaultMessage='Yes, I have already set up trust from the other environment.'
            />
          </b>
        }
      >
        <EuiText>
          <p>
            <EuiSpacer size='s' />
            <FormattedMessage
              id='deploymentTrustManagement.direct-cert.yes-body'
              defaultMessage='You’re all set. You can now add remote clusters in Kibana and perform cross-cluster operations between both environments.'
            />
          </p>
        </EuiText>
      </EuiAccordion>
    </EuiPanel>
    <EuiSpacer size='l' />
    <EuiPanel>
      <EuiAccordion
        id={`instructions-no`}
        buttonContent={
          <b>
            <FormattedMessage
              id='deploymentTrustManagement.direct-cert.no'
              defaultMessage='No, I have NOT set up trust from the other environment yet.'
            />
          </b>
        }
      >
        <EuiText>
          <p>
            <EuiSpacer size='s' />
            <FormattedMessage
              id='deploymentTrustManagement.direct-cert.no-body'
              defaultMessage='To enable cross-cluster operations, both environments must trust each other. That means that if you haven’t already done so, you also need to perform this configuration in the newly trusted environment. To do this:'
            />
          </p>
        </EuiText>
        <EuiSpacer />
        <EuiSteps
          steps={[
            {
              title: formatMessage(messages.step1Title),
              children: renderStep1(deployment),
            },
            {
              title: formatMessage(messages.step2Title),
              children: renderStep2(trustRelationship),
            },
          ]}
        />
      </EuiAccordion>
    </EuiPanel>
    <EuiSpacer />
    <EuiFlexGroup direction='column' alignItems='flexEnd'>
      <EuiFlexItem>
        <EuiButton fill={true} href={securityUrl(deployment.id)}>
          <FormattedMessage id='directTrustRelationshipInstructions.done' defaultMessage='Done' />
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  </Fragment>
)

function renderStep1(deployment: StackDeployment): JSX.Element {
  return (
    <Fragment>
      <EuiText>
        <FormattedMessage
          id='accountTrustRelationshipInstructions.step1.body'
          defaultMessage="You'll need them in the other environment."
        />
      </EuiText>

      <EuiSpacer />

      <EuiFormRow label={<FormattedMessage {...messages.step1OrganizationId} />}>
        <EuiCodeBlock paddingSize='m'>
          <EuiFlexGroup alignItems='center' gutterSize='xs'>
            <EuiFlexItem grow={false}>
              <EuiText size='s'>{deployment.metadata?.organization_id}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ marginTop: -2 }}>
              <CopyButton asLink={true} value={deployment.metadata?.organization_id || ``}>
                <EuiIcon type='copyClipboard' />
              </CopyButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiCodeBlock>
      </EuiFormRow>

      <EuiSpacer />

      <EuiFormRow
        label={<FormattedMessage {...messages.step1DeploymentId} />}
        helpText={<FormattedMessage {...messages.step1DeploymentExplanation} />}
      >
        <EuiCodeBlock paddingSize='m'>
          <EuiFlexGroup alignItems='center' gutterSize='xs'>
            <EuiFlexItem grow={false}>
              <EuiText size='s'>{deployment.id}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ marginTop: -2 }}>
              <CopyButton asLink={true} value={deployment.id}>
                <EuiIcon type='copyClipboard' />
              </CopyButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiCodeBlock>
      </EuiFormRow>
    </Fragment>
  )
}

function renderStep2(trustRelationship: AccountTrustRelationship): JSX.Element {
  return (
    <EuiText>
      <FormattedMessage
        id='accountTrustRelationshipInstructions.step2.body'
        defaultMessage='Go to your {environmentName} environment. Use the details you just saved to trust this environment from the Security page of the deployments you want to use for your cross-cluster operations.
          '
        values={{
          environmentName: trustRelationship.name,
        }}
      />
    </EuiText>
  )
}

export default injectIntl(AccountRelationshipInstructions)
