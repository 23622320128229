/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSkeletonText } from '@elastic/eui'

import type { ReactElement } from 'react'
import type { InvitationSource } from './types'

interface Props {
  inProgress: boolean
  organizationName: string | undefined
  source?: InvitationSource
  isMfaRequired: boolean | undefined
}

class AcceptInvitationTitle extends Component<Props> {
  componentDidMount(): void {}

  render(): ReactElement {
    const { inProgress, isMfaRequired, organizationName, source } = this.props

    if (inProgress) {
      return <EuiSkeletonText lines={2} />
    }

    if (isMfaRequired) {
      return (
        <FormattedMessage
          id='accept-invite.title.mfa'
          defaultMessage='Multifactor authentication'
        />
      )
    }

    if (source === 'organization-invite') {
      return (
        <Fragment>
          <FormattedMessage
            id='accept-invite.title.join-organization'
            defaultMessage='Join organization'
          />
          <br />
          {organizationName}
        </Fragment>
      )
    }

    return (
      <FormattedMessage
        id='accept-invite.title.create-your-account'
        defaultMessage='Create your account'
      />
    )
  }
}

export default AcceptInvitationTitle
