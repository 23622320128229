/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiComboBox, EuiFormLabel, EuiSpacer } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'

import { DeploymentName } from '../DeploymentName'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement } from 'react'

type ComboBoxOption = {
  label: string
  value: DeploymentSearchResponse
  disabled?: boolean
}

type ComboBoxOptionGroup = {
  label: string
  options: ComboBoxOption[]
}

type ComboBoxItem = ComboBoxOption | ComboBoxOptionGroup

type Props = WrappedComponentProps & {
  searchDeployments: (query: string) => void
  searchDeploymentsRequest: AsyncRequestState

  // pass in `deployments`, letting `DeploymentPicker` create the combo box options
  deployments?: DeploymentSearchResponse[]

  // pass in `options` defining the combo box options yourself, allows for groups
  options?: ComboBoxItem[]
  value: DeploymentSearchResponse | null
  onChange: (cluster: DeploymentSearchResponse | null) => void
  label?: ReactElement
  placeholder?: string
  ['data-test-id']?: string
  fullWidth?: boolean
  prepend?: Array<string | ReactElement>
}

const messages = defineMessages({
  defaultPlaceholder: {
    id: `cui-cluster-picker.default-placeholder`,
    defaultMessage: `Select a deployment`,
  },
})

class DeploymentPickerImpl extends Component<Props> {
  componentDidMount() {
    this.searchDeployments(``)
  }

  render() {
    const {
      intl: { formatMessage },
      searchDeploymentsRequest,
      label,
      placeholder: consumerPlaceholder,
      [`data-test-id`]: dataTestSubj,
      fullWidth,
      prepend,
    } = this.props

    const { options, selectedOptions } = this.getOptions()

    const placeholder = consumerPlaceholder || formatMessage(messages.defaultPlaceholder)

    return (
      <Fragment>
        {label && <EuiFormLabel>{label}</EuiFormLabel>}

        <EuiComboBox
          fullWidth={fullWidth}
          css={{ '.euiFormControlLayout__prepend': { width: 180, gap: 0 } }}
          prepend={prepend}
          async={true}
          singleSelection={{ asPlainText: true }}
          placeholder={placeholder}
          isLoading={searchDeploymentsRequest.inProgress}
          options={options}
          selectedOptions={selectedOptions}
          onSearchChange={this.searchDeployments}
          onChange={this.onChange}
          renderOption={this.renderClusterOption}
          data-test-id={dataTestSubj}
        />

        {searchDeploymentsRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{searchDeploymentsRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderClusterOption = (option: ComboBoxOption, searchValue, contentClassName) => (
    <DeploymentName
      deployment={option.value}
      linkify={false}
      highlight={searchValue}
      className={contentClassName}
    />
  )

  getOptions() {
    const { value } = this.props
    const options = getConsumerOptions(this.props)
    const selectedOptions = getSelectedOptions(value)

    return { options, selectedOptions }
  }

  searchDeployments = (query: string): void => {
    const { searchDeployments } = this.props
    searchDeployments(query)
  }

  onChange = (selection: ComboBoxOption[]): void => {
    const { onChange } = this.props
    const [selectedOption] = selection
    const nextValue = selectedOption ? selectedOption.value : null

    onChange(nextValue)
  }
}

function getConsumerOptions(props: Props): ComboBoxItem[] {
  const { options, deployments } = props

  if (Array.isArray(options)) {
    return options
  }

  if (Array.isArray(deployments)) {
    return deployments.map((deployment) => getOption(deployment))
  }

  throw new Error(
    `Expected at least one of \`deployments\` or \`options\` to be an array at <DeploymentPicker/>`,
  )
}

function getOption(deployment: DeploymentSearchResponse): ComboBoxOption {
  return {
    label: getDeploymentPickerLabel(deployment),
    value: deployment,
  }
}

function getSelectedOptions(value: DeploymentSearchResponse | null): ComboBoxOption[] {
  if (value === null) {
    return []
  }

  return [getOption(value)]
}

export function getDeploymentPickerLabel(deployment: DeploymentSearchResponse): string {
  const { id } = deployment

  const name: string | undefined = deployment.name

  const displayId = id ? id.slice(0.6) : ''
  return name ? `${name} (${displayId})` : displayId
}

export const DeploymentPicker = injectIntl(DeploymentPickerImpl)
