/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { flatMap, fromPairs } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { IconType } from '@elastic/eui'

import type { RoutableBreadcrumb, VersionNumber } from '@modules/ui-types'

import DeploymentTrustRelationshipHeader from '@/components/DeploymentTrustRelationships/DeploymentTrustRelationshipHeader'
import CreateDeploymentTrustRelationshipHeader from '@/components/DeploymentTrustRelationships/CreateDeploymentTrustRelationship/CreateDeploymentTrustRelationshipHeader'
import {
  deploymentActivityCrumbs,
  deploymentActivitySliderCrumbs,
  deploymentAddTrustCrumbs,
  deploymentApiConsoleCrumbs,
  deploymentEditAdvancedCrumbs,
  deploymentEditCrumbs,
  deploymentEditTrustCrumbs,
  deploymentGettingStartedCrumbs,
  deploymentHealthCrumbs,
  deploymentHealthIssueDetailsCrumbs,
  deploymentHeapDumpsCrumbs,
  deploymentIlmMigrationCrumbs,
  deploymentLogsCrumbs,
  deploymentMetricsCrumbs,
  deploymentOperationsCrumbs,
  deploymentOverviewCrumbs,
  deploymentSecurityCrumbs,
  deploymentSliderCrumbs,
  deploymentSnapshotCrumbs,
  deploymentSnapshotsCrumbs,
} from '@/lib/crumbBuilder'
import { getSupportedSliderInstanceTypes } from '@/lib/sliders/support'
import { getSliderPrettyName } from '@/lib/sliders/messages'
import { getSliderIconType } from '@/lib/sliders/icons'

import { HealthStatusDetailsHeader } from '../HealthStatusDetails/HealthStatusDetailsHeader'

import type { ReactNode } from 'react'
import type { Props as DeploymentHeaderProps } from './DeploymentHeader'
import type { RouteComponentProps } from 'react-router-dom'

type QueryParams = {
  deploymentId: string
  snapshotName?: string
  trustRelationshipId?: string
  issue: string
}

type HeaderDefinition = {
  breadcrumbs: RoutableBreadcrumb[]
  title?: ReactNode
  iconType?: IconType
  shouldHideRegion?: boolean
  hideHorizontalRule?: boolean
  component?: React.ComponentType<DeploymentHeaderProps> // custom implementation which overrides title/icon/region
}

type HeaderDefinitions = {
  [matchPath: string]: HeaderDefinition
}

export function getHeaderDefinition({
  match,
  deploymentDisplayName,
  version,
}: {
  match: RouteComponentProps<QueryParams>['match']
  deploymentDisplayName?: string
  version?: VersionNumber | null
}): HeaderDefinition {
  const { deploymentId, snapshotName, issue } = match.params

  const matchPath = match.path.replace(/^\/deployments\/:deploymentId(\/|$)/gi, `/`)

  const editHeaderDefinition: HeaderDefinition = {
    title: <FormattedMessage id='deployment-header.edit' defaultMessage='Edit' />,
    breadcrumbs: deploymentEditCrumbs({ deploymentId, deploymentDisplayName }),
  }

  const healthHeaderDefinition: HeaderDefinition = {
    title: <FormattedMessage id='deployment-header.health' defaultMessage='Health' />,
    breadcrumbs: deploymentHealthCrumbs({ deploymentId, deploymentDisplayName }),
  }

  const healthIssueDetailsHeaderDefinition: HeaderDefinition = {
    component: HealthStatusDetailsHeader,
    breadcrumbs: deploymentHealthIssueDetailsCrumbs({ deploymentId, deploymentDisplayName, issue }),
  }

  const editAdvancedHeaderDefinition: HeaderDefinition = {
    title: <FormattedMessage id='deployment-header.advanced-edit' defaultMessage='Advanced edit' />,
    breadcrumbs: deploymentEditAdvancedCrumbs({ deploymentId }),
  }

  const addTrustHeaderDefinition: HeaderDefinition = {
    breadcrumbs: deploymentAddTrustCrumbs({ deploymentId, deploymentDisplayName }),
    component: DeploymentTrustRelationshipHeader,
  }

  const editTrustHeaderDefinition: HeaderDefinition = {
    breadcrumbs: deploymentEditTrustCrumbs({ deploymentId, deploymentDisplayName }),
    component: DeploymentTrustRelationshipHeader,
  }

  const rootDefinition = {
    title: deploymentDisplayName,
    breadcrumbs: deploymentOverviewCrumbs({ deploymentId, deploymentDisplayName }),
  }

  const headerDefinitions: HeaderDefinitions = {
    '/': rootDefinition,
    '/edit': editHeaderDefinition,
    '/health': healthHeaderDefinition,
    '/health/:issue': healthIssueDetailsHeaderDefinition,
    '/edit/advanced': editAdvancedHeaderDefinition,
    '/metrics': {
      title: <FormattedMessage id='deployment-header.performance' defaultMessage='Performance' />,
      breadcrumbs: deploymentMetricsCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/logs-metrics': {
      title: (
        <FormattedMessage
          id='deployment-header.logging-and-monitoring'
          defaultMessage='Logs and metrics'
        />
      ),
      breadcrumbs: deploymentLogsCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/activity': {
      title: <FormattedMessage id='deployment-header.activity' defaultMessage='Activity' />,
      breadcrumbs: deploymentActivityCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/getting-started': {
      title: <FormattedMessage id='deployment-header.create' defaultMessage='Create' />,
      breadcrumbs: deploymentGettingStartedCrumbs({ deploymentId, deploymentDisplayName }),
    },
    ...fromPairs(
      flatMap(getSupportedSliderInstanceTypes(), (sliderInstanceType) => [
        [
          `/:sliderInstanceType(${sliderInstanceType})`,
          {
            title: <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />,
            iconType: getSliderIconType({ sliderInstanceType }),
            breadcrumbs: deploymentSliderCrumbs({
              deploymentId,
              sliderInstanceType,
              deploymentDisplayName,
              version,
            }),
          },
        ],
        [
          `/activity/${sliderInstanceType}`,
          {
            title: (
              <FormattedMessage
                id='deployment-header.slider-activity'
                defaultMessage='{sliderName} activity'
                values={{
                  sliderName: (
                    <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />
                  ),
                }}
              />
            ),
            iconType: getSliderIconType({ sliderInstanceType }),
            breadcrumbs: deploymentActivitySliderCrumbs({
              deploymentId,
              sliderInstanceType,
              deploymentDisplayName,
            }),
          },
        ],
      ]),
    ),
    '/elasticsearch/snapshots': {
      title: (
        <FormattedMessage
          id='deployment-header.elasticsearch-snapshots'
          defaultMessage='Snapshots'
        />
      ),
      iconType: getSliderIconType({ sliderInstanceType: `elasticsearch` }),
      breadcrumbs: deploymentSnapshotsCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/elasticsearch/snapshots/:snapshotName': {
      title: (
        <FormattedMessage id='deployment-header.elasticsearch-snapshot' defaultMessage='Snapshot' />
      ),
      iconType: getSliderIconType({ sliderInstanceType: `elasticsearch` }),
      breadcrumbs: deploymentSnapshotCrumbs({
        deploymentId,
        snapshotName: snapshotName!,
        deploymentDisplayName,
      }),
    },
    '/elasticsearch/console': {
      title: (
        <FormattedMessage
          id='deployment-header.elasticsearch-console'
          defaultMessage='API console'
        />
      ),
      iconType: getSliderIconType({ sliderInstanceType: `elasticsearch` }),
      breadcrumbs: deploymentApiConsoleCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/security': {
      title: <FormattedMessage id='deployment-header.security' defaultMessage='Security' />,
      breadcrumbs: deploymentSecurityCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/security/trust/create': {
      breadcrumbs: deploymentAddTrustCrumbs({ deploymentId, deploymentDisplayName }),
      component: CreateDeploymentTrustRelationshipHeader,
    },
    '/security/trust/accounts': addTrustHeaderDefinition,
    '/security/trust/external': addTrustHeaderDefinition,
    '/security/trust/direct': addTrustHeaderDefinition,
    '/security/trust/accounts/:trustRelationshipId': editTrustHeaderDefinition,
    '/security/trust/external/:trustRelationshipId': {
      ...editTrustHeaderDefinition,
      hideHorizontalRule: true, // due to tabs
    },
    '/security/trust/direct/:trustRelationshipId': {
      ...editTrustHeaderDefinition,
      hideHorizontalRule: true, // due to tabs
    },
    '/operations': {
      title: <FormattedMessage id='deployment-header.operations' defaultMessage='Operations' />,
      breadcrumbs: deploymentOperationsCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/operations/heap-dumps': {
      title: <FormattedMessage id='deployment-header.heap-dumps' defaultMessage='Heap dumps' />,
      breadcrumbs: deploymentHeapDumpsCrumbs({ deploymentId, deploymentDisplayName }),
    },
    '/migrate-to-index-lifecycle-management': {
      title: (
        <FormattedMessage id='deployment-header.ilm-migration' defaultMessage='Migrate to ILM' />
      ),
      breadcrumbs: deploymentIlmMigrationCrumbs({ deploymentId, deploymentDisplayName }),
    },
  }

  return headerDefinitions[matchPath] || rootDefinition
}
