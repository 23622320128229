/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiTextColor } from '@elastic/eui'

import type { FunctionComponent } from 'react'

interface Props {
  email: string
  isRemoved: boolean
}

const OrganizationMemberEmailLabel: FunctionComponent<Props> = ({ email, isRemoved }) => (
  <Fragment>
    {email}
    {isRemoved && (
      <EuiTextColor
        style={{ marginLeft: 5 }}
        color='subdued'
        data-test-id='organization.organization-members.removed-user'
      >
        <FormattedMessage
          id='organization.organization-members.removed-user'
          defaultMessage='(former member)'
        />
      </EuiTextColor>
    )}
  </Fragment>
)

export default OrganizationMemberEmailLabel
