/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import TextTruncate from 'react-text-truncate'

import {
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  type EuiLinkProps,
  EuiPanel,
  EuiText,
  EuiToolTip,
} from '@elastic/eui'

import ExternalLink from '@/components/ExternalLink'
import playButton from '@/files/video-play-btn.svg'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactChild, ReactNode } from 'react'

import './thumbnail.scss'

export interface ThumbnailProps {
  title: ReactNode
  description: ReactChild
  src: string
  url?: string
  alt?: string
  isExternalLink?: boolean
  ['data-test-id']: string
  type?: 'video' | 'article'
  footer?: ReactNode
  imgMaxWidth?: number
}

const messages = defineMessages({
  play: {
    id: `cloud-portal.webinars-play`,
    defaultMessage: `Play`,
  },
  externalLink: {
    id: `cloud-portal-thumbnails.external-link`,
    defaultMessage: `External link`,
  },
})

class Thumbnail extends PureComponent<ThumbnailProps & WrappedComponentProps> {
  render() {
    const {
      intl,
      title,
      description,
      src,
      url,
      alt = typeof title === 'string' ? title : '',
      isExternalLink,
      'data-test-id': dataTestId,
      type = 'video',
      footer,
      imgMaxWidth,
    } = this.props
    const { formatMessage } = intl

    return (
      <EuiFlexGroup
        className='cloud-portal-thumbnail'
        gutterSize='m'
        responsive={false}
        direction={type === 'article' ? 'rowReverse' : 'row'}
        data-test-id={dataTestId}
      >
        <EuiFlexItem grow={false}>
          <EuiPanel className='cloud-portal-thumbnail-image-panel' paddingSize='none' grow={false}>
            <Fragment>
              {type === 'video' &&
                this.renderThumbnailLink({
                  content: <img src={playButton} role='presentation' alt='' />,
                  href: url,
                  isExternalLink,
                  className: 'cloud-portal-play-btn',
                  'aria-label': formatMessage(messages.play),
                })}
              <img
                style={{ maxWidth: imgMaxWidth }}
                src={src}
                alt={alt}
                data-test-id={`${dataTestId}-thumbnail-link`}
                role='presentation'
              />
            </Fragment>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiDescriptionList titleProps={{ className: 'cloud-portal-thumbnail-title' }}>
            <EuiDescriptionListTitle>
              <h3
                className='cloud-portal-tile-inner-content-title'
                data-test-id={`${dataTestId}-regular-link`}
              >
                {this.renderThumbnailLink({ content: title, href: url, isExternalLink })}
              </h3>
            </EuiDescriptionListTitle>

            <EuiDescriptionListDescription>
              {typeof description === 'string' ? (
                <EuiToolTip content={description}>
                  <EuiText size='s'>
                    <TextTruncate text={description} line={2} />
                  </EuiText>
                </EuiToolTip>
              ) : (
                description
              )}
            </EuiDescriptionListDescription>

            {footer}
          </EuiDescriptionList>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderThumbnailLink({
    content,
    href,
    isExternalLink,
    className,
    'aria-label': ariaLabel,
  }: {
    content: ReactNode
    isExternalLink: boolean | undefined
    href: EuiLinkProps['href']
    className?: EuiLinkProps['className']
    'aria-label'?: EuiLinkProps['aria-label']
  }) {
    const children =
      typeof content === 'string' ? <TextTruncate text={content} line={1} /> : content

    if (!href) {
      return children
    }

    if (isExternalLink) {
      return (
        <ExternalLink
          className={className}
          href={href}
          showExternalLinkIcon={false}
          aria-label={ariaLabel}
        >
          {children}
        </ExternalLink>
      )
    }

    return (
      <EuiLink href={href} aria-label={ariaLabel} className={className}>
        {children}
      </EuiLink>
    )
  }
}

export default injectIntl(Thumbnail)
