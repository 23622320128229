/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { StackDeployment } from '@modules/ui-types'

import { hasFailedCreatePlan } from '@/lib/stackDeployments/selectors/configurationChangeHealth'
import { hasOngoingConfigurationChange } from '@/lib/stackDeployments/selectors/configurationChanges'

import type { Problem } from '../../problems'

/**
 * Reports on issues that are currently being reported by the Deployment Health API.
 * This reporter handles failed initial plan errors.
 */
export function getInitialPlanFailureProblems({
  deployment,
}: {
  deployment?: StackDeployment
}): Problem[] {
  const problems: Problem[] = []

  if (!deployment) {
    return problems
  }

  if (hasOngoingConfigurationChange({ deployment })) {
    return problems
  }

  if (hasFailedCreatePlan({ deployment })) {
    problems.push({
      kind: `deployment`,
      id: `failed-initial-plan`,
      level: `danger`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.deployment-failed-initial-plan'
          defaultMessage='Something went wrong while creating this deployment'
        />
      ),
    })

    return problems
  }

  return problems
}

export function getInitialPlanFailureFromProblemsList(problems: Problem[]) {
  return problems.filter((problem) => problem.id === `failed-initial-plan`)
}
