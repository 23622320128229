/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { withErrorBoundary } from '@modules/cui/Boundary'

import { getArchitectureDescription } from '@/lib/deployments/architecture'

import ZoneSummary from '../Topology/DeploymentTemplates/components/ArchitectureViz/ZoneSummary'

import type { ReactElement, FunctionComponent } from 'react'
import type { DeploymentArchitectureVizProps as Props } from './types'

const DeploymentArchitectureViz: FunctionComponent<Props> = ({
  instanceConfigurations,
  nodeConfigurations,
  version,
}): ReactElement | null => {
  const architecture = getArchitectureDescription({
    instanceConfigurations,
    nodeConfigurations,
    version,
  })
  const empty = architecture.zones.length === 0

  if (empty) {
    return null
  }

  const summaries = architecture.zones.map((zone, zoneIndex) => (
    <ZoneSummary key={zoneIndex} zone={zone} zoneIndex={zoneIndex} />
  ))

  return <div data-test-id='architecture-zone-summary'>{summaries}</div>
}

export default withErrorBoundary(DeploymentArchitectureViz)
