/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { getErrorCode } from '@modules/utils/error'
import { parseError } from '@modules/cui/Alert'

import RemoveDeviceButton from './RemoveDeviceButton'

import type { FunctionComponent, ReactNode } from 'react'

interface Props {
  description: ReactNode
  canRemove: boolean
  onRemoveMfaDevice: () => void
  removeMfaDeviceRequest: AsyncRequestState
}

const DeviceActive: FunctionComponent<Props> = ({
  description,
  canRemove,
  onRemoveMfaDevice,
  removeMfaDeviceRequest,
}) => {
  return (
    <EuiFlexGroup gutterSize='s' alignItems='baseline'>
      <EuiFlexItem>
        <EuiFlexGroup direction='column' gutterSize='s'>
          <EuiFlexItem>
            <EuiText size='s'>{description}</EuiText>

            <EuiSpacer size='s' />
          </EuiFlexItem>

          {renderError()}

          <EuiFlexItem grow={false}>
            <RemoveDeviceButton
              disabled={!canRemove}
              isLoading={removeMfaDeviceRequest.inProgress}
              onClick={onRemoveMfaDevice}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  function renderError(): ReactNode {
    if (!removeMfaDeviceRequest.error) {
      return null
    }

    const code = getErrorCode(removeMfaDeviceRequest.error)

    if (code === 'auth.no_available_mfa_devices') {
      return (
        <EuiFlexItem>
          <EuiCallOut
            title={
              <FormattedMessage
                id='device-active.no_available_mfa_devices.title'
                defaultMessage='You must have at least one security method enabled'
              />
            }
            color='danger'
          >
            <p>
              <FormattedMessage
                id='device-active.no_available_mfa_devices.description'
                defaultMessage='To preserve the security of your account, you will be able to remove this security method only after setting up a different one.'
              />
            </p>
          </EuiCallOut>

          <EuiSpacer size='s' />
        </EuiFlexItem>
      )
    }

    return (
      <EuiFlexItem>
        <EuiCallOut
          title={
            <FormattedMessage
              id='device-active.unknown-error'
              defaultMessage='Could not remove device'
            />
          }
          color='danger'
        >
          {parseError(removeMfaDeviceRequest.error)}
        </EuiCallOut>

        <EuiSpacer size='s' />
      </EuiFlexItem>
    )
  }
}

export default DeviceActive
