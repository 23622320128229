/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import { ViewBy } from './types'

import type { BucketingStrategy } from './types'

export const DeploymentDimensions = [
  { id: 'capacity', name: 'Capacity' },
  { id: 'data_transfer', name: 'Data Transfer' },
  { id: 'storage', name: 'Storage' },
  { id: 'synthetics', name: 'Synthetics' },
]

export const CapacityColor = colorForInstances.get('capacity')
export const DataTransferColor = colorForInstances.get('data_transfer')
export const StorageColor = colorForInstances.get('storage')
export const SyntheticsColor = colorForInstances.get('synthetics')

export const ColorKeyForOtherDeployments = 'all-deployments'
export const ColorKeyForOtherProjects = 'all-projects'
export const VisibleDeployments = 5
export const VisibleProjects = 5

export const ViewByToBucketingStrategy: Record<ViewBy, BucketingStrategy> = {
  [ViewBy.DAY]: 'daily',
  [ViewBy.MONTH]: 'monthly',
}
