/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { connect } from 'react-redux'

import { changeByokToggle, getByokApplicationId } from '@/actions/byokToggle'
import {
  isByokToggleEnabled,
  byokApplicationId,
  byokServiceAccountEmail,
  byokCloudStorageServiceAgentEmail,
} from '@/reducers/byokToggle'
import { getByokApplicationIdRequest } from '@/reducers/asyncRequests/registry'

import ByokToggle from './ByokToggle'

import type { DispatchProps, StateProps } from './ByokToggle'
import type { ThunkDispatch, ReduxState } from '@/types/redux'

const mapStateToProps = (state: ReduxState): StateProps => ({
  isByokToggleEnabled: isByokToggleEnabled(state),
  byokApplicationId: byokApplicationId(state),
  byokApplicationIdRequest: getByokApplicationIdRequest(state),
  byokServiceAccountEmail: byokServiceAccountEmail(state),
  byokCloudStorageServiceAgentEmail: byokCloudStorageServiceAgentEmail(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  changeByokToggle: (value) => dispatch(changeByokToggle(value)),
  getByokApplicationId: (regionId: string) => dispatch(getByokApplicationId(regionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ByokToggle)
