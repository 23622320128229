/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import type {
  ResourceRoleAssignment,
  ResourceType,
  RoleAssignmentsTableItem,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import type { components } from '@modules/project-user-api/v1/types'
import {
  useGetElasticsearchProjectRolesQuery,
  useGetSecurityProjectRolesQuery,
} from '@modules/project-admin-lib/hooks/get'
import { RoleSelector } from '@modules/role-assignments-components/ProjectRoleAssignmentsTable/RoleSelector'

export interface AdminRoleSelectorProps<
  T extends ResourceType,
  R extends ResourceRoleAssignment<T>,
> {
  resourceType: T
  items: Array<RoleAssignmentsTableItem<T>>
  tableItemsToRoleAssignment: (items: Array<RoleAssignmentsTableItem<T>>) => R[]
  onChangeRoleAssignments: (roleAssignments: Array<ResourceRoleAssignment<T>>) => void
  roles: TableItemRoleDescriptor[]
  id: string
  roleManagementUrl?: string
  isDisabled: boolean
}

export const AdminRoleSelector = <T extends ResourceType, R extends ResourceRoleAssignment<T>>(
  props: AdminRoleSelectorProps<T, R>,
) => {
  const {
    resourceType,
    items,
    tableItemsToRoleAssignment,
    onChangeRoleAssignments,
    roles,
    id: resourceId,
    roleManagementUrl,
    isDisabled,
  } = props

  const elasticsearchQuery = useGetElasticsearchProjectRolesQuery(resourceId, {
    enabled: resourceType === 'elasticsearch',
  })

  const securityQuery = useGetSecurityProjectRolesQuery(resourceId, {
    enabled: resourceType === 'security',
  })

  let data: components['schemas']['ProjectRolesByProjectID'] | undefined = {}
  let isLoading = false

  if (resourceType === 'elasticsearch') {
    data = elasticsearchQuery.data
    isLoading = elasticsearchQuery.isLoading
  } else if (resourceType === 'security') {
    data = securityQuery.data
    isLoading = securityQuery.isLoading
  }

  if (isLoading) {
    return <EuiSkeletonText />
  }

  return (
    <RoleSelector
      resourceType={resourceType}
      items={items}
      tableItemsToRoleAssignment={tableItemsToRoleAssignment}
      onChangeRoleAssignments={onChangeRoleAssignments}
      roles={roles}
      id={resourceId}
      roleManagementUrl={roleManagementUrl}
      isDisabled={isDisabled}
      customRolesEnabled={true}
      projectRolesQueryData={data}
    />
  )
}
