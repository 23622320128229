/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ApiKeyResponse, ApiKeysResponse } from '@modules/cloud-api/v1/types'

import { FETCH_CURRENT_USER_API_KEYS, FETCH_ALL_USERS_API_KEYS } from '../../constants/actions'

import type { AsyncAction, ReduxState } from '@/types/redux'

export interface State {
  allUsersApiKeys: ApiKeyResponse[]
  currentUserApiKeys: ApiKeyResponse[]
}

type FetchAllUsersApiKeysAction = AsyncAction<typeof FETCH_ALL_USERS_API_KEYS, ApiKeysResponse>
type FetchCurrentUserApiKeysAction = AsyncAction<
  typeof FETCH_CURRENT_USER_API_KEYS,
  ApiKeysResponse
>

type Action = FetchAllUsersApiKeysAction | FetchCurrentUserApiKeysAction

const initialState: State = {
  allUsersApiKeys: [],
  currentUserApiKeys: [],
}

export default function apiKeysReducer(
  state: State = initialState,
  { type, payload, error }: Action,
): State {
  if (payload && !error) {
    if (type === FETCH_ALL_USERS_API_KEYS) {
      return {
        ...state,
        allUsersApiKeys: payload.keys,
      }
    }
  }

  if (payload && !error) {
    if (type === FETCH_CURRENT_USER_API_KEYS) {
      return {
        ...state,
        currentUserApiKeys: payload.keys,
      }
    }
  }

  return state
}

export const getAllUsersApiKeys = (state: ReduxState) => state.apiKeys.allUsersApiKeys
export const getCurrentUserApiKeys = (state: ReduxState) => state.apiKeys.currentUserApiKeys
