/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { noop } from 'lodash'

import { EuiStepsHorizontal, EuiStepsHorizontalProps } from '@elastic/eui'

import type { IntlShape, WrappedComponentProps } from 'react-intl'

type Props = {
  currentStep: number
  onChange?: (organizationId: string) => void
  intl: IntlShape
}

enum PageState {
  MECHANISM = 0,
  AUTHTYPE = 1,
  ENV = 2,
}

const messages = defineMessages({
  mechanism: {
    id: 'create-trust-relationship.navigation-steps.env-type-method',
    defaultMessage: 'Select environment type',
  },
  env: {
    id: 'create-trust-relationship.navigation-steps.trust-env',
    defaultMessage: 'Trust new environment',
  },
  authType: {
    id: 'create-trust-relationship.navigation-steps.auth-type',
    defaultMessage: 'Choose authentication method',
  },
})

const TrustRelationshipNavigationSteps: React.FunctionComponent<Props & WrappedComponentProps> = ({
  currentStep,
  intl: { formatMessage },
}) => {
  const horizontalSteps: EuiStepsHorizontalProps['steps'] = [
    {
      title: formatMessage(messages.mechanism),
      status: getStepStatus(currentStep, PageState.MECHANISM),
      onClick: noop,
    },
    {
      title: formatMessage(messages.authType),
      status: getStepStatus(currentStep, PageState.AUTHTYPE),
      onClick: noop,
    },
    {
      title: formatMessage(messages.env),
      status: getStepStatus(currentStep, PageState.ENV),
      onClick: noop,
    },
  ]

  return <EuiStepsHorizontal steps={horizontalSteps} />
}

function getStepStatus(currentStep: number, page: number) {
  if (page === currentStep) {
    return 'current'
  }

  if (currentStep >= page) {
    return 'complete'
  }

  if (currentStep < page) {
    return 'disabled'
  }

  return 'incomplete'
}

export default injectIntl(TrustRelationshipNavigationSteps)
