/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { FeaturesUsage, SaasUserResponse } from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import type { BillingActivity } from '@modules/ui-types/billing'

import {
  FETCH_ACCOUNT_ACTIVITY,
  FETCH_ACCOUNT_DETAILS,
  FETCH_PROFILE,
  FETCH_USAGE_DETAILS,
  UPDATE_ACCOUNT_EMAIL,
  UPDATE_ACCOUNT_PASSWORD,
  UPDATE_ACCOUNT_DETAILS,
  CONFIRM_EMAIL_CHANGE,
  CLEAR_EMAIL_CHANGE_CONFIRMATION,
  UPDATE_BILLING_SUBSCRIPTION_LEVEL,
} from '../../constants/actions'
import { getAsyncRequestState } from '../../../../reducers/asyncRequests'
import { replaceIn } from '../../../../lib/immutability-helpers'

import type { ReduxState } from '@/types/redux'

export interface AccountState {
  ui: {
    newEmail?: string | undefined
  }
  activity?: BillingActivity
  costsOverview: unknown
  prepaidBalanceLineItems: unknown
  costDetails: unknown
  details?: UserProfile
  billingHistory: unknown
  usage?: FeaturesUsage
}

const initialState: AccountState = {
  ui: {},
  activity: undefined,
  costsOverview: undefined,
  prepaidBalanceLineItems: undefined,
  costDetails: undefined,
  details: undefined,
  billingHistory: undefined,
  usage: undefined,
}

export default function accountReducer(state: AccountState = initialState, action) {
  if (!action.error && action.payload) {
    if (action.type === FETCH_ACCOUNT_ACTIVITY) {
      return {
        ...state,
        activity: action.payload,
      }
    }

    if (action.type === FETCH_ACCOUNT_DETAILS) {
      return {
        ...state,
        details: makeDetails(action.payload),
      }
    }

    if (action.type === UPDATE_ACCOUNT_DETAILS) {
      return {
        ...state,
        details: makeDetails(action.payload),
      }
    }

    if (action.type === UPDATE_ACCOUNT_EMAIL) {
      // preserve immediate UI since the email change is async on the backend
      return replaceIn(state, [`ui`, `newEmail`], action.meta.newEmail)
    }

    if (action.type === CONFIRM_EMAIL_CHANGE) {
      const withOutNewEmail = replaceIn(state, [`ui`, `newEmail`], null)
      const withDidChangeFlag = replaceIn(withOutNewEmail, [`ui`, `emailDidChange`], true)
      return withDidChangeFlag
    }

    if (action.type === FETCH_PROFILE) {
      // remove the pending email state if the user's actual email address changes
      const pendingEmail = state.ui.newEmail
      const fetchedEmail = action.payload.user.email

      if (pendingEmail && fetchedEmail !== pendingEmail) {
        return replaceIn(state, [`ui`, `newEmail`], null)
      }
    }
  }

  if (action.type === CLEAR_EMAIL_CHANGE_CONFIRMATION) {
    return replaceIn(state, [`ui`, `emailDidChange`], null)
  }

  if (action.type === FETCH_USAGE_DETAILS) {
    return {
      ...state,
      usage: action.payload,
    }
  }

  return state
}

export function getAccountUI(state: ReduxState) {
  return state.account?.ui ?? {}
}

export function getAccountActivity(state: ReduxState) {
  return state.account?.activity
}

export function getAccountDetails(state: ReduxState) {
  return state.account?.details
}

export function getUsageDetails(state: ReduxState) {
  return state.account?.usage
}

export const fetchAccountDetailsRequest = getAsyncRequestState(FETCH_ACCOUNT_DETAILS)
export const updateAccountEmailRequest = getAsyncRequestState(UPDATE_ACCOUNT_EMAIL)
export const updateAccountPasswordRequest = getAsyncRequestState(UPDATE_ACCOUNT_PASSWORD)
export const updateAccountDetailsRequest = getAsyncRequestState(UPDATE_ACCOUNT_DETAILS)
export const confirmEmailChangeRequest = getAsyncRequestState(CONFIRM_EMAIL_CHANGE)
export const fetchUpdateBillingLevelRequest = getAsyncRequestState(
  UPDATE_BILLING_SUBSCRIPTION_LEVEL,
)
export const fetchUsageDetailsRequest = getAsyncRequestState(FETCH_USAGE_DETAILS)

function makeDetails(payload: SaasUserResponse): UserProfile {
  return {
    ...payload.user,
    ...payload.rules,
    ...payload.subscription,
    trials: payload.trials,
  } as UserProfile
}
