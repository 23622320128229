/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { TableItemRoleDescriptor } from '@modules/role-assignments-lib/types'

import type { FunctionComponent } from 'react'

export const CustomRoleBadge: FunctionComponent<{
  roleDescriptor: TableItemRoleDescriptor
  customRoles: string[]
}> = ({ roleDescriptor, customRoles }) => (
  <EuiFlexGroup gutterSize='xs' responsive={false}>
    {customRoles.length > 0 && !customRoles.includes(roleDescriptor.roleId) && (
      <EuiFlexItem grow={false}>
        <EuiBadge color='danger'>
          <FormattedMessage id='roles-assignments.deleted-badge' defaultMessage='Deleted' />
        </EuiBadge>
      </EuiFlexItem>
    )}
    {roleDescriptor.roleId}
  </EuiFlexGroup>
)
