/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import DeploymentDependencyLoader from './DeploymentDependencyLoader'

import type { FunctionComponent } from 'react'

type Props = {
  deploymentId: string
}

const DeploymentDependencyLoaderWrapper: FunctionComponent<Props> = ({
  deploymentId,
  children,
}) => (
  <PermissionsGate permissions={[{ type: `deployment`, action: `get`, id: deploymentId }]}>
    {({ hasPermissions, isLoading }) =>
      isLoading ? null : (
        <DeploymentDependencyLoader
          stackDeploymentId={deploymentId}
          hasPermissions={hasPermissions}
        >
          {children}
        </DeploymentDependencyLoader>
      )
    }
  </PermissionsGate>
)

export default DeploymentDependencyLoaderWrapper
