/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AuthzRole, AuthzRoles } from '@modules/cloud-api/v1/types'

import { FETCH_AUTHZ_ROLES } from '@/constants/actions'

import type { AsyncAction, ReduxState } from '@/types/redux'

export interface State {
  roles: AuthzRole[] | null
}

const initialState: State = {
  roles: null,
}

interface FetchAuthzRolesAction extends AsyncAction<typeof FETCH_AUTHZ_ROLES, AuthzRoles> {}

type Action = FetchAuthzRolesAction

export default function authzRolesReducer(state: State = initialState, action: Action): State {
  if (action.type === FETCH_AUTHZ_ROLES) {
    if (!action.error && action.payload) {
      return {
        ...state,
        roles: action.payload.roles,
      }
    }
  }

  return state
}

function _getAuthzRoles(state: State): AuthzRole[] | null {
  return state.roles
}

export function getAuthzRoles(state: ReduxState) {
  return _getAuthzRoles(state.authzRoles)
}
