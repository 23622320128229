/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt } from '@elastic/eui'

import type { UserProfile } from '@modules/ui-types'
import { isPrepaidAccount } from '@modules/billing-lib/utils'
import type { PaymentDetails } from '@modules/billing-api/customTypes'

import BillingHistoryPanel from '../BillingHistoryPanel'

import type { FunctionComponent } from 'react'

interface Props {
  profile: UserProfile
  paymentDetails?: PaymentDetails
}

const BillingHistoryEmptyPrompt: FunctionComponent<Props> = ({ profile, paymentDetails }) => {
  const statements = (
    <FormattedMessage id='billing-history.statements' defaultMessage='statements' />
  )
  const invoices = <FormattedMessage id='billing-history.invoices' defaultMessage='invoices' />
  const documents = isPrepaidAccount(profile, paymentDetails) ? statements : invoices

  return (
    <BillingHistoryPanel data-test-id='billing-history-empty-prompt'>
      <EuiEmptyPrompt
        style={{ maxWidth: `50em` }}
        title={
          <h2>
            <FormattedMessage
              id='billing-history.empty-title'
              defaultMessage='You have no {documents}'
              values={{ documents }}
            />
          </h2>
        }
        body={
          <FormattedMessage
            id='billing-history.empty-content'
            defaultMessage='New {documents} will show here the first day of the month.'
            values={{ documents }}
          />
        }
      />
    </BillingHistoryPanel>
  )
}

export default BillingHistoryEmptyPrompt
