/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiComboBox, EuiSpacer, EuiText } from '@elastic/eui'

import { messages } from '../messages'
import BackToLoginButton from '../BackToLoginButton'

import type { Props } from './types'

const IdpSelection = ({ idpList, onClickInitiateUrl }: Props) => {
  const idpOptions = useMemo(
    () =>
      idpList.map((idp) => ({
        value: idp.login_identifier,
        label: idp.organization_name,
      })),
    [idpList],
  )
  const [loginIdentifier, setLoginIdentifier] = useState(idpOptions[0]?.value)

  const onSelectIdp = () => {
    if (loginIdentifier) {
      onClickInitiateUrl(loginIdentifier)
    }
  }

  useEffect(() => {
    if (idpList.length === 1) {
      onSelectIdp()
    }
  }, [idpList])

  const onChangeIdp = (selectedOptions) => {
    setLoginIdentifier(selectedOptions[0]?.value)
  }

  return (
    <Fragment>
      <EuiText size='m' color='subdued' textAlign='center'>
        <FormattedMessage {...messages.selectOrganization} />
      </EuiText>
      <EuiSpacer size='l' />
      <EuiComboBox
        options={idpOptions}
        singleSelection={{ asPlainText: true }}
        selectedOptions={idpOptions.filter((option) => option.value === loginIdentifier)}
        onChange={onChangeIdp}
        isClearable={false}
      />
      <EuiSpacer size='l' />
      <EuiText size='xs' color='subdued'>
        <FormattedMessage {...messages.onlyBelongToOneOrganization} />
      </EuiText>
      <EuiSpacer size='m' />
      <EuiButton onClick={onSelectIdp} fill={true} fullWidth={true}>
        <FormattedMessage {...messages.loginButton} />
      </EuiButton>
      <EuiSpacer size='m' />
      <BackToLoginButton />
    </Fragment>
  )
}

export default IdpSelection
