/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiText,
  EuiButton,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import { CuiThemedIcon } from '@modules/cui/ThemedIcon'

import SpinButton from '@/components/SpinButton'
import verifyEmailDark from '@/files/illustration-check-email-dark.svg'
import verifyEmailLight from '@/files/illustration-check-email-light.svg'

import type { Props } from './types'

interface State {
  isEmailSentModalOpened: boolean
}

class CreatePassword extends Component<Props, State> {
  state = {
    isEmailSentModalOpened: false,
  }

  render() {
    const { createPasswordRequestState } = this.props
    const { isEmailSentModalOpened } = this.state

    return (
      <Fragment>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <SpinButton
              data-test-id='authenticated-user-create-password-btn'
              size='m'
              spin={createPasswordRequestState.inProgress}
              onClick={this.handleCreatePasswordClick}
            >
              <FormattedMessage
                id='user-profile-create-password.create-btn-title'
                defaultMessage='Create'
              />
            </SpinButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        {createPasswordRequestState.error && (
          <Fragment>
            <EuiSpacer size='l' />

            <CuiAlert type='danger' iconType='alert'>
              {createPasswordRequestState.error}
            </CuiAlert>
          </Fragment>
        )}
        {isEmailSentModalOpened && this.renderEmailSentModal()}
      </Fragment>
    )
  }

  renderEmailSentModal() {
    const { email } = this.props
    return (
      <EuiModal maxWidth={428} onClose={this.closeModal}>
        <EuiModalHeader>
          <EuiFlexGroup direction='column' alignItems='center'>
            <EuiFlexItem>
              <CuiThemedIcon
                style={{ maxWidth: 183 }}
                size='original'
                lightType={verifyEmailLight}
                darkType={verifyEmailDark}
              />
            </EuiFlexItem>

            <EuiSpacer size='l' />

            <EuiFlexItem>
              <EuiModalHeaderTitle>
                <FormattedMessage
                  id='user-profile-create-password.check-your-email'
                  defaultMessage='Check your email'
                />
              </EuiModalHeaderTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiText color='subdued' textAlign='center'>
            <FormattedMessage
              id='user-profile-create-password.email-sent-text'
              defaultMessage='We sent an email to {email} with instructions for creating a password.'
              values={{ email: <strong>{email}</strong> }}
            />
          </EuiText>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiButton onClick={this.closeModal}>
                <FormattedMessage
                  id='user-profile-create-password.close-modal-text'
                  defaultMessage='Close'
                />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  closeModal = () => {
    this.setState({ isEmailSentModalOpened: false })
  }

  handleCreatePasswordClick = () => {
    const { createPassword, email } = this.props
    createPassword(email).then(() => this.setState({ isEmailSentModalOpened: true }))
  }
}

export default CreatePassword
