/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { stringify } from 'query-string'
import { defineMessages, injectIntl } from 'react-intl'

import { EuiFieldSearch } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'

type Props = WrappedComponentProps

interface State {
  documentationQuery: string
}

const emptyString = /^\s*$/

const messages = defineMessages({
  searchLabel: {
    id: 'cloud-portal-documentation.search-label',
    defaultMessage: 'Documentation search',
  },
  placeholder: {
    id: 'cloud-portal-documentation.search-placeholder',
    defaultMessage: 'Search documentation, guides, articles and more...',
  },
})

class SearchField extends PureComponent<Props, State> {
  state: State = {
    documentationQuery: '',
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <EuiFieldSearch
        aria-label={formatMessage(messages.searchLabel)}
        value={this.state.documentationQuery}
        placeholder={formatMessage(messages.placeholder)}
        onKeyDown={this.onDocumentationSearchKeyDown}
        onChange={this.onSearchTextChange}
        fullWidth={true}
      />
    )
  }

  onDocumentationSearchKeyDown = (e) => {
    if (e.key !== `Enter`) {
      return
    }

    const { documentationQuery } = this.state

    if (emptyString.test(documentationQuery)) {
      return
    }

    const parameters = {
      website_area: 'Documentation',
    }

    const query = documentationQuery.trim()

    const documentationUrl = `https://support.elastic.co/search/${query}/?${stringify(parameters)}`
    window.open(documentationUrl)
  }

  onSearchTextChange = (e) => {
    this.setState({ documentationQuery: e.target.value })
  }
}

export default injectIntl(SearchField)
