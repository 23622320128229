/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { isEmpty } from 'lodash'
import { jsx, css } from '@emotion/react'

import { EuiFlexGroup, EuiLoadingSpinner } from '@elastic/eui'

import type { StackDeployment, UserProfile } from '@modules/ui-types'
import type { DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'

import { getInstanceConfigurationsFromTemplate } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getNodeConfigurationsFromStackDeployment } from '@/lib/stackDeployments/selectors/topologyElements'

import TrialSummaryWithDeployment from './TrialSummaryWithDeployment'
import TrialSummaryWithoutDeploymentOrTemplate from './TrialSummaryWithoutDeploymentOrTemplate'

import type { FC } from 'react'

type TrialSummaryModalContentProps = {
  profile: UserProfile | null
  deployment: StackDeployment | undefined
  deploymentTemplate: DeploymentTemplateInfoV2 | null
  isFetchingData: boolean
  closeModal: () => void
}

const TrialSummaryModalContent: FC<TrialSummaryModalContentProps> = ({
  profile,
  isFetchingData,
  deployment,
  deploymentTemplate,
  closeModal,
}) => {
  if (isFetchingData || !profile) {
    return (
      <EuiFlexGroup alignItems='center' justifyContent='center' css={css({ minHeight: 360 })}>
        <EuiLoadingSpinner size='xl' />
      </EuiFlexGroup>
    )
  }

  const hasNoNodeConfigurations =
    deployment && isEmpty(getNodeConfigurationsFromStackDeployment(deployment))
  const hasNoInstanceConfigurations =
    deploymentTemplate && isEmpty(getInstanceConfigurationsFromTemplate({ deploymentTemplate }))

  if (
    !deployment ||
    !deploymentTemplate ||
    hasNoNodeConfigurations ||
    hasNoInstanceConfigurations
  ) {
    return (
      <TrialSummaryWithoutDeploymentOrTemplate
        closeModal={closeModal}
        profile={profile}
        deployment={deployment}
      />
    )
  }

  return (
    <TrialSummaryWithDeployment
      closeModal={closeModal}
      profile={profile}
      deployment={deployment}
      deploymentTemplate={deploymentTemplate}
    />
  )
}

export default TrialSummaryModalContent
