/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { CloudAppPlatform, CloudDocKind, DocLinkKind } from '@modules/ui-types'
import { CuiSmallErrorBoundary } from '@modules/cui/SmallErrorBoundary'

import { getConfigForKey } from '../../store'
import docLinks from '../../constants/docLinks'

import EceDocLink from './EceDocLink'
import EssDocLink from './EssDocLink'
import EssHerokuDocLink from './EssHerokuDocLink'
import StackDocLink from './StackDocLink'
import ServerlessDocLink from './ServerlessDocLink'

import type { DocLinkProps } from './types'
import type { FunctionComponent } from 'react'

export type DocLinkKey = keyof typeof docLinks

interface Props {
  link: DocLinkKey
  showExternalLinkIcon?: boolean
  /**
   * If true, will use the `ess` over the `ece` link, if one exists and the
   * appliation is running in the saas context. Translation: set this to true to
   * link to the ESS docs instead of the ECE docs, in the SaaS adminconsole.
   */
  favorSaasContext?: true
  'aria-label'?: string
}

const cloudDocLinkComponents: Record<DocLinkKind, FunctionComponent<DocLinkProps>> = {
  ess: EssDocLink,
  ess_heroku: EssHerokuDocLink,
  ece: EceDocLink,
  stack: StackDocLink,
  serverless: ServerlessDocLink,
}

const DocLink: FunctionComponent<Props> = ({
  link: linkKey,
  showExternalLinkIcon,
  favorSaasContext,
  children,
  'aria-label': ariaLabel,
}) => {
  const defaultKind = getConfigForKey<CloudDocKind>(`DOCUMENTATION_KIND`)
  const kind = getDocLinkKind(defaultKind, linkKey, Boolean(favorSaasContext))

  const Component = cloudDocLinkComponents[kind]
  const props = docLinks[linkKey][kind]

  return (
    <CuiSmallErrorBoundary forInlineText={true}>
      <Component
        {...props}
        showExternalLinkIcon={Boolean(showExternalLinkIcon)}
        aria-label={ariaLabel}
      >
        {children}
      </Component>
    </CuiSmallErrorBoundary>
  )
}

/**
 * Handle special cases and overrides to the default link kind for the environment.
 */
function getDocLinkKind(
  defaultKind: DocLinkKind | null,
  linkKey: DocLinkKey,
  favorSaasContext: boolean,
): DocLinkKind {
  if (defaultKind === null) {
    throw TypeError(`Expected "DOCUMENTATION_KIND" to be configured, but got: ${defaultKind}`)
  }

  const linksByKind = docLinks[linkKey]

  // Stack links take top priority, when present.
  if ('stack' in linksByKind) {
    return 'stack'
  }

  // then Serverless
  if ('serverless' in linksByKind) {
    return 'serverless'
  }

  // Use ESS docs instead of ECE docs in the AC when requested.
  if (
    defaultKind === 'ece' &&
    'ess' in linksByKind &&
    favorSaasContext &&
    getConfigForKey<CloudAppPlatform>(`APP_PLATFORM`) === 'saas'
  ) {
    return 'ess'
  }

  return defaultKind
}

export default DocLink
