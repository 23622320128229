/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState, useCallback, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiTabs,
  EuiTab,
  EuiSpacer,
  EuiText,
  EuiStat,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPanel,
  EuiAccordion,
} from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'

import messages from '../../messages'
import UsageStat from '../UsageStat'

import { Extras, Project } from './types'

import type { FunctionComponent } from 'react'
import type { ProjectCosts } from './types'

interface Props {
  isLoading: boolean
  totalUsage: number
  costs: ProjectCosts
}

const ServerlessProjects: FunctionComponent<Props> = ({ isLoading, costs, totalUsage }) => {
  const availableProjectCosts = Object.keys(costs).filter((product) =>
    ['elasticsearch', 'security', 'observability'].includes(product),
  )
  const availableExtrasCosts = Object.keys(costs).filter((product) =>
    ['serverless_addons', 'serverless_support'].includes(product),
  )
  const [selectedProjectTabId, setSelectedProjectTabId] = useState(availableProjectCosts[0])
  const [selectedExtrasTabId, setSelectedExtrasTabId] = useState(availableExtrasCosts[0])

  const onSelectedProjectChanged = (id: Project) => {
    setSelectedProjectTabId(id)
  }

  const onSelectedExtrasChanged = (id: Extras) => {
    setSelectedExtrasTabId(id)
  }

  const renderUsageRates = useCallback(
    (rates = [], columns = 2) => (
      <EuiFlexGrid columns={columns} gutterSize='m' responsive={false}>
        {rates.map(({ type, total_ecu }) => (
          <EuiFlexItem key={type}>
            <EuiStat
              isLoading={isLoading}
              title={
                <UsageStat
                  title={
                    <CuiElasticConsumptionUnits value={total_ecu} unit='none' withSymbol={false} />
                  }
                  subText={<FormattedMessage {...messages.ecuUnit} />}
                />
              }
              description={<EuiText size='s'>{type}</EuiText>}
              descriptionElement='div'
              titleSize='xs'
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    ),
    [isLoading],
  )

  const renderProjects = () => {
    const projectTabs = [
      {
        id: Project.Security,
        name: <FormattedMessage {...messages.securityProduct} />,
        content: renderUsageRates(costs.security),
      },
      {
        id: Project.Observability,
        name: <FormattedMessage {...messages.observabilityProduct} />,
        content: renderUsageRates(costs.observability),
      },
      {
        id: Project.Elasticsearch,
        name: <FormattedMessage {...messages.searchProduct} />,
        content: renderUsageRates(costs.elasticsearch),
      },
    ].filter(({ id }) => availableProjectCosts.includes(id))

    return renderTabs({
      tabs: projectTabs,
      selectedTab: selectedProjectTabId,
      onTabChange: onSelectedProjectChanged,
    })
  }

  const renderExtras = () => {
    const extrasTabs = [
      {
        id: Extras.Support,
        name: <FormattedMessage {...messages.extrasSupport} />,
        content: renderUsageRates(costs.serverless_support, 1),
      },
      {
        id: Extras.Addons,
        name: <FormattedMessage {...messages.extrasAddons} />,
        content: renderUsageRates(costs.serverless_addons, 1),
      },
    ].filter(({ id }) => availableExtrasCosts.includes(id))

    return renderTabs({
      tabs: extrasTabs,
      selectedTab: selectedExtrasTabId,
      onTabChange: onSelectedExtrasChanged,
    })
  }

  const renderTabs = ({ tabs, selectedTab, onTabChange }) => {
    const selectedContent = tabs.find((obj) => obj.id === selectedTab)?.content

    return (
      <Fragment>
        <EuiTabs>
          {tabs.map((tab, index) => (
            <EuiTab
              key={index}
              onClick={() => onTabChange(tab.id)}
              isSelected={tab.id === selectedTab}
            >
              {tab.name}
            </EuiTab>
          ))}
        </EuiTabs>
        <EuiSpacer size='l' />

        {selectedContent}
      </Fragment>
    )
  }

  return (
    <EuiPanel hasShadow={false} hasBorder={true}>
      <EuiAccordion
        id='project'
        initialIsOpen={true}
        extraAction={
          <EuiStat
            isLoading={isLoading}
            textAlign='right'
            title={
              <UsageStat
                title={
                  <CuiElasticConsumptionUnits value={totalUsage} unit='none' withSymbol={false} />
                }
                subText={<FormattedMessage {...messages.ecuUnit} />}
              />
            }
            description={
              <EuiText size='s'>
                <FormattedMessage {...messages.currentUsage} />
              </EuiText>
            }
            descriptionElement='div'
            titleSize='xs'
          />
        }
        buttonContent={
          <EuiText size='s'>
            <h4>
              <FormattedMessage {...messages.fullyManagedProjects} />
            </h4>
          </EuiText>
        }
      >
        <EuiSpacer size='m' />
        {renderProjects()}
        {availableExtrasCosts.length > 0 && <EuiSpacer size='l' />}
        {renderExtras()}
      </EuiAccordion>
    </EuiPanel>
  )
}

export default ServerlessProjects
