/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import { useIsProjectInitializing } from '@modules/project-user-lib/hooks/status'
import type { AnyUserProject } from '@modules/project-user-api/types'
import { isProjectSuspended } from '@modules/project-lib/suspended'
import { getKibanaRedirectPageUrl } from '@modules/kibana-redirect/url'

export type Props = {
  project: AnyUserProject
}

const OpenProjectButton: React.FunctionComponent<Props> = ({ project }) => {
  const isInitializing = useIsProjectInitializing(project)
  const isSuspended = isProjectSuspended(project)

  const href = getKibanaRedirectPageUrl(project.type, project.id)

  return (
    <EuiButton fill={true} isDisabled={isInitializing || isSuspended} href={href}>
      <FormattedMessage id='project.openButton' defaultMessage='Open project' />
    </EuiButton>
  )
}

export default OpenProjectButton
