/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'

import type { Region } from '@modules/ui-types'

import { getVersion, getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getLowestSliderVersion } from '@/lib/stackDeployments/upgradesToSliders'
import { getVersionStack } from '@/reducers/elasticStack'
import { getRegion } from '@/reducers/regions'
import { fetchVersionRequest } from '@/reducers/asyncRequests/registry'

import { fetchRegionIfNeeded } from '../../../../actions/regions'
import { fetchVersion, fetchVersions } from '../../../../actions/elasticStack'

import UpgradableDeploymentVersion from './UpgradableDeploymentVersion'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { RouteComponentProps } from 'react-router-dom'
import type { ConsumerProps, StateProps, DispatchProps } from './UpgradableDeploymentVersion'

type ConsumerPropsWithRoute = ConsumerProps & RouteComponentProps

const mapStateToProps = (
  state: ReduxState,
  { deployment, location }: ConsumerPropsWithRoute,
): StateProps => {
  const lowestVersion = getLowestSliderVersion({ deployment })! || getVersion({ deployment })
  const regionId = getRegionId({ deployment })!

  const versionStack = getVersionStack(state, regionId, lowestVersion!)

  const { show_upgrade } = parse(location.search)
  const showModalOnMount = Boolean(show_upgrade)

  return {
    region: getRegion(state, regionId),
    availableVersions: versionStack && versionStack.upgradable_to,
    esVersionRequest: fetchVersionRequest(state, regionId),
    showModalOnMount,
    lowestVersion,
    versionStack,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchRegion: () => dispatch(fetchRegionIfNeeded()),
  fetchVersionStack: (version: string, region: Region | string) =>
    dispatch(fetchVersion(version, region)),
  fetchVersionStacks: (regionId: string) => dispatch(fetchVersions(regionId)),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerPropsWithRoute>(
    mapStateToProps,
    mapDispatchToProps,
  )(UpgradableDeploymentVersion),
)
