/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiText } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'

import prettySize from '@/lib/prettySize'
import { getDeploymentMemoryCapacity } from '@/lib/stackDeployments/selectors/instances'
import PortalDeploymentStatus from '@/components/Portal/components/PortalDeploymentStatus'

import type { FunctionComponent } from 'react'

export type DeploymentInfoItem = 'storage' | 'cpu' | 'ram' | 'status'

interface Props {
  deploymentInfoItem: DeploymentInfoItem
  hasPendingUpdate?: boolean
  deployment: StackDeployment
}

const DeploymentInfo: FunctionComponent<Props> = ({
  deployment,
  deploymentInfoItem,
  hasPendingUpdate,
}) => {
  switch (deploymentInfoItem) {
    case 'status':
      return renderDeploymentStatus(deployment, hasPendingUpdate)
    case 'storage':
    case 'cpu':
      return null
    case 'ram':
      return (
        <EuiText size='s' data-test-id='deployment-total-ram'>
          {prettySize(getDeploymentMemoryCapacity({ deployment }))}
        </EuiText>
      )
    default:
      return null
  }
}

function renderDeploymentStatus(deployment: DeploymentSearchResponse, hasPendingUpdate?: boolean) {
  if (hasPendingUpdate) {
    return (
      <EuiBadge color='warning' data-test-id='deployment-status-badge'>
        <FormattedMessage id='portal-deployment-status.processing' defaultMessage='Pending' />
      </EuiBadge>
    )
  }

  return <PortalDeploymentStatus deployment={deployment} />
}

export default DeploymentInfo
