/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormHelpText,
  EuiLink,
  EuiPortal,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'
import { CuiCodeBlock } from '@modules/cui/CodeBlock'

import DocLink from '../DocLink'

import type { FC } from 'react'

type Props = {
  isSupported: boolean
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  endpoint: string
  body: string | unknown
}

const ApiRequestExampleFC: FC<Props> = ({ isSupported, method, endpoint, body }) => {
  const [showingExample, setShowingExample] = useState(false)

  if (!isSupported) {
    return null
  }

  return (
    <Fragment>
      <EuiFormHelpText>
        <EuiLink onClick={() => setShowingExample(true)}>
          <FormattedMessage
            id='create-deployment-from-template.equivalent-api-request'
            defaultMessage='Equivalent API request'
          />
        </EuiLink>
      </EuiFormHelpText>

      {showingExample && (
        <ApiExampleFlyout
          method={method}
          endpoint={endpoint}
          body={body}
          onClose={() => setShowingExample(false)}
        />
      )}
    </Fragment>
  )
}

type ApiExampleFlyoutProps = Pick<Props, 'method' | 'endpoint' | 'body'> & {
  onClose: () => void
}

const ApiExampleFlyout: FC<ApiExampleFlyoutProps> = ({ method, endpoint, body, onClose }) => {
  const language = typeof body === `string` ? `text` : `json`
  const payload = typeof body === `string` ? body : JSON.stringify(body, null, 2)

  return (
    <EuiPortal>
      <EuiFlyout onClose={onClose} aria-labelledby='apiRequestExampleFlyoutTitle'>
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='s'>
            <h2 id='apiRequestExampleFlyoutTitle'>
              <FormattedMessage
                id='api-request-example.title'
                defaultMessage='Equivalent API request'
              />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <EuiText>
            <FormattedMessage
              id='api-request-example.description'
              defaultMessage='Use the REST API with the following JSON object.'
            />
          </EuiText>

          <EuiSpacer />

          <CuiCodeBlock isCopyable={true}>
            {method} {endpoint}
          </CuiCodeBlock>

          <CuiCodeBlock isCopyable={true} language={language}>
            {payload}
          </CuiCodeBlock>
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup gutterSize='m' alignItems='center' justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <DocLink link='apiReferenceDocLink'>
                <FormattedMessage
                  id='api-request-example.api-reference'
                  defaultMessage='API reference'
                />
              </DocLink>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton onClick={onClose} fill={true}>
                <FormattedMessage id='api-request-example.close' defaultMessage='Close' />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiPortal>
  )
}

export default withErrorBoundary(ApiRequestExampleFC)
