/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// eslint-disable-next-line no-restricted-imports
import { get, isPlainObject, uniqWith, isEqual, isString } from 'lodash'

import type {
  DeploymentUpdateRequest,
  ElasticsearchClusterPlan,
  ElasticsearchClusterTopologyElement,
} from '@modules/cloud-api/v1/types'
import type {
  AnyPlan,
  AnyTopologyElement,
  PlainHashMap,
  SliderInstanceType,
  StackDeployment,
  WellKnownSliderInstanceType,
} from '@modules/ui-types'

import {
  getVersion,
  getSliderPlanFromGet,
  getEsPlanFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  getUserSettingsFromConfiguration,
  getAllSettingsFromDeployment,
} from '@/lib/stackDeployments/selectors/configuration'

import { gte } from '../semver'
import { isValidYaml, yamlToJson } from '../yaml'
import { collapseKeysRecursively } from '../objects'
import { isEnabledConfiguration } from '../deployments/conversion'

import type { JsonFromYaml, JsonObject } from '../yaml'

const defaultEsUserSettings = `
# Note that the syntax for user settings can change between major versions.
# You might need to update these user settings before performing a major version upgrade.
#
# Slack integration for versions 7.0 and later must use the secure key store method.
# For more information, see:
# https://www.elastic.co/guide/en/elasticsearch/reference/current/actions-slack.html#configuring-slack
#
# Slack integration example (for versions after 5.0 and before 7.0)
# xpack.notification.slack:
#   account:
#     monitoring:
#       url: https://hooks.slack.com/services/T0A6BLEEA/B0A6D1PRD/XYZ123
#
# Slack integration example (for versions before 5.0)
# watcher.actions.slack.service:
#   account:
#     monitoring:
#       url: https://hooks.slack.com/services/T0A6BLEEA/B0A6D1PRD/XYZ123
#       message_defaults:
#        from: Watcher
#
# HipChat and PagerDuty integration are also supported. To learn more, see the documentation.
`.trim()

const defaultKibanaUserSettings = `
# Note that the syntax for user settings can change between major versions.
# You might need to update these user settings before performing a major version upgrade.
#
# Use OpenStreetMap for tiles:
# tilemap:
#   options.maxZoom: 18
#   url: http://a.tile.openstreetmap.org/{z}/{x}/{y}.png
#
# To learn more, see the documentation.
`.trim()

const defaultSliderUserSettings = `
# Note that the syntax for user settings can change between major versions.
# You might need to update these user settings before performing a major version upgrade.
#
# To learn more, see the documentation.
`.trim()

// User settings plan paths
const esUserSettingsPath = [`elasticsearch`, `user_settings_yaml`]
const esUserSettingsOverridePath = [`elasticsearch`, `user_settings_override_yaml`]
const esUserSettingsOverrideJsonPath = [`elasticsearch`, `user_settings_override_json`]

export function getDefaultUserSettings(sliderInstanceType?: SliderInstanceType): string {
  switch (sliderInstanceType) {
    case `elasticsearch`:
      return defaultEsUserSettings
    case `kibana`:
      return defaultKibanaUserSettings
    default:
      return defaultSliderUserSettings
  }
}

function hasUserSetting(userSettingsYaml: string | undefined, field: string): boolean {
  const parsedUserSettings = parseUserSettings(userSettingsYaml)
  return findUserSetting(parsedUserSettings, field)
}

function hasUserSettingJson(userSettingsJson: JsonFromYaml, field: string): boolean {
  const parsedUserSettings = parseUserSettingsJson(userSettingsJson)
  return findUserSetting(parsedUserSettings, field)
}

function containsUserSettings(userSettingsYaml: string | undefined): userSettingsYaml is string {
  const parsedUserSettings = parseUserSettings(userSettingsYaml)
  return Object.keys(parsedUserSettings).length > 0
}

export function parseUserSettings(
  userSettingsYaml: string | undefined,
): PlainHashMap<JsonFromYaml> {
  const parsedYaml = yamlToJson(userSettingsYaml || ``)
  return parseUserSettingsJson(parsedYaml)
}

function parseUserSettingsJson(userSettingsJson: JsonFromYaml): PlainHashMap<JsonFromYaml> {
  const yamlObject = isPlainObject(userSettingsJson) ? (userSettingsJson as JsonObject) : {}
  return collapseKeysRecursively(yamlObject)
}

function findUserSetting(parsedUserSettings: PlainHashMap<JsonFromYaml>, field: string): boolean {
  return Object.keys(parsedUserSettings).some((key) => key.startsWith(field))
}

// #47466 - Check that all the Elasticsearch instances have the same user settings. We're not aware of
// a valid case for having them differ, but a very real problem arises when security settings
// are configured on all the nodes except ML, which has caused a few support tickets. Ultimately,
// we should force the settings to be the same for all instance configurations.
export function hasInconsistentUserSettings({
  nodeConfigurations = [],
}: {
  nodeConfigurations?: ElasticsearchClusterTopologyElement[]
}): boolean {
  // disabled sliders are irrelevant to these warnings
  const enabledConfigurations = nodeConfigurations.filter(isEnabledConfiguration)

  const yamlSettings = enabledConfigurations.map(getYamlUserSettings).filter(isString)
  const parsedYamlSettings = yamlSettings.map(parseUserSettings)

  const variants = uniqWith(parsedYamlSettings, isEqual)
  const inconsistentSettings = variants.length > 1

  return inconsistentSettings

  function getYamlUserSettings(nodeConfiguration: ElasticsearchClusterTopologyElement) {
    return nodeConfiguration.elasticsearch?.user_settings_yaml
  }
}

function findUserSettingsBy({
  plan,
  sliderInstanceType,
  predicate,
  settingsPath = null,
  nodeIndex = null,
}: {
  plan: AnyPlan
  sliderInstanceType: WellKnownSliderInstanceType
  predicate: (userSettings: string | null) => boolean | undefined
  settingsPath?: string | string[] | null
  nodeIndex?: number | null
}): boolean {
  // Check plan for user settings
  const defaultPlanUserSettings = getUserSettingsFromConfiguration(plan[sliderInstanceType])
  const planUserSettings =
    settingsPath === null ? defaultPlanUserSettings : get(plan, settingsPath, null)
  const foundPlanUserSetting = predicate(planUserSettings)

  if (foundPlanUserSetting) {
    return true
  }

  // Fall back to checking nodes for user settings
  const topology: AnyTopologyElement[] = plan?.cluster_topology || []

  for (const nodeConfiguration of topology) {
    const topologyIndex = topology.indexOf(nodeConfiguration)

    if (nodeIndex && nodeIndex !== topologyIndex) {
      continue
    }

    if (!isEnabledConfiguration(nodeConfiguration)) {
      continue
    }

    const defaultNodeUserSettings = getUserSettingsFromConfiguration(
      nodeConfiguration?.[sliderInstanceType],
    )
    const nodeUserSettings =
      settingsPath === null ? defaultNodeUserSettings : get(nodeConfiguration, settingsPath, null)
    const foundNodeUserSetting = predicate(nodeUserSettings)

    if (foundNodeUserSetting) {
      return true
    }
  }

  return false
}

function hasNodeLevelUserSettings({
  plan,
  sliderInstanceType,
}: {
  plan: AnyPlan
  sliderInstanceType: WellKnownSliderInstanceType
}): boolean {
  const predicate = (userSettingsYaml: string) => containsUserSettings(userSettingsYaml)

  return findUserSettingsBy({ plan, predicate, sliderInstanceType })
}

export function updateContainsInvalidUserSettingsYaml({
  deployment,
}: {
  deployment: DeploymentUpdateRequest
}): boolean {
  const allUserSettings = getAllSettingsFromDeployment(deployment)
  return !allUserSettings.every(isValidYaml)
}

export function hasAnyUserSettingsInDeployment({
  deployment,
}: {
  deployment: StackDeployment
}): boolean {
  return Object.keys(deployment.resources).some(
    (sliderInstanceType: WellKnownSliderInstanceType) => {
      const plan = getSliderPlanFromGet({ deployment, sliderInstanceType })

      if (!plan) {
        return false
      }

      return hasAnyUserSettings({ plan, sliderInstanceType })
    },
  )
}

function hasAnyUserSettings({
  plan,
  sliderInstanceType,
}: {
  plan: AnyPlan
  sliderInstanceType: WellKnownSliderInstanceType
}): boolean {
  const planLevelUserSettings = containsUserSettings(plan[sliderInstanceType]?.user_settings_yaml)
  const nodeLevelUserSettings = hasNodeLevelUserSettings({ plan, sliderInstanceType })

  return planLevelUserSettings || nodeLevelUserSettings
}

export function hasSecurityRealmSettingsInDeployment({
  deployment,
  planSettingsPath,
  settingIsYaml,
}: {
  deployment: StackDeployment
  planSettingsPath?: string[]
  settingIsYaml?: boolean
}): boolean {
  const version = getVersion({ deployment })

  // xpack settings removed in 8.0.0
  if (version && gte(version, `8.0.0`)) {
    return false
  }

  const plan = getEsPlanFromGet({ deployment })

  if (!plan) {
    return false
  }

  return hasSecurityRealmSettings({ plan, settingsPath: planSettingsPath, settingIsYaml })
}

function hasSecurityRealmSettings({
  plan,
  settingsPath = esUserSettingsPath,
  settingIsYaml = true,
}: {
  plan: ElasticsearchClusterPlan
  settingsPath?: string[]
  settingIsYaml?: boolean
}): boolean {
  const predicate = (userSettings: string) =>
    settingIsYaml
      ? hasUserSetting(userSettings, 'xpack.security.authc.realms')
      : hasUserSettingJson(userSettings, 'xpack.security.authc.realms')

  // These rbac settings are ES only
  const sliderInstanceType = 'elasticsearch' as const
  return findUserSettingsBy({ plan, predicate, sliderInstanceType, settingsPath })
}

function hasSecurityRealmSettingOverrides({ plan }: { plan: ElasticsearchClusterPlan }): boolean {
  const yamlOverrides = hasSecurityRealmSettings({
    plan,
    settingsPath: esUserSettingsOverridePath,
  })

  const jsonOverrides = hasSecurityRealmSettings({
    plan,
    settingsPath: esUserSettingsOverrideJsonPath,
    settingIsYaml: false,
  })

  return yamlOverrides || jsonOverrides
}

export function hasOverridesPreventingUpgrade({
  plan,
  version,
}: {
  plan: ElasticsearchClusterPlan | null
  version: string | null
}): boolean {
  if (!plan || !version) {
    return false
  }

  // if the user has 'xpack.security.authc.realms' set in
  // overrides, they can't update the settings themselves
  return gte(version, `6.7.0`) && hasSecurityRealmSettingOverrides({ plan })
}
