/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
} from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { isRoleAssignmentsEmpty } from '@modules/role-assignments-lib'

import RoleAssignmentsPanel from '@/components/Users/RoleAssignmentsPanel'

import { messages } from '../messages'

const RoleAssignmentFlyout = ({
  organizationId,
  roleAssignments,
  setRoleAssignments,
  onClose,
}: {
  organizationId: string
  roleAssignments: RoleAssignments
  setRoleAssignments: (roleAssignments: RoleAssignments) => void
  onClose: () => void
}) => {
  const [modifiedRoleAssignments, setModifiedRoleAssignments] =
    useState<RoleAssignments>(roleAssignments)
  const { formatMessage } = useIntl()
  return (
    <EuiFlyout onClose={onClose}>
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size='m'>
          <h2>{formatMessage(messages.roleAssignmentsFlyoutTitle)}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexItem grow={false} style={{ padding: '24px' }}>
          <RoleAssignmentsPanel
            organizationId={organizationId}
            roleAssignments={modifiedRoleAssignments}
            onChangeRoleAssignments={(_roleAssignments) =>
              setModifiedRoleAssignments(_roleAssignments)
            }
          />
        </EuiFlexItem>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiButton onClick={onClose}>
            {formatMessage(messages.roleAssignmentsFlyoutCancelButton)}
          </EuiButton>
          <EuiButton
            fill={true}
            disabled={isRoleAssignmentsEmpty(modifiedRoleAssignments)}
            onClick={() => {
              setRoleAssignments(modifiedRoleAssignments)
              onClose()
            }}
          >
            {formatMessage(messages.roleAssignmentsFlyoutSaveButton)}
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default RoleAssignmentFlyout
