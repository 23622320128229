/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

// eslint-disable-next-line import/no-restricted-paths
import { addLocales, enMessages } from '@/i18n/intl'
// eslint-disable-next-line import/no-restricted-paths
import { getLanguage } from '@/lib/locale'

import type { MiddlewareComponent } from '../types'

const IntlMiddlware: MiddlewareComponent = ({ children }) => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    addLocales()
    setIsReady(true)
  }, [])

  if (!isReady) {
    return null
  }

  const lang = getLanguage(window.navigator)

  return (
    <IntlProvider key={lang} locale={lang} messages={enMessages}>
      {children}
    </IntlProvider>
  )
}

IntlMiddlware.middleware = {
  displayName: 'IntlMiddlware',
  dependencies: [],
}

export default IntlMiddlware
