/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import withPermissions from '@modules/permissions-components/PermissionsGate/withPermissions'
import { withLoadedFlags } from '@modules/launchdarkly/flagWrapper'

import { getProfile } from '@/apps/userconsole/reducers/profile'
import {
  fetchOrganizationMembershipsRequest,
  fetchOrganizationInvitationsRequest,
  fetchOrganizationRequest,
} from '@/reducers/asyncRequests/registry'
import { getOrganization } from '@/reducers/organizations'
import {
  fetchOrganizationIfNeeded,
  fetchOrganizationInvitations,
  fetchOrganizationMemberships,
} from '@/actions/organizations'
import { shouldShowOrganization } from '@/components/Organization/lib'
import { getOrganizationMemberRows } from '@/components/Organization/OrganizationMembers/lib'

import Account from './Account'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, StateProps, DispatchProps } from './types'

const isLoading = (state: ReduxState, organizationId: string | undefined): boolean => {
  if (organizationId === undefined) {
    return false
  }

  return (
    fetchOrganizationRequest(state, organizationId).inProgress ||
    fetchOrganizationInvitationsRequest(state, organizationId).inProgress ||
    fetchOrganizationMembershipsRequest(state, organizationId).inProgress
  )
}

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const profile = getProfile(state)
  const organizationId = profile?.organization_id
  const organizationMemberRows = getOrganizationMemberRows(state, organizationId)

  return {
    isLoading: isLoading(state, organizationId),
    location,
    profile,
    organizationId,
    organizationMemberRows,
    showOrganization: shouldShowOrganization(profile),
    organization: organizationId ? getOrganization(state, organizationId) : undefined,
    fetchOrganizationRequest: (id: string) => fetchOrganizationRequest(state, id),
    fetchOrganizationInvitationsRequest: (id: string) =>
      fetchOrganizationInvitationsRequest(state, id),
    fetchOrganizationMembersRequest: (id: string) => fetchOrganizationMembershipsRequest(state, id),
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchOrganizationIfNeeded: (organizationId) =>
    dispatch(fetchOrganizationIfNeeded({ organizationId })),
  fetchOrganizationMembers: (organizationId) =>
    dispatch(fetchOrganizationMemberships({ organizationId })),
  fetchOrganizationInvites: (organizationId) =>
    dispatch(fetchOrganizationInvitations({ organizationId })),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withPermissions({
      hasViewMembersTabPermission: {
        permissionsToCheck: [
          {
            type: 'organization-members',
            action: 'list',
          },
          {
            type: 'organization-invitation',
            action: 'list',
          },
        ],
        behavior: 'some',
      },
      hasViewAccountTabPermission: {
        permissionsToCheck: [
          {
            type: 'organization',
            action: 'update',
          },
        ],
      },
      hasViewApiKeysTabPermission: {
        permissionsToCheck: [
          {
            type: 'api-key',
            action: 'list',
          },
        ],
      },
      hasGetOrganizationPermission: {
        permissionsToCheck: [
          {
            type: 'organization',
            action: 'get',
          },
        ],
      },
      hasListOrganizationMembersPermission: {
        permissionsToCheck: [
          {
            type: 'organization-members',
            action: 'list',
          },
        ],
      },
      hasListOrganizationInvitationsPermission: {
        permissionsToCheck: [
          {
            type: 'organization-invitation',
            action: 'list',
          },
        ],
      },
      hasAccessToSecurityTab: {
        permissionsToCheck: [
          {
            type: 'organization-idp',
            action: 'get',
          },
          {
            type: 'organization-domain-claim',
            action: 'get',
          },
          {
            type: 'organization-role-mapping',
            action: 'get',
          },
        ],
        behavior: 'some',
      },
    })(withLoadedFlags(Account)),
  ),
)
