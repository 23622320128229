/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty, keys } from 'lodash'

import {
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import history from '@modules/utils/history'
import type { AnyTrustRelationship } from '@modules/ui-types'
import { CuiTable } from '@modules/cui/Table'
import { addToast } from '@modules/cui/Toasts'
import { CuiAlert } from '@modules/cui/Alert'
import type {
  AccountTrustRelationship,
  ExternalTrustRelationship,
} from '@modules/cloud-api/v1/types'
import type { CuiTableColumn } from '@modules/cui/Table'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import AddApiKey from '@/components/AddApiKey'
import DangerButton from '@/components/DangerButton'
import { getOrganizationId } from '@/lib/stackDeployments/selectors/metadata'
import { isKeystoreSettingApiKey, extractAliasFromApiKey, shouldShowApiKeys } from '@/lib/apiKeys'
import { createDeploymentTrustRelationshipUrl } from '@/lib/urlBuilder'
import { getConfigForKey } from '@/store'

import {
  getAccountTrustRelationships,
  getDirectTrustRelationships,
  getExternalTrustRelationships,
  getTrustRelationshipId,
  isAccountRelationship,
  isExternalRelationship,
  isOwnAccountRelationship,
  isApiKeysRelationship,
  isDirectRelationship,
} from '../../../lib/stackDeployments/trustRelationships'
import { getTargetEnvironmentType } from '../helpers'

import DeploymentTrustRelationshipEditButton from './EditButton'
import DeploymentTrustRelationshipDeleteButton from './DeleteButton'
import EnvironmentName from './EnvironmentName'
import LinkedDeploymentName from './LinkedDeploymentName'

import type { AllProps as Props } from './types'

const toastText = {
  keyDeletionSuccess: {
    family: 'keystore',
    dataTestSubj: 'delete-api-key.success',
    title: (
      <FormattedMessage
        id='api-key.delete-key.success'
        defaultMessage='API Key successfully deleted'
      />
    ),
    color: 'success',
  },
  keyDeletionFailure: {
    family: 'keystore',
    title: (
      <FormattedMessage id='api-key.delete-key.failure' defaultMessage='API Key not deleted' />
    ),
    color: 'danger',
  },
}

class DeploymentTrustRelationshipTable extends Component<Props> {
  componentWillUnmount(): void {
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element | null {
    const { deployment, trustRelationships, updateStackDeploymentRequest, deleteSecretRequest } =
      this.props

    const isEss = getConfigForKey(`APP_PLATFORM`) === 'saas'

    const orgIdColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.environmentNameHeader'
          defaultMessage='Environment'
        />
      ),
      render: (trustRelationship) => (
        <EnvironmentName
          trustRelationship={trustRelationship}
          environmentTrustRelationships={trustRelationships}
        />
      ),
    }

    const targetEnvironmentColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.targetEnvironmentHeader'
          defaultMessage='Environment type'
        />
      ),
      render: (trustRelationship) => {
        let envTypeMsg
        const envType = getTargetEnvironmentType(trustRelationship)

        switch (envType) {
          case `ess`:
            envTypeMsg = (
              <FormattedMessage
                id='deploymentTrustManagement.table.ess'
                defaultMessage='Elastic Cloud'
              />
            )
            break
          case `ece`:
            envTypeMsg = (
              <FormattedMessage
                id='deploymentTrustManagement.table.ece'
                defaultMessage='Elastic Cloud Enterprise'
              />
            )
            break
          default:
            envTypeMsg = (
              <FormattedMessage
                id='deploymentTrustManagement.table.selfManaged'
                defaultMessage='Self-managed'
              />
            )
        }

        if ((trustRelationship as AccountTrustRelationship).account_id === `all_other`) {
          envTypeMsg = (
            <FormattedMessage
              id='deploymentTrustManagement.table.ess'
              defaultMessage='Elastic Cloud'
            />
          )
        }

        return <EuiText size='s'>{envTypeMsg}</EuiText>
      },
    }

    const authTypeColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.authTypeHeader'
          defaultMessage='Authentication method'
        />
      ),
      render: (trustRelationship) => {
        const castedAccountTrustRelationship = trustRelationship as AccountTrustRelationship
        const isDeploymentAccount = isOwnAccountRelationship(
          trustRelationship,
          getOrganizationId({ deployment }),
        )

        const apiKeysMsg = (
          <FormattedMessage
            id='deploymentTrustManagement.table.api-keys-based'
            defaultMessage='API keys'
          />
        )
        const certMsg = (
          <FormattedMessage
            id='deploymentTrustManagement.table.certificate-based'
            defaultMessage='Certificate'
          />
        )
        const apiKeysAndCertMsg = (
          <FormattedMessage
            id='deploymentTrustManagement.table.api-keys-and-certificate-based'
            defaultMessage='API keys, Certificate'
          />
        )

        if (isApiKeysRelationship(trustRelationship)) {
          return apiKeysMsg
        }

        if (!shouldShowApiKeys(deployment)) {
          return certMsg
        }

        if (isDeploymentAccount) {
          return apiKeysAndCertMsg
        }

        if (isAccountRelationship(trustRelationship)) {
          return castedAccountTrustRelationship.account_id === `all_other` ||
            castedAccountTrustRelationship.account_id === `public_ece_proxy_cas`
            ? apiKeysMsg
            : certMsg
        }

        if (
          isExternalRelationship(trustRelationship) &&
          trustRelationship.name?.endsWith(`(Global Trust by Default ECE)`)
        ) {
          return apiKeysMsg
        }

        if (
          isDirectRelationship(trustRelationship) &&
          !trustRelationship.trust_all &&
          trustRelationship.trust_allowlist === undefined &&
          trustRelationship.additional_node_names === undefined
        ) {
          return apiKeysMsg
        }

        return certMsg
      },
    }

    const actionsColumn: CuiTableColumn<AnyTrustRelationship> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.actionsHeader'
          defaultMessage='Actions'
        />
      ),
      actions: true,
      width: `75px`,
      render: (trustRelationship) => {
        let actionButtonsDisabled = false

        if (
          isAccountRelationship(trustRelationship) &&
          (trustRelationship.account_id === `all_other` ||
            trustRelationship.account_id === `public_ece_proxy_cas`)
        ) {
          actionButtonsDisabled = true
        }

        if (
          isExternalRelationship(trustRelationship) &&
          trustRelationship.name?.endsWith(`(Global Trust by Default ECE)`)
        ) {
          actionButtonsDisabled = true
        }

        return (
          <EuiFlexGroup gutterSize='xs'>
            <EuiFlexItem>
              <DeploymentTrustRelationshipEditButton
                deployment={deployment}
                trustRelationship={trustRelationship}
                disabled={actionButtonsDisabled}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <DeploymentTrustRelationshipDeleteButton
                deployment={deployment}
                trustRelationship={trustRelationship}
                disabled={actionButtonsDisabled}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
    }

    const actionsColumnDep: CuiTableColumn<any> = {
      label: (
        <FormattedMessage
          id='deploymentTrustManagement.table.actionsHeader'
          defaultMessage='Actions'
        />
      ),
      actions: true,
      width: `75px`,
      render: (trustRelationship) => (
        <DeploymentTrustRelationshipEditButton
          deployment={deployment}
          trustRelationship={trustRelationship.tr}
        />
      ),
    }

    const columns: Array<CuiTableColumn<AnyTrustRelationship>> = [
      orgIdColumn,
      authTypeColumn,
      targetEnvironmentColumn,
      actionsColumn,
    ]

    const accounts = getAccountTrustRelationships({ deployment })
    const external = getExternalTrustRelationships({ deployment })
    const direct = getDirectTrustRelationships({ deployment })

    // we want to add a hard-coded item for all other ESS orgs for API based. This won't be editable
    const otherECOrgs: AccountTrustRelationship = {
      account_id: `all_other`,
      name: `All Elastic Cloud organizations`,
      trust_all: false,
    }

    // add another one for ece's with publicly trusted proxy CAs
    const publicProxyCAs: AccountTrustRelationship = {
      account_id: `public_ece_proxy_cas`,
      name: `ECE environments with publicly trusted proxy CAs`,
      trust_all: false,
    }

    const rows: AnyTrustRelationship[] = [...accounts, ...external, ...direct]

    if (shouldShowApiKeys(deployment)) {
      // for ESS we want to spefically add this extra row explaining that they can establish trust to other ESS orgs via API keys only
      rows.splice(1, 0, publicProxyCAs)
      // add the other one about ECE public CA's
      rows.splice(1, 0, otherECOrgs)
    }

    if (!isEss && trustRelationships && shouldShowApiKeys(deployment)) {
      const globalEces = trustRelationships.filter((tr) => tr.trust_by_default && !tr.local)
      const toAdd: ExternalTrustRelationship[] = []
      globalEces.forEach((tr) => {
        toAdd.push({
          trust_relationship_id: tr.id,
          name: tr.name + ' (Global Trust by Default ECE)',
          trust_all: false,
        })
      })
      rows.splice(1, 0, ...toAdd)
    }

    let deploymentList: AnyTrustRelationship[] = []

    if (!isEmpty(accounts)) {
      deploymentList = deploymentList.concat(this.getDeployments(accounts))
    }

    if (!isEmpty(direct)) {
      deploymentList = deploymentList.concat(this.getDeployments(direct))
    }

    if (!isEmpty(external)) {
      deploymentList = deploymentList.concat(this.getDeployments(external))
    }

    const depColumns = [
      {
        label: (
          <FormattedMessage id='deploymentTrustManagement.table.trust' defaultMessage='Trust' />
        ),
        render: (dep) => (
          <LinkedDeploymentName trustRelationship={dep.tr} clusterId={dep.clusterId} />
        ),
      },
      {
        label: (
          <FormattedMessage
            id='deploymentTrustManagement.deployment-table.environmentNameHeader'
            defaultMessage='Environment'
          />
        ),
        render: (dep) => dep.envName,
      },
      actionsColumnDep,
    ]

    const apiKeyColumns = [
      {
        label: (
          <FormattedMessage id='deploymentTrustManagement.table.alias' defaultMessage='Alias' />
        ),
        render: (dep) => dep.name,
      },
      {
        mobile: {
          label: <FormattedMessage id='api-key.actions' defaultMessage='Actions' />,
        },
        actions: true,
        render: (key) => {
          const request = deleteSecretRequest(key.name)

          return (
            <span data-test-id='api-key-deleteButton'>
              <PermissionsGate
                permissions={[
                  {
                    type: 'deployment',
                    action: 'update',
                    id: deployment.id,
                  },
                ]}
              >
                {({ hasPermissions }) => (
                  <DangerButton
                    buttonType={EuiButtonIcon}
                    buttonProps={{ color: `danger` }}
                    disabled={!hasPermissions}
                    onConfirm={() => this.deleteKey(key.name)}
                    isBusy={request.inProgress}
                    spin={request.inProgress}
                    modal={{
                      title: (
                        <FormattedMessage
                          id='api-key.key.confirm-to-delete'
                          defaultMessage='Delete API Key {name}?'
                          values={{ name: key.name }}
                        />
                      ),
                    }}
                    iconType='trash'
                    aria-label='Delete'
                  />
                )}
              </PermissionsGate>
            </span>
          )
        },
        width: `50px`,
      },
    ]

    const apiKeyRows = this.getApiKeys()

    return (
      <Fragment>
        <EuiTitle size='xs'>
          <h3>
            <FormattedMessage
              id='deploymentTrustManagement.table.environments-header'
              defaultMessage='Trusted environments'
            />
          </h3>
        </EuiTitle>
        <EuiSpacer size='s' />
        {this.renderAddButton()}
        <EuiSpacer size='s' />
        <CuiTable<AnyTrustRelationship>
          tableLayout='auto'
          rows={rows}
          getRowId={(trustRelationship) => getTrustRelationshipId({ trustRelationship })}
          columns={columns}
        />
        {updateStackDeploymentRequest.inProgress && (
          <Fragment>
            <EuiSpacer />
            <EuiLoadingSpinner />
          </Fragment>
        )}
        {updateStackDeploymentRequest.error && (
          <Fragment>
            <EuiSpacer />
            <CuiAlert type='danger'>{updateStackDeploymentRequest.error}</CuiAlert>
          </Fragment>
        )}
        <EuiSpacer />
        <EuiTitle size='xs'>
          <h3>
            <FormattedMessage
              id='deploymentTrustManagement.table.cert-based-deps-header'
              defaultMessage='Connections using certificates'
            />
          </h3>
        </EuiTitle>
        <EuiSpacer size='s' />
        <CuiTable
          tableLayout='auto'
          rows={deploymentList}
          getRowId={(dep) => dep.id}
          columns={depColumns}
        />
        {shouldShowApiKeys(deployment) && (
          <Fragment>
            <EuiSpacer />
            <EuiTitle size='xs'>
              <h3>
                <FormattedMessage
                  id='deploymentTrustManagement.table.deployments-apikey-header'
                  defaultMessage='Connections using API keys'
                />
              </h3>
            </EuiTitle>
            <EuiSpacer size='s' />
            <AddApiKey deployment={deployment} />
            <EuiSpacer size='s' />
            <CuiTable
              tableLayout='auto'
              rows={apiKeyRows}
              getRowId={(dep) => dep.name}
              columns={apiKeyColumns}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderAddButton(): JSX.Element | null {
    const { deployment } = this.props

    return (
      <PermissionsGate
        permissions={[
          {
            type: 'trusted-environment',
            action: 'list',
          },
        ]}
      >
        {({ hasPermissions }) => (
          <EuiButton
            disabled={!hasPermissions}
            size='s'
            onClick={() =>
              history.push(createDeploymentTrustRelationshipUrl(deployment.id, undefined))
            }
            data-test-id='add-deployment-trust-button'
          >
            <FormattedMessage
              id='deploymentTrustManagement.ess.addTrustButton'
              defaultMessage='Add trusted environment'
            />
          </EuiButton>
        )}
      </PermissionsGate>
    )
  }

  deleteKey = (key: string): void => {
    const { deleteSecretFromKeystore } = this.props

    const apiKey = `cluster.remote.${key}.credentials`

    deleteSecretFromKeystore(apiKey)
      .then(() => {
        addToast({
          ...toastText.keyDeletionSuccess,
        })
        return
      })
      .catch(() => {
        addToast({
          ...toastText.keyDeletionFailure,
        })
      })
  }

  getApiKeys() {
    const { keystore } = this.props
    const rows = keystore ? keys(keystore).filter((key) => isKeystoreSettingApiKey(key)) : []

    return rows.map((row) => ({ name: extractAliasFromApiKey(row) }))
  }

  getDeployments(trustRelationship) {
    const depList: any[] = []

    if (!isEmpty(trustRelationship)) {
      trustRelationship.forEach((tr) => {
        if (tr.trust_all) {
          depList.push({
            id: `all_${tr.name}`,
            envName: tr.name,
            tr,
            clusterId: `all`,
          })
        } else if (tr.trust_allowlist) {
          tr.trust_allowlist.forEach((dep) => {
            depList.push({
              id: dep + tr.name,
              envName: tr.name,
              tr,
              clusterId: dep,
            })
          })
        }
      })
    }

    return depList
  }
}

export default DeploymentTrustRelationshipTable
