/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { OrganizationRequest } from '@modules/cloud-api/v1/types'

import { ThunkDispatch } from '@/types/redux'
import {
  fetchOrganizationRequest,
  updateOrganizationRequest,
} from '@/reducers/asyncRequests/registry'
import { getOrganization } from '@/reducers/organizations'
import {
  fetchOrganizationIfNeeded,
  updateOrganization,
  resetUpdateOrganizationRequest,
} from '@/actions/organizations'

import AllowedDomains from './AllowedDomains'

import type { ConsumerProps, StateProps, DispatchProps } from './types'

const mapStateToProps = (state, { organizationId }: ConsumerProps): StateProps => ({
  organization: getOrganization(state, organizationId),
  fetchOrganizationRequest: fetchOrganizationRequest(state, organizationId),
  updateOrganizationRequest: updateOrganizationRequest(state, organizationId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId }: ConsumerProps,
): DispatchProps => ({
  fetchOrganizationIfNeeded: () => dispatch(fetchOrganizationIfNeeded({ organizationId })),
  updateOrganization: (updateRequest: OrganizationRequest) =>
    dispatch(updateOrganization({ organizationId, organization: updateRequest })),
  resetUpdateOrganizationRequest: () => dispatch(resetUpdateOrganizationRequest(organizationId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AllowedDomains)
