/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SliderInstanceType, StackDeployment } from '@modules/ui-types'
import Feature from '@modules/utils/feature'
import withPermissions from '@modules/permissions-components/PermissionsGate/withPermissions'

import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getDeploymentAllocatorIds } from '@/lib/stackDeployments/selectors/instances'

import { searchAllocatorsSimpleQuery } from '../../../actions/allocatorSearch'
import { fetchNodeStats } from '../../../actions/deployments/fetchNodeStats'
import { getConfigForKey, isFeatureActivated } from '../../../selectors'

import StackDeploymentNodesVisualization from './StackDeploymentNodesVisualization'
import createDeploymentAllocatorsQuery from './lib/createDeploymentAllocatorsQuery'

import type { ReactNode } from 'react'
import type { ThunkDispatch } from '@/types/redux'

type StateProps = {
  disableNodeControlsIfPlanPending?: boolean
  shouldFetchDeploymentAllocators: boolean
}

interface DispatchProps {
  fetchDeploymentAllocators: () => void
  fetchNodeStats: () => void
}

type ConsumerProps = {
  title: ReactNode
  deployment: StackDeployment
  showNativeMemoryPressure?: boolean
  sliderInstanceType?: SliderInstanceType
}

const mapStateToProps = (state: any): StateProps => ({
  disableNodeControlsIfPlanPending: isFeatureActivated(
    state,
    Feature.disableNodeControlsIfPlanPending,
  ),
  shouldFetchDeploymentAllocators: getConfigForKey(state, `APP_NAME`) === `adminconsole`,
})

const mapDispatchToProps = (dispatch: ThunkDispatch, { deployment }): DispatchProps => {
  const regionId = getRegionId({ deployment })
  const allocatorIds = getDeploymentAllocatorIds({ deployment })

  return {
    fetchDeploymentAllocators: () => {
      if (allocatorIds.length === 0) {
        return
      }

      dispatch(
        searchAllocatorsSimpleQuery(
          `search-deployment-allocators/${deployment.id}`,
          regionId,
          createDeploymentAllocatorsQuery(allocatorIds),
        ),
      )
    },
    fetchNodeStats: () => dispatch(fetchNodeStats(deployment)),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(
  withPermissions({
    hasSearchAllocatorsPermission: {
      permissionsToCheck: [{ type: 'allocator', action: 'search' }],
    },
  })(StackDeploymentNodesVisualization),
)
