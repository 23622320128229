/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { EuiCallOut, type EuiCallOutProps } from '@elastic/eui'

type CalloutType = 'info' | 'danger'

type ChartCalloutProps = {
  type: CalloutType
  title: string
  message: string | undefined
}

const ColorByType: Record<CalloutType, EuiCallOutProps['color'] | undefined> = {
  info: undefined,
  danger: 'danger',
}

const IconByType: Record<CalloutType, EuiCallOutProps['iconType']> = {
  info: 'iInCircle',
  danger: 'error',
}

export const ChartCallout = ({ type, title, message }: ChartCalloutProps) => (
  <EuiCallOut title={title} color={ColorByType[type]} iconType={IconByType[type]}>
    {message && <p>{message}</p>}
  </EuiCallOut>
)
