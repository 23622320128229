/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { capitalize } from 'lodash'

import { EuiText, EuiTitle, EuiPanel, EuiSpacer, EuiTextColor } from '@elastic/eui'

import type { BillingSubscriptionLevel } from '@modules/ui-types'
import { getSupportNameBasedOnLevel } from '@modules/billing-lib/supportName'

import type { ReactElement, FunctionComponent } from 'react'

export interface Props {
  subscriptionLevel: BillingSubscriptionLevel
  title: string | ReactElement
  details?: string | ReactElement
  footer?: ReactElement
  backgroundImage: string
}

const SubscriptionDetailsCard: FunctionComponent<Props> = ({
  subscriptionLevel,
  title,
  details,
  footer,
  backgroundImage,
}) => (
  <EuiPanel
    paddingSize='l'
    css={css({
      backgroundImage: `url("${backgroundImage}")`,
      backgroundColor: '#20377D',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: 'contain',
      paddingRight: 70,
    })}
    hasShadow={false}
    data-test-id='subscription-details-card'
  >
    <EuiTextColor color='ghost'>
      <EuiText size='s'>
        <strong>{title}</strong>
      </EuiText>

      <EuiSpacer size='m' />

      <EuiTitle size='m' className='eui-textInheritColor'>
        <h2 data-test-id='subscription-details-card.subscription-level'>
          {capitalize(subscriptionLevel)}
        </h2>
      </EuiTitle>
      <EuiText size='s'>
        <FormattedMessage
          id='subscription-details-card.support'
          defaultMessage='with {supportLevel} support'
          values={{ supportLevel: getSupportNameBasedOnLevel(subscriptionLevel) }}
        />
      </EuiText>

      <EuiSpacer size='m' />

      {details && <EuiText>{details}</EuiText>}
    </EuiTextColor>

    <EuiSpacer size='m' />

    {footer}
  </EuiPanel>
)

export default SubscriptionDetailsCard
