/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import type { DeploymentTemplateInfoV2, DeploymentsListResponse } from '@modules/cloud-api/v1/types'
import type { GetDeploymentTemplatesV2UrlParams } from '@modules/cloud-api/v1/urls'
import {
  getDeploymentTemplatesV2Url,
  listDeploymentsUrl,
  searchDeploymentsUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'
import type { QueryHookOptions } from '@modules/query/types'

// eslint-disable-next-line import/no-restricted-paths
import searchDeploymentsQuery from '@/components/StackDeploymentSearch/searchDeploymentsQuery'
// eslint-disable-next-line import/no-restricted-paths
import { transformResourceQuery } from '@/components/StackDeploymentSearch/DeploymentFilterContext/queryParser'

export const useListDeploymentsQuery = (options?: QueryHookOptions) =>
  useQuery(
    'listDeployments',
    () =>
      fetchAsJson<DeploymentsListResponse>(listDeploymentsUrl(), {
        method: 'get',
      }),
    options,
  )

export const useSearchOrganizationDeploymentsQuery = (
  organizationId: string,
  options?: QueryHookOptions,
) => {
  const query = searchDeploymentsQuery()

  if (query.query?.bool === undefined) {
    throw new Error('Expected organization search query to contain a bool node')
  }

  const matchOrganization = transformResourceQuery({
    path: `info.settings.metadata.organization_id`,
    queryType: `match`,
    query: { query: String(organizationId) },
  })

  query.query.bool.filter = [...(query.query.bool.filter || []), matchOrganization]

  return useQuery(
    ['searchOrganizationDeployments', organizationId],
    () =>
      fetchAsJson<DeploymentsListResponse>(searchDeploymentsUrl(), {
        method: 'post',
        body: JSON.stringify(query),
      }),
    options,
  )
}

export const useGetDeploymentTemplatesQuery = (
  params: GetDeploymentTemplatesV2UrlParams,
  options?: QueryHookOptions,
) =>
  useQuery(
    ['getDeploymentTemplates', params],
    () =>
      fetchAsJson<DeploymentTemplateInfoV2[]>(getDeploymentTemplatesV2Url(params), {
        method: 'get',
      }),
    options,
  )
