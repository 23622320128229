/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { fromEvent, Observable, Subject, startWith, shareReplay, distinctUntilChanged } from 'rxjs'
import { v4 } from 'uuid'

import type { AnalyticsClient } from '@elastic/ebt'

import type { History } from 'history'

const HTML_ATTRIBUTES_TO_REMOVE = [
  'data-href',
  'data-ech-series-name',
  'data-provider-id',
  'data-rfd-drag-handle-draggable-id',
  'data-rfd-droppable-id',
  'data-rfd-draggable-id',
  'href',
  'value',
  'title',
]

export function trackClicks(analytics: AnalyticsClient, isDevMode: boolean) {
  analytics.registerEventType<{ target: string[] }>({
    eventType: 'click',
    schema: {
      target: {
        type: 'array',
        items: {
          type: 'keyword',
          _meta: {
            description:
              'The attributes of the clicked element and all its parents in the form `{attr.name}={attr.value}`. It allows finding the clicked elements by looking up its attributes like "data-test-subj=my-button".',
          },
        },
      },
    },
  })

  return fromEvent(window, 'click').subscribe((event) => {
    try {
      const target = event.target as HTMLElement
      analytics.reportEvent('click', { target: getTargetDefinition(target) })
    } catch (error) {
      if (isDevMode) {
        // Defensively log the error in dev mode to catch any potential bugs.
        // eslint-disable-next-line no-console
        console.error(`Failed to report the click event`, { event, error })
      }
    }
  })
}

function getTargetDefinition(target: HTMLElement): string[] {
  const definitions: string[] = []
  let currentElement: HTMLElement | null = target

  while (currentElement) {
    // Add filtered attributes first to maintain order
    for (const attr of Array.from(currentElement.attributes)) {
      if (!HTML_ATTRIBUTES_TO_REMOVE.includes(attr.name)) {
        definitions.unshift(`${attr.name}=${attr.value}`.slice(0, 256))
      }
    }

    // Then add the tag name
    definitions.unshift(currentElement.tagName)

    // Move to the parent element
    currentElement = currentElement.parentElement
  }

  return definitions
}

export function getSessionId(): string {
  const sessionId = sessionStorage.getItem('sessionId') ?? v4()
  sessionStorage.setItem('sessionId', sessionId)
  return sessionId
}

export interface Location {
  pathname: string
  hash: string
}

export const getLocationObservable = (
  initialLocation: Location,
  history: History,
): Observable<string> => {
  const subject = new Subject<string>()
  history.listen((location) => {
    subject.next(locationToUrl(location))
  })
  return subject.pipe(
    startWith(locationToUrl(initialLocation)),
    distinctUntilChanged(),
    shareReplay(1),
  )
}

const locationToUrl = (location: Location) => `${location.pathname}${location.hash}`
