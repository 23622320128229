/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  standardUserAuthentication: {
    id: `organization.security.user-authentication.standard-method`,
    defaultMessage: `Email, Google, or Microsoft login`,
  },
  samlUserAuthentication: {
    id: `organization.security.user-authentication.saml-method`,
    defaultMessage: `SAML SSO login`,
  },
  standardAndSamlUserAuthentication: {
    id: `organization.security.user-authentication.standard-and-saml-method`,
    defaultMessage: `Email, Google, Microsoft, or SAML SSO login`,
  },
})

export const getLoginMethodMessage = (isEnabledSamlSso, isEnforcedSamlSso) => {
  if (isEnabledSamlSso && isEnforcedSamlSso) {
    return messages.samlUserAuthentication
  }

  if (isEnabledSamlSso) {
    return messages.standardAndSamlUserAuthentication
  }

  return messages.standardUserAuthentication
}
