/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt, EuiButton, EuiImage } from '@elastic/eui'

import LocalStorageKey from '@modules/utils/localStorageKeys'

import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import trialExpiredIllustration from '../../../../../files/illustration-hour-glass-128-white-bg.svg'
import trialExpiredIllustrationDark from '../../../../../files/illustration-hour-glass-128-ink-bg.svg'

import type { FunctionComponent } from 'react'

export interface Props {
  disableButtons: boolean
  theme: 'dark' | 'light'
  isGovCloud: boolean
}

const onBillingDetails = () => {
  localStorage.setItem(LocalStorageKey.trialExperienceDismissed, 'true')
}

const TrialExpiredPrompt: FunctionComponent<Props> = ({ disableButtons, theme, isGovCloud }) => {
  const themes = {
    light: trialExpiredIllustration,
    dark: trialExpiredIllustrationDark,
  }

  return (
    <EuiEmptyPrompt
      icon={<EuiImage css={css({ width: 206, height: 206 })} src={themes[theme]} alt='' />}
      title={
        <h3>
          <FormattedMessage
            id='no-deployment-state.expired-trial'
            defaultMessage='Your trial is over'
          />
        </h3>
      }
      body={
        isGovCloud ? (
          <p data-test-id='govcloud-trial-expired'>
            <FormattedMessage
              id='no-deployment-state.govcloud-expired-trial-info'
              defaultMessage='Your deployment has been terminated. Contact us to restore your deployment and continue using our Elasticsearch services.'
            />
          </p>
        ) : (
          <p data-test-id='trial-expired'>
            <FormattedMessage
              id='no-deployment-state.expired-trial-info'
              defaultMessage='Your deployment has been terminated. Provide your credit card details to restore your deployment and continue using Elastic Cloud.'
            />
          </p>
        )
      }
      actions={
        isGovCloud
          ? [
              <EuiButton
                href='mailto:gov-onboarding@elastic.co'
                data-test-id='govcloud-contact-us'
                fill={true}
              >
                <FormattedMessage id='no-deployment-state.contact-us' defaultMessage='Contact us' />
              </EuiButton>,
            ]
          : [
              <CreditCardModalButton
                disabled={disableButtons}
                fill={true}
                type='full'
                onOpenModal={onBillingDetails}
              >
                <FormattedMessage
                  id='no-deployment-state.subscribe'
                  defaultMessage='Provide billing details'
                />
              </CreditCardModalButton>,
            ]
      }
    />
  )
}

export default TrialExpiredPrompt
