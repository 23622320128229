/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse, format } from 'url'

import { isEmpty } from 'lodash'
import { parse as parseQuery } from 'query-string'
import jwtDecode from 'jwt-decode'

import { rootUrl } from '@modules/utils/rootUrls'
import LocalStorageKey from '@modules/utils/localStorageKeys'

import {
  loginUrl,
  basicLoginUrl,
  ssoLoginUrl,
  logoutUrl,
  acceptInviteUrl,
  registrationUrl,
  serverlessRegistrationUrl,
  resetPasswordUrl,
  forgotPasswordUrl,
} from './urls'

interface JwtBearerToken {
  exp?: number
  okta_session_id?: string
}

type HandleUnauthorizedParams = {
  isHeroku?: boolean
  location: {
    pathname: string
    search?: string
  }
  setLocation: (redirectUrl: string) => void
}

export function handleUnauthorizedAction({
  isHeroku,
  location,
  setLocation,
}: HandleUnauthorizedParams): Promise<void> {
  // When Cloud's session is being invalidated for the first time,
  // we want to invalidate Okta's session too, but only once.
  const invalidateIdPSession = getAuthTokenExpiration() !== null

  // Token not valid so we remove it and redirect to login
  clearAuthExpiration()

  // Redirect to the current location after authentication
  const { pathname, search = `` } = location
  const currentPathname = parse(pathname).pathname

  /* Heroku customers can't auth through our UI, so redirecting them to Login is pointless.
   * We don't handle that route for Heroku customers and a big error is shown anyway.
   * Instead, Heroku customers use specially crafted auth params to log in.
   * See also: `HerokuAppRoot`.
   */
  const noLoginPage = isHeroku

  if (noLoginPage) {
    if (currentPathname !== rootUrl()) {
      setLocation(rootUrl())
    }

    return Promise.resolve()
  }

  const nonAuthRoutes = [
    loginUrl(),
    logoutUrl(),
    registrationUrl(),
    serverlessRegistrationUrl(),
    basicLoginUrl(),
    ssoLoginUrl(),
    acceptInviteUrl(),
    resetPasswordUrl(),
    forgotPasswordUrl(),
  ]

  if (currentPathname && nonAuthRoutes.some((route) => currentPathname.startsWith(route))) {
    return Promise.resolve()
  }

  const redirectTo = encodeURIComponent(pathname + search)

  setLocation(
    `${logoutUrl()}?redirectTo=${redirectTo}&reason=unauthorised&invalidateIdPSession=${invalidateIdPSession}`,
  )

  return Promise.resolve()
}

export function hasAnySsoMethod(authMethods) {
  if (!authMethods) {
    return false
  }

  const { sso_methods } = authMethods

  return !isEmpty(sso_methods)
}

export function hasRedirectOnMount(newBearerToken) {
  return Boolean(newBearerToken) || hasUnexpiredSession()
}

export function getLoginUrl({ ssoUrl, location }) {
  const locationQuery = parseQuery(location.search.slice(1))
  const { redirectTo } = locationQuery
  const parts = parse(ssoUrl, true)
  const query = parts.query || {}

  parts.query = {
    ...query,
    state: JSON.stringify({ redirectTo }),
  }

  return format(parts)
}

export function getBearerToken(hash: string): string | null {
  const poundlessHash = String(hash).slice(1)
  const hashParams = parseQuery(poundlessHash)
  const newBearerToken = hashParams.bearer_token

  if (typeof newBearerToken !== `string`) {
    return null // the hash might not contain a bearer token, and that's fine
  }

  return newBearerToken
}

export function redirectOnMount({
  redirectAfterLogin,
  redirectTo,
  fromURI,
  logout,
  newBearerToken,
}: {
  redirectAfterLogin: (redirectTo?: string) => void
  redirectTo?: string
  fromURI?: string
  logout?: (fromURI?: string) => void
  newBearerToken: string | null
}) {
  /*If users are coming from Okta (there's a fromURI parameter),
   * and we can assume Okta has already checked and there wasn't a SSO session cookie for them.
   * Even if we have a non-expired JWT token, user needs to re-authenticate.
   */
  if (fromURI && logout) {
    logout(fromURI)
    return
  }
  /* Allows us to receive Basic authentication requests.
   * 1. Server request to https://user:pass@cloud.elastic.co/login/_basic
   * 2. Server redirects to https://cloud.elastic.co/login#bearer_token=$API_BEARER_TOKEN
   * 3. Client persists the bearer token
   */

  if (newBearerToken) {
    updateAuthExpirationFromToken(newBearerToken)
  }

  /* Besides being useful when we receive a Basic authentication token,
   * the original — and still intended — purpose of this redirect is
   * to not challenge authenticated users with a Login screen.
   */
  const hasSession = hasUnexpiredSession()

  if (hasSession) {
    redirectAfterLogin(redirectTo)
  }
}

/*
 * This is used for the following flows: basic authentication and SSO login
 */
export function updateAuthExpirationFromToken(token: string) {
  try {
    const decoded = jwtDecode<JwtBearerToken>(token)
    localStorage.setItem(
      LocalStorageKey.cloudAuthExpires,
      JSON.stringify(decoded.exp ? decoded.exp * 1000 : null),
    )
  } catch (err) {
    console.warn(err)
    clearAuthExpiration()
  }
}

export function updateAuthExpiration(expiration: string) {
  try {
    const date = new Date(expiration)
    const timestamp = date.getTime()
    localStorage.setItem(LocalStorageKey.cloudAuthExpires, JSON.stringify(timestamp))
  } catch (err) {
    console.warn(err)
    clearAuthExpiration()
  }
}

export function clearAuthExpiration() {
  // clear token bits
  localStorage.removeItem(LocalStorageKey.cloudAuthExpires)

  // Upon logout, forget Heroku cluster
  localStorage.removeItem(LocalStorageKey.herokuCluster)
}

function getAuthTokenExpiration(): Date | null {
  const cloudAuthExpires = localStorage.getItem(LocalStorageKey.cloudAuthExpires)

  if (cloudAuthExpires === null) {
    return null
  }

  return JSON.parse(cloudAuthExpires)
}

export function hasUnexpiredSession(): boolean {
  const expiration = getAuthTokenExpiration()

  return expiresInTheFuture(expiration)
}

export function expiresInTheFuture(date: Date | null): boolean {
  if (!date) {
    return false
  }

  return date.valueOf() > Date.now()
}

/* Returns true if auth token is about to expire in less than a range between 2
 * and 5 minutes from now. This entropy is needed because we want to make sure
 * browsers don't refresh the token at the same time.
 */
export function shouldRefreshAuthToken(): boolean {
  const expiration = getAuthTokenExpiration()

  // If we don't have an expiration saved in local storage, we can't refresh
  if (!expiration) {
    return false
  }

  const twoMinutes = 1000 * 60 * 2

  // anywhere from 0 to 3 minutes so that different browser tabs don't fight for tokens
  const upToThreeMinutes = Math.random() * 1000 * 60 * 3

  // refresh ~2-5 minutes before the token expires
  const refreshEagerness = twoMinutes + upToThreeMinutes

  const refreshTime = expiration.valueOf() - refreshEagerness

  return Date.now() > refreshTime
}
