/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import {
  getCompatibleDeploymentTemplates,
  getPreferredMigrationDestinationVersion,
} from '@/lib/stackDeployments/migrationsToEnterpriseSearch'
import { getActiveVersionStacks } from '@/reducers/elasticStack'
import { getRegion } from '@/reducers/regions'
import { getAppSearchToEnterpriseSearchMigrationProgress } from '@/reducers/appSearchToEnterpriseSearchMigrationProgress'
import { getDeploymentTemplates } from '@/reducers/deploymentTemplates'
import {
  fetchProvidersRequest,
  fetchDeploymentTemplatesRequest,
  fetchVersionsRequest,
  createStackDeploymentRequest,
} from '@/reducers/asyncRequests/registry'

import { createDeployment } from '../../../../actions/stackDeployments/crud'
import { fetchRegion } from '../../../../actions/regions'
import { fetchVersions } from '../../../../actions/elasticStack'
import { fetchDeploymentTemplates } from '../../../../actions/topology/deploymentTemplates'

import CreateDeployment from './CreateDeployment'
import { StateProps, DispatchProps, ConsumerProps } from './types'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

const mapStateToProps: (state: ReduxState, consumerProps: ConsumerProps) => StateProps = (
  state,
  { sourceDeployment },
) => {
  const regionId = getRegionId({ deployment: sourceDeployment })
  const stackVersions = getActiveVersionStacks(state, regionId) ?? []

  const targetVersion = getPreferredMigrationDestinationVersion(stackVersions)

  const deploymentTemplates = getDeploymentTemplates(state, regionId, targetVersion)

  const eligibleDeploymentTemplates = getCompatibleDeploymentTemplates({
    deployment: sourceDeployment,
    deploymentTemplates,
  })

  const progress = getAppSearchToEnterpriseSearchMigrationProgress(state, sourceDeployment.id)!
  const sourceSnapshotName = progress.snapshotToWatch!

  return {
    regionId,
    region: getRegion(state, regionId),
    targetVersion,
    stackVersions,
    createDeploymentRequest: createStackDeploymentRequest(state),
    fetchProvidersRequest: fetchProvidersRequest(state, regionId),
    fetchVersionsRequest: fetchVersionsRequest(state),
    fetchDeploymentTemplatesRequest: fetchDeploymentTemplatesRequest(
      state,
      regionId,
      targetVersion,
    ),
    sourceSnapshotName,
    deploymentTemplates,
    eligibleDeploymentTemplates,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  createDeployment: (params) => dispatch(createDeployment(params)),
  fetchRegion: () => dispatch(fetchRegion()),
  fetchVersions: (regionId) => dispatch(fetchVersions(regionId)),
  fetchDeploymentTemplates: (params) => dispatch(fetchDeploymentTemplates(params)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDeployment)
