/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { difference, differenceWith, isEqual } from 'lodash'

import type {
  RoleAssignments,
  DeploymentRoleAssignment,
  OrganizationRoleAssignment,
} from '@modules/cloud-api/v1/types'
import { getEmptyRoleAssignments } from '@modules/role-assignments-lib'
import diffProjectRoleAssignments from '@modules/role-assignments-lib/diff'
import { ROLE_IDS } from '@modules/role-assignments-lib/types'
import type { OrganizationRoleId } from '@modules/role-assignments-lib/types'

export const toggleOrganizationBillingRoleAssignment = (
  roleAssignments: RoleAssignments,
  organizationId: string,
): RoleAssignments => {
  // shouldReplace must be true when ORGANIZATION_BILLING doesn't exist
  const shouldReplace =
    roleAssignments.organization?.every(({ role_id }) => role_id !== 'billing-admin') ?? true

  // merge with existing roleAssignments since ORGANIZATION_BILLING doesn't
  // interfere with deployment roles assignments
  return {
    ...roleAssignments,
    organization: shouldReplace
      ? [
          {
            organization_id: organizationId,
            role_id: 'billing-admin',
          },
        ]
      : [],
  }
}

export const toggleOrganizationOwnerRoleAssignment = (
  roleAssignments: RoleAssignments,
  organizationId: string,
): RoleAssignments => {
  // shouldReplace must be true when organization-admin doesn't exist
  const shouldReplace =
    roleAssignments.organization?.every(({ role_id }) => role_id !== 'organization-admin') ?? true

  // do not merge with existing roleAssignments since organization owner
  // replaces everything else, including deployment roles assignments
  return {
    ...getEmptyRoleAssignments(),
    organization: shouldReplace
      ? [
          {
            organization_id: organizationId,
            role_id: 'organization-admin',
          },
        ]
      : [],
  }
}

export const filterDeploymentRoleAssignmentByAll = (
  deploymentRoleAssignments: DeploymentRoleAssignment[] | undefined,
  all: boolean,
): DeploymentRoleAssignment[] =>
  deploymentRoleAssignments
    ?.filter((deploymentRoleAssignment) => (deploymentRoleAssignment.all ?? false) === all)
    .filter(hasValidDeploymentRoleId)
    .sort(sortDeploymentRoleAssignments) || []

const hasValidDeploymentRoleId = (
  roleAssignment: DeploymentRoleAssignment,
): roleAssignment is DeploymentRoleAssignment => {
  const roleIds: readonly string[] = ROLE_IDS.deployment
  return roleIds.includes(roleAssignment.role_id)
}

export const isOrganizationRoleSelected = (
  roleAssignments: RoleAssignments,
  role: OrganizationRoleId,
): boolean =>
  roleAssignments.organization?.some((organization) => organization.role_id === role) ?? false

const diffOrganizationRoleAssignments = (
  original: OrganizationRoleAssignment[] = [],
  changed: OrganizationRoleAssignment[] = [],
): {
  added: OrganizationRoleAssignment[]
  removed: OrganizationRoleAssignment[]
} => ({
  added: differenceWith(changed, original, isEqual),
  removed: differenceWith(original, changed, isEqual),
})

const diffAllDeploymentsRoleAssignments = (
  original: DeploymentRoleAssignment[] = [],
  changed: DeploymentRoleAssignment[] = [],
): {
  added: OrganizationRoleAssignment[]
  removed: OrganizationRoleAssignment[]
} => {
  const originalAllDeploymentsRoleAssignments: DeploymentRoleAssignment[] =
    filterDeploymentRoleAssignmentByAll(original, true)

  const changedAllDeploymentsRoleAssignments: DeploymentRoleAssignment[] =
    filterDeploymentRoleAssignmentByAll(changed, true)

  return {
    added: differenceWith(
      changedAllDeploymentsRoleAssignments,
      originalAllDeploymentsRoleAssignments,
      isEqual,
    ),
    removed: differenceWith(
      originalAllDeploymentsRoleAssignments,
      changedAllDeploymentsRoleAssignments,
      isEqual,
    ),
  }
}

const diffSpecificDeploymentsRoleAssignments = (
  original: DeploymentRoleAssignment[] = [],
  changed: DeploymentRoleAssignment[] = [],
): {
  added: DeploymentRoleAssignment[]
  removed: DeploymentRoleAssignment[]
} => {
  const originalSpecificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    original,
    false,
  )

  const originalDeploymentIdsByRoleId = getDeploymentIdsByRoleId(
    originalSpecificDeploymentsRoleAssignments,
  )

  const changedSpecificDeploymentsRoleAssignments = filterDeploymentRoleAssignmentByAll(
    changed,
    false,
  )

  const changedDeploymentIdsByRoleId = getDeploymentIdsByRoleId(
    changedSpecificDeploymentsRoleAssignments,
  )

  const added = diffDeploymentIds(
    changedSpecificDeploymentsRoleAssignments,
    originalDeploymentIdsByRoleId,
  ).filter(({ deployment_ids = [] }) => deployment_ids.length > 0)

  const removed = diffDeploymentIds(
    originalSpecificDeploymentsRoleAssignments,
    changedDeploymentIdsByRoleId,
  ).filter(({ deployment_ids = [] }) => deployment_ids.length > 0)

  return {
    added,
    removed,
  }

  function getDeploymentIdsByRoleId(roleAssignments: DeploymentRoleAssignment[]): {
    [roleId: string]: string[]
  } {
    return roleAssignments.reduce(
      (prev, { role_id: roleId, deployment_ids: deploymentIds = [] }) => ({
        ...prev,
        [roleId]: (prev[roleId] ?? []).concat(deploymentIds),
      }),
      {},
    )
  }

  function diffDeploymentIds(
    roleAssignments: DeploymentRoleAssignment[],
    deploymentIdsByRoleId: { [roleId: string]: string[] },
  ): DeploymentRoleAssignment[] {
    return roleAssignments.map((roleAssignment) => ({
      ...roleAssignment,
      deployment_ids: difference(
        roleAssignment.deployment_ids,
        deploymentIdsByRoleId[roleAssignment.role_id] ?? [],
      ),
    }))
  }
}

export type RoleAssignmentsDiff = {
  added: RoleAssignments
  removed: RoleAssignments
}

export const diffRoleAssignments = (
  original: RoleAssignments,
  changed: RoleAssignments,
): RoleAssignmentsDiff => {
  const organizationRoleAssignmentsDiff = diffOrganizationRoleAssignments(
    original.organization,
    changed.organization,
  )

  const allDeploymentsRoleAssignmentsDiff = diffAllDeploymentsRoleAssignments(
    original.deployment,
    changed.deployment,
  )

  const specificDeploymentsRoleAssignmentsDiff = diffSpecificDeploymentsRoleAssignments(
    original.deployment,
    changed.deployment,
  )

  const projectRoleAssignmentsDiff = diffProjectRoleAssignments(original.project, changed.project)

  return {
    added: {
      organization: organizationRoleAssignmentsDiff.added,
      deployment: allDeploymentsRoleAssignmentsDiff.added.concat(
        specificDeploymentsRoleAssignmentsDiff.added,
      ),
      project: projectRoleAssignmentsDiff.added,
    },
    removed: {
      organization: organizationRoleAssignmentsDiff.removed,
      deployment: allDeploymentsRoleAssignmentsDiff.removed.concat(
        specificDeploymentsRoleAssignmentsDiff.removed,
      ),
      project: projectRoleAssignmentsDiff.removed,
    },
  }
}

const deploymentRolesOrder = {
  ['deployment-admin']: 1,
  ['deployment-editor']: 2,
  ['deployment-viewer']: 3,
}

const sortDeploymentRoleAssignments = (
  deploymentRoleAssignment1: DeploymentRoleAssignment,
  deploymentRoleAssignment2: DeploymentRoleAssignment,
): number =>
  deploymentRolesOrder[deploymentRoleAssignment1.role_id] -
  deploymentRolesOrder[deploymentRoleAssignment2.role_id]
