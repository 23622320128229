/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiTextColor, EuiTitle } from '@elastic/eui'

import { ResourceType } from '../../discovery-questions-lib/utils'
import { getPrettyProjectType } from '../../project-lib/display'

import type { ProjectType } from '../../ui-types/projects'
import type { FunctionComponent } from 'react'

type RoleBadgeIcon = 'logoElasticsearch' | 'logoObservability' | 'logoSecurity' | 'logoElasticStack'

const PROJECT_TYPE_ICON = new Map<ProjectType, RoleBadgeIcon>([
  ['elasticsearch', 'logoElasticsearch'],
  ['observability', 'logoObservability'],
  ['security', 'logoSecurity'],
])

function getProjectTypeIcon(projectType?: ProjectType): RoleBadgeIcon {
  return (projectType && PROJECT_TYPE_ICON.get(projectType)) ?? 'logoElasticsearch'
}

interface HeaderOnboardingProps {
  hide: boolean
  resourceType: ResourceType
  projectType?: ProjectType
}

// TODO implement lear more for hosted and serverless + icon of the solution

const HeaderOnboarding: FunctionComponent<HeaderOnboardingProps> = ({
  hide,
  resourceType,
  projectType,
}) => {
  if (hide) {
    return null
  }

  const projectNode = projectType ? (
    <EuiFlexItem>
      <EuiTitle size='xs'>
        <h4>
          <EuiTextColor color='default'>
            <EuiFlexGroup direction='row' gutterSize='xs' alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiIcon
                  data-test-id='onboarding-flow-project-icon'
                  type={getProjectTypeIcon(projectType)}
                  size='m'
                />
              </EuiFlexItem>
              {projectType !== 'elasticsearch' && (
                <EuiFlexItem grow={false}>
                  <FormattedMessage
                    id={`onboarding-${resourceType}-flow-project.title`}
                    defaultMessage='Elastic for'
                  />
                </EuiFlexItem>
              )}

              <EuiFlexItem grow={false}>
                <FormattedMessage {...getPrettyProjectType(projectType)} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiTextColor>
        </h4>
      </EuiTitle>
      <EuiSpacer size='m' />
    </EuiFlexItem>
  ) : null

  if (resourceType === ResourceType.Serverless) {
    return (
      <EuiFlexGroup direction='column' gutterSize='xs'>
        {projectNode}
        <EuiFlexItem>
          <p>
            <strong>
              <EuiTextColor color='default'>
                <FormattedMessage
                  id='onboarding-serverless-flow.title'
                  defaultMessage='Elastic Cloud Serverless'
                />
              </EuiTextColor>
            </strong>{' '}
            <EuiTextColor color='subdued'>
              <FormattedMessage
                id='onboarding-serverless-flow.subtitle'
                defaultMessage='is our fully managed, autoscaling infrastructure for the easiest way to run your workloads.'
              />
            </EuiTextColor>
          </p>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  // resourceType === ResourceType.Stateful
  return (
    <EuiFlexGroup direction='column' gutterSize='xs'>
      {projectNode}
      <EuiFlexItem>
        <p>
          <strong>
            <EuiTextColor color='default'>
              <FormattedMessage
                id='onboarding-hosted-flow.title'
                defaultMessage='Elastic Cloud Hosted'
              />
            </EuiTextColor>
          </strong>{' '}
          <EuiTextColor color='subdued'>
            <FormattedMessage
              id='onboarding-hosted-flow.subtitle'
              defaultMessage='is for experts looking for full control: hosted requires you to manage clusters, upgrades, backup, retention, and scaling.'
            />
          </EuiTextColor>
        </p>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default HeaderOnboarding
