/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import type { MiddlewareComponent } from '@modules/app/types'

// eslint-disable-next-line import/no-restricted-paths
import { _injectProfile } from '@/apps/userconsole/actions/profile'

import ProfileContext from './ProfileContext'
import { useGetUserProfileQuery } from './hooks'

const ProfileProvider: MiddlewareComponent = ({ children }) => {
  const { data: profile, isLoading, isError } = useGetUserProfileQuery()

  const dispatch = useDispatch()

  useEffect(() => {
    if (profile?.user) {
      dispatch(_injectProfile(profile.user))
    }
  }, [dispatch, profile])

  const userData = {
    isReady: !isLoading && !isError,
    isError,
    user: profile?.user,
  }

  return <ProfileContext.Provider value={userData}>{children}</ProfileContext.Provider>
}

ProfileProvider.middleware = {
  displayName: 'ProfileProvider',
  dependencies: [],
}

export default ProfileProvider
