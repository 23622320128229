/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { xor } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiContextMenuItem, EuiIcon, EuiLoadingSpinner } from '@elastic/eui'

import type { DeploymentsSearchResponse } from '@modules/cloud-api/v1/types'
import type { StackDeployment, AsyncRequestState, ProfileState } from '@modules/ui-types'
import type { FeedbackType } from '@modules/ui-types/custom'
import { addToast } from '@modules/cui/Toasts'
import { parseError } from '@modules/cui/Alert'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { hasOngoingConfigurationChange } from '@/lib/stackDeployments/selectors/configurationChanges'
import { isAzurePlusUser, isIntegratedAzurePlusDeployment } from '@/lib/marketPlace'

import { getConfigForKey } from '../../../../../store'
import DeleteDeploymentModal from '../DeleteDeployment/DeleteDeploymentModal'

import FeedbackModal from './FeedbackModal'

import type { WrappedComponentProps } from 'react-intl'

export type StateProps = {
  shutdownStackDeploymentRequest: AsyncRequestState
  searchResults?: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
  profile: NonNullable<ProfileState>
}

export type DispatchProps = {
  fetchDeployment: () => void
  stopAndHideDeployment: () => void
  resetShutdownStackDeployment: () => void
  fetchDeployments: () => void
  submitUserFeedback: (options: {
    deployment: StackDeployment
    type: string
    reasons: FeedbackType[]
    feedback: string
  }) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
  onDelete?: () => void
}

type Props = StateProps & DispatchProps & ConsumerProps & WrappedComponentProps

type State = {
  reasons: FeedbackType[]
  feedback: string
  deleteValue: string
  isConfirmModalOpen: boolean
  isFeedbackModalOpen: boolean
  requestChainProgress: boolean
  isInvalid: boolean
}

class HideDeploymentInUserconsole extends Component<Props, State> {
  mounted: boolean = false

  state: State = {
    reasons: [],
    feedback: ``,
    deleteValue: ``,
    isConfirmModalOpen: false,
    isFeedbackModalOpen: false,
    requestChainProgress: false,
    isInvalid: false,
  }

  componentDidMount() {
    const { fetchDeployments } = this.props
    const mightAskForFeedback = this.couldInvolveFeedback()

    if (mightAskForFeedback) {
      fetchDeployments()
    }

    this.mounted = true
  }

  componentDidUpdate(prevProps: Props) {
    const { deployment, resetShutdownStackDeployment } = this.props
    const wasPending = hasOngoingConfigurationChange({ deployment: prevProps.deployment })
    const isPending = hasOngoingConfigurationChange({ deployment })

    // Whenever we change from not pending to pending we need to make sure the restart button
    // is no longer in the 'successfully saved deployment' state.
    if (!wasPending && isPending) {
      resetShutdownStackDeployment()
    }
  }

  componentWillUnmount() {
    const { resetShutdownStackDeployment } = this.props
    resetShutdownStackDeployment()
    this.mounted = false
  }

  render() {
    const { searchResultsRequest, shutdownStackDeploymentRequest, profile, deployment } = this.props
    const { requestChainProgress } = this.state
    const isBusy =
      requestChainProgress ||
      shutdownStackDeploymentRequest.inProgress ||
      searchResultsRequest.inProgress

    const azurePlusUser = isAzurePlusUser(profile)

    const azurePlusDeployment = isIntegratedAzurePlusDeployment(deployment)
    const deleteOnAzurePlus = azurePlusUser && azurePlusDeployment

    return (
      <Fragment>
        <PermissionsGate
          permissions={[
            {
              type: 'deployment',
              action: 'shutdown',
              id: deployment.id,
            },
          ]}
        >
          {({ hasPermissions }) => (
            <EuiContextMenuItem
              className='actionsDropdown-delete-deployment'
              data-test-id='deploymentDelete-Btn'
              icon={isBusy ? <EuiLoadingSpinner size='m' /> : <EuiIcon type='trash' />}
              disabled={isBusy || deleteOnAzurePlus || !hasPermissions}
              onClick={this.openConfirmModal}
              toolTipContent={
                deleteOnAzurePlus && (
                  <FormattedMessage
                    id='deployment-shut-down-and-hide-deployment.shut-down-deployment-azure'
                    defaultMessage='Go to the Azure console to delete this deployment.'
                  />
                )
              }
              toolTipProps={{ position: 'bottom' }}
            >
              <FormattedMessage
                id='deployment-shut-down-and-hide-deployment.shut-down-deployment'
                defaultMessage='Delete deployment'
              />
            </EuiContextMenuItem>
          )}
        </PermissionsGate>

        {this.renderConfirmModal()}
        {this.renderFeedbackModal()}
      </Fragment>
    )
  }

  renderConfirmModal() {
    const { searchResultsRequest, deployment } = this.props
    const { isConfirmModalOpen } = this.state

    if (!isConfirmModalOpen) {
      return null
    }

    if (searchResultsRequest.inProgress) {
      return null
    }

    return (
      <DeleteDeploymentModal
        deployment={deployment}
        closeConfirmModal={this.closeConfirmModal}
        onDeleteButtonConfirm={() =>
          this.shouldGetFeedback() ? this.openFeedbackModal() : this.stop()
        }
      />
    )
  }

  renderFeedbackModal() {
    const { searchResultsRequest } = this.props
    const { isFeedbackModalOpen, reasons } = this.state

    if (!isFeedbackModalOpen) {
      return null
    }

    const shouldAskForFeedback = this.shouldGetFeedback()

    if (!shouldAskForFeedback) {
      return
    }

    if (searchResultsRequest.inProgress) {
      return null
    }

    const { feedback, isInvalid } = this.state

    return (
      <FeedbackModal
        selectedReasons={reasons}
        feedback={feedback}
        onCheckboxChange={(id) => this.onCheckboxChange(id)}
        isInvalid={isInvalid}
        stop={() => this.stop()}
        stopAndHide={() => this.stopAndHide()}
        onChangeFeedback={(e) => this.setState({ feedback: e.target.value })}
        closeFeedbackModal={() => this.closeFeedbackModal()}
      />
    )
  }

  onCheckboxChange(reason) {
    this.setState({
      // add or remove as appropriate
      reasons: xor(this.state.reasons, [reason]),
    })
  }

  closeConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false })
  }

  closeFeedbackModal = () => {
    this.setState({
      isFeedbackModalOpen: false,
      isInvalid: false,
      reasons: [],
    })
  }

  openConfirmModal = () => {
    this.setState({ isConfirmModalOpen: true })
  }

  openFeedbackModal = () => {
    this.closeConfirmModal()
    this.setState({ isFeedbackModalOpen: true })
  }

  shouldGetFeedback(): boolean {
    const { searchResults } = this.props
    const mightAskForFeedback = this.couldInvolveFeedback()

    if (!mightAskForFeedback) {
      return false
    }

    if (!searchResults) {
      return false
    }

    return searchResults.match_count === 1
  }

  // only affects non-trial SaaS userconsole users terminating their last deployment
  couldInvolveFeedback() {
    const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

    if (!isUserConsole) {
      return false
    }

    const { profile } = this.props
    const { email, inTrial } = profile

    if (email.endsWith(`@elastic.co`)) {
      return false
    }

    return !inTrial
  }

  stop = () => {
    const { deployment, submitUserFeedback } = this.props
    const { feedback, reasons } = this.state

    // If `other` has been checked but no feedback given, show error
    if (this.isFeedbackInvalid()) {
      this.setState({ isInvalid: true })
      return
    }

    this.stopAndHide()

    if (reasons.length !== 0) {
      submitUserFeedback({
        type: `deployment_shutdown`,
        deployment,
        reasons,
        feedback,
      })
    }
  }

  isFeedbackInvalid() {
    const { feedback, reasons } = this.state

    if (reasons.find((reason) => reason === `other`) && feedback.trim().length === 0) {
      return true
    }

    return false
  }

  stopAndHide = () => {
    const { stopAndHideDeployment, fetchDeployment, onDelete } = this.props

    if (onDelete) {
      onDelete()
    }

    Promise.resolve()
      .then(() => this.setState({ requestChainProgress: true, isConfirmModalOpen: false }))
      .then(() => stopAndHideDeployment())
      .then(() => {
        if (!onDelete) {
          return fetchDeployment()
        }

        return null
      })
      .then(() => {
        if (this.mounted) {
          return this.setState({ requestChainProgress: false })
        }

        return null
      })
      .catch((error) => {
        if (this.mounted) {
          addToast({
            family: `toast-error`,
            color: `danger`,
            iconType: `broom`,
            title: (
              <FormattedMessage
                id='toasts.title-deleting-failed'
                defaultMessage='Deleting your deployment failed'
              />
            ),
            text: parseError(error),
          })
          return this.setState({ requestChainProgress: false })
        }

        return null
      })
  }
}

export default injectIntl(HideDeploymentInUserconsole)
