/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchClusterPlan, DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type {
  ElasticsearchCluster,
  ElasticsearchId,
  RegionId,
  VersionNumber,
} from '@modules/ui-types'

import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import {
  FETCH_STACK_DEPLOYMENT,
  SET_DISK_QUOTA,
  SET_INSTANCE_CAPACITY,
  SET_INSTANCE_STATUS,
  SET_MAINTENANCE_MODE,
} from '@/constants/actions'

import createCluster from './createCluster'
import {
  updateClusterInstancesMaintenanceMode,
  updateClusterInstancesStatus,
} from './updateClusterInstance'
import setDiskQuota from './setDiskQuota'
import setInstanceCapacity from './setInstanceCapacity'

import type { ReduxState } from '@/types/redux'
import type { FetchDeploymentAction } from '@/actions/stackDeployments/types'
import type { ClusterAction } from './clusterTypes'

export type State = { [descriptor: string]: ElasticsearchCluster }

type Action = ClusterAction | FetchDeploymentAction

function clusterReducer(
  cluster: ElasticsearchCluster | undefined,
  action: Action,
): ElasticsearchCluster | undefined {
  if (action.type === FETCH_STACK_DEPLOYMENT) {
    const stackDeployment = action.payload as DeploymentGetResponse
    const esCluster = stackDeployment.resources.elasticsearch[0]

    if (!esCluster) {
      throw new Error('No elasticsearch resources in deployment')
    }

    const { id, info } = esCluster
    const regionId = getRegion(action)
    const selfUrl = getSelfUrl(regionId, id)

    return createCluster({
      regionId,
      clusterId: id,
      selfUrl,
      source: info,
      oldCluster: cluster,
      stackDeployment,
    })
  }

  if (!cluster) {
    return undefined
  }

  if (action.type === SET_MAINTENANCE_MODE) {
    return updateClusterInstancesMaintenanceMode(
      cluster,
      action.meta.instanceIds,
      action.meta.action,
    )
  }

  if (action.type === SET_INSTANCE_STATUS) {
    return updateClusterInstancesStatus(cluster, action.meta.instanceIds, action.meta.action)
  }

  if (action.type === SET_DISK_QUOTA) {
    return setDiskQuota(cluster, action)
  }

  if (action.type === SET_INSTANCE_CAPACITY) {
    return setInstanceCapacity(cluster, action)
  }

  return cluster
}

function createDescriptor(regionId: string, clusterId: string) {
  return `${regionId}/${clusterId}`
}

export default function clustersReducer(clusters: State = {}, action: Action): State {
  switch (action.type) {
    case FETCH_STACK_DEPLOYMENT:
    case SET_DISK_QUOTA:
    case SET_INSTANCE_CAPACITY:
    case SET_INSTANCE_STATUS:
    case SET_MAINTENANCE_MODE:
      if (action.error || !action.payload) {
        return clusters
      }

      const descriptor = createDescriptor(getRegion(action), getClusterId(action))

      const newCluster = clusterReducer(clusters[descriptor], action)

      if (newCluster) {
        return {
          ...clusters,
          [descriptor]: newCluster,
        }
      }

      return clusters

    default:
      return clusters
  }
}

function getClusterId(action: Action) {
  const { clusterId } = action.meta as any

  if (clusterId) {
    return clusterId
  }

  // assume Stack deployments API action
  const stackDeploymentsAction = action as FetchDeploymentAction
  return stackDeploymentsAction.payload?.resources.elasticsearch[0]?.id
}

function getRegion(action: Action): string {
  const { regionId } = action.meta

  if (regionId) {
    return regionId
  }

  // assume Stack deployments API action
  const stackDeploymentsAction = action as FetchDeploymentAction
  return getRegionId({ deployment: stackDeploymentsAction.payload as DeploymentGetResponse })
}

function _getCluster(
  state: State,
  regionId: RegionId,
  clusterId: ElasticsearchId,
): ElasticsearchCluster | undefined {
  return state[createDescriptor(regionId, clusterId)]
}

/**
 * Fetches the version number from the supplied cluster
 *
 * @param {Object} cluster the cluster to interrogate
 * @return {Number} the version number
 */
export function getVersion(cluster: { plan: ElasticsearchClusterPlan | null }): VersionNumber {
  return cluster.plan?.elasticsearch.version as VersionNumber
}

function getSelfUrl(regionId: string, id: string): string {
  return `/api/v1/regions/${regionId}/clusters/elasticsearch/${id}`
}

export const getCluster = (state: ReduxState, regionId: RegionId, clusterId: ElasticsearchId) =>
  _getCluster(state.clusters, regionId, clusterId)
