/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx } from '@emotion/react'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  EuiSpacer,
  EuiPanel,
  EuiTitle,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
} from '@elastic/eui'

import { outlineButtonStyle } from '@modules/access-management-components/styles'

import {
  awsMarketplaceSignUpUrl,
  azureMarketplaceSignUpUrl,
  gcpMarketplaceSignUpUrl,
} from '@/apps/userconsole/urls'
import { setCookie } from '@/lib/cookies'

import gcpLogo from '../../files/GCP.svg'
import azureLogo from '../../files/Azure.svg'
import awsLogo from '../../files/AWS.svg'

import type { FunctionComponent, ReactNode } from 'react'
import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'

interface MarketplaceSignUpButton {
  provider: 'aws' | 'gcp' | 'azure'
  icon: string
  text: React.ReactNode
  href: string
  dataTrackId: string
}

type Props = LDProps & { isFlowV2?: boolean }

const MarketplaceSignUp: FunctionComponent<Props> = ({ ldClient, isFlowV2 }) => {
  const marketplaceSignUpButtons: MarketplaceSignUpButton[] = [
    {
      provider: 'aws',
      icon: awsLogo,
      text: (
        <FormattedMessage id='marketplace-signup.aws-btn' defaultMessage='Amazon Web Services' />
      ),
      href: awsMarketplaceSignUpUrl,
      dataTrackId: `mkp-signup-aws-button-v${isFlowV2 ? '2' : '1'}`,
    },
    {
      provider: 'gcp',
      icon: gcpLogo,
      text: <FormattedMessage id='marketplace-signup.gcp-btn' defaultMessage='Google Cloud' />,
      href: gcpMarketplaceSignUpUrl,
      dataTrackId: `mkp-signup-gcp-button-v${isFlowV2 ? '2' : '1'}`,
    },
    {
      provider: 'azure',
      icon: azureLogo,
      text: <FormattedMessage id='marketplace-signup.azure-btn' defaultMessage='Microsoft Azure' />,
      href: azureMarketplaceSignUpUrl,
      dataTrackId: `mkp-signup-azure-button-v${isFlowV2 ? '2' : '1'}`,
    },
  ]

  const handleMarketplaceButtonClick = (provider: MarketplaceSignUpButton['provider']) => {
    if (ldClient) {
      ldClient.track('Marketplace registration', { provider }, 0.3)
      ldClient.flush()
    } else {
      setCookie('ec_marketplace_referrer', provider)
    }
  }

  if (isFlowV2) {
    return (
      <MarketplaceSignUpTemplateV2
        subtitle={
          <FormattedMessage
            id='marketplace-signup.description'
            defaultMessage='Subscribe via a cloud marketplace for simplified billing'
          />
        }
        marketplaceSignUpButtons={marketplaceSignUpButtons}
        handleMarketplaceButtonClick={handleMarketplaceButtonClick}
      />
    )
  }

  return (
    <MarketplaceSignUpTemplateV1
      title={
        <FormattedMessage
          id='marketplace-signup.title'
          defaultMessage='Subscribe via your cloud marketplace'
        />
      }
      subtitle={
        <FormattedMessage
          id='marketplace-signup.subtitle'
          defaultMessage='Consolidate billing and utilize existing commitments'
        />
      }
      marketplaceSignUpButtons={marketplaceSignUpButtons}
      handleMarketplaceButtonClick={handleMarketplaceButtonClick}
    />
  )
}

interface TemplateProps {
  title?: ReactNode
  subtitle: ReactNode
  marketplaceSignUpButtons: MarketplaceSignUpButton[]
  handleMarketplaceButtonClick
}

const MarketplaceSignUpTemplateV1: FunctionComponent<TemplateProps> = ({
  title,
  subtitle,
  marketplaceSignUpButtons,
  handleMarketplaceButtonClick,
}) => (
  <EuiPanel paddingSize='l'>
    <EuiFlexGroup direction='column' alignItems='stretch' gutterSize='s'>
      <EuiFlexItem>
        <EuiTitle size='s'>
          <h3 data-test-id='cloud-signup-page-marketplace-title' style={{ textAlign: 'center' }}>
            {title}
          </h3>
        </EuiTitle>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiText size='s' textAlign='center' color='subdued'>
          {subtitle}
        </EuiText>
      </EuiFlexItem>

      {marketplaceSignUpButtons.map((button) => (
        <Fragment key={button.provider}>
          <EuiSpacer size='m' />

          <EuiFlexItem onClick={() => handleMarketplaceButtonClick(button.provider)}>
            <EuiButton
              color='primary'
              iconType={button.icon}
              href={button.href}
              rel='noopener noreferrer'
              data-track-id={button.dataTrackId}
            >
              {button.text}
            </EuiButton>
          </EuiFlexItem>
        </Fragment>
      ))}
    </EuiFlexGroup>
  </EuiPanel>
)

const MarketplaceSignUpTemplateV2: FunctionComponent<TemplateProps> = ({
  subtitle,
  marketplaceSignUpButtons,
  handleMarketplaceButtonClick,
}) => (
  <EuiFlexGroup direction='column' alignItems='stretch' gutterSize='m'>
    <EuiFlexItem>
      <EuiText textAlign='center' color='subdued'>
        {subtitle}
      </EuiText>
    </EuiFlexItem>

    <EuiFlexGroup alignItems='stretch' gutterSize='s'>
      {marketplaceSignUpButtons.map((button) => (
        <Fragment key={button.provider}>
          <EuiFlexItem onClick={() => handleMarketplaceButtonClick(button.provider)}>
            <EuiButton
              iconType={button.icon}
              href={button.href}
              rel='noopener noreferrer'
              css={outlineButtonStyle}
              data-track-id={button.dataTrackId}
            >
              {button.text}
            </EuiButton>
          </EuiFlexItem>
        </Fragment>
      ))}

      <EuiSpacer size='s' />
    </EuiFlexGroup>
  </EuiFlexGroup>
)

export default withLDConsumer()(MarketplaceSignUp)
