/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiCallOut, EuiIcon, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import { isStopped } from '@/lib/stackDeployments/selectors/configurationChanges'
import {
  getVersion,
  getFirstSliderClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  shouldUpgradeAssistantBeUsed,
  isUpgradeAssistantUsingKibana,
  isUpgradeAssistantSupported,
  isMajorVersionChange,
} from '@/lib/stackDeployments/upgrades'
import { isSliderEnabledInStackDeployment } from '@/lib/sliders/stackDeployments'

import ExternalLink from '../../ExternalLink'
import DocLink from '../../DocLink'
import { kibanaUpgradeAssistantUrl } from '../../../lib/serviceProviderDeepLinks'
import { lt } from '../../../lib/semver'
import { deploymentEditSliderUrl } from '../../../lib/urlBuilder'

import type { Props, State } from './types'

const docLink = (
  <DocLink link='upgradeAssistantDocLink'>
    <FormattedMessage id='upgrade-assistant.learnMore' defaultMessage='Learn more' />
  </DocLink>
)

class UpgradeAssistant extends Component<Props, State> {
  componentDidMount(): void {
    this.props.fetchUpgradeAssistantStatus()
  }

  render(): JSX.Element | null {
    const callout = this.renderCallout()

    if (!callout) {
      return null
    }

    return (
      <div data-test-id='upgrade-assistant'>
        <EuiSpacer size='m' />
        {callout}
      </div>
    )
  }

  renderUnavailableAssistantCallout(): JSX.Element | null {
    const { deployment, pendingVersion, upgradeAssistantStatusRequest, hasPermission } = this.props

    const version = getVersion({ deployment })

    if (
      !shouldUpgradeAssistantBeUsed(version, pendingVersion) ||
      !isUpgradeAssistantSupported(version)
    ) {
      return null
    }

    if (!hasPermission) {
      return (
        <EuiCallOut
          color='warning'
          title={
            <FormattedMessage
              id='upgrade-assistant.no-permission.title'
              defaultMessage='Upgrade Assistant unavailable'
            />
          }
        >
          <FormattedMessage
            id='upgrade-assistant.no-permission.body'
            defaultMessage="You don't have the required permissions to access the upgrade assistant. Contact your system administrator. {docLink}"
            values={{
              docLink,
            }}
          />
        </EuiCallOut>
      )
    }

    if (isUpgradeAssistantUsingKibana(version)) {
      const noKibana = (
        <EuiCallOut
          color='warning'
          title={
            <span data-test-id='upgrade-assistant-without-kibana'>
              <FormattedMessage
                id='upgrade-assistant.enable-kibana.title'
                defaultMessage='Upgrade Assistant unavailable'
              />
            </span>
          }
        >
          <FormattedMessage
            id='upgrade-assistant.enable-kibana.body'
            defaultMessage='<link>Enable Kibana</link> to migrate your indices to a compatible format with the Upgrade Assistant. {docLink}'
            values={{
              link: (content: string[]) => (
                <Link to={deploymentEditSliderUrl(deployment.id, `kibana`)}>{content}</Link>
              ),
              docLink,
            }}
          />
        </EuiCallOut>
      )

      const hasKibana = isSliderEnabledInStackDeployment(deployment, `kibana`)
      const kibana = getFirstSliderClusterFromGet({
        deployment,
        sliderInstanceType: `kibana`,
      })

      if (!hasKibana || !kibana) {
        return noKibana
      }

      const isKibanaStopped = isStopped({ resource: kibana })

      if (isKibanaStopped) {
        return noKibana
      }
    }

    if (upgradeAssistantStatusRequest.error) {
      return (
        <EuiCallOut
          color='danger'
          title={
            <span data-test-id='upgrade-assistant-unavailable'>
              <FormattedMessage
                id='upgrade-assistant.request-failed.title'
                defaultMessage='Something went wrong'
              />
            </span>
          }
        >
          <FormattedMessage
            id='upgrade-assistant.request-failed.body'
            defaultMessage='Failed to fetch upgrade assistant status'
          />
        </EuiCallOut>
      )
    }

    return null
  }

  renderCallout(): JSX.Element | null {
    const { upgradeAssistantStatus, deployment, pendingVersion } = this.props

    if (!upgradeAssistantStatus) {
      return this.renderUnavailableAssistantCallout()
    }

    const { ready_for_upgrade: readyForUpgrade, details } = upgradeAssistantStatus

    const version = getVersion({ deployment })
    const kibana = getFirstSliderClusterFromGet({
      deployment,
      sliderInstanceType: `kibana`,
    })

    if (!shouldUpgradeAssistantBeUsed(version, pendingVersion)) {
      return null
    }

    if (!readyForUpgrade) {
      return (
        <EuiCallOut
          color='danger'
          title={
            <EuiFlexGroup
              gutterSize='s'
              alignItems='center'
              data-test-id='cannot-upgrade'
              component='span'
            >
              <EuiFlexItem grow={false} component='span'>
                <EuiIcon type='alert' />
              </EuiFlexItem>
              <EuiFlexItem grow={false} component='span'>
                {version === pendingVersion ? (
                  <FormattedMessage
                    id='upgrade-assistant.upgrade-disabled.title'
                    defaultMessage='Fix the following issues before upgrading'
                    values={{ pendingVersion }}
                  />
                ) : (
                  <FormattedMessage
                    id='upgrade-assistant.upgrade-disabled.title-secondary'
                    defaultMessage='Fix the following issues before upgrading to {pendingVersion}'
                    values={{ pendingVersion }}
                  />
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        >
          <FormattedMessage
            id='upgrade-assistant.upgrade-disabled.body'
            defaultMessage='{details} {moreDetailsPrompt}'
            values={{
              details,
              moreDetailsPrompt: isUpgradeAssistantUsingKibana(version) ? (
                <FormattedMessage
                  id='upgrade-assistant.upgrade-disabled.details'
                  defaultMessage='For more details, check the <link>{pendingVersion} Upgrade Assistant</link>.'
                  values={{
                    link: (content: string[]) => (
                      <ExternalLink href={kibanaUpgradeAssistantUrl({ resource: kibana })}>
                        {content}
                      </ExternalLink>
                    ),
                    pendingVersion,
                  }}
                />
              ) : null,
            }}
          />
        </EuiCallOut>
      )
    }

    if (!isMajorVersionChange(version, pendingVersion)) {
      return null
    }

    return (
      <EuiCallOut
        color='primary'
        title={
          <EuiFlexGroup
            direction='column'
            gutterSize='s'
            data-test-id='can-upgrade'
            component='span'
          >
            <EuiFlexItem grow={false} component='span'>
              <FormattedMessage
                data-test-id='major-version-upgrade'
                id='upgrade-assistant.major-upgrade-notice'
                defaultMessage='You will be performing a major version upgrade.'
              />
            </EuiFlexItem>
            {lt(pendingVersion, `7.0.0`) && (
              <EuiFlexItem grow={false} component='span'>
                <FormattedMessage
                  id='upgrade-assistant.major-upgrade-restart'
                  defaultMessage='The deployment performs a full restart during the upgrade process.'
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        }
      >
        <FormattedMessage
          id='upgrade-assistant.recommend-upgrade-guide'
          defaultMessage='We highly recommend <link>testing the upgrade process with a cloned cluster</link> before upgrading a production cluster.'
          values={{
            link: (content: string[]) => (
              <DocLink link='crossClusterRestoreDocLink'>{content}</DocLink>
            ),
          }}
        />
      </EuiCallOut>
    )
  }
}

export default UpgradeAssistant
