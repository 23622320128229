/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalBody,
  EuiModalHeader,
  EuiText,
  EuiSpacer,
  EuiPanel,
  EuiImage,
  EuiHorizontalRule,
  useEuiTheme,
} from '@elastic/eui'

import type { UserProfile, StackDeployment } from '@modules/ui-types'
import { isTrialNotStartedUser } from '@modules/billing-lib/utils'

import integrationsIllustration from '@/files/kibana-integrations-lightmode.svg'
import integrationsIllustrationDark from '@/files/kibana-integrations-darkmode.svg'

import RemainingTrial from './RemainingTrial'
import SubscribeButton from './SubscribeButton'
import HelperLinks from './HelperLinks'
import ExtendTrialSection from './ExtendTrialSection'

import type { FC } from 'react'

export interface Props {
  closeModal: () => void
  profile: UserProfile
  deployment: StackDeployment | undefined
}

const TrialSummaryWithoutDeployment: FC<Props> = ({ deployment, closeModal, profile }) => {
  const { colorMode } = useEuiTheme()

  const { formatMessage } = useIntl()

  const { hasExpiredTrial, trialDaysRemaining } = profile

  return (
    <Fragment>
      <EuiModalHeader>
        <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='none'>
          <EuiFlexItem grow={false}>
            <EuiText
              size='s'
              data-test-id='trial-summary-modal.title'
              data-test-subj='trial-summary-without-deployment-or-template'
            >
              <h3>
                <ModalTitle hasExpiredTrial={hasExpiredTrial} />
              </h3>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <RemainingTrial trialDaysRemaining={trialDaysRemaining} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalHeader>

      <EuiSpacer size='s' />

      <EuiModalBody>
        <EuiFlexGroup gutterSize='xl'>
          <EuiFlexItem grow={7}>
            <HelperLinks
              deploymentId={deployment?.id}
              trialStarted={!isTrialNotStartedUser(profile)}
              trialExpired={hasExpiredTrial}
            />

            {hasExpiredTrial && (
              <Fragment>
                <EuiHorizontalRule margin='m' />

                <ExtendTrialSection profile={profile} onTrialExtensionSuccess={closeModal} />
              </Fragment>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={5}>
            <EuiPanel grow={false} css={css({ textAlign: 'center' })}>
              <EuiImage
                src={
                  { DARK: integrationsIllustrationDark, LIGHT: integrationsIllustration }[colorMode]
                }
                width={175}
                alt={formatMessage({
                  id: 'trial-summary-modal.integrations-box.illustration',
                  defaultMessage: 'An illustration of a packed box with integrations.',
                })}
              />

              <EuiSpacer />

              <EuiText size='m'>
                <h4>
                  <FormattedMessage
                    id='trial-summary-modal.no-data.promo-section.title'
                    defaultMessage='Enjoying Elastic?'
                  />
                </h4>
              </EuiText>

              <EuiSpacer size='m' />

              <EuiText size='s'>
                <FormattedMessage
                  id='trial-summary-modal.no-data.promo-section.description'
                  defaultMessage='Subscribe now and access everything that Elastic has to offer.'
                />
              </EuiText>

              <EuiSpacer />

              <SubscribeButton onSendBillingDetailsSuccess={closeModal} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
    </Fragment>
  )
}

const ModalTitle = ({ hasExpiredTrial }: { hasExpiredTrial: boolean }) => {
  if (hasExpiredTrial) {
    return (
      <FormattedMessage
        id='trial-summary-modal.expired-trial-no-data-title'
        defaultMessage='Your trial has expired'
      />
    )
  }

  return (
    <FormattedMessage
      id='trial-summary-modal.active-trial-no-data-title'
      defaultMessage='Free trial'
    />
  )
}

export default TrialSummaryWithoutDeployment
