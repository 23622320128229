/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import cx from 'classnames'
import React, { PureComponent, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiHorizontalRule,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiIconTip,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiDescribedFormGroup,
} from '@elastic/eui'

import type { SaasUserProfile, SaasUserRules, SaasAuthResponse } from '@modules/cloud-api/v1/types'
import type { AccountUI, AsyncRequestState } from '@modules/ui-types'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import { portalUserSettingsCrumbs } from '@/lib/portalCrumbBuilder'
import DeviceAuthentication from '@/components/DeviceAuthentication'
import RolesSummary from '@/components/Organization/OrganizationMembers/OrganizationMemberRoleAssignments/RolesSummary'

import PortalPage from '../PortalPage'
import { messages as portalMessages } from '../../messages'

import UserProfileForm from './UserProfileForm'
import ChangePasswordForm from './ChangePasswordForm'

import type { MfaDevice } from '@/apps/userconsole/reducers/mfa/types'
import type { MfaState, AsyncAction } from '@/types/redux'

import './userAccountSettings.scss'

interface Props {
  accountDetails: SaasUserProfile & SaasUserRules
  ui: AccountUI
  fetchAccountDetailsIfNeeded: () => Promise<any>
  fetchAccountDetailsRequest: AsyncRequestState
  resetUpdateAccountDetailsRequest: () => void
  resetUpdateAccountPasswordRequest: () => void
  updateAccountDetailsRequest: AsyncRequestState
  updateAccountDetails: (accountDetails: SaasUserProfile & SaasUserRules) => any
  mfa: MfaState
  mfaEnabled: boolean | null
  mfaDevices: MfaDevice[] | null
  fetchMfaStatus: () => void
  enableMfa: () => void
  disableMfa: () => void
  fetchMfaDevices: () => void
  fetchMfaDevicesRequest: AsyncRequestState
  disableMfaDeviceRequest: AsyncRequestState
  enableMfaDeviceRequest: AsyncRequestState
  updateAccountPassword: (args: { oldPassword: string; newPassword: string; email: string }) => void
  updateAccountPasswordRequest: AsyncRequestState
  challengeSaasCurrentUserMfaFactor: (args: { device_id: string }) => Promise<any>
  verifySaasCurrentUserMfaFactorRequest: AsyncRequestState
  resetVerifySaasCurrentUserMfaFactorRequest: () => void
  verifySaasCurrentUserMfaFactor: (args: {
    device_id: string
    pass_code: string
  }) => Promise<AsyncAction<'VERIFY_CURRENT_USER_MFA_FACTOR', SaasAuthResponse>>
}

class UserAccountSettings extends PureComponent<Props> {
  componentDidMount() {
    const { fetchAccountDetailsIfNeeded, fetchMfaStatus, fetchMfaDevices } = this.props

    fetchAccountDetailsIfNeeded()
    fetchMfaStatus()
    fetchMfaDevices()
  }

  componentWillUnmount() {
    this.props.resetUpdateAccountDetailsRequest()
    this.props.resetUpdateAccountPasswordRequest()
  }

  render() {
    const isLoading = this.isLoading()

    return (
      <PortalPage
        breadcrumbs={portalUserSettingsCrumbs()}
        className={cx('cloud-portal-user-account-settings', {
          'cloud-portal-page-loading': isLoading,
        })}
        contentHeader={this.renderHeader()}
      >
        {this.renderContent()}
      </PortalPage>
    )
  }

  renderHeader() {
    return (
      <EuiFlexGroup direction='column' gutterSize='s'>
        <EuiFlexItem>
          <EuiTitle size='l'>
            <h2>
              <FormattedMessage {...portalMessages.userSettings} />
            </h2>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup gutterSize='s' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiText size='s' color='subdued' data-test-id='user-settings-profile.user-id'>
                <FormattedMessage
                  id='user-settings-profile.user-id'
                  defaultMessage='User ID: {userID}'
                  values={{ userID: this.props.accountDetails?.user_id }}
                  data-test-id={'user-settings-profile.user-id'}
                />
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiIconTip
                position='left'
                size='m'
                type='questionInCircle'
                color='subdued'
                content={
                  <FormattedMessage
                    id='user-settings-profile.user-id-tooltip'
                    defaultMessage='Your User ID is sometimes used instead of your email address to identify you across Elastic Cloud, for example in deployment audit logs. The User ID is set automatically.'
                  />
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderContent() {
    const {
      accountDetails,
      disableMfa,
      enableMfa,
      disableMfaDeviceRequest,
      enableMfaDeviceRequest,
      mfa,
      mfaDevices,
      mfaEnabled,
      updateAccountDetailsRequest,
      updateAccountPassword,
      updateAccountPasswordRequest,
      resetUpdateAccountPasswordRequest,
      challengeSaasCurrentUserMfaFactor,
      verifySaasCurrentUserMfaFactorRequest,
      verifySaasCurrentUserMfaFactor,
      resetVerifySaasCurrentUserMfaFactorRequest,
      ui,
    } = this.props

    const isLoading = this.isLoading()

    if (!accountDetails && isLoading) {
      return <EuiSkeletonText />
    }

    const { is_profile_editable } = accountDetails

    return (
      <PrivacySensitiveContainer>
        <EuiSpacer size='m' />

        <div className='profile-wrapper'>
          <UserProfileForm
            ui={ui}
            updateAccountDetailsRequest={updateAccountDetailsRequest}
            accountDetails={accountDetails}
            updateAccountDetails={this.updateAccountDetails}
            resetUpdateAccountDetailsRequest={this.props.resetUpdateAccountDetailsRequest}
          />

          <EuiSpacer size='m' />

          <EuiHorizontalRule margin='xs' />

          <EuiSpacer size='m' />

          <ChangePasswordForm
            accountDetails={accountDetails}
            updateAccountPassword={updateAccountPassword}
            updateAccountPasswordRequest={updateAccountPasswordRequest}
            resetUpdateAccountPasswordRequest={resetUpdateAccountPasswordRequest}
          />

          {is_profile_editable && (
            <Fragment>
              <EuiSpacer />
              <EuiHorizontalRule margin='xs' />

              <EuiSpacer size='m' />

              {mfaDevices && (
                <DeviceAuthentication
                  mfa={mfa}
                  mfaDevices={mfaDevices}
                  enableMfa={enableMfa}
                  disableMfa={disableMfa}
                  challengeSaasCurrentUserMfaFactor={challengeSaasCurrentUserMfaFactor}
                  verifySaasCurrentUserMfaFactorRequest={verifySaasCurrentUserMfaFactorRequest}
                  resetVerifySaasCurrentUserMfaFactorRequest={
                    resetVerifySaasCurrentUserMfaFactorRequest
                  }
                  verifySaasCurrentUserMfaFactor={verifySaasCurrentUserMfaFactor}
                  mfaEnabled={mfaEnabled}
                  disableMfaDeviceRequest={disableMfaDeviceRequest}
                  enableMfaDeviceRequest={enableMfaDeviceRequest}
                />
              )}
            </Fragment>
          )}

          <EuiSpacer size='m' />

          {accountDetails.organization_id && accountDetails.role_assignments && (
            <Fragment>
              <EuiHorizontalRule margin='xs' />
              <EuiSpacer size='m' />

              <EuiDescribedFormGroup
                ratio='third'
                title={
                  <h3>
                    <FormattedMessage
                      id='user-settings.current-user.roles'
                      defaultMessage='Roles'
                    />
                  </h3>
                }
              >
                <EuiFlexGroup
                  direction='column'
                  gutterSize='none'
                  justifyContent='flexStart'
                  alignItems='baseline'
                >
                  <EuiFlexItem>
                    <RolesSummary
                      memberOrApiKey='member'
                      organizationId={accountDetails.organization_id}
                      roleAssignments={accountDetails.role_assignments}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiDescribedFormGroup>
            </Fragment>
          )}
        </div>
      </PrivacySensitiveContainer>
    )
  }

  updateAccountDetails = (fieldValues) =>
    this.props.updateAccountDetails({
      ...fieldValues,
    })

  isLoading = (): boolean => {
    const { accountDetails, fetchAccountDetailsRequest } = this.props

    const isLoading = !accountDetails || fetchAccountDetailsRequest.inProgress

    return isLoading
  }
}

export default UserAccountSettings
