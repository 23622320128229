/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { connect } from 'react-redux'
import { withRouter, type RouteComponentProps } from 'react-router'

import { fetchOrganizationMemberships } from '@/actions/organizations'
import {
  fetchOrganizationMembershipsRequest,
  revokeCurrentUserApiKeyRequest,
  revokeUsersApiKeysRequest,
  generateApiKeyRequest,
  fetchAllUsersApiKeysRequest,
  fetchCurrentUserApiKeysRequest,
} from '@/reducers/asyncRequests/registry'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import {
  searchAllOrganizationDeployments,
  getAllOrganizationDeploymentsSearchResults,
} from '@/components/Organization/OrganizationDeployments/lib/'
import { getOrganizationMembers } from '@/reducers/organizations'
import { getAllUsersApiKeys, getCurrentUserApiKeys } from '@/reducers/apiKeys'

import {
  fetchAllUsersApiKeys,
  fetchCurrentUserApiKeys,
  generateApiKey,
  resetRevokeCurrentUserApiKeyRequest,
  resetRevokeUsersApiKeysRequest,
  revokeCurrentUserApiKey,
  revokeUsersApiKeys,
} from '../../actions/apiKeys'
import { getConfigForKey } from '../../selectors'
import { getMemberEmail, hasNoMember } from '../Organization/OrganizationMembers/lib'

import { getKeyStatus } from './lib'
import ApiKeys from './ApiKeys'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps, ApiKeyRow } from './types'

const isManagingAllUsersApiKeys = ({
  match: {
    params: { regionId },
  },
}: ConsumerProps): boolean => regionId !== undefined

const mapStateToProps = (state: ReduxState, props: ConsumerProps): StateProps => {
  // When running on ECE and not in User Settings page (i.e. URL contains ECE region ID),
  // we want to display and manage all users API key and not only the current user's API keys
  const regionId = props.match.params.regionId
  const manageAllUsersApiKeys = isManagingAllUsersApiKeys(props)

  const getApiKeys = manageAllUsersApiKeys ? getAllUsersApiKeys : getCurrentUserApiKeys

  const fetchApiKeysRequest = manageAllUsersApiKeys
    ? fetchAllUsersApiKeysRequest
    : fetchCurrentUserApiKeysRequest

  const revokeApiKeyRequest = manageAllUsersApiKeys
    ? revokeUsersApiKeysRequest
    : revokeCurrentUserApiKeyRequest

  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`

  // All Adminconsole environments (ESS, GovCloud), except Userconsole and ECE
  const isAnySaasAdminconsole = getConfigForKey(state, `APP_PLATFORM`) === `saas` && isAdminconsole

  const profile = getProfile(state)
  const organizationId = profile?.organization_id
  const organizationMembers = getOrganizationMembers(state, organizationId)

  return {
    regionId,
    defaultExpiration: isAnySaasAdminconsole ? '12 hour' : '3 month',
    deploymentsIds:
      (organizationId &&
        getAllOrganizationDeploymentsSearchResults(state, organizationId)?.deployments.map(
          ({ id }) => id,
        )) ||
      [],
    apiKeys: getApiKeys(state).map((apiKey) => ({
      ...apiKey,
      createdBy: getMemberEmail({ userId: apiKey.user_id, members: organizationMembers }),
      status: getKeyStatus(apiKey.expiration_date).status,
      isRemoved: isAdminconsole
        ? false
        : hasNoMember({ userId: apiKey.user_id, members: organizationMembers }),
    })),
    organizationId,
    fetchKeysRequest: fetchApiKeysRequest(state),
    fetchOrganizationMembersRequest: ({ organizationId: id }) =>
      fetchOrganizationMembershipsRequest(state, id),
    revokeApiKeyRequest: revokeApiKeyRequest(state),
    generateKeyRequest: generateApiKeyRequest(state),
    showCreateApiKey: !manageAllUsersApiKeys,
    showRoleAssignments: !isAdminconsole, // set API keys roles only in Userconsole
    showNeverExpirationOption: !isAnySaasAdminconsole, // do not show option for API key to never expire in ESS
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch, props: ConsumerProps): DispatchProps => {
  const manageAllUsersApiKeys = isManagingAllUsersApiKeys(props)

  return {
    fetchApiKeys: () =>
      manageAllUsersApiKeys
        ? dispatch(fetchAllUsersApiKeys())
        : dispatch(fetchCurrentUserApiKeys()),
    fetchOrganizationMembers: ({ organizationId }) =>
      dispatch(fetchOrganizationMemberships({ organizationId })),
    revokeApiKey: (key: ApiKeyRow) =>
      manageAllUsersApiKeys
        ? dispatch(revokeUsersApiKeys([key]))
        : dispatch(revokeCurrentUserApiKey(key.id)),
    resetRevokeApiKeyRequest: () =>
      manageAllUsersApiKeys
        ? dispatch(resetRevokeUsersApiKeysRequest())
        : dispatch(resetRevokeCurrentUserApiKeyRequest()),
    searchAllOrganizationDeployments: ({ organizationId }) =>
      dispatch(
        searchAllOrganizationDeployments({
          organizationId,
        }),
      ),
    generateApiKey: (name, expiration, roleAssignments) =>
      dispatch(generateApiKey(name, expiration, roleAssignments)),
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps & RouteComponentProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(ApiKeys),
)
