/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from '@elastic/eui'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'

const DeviceOption: React.FunctionComponent<{
  device: SaasAuthMfaDeviceResponse
  onSelect: (device: SaasAuthMfaDeviceResponse) => void
}> = ({ device, onSelect }) => {
  const { device_type: type } = device

  return (
    <EuiPanel hasBorder={true}>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={true}>
          <h2>
            <EuiText size='s'>
              <strong>
                {type === 'GOOGLE' && (
                  <FormattedMessage
                    id='selectDevice.authenticator.title'
                    defaultMessage='Authenticator app'
                  />
                )}
                {type === 'EMAIL' && (
                  <FormattedMessage id='selectDevice.email.title' defaultMessage='Email' />
                )}
                {type === 'SMS' && (
                  <FormattedMessage id='selectDevice.sms.title' defaultMessage='SMS' />
                )}
                {type === 'WEBAUTHN' && (
                  <FormattedMessage
                    id='selectDevice.webauthn.title'
                    defaultMessage='Security key or biometrics'
                  />
                )}
              </strong>
            </EuiText>
          </h2>
          <EuiText size='s'>
            {type === 'GOOGLE' && (
              <FormattedMessage
                id='selectDevice.authenticator.description'
                defaultMessage='Enter a verification code from your registered authenticator app'
              />
            )}
            {type === 'EMAIL' && (
              <FormattedMessage
                id='selectDevice.email.description'
                defaultMessage='Get a verification code sent to {email}'
                values={{
                  email: <strong>{device.email_address}</strong>,
                }}
              />
            )}
            {type === 'SMS' && (
              <FormattedMessage
                id='selectDevice.sms.description'
                defaultMessage='Get a verification code sent to {phone}'
                values={{
                  phone: <strong>{device.phone_number}</strong>,
                }}
              />
            )}
            {type === 'WEBAUTHN' && (
              <FormattedMessage
                id='selectDevice.webauthn.description'
                defaultMessage='Use your hardware security key or biometrics'
              />
            )}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={() => onSelect(device)}>
            <FormattedMessage id='selectDevice.setupButton' defaultMessage='Select' />
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default DeviceOption
