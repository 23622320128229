/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { useIntl } from 'react-intl'
import { useField } from 'formik'

import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from '@elastic/eui'

import type { RoleMappingGroupRule } from '@modules/cloud-api/v1/types'

import { messages } from './messages'

const MappingValue = ({
  ruleType,
  index,
  removeValue,
}: {
  ruleType: 'all' | 'any'
  index: number
  removeValue: (index: number) => void
}) => {
  const { formatMessage } = useIntl()
  const fieldName = `rule.${ruleType}[${index}]`
  const [groupRuleField, groupRuleMeta, groupRuleHelper] = useField<RoleMappingGroupRule>(fieldName)
  const error = groupRuleMeta.error

  return (
    <EuiFormRow label={formatMessage(messages.value)} isInvalid={!!error} error={error}>
      <EuiFlexGroup justifyContent='flexStart' alignItems='center'>
        <EuiFlexItem grow={false}>
          <EuiFieldText
            style={{ maxWidth: '700px' }}
            value={groupRuleField.value.group}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
            onChange={(e) => groupRuleHelper.setValue({ group: e.target.value })}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            color='danger'
            onClick={() => {
              removeValue(index)
            }}
            iconType='trash'
            aria-label={formatMessage(messages.removeButton)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
  )
}

export default MappingValue
