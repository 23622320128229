/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasUserResponse } from '@modules/cloud-api/v1/types'

const isAWSUser = (user: SaasUserResponse): boolean => user.user.domain === 'aws'
const isGCPUser = (user: SaasUserResponse): boolean => user.user.domain === 'gcp'
const isAzureUser = (user: SaasUserResponse): boolean => user.user.domain === 'azure'

export const isMarketplaceUser = (user: SaasUserResponse): boolean =>
  isAWSUser(user) || isGCPUser(user) || isAzureUser(user)

export const isUnsubscribedMarketplaceUser = (user: SaasUserResponse): boolean => {
  const { aws_subscribed, gcp_subscribed, azure_subscribed } = user.subscription
  return (
    (isAWSUser(user) && !aws_subscribed) ||
    (isGCPUser(user) && !gcp_subscribed) ||
    (isAzureUser(user) && !azure_subscribed)
  )
}
