/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiCallOut, EuiSpacer, EuiText, EuiTextAlign } from '@elastic/eui'

export const ErrorPrompt = ({ message }: { message: string }) => (
  <Fragment>
    <EuiCallOut color='danger' iconType='alert'>
      <EuiText size='m' textAlign='center' color='danger'>
        <EuiTextAlign textAlign='left'>{message}</EuiTextAlign>
      </EuiText>
    </EuiCallOut>
    <EuiSpacer />
  </Fragment>
)
