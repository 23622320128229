/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import type {
  SliderInstanceType,
  VersionNumber,
  AnyPlan,
  PayloadForSliderInstanceType,
} from '@modules/ui-types'

import { getFirstSliderResourceFromTemplate } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { isSliderInstanceTypeSupportedInTemplate } from '@/lib/sliders/support'

import { replaceIn } from '../immutability-helpers'

export const getBlankNodeConfigurationPerTemplate = ({
  sliderInstanceType,
  deploymentTemplate,
  version,
}: {
  sliderInstanceType: SliderInstanceType
  deploymentTemplate: DeploymentTemplateInfoV2
  version?: VersionNumber
}): AnyPlan | null => {
  if (!isSliderInstanceTypeSupportedInTemplate(sliderInstanceType, deploymentTemplate)) {
    return null
  }

  const resource = getFirstSliderResourceFromTemplate({
    deploymentTemplate: deploymentTemplate.deployment_template,
    sliderInstanceType,
  })

  const templatePlan = resource?.plan

  if (!templatePlan) {
    return null
  }

  const versionedPlan = replaceIn(templatePlan, [sliderInstanceType, `version`], version)
  const blankPlan = getBlankPlan<typeof sliderInstanceType>({
    plan: versionedPlan,
  })

  return blankPlan
}

export const getBlankPlan = <T extends SliderInstanceType>({
  plan,
}: {
  plan: PayloadForSliderInstanceType<T>['plan']
}): PayloadForSliderInstanceType<T>['plan'] => {
  const blankPlan = replaceIn(plan, [`cluster_topology`, `0`, `size`, `value`], 0)

  return blankPlan
}
