/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGrid, EuiFlexItem } from '@elastic/eui'

import type { UserProfile } from '@modules/ui-types'

import MonthToDateUsage from '../RatePanel/MonthToDateUsage'

interface Props {
  profile: UserProfile
}

const TrialStarted = ({ profile }: Props) => (
  <EuiFlexGrid columns={3} data-test-id='cost-analysis-trial-started'>
    <EuiFlexItem>
      <MonthToDateUsage profile={profile} />
    </EuiFlexItem>
  </EuiFlexGrid>
)

export default TrialStarted
