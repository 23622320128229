/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SnapshotConfiguration } from '@modules/cloud-api/v1/types'

import { replaceIn } from '@/lib/immutability-helpers'

import { FETCH_SNAPSHOT_DEPLOYMENT_CONFIGURATION } from '../constants/actions'

import type { ReduxState } from '@/types/redux'

type SnapshotDeploymentDependencies = SnapshotConfiguration | null

export type State = {
  [deploymentId: string]: SnapshotDeploymentDependencies
}

type Action = {
  type: string
  meta: {
    deploymentId: string
  }
  error?: boolean
  payload?: SnapshotConfiguration
}

export default function snapshotDeploymentConfigurationReducer(
  state: State = {},
  action: Action,
): State {
  if (action.type === FETCH_SNAPSHOT_DEPLOYMENT_CONFIGURATION) {
    if (!action.error && action.payload) {
      const { deploymentId } = action.meta

      return replaceIn(state, [deploymentId], action.payload)
    }
  }

  return state
}

function _getSnapshotDeploymentConfiguration(
  state: State,
  deploymentId: string,
): SnapshotDeploymentDependencies {
  return state[deploymentId] || null
}

export const getSnapshotDeploymentConfiguration = (state: ReduxState, deploymentId: string) =>
  _getSnapshotDeploymentConfiguration(state.snapshotDeploymentDependencies, deploymentId)
