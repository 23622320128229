/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import { EuiPanel, useEuiTheme } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const PortalTile: FunctionComponent<Props> = ({ children, ...rest }) => {
  const {
    euiTheme: {
      size: { base },
    },
  } = useEuiTheme()

  return (
    <EuiPanel
      color='transparent'
      hasBorder={true}
      hasShadow={true}
      paddingSize='l'
      css={css({ paddingTop: base })}
      grow={false}
      {...rest}
    >
      {children}
    </EuiPanel>
  )
}

export default PortalTile
