/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getProfile } from '@/apps/userconsole/reducers/profile'
import { getConfigForKey } from '@/selectors'
import {
  searchAllOrganizationDeployments,
  getAllOrganizationDeploymentsSearchResults,
} from '@/components/Organization/OrganizationDeployments/lib/'

import OrganizationMembers from './OrganizationMembers'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { ConsumerProps, StateProps, DispatchProps } from './types'

const mapStateToProps = (state: ReduxState, { organizationId }: ConsumerProps): StateProps => {
  const profile = getProfile(state)
  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`

  return {
    deploymentsIds:
      getAllOrganizationDeploymentsSearchResults(state, organizationId)?.deployments.map(
        ({ id }) => id,
      ) || [],
    profile,
    isAdminconsole,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { organizationId }: ConsumerProps,
): DispatchProps => ({
  searchAllOrganizationDeployments: () =>
    dispatch(
      searchAllOrganizationDeployments({
        organizationId,
      }),
    ),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationMembers)
