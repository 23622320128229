/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages, type MessageDescriptor } from 'react-intl'
import { isInteger, sumBy } from 'lodash'
import moment from 'moment'

import type { Product, ProductLineItem } from '@modules/billing-api/v1/types'
import type { ProjectType } from '@modules/ui-types/projects'
import type { SaasUserTrial } from '@modules/cloud-api/v1/types'

export const DIMENSION_GROUPS = [
  'cloudProtection',
  'endpointProtection',
  'observabilityComplete',
  'securityAnalytics',
  'synthetics',
  'vcus',
  'storage',
  'dataOut',
  'support',
] as const

export type DimensionGroup = (typeof DIMENSION_GROUPS)[number]

type PliMetadata = {
  skuPrefix: string
  dimensionGroup: DimensionGroup
  displayName?: string
  hideAsIndividualLine?: true
}

/**
 * `displayName` sits between the amount and the unit, so may read oddly in
 * isolation and can be skipped if the unit alone is sufficiently descriptive.
 */
const pliMetadata: PliMetadata[] = [
  {
    skuPrefix: 'security.siem-essentials-ingested',
    dimensionGroup: 'securityAnalytics',
    displayName: 'Ingest',
  },
  {
    skuPrefix: 'security.siem-complete-ingested',
    dimensionGroup: 'securityAnalytics',
    displayName: 'Ingest',
  },
  {
    skuPrefix: 'security.siem-essentials-retained',
    dimensionGroup: 'securityAnalytics',
    displayName: 'Retention (Search AI Lake)',
  },
  {
    skuPrefix: 'security.siem-complete-retained',
    dimensionGroup: 'securityAnalytics',
    displayName: 'Retention (Search AI Lake)',
  },
  {
    skuPrefix: 'security.endpoint-essentials',
    dimensionGroup: 'endpointProtection',
  },
  {
    skuPrefix: 'security.endpoint-complete',
    dimensionGroup: 'endpointProtection',
  },
  {
    skuPrefix: 'security.cloud-essentials-assets',
    dimensionGroup: 'cloudProtection',
    displayName: 'CSPM',
  },
  {
    skuPrefix: 'security.cloud-essentials-workload',
    dimensionGroup: 'cloudProtection',
    displayName: 'Workload',
  },
  {
    skuPrefix: 'security.cloud-complete-workload',
    dimensionGroup: 'cloudProtection',
    displayName: 'Workload',
  },
  {
    skuPrefix: 'observability.observability-complete-ingested',
    dimensionGroup: 'observabilityComplete',
    displayName: 'Ingest',
  },
  {
    skuPrefix: 'observability.observability-complete-retained',
    dimensionGroup: 'observabilityComplete',
    displayName: 'Retention (Search AI Lake)',
  },
  {
    skuPrefix: 'observability.observability-synthetic-executions',
    dimensionGroup: 'synthetics',
    displayName: 'Browser',
  },
  {
    skuPrefix: 'observability.observability-synthetic-pings',
    dimensionGroup: 'synthetics',
    displayName: 'Lightweight test',
  },
  {
    skuPrefix: 'elasticsearch.general-purpose-search-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Search',
  },
  {
    skuPrefix: 'elasticsearch.general-purpose-ingest-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Ingest',
  },
  {
    skuPrefix: 'elasticsearch.general-purpose-ml-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Machine Learning',
  },
  {
    skuPrefix: 'elasticsearch.vector-search-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Search',
  },
  {
    skuPrefix: 'elasticsearch.vector-ingest-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Ingest',
  },
  {
    skuPrefix: 'elasticsearch.vector-ml-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Machine Learning',
  },
  {
    skuPrefix: 'elasticsearch.timeseries-search-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Search',
  },
  {
    skuPrefix: 'elasticsearch.timeseries-ingest-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Ingest',
  },
  {
    skuPrefix: 'elasticsearch.timeseries-ml-vcu',
    dimensionGroup: 'vcus',
    displayName: 'Machine Learning',
  },
  {
    skuPrefix: 'elasticsearch.retained',
    dimensionGroup: 'storage',
  },
  {
    skuPrefix: 'serverless.data-out',
    dimensionGroup: 'dataOut',
  },
  {
    skuPrefix: 'serverless.support-tier1',
    dimensionGroup: 'support',
    hideAsIndividualLine: true,
  },
  {
    skuPrefix: 'serverless.support-tier2',
    dimensionGroup: 'support',
    hideAsIndividualLine: true,
  },
  {
    skuPrefix: 'serverless.support-tier3',
    dimensionGroup: 'support',
    hideAsIndividualLine: true,
  },
  {
    skuPrefix: 'serverless.support-tier4',
    dimensionGroup: 'support',
    hideAsIndividualLine: true,
  },
]

const dimensionGroupTitles = defineMessages(<Record<DimensionGroup, MessageDescriptor>>{
  cloudProtection: { id: 'trialSummary.cloudProtection.title', defaultMessage: 'Cloud Protection' },
  endpointProtection: {
    id: 'trialSummary.endpointProtection.title',
    defaultMessage: 'Endpoint Protection',
  },
  observabilityComplete: {
    id: 'trialSummary.observabilityComplete.title',
    defaultMessage: 'Observability Complete',
  },
  securityAnalytics: {
    id: 'trialSummary.securityAnalystics.title',
    defaultMessage: 'Security Analytics',
  },
  synthetics: { id: 'trialSummary.synthetics.title', defaultMessage: 'Synthetics' },
  vcus: { id: 'trialSummary.vcus.title', defaultMessage: 'Virtual Compute Units (VCUs)' },
  storage: { id: 'trialSummary.storage.title', defaultMessage: 'Storage (Search AI Lake)' },
  dataOut: { id: 'trialSummary.dataOut.title', defaultMessage: 'Data out' },
  support: { id: 'trialSummary.support.title', defaultMessage: 'Support' },
})

export function getDimensionGroupTitle(dimensionGroup: DimensionGroup): MessageDescriptor {
  return dimensionGroupTitles[dimensionGroup]
}

export function getPliMetadataForLineItem(lineItem: ProductLineItem): PliMetadata | undefined {
  return pliMetadata.find(({ skuPrefix }) => lineItem.sku.startsWith(skuPrefix))
}

export function groupLineItemsByDimensionGroup(lineItems: ProductLineItem[]) {
  const lineItemsByDimensionGroup: { [k in DimensionGroup]?: ProductLineItem[] } = {}

  lineItems.forEach((lineItem) => {
    const metadata = getPliMetadataForLineItem(lineItem)

    if (!metadata) {
      return // sanity
    }

    lineItemsByDimensionGroup[metadata.dimensionGroup] ??= []
    lineItemsByDimensionGroup[metadata.dimensionGroup]!.push(lineItem)
  })

  return lineItemsByDimensionGroup
}

export function getLineItemsTotalEcu(lineItems: ProductLineItem[]) {
  const total = sumBy(lineItems, (lineItem) => lineItem.total_ecu)

  return `$${total.toFixed(2)}`
}

export function getLineItemDescription(lineItem: ProductLineItem) {
  const metadata = getPliMetadataForLineItem(lineItem)

  return [
    isInteger(lineItem.quantity.value)
      ? lineItem.quantity.value.toFixed(0)
      : lineItem.quantity.value.toFixed(2),
    metadata?.displayName,
    `${lineItem.unit}${lineItem.quantity.value === 1 ? '' : 's'}`,
  ]
    .filter(Boolean)
    .join(' ')
}

export function getLineItemRate(lineItem: ProductLineItem) {
  return `$${lineItem.rate.formatted_value}`
}

export function getTotalHourlyRate({
  trial,
  product,
}: {
  trial: SaasUserTrial | undefined
  product: Product
}): number {
  if (!trial) {
    return 0 // sanity/fallback
  }

  // account for the fact that we may be mid-trial and usage is only reflected up until now
  const effectiveEnd = moment.min(moment(), moment(trial.end))

  const trialDuration = moment.duration(effectiveEnd.diff(moment(trial.start))).asHours()

  if (trialDuration === 0) {
    return 0 // avoid divide by zero if the data is bad
  }

  return product.total_ecu / trialDuration
}

/**
 * Creates an nil payload to render against in the case of the actual payload
 * not having any line items. This will catch the imtermittent case of a project
 * being present but not having any populated billing data yet.
 */
export function makeProductBlank(type: ProjectType): Product {
  switch (type) {
    case 'observability':
      return {
        total_ecu: 0,
        type: 'observability',
        product_line_items: [
          {
            name: 'Observability Complete - Data Retention (AWS eu-west-1)',
            total_ecu: 0,
            type: 'Search AI Lake',
            sku: 'observability.observability-complete-retained_aws-eu-west-1',
            unit: 'GB',
            quantity: {
              value: 0,
              formatted_value: '0 GBs',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0 GBs',
              type: 'default',
            },
            rate: {
              value: 0.0,
              formatted_value: '0.0000 per GB',
            },
            quantities: [],
          },
          {
            name: 'Observability Complete - Data Ingestion (AWS eu-west-1)',
            total_ecu: 0,
            type: 'Ingest and Search Boost',
            sku: 'observability.observability-complete-ingested_aws-eu-west-1',
            unit: 'GB',
            quantity: {
              value: 0,
              formatted_value: '0 GBs',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0 GBs',
              type: 'default',
            },
            rate: {
              value: 0,
              formatted_value: '0.0000 per GB',
            },
            quantities: [],
          },
          {
            name: 'Synthetic Browser Test Execution',
            total_ecu: 0.0,
            type: 'Synthetics',
            sku: 'observability.observability-synthetic-executions',
            unit: 'test',
            quantity: {
              value: 0.0,
              formatted_value: '0.0000 tests',
            },
            display_quantity: {
              value: 0.0,
              formatted_value: '0.0000 tests',
              type: 'default',
            },
            rate: {
              value: 0.0,
              formatted_value: '0.0000 per test',
            },
            quantities: [],
          },
          {
            name: 'Synthetic Lightweight Test Execution Regions',
            total_ecu: 0.0,
            type: 'Synthetics',
            sku: 'observability.observability-synthetic-pings',
            unit: 'region',
            quantity: {
              value: 0,
              formatted_value: '0.0000 regions',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0.0000 regions',
              type: 'default',
            },
            rate: {
              value: 0,
              formatted_value: '0.0000 per region',
            },
            quantities: [],
          },
        ],
      }
    case 'security':
      return {
        total_ecu: 0,
        type: 'security',
        product_line_items: [
          {
            name: 'Security Analytics Complete - Data ingestion (AWS eu-west-1)',
            total_ecu: 0,
            type: 'Ingest',
            sku: 'security.siem-complete-ingested_aws-eu-west-1',
            unit: 'GB',
            quantity: {
              value: 0,
              formatted_value: '0 GBs',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0 GBs',
              type: 'default',
            },
            rate: {
              value: 0,
              formatted_value: '0.0000 per GB',
            },
            quantities: [],
          },
          {
            name: 'Security Analytics Complete - Data retention (AWS eu-west-1)',
            total_ecu: 0.0,
            type: 'Search AI Lake',
            sku: 'security.siem-complete-retained_aws-eu-west-1',
            unit: 'GB',
            quantity: {
              value: 0,
              formatted_value: '0 GBs',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0 GBs',
              type: 'default',
            },
            rate: {
              value: 0.0,
              formatted_value: '0.0000 per GB',
            },
            quantities: [],
          },
          {
            name: 'Endpoint Protection Complete - Protected Endpoints (AWS eu-west-1)',
            total_ecu: 0,
            type: 'Endpoint',
            sku: 'security.endpoint-complete_aws-eu-west-1',
            unit: 'endpoint',
            quantity: {
              value: 0,
              formatted_value: '0 endpoints',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0 endpoints',
              type: 'normalized',
            },
            rate: {
              value: 0,
              formatted_value: '0.0000 per endpoint',
            },
            quantities: [],
          },
        ],
      }
    default:
      return {
        type: 'elasticsearch',
        total_ecu: 0,
        product_line_items: [
          {
            name: 'VCU',
            total_ecu: 0,
            type: 'VCU',
            sku: 'elasticsearch.general-purpose-search-vcu',
            unit: 'VCU',
            quantity: {
              value: 0,
              formatted_value: '0.0000 VCUs',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0.0000 VCUs',
              type: 'default',
            },
            rate: {
              value: 0.0,
              formatted_value: '0.0000 per VCU',
            },
            quantities: [],
          },
          {
            name: 'VCU',
            total_ecu: 0,
            type: 'VCU',
            sku: 'elasticsearch.general-purpose-ingest-vcu',
            unit: 'VCU',
            quantity: {
              value: 0,
              formatted_value: '0.0000 VCUs',
            },
            display_quantity: {
              value: 0,
              formatted_value: '0.0000 VCUs',
              type: 'default',
            },
            rate: {
              value: 0.0,
              formatted_value: '0.0000 per VCU',
            },
            quantities: [],
          },
          {
            name: 'Retained data',
            total_ecu: 0.0,
            type: 'Search AI Lake',
            sku: 'elasticsearch.retained',
            unit: 'GB',
            quantity: {
              value: 0.0,
              formatted_value: '0.0000 GBs',
            },
            display_quantity: {
              value: 0.0,
              formatted_value: '0.0000 GBs',
              type: 'default',
            },
            rate: {
              value: 0.0,
              formatted_value: '0.0000 per GB',
            },
            quantities: [],
          },
        ],
      }
  }
}
