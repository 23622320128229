/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiCallOut, EuiCode, EuiSpacer } from '@elastic/eui'

import type { CloudAppPlatform, InstanceSummary } from '@modules/ui-types'

import { getConfigForKey } from '@/store'
import ExternalLink from '@/components/ExternalLink'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  instancesWithOverride: InstanceSummary[]
}

const InstanceOverrideCallout: FunctionComponent<Props> = ({ instancesWithOverride }) => {
  if (instancesWithOverride.length === 0) {
    return null
  }

  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`

  const instanceNames = instancesWithOverride
    .map<React.ReactNode>((instance) => (
      <EuiCode transparentBackground={true}>{instance.instance.instance_name}</EuiCode>
    ))
    .reduce((prev, curr) => [prev, ', ', curr])

  const isEce = getConfigForKey<CloudAppPlatform>(`APP_PLATFORM`) === `ece`
  const adminLabel = isEce ? (
    <FormattedMessage id='administrators' defaultMessage='Administrators' />
  ) : (
    <FormattedMessage
      id='elastic.cloud.administrators'
      defaultMessage='Elastic Cloud administrators'
    />
  )
  const description = isUserConsole ? (
    <FormattedMessage
      id='instances-override-callout-userconsole.description'
      defaultMessage='{adminLabel} have temporarily increased resource capacity for the following instances in this deployment in order to stabilize operations: [{instances}]. While emergency resources are added, some features will not function. The additional capacity can be removed by Elastic at any time. To make sure that the deployment remains stable, add more resources or reduce the workloads. If you have more questions or concerns, please <link>contact support</link>.'
      values={{
        adminLabel,
        instances: instanceNames,
        link: (content) => (
          <ExternalLink showExternalLinkIcon={false} href='/support'>
            {content}
          </ExternalLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage
      id='instances-override-callout-adminconsole.description'
      defaultMessage='{adminLabel} have temporarily increased resource capacity for the following instances in this deployment in order to stabilize operations: [{instances}]. While emergency resources are added, some features will not function. The additional capacity can be removed by Elastic at any time. To make sure that the deployment remains stable, add more resources or reduce the workloads. In addition, the temporarily increased resource capacity can impact the overall allocator stability. Remove it as soon as possible.'
      values={{
        adminLabel,
        instances: instanceNames,
      }}
    />
  )

  return (
    <Fragment>
      <EuiCallOut
        data-test-id='instanceOverride-callout'
        title={
          <FormattedMessage
            id='instance-override-callout.title'
            defaultMessage='Elastic added temporary capacity to stabilize the deployment'
          />
        }
        color='warning'
      >
        <p>{description}</p>
      </EuiCallOut>
      <EuiSpacer />
    </Fragment>
  )
}

export default injectIntl(InstanceOverrideCallout)
