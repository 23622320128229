/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCode, EuiFlexGroup, EuiFlexItem, EuiSkeletonText, EuiText } from '@elastic/eui'

import { DeploymentName } from '@/components/DeploymentName'
import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'

import {
  getTrustLevelFromRelationship,
  isOwnAccountRelationship,
  trustLevelShortLabels,
} from '../../../../lib/stackDeployments/trustRelationships'

import type { AllProps } from '.'

class Allowlist extends Component<AllProps> {
  componentDidMount(): void {
    this.searchForTrustedDeployments()
  }

  componentDidUpdate(): void {
    // may not have userOrgId on mount, so catch it on update
    this.searchForTrustedDeployments()
  }

  render(): JSX.Element | null {
    const { userOrgId, trustRelationship, clusterId, searchedDeployment } = this.props

    const trustLevel = getTrustLevelFromRelationship(trustRelationship)

    if (clusterId === `all`) {
      return (
        <EuiText data-test-id='deployment-trust-level' data-test-trustlevel={trustLevel} size='s'>
          <FormattedMessage {...trustLevelShortLabels[trustLevel]} />
        </EuiText>
      )
    }

    // within the user's account we can display more info like deployment name and health
    if (isOwnAccountRelationship(trustRelationship, userOrgId)) {
      if (!searchedDeployment) {
        return <EuiSkeletonText lines={1} />
      }

      const trustedDeployment = searchedDeployment.find((_deployment) => {
        const esCluster = getFirstEsClusterFromGet({ deployment: _deployment })
        return esCluster?.id === clusterId
      })

      if (searchedDeployment && !trustedDeployment) {
        return null // skip over deleted deployments
      }

      return (
        <EuiFlexGroup gutterSize='s' direction='column'>
          <EuiFlexItem
            key={clusterId}
            data-test-id={`trusted-deployment-${clusterId}`}
            grow={false}
          >
            <DeploymentName deployment={trustedDeployment} linkify={true} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return (
      <EuiFlexGroup gutterSize='s' direction='column'>
        <EuiFlexItem key={clusterId}>
          <EuiCode>{clusterId.slice(0, 6)}</EuiCode>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  searchForTrustedDeployments(): void {
    const {
      userOrgId,
      trustRelationship,
      clusterId,
      searchedDeploymentRequest,
      searchForDeployment,
    } = this.props

    // only run once
    if (searchedDeploymentRequest.inProgress || searchedDeploymentRequest.isDone) {
      return
    }

    // only run for the "local" (same org if ESS, same env if ECE) trust relationship
    if (!isOwnAccountRelationship(trustRelationship, userOrgId)) {
      return
    }

    // only run if there are discrete deployments to query
    if (clusterId === `all`) {
      return
    }

    searchForDeployment(clusterId)
  }
}

export default Allowlist
