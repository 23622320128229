/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiContextMenuItem, EuiIcon, EuiLoadingSpinner } from '@elastic/eui'

import usePermissions from '@modules/permissions-lib/hooks'
import type { AnyUserProject } from '@modules/project-user-api/types'
import { getResumeProjectMutationHook } from '@modules/project-user-lib/hooks/resume'
import { addToast } from '@modules/cui/Toasts'
import genericMessages from '@modules/project-lib/genericMessages'
import ServerlessTrialSummaryModal from '@modules/trial-components/ServerlessSummaryModal'
import { useSaasUser } from '@modules/profile-lib/hooks'
import Feature from '@modules/utils/feature'
import { useConfig } from '@modules/cui/ConfigContext'
import { doesSaasEntityHaveExpiredTrial } from '@modules/profile-lib/trial'

export type Props = {
  project: AnyUserProject
  onComplete: () => void
}

const ResumeProjectButton: React.FunctionComponent<Props> = ({ project, onComplete }) => {
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false)

  const saasUser = useSaasUser()
  const hasExpiredTrial =
    saasUser && saasUser.subscription.is_trial && doesSaasEntityHaveExpiredTrial(saasUser)

  const isGovCloud = Boolean(useConfig(Feature.hideIrrelevantSectionsFromGovCloud))

  const showTrialModalOnError = hasExpiredTrial && !isGovCloud

  const resumeProjectMutation = getResumeProjectMutationHook(project.type)()

  const { hasPermissions: hasUpdatePermisson } = usePermissions([
    { type: `project-${project.type}`, action: 'update', id: project.id },
  ])

  const icon = resumeProjectMutation.isLoading ? (
    <EuiLoadingSpinner size='m' />
  ) : (
    <EuiIcon type='frameNext' size='m' />
  )

  return (
    <Fragment>
      {isTrialModalOpen && saasUser && (
        <ServerlessTrialSummaryModal
          saasUser={saasUser}
          onClose={() => setIsTrialModalOpen(false)}
        />
      )}

      <EuiContextMenuItem
        key='resume'
        icon={icon}
        onClick={performResume}
        disabled={!hasUpdatePermisson}
      >
        <FormattedMessage id='project.resume.button' defaultMessage='Resume project' />
      </EuiContextMenuItem>
    </Fragment>
  )

  function performResume() {
    resumeProjectMutation.mutate({ id: project.id }, { onSuccess, onError })
  }

  function onSuccess() {
    addToast({
      family: 'project.resume',
      color: 'success',
      title: <FormattedMessage id='project.resume.success' defaultMessage='Project resumed' />,
    })
    onComplete()
  }

  function onError() {
    if (showTrialModalOnError) {
      setIsTrialModalOpen(true)
    } else {
      addToast({
        family: 'project.resume',
        color: 'danger',
        title: <FormattedMessage {...genericMessages.sorryErrorOccurred} />,
      })
      onComplete()
    }
  }
}

export default ResumeProjectButton
