/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useEffect, useState } from 'react'

export const useLocalStorage = (storageKey: string, fallbackState?: unknown) => {
  const item: string | null = localStorage.getItem(storageKey)
  const [value, setValue] = React.useState(
    typeof item === 'string' ? JSON.parse(item) : fallbackState || null,
  )

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

export function useSessionStorageBackedState<T>(
  storageKey: string,
  initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(() => {
    try {
      const storedState = sessionStorage.getItem(storageKey)

      if (storedState && storedState !== 'undefined') {
        return JSON.parse(storedState)
      }
    } catch (e) {
      console.error(e)
    }

    return initialState
  })

  useEffect(() => {
    try {
      sessionStorage.setItem(storageKey, JSON.stringify(state))
    } catch (e) {
      console.error(e)
    }
  }, [state, storageKey])

  return [state, setState]
}
