/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  loadingRoleMappings: {
    id: `organization.security.role-mappings-table.loading-role-mappings`,
    defaultMessage: `Loading role mappings`,
  },
  noRoleMappings: {
    id: `organization.security.role-mappings-table.no-role-mappings`,
    defaultMessage: `No role mappings defined`,
  },
  nameColumn: {
    id: `organization.security.role-mappings-table.name-column`,
    defaultMessage: `Name`,
  },
  statusColumn: {
    id: `organization.security.role-mappings-table.status-column`,
    defaultMessage: `Status`,
  },
  rolesColumn: {
    id: `organization.security.role-mappings-table.roles-column`,
    defaultMessage: `Roles`,
  },
  actionsColumn: {
    id: `organization.security.role-mappings-table.actions-column`,
    defaultMessage: `Actions`,
  },
  enabledStatus: {
    id: `organization.security.role-mappings-table.enabled-status`,
    defaultMessage: `Enabled`,
  },
  disabledStatus: {
    id: `organization.security.role-mappings-table.disabled-status`,
    defaultMessage: `Disabled`,
  },
  removeRoleMapping: {
    id: `organization.security.role-mappings-table.remove-role-mapping`,
    defaultMessage: `Delete role mapping`,
  },
  editRoleMapping: {
    id: `organization.security.role-mappings-table.edit-role-mapping`,
    defaultMessage: `Edit role mapping`,
  },
  description: {
    id: `organization.security.role-mappings-table.description`,
    defaultMessage: `Define the roles of users accessing this organization from your identity provider.`,
  },
  modalRemoveRoleMappingTitle: {
    id: `organization.security.role-mappings-table.modal-remove-role-mapping-title`,
    defaultMessage: `Delete role mapping?`,
  },
  modalRemoveRoleMappingMessage: {
    id: `organization.security.role-mappings-table.modal-remove-role-mapping-message`,
    defaultMessage: `The <b>{roleMappingName}</b> role mapping will be permanently deleted.`,
  },
  modalRemoveRoleMappingCancel: {
    id: `organization.security.role-mappings-table.modal-remove-role-mapping-cancel`,
    defaultMessage: `Cancel`,
  },
  modalRemoveRoleMappingConfirm: {
    id: `organization.security.role-mappings-table.modal-remove-role-mapping-confirm`,
    defaultMessage: `Delete role mapping`,
  },
  toastRemoveRoleMappingSuccess: {
    id: `organization.security.role-mappings-table.toast-remove-role-mapping-success`,
    defaultMessage: `Role mapping {roleMappingName} has been deleted.`,
  },
  toastRemoveRoleMappingError: {
    id: `organization.security.role-mappings-table.toast-remove-role-mapping-error`,
    defaultMessage: `Unknown error while deleting role mapping {roleMappingName}. Try again later.`,
  },
})
