/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'
import { withEuiTheme } from '@elastic/eui'

import type { FunctionComponent, ReactNode } from 'react'

import './fieldEditButton.scss'

interface Props extends WithEuiThemeProps {
  className?: string
  onClick: () => void
  children?: ReactNode
}

const FieldEditButton: FunctionComponent<Props> = ({ children, className, onClick, theme }) => {
  const cls = className ? ` ${className}` : ``

  const buttonStyle = css({
    color: theme.euiTheme.colors.primary,
  })
  return (
    <button className={`a fieldEditButton${cls}`} onClick={onClick} type='button' css={buttonStyle}>
      {children || <FormattedMessage id='fieldEditButton.label' defaultMessage='Edit' />}
    </button>
  )
}

export default withEuiTheme(FieldEditButton)
