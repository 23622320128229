/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  updateStackDeploymentRequest,
  deleteSecretRequest,
} from '@/reducers/asyncRequests/registry'

import { updateDeployment, resetUpdateDeployment } from '../../../actions/stackDeployments'
import { deleteSecretFromKeystore } from '../../../actions/keystore'
import { getFirstEsClusterFromGet } from '../../../lib/stackDeployments/selectors/fundamentals'

import DeploymentTrustRelationshipTable from './DeploymentTrustRelationshipTable'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const { ref_id } = getFirstEsClusterFromGet({ deployment })!

  return {
    updateStackDeploymentRequest: updateStackDeploymentRequest(state, deployment.id),
    deleteSecretRequest: (key: string) => deleteSecretRequest(state, deployment.id, ref_id, key),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => {
  const { ref_id } = getFirstEsClusterFromGet({ deployment })!
  return {
    updateStackDeployment: (payload) =>
      dispatch(
        updateDeployment({ deploymentId: deployment.id, deployment: payload, redirect: false }),
      ),
    resetUpdateStackDeployment: () => dispatch(resetUpdateDeployment(deployment.id)),
    deleteSecretFromKeystore: (key: string) =>
      dispatch(deleteSecretFromKeystore(deployment.id, ref_id, key)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentTrustRelationshipTable)
