/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export const MAX_MAXIMUM_RETENTION_DAYS = 3681

export const MIN_MAXIMUM_RETENTION_DAYS = 7

export const MAX_DEFAULT_RETENTION_DAYS = 3681

export const MIN_DEFAULT_RETENTION_DAYS = 7

export const DEFAULT_MAXIMUM_RETENTION_DAYS = 396

export const DEFAULT_DEFAULT_RETENTION_DAYS = 396

export function isDefaultRetentionDaysWithinRange(defaultRetentionDays: number): boolean {
  return (
    defaultRetentionDays >= MIN_DEFAULT_RETENTION_DAYS &&
    defaultRetentionDays <= MAX_DEFAULT_RETENTION_DAYS
  )
}

export function isMaximumRetentionDaysWithinRange(maxRetentionDays: number): boolean {
  return (
    maxRetentionDays >= MIN_MAXIMUM_RETENTION_DAYS && maxRetentionDays <= MAX_MAXIMUM_RETENTION_DAYS
  )
}

export function isValidDataRetentionConfiguration(
  defaultRetentionDays: number,
  maximumRetentionDays: number,
): boolean {
  if (
    isDefaultRetentionDaysWithinRange(defaultRetentionDays) &&
    isMaximumRetentionDaysWithinRange(maximumRetentionDays) &&
    defaultRetentionDays <= maximumRetentionDays
  ) {
    return true
  }

  if (defaultRetentionDays === Infinity && maximumRetentionDays === Infinity) {
    return true
  }

  if (
    maximumRetentionDays === Infinity &&
    isDefaultRetentionDaysWithinRange(defaultRetentionDays)
  ) {
    return true
  }

  return false
}

export function securityApiToRetentionDaysAdapter(value: number | null | undefined): number {
  return value ?? Infinity
}

export function retentionDaysToSecurityApiAdapter(value: number): number | null {
  return value === Infinity ? null : value
}
