/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import datemath from '@elastic/datemath'

import { getProjectMetrics } from '@modules/autoops-api'
import { METRIC_TYPES_BY_PROJECT_TYPE } from '@modules/autoops-lib/constants'
import type { ProjectType } from '@modules/ui-types/projects'

export const useGetProjectMetricsQuery = ({
  projectId,
  projectType,
  organizationId,
  region,
  range,
}: {
  projectId: string
  projectType: ProjectType
  organizationId: string
  region: string
  range: { from: string; to: string }
}) =>
  useQuery(
    ['getAutoOpsMetrics', projectId, range.from, range.to],
    () => {
      const metricTypes = METRIC_TYPES_BY_PROJECT_TYPE[projectType]

      return getProjectMetrics({
        projectId,
        organizationId,
        region,
        from: datemath.parse(range.from)?.valueOf() ?? 0,
        to: datemath.parse(range.to, { roundUp: true })?.valueOf() ?? 0,
        metricTypes,
      })
    },
    {
      select: (data) => ({
        search_latency: data.search_latency?.[0],
        search_rate: data.search_rate?.[0],
        index_latency: data.index_latency?.[0],
        index_rate: data.index_rate?.[0],
        search_vcu: data.search_vcu?.[0],
        ingest_vcu: data.ingest_vcu?.[0],
        ml_vcu: data.ml_vcu?.[0],
        storage_retained: data.storage_retained?.[0],
        ingest_rate: data.ingest_rate?.[0],
      }),
      cacheTime: 0,
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
