/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { type ReactNode } from 'react'
import { css } from '@emotion/react'

import { EuiButtonIcon, EuiFlexGroup, EuiTitle, EuiToolTip, EuiText } from '@elastic/eui'

type MetricTitleProps = {
  title: string
  infoContent: ReactNode
}

export const MetricTitle = ({ title, infoContent }: MetricTitleProps) => (
  <EuiFlexGroup gutterSize='s'>
    <EuiTitle size='xs'>
      <h3>{title}</h3>
    </EuiTitle>
    <EuiToolTip
      content={<EuiText css={css({ width: 200 })}>{infoContent}</EuiText>}
      position='bottom'
    >
      <EuiButtonIcon iconType='questionInCircle' aria-label='Help' />
    </EuiToolTip>
  </EuiFlexGroup>
)
