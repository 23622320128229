/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import type { CloudAppName } from '@modules/ui-types'
import { useConfig } from '@modules/cui/ConfigContext'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import {
  isAnnualAndOverages,
  isMarketplaceCustomer,
} from '@modules/billing-lib/billingDetails/utils'

// eslint-disable-next-line import/no-restricted-paths
import { getConfigForKey } from '@/store'

const useMarketplaceBillingUIEnabled = (): boolean => {
  const [isFlagUsable, flags] = useFlagsWhenLoaded()
  const appName = getConfigForKey<CloudAppName>('APP_NAME')
  const isMarketplaceUIEnabled = useConfig('MARKETPLACE_BILLING_UI')
  const billingDetails = useBillingDetails()

  if (!billingDetails.data) {
    return false
  }

  if (
    !isMarketplaceCustomer(billingDetails.data) ||
    (isMarketplaceCustomer(billingDetails.data) && isAnnualAndOverages(billingDetails.data)) // A+O out of the scope of migration
  ) {
    return false
  }

  if (appName === 'adminconsole') {
    return isMarketplaceUIEnabled
  }

  if (!isFlagUsable) {
    return false
  }

  return flags.marketplaceBillingUi
}

export default useMarketplaceBillingUIEnabled
