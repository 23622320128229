/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'
import AuthContext from '@modules/auth/context'
import { portalUrl } from '@modules/utils/rootUrls'
import type { MiddlewareComponent } from '@modules/app/types'

// eslint-disable-next-line import/no-restricted-paths
import AppLoadingRoot from '@/components/AppLoadingRoot'
// eslint-disable-next-line import/no-restricted-paths
import { setUpMfaUrl } from '@/apps/userconsole/urls'
// eslint-disable-next-line import/no-restricted-paths
import LaunchDarklyMiddleware from '@/apps/userconsole/components/App/middleware/LaunchDarklyMiddleware'

import EnableMfaPage from '../EnableMfaPage'
import { isMfaEnforceable, useIsMfaEnforced } from '../lib'

const MfaEnforcementMiddleware: MiddlewareComponent = ({ children }) => {
  const { pathname } = useLocation()
  const [showEnableMfaPage, setShowEnableMfaPage] = useState<boolean>(false)
  const [, { mfaEnforced }] = useFlagsWhenLoaded()

  const {
    authContext: { method: authMethod },
  } = useContext(AuthContext)

  // if it already knows MFA is not enforced, do not fetch devices unnecessarily
  const devicesQuery = useGetSaasCurrentUserMfaDevicesQuery({
    enabled: isMfaEnforceable({ mfaEnforced, authMethod }),
  })

  const devices = devicesQuery.data?.mfa_devices
  const isMfaEnforced = useIsMfaEnforced({ devices })

  useEffect(() => setShowEnableMfaPage(Boolean(isMfaEnforced)), [isMfaEnforced])

  // do not render MfaEnforcementMiddleware if MfaEnforcement is already being rendered
  if (pathname === setUpMfaUrl()) {
    return <Fragment>{children}</Fragment>
  }

  if (showEnableMfaPage) {
    return <EnableMfaPage devices={devices} onContinue={() => setShowEnableMfaPage(false)} />
  }

  return <Fragment>{children}</Fragment>
}

MfaEnforcementMiddleware.middleware = {
  displayName: 'MfaEnforcementMiddleware',
  dependencies: [LaunchDarklyMiddleware],
}

export type MfaEnforcementRedirectToState = { redirectTo: string | undefined } | undefined

const MfaEnforcement: React.FunctionComponent = () => {
  const { state } = useLocation<MfaEnforcementRedirectToState>()
  const redirectTo = state?.redirectTo

  // should always fetch devices
  const devicesQuery = useGetSaasCurrentUserMfaDevicesQuery()
  const devices = devicesQuery.data?.mfa_devices
  const isMfaEnforced = useIsMfaEnforced({ devices })

  if (isMfaEnforced === null) {
    // it hasn't decided yet if MFA should be enforced or not, show a loading page in this case
    return <AppLoadingRoot />
  }

  const redirect = redirectTo ?? portalUrl()

  if (isMfaEnforced) {
    // show MFA set up page, redirect user after they had it set up after clicking on the continue button
    return <EnableMfaPage devices={devices} onContinue={() => window.location.replace(redirect)} />
  }

  // user has MFA enabled, it's allowed to be redirected
  window.location.replace(redirect)
  return null
}

export { MfaEnforcement }
export default MfaEnforcementMiddleware
