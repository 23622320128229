/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiTitle } from '@elastic/eui'

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type { ElasticsearchCluster } from '@modules/ui-types'

import { isStopped } from '@/lib/stackDeployments/selectors/configurationChanges'
import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'

import ClusterSlainNodes from '../../../../../components/Cluster/SlainEvents'
import ClusterShards from '../../../../../components/Cluster/ClusterShards'
import SliderAppLinks from '../../../../../components/StackDeploymentSlider/SliderAppLinks'
import StackDeploymentNodesVisualization from '../../../../../components/StackDeployments/StackDeploymentNodesVisualization'
import { getRecentSlainEvents } from '../../../../../lib/deployments/events'
import { isClusterActive } from '../../../../../lib/clusters'

import type { FunctionComponent } from 'react'
import type { WithStackDeploymentRouteParamsProps } from '../../../../../components/StackDeploymentEditor'

export type StateProps = {
  deployment: DeploymentGetResponse
  cluster: ElasticsearchCluster
  saasClusterMetrics: boolean
  showNativeMemoryPressure: boolean
}

export type ConsumerProps = WithStackDeploymentRouteParamsProps & unknown

export type Props = ConsumerProps & StateProps

const ClusterOverview: FunctionComponent<Props> = ({
  deployment,
  cluster,
  saasClusterMetrics,
  showNativeMemoryPressure,
}) => {
  const { instances } = cluster

  if (!isClusterActive(cluster)) {
    return null
  }

  const esResource = getFirstEsClusterFromGet({ deployment })!
  const isClusterStopped = isStopped({ resource: esResource })
  const slainEvents = getRecentSlainEvents(cluster)

  return (
    <div>
      <EuiSpacer size='s' />

      <SliderAppLinks
        sliderInstanceType='elasticsearch'
        deployment={deployment}
        resource={esResource}
        saasClusterMetrics={saasClusterMetrics}
      />

      <EuiSpacer size='m' />

      {!isClusterStopped && (
        <Fragment>
          <ClusterShards deployment={deployment} />
          <EuiSpacer size='xxl' />
        </Fragment>
      )}

      {instances.record.length === 0 || (
        <StackDeploymentNodesVisualization
          title={
            <EuiTitle size='s'>
              <h3 data-test-id='deploymentOverview-zonesAndNodes'>
                <FormattedMessage
                  id='userconsole-deployment-overview.instances'
                  defaultMessage='Instances'
                />
              </h3>
            </EuiTitle>
          }
          deployment={deployment}
          sliderInstanceType='elasticsearch'
          showNativeMemoryPressure={showNativeMemoryPressure}
        />
      )}

      <EuiSpacer size='xl' />

      {slainEvents.length > 0 && <ClusterSlainNodes events={slainEvents} />}
    </div>
  )
}

export default ClusterOverview
