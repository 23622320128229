/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { omit } from 'lodash'

import type { DeploymentCreateResponse, DeploymentGetResponse } from '@modules/cloud-api/v1/types'

import { replaceIn } from '../../lib/immutability-helpers'
import {
  CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE,
  CREATE_STACK_DEPLOYMENT,
  DELETE_STACK_DEPLOYMENT,
  FETCH_STACK_DEPLOYMENT,
  SET_DISK_QUOTA,
  SET_INSTANCE_CAPACITY,
  SET_INSTANCE_STATUS,
  SET_MAINTENANCE_MODE,
} from '../../constants/actions'

import {
  updateStackDeploymentInstancesMaintenanceMode,
  updateStackDeploymentInstancesStatus,
} from './updateStackDeploymentInstance'
import setStackDeploymentInstanceCapacity from './setStackDeploymentInstanceCapacity'
import setStackDeploymentDiskQuota from './setStackDeploymentDiskQuota'

import type { ReduxState } from '@/types/redux'
import type {
  AnyStackDeploymentsAction,
  AnyStackDeploymentsInstanceAction,
} from '@/actions/stackDeployments/types'

type DeploymentMap<TResponse> = {
  [deploymentId: string]: TResponse
}

export type State = {
  regular: DeploymentMap<DeploymentGetResponse>
  creates: DeploymentMap<DeploymentCreateResponse>
  deletes: string[]
}

const initialState: State = {
  deletes: [],
  regular: {},
  creates: {},
}

export default function stackDeploymentsReducer(
  state: State = initialState,
  action: AnyStackDeploymentsAction | AnyStackDeploymentsInstanceAction,
): State {
  if (action.type === CREATE_STACK_DEPLOYMENT) {
    if (!action.error && action.payload) {
      const { id } = action.payload as DeploymentCreateResponse
      return replaceIn(state, [`creates`, id], action.payload)
    }
  }

  if (action.type === CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE) {
    const { deploymentId } = action.meta
    return replaceIn(state, [`creates`], omit(state.creates, deploymentId))
  }

  if (action.type === FETCH_STACK_DEPLOYMENT) {
    if (!action.error && action.payload) {
      const { id } = action.payload

      return replaceIn(state, [`regular`, id], action.payload)
    }
  }

  if (action.type === SET_INSTANCE_STATUS) {
    const {
      action: statusAction,
      kind: sliderType,
      resourceId,
      instanceIds,
      stackDeploymentId,
    } = action.meta

    const deploymentPath = [`regular`, stackDeploymentId]
    const stackDeployment = state.regular[stackDeploymentId]

    if (!stackDeployment) {
      return state // sanity
    }

    return replaceIn(
      state,
      deploymentPath,
      updateStackDeploymentInstancesStatus(
        stackDeployment,
        sliderType,
        resourceId,
        instanceIds,
        statusAction,
      ),
    )
  }

  if (action.type === SET_MAINTENANCE_MODE) {
    const {
      action: maintenanceAction,
      kind: sliderType,
      resourceId,
      instanceIds,
      stackDeploymentId,
    } = action.meta
    const stackDeployment = state.regular[stackDeploymentId]

    if (!stackDeployment) {
      return state // sanity
    }

    return replaceIn(
      state,
      [`regular`, stackDeploymentId],
      updateStackDeploymentInstancesMaintenanceMode(
        stackDeployment,
        sliderType,
        resourceId,
        instanceIds,
        maintenanceAction,
      ),
    )
  }

  if (action.type === SET_INSTANCE_CAPACITY) {
    const { deploymentId } = action.meta
    const stackDeployment = state.regular[deploymentId]

    if (!stackDeployment) {
      return state // sanity
    }

    return replaceIn(
      state,
      [`regular`, deploymentId],
      setStackDeploymentInstanceCapacity(stackDeployment, action),
    )
  }

  if (action.type === SET_DISK_QUOTA && action.payload) {
    const { deploymentId } = action.meta
    const stackDeployment = state.regular[deploymentId]

    if (!stackDeployment) {
      return state // sanity
    }

    return replaceIn(
      state,
      [`regular`, deploymentId],
      setStackDeploymentDiskQuota(stackDeployment, action),
    )
  }

  if (action.type === DELETE_STACK_DEPLOYMENT && action.payload) {
    return replaceIn(state, `deletes`, [...state.deletes, action.meta.deploymentId])
  }

  return state
}

const _getStackDeployment = (
  state: State,
  deploymentId: string,
): DeploymentGetResponse | undefined => state.regular[deploymentId]

const _getStackDeploymentCreateResponse = (
  state: State,
  deploymentId: string,
): DeploymentCreateResponse | undefined => state.creates[deploymentId]

const _getDeletedStackDeploymentIds = (state: State): string[] => state.deletes

export const getDeletedStackDeploymentIds = (state: ReduxState) =>
  _getDeletedStackDeploymentIds(state.stackDeployments)

export const getStackDeployment = (state: ReduxState, deploymentId: string) =>
  _getStackDeployment(state.stackDeployments, deploymentId)

export const getStackDeploymentCreateResponse = (state: ReduxState, deploymentId: string) =>
  _getStackDeploymentCreateResponse(state.stackDeployments, deploymentId)
