/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'

const LAST_USED_MFA_DEVICE_TYPE_KEY = 'LAST_USED_MFA_DEVICE_TYPE'

export function getPreferredMfaDevice(
  devices: SaasAuthMfaDeviceResponse[],
): SaasAuthMfaDeviceResponse | null {
  const activeDevices = devices.filter(({ status }) => status === 'ACTIVE')

  const lastUsedDeviceType = window.localStorage.getItem(LAST_USED_MFA_DEVICE_TYPE_KEY)

  if (lastUsedDeviceType) {
    const lastUsedDevice = activeDevices.find(
      ({ device_type }) => device_type === lastUsedDeviceType,
    )

    if (lastUsedDevice) {
      return lastUsedDevice
    }
  }

  const webAuthnDevice = activeDevices.find(({ device_type }) => device_type === 'WEBAUTHN')

  if (webAuthnDevice) {
    return webAuthnDevice
  }

  const authenticatorDevice = activeDevices.find(({ device_type }) => device_type === 'GOOGLE')

  if (authenticatorDevice) {
    return authenticatorDevice
  }

  const emailDevice = activeDevices.find(({ device_type }) => device_type === 'EMAIL')

  if (emailDevice) {
    return emailDevice
  }

  const smsDevice = activeDevices.find(({ device_type }) => device_type === 'SMS')

  if (smsDevice) {
    return smsDevice
  }

  return null
}

export function setLastUsedMfaDeviceType(type: SaasAuthMfaDeviceResponse['device_type']): void {
  window.localStorage.setItem(LAST_USED_MFA_DEVICE_TYPE_KEY, type)
}
