/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { BillingSubscriptionLevel } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { getExternalSubscription, getProfile } from '@/apps/userconsole/reducers/profile'
import { getBasePrices } from '@/apps/userconsole/reducers/pricing/basePricing'
import { fetchBasePricesRequest } from '@/apps/userconsole/reducers/registry'

import { fetchBasePricesIfNeeded } from '../../../actions/pricing'
import { isFeatureActivated, getConfigForKey } from '../../../../../selectors'

import PricedArchitectureSummary from './PricedArchitectureSummary'

import type { ConsumerProps, DispatchProps, StateProps } from './PricedArchitectureSummary'
import type { ThunkDispatch } from '../../../../../types/redux'

const mapStateToProps = (state, { regionId, selectedSubscription }: ConsumerProps): StateProps => {
  const profile = getProfile(state)
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const level = selectedSubscription || profile?.level

  return {
    profile,
    basePrices: getBasePrices(state, regionId, level),
    fetchBasePricesRequest: fetchBasePricesRequest(state, regionId, level || null),
    subscription: getExternalSubscription(state),
    showPrices: !isHeroku && isFeatureActivated(state, Feature.showPrices),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchBasePrices: ({
    regionId,
    level,
    marketplace,
  }: {
    regionId: string
    level?: BillingSubscriptionLevel
    marketplace?: boolean
  }) => dispatch(fetchBasePricesIfNeeded({ regionId, level, marketplace })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PricedArchitectureSummary)
