/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type {
  ResourceInfoForSliderInstanceType,
  SliderInstanceType,
  StackDeployment,
} from '@modules/ui-types'

import { isResourceAvailable } from './resources'
import { hasFailedCreatePlan } from './configurationChangeHealth'
import { getSliderResources } from './stackDeployment'

export function getFirstAvailableSliderClusterFromGet<T extends SliderInstanceType>({
  deployment,
  sliderInstanceType,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
  sliderInstanceType?: T
}): ResourceInfoForSliderInstanceType<T> | null {
  const resources = getSliderResources({ deployment, sliderInstanceType })

  return resources?.find(isResourceAvailable) || null
}

export function isDeploymentAvailable(deployment: StackDeployment): boolean {
  const hasNotFailed = !hasFailedCreatePlan({ deployment })

  // We only check ES instead of every instance, as it's the only hard dependency
  const hasEsInstances = deployment.resources.elasticsearch.some(isResourceAvailable)

  return hasNotFailed && hasEsInstances
}

export function isDeploymentAvailableInKibana(deployment: StackDeployment): boolean {
  const resource = getFirstAvailableSliderClusterFromGet({
    deployment,
    sliderInstanceType: 'kibana',
  })

  return !!resource
}
