/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { Dimension } from '@modules/billing-api/customTypes'

// eslint-disable-next-line import/no-restricted-paths

const dimensions: Dimension[] = [
  'RAM Hours',
  'data-transfer-in',
  'data-transfer-out',
  'data-transfer-inter-node',
  'snapshot-storage',
  'snapshot-api-1k',
  'synthetics-browser',
  'synthetics-lightweight',
  'serverless',
]

export const processQueryParams = (queryParams): any => ({ ...queryParams, dimensions })
