/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckableCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiListGroup,
  EuiListGroupItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle,
  useEuiMinBreakpoint,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui'

import genericMessages from '@modules/project-lib/genericMessages'
import type { AnySecurityProject } from '@modules/project-api/types'
import {
  includesProductType,
  setSecurityTier,
  toggleSecurityLine,
} from '@modules/project-lib/securityProjectTypes'
import type {
  SecurityProductLine,
  SecurityProductTier,
  SecurityProductType,
} from '@modules/project-user-api/v1/types'
import { getQuerystringItem } from '@modules/utils/qs'
import usePermissions from '@modules/permissions-lib/hooks'

// eslint-disable-next-line import/no-restricted-paths
import DocLink from '@/components/DocLink'

import type { ReactNode } from 'react'

export type Props = {
  project: AnySecurityProject
  onChange: (productTypes: AnySecurityProject['product_types'], onSettled: () => void) => void
  isLoading: boolean
}

type ProductDefinition = {
  obj: SecurityProductType
  title: ReactNode
  preamble?: ReactNode
  bullets: ReactNode[]
}

const PRODUCT_TYPES: Record<SecurityProductTier, Record<SecurityProductLine, ProductDefinition>> = {
  essentials: {
    security: {
      obj: {
        product_line: 'security',
        product_tier: 'essentials',
      },
      title: (
        <FormattedMessage
          id='projectSecurityFeatures.security.essentials.title'
          defaultMessage='Security Analytics Essentials'
        />
      ),
      bullets: [
        <FormattedMessage
          id='projectSecurityFeatures.security.essentials.bullets.1'
          defaultMessage='Ad hoc analytics, machine learning'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.essentials.bullets.2'
          defaultMessage='Prebuilt detection rules'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.essentials.bullets.3'
          defaultMessage='Triage, investigation, and hunting'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.essentials.bullets.4'
          defaultMessage='Collaboration workflows'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.essentials.bullets.5'
          defaultMessage='Threat intelligence integration'
        />,
      ],
    },
    endpoint: {
      obj: {
        product_line: 'endpoint',
        product_tier: 'essentials',
      },
      title: (
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.essentials.title'
          defaultMessage='Endpoint Protection Essentials'
        />
      ),
      bullets: [
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.essentials.bullets.1'
          defaultMessage='Elastic Defend for malware prevention'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.essentials.bullets.2'
          defaultMessage='Ransomware prevention'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.essentials.bullets.3'
          defaultMessage='Memory and behavior prevention'
        />,
      ],
    },
    cloud: {
      obj: {
        product_line: 'cloud',
        product_tier: 'essentials',
      },
      title: (
        <FormattedMessage
          id='projectSecurityFeatures.cloud.essentials.title'
          defaultMessage='Cloud Protection Essentials'
        />
      ),
      bullets: [
        <FormattedMessage
          id='projectSecurityFeatures.cloud.essentials.bullets.1'
          defaultMessage='Workload runtime protection'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.cloud.essentials.bullets.2'
          defaultMessage='Cloud Native Posture Management for: Kubernetes | AWS | GCP | More'
        />,
      ],
    },
  },
  complete: {
    security: {
      obj: {
        product_line: 'security',
        product_tier: 'complete',
      },
      title: (
        <FormattedMessage
          id='projectSecurityFeatures.security.complete.title'
          defaultMessage='Security Analytics Complete'
        />
      ),
      preamble: (
        <FormattedMessage
          id='projectSecurityFeatures.security.complete.preamble'
          defaultMessage='Everything in Security Analytics Essentials plus:'
        />
      ),
      bullets: [
        <FormattedMessage
          id='projectSecurityFeatures.security.complete.bullets.1'
          defaultMessage='Entity analytics / UEBA security content'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.complete.bullets.2'
          defaultMessage='Threat intelligence management'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.complete.bullets.3'
          defaultMessage='Bi-directional response framework'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.security.complete.bullets.4'
          defaultMessage='Elastic AI Assistant'
        />,
      ],
    },
    endpoint: {
      obj: {
        product_line: 'endpoint',
        product_tier: 'complete',
      },
      title: (
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.complete.title'
          defaultMessage='Endpoint Protection Complete'
        />
      ),
      preamble: (
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.complete.preamble'
          defaultMessage='Everything in Endpoint Protection Essentials plus:'
        />
      ),
      bullets: [
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.complete.bullets.1'
          defaultMessage='Endpoint response actions'
        />,
        <FormattedMessage
          id='projectSecurityFeatures.endpoint.complete.bullets.2'
          defaultMessage='Advanced endpoint policy management'
        />,
      ],
    },
    cloud: {
      obj: {
        product_line: 'cloud',
        product_tier: 'complete',
      },
      title: (
        <FormattedMessage
          id='projectSecurityFeatures.cloud.complete.title'
          defaultMessage='Cloud Protection Complete'
        />
      ),
      preamble: (
        <FormattedMessage
          id='projectSecurityFeatures.cloud.complete.preamble'
          defaultMessage='Everything in Cloud Protection Essentials plus:'
        />
      ),
      bullets: [
        <FormattedMessage
          id='projectSecurityFeatures.cloud.complete.bullets.2'
          defaultMessage='Response actions'
        />,
      ],
    },
  },
}

const DEFAULT_VALUE = Object.values(PRODUCT_TYPES.complete).map(({ obj }) => obj)

const SecurityProjectFeatures: React.FunctionComponent<Props> = ({
  project,
  onChange,
  isLoading,
}) => {
  const htmlId = useGeneratedHtmlId()
  const { euiTheme } = useEuiTheme()

  const { hasPermissions: hasUpdatePermission } = usePermissions([
    { type: `project-${project.type}`, action: 'update', id: project.id },
  ])

  const [isEditing, setIsEditing] = useState(
    getQuerystringItem('open') === 'securityProjectFeatures',
  )
  const [productTypes, setProductTypes] = useState(project.product_types || DEFAULT_VALUE)

  const dividerCss = css`
    ${useEuiMinBreakpoint('m')} {
      border-left: ${euiTheme.border.thin};
      padding-left: ${euiTheme.size.l};
    }
  `

  return (
    <div data-test-subj='editProjectFeatures'>
      <EuiFlexGroup
        responsive={false}
        gutterSize='m'
        alignItems='center'
        style={{ whiteSpace: 'nowrap' }}
      >
        <EuiFlexItem grow={true} data-test-subj='currentValue'>
          {renderValue()}
        </EuiFlexItem>
        {hasUpdatePermission && <EuiFlexItem grow={false}>{renderEditButton()}</EuiFlexItem>}
      </EuiFlexGroup>
      {renderModal()}
    </div>
  )

  function renderValue() {
    // Currently product_tier is homogenous arcoss product_types, but for
    // defensive purposes, consider any existence of `product_tier: complete` as
    // the project being on "Security complete".
    const isCurrentComplete = (project.product_types || DEFAULT_VALUE).some(
      ({ product_tier }) => product_tier === 'complete',
    )

    return isCurrentComplete ? (
      <FormattedMessage {...genericMessages.complete} />
    ) : (
      <FormattedMessage {...genericMessages.essentials} />
    )
  }

  function renderEditButton() {
    return (
      <EuiLink onClick={() => setIsEditing(true)}>
        <FormattedMessage {...genericMessages.edit} />
      </EuiLink>
    )
  }

  function renderModal() {
    if (!isEditing) {
      return null
    }

    function renderBase({ obj, title, preamble, bullets }: ProductDefinition): ReactNode {
      return (
        <EuiCheckableCard
          id={`${htmlId}-${obj.product_line}-${obj.product_tier}`}
          style={{ minHeight: '10rem' }}
          checkableType='radio'
          checked={includesProductType(productTypes, obj)}
          onChange={() => setProductTypes(setSecurityTier(obj.product_tier))}
          label={
            <Fragment>
              <EuiText style={{ whiteSpace: 'nowrap', marginTop: -4 }}>
                <strong style={{ whiteSpace: 'nowrap' }}>{title}</strong>
              </EuiText>
              <EuiSpacer size='s' />
              <EuiListGroup gutterSize='none' flush={true} wrapText={true} size='s'>
                {preamble && (
                  <EuiListGroupItem
                    size='xs'
                    style={{ paddingLeft: 0 }}
                    label={<EuiText size='s'>{preamble}</EuiText>}
                    wrapText={true}
                  />
                )}
                {bullets.map((label) => (
                  <EuiListGroupItem
                    size='xs'
                    style={{ paddingLeft: 0 }}
                    iconType='dot'
                    label={<EuiText size='s'>{label}</EuiText>}
                    wrapText={true}
                  />
                ))}
              </EuiListGroup>
            </Fragment>
          }
        />
      )
    }

    function renderOption({ obj, title, preamble, bullets }: ProductDefinition): ReactNode {
      return (
        <div style={{ minHeight: '10rem' }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={true}>
              <EuiText size='s'>
                <strong>
                  <label htmlFor={`${htmlId}-${obj.product_line}-${obj.product_tier}`}>
                    {title}
                  </label>
                </strong>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSwitch
                id={`${htmlId}-${obj.product_line}-${obj.product_tier}`}
                label=''
                showLabel={false}
                disabled={
                  !includesProductType(productTypes, PRODUCT_TYPES[obj.product_tier].security.obj)
                }
                checked={includesProductType(productTypes, obj)}
                onChange={() => setProductTypes(toggleSecurityLine(productTypes, obj))}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='s' />
          <EuiListGroup gutterSize='none' flush={true} wrapText={true} size='s'>
            {preamble && (
              <EuiListGroupItem
                size='xs'
                style={{ paddingLeft: 0 }}
                label={<EuiText size='s'>{preamble}</EuiText>}
                wrapText={true}
              />
            )}
            {bullets.map((label) => (
              <EuiListGroupItem
                size='xs'
                style={{ paddingLeft: 0 }}
                iconType='dot'
                label={<EuiText size='s'>{label}</EuiText>}
                wrapText={true}
              />
            ))}
          </EuiListGroup>
        </div>
      )
    }

    const addOnsTitle = (
      <EuiTitle size='xxxs' textTransform='uppercase'>
        <h4>
          <FormattedMessage
            id='project.securityProjectFeatures.addOns'
            defaultMessage='Add-on options'
          />
        </h4>
      </EuiTitle>
    )

    const shouldRenderDowngradeWarning =
      project.product_types &&
      includesProductType(project.product_types, PRODUCT_TYPES.complete.security.obj) &&
      !includesProductType(productTypes, PRODUCT_TYPES.complete.security.obj)

    return (
      <EuiModal maxWidth={'65rem'} onClose={close}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <FormattedMessage
              id='project.securityProjectFeatures.title'
              defaultMessage='Project features for {name}'
              values={{ name: project.name }}
            />
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFlexGroup>
            <EuiFlexItem grow={1}>
              {renderBase(PRODUCT_TYPES.essentials.security)}
              <EuiSpacer size='xl' />
              {addOnsTitle}
              <EuiSpacer size='m' />
              {renderOption(PRODUCT_TYPES.essentials.endpoint)}
              <EuiSpacer size='m' />
              {renderOption(PRODUCT_TYPES.essentials.cloud)}
            </EuiFlexItem>
            <EuiFlexItem grow={1} css={dividerCss}>
              {renderBase(PRODUCT_TYPES.complete.security)}
              <EuiSpacer size='xl' />
              {addOnsTitle}
              <EuiSpacer size='m' />
              {renderOption(PRODUCT_TYPES.complete.endpoint)}
              <EuiSpacer size='m' />
              {renderOption(PRODUCT_TYPES.complete.cloud)}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup direction='column'>
            {shouldRenderDowngradeWarning && (
              <EuiFlexItem grow={false}>
                <EuiCallOut
                  title={
                    <FormattedMessage
                      id='project.securityProjectFeatures.callout.title'
                      defaultMessage='Changing your Project features selection from Security Analytics Complete to Security Analytics Essentials will cause some features to become unavailable immediately, and data associated with those features may be permanently deleted. {learnMoreLink}'
                      values={{
                        learnMoreLink: (
                          <DocLink link='manageProjectFeatures' showExternalLinkIcon={true}>
                            <FormattedMessage
                              id='project.securityProjectFeatures.callout.learnMore'
                              defaultMessage='Learn more'
                            />
                          </DocLink>
                        ),
                      }}
                    />
                  }
                  color='warning'
                  size='s'
                  iconType='alert'
                />
              </EuiFlexItem>
            )}
            <EuiFlexItem>
              <EuiFlexGroup gutterSize='m' justifyContent='flexEnd'>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty size='s' onClick={close}>
                    <FormattedMessage {...genericMessages.cancel} />
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    size='s'
                    color='primary'
                    fill={true}
                    isLoading={isLoading}
                    onClick={() => {
                      onChange(productTypes, () => setIsEditing(false))
                    }}
                    minWidth='6rem'
                  >
                    <FormattedMessage {...genericMessages.save} />
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  function close() {
    setIsEditing(false)
    setProductTypes(project.product_types || DEFAULT_VALUE)
  }
}

export default SecurityProjectFeatures
