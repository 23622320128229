/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { mapValues, keyBy } from 'lodash'
import Cookies from 'js-cookie'

import type { Theme } from '@modules/ui-types'

import darkThemeVariables from './dark'
import lightThemeVariables from './light'

const lightTheme: Theme = `light`
const darkTheme: Theme = `dark`
const darkThemeSelector = `(prefers-color-scheme: dark)`
const euiThemeCookieKey = `EUI_THEME`

const themeVariables = {
  light: lightThemeVariables,
  dark: darkThemeVariables,
}

export function getTheme(): Theme {
  const cookieTheme = Cookies.get(euiThemeCookieKey) as Theme | undefined

  // if there is a cookie, use that
  if (cookieTheme && themeVariables.hasOwnProperty(cookieTheme)) {
    return cookieTheme
  }

  // fall back to the system default
  return getSystemPreference()
}

function getSystemPreference(): Theme {
  try {
    // if there's a system preference for dark mode, go with that
    if (window.matchMedia && window.matchMedia(darkThemeSelector).matches) {
      return darkTheme
    }
  } catch (err) {
    console.warn(err)
  }

  // finally, fall back to light theme
  return lightTheme
}

export function getThemeVariables() {
  const theme = getTheme()
  return themeVariables[theme]
}

export function getThemeColors() {
  const themeVariables = getThemeVariables()
  const colorKeys = Object.keys(themeVariables).filter((key) => key.startsWith(`euiColor`))
  const colors = mapValues(keyBy(colorKeys), (color) => themeVariables[color])
  return colors
}
