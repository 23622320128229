/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { CommonClusterPlanInfo } from '@modules/cloud-api/v1/types'

import { FETCH_ACTIVITY_DETAIL } from '@/constants/actions'
import { replaceIn } from '@/lib/immutability-helpers'

import type { ReduxState } from '@/types/redux'

export type State = {
  [crumbs: string]: CommonClusterPlanInfo
}

export default function planActivityDetailReducer(state: State = {}, action) {
  if (action.type === FETCH_ACTIVITY_DETAIL) {
    if (!action.error && action.payload) {
      const { deploymentId, resourceKind, refId, attemptId } = action.meta
      return replaceIn(
        state,
        [getCrumbs(deploymentId, resourceKind, refId, attemptId)],
        action.payload,
      )
    }
  }

  return state
}

function _getPlanActivityDetail(
  state: State,
  deploymentId: string | undefined,
  resourceKind: string,
  refId: string,
  attemptId?: string,
): CommonClusterPlanInfo | null {
  if (!deploymentId || !attemptId) {
    return null
  }

  return state[getCrumbs(deploymentId, resourceKind, refId, attemptId)] || null
}

function getCrumbs(
  deploymentId: string,
  resourceKind: string,
  refId: string,
  attemptId: string,
): string {
  return `${deploymentId}/${resourceKind}/${refId}/${attemptId}`
}

export const getPlanActivityDetail = (
  state: ReduxState,
  deploymentId: string | undefined,
  kind: string,
  refId: string,
  attemptId?: string,
) => _getPlanActivityDetail(state.planActivityDetail, deploymentId, kind, refId, attemptId)
