/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiSuperSelectOption } from '@elastic/eui'
import {
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon,
  EuiSuperSelect,
} from '@elastic/eui'

import type { PlatformInfo } from '@modules/utils/platform'
import { getPlatformInfoById } from '@modules/utils/platform'
import type { PlatformId } from '@modules/ui-types'

import type { ReactElement } from 'react'

interface Props {
  provider: string
  providers: string[]
  onSelectProvider: (provider: string) => void
  isLoading: boolean
}

class ProviderControl extends PureComponent<Props> {
  render(): ReactElement {
    const { isLoading, provider, onSelectProvider } = this.props

    return (
      <EuiFormRow
        fullWidth={true}
        label={
          <FormattedMessage
            id='provider-region-control.cloud-provider'
            defaultMessage='Cloud provider'
          />
        }
      >
        <EuiSuperSelect
          id='provider-control'
          fullWidth={true}
          options={this.getProviderOptions()}
          valueOfSelected={provider}
          onChange={onSelectProvider}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </EuiFormRow>
    )
  }

  renderProviderOption(platform: PlatformInfo): ReactElement {
    return (
      <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon size='m' type={platform.iconType} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText style={{ paddingLeft: 0 }} size='s'>
            {platform.title}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  getProviderOptions = (): Array<EuiSuperSelectOption<string>> => {
    const { providers } = this.props

    return providers
      .map((key) => getPlatformInfoById(key as PlatformId))
      .map((provider) => ({
        inputDisplay: this.renderProviderOption(provider),
        value: provider.id,
        'data-test-id': `cloud-provider-${provider.id}`,
      }))
  }
}

export default ProviderControl
