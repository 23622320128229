/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import type { HerokuAuthenticationParams } from '@modules/ui-types'
import { hasUnexpiredSession } from '@modules/auth/auth'

import { herokuAuthHandshakeRequest } from '@/reducers/asyncRequests/registry'

import { startHerokuAuthHandshake } from '../../../../../actions/heroku'
import { parseHerokuAuthenticationParams } from '../../../../../lib/heroku'

import HerokuAppRoot from './HerokuAppRoot'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const query = parse(location.search.slice(1))
  const isHerokuAuthenticated = hasUnexpiredSession()
  const herokuAuthenticationParams = parseHerokuAuthenticationParams(query)

  return {
    isHerokuAuthenticated,
    herokuAuthenticationParams,
    herokuAuthHandshakeRequest: herokuAuthHandshakeRequest(state),
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  startHerokuAuthHandshake: (authParams: HerokuAuthenticationParams) =>
    dispatch(startHerokuAuthHandshake(authParams)),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(HerokuAppRoot),
)
