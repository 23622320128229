/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find, without } from 'lodash'

import type {
  SecurityProductLine,
  SecurityProductTier,
  SecurityProductType,
} from '@modules/project-user-api/v1/types'

const ALL_LINES = ['security', 'endpoint', 'cloud'] as const

/**
 * Sets the overall tier, turning on all lines for that tier.
 */
export function setSecurityTier(product_tier: SecurityProductTier): SecurityProductType[] {
  return ALL_LINES.map((product_line) => ({ product_line, product_tier }))
}

export function toggleSecurityLine(
  productFeatures: SecurityProductType[] | undefined = [],
  obj: { product_line: SecurityProductLine; product_tier: SecurityProductTier },
): SecurityProductType[] {
  const foundObj = find(productFeatures, obj)
  return foundObj ? without(productFeatures, foundObj) : [...productFeatures, obj]
}

export function includesProductType(
  productFeatures: SecurityProductType[] | undefined,
  obj: { product_line: SecurityProductLine; product_tier: SecurityProductTier },
): boolean {
  return Boolean(find(productFeatures, obj))
}
