/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { groupBy, sumBy } from 'lodash'

import type {
  AppSearchTopologyElement,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import type {
  ArchitectureBreakdownItem,
  BasePrice,
  BillingSubscriptionLevel,
  NodeConfiguration,
  RegionId,
} from '@modules/ui-types'

import {
  isDedicatedML,
  isDedicatedMaster,
  getTier,
} from '@/lib/stackDeployments/selectors/nodeRoles'

import { getConfigForKey } from '../../store'

import { getNodeTypeForArchitectureSummary } from './nodeTypes'
import { getTiebreakerMaster, hasTiebreakerMaster } from './masters'
import { getFullSku, getPrice, matchDedicatedMasterBasePrice } from './architecture'
import { isEnabledConfiguration } from './conversion'

import type { IntlShape } from 'react-intl'

export function getInstanceType({ nodeConfig, instanceConfigurations }) {
  if (isDedicatedML({ topologyElement: nodeConfig })) {
    return 'ml'
  }

  const instanceConfiguration = instanceConfigurations.find(
    ({ id }) => id === nodeConfig.instance_configuration_id,
  )

  return instanceConfiguration && instanceConfiguration.instance_type
}

export function getDeploymentArchitecturePrices({
  regionId,
  nodeConfigurations,
  instanceConfigurations,
  basePrices,
  level,
}: {
  intl: IntlShape
  regionId: RegionId
  nodeConfigurations: NodeConfiguration[]
  instanceConfigurations: InstanceConfiguration[]
  basePrices: BasePrice[] | undefined
  level: BillingSubscriptionLevel
}) {
  if (!basePrices) {
    return []
  }

  const { breakdownHourlyRate } = getHourlyRate({
    regionId,
    nodeConfigurations,
    instanceConfigurations,
    basePrices,
    level,
  })

  return getBreakdownHourlyRate(breakdownHourlyRate)
}

function getBreakdownHourlyRate(breakdownHourlyRate) {
  const breakdownRatesById = groupBy(breakdownHourlyRate, (item) => item.id)
  // if we have 2 data nodes (hi-lo) sum price per type
  const breakdownHourlyRates = Object.keys(breakdownRatesById).reduce((breakdownRates, id) => {
    let priceSum = sumBy(breakdownRatesById[id], `price`)

    if (id?.startsWith(`data`) && breakdownRatesById.master) {
      priceSum += breakdownRatesById.master[0].price
    }

    breakdownRates[id] = priceSum

    return breakdownRates
  }, {})
  return breakdownHourlyRates
}

function getHourlyRate({
  basePrices,
  regionId,
  instanceConfigurations,
  nodeConfigurations,
  level,
}: {
  basePrices: BasePrice[]
  regionId: RegionId
  instanceConfigurations: InstanceConfiguration[]
  nodeConfigurations: Array<ElasticsearchClusterTopologyElement | AppSearchTopologyElement>
  level: BillingSubscriptionLevel
}) {
  const isProduction = getConfigForKey(`NODE_ENV`) === `production`
  const activeConfigurations = nodeConfigurations.filter(whereNodeConfigurationIsValid)
  const breakdown = activeConfigurations
    .map(getNodeConfigurationPrice)
    .filter((item) => item !== null) as ArchitectureBreakdownItem[]
  const tiebreakerPrice: ArchitectureBreakdownItem | null = getTiebreakerHourlyRate()

  if (tiebreakerPrice) {
    breakdown.push(tiebreakerPrice)
  }

  const hourlyRate = sumBy(breakdown.map((item) => item!.price))

  const breakdownHourlyRate = breakdown.map((item) => {
    const tier = getTier(item.nodeConfiguration)
    const hasTieBreaker = hasTiebreakerMaster(nodeConfigurations, instanceConfigurations)
    const type = getNodeTypeForArchitectureSummary(
      item.nodeConfiguration,
      item.instanceConfiguration!,
      hasTieBreaker,
    )

    return {
      id: tier || type,
      price: !item.pricePointFail ? item.price : undefined,
    }
  })

  return {
    breakdown,
    hourlyRate,
    breakdownHourlyRate,
    tiebreakerPrice,
  }

  function whereNodeConfigurationIsValid(nodeConfiguration) {
    const enabled = isEnabledConfiguration(nodeConfiguration)

    if (enabled === false) {
      return false
    }

    const instanceConfiguration = instanceConfigurations.find(
      (each) => each.id === nodeConfiguration.instance_configuration_id,
    )

    return instanceConfiguration != null // sanity
  }

  function getNodeConfigurationPrice(
    nodeConfiguration: ElasticsearchClusterTopologyElement,
  ): ArchitectureBreakdownItem | null {
    const instanceConfiguration = instanceConfigurations.find(
      (each) => each.id === nodeConfiguration.instance_configuration_id,
    )

    if (!instanceConfiguration) {
      return null
    }

    return getPrice({
      regionId,
      nodeConfiguration,
      instanceConfiguration,
      basePrices,
      level,
    })
  }

  function getTiebreakerHourlyRate(): ArchitectureBreakdownItem | null {
    const hasDedicatedMaster = activeConfigurations.some((topologyElement) =>
      isDedicatedMaster({ topologyElement }),
    )

    if (hasDedicatedMaster) {
      return null
    }

    const hasTieBreaker = hasTiebreakerMaster(nodeConfigurations, instanceConfigurations)

    if (!hasTieBreaker) {
      return null
    }

    const tieBreakerInstanceConfiguration = getTiebreakerMaster(instanceConfigurations)

    if (!tieBreakerInstanceConfiguration) {
      return {
        sku: null,
        price: 0, // sanity
        pricePointFail: true,
        instanceConfiguration: null,
        nodeConfiguration: null,
      }
    }

    const dedicatedMasterBasePrice = basePrices.find((price) =>
      matchDedicatedMasterBasePrice({
        price,
        userLevel: level,
        id: tieBreakerInstanceConfiguration.id,
      }),
    )

    if (dedicatedMasterBasePrice == null) {
      if (!isProduction) {
        console.warn(`Did not find a base price for "dedicated master".`)
      }

      return {
        sku: null,
        price: 0, // sanity
        pricePointFail: true,
        instanceConfiguration: null,
        nodeConfiguration: null,
      }
    }

    const { sku } = dedicatedMasterBasePrice

    const instanceConfiguration = instanceConfigurations.find((config) => {
      if (config?.metadata?.sku === sku) {
        return true
      }

      return config.id === sku
    })

    if (instanceConfiguration == null) {
      return {
        sku: null,
        price: 0, // sanity
        pricePointFail: true,
        instanceConfiguration: null,
        nodeConfiguration: null,
      }
    }

    // we explicitly want to add a surcharge for the 1 GB 1 AZ tiebreaker,
    // priced as a dedicated master
    const nodeConfiguration: ElasticsearchClusterTopologyElement = {
      instance_configuration_id: instanceConfiguration.id,
      node_type: { data: false, master: true, ingest: false },
      zone_count: 1,
      size: {
        resource: `memory`,
        value: 1024,
      },
    }

    const fullSku = getFullSku({
      regionId,
      sku: dedicatedMasterBasePrice.sku,
      userLevel: level,
      capacity: 1024,
      zones: 1,
    })

    return {
      sku: fullSku,
      price: dedicatedMasterBasePrice.price,
      instanceConfiguration,
      nodeConfiguration,
    }
  }
}
