/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { FeaturesUsage } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, UserProfile } from '@modules/ui-types'

import { getUsageDetails, fetchUsageDetailsRequest } from '@/apps/userconsole/reducers/account'

import { fetchUsageDetails } from '../../../actions/billing/details'

import UsageNotice from './UsageNotice'

import type { ThunkDispatch } from '@/types/redux'
import type { FetchUsageDetailsProps } from '../../../actions/billing/details'

interface StateProps {
  usageDetails: FeaturesUsage | undefined
  fetchUsageDetailsRequest: AsyncRequestState
}

interface DispatchProps {
  fetchUsageDetails: (args: FetchUsageDetailsProps) => void
}

interface ConsumerProps {
  accountDetails: UserProfile
}

const mapStateToProps = (state): StateProps => ({
  usageDetails: getUsageDetails(state),
  fetchUsageDetailsRequest: fetchUsageDetailsRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchUsageDetails: (args) => dispatch(fetchUsageDetails(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UsageNotice)
