/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiBadge } from '@elastic/eui'

import { getSliderPrettyName } from '@/lib/sliders/messages'
import prettySize from '@/lib/prettySize'
import {
  getSizeOptionText,
  getRawCpu,
} from '@/components/Topology/DeploymentTemplates/components/DeploymentInfrastructure/TopologyElement/helpers'
import { isDedicatedML } from '@/lib/stackDeployments/selectors/nodeRoles'

import type { FunctionComponent } from 'react'
import type { HardwareSummaryProps as Props } from './types'

const HardwareSummary: FunctionComponent<Props> = ({
  instance: {
    name,
    icConfigVersion,
    sliderInstanceType,
    sliderNodeType: sliderNodeTypes,
    storageMultiplier,
    cpuMultiplier,
    totalSize,
  },
  diffs,
  topologyElement,
}): JSX.Element => (
  <Fragment>
    <EuiText size='s'>
      {name} {` `}
      {icConfigVersion !== undefined ? <EuiBadge>v{icConfigVersion}</EuiBadge> : ``}
    </EuiText>

    <EuiSpacer size='xs' />

    {sliderNodeTypes && sliderNodeTypes.length > 0 && (
      <Fragment>
        <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false} wrap={true}>
          {sliderNodeTypes.map((sliderNodeType, index) => (
            <Fragment key={`${sliderInstanceType}-${sliderNodeType}-${index}`}>
              {index === 0 || (
                <EuiFlexItem grow={false}>
                  <EuiText size='xs' color='subdued'>{`\u00b7`}</EuiText>
                </EuiFlexItem>
              )}

              <EuiFlexItem grow={false}>
                <EuiText size='xs' color='subdued'>
                  <FormattedMessage
                    {...getSliderPrettyName({
                      sliderInstanceType,
                      sliderNodeType,
                    })}
                  />
                </EuiText>
              </EuiFlexItem>
            </Fragment>
          ))}
        </EuiFlexGroup>

        <EuiSpacer size='xs' />
      </Fragment>
    )}

    <EuiText size='xs' color='subdued'>
      {totalSize &&
        getSizeOptionText({
          instanceResource: `memory`,
          storageMultiplier,
          cpuMultiplier,
          value: totalSize,
          primaryKey: `storage`,
          secondaryKey: `memory`,
          isBlobStorage: false,
          isMachineLearning: isDedicatedML({ topologyElement }),
        })}
    </EuiText>

    {diffs && dealWithDiffs(diffs, totalSize)}

    <EuiSpacer size='xs' />
  </Fragment>
)

function dealWithDiffs(diffs, totalSize): JSX.Element {
  return (
    <EuiText>
      {diffs.totalSizeDiff !== 0 && <span>{displayDiff(diffs.totalSizeDiff, `RAM`, true)}</span>}
      {diffs.storageMultiplierDiff !== 0 && (
        <span>{displayDiff(diffs.storageMultiplierDiff * totalSize, `storage`, true)}</span>
      )}
      {diffs.cpuMultiplierDiff !== 0 && (
        <span>
          {displayDiff(
            getRawCpu({
              cpuMultiplier: diffs.cpuMultiplierDiff,
              totalSize,
              skipSizeCheck: false,
            }),
            `vCPU`,
            false,
          )}
        </span>
      )}
    </EuiText>
  )
}

function displayDiff(value, type, shouldPrettySize) {
  if (value < 0) {
    const absoluteVal = value * -1
    return (
      <Fragment>
        <EuiBadge color='warning'>
          -{shouldPrettySize ? prettySize(absoluteVal) : absoluteVal} {type}
        </EuiBadge>
        &nbsp;
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiBadge color='success'>
        +{shouldPrettySize ? prettySize(value) : value} {type}
      </EuiBadge>
      &nbsp;
    </Fragment>
  )
}

export default HardwareSummary
