/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiCodeBlock,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import sanitizeHtml from '@/lib/sanitizeHtml'

import type { FunctionComponent } from 'react'

interface Props {
  onClose: () => void
  affectedResources: string[]
  actionText: string
  resourceName: string
}

const HealthStatusDetailsTroubleshootingFlyout: FunctionComponent<Props> = ({
  onClose,
  affectedResources,
  resourceName,
  actionText,
}) => {
  const flyoutTitleId = useGeneratedHtmlId({
    prefix: 'health-status-flyout-resources',
  })

  return (
    <EuiFlyout ownFocus={true} onClose={onClose} aria-labelledby={flyoutTitleId}>
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size='m'>
          <h2 id={flyoutTitleId}>
            <FormattedMessage
              id='health-status-flyout-resources.affected-resources-title'
              defaultMessage={`Affected ${resourceName.toLowerCase()}`}
            />
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiText>
          <p>{sanitizeHtml(actionText)}</p>
          <p>
            <FormattedMessage
              id='health-status-flyout-resources.affected-resources-subtitle'
              defaultMessage={`This problem affects the following ${resourceName.toLowerCase()}:`}
            />
          </p>
        </EuiText>
        <EuiSpacer size='m' />
        <EuiCodeBlock transparentBackground={false}>{affectedResources.join('\r\n')}</EuiCodeBlock>
      </EuiFlyoutBody>
    </EuiFlyout>
  )
}

export default HealthStatusDetailsTroubleshootingFlyout
