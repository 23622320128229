/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AnyProject } from '@modules/project-api/types'

// ts-unused-exports:disable-next-line
export function managementUrl({ endpoints }: Pick<AnyProject, 'endpoints'>): string | undefined {
  if (!endpoints.kibana) {
    return
  }

  return `${endpoints.kibana}/app/management`
}

export function indexManagementUrl({
  endpoints,
}: Pick<AnyProject, 'endpoints'>): string | undefined {
  if (!endpoints.kibana) {
    return
  }

  return `${endpoints.kibana}/app/management/data/index_management/data_streams`
}

// ts-unused-exports:disable-next-line
export function apiKeysUrl({ endpoints }: Pick<AnyProject, 'endpoints'>): string | undefined {
  if (!endpoints.kibana) {
    return
  }

  return `${endpoints.kibana}/app/management/security/api_keys`
}

export function makeSsoUrl(
  project?: AnyProject,
  { kibanaDeepLink }: { kibanaDeepLink?: string } = {},
): string | undefined {
  if (!project) {
    return undefined
  }

  const kibanaEndpoint = project.endpoints.kibana

  if (!kibanaEndpoint) {
    return undefined
  }

  const {
    id,
    type,
    metadata: { organization_id: organizationId },
  } = project

  const acs = `${kibanaEndpoint}/api/security/saml/callback`
  const entityId = encodeURIComponent(`ec:/organizations:${organizationId}/project-${type}:${id}`)
  const kibanaDeepLinkParam = kibanaDeepLink ? `&RelayState=${kibanaDeepLink}` : ``

  return `/sso/v1/go/${entityId}?acs=${acs}&sp_login_url=${kibanaEndpoint}${kibanaDeepLinkParam}`
}
