/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiSpacer, EuiTitle } from '@elastic/eui'

// eslint-disable-next-line import/no-restricted-paths
import DriftChat from '@/apps/userconsole/components/DriftChat'

const Trigger: React.FunctionComponent<{ triggerChat: () => void }> = ({ triggerChat }) => (
  <EuiLink onClick={triggerChat}>
    <FormattedMessage id='trialSummary.liveChat' defaultMessage='Live chat with an engineer.' />
  </EuiLink>
)

const Help: React.FunctionComponent = () => {
  return null // pending https://github.com/elastic/cloud/pull/129193

  // eslint-disable-next-line no-unreachable
  return (
    <Fragment>
      <EuiTitle size='xs'>
        <h2>
          <FormattedMessage id='trialSummary.helpTitle' defaultMessage='Get help' />
        </h2>
      </EuiTitle>
      <EuiSpacer size='s' />
      <DriftChat>{Trigger}</DriftChat>
    </Fragment>
  )
}

export default Help
