/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  'auth.org_idp_not_configured': {
    id: `cloud-sso-page.idp-not-configured`,
    defaultMessage: `Organization SSO not configured. Please try a different login method instead. {login}.`,
  },
  'auth.idp_disabled': {
    id: `cloud-sso-page.idp-disabled`,
    defaultMessage: `Organization SSO disabled. Please try a different login method instead. {login}.`,
  },
  unknownError: {
    id: `cloud-sso-page.unknown-error`,
    defaultMessage: `Unexpected error while checking SSO. Please contact your organization's administrator.`,
  },
  errorLoginLink: {
    id: `cloud-sso-page.error-login-link`,
    defaultMessage: `Log in`,
  },
  'auth.nonce_expired': {
    id: `cloud-sso-page.nonce-expired`,
    defaultMessage: `Too much time elapsed between starting and completing SSO. {pleaseTryAgain}`,
  },
  pleaseTryAgain: {
    id: `cloud-sso-page.please-try-again`,
    defaultMessage: `Please try again.`,
  },
  'auth.nonce_mismatch': {
    id: `cloud-sso-page.nonce-mismatch`,
    defaultMessage: `Error during SSO handshake. {pleaseTryAgain}`,
  },
  'auth.session_nonce_missing': {
    id: `cloud-sso-page.session-nonce-missing`,
    defaultMessage: `Error during SSO handshake. {pleaseTryAgain}`,
  },
  'auth.idp_not_registered': {
    id: `cloud-sso-page.idp-not-registered`,
    defaultMessage: `Identity Provider configuration error. Please contact your organization's administrator.`,
  },
  'org.idp.no_domain_claim': {
    id: `cloud-sso-page.idp-no-domain-claim`,
    defaultMessage: `Identity Provider configuration error. Please contact your organization's administrator.`,
  },
  'auth.email_domain_not_claimed': {
    id: `cloud-sso-page.email-domain-not-claimed`,
    defaultMessage: `The email address returned by the identity provider did not match a domain claimed by your organization. Please contact your organization's administrator.`,
  },
  'auth.user_member_of_different_org': {
    id: `cloud-sso-page.user-member-of-different-org`,
    defaultMessage: `Authenticated successfully, but unable to join the organization. Please leave your existing organization and try again.`,
  },
  'idp.access_denied': {
    id: `cloud-sso-page.idp.access-denied`,
    defaultMessage: `The Identity Provider server denied the request. Please contact your organization's administrator.`,
  },
  'idp.invalid_client': {
    id: `cloud-sso-page.idp.invalid-client`,
    defaultMessage: `The specified client ID is invalid. Please contact your organization's administrator.`,
  },
  'idp.invalid_grant': {
    id: `cloud-sso-page.idp.invalid-grant`,
    defaultMessage: `The specified grant is invalid, expired, revoked, or doesn't match the redirect URI used in the authorization request. {pleaseTryAgain}`,
  },
  'idp.invalid_request': {
    id: `cloud-sso-page.idp.invalid-request`,
    defaultMessage: `The request is missing a necessary parameter, the parameter has an invalid value, or the request contains duplicate parameters. Please contact your organization's administrator.`,
  },
  'idp.invalid_scope': {
    id: `cloud-sso-page.idp.invalid-scope`,
    defaultMessage: `The scopes list contains an invalid or unsupported value. Please contact your organization's administrator.`,
  },
  'idp.invalid_token': {
    id: `cloud-sso-page.idp.invalid-token`,
    defaultMessage: `The specified token is invalid. Please contact your organization's administrator.`,
  },
  'idp.server_error': {
    id: `cloud-sso-page.idp.server-error`,
    defaultMessage: 'The server encountered an internal error. {pleaseTryAgain}',
  },
  'idp.temporarily_unavailable': {
    id: `cloud-sso-page.idp.temporarily-unavailable.`,
    defaultMessage:
      'The server is temporarily unavailable, but should be able to process the request at a later time. {pleaseTryAgain}',
  },
  'idp.unsupported_response_type': {
    id: `cloud-sso-page.idp.unsupported-response-type`,
    defaultMessage: `The specified response type is invalid or unsupported. Please contact your organization’s administrator.`,
  },
  'idp.unsupported_response_mode': {
    id: `cloud-sso-page.idp.unsupported-response-mode`,
    defaultMessage: `The specified response mode is invalid or unsupported. Please contact your organization’s administrator.`,
  },
})
