/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Suspense } from 'react'

import { EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { isPrepaidConsumptionDirectCustomer } from '@modules/billing-lib/billingDetails/utils'
import useMarketplaceBillingUIEnabled from '@modules/billing-lib/marketplaces/useMarketplaceBillingUIEnabled'

import ErrorCallout from '../ErrorCallout'

import PrepaidConsumptionOverview from './PrepaidConsumptionOverview'
import MarketplacesOverview from './MarketplacesOverview'

import type { FunctionComponent } from 'react'
import type { AllProps } from './types'

const OldUCBillingUI = React.lazy(() => import('@/apps/userconsole/components/Billing'))
const OldACBillingUI = React.lazy(
  () => import('@/apps/adminconsole/components/User/ReadonlyBillingOverview'),
)

const BillingOverview: FunctionComponent<AllProps> = ({
  organizationId,
  billingDetails,
  appName,
}) => {
  const shouldShowLoading = !organizationId || billingDetails.loading
  const isMarketplaceUIEnabled = useMarketplaceBillingUIEnabled()

  if (billingDetails.error) {
    return (
      <Fragment>
        <EuiSpacer size='xxl' />
        <ErrorCallout onReload={billingDetails.refetch} />
      </Fragment>
    )
  }

  if (shouldShowLoading) {
    return <EuiSkeletonText />
  }

  if (isPrepaidConsumptionDirectCustomer(billingDetails.data!)) {
    return <PrepaidConsumptionOverview organizationId={organizationId} />
  }

  if (isMarketplaceUIEnabled) {
    return <MarketplacesOverview organizationId={organizationId} />
  }

  if (appName === 'adminconsole') {
    return (
      <Suspense fallback={<EuiSkeletonText />} data-test-id='old-ac-billing-overview-ui'>
        <OldACBillingUI organizationId={organizationId} />
      </Suspense>
    )
  }

  if (appName === 'userconsole') {
    return (
      <Suspense fallback={<EuiSkeletonText />} data-test-id='old-uc-billing-overview-ui'>
        <OldUCBillingUI />
      </Suspense>
    )
  }

  // Sanity
  return <ErrorCallout />
}

export default BillingOverview
