/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import communityDark from '../../../../../files/illustration-community-darkmode.svg'
import communityLight from '../../../../../files/illustration-community.svg'
import verifyEmailDark from '../../../../../files/noResults--dark.svg'
import verifyEmailLight from '../../../../../files/noResults--light.svg'
import supportDark from '../../../../../files/welcome-support-darkmode.svg'
import supportLight from '../../../../../files/welcome-support.svg'

export default {
  communityDark,
  communityLight,
  verifyEmailDark,
  verifyEmailLight,
  supportDark,
  supportLight,
}
