/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import type { DeploymentGetResponse, RemoteResources } from '@modules/cloud-api/v1/types'

import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { isStopped } from '@/lib/stackDeployments/selectors/configurationChanges'
import {
  hasAnyPendingPlan,
  hasFailedCreatePlan,
} from '@/lib/stackDeployments/selectors/configurationChangeHealth'

import StackDeploymentHealthProblems from '../../StackDeploymentHealthProblems'

import type { Problem } from '../../../lib/healthProblems/problems'

export type StateProps = {
  ccsSettings: RemoteResources | undefined
}

export type DispatchProps = {
  fetchCcsSettings: () => void
}

export type ConsumerProps = {
  stackDeployment: DeploymentGetResponse | null
  hideActivityBits?: boolean
  onGettingStartedPage?: boolean
  spacerAfter?: boolean
  filterProblems?: (problem: Problem) => boolean
}

type AllProps = StateProps & DispatchProps & ConsumerProps

class DeploymentHealthStatus extends Component<AllProps> {
  componentDidMount(): void {
    if (this.props.stackDeployment !== null) {
      /*
        This API call returns an ugly 400 Bad Request when the deploymentId in question is "broken" in some way, e.g. it has a failed
        genesis plan. This ends up a) throwing a nice error in the console and b) failing some Cypress tests that expect to not have
        random 400's being thrown around. A pending plan after a cancel of the genesis plan  also results in a failure, so the only way
        to fix this is to not make the call during any pending plan :sad: Also fails when the deployment is terminated (stopped).
      */
      const esResource = getFirstEsClusterFromGet({ deployment: this.props.stackDeployment })

      if (
        !hasFailedCreatePlan({ deployment: this.props.stackDeployment }) &&
        !hasAnyPendingPlan({ deployment: this.props.stackDeployment }) &&
        esResource &&
        !isStopped({ resource: esResource })
      ) {
        this.props.fetchCcsSettings()
      }
    }
  }

  render(): JSX.Element {
    const {
      stackDeployment,
      hideActivityBits,
      onGettingStartedPage,
      spacerAfter = true,
      filterProblems,
      ccsSettings,
    } = this.props

    return (
      <StackDeploymentHealthProblems
        onGettingStartedPage={onGettingStartedPage}
        deployment={stackDeployment!}
        spacerAfter={spacerAfter}
        filterProblems={filterProblems}
        linkRecentChanges={true}
        hideActivityBits={hideActivityBits}
        ccsSettings={ccsSettings}
      />
    )
  }
}

export default DeploymentHealthStatus
