/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = {
  modal: defineMessages({
    title: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.title',
      defaultMessage: 'Change existing access to specific instances?',
    },
    opening: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.opening',
      defaultMessage:
        'This member was previously granted a different access level to specific instances.',
    },
    newRoleAssignment: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.new-role-assignment',
      defaultMessage:
        'You will now change their current access level on those instances by assigning:',
    },
    connectingRoleAndResource: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.connecting-role-and-resource',
      defaultMessage:
        '{assignedRole} {assignedRole, select, null {No roles} other {role}} on all {resourceTypes}',
    },
    cancel: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.cancel',
      defaultMessage: 'Cancel',
    },
    confirm: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.confirm',
      defaultMessage: 'Confirm changes',
    },
    elasticsearch: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.elasticsearch',
      defaultMessage: 'Elasticsearch projects',
    },
    observability: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.observability',
      defaultMessage: 'Observability projects',
    },
    kibana: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.kibana',
      defaultMessage: 'Kibana projects',
    },
    security: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.security',
      defaultMessage: 'Security projects',
    },
    deployment: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.hosted',
      defaultMessage: 'hosted deployments',
    },
    noneRole: {
      id: 'organization.organization-members.role-assignment.confirmation-modal.none-role',
      defaultMessage: 'None',
    },
  }),
}
