/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { map, isEmpty, merge } from 'lodash'

import {
  EuiButtonEmpty,
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiSelect,
  EuiSpacer,
} from '@elastic/eui'

import history from '@modules/utils/history'
import { CuiButton } from '@modules/cui/Button'
import { CuiAlert } from '@modules/cui/Alert'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { createUpdateRequestFromGetResponse } from '@/lib/stackDeployments/updates'
import { getSnapshotRepositoryIdFromGet } from '@/lib/stackDeployments/selectors/snapshots'
import {
  getRegionId,
  getPlanInfo,
  getFirstEsClusterFromGet,
  getDeploymentSettingsFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { getFirstEsCluster } from '@/lib/stackDeployments/selectors/stackDeployment'

import { createSnapshotRepositoryUrl } from '../../../../lib/urlBuilder'
import SnapshotRestore from '../SnapshotRestore'
import TakeSnapshotButton from '../TakeSnapshotButton'
import DangerButton from '../../../DangerButton'
import DeploymentLockGate from '../../../DeploymentLockGate'

import type { AllProps } from './types'

type State = {
  snapshotRepositoryId: string
}

class SnapshotActions extends Component<AllProps, State> {
  state: State = {
    snapshotRepositoryId: this.getCurrentSnapshotRepoId(),
  }

  componentDidMount() {
    const { deployment, fetchSnapshotRepositories } = this.props
    fetchSnapshotRepositories(deployment.regionId)
  }

  render() {
    const { canManageRepos } = this.props

    return (
      <div data-test-id='slm-snapshot-actions-container'>
        {this.showActions() && this.renderActions()}
        {canManageRepos && this.renderSnapshotRepoEditor()}
      </div>
    )
  }

  renderActions() {
    const {
      executeSlmPolicyRequest,
      showTakeSnapshotButton,
      showRestoreSnapshotButton,
      stackDeployment,
    } = this.props

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m'>
          {showTakeSnapshotButton && (
            <EuiFlexItem grow={false}>
              <DeploymentLockGate>
                <TakeSnapshotButton deployment={stackDeployment} handleStatus={true} />
              </DeploymentLockGate>
            </EuiFlexItem>
          )}

          {showRestoreSnapshotButton && (
            <EuiFlexItem grow={false}>
              <DeploymentLockGate>
                <SnapshotRestore deployment={stackDeployment} />
              </DeploymentLockGate>
            </EuiFlexItem>
          )}

          <EuiFlexItem grow={false}>
            <DeploymentLockGate>
              {this.showDisableSnapshotsButton() && (
                <PermissionsGate
                  permissions={[
                    {
                      type: 'deployment',
                      action: 'update',
                      id: stackDeployment.id,
                    },
                  ]}
                >
                  {({ hasPermissions }) => (
                    <DangerButton
                      data-test-id='disable-snapshots-for-deployment'
                      onConfirm={() => this.disableSnapshotsForCluster()}
                      disabled={this.hasPendingPlan() || !hasPermissions}
                      fill={false}
                      modal={{
                        title: (
                          <FormattedMessage
                            id='cluster-manage-disable-snapshot-repository.confirm-disable-snapshots'
                            defaultMessage='Confirm to disable snapshots'
                          />
                        ),
                        body: (
                          <FormattedMessage
                            id='cluster-manage-disable-snapshot-repository.explanation'
                            defaultMessage='Disabling snapshots can lead to data loss. Without a backup of your data, this Elasticsearch cluster will not be able to recover from failures.'
                          />
                        ),
                      }}
                    >
                      <FormattedMessage
                        id='cluster-manage-disable-snapshot-repository.disable-snapshots'
                        defaultMessage='Disable snapshots'
                      />
                    </DangerButton>
                  )}
                </PermissionsGate>
              )}
            </DeploymentLockGate>
          </EuiFlexItem>
        </EuiFlexGroup>

        {executeSlmPolicyRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <CuiAlert type='error'>{executeSlmPolicyRequest.error}</CuiAlert>
          </Fragment>
        )}

        {executeSlmPolicyRequest.isDone && !executeSlmPolicyRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiCallOut
              title={
                <FormattedMessage
                  id='take-snapshot-request-success'
                  defaultMessage='Snapshot in progress. Refresh the page to view status.'
                />
              }
            />
          </Fragment>
        )}
        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderSnapshotRepoEditor() {
    const { stackDeployment, snapshotRepositories, fetchSnapshotRepositoriesRequest } = this.props

    const regionId = getRegionId({ deployment: stackDeployment })

    if (fetchSnapshotRepositoriesRequest.error) {
      return (
        <CuiAlert type='error' data-test-id='repositories-request-error'>
          {fetchSnapshotRepositoriesRequest.error}
        </CuiAlert>
      )
    }

    if (!snapshotRepositories) {
      return <EuiLoadingSpinner data-test-id='repositories-loading-spinner' />
    }

    const { snapshotRepositoryId } = this.state
    const enabledSnapshots = this.hasEnabledSnapshots()
    const noSnapshotRepositoriesDefined = isEmpty(snapshotRepositories)
    const hasChangedSnapshotRepo = this.hasChangedSnapshotRepo()

    if (noSnapshotRepositoriesDefined && regionId) {
      return (
        <CuiAlert iconType='faceSad' type='warning' data-test-id='no-repositories-defined-alert'>
          <FormattedMessage
            id='cluster-manage-update-snapshot-repository.no-repos'
            defaultMessage='You do not have any snapshot repositories set up. {addLink} to enable snapshots for your Elasticsearch clusters.'
            values={{
              addLink: (
                <EuiButtonEmpty onClick={() => history.push(createSnapshotRepositoryUrl(regionId))}>
                  <FormattedMessage
                    id='cluster-manage-update-snapshot-repository.no-repos-link'
                    defaultMessage='Add a repository'
                  />
                </EuiButtonEmpty>
              ),
            }}
          />
        </CuiAlert>
      )
    }

    return (
      <EuiFormRow
        label={
          <FormattedMessage
            id='cluster-manage-update-snapshot-repository.title'
            defaultMessage='Snapshot repository'
          />
        }
      >
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem className='updateSnapshotRepository-options'>
            <EuiSelect
              data-test-id='update-snapshot-repo-for-deployment'
              value={snapshotRepositoryId}
              onChange={(e) => this.setState({ snapshotRepositoryId: e.target.value })}
              options={[
                ...(snapshotRepositoryId ? [] : [{ text: `` }]),
                ...map(snapshotRepositories, (repo) => {
                  const { type } = repo.config as { type?: string }
                  return {
                    value: repo.repository_name,
                    text: type ? `${repo.repository_name} (${type})` : repo.repository_name,
                  }
                }),
              ]}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize='s'>
              {(hasChangedSnapshotRepo || !enabledSnapshots) && (
                <EuiFlexItem grow={false}>
                  <PermissionsGate
                    permissions={[
                      {
                        type: 'deployment',
                        action: 'update',
                        id: stackDeployment.id,
                      },
                    ]}
                  >
                    {({ hasPermissions }) => (
                      <CuiButton
                        data-test-id='save-snapshot-repo-button'
                        fill={true}
                        onClick={() => {
                          this.setSnapshotRepository()
                        }}
                        disabled={this.hasPendingPlan() || !hasPermissions}
                      >
                        <FormattedMessage
                          id='cluster-manage-update-snapshot-repository.save-repository'
                          defaultMessage='Save'
                        />
                      </CuiButton>
                    )}
                  </PermissionsGate>
                </EuiFlexItem>
              )}
              {hasChangedSnapshotRepo && (
                <EuiFlexItem grow={false}>
                  <EuiButton
                    data-test-id='reset-snapshot-repo-button'
                    onClick={() => this.resetSnapshotRepo()}
                  >
                    <FormattedMessage
                      id='cluster-manage-update-snapshot-repository.reset-repository'
                      defaultMessage='Cancel'
                    />
                  </EuiButton>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    )
  }

  getCurrentSnapshotRepoId(): string {
    return getSnapshotRepositoryIdFromGet({ deployment: this.props.stackDeployment }) || ``
  }

  hasChangedSnapshotRepo() {
    return this.getCurrentSnapshotRepoId() !== this.state.snapshotRepositoryId
  }

  resetSnapshotRepo() {
    this.setState({ snapshotRepositoryId: this.getCurrentSnapshotRepoId() })
  }

  hasEnabledSnapshots(): boolean {
    const { stackDeployment } = this.props
    const deploymentSettings = getDeploymentSettingsFromGet({ deployment: stackDeployment })
    return Boolean(deploymentSettings?.snapshot?.enabled)
  }

  hasPendingPlan(): boolean {
    const { stackDeployment } = this.props
    const resource = getFirstEsClusterFromGet({ deployment: stackDeployment })
    return Boolean(!resource || getPlanInfo({ resource, state: 'pending' }))
  }

  showDisableSnapshotsButton() {
    return this.props.canManageRepos && this.hasEnabledSnapshots()
  }

  showActions() {
    const { showTakeSnapshotButton, showRestoreSnapshotButton } = this.props
    return showTakeSnapshotButton || showRestoreSnapshotButton || this.showDisableSnapshotsButton()
  }

  disableSnapshotsForCluster() {
    this.setState({ snapshotRepositoryId: `` }, () => {
      this.setSnapshotRepository()
    })
  }

  setSnapshotRepository() {
    const { snapshotRepositoryId } = this.state
    const { stackDeployment, updateDeployment } = this.props

    const regionId = getRegionId({ deployment: stackDeployment })

    if (!regionId) {
      return // sanity
    }

    const deployment = createUpdateRequestFromGetResponse({ deployment: stackDeployment })
    const resource = getFirstEsCluster({ deployment })

    if (!resource) {
      return // sanity
    }

    const enabled = Boolean(snapshotRepositoryId)
    const repository = enabled
      ? {
          reference: {
            repository_name: snapshotRepositoryId,
          },
        }
      : undefined

    resource.settings = merge(resource.settings || {}, {
      snapshot: {
        enabled,
        repository,
      },
    })

    updateDeployment({ regionId, deploymentId: stackDeployment.id, deployment })
  }
}

export default SnapshotActions
