/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCode } from '@elastic/eui'

import type { definitions } from '@modules/cloud-api/v1/types'

import {
  hasHealthyMasters,
  getMasterCount,
  getInstancesWithNoMaster,
} from '@/lib/stackDeployments/selectors/masters'

import type { Problem } from '../../problems'

/**
 * Reports on issues that are currently being reported by the Deployment Health API.
 * This reporter handles master health issues.
 */
export function getMasterProblems(resource: definitions['ElasticsearchResourceInfo']): Problem[] {
  const problems: Problem[] = []
  const healthyMasters = hasHealthyMasters({ resource })
  const masterCount = getMasterCount({ resource })
  const instancesWithNoMaster = getInstancesWithNoMaster({ resource })

  if (!healthyMasters) {
    if (masterCount === 0) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-master-node-unhealthy-none`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-master-node-none'
            defaultMessage='There are no Elasticsearch master nodes'
          />
        ),
      })
    } else if (masterCount === 1) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-master-node-unhealthy-single`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-master-node-unhealthy'
            defaultMessage='The Elasticsearch master node is unhealthy'
          />
        ),
      })
    } else if (instancesWithNoMaster.length === 0) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-master-node-unhealthy-many`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-master-node-multiples'
            defaultMessage='There are {masterNodeCount} unhealthy Elasticsearch master nodes'
            values={{ masterNodeCount: masterCount }}
          />
        ),
      })
    } else if (instancesWithNoMaster.length === 1) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-master-node-without-master`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-node-without-master'
            defaultMessage='Instance {instanceName} has no master'
            values={{ instanceName: <EuiCode>{instancesWithNoMaster[0]}</EuiCode> }}
          />
        ),
      })
    } else {
      problems.push({
        kind: `elasticsearch`,
        id: `es-master-node-many-without-master`,
        level: `danger`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.many-es-nodes-without-master'
            defaultMessage='Instances {instances} have no master'
            values={{
              instances: (
                <Fragment>
                  {instancesWithNoMaster.map((instanceWithNoMaster, index) => (
                    <Fragment>
                      {index === 0 ? null : ', '}
                      <EuiCode key={instanceWithNoMaster}>{instanceWithNoMaster}</EuiCode>
                    </Fragment>
                  ))}
                </Fragment>
              ),
            }}
          />
        ),
      })
    }
  }

  return problems
}
