/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { StackDeployment } from '@modules/ui-types'

import { getVersion } from './stackDeployments/selectors/fundamentals'
import { gte } from './semver'

export const API_KEYS_AVAILABLE_VERSION = `8.13.0`
export const API_KEYS_GA_AVAILABLE_VERSION = `8.14.0`

export function shouldShowApiKeys(deployment: StackDeployment): boolean {
  const version = getVersion({ deployment })

  if (!version) {
    return false
  }

  return gte(version, API_KEYS_AVAILABLE_VERSION)
}

export function isKeystoreSettingApiKey(input: string): boolean {
  const apiKeyPattern = /^cluster\.remote\.[-_a-zA-Z0-9_]+\.credentials$/
  // Test if the input string matches the pattern
  return apiKeyPattern.test(input)
}

export function isApiKeyRightFormat(input: string): boolean {
  // Define a regular expression pattern to match lowercase letters, numbers, dashes and underscores
  const pattern = /^[-_a-z0-9]+$/

  // Test if the input string matches the pattern
  return pattern.test(input)
}

export function extractAliasFromApiKey(input: string): string {
  const pattern = /^cluster\.remote\.([-_a-zA-Z0-9_]+)\.credentials$/
  const match = input.match(pattern)

  if (match && match[1]) {
    return match[1]
  }

  return ''
}
