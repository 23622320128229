/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCodeBlock, EuiHorizontalRule, EuiSpacer, EuiText } from '@elastic/eui'

import type { IdpResponse } from '@modules/security-idp-api/types'

import { EditUserAuthenticationFormRow } from '../components/EditUserAuthenticationFormRow'

import type { ReactElement } from 'react'

export const ElasticProvidedSsoConfig = ({ idpInfo }: { idpInfo: IdpResponse }) => {
  const { sso_login_url, metadata_url, acs, sp_entity_id } = idpInfo

  return (
    <div>
      <EuiSpacer size='l' />
      <EuiHorizontalRule size='full' />
      <EuiSpacer size='l' />
      <EuiText color='subdued'>
        <FormattedMessage
          id='organization.security.edit-user-authentication.provided-by-elastic'
          defaultMessage='PROVIDED BY ELASTIC'
        />
      </EuiText>
      <EuiSpacer size='l' />
      <EuiText>
        <FormattedMessage
          id='organization.security.edit-user-authentication.elastic-provided-sso-config-description'
          defaultMessage='To establish trust with your identity provider, copy the generated values for <b>Service provider Entity ID</b> and <b>Service provider ACS URL</b> to your identity provider. The Assertion Consumer Service URL, or ACS URL, is also known as SSO URL.'
          values={{
            b: (msg: string) => <strong>{msg}</strong>,
          }}
        />
      </EuiText>
      <EuiSpacer size='l' />

      <Row
        label={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sso-login-url'
            defaultMessage='SSO Login URL'
          />
        }
        value={sso_login_url}
        helpText={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sso-login-url-description'
            defaultMessage='This URL for your organization members to log in to Elastic using SSO.'
          />
        }
      />
      <Row
        label={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sp-entity-id'
            defaultMessage='Service provider Entity ID'
          />
        }
        value={sp_entity_id}
        helpText={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sp-entity-id-description'
            defaultMessage='The unique identifier that allows your IdP to recognize and validate SAML requests from Elastic Cloud.'
          />
        }
      />
      <Row
        label={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sp-acs-url'
            defaultMessage='Service provider ACS URL'
          />
        }
        value={acs}
        helpText={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sp-acs-url-description'
            defaultMessage='The Elastic Cloud URL that receives SAML assertions from the IdP.'
          />
        }
      />
      <Row
        label={
          <FormattedMessage
            id='organization.security.edit-user-authentication.metadata-url'
            defaultMessage='Metadata URL'
          />
        }
        value={metadata_url}
        helpText={
          <FormattedMessage
            id='organization.security.edit-user-authentication.metadata-url-description'
            defaultMessage='The URL that your IdP uses to retrieve Elastic Cloud metadata.'
          />
        }
      />
    </div>
  )
}

const Row = ({
  label,
  value,
  helpText,
}: {
  label: ReactElement
  value: string
  helpText: ReactElement
}) => (
  <EditUserAuthenticationFormRow
    label={label}
    valueElement={
      <div>
        <EuiCodeBlock fontSize='m' paddingSize='m' isCopyable={true}>
          {value}
        </EuiCodeBlock>
        <EuiText size='xs' color='subdue'>
          {helpText}
        </EuiText>
      </div>
    }
  />
)
