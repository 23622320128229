/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import messages from './messages'

import type { FunctionComponent } from 'react'

type Props = {
  onClick: () => void
  disabled: boolean
}

const GenerateApiKeyButton: FunctionComponent<Props> = (props) => {
  const { onClick, disabled } = props

  return (
    <EuiButton
      disabled={disabled}
      data-test-id='generateKey-button'
      fill={true}
      onClick={() => onClick()}
    >
      <FormattedMessage {...messages.createApiKey} />
    </EuiButton>
  )
}

export default GenerateApiKeyButton
