/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer } from '@elastic/eui'

import RegionControl from '@modules/pricing-list-components/Serverless/Filters/RegionControl'
import ProviderControl from '@modules/pricing-list-components/Serverless/Filters/ProviderControl'

import type { Props } from './types'
import type { FunctionComponent } from 'react'
import type { Moment } from 'moment'

const Filters: FunctionComponent<Props> = ({
  isLoading,
  providers,
  regions,
  provider,
  region,
  timestamp,
  onSelectedProviderChange,
  onSelectedRegionChange,
  onSelectedTimestampChange,
}) => (
  <Fragment>
    <EuiFlexGroup>
      <EuiFlexItem>
        <ProviderControl
          provider={provider}
          providers={providers}
          isLoading={isLoading}
          onSelectProvider={(providerId) => onSelectedProviderChange(providerId)}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <RegionControl
          region={region}
          regions={regions}
          isLoading={isLoading}
          onSelectRegion={(regionId) => onSelectedRegionChange(regionId)}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow
          label={
            <FormattedMessage
              id='serverless-pricing-table-page.date-picker-label'
              defaultMessage='Price as of'
            />
          }
        >
          <EuiDatePicker
            disabled={isLoading}
            fullWidth={true}
            selected={moment.utc(timestamp)}
            onChange={(timestamp: Moment) => onSelectedTimestampChange(timestamp.utc().format())}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer size='m' />
  </Fragment>
)

export default Filters
