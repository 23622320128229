/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import type {
  DeploymentGetResponse,
  DeploymentTemplateInfoV2,
  DeploymentUpdateRequest,
} from '@modules/cloud-api/v1/types'
import type { Region, StackDeployment } from '@modules/ui-types'

import DangerButton from '@/components/DangerButton'
import { getDedicatedMasterThresholdFromTemplate } from '@/lib/stackDeployments/selectors/masters'
import { replaceIn } from '@/lib/immutability-helpers'
import {
  ensureDedicatedMasterAwareTopology,
  hadDedicatedMastersBelowThreshold,
} from '@/lib/stackDeployments/dedicatedMasters'
import { getEsPlanFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import {
  createUpdateRequestFromGetResponse,
  sanitizeUpdateRequestBeforeSend,
} from '@/lib/stackDeployments/updates'

import type { AllProps } from '.'
import type { FunctionComponent } from 'react'

/*
  This whole component is necessary because we changed the calculation for dedicated masters. In the past we used to include ML nodes as data nodes
  so when there were enough data nodes to cross the threshold, we'd add the dedicated masters. Once we changed the calculation to remove ML nodes
  there were deployments that were created under the old logic that now have dedicated masters that are not necessary. Via this component, users
  can remove the dedicated masters if they so choose, and therefore will pay less money. 
  See https://elasticco.atlassian.net/browse/CP-5707
*/

const DedicatedMastersUnnecessary: FunctionComponent<AllProps> = ({
  deploymentTemplate,
  stackDeployment,
  region,
  updateDeployment,
}) => {
  if (!stackDeployment || !deploymentTemplate) {
    return null
  }

  const showDedicatedMastersCallout = hadDedicatedMastersBelowThreshold({
    clusterPlanUnderEdit: stackDeployment.resources.elasticsearch[0]?.info.plan_info.current?.plan,
    dedicatedMastersThreshold: getDedicatedMasterThresholdFromTemplate({
      deploymentTemplate: deploymentTemplate.deployment_template,
    }),
    instanceConfigurations:
      (stackDeployment as DeploymentGetResponse).instance_configurations ||
      deploymentTemplate.instance_configurations,
  })

  if (!showDedicatedMastersCallout) {
    return null
  }

  return (
    <Fragment>
      <EuiCallOut
        title={
          <FormattedMessage
            id='dedicated-masters-unneeded.title'
            defaultMessage='Remove unneeded dedicated master instances'
          />
        }
      >
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem grow={false}>
            <FormattedMessage
              id='dedicated-masters-unneeded.description'
              defaultMessage='Due to recent optimizations in the Elasticsearch configuration, your deployment no longer requires dedicated master instances. You may remove them now to reduce costs.'
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <DangerButton
              disabled={false}
              buttonType={EuiButton}
              color='primary'
              size='s'
              isEmpty={false}
              aria-label='remove'
              modal={{
                title: (
                  <FormattedMessage
                    id='dedicated-masters-unneeded.confirm-title'
                    defaultMessage='Remove dedicated master instances?'
                  />
                ),
                body: (
                  <FormattedMessage
                    id='dedicated-masters-unneeded.confirm-body'
                    defaultMessage='The dedicated master instances will be taken offline and the master role will be added to the existing data instances of your deployment. The configuration change can take some time to complete as changes are applied to one instance at a time.'
                  />
                ),
                confirmButtonText: (
                  <FormattedMessage
                    id='dedicated-masters-unneeded.confirm-button'
                    defaultMessage='Yes, remove dedicated master instances'
                  />
                ),
              }}
              onConfirm={() =>
                removeDedicatedMasters(
                  stackDeployment,
                  deploymentTemplate,
                  region,
                  updateDeployment,
                )
              }
            >
              <FormattedMessage id='dedicated-masters-unneeded.edit' defaultMessage='Remove' />
            </DangerButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiCallOut>
      <EuiSpacer />
    </Fragment>
  )
}

function removeDedicatedMasters(
  stackDeployment: StackDeployment | null,
  deploymentTemplate: DeploymentTemplateInfoV2,
  region: Region | undefined,
  updateDeployment,
) {
  if (!stackDeployment || !region) {
    return
  }

  const clusterPlanUnderEdit = getEsPlanFromGet({ deployment: stackDeployment })
  const updateDeploymentObj: DeploymentUpdateRequest = createUpdateRequestFromGetResponse({
    deployment: stackDeployment,
    deploymentTemplate,
  })

  if (updateDeploymentObj.resources && updateDeploymentObj.resources.elasticsearch) {
    updateDeploymentObj.resources.elasticsearch.map((esCluster) =>
      replaceIn(
        esCluster,
        [`plan`, `cluster_topology`],
        ensureDedicatedMasterAwareTopology({
          region,
          deploymentTemplate,
          cluster: esCluster,
          clusterPlanUnderEdit,
          instanceConfigurations: deploymentTemplate.instance_configurations,
          onlySized: false,
          ignorePreviousMasters: true,
        }),
      ),
    )

    updateDeployment({
      deploymentId: stackDeployment.id,
      deployment: sanitizeUpdateRequestBeforeSend({ deployment: updateDeploymentObj }),
      redirect: true,
      dryRun: false,
    })
  }
}

export default DedicatedMastersUnnecessary
