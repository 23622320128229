/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AnyTrustRelationship, AsyncRequestState, StackDeployment } from '@modules/ui-types'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getCurrentAccount } from '@/reducers/currentAccount'
import { getStackDeploymentsFromSearch } from '@/reducers/stackDeploymentSearches'
import { searchStackDeploymentsRequest } from '@/reducers/asyncRequests/registry'

import { searchDeployments } from '../../../../actions/stackDeployments'
import { getDeploymentsByClusterIdsQuery } from '../../../../lib/deploymentQuery'

import LinkedDeploymentName from './LinkedDeploymentName'

export interface StateProps {
  searchedDeployment: StackDeployment[] | undefined
  searchedDeploymentRequest: AsyncRequestState
  userOrgId: string | null
}

export interface DispatchProps {
  searchForDeployment: (ids: string) => void
}

export interface ConsumerProps {
  trustRelationship: AnyTrustRelationship
  clusterId: string
}

export type AllProps = StateProps & DispatchProps & ConsumerProps

const nonce = `trustLinkedDeploymentName`

const mapStateToProps = (state: ReduxState, { clusterId }: ConsumerProps): StateProps => ({
  searchedDeployment: getStackDeploymentsFromSearch(state, nonce + clusterId)?.deployments,
  searchedDeploymentRequest: searchStackDeploymentsRequest(state, nonce + clusterId),
  userOrgId: getCurrentAccount(state)?.id || null,
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchForDeployment: (clusterId) => {
    const query = getDeploymentsByClusterIdsQuery({ clusterIds: [clusterId] })
    dispatch(searchDeployments({ queryId: nonce + clusterId, query }))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LinkedDeploymentName)
