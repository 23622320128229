/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { Fragment, useState } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiFormRow, EuiFieldText, EuiSpacer, EuiTextAlign, EuiLink } from '@elastic/eui'

import history from '@modules/utils/history'
import type { AsyncRequestState } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import { submitButtonStyle } from '@modules/access-management-components/styles'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import SpinButton from '@/components/SpinButton'
import validateEmail from '@/lib/validateEmail'
import lightLock from '@/files/cloud-lock-white.svg'
import darkLock from '@/files/cloud-lock-dark.svg'
import AppLoadingRoot from '@/components/AppLoadingRoot'

import type { WrappedComponentProps } from 'react-intl'
import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'
import type { BaseSyntheticEvent, FunctionComponent } from 'react'

const messages = defineMessages({
  title: {
    id: 'uc.forgotPassword.formTitle',
    defaultMessage: 'Reset your password',
  },
  subtitle: {
    id: 'uc.forgotPasswordForm.instructions',
    defaultMessage: "Tell us the email for your account and we'll send a password reset link.",
  },
  emailField: {
    id: 'uc.forgotPassword.emailLabel',
    defaultMessage: 'Email',
  },
  resetButton: {
    id: 'uc.forgotPasswordForm.submitButton',
    defaultMessage: 'Reset password',
  },
  backToLogin: {
    id: 'uc.forgotPasswordForm.cancelButton',
    defaultMessage: 'Back to login',
  },
})

type Props = WrappedComponentProps & {
  onResetPassword: () => void
  resetPasswordRequest: AsyncRequestState
  onChangeEmail: (e: string) => void
  email: string
  resetPassword: (email: string) => Promise<any>
  loginUrl: string
}

const ForgotPasswordForm: FunctionComponent<Props & LDProps> = ({
  email,
  loginUrl,
  intl: { formatMessage },
  onResetPassword,
  resetPasswordRequest,
  onChangeEmail,
  resetPassword,
}) => {
  const [emailError, setEmailError] = useState(false)
  const [isFlagsUsable, flags] = useFlagsWhenLoaded()

  const renderResetPasswordButton = () => (
    <SpinButton
      data-test-id='forgotPasswordForm-submitButton'
      fill={true}
      spin={resetPasswordRequest.inProgress}
      disabled={email.length === 0 || emailError || resetPasswordRequest.inProgress}
      onClick={save}
      buttonProps={{ fullWidth: true }}
      css={submitButtonStyle}
    >
      {formatMessage(messages.resetButton)}
    </SpinButton>
  )

  const renderError = () => {
    if (resetPasswordRequest.error) {
      return (
        <Fragment>
          <EuiSpacer size='l' />

          <CuiAlert type='danger' iconType='alert'>
            {resetPasswordRequest.error}
          </CuiAlert>
        </Fragment>
      )
    }

    return null
  }

  const onChangeEmailClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeEmail(e.target.value)
    validateField(e)
  }

  const save = (): void => {
    if (emailError) {
      return
    }

    resetPassword(email).then(() => {
      onResetPassword()
    })
  }

  const validateField = (e: BaseSyntheticEvent): void => {
    const {
      target: { value },
    } = e

    setEmailError(false)

    if (!validateEmail(value)) {
      setEmailError(true)
    }
  }

  const isFlowV2 = flags?.signUpFlowV2

  if (!isFlagsUsable) {
    return <AppLoadingRoot />
  }

  if (isFlowV2) {
    return (
      <LandingPageOuterContainer isFlowV2={isFlowV2} pageContext={{ name: 'resetPassword' }}>
        <LandingPageInnerContainer
          isFlowV2={isFlowV2}
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subtitle)}
        >
          <PrivacySensitiveContainer>
            <EuiFormRow>
              <EuiFieldText
                icon='email'
                data-test-id='forgotPasswordForm-email'
                value={email}
                onChange={onChangeEmailClick}
                placeholder={formatMessage(messages.emailField)}
              />
            </EuiFormRow>
          </PrivacySensitiveContainer>

          <EuiSpacer size='m' />

          {renderResetPasswordButton()}

          <EuiSpacer size='l' />

          <EuiTextAlign textAlign='center'>
            <EuiLink
              href={loginUrl}
              onClick={() => history.push(loginUrl)}
              data-test-id='forgot-password-cancel-button'
            >
              {'← '}
              {formatMessage(messages.backToLogin)}
            </EuiLink>
          </EuiTextAlign>

          {renderError()}
        </LandingPageInnerContainer>
      </LandingPageOuterContainer>
    )
  }

  return (
    <LandingPageOuterContainer>
      <LandingPageInnerContainer
        image={lightLock}
        darkImage={darkLock}
        title={formatMessage(messages.title)}
        subtitle={formatMessage(messages.subtitle)}
      >
        <PrivacySensitiveContainer>
          <EuiFormRow label={formatMessage(messages.emailField)}>
            <EuiFieldText
              data-test-id='forgotPasswordForm-email'
              value={email}
              onChange={onChangeEmailClick}
            />
          </EuiFormRow>
        </PrivacySensitiveContainer>

        <EuiSpacer size='l' />

        {renderResetPasswordButton()}

        <EuiSpacer size='l' />

        <EuiTextAlign textAlign='center'>
          <Link to={loginUrl} data-test-id='forgot-password-cancel-button'>
            {formatMessage(messages.backToLogin)}
          </Link>
        </EuiTextAlign>

        {renderError()}
      </LandingPageInnerContainer>
    </LandingPageOuterContainer>
  )
}

export default injectIntl(ForgotPasswordForm)
