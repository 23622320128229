/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import PricedArchitectureSummary from '@/apps/userconsole/components/Topology/PricedArchitectureSummary'
import {
  fetchDeploymentTemplatesRequest,
  createStackDeploymentRequest,
} from '@/reducers/asyncRequests/registry'
import { getActiveVersionStacks } from '@/reducers/elasticStack'
import { getConfigForKey } from '@/selectors'

import ConfigureDeployment from './ConfigureDeployment'
import { ConsumerProps, DispatchProps, StateProps } from './types'

import type { ReduxState } from '@/types/redux'

const mapStateToProps = (state: ReduxState, { region, showPrice }: ConsumerProps): StateProps => {
  const regionId = region.id

  return {
    regionId,
    fetchDeploymentTemplatesRequest: fetchDeploymentTemplatesRequest(state, regionId),
    createDeploymentRequest: createStackDeploymentRequest(state),
    stackVersions: getActiveVersionStacks(state, regionId),
    inTrial: state.profile?.inTrial ?? false,
    architectureSummary: showPrice ? PricedArchitectureSummary : undefined,
    isEceAdminconsole: getConfigForKey(state, `CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`,
  }
}

const mapDispatchToProps: DispatchProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigureDeployment)

export type { Props } from './ConfigureDeployment'
