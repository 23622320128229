/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useRef } from 'react'

import { EuiPageSection } from '@elastic/eui'

import Breadcrumbs, { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'

import ChromeHeader from '../../ChromeHeader'

import type { FunctionComponent, ReactNode } from 'react'

import '../errorPages.scss'

type Props = {
  isPortalEnabled: boolean
  children: ReactNode
}

const HttpErrorPage: FunctionComponent<Props> = ({ isPortalEnabled, children }) => {
  const breadcrumbsRef = useRef<HTMLDivElement>(null)
  const announcementsRef = useRef<HTMLDivElement>(null)

  return (
    <Fragment>
      <BreadcrumbsContext.Provider value={{ breadcrumbsRef, announcementsRef }}>
        <Fragment>
          {isPortalEnabled ? (
            <ChromeHeader
              breadcrumbsRef={breadcrumbsRef}
              announcementsRef={announcementsRef}
              hideTrialIndicator={true}
              showHelp={true}
            />
          ) : (
            <ChromeHeader showBreadcrumbs={false} />
          )}
          <Breadcrumbs />
        </Fragment>
      </BreadcrumbsContext.Provider>

      <EuiPageSection style={{ height: '90vh' }}>{children}</EuiPageSection>
    </Fragment>
  )
}

export default HttpErrorPage
