/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { IdpConfiguration, IdpResponse } from '@modules/security-idp-api/types'
import { useOrganizationIdpMutation } from '@modules/security-idp-lib/hooks'
import { addToast } from '@modules/cui/Toasts'

const EditUserAuthenticationHeader = ({
  organizationId,
  idpConfiguration,
  hasUpdateOrganizationIdp,
}: {
  idpConfiguration: IdpConfiguration | undefined
  organizationId: string
  hasUpdateOrganizationIdp: boolean
}) => {
  const { mutate: updateOrganizationIdp, isLoading } = useOrganizationIdpMutation()
  const isSsoEnabled = idpConfiguration?.enabled
  const [isConfirmDisableModalVisible, setIsConfirmDisableModalVisible] = React.useState(false)

  const toggleSamlSso = () => {
    if (isSsoEnabled && !isConfirmDisableModalVisible) {
      setIsConfirmDisableModalVisible(true)
      return
    }

    if (!idpConfiguration) {
      return
    }

    updateOrganizationIdp(
      {
        organizationId,
        idpConfiguration: {
          ...idpConfiguration,
          enabled: !idpConfiguration.enabled,
        },
      },
      {
        onSuccess: (idpResponse: IdpResponse) => {
          const ssoEnabled = idpResponse.configuration.enabled
          addToast({
            family: 'organization.security.edit-user-authentication.toggling-sso-toast',
            color: ssoEnabled ? 'success' : 'danger',
            iconType: 'check',
            title: (
              <FormattedMessage
                id='organization.security.edit-user-authentication.toggling-sso-toast'
                defaultMessage='SAML SSO has been {enabled, plural, one {enabled} other {disabled}}.'
                values={{ enabled: ssoEnabled ? 1 : 0 }}
              />
            ),
          })
        },
        onError: () => {
          addToast({
            family: 'organization.security.edit-user-authentication.toggling-sso-toast',
            color: 'danger',
            iconType: 'alert',
            title: (
              <FormattedMessage
                id='organization.security.edit-user-authentication.toggling-sso-toast-error'
                defaultMessage='An unknown error has occurred'
              />
            ),
          })
        },
        onSettled: () => {
          setIsConfirmDisableModalVisible(false)
        },
      },
    )
  }

  const isSsoRegistered = idpConfiguration?.enabled !== undefined

  return (
    <div style={{ width: '100%' }}>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiTitle size='l'>
          <h2>
            <FormattedMessage
              id='organization.security.edit-user-authentication.title'
              defaultMessage='User authentication'
            />
          </h2>
        </EuiTitle>
        {isSsoRegistered && (
          <EuiButton
            color={isSsoEnabled ? 'danger' : 'primary'}
            onClick={toggleSamlSso}
            isLoading={isLoading}
            disabled={!hasUpdateOrganizationIdp}
          >
            {isSsoEnabled ? (
              <FormattedMessage
                id='organization.security.edit-user-authentication.disable-sso-button'
                defaultMessage='Disable SAML SSO'
              />
            ) : (
              <FormattedMessage
                id='organization.security.edit-user-authentication.enable-sso-button'
                defaultMessage='Enable SAML SSO'
              />
            )}
          </EuiButton>
        )}
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiText>
        <FormattedMessage
          id='organization.security.edit-user-authentication.description'
          defaultMessage='Define how users can access this Elastic Cloud organization.'
        />
      </EuiText>
      {isConfirmDisableModalVisible && (
        <ConfirmationModal
          onCancel={() => setIsConfirmDisableModalVisible(false)}
          onConfirm={toggleSamlSso}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

const ConfirmationModal = ({
  onCancel,
  onConfirm,
  isLoading,
}: {
  onCancel: () => void
  onConfirm: () => void
  isLoading: boolean
}) => (
  <EuiConfirmModal
    style={{ width: '600px' }}
    isLoading={isLoading}
    confirmButtonText={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-modal.confirm-button'
        defaultMessage='Disable SAML SSO'
      />
    }
    cancelButtonText={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-modal.cancel-button'
        defaultMessage='Cancel'
      />
    }
    title={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-modal.title'
        defaultMessage='Disable SAML SSO?'
      />
    }
    onCancel={onCancel}
    onConfirm={onConfirm}
    buttonColor='danger'
  >
    <EuiText>
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-modal.description'
        defaultMessage='Your users will no longer be able to log in to this organization using <b>SSO</b>. Instead, they will have to use their regular email, Google, or Microsoft accounts, and you will have to manage their roles individually. {br}{br} You can turn on SAML SSO authentication again at any time.'
        values={{
          b: (msg) => <strong>{msg}</strong>,
          br: <br />,
        }}
      />
    </EuiText>
  </EuiConfirmModal>
)

export default EditUserAuthenticationHeader
