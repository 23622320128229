/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import type { SaasUserProfile, SaasUserRules, SaasAuthResponse } from '@modules/cloud-api/v1/types'
import type { AccountUI, AsyncRequestState, UserProfile } from '@modules/ui-types'

import {
  updateAccountPasswordRequest,
  updateAccountDetailsRequest,
  fetchAccountDetailsRequest,
  getAccountUI,
  getAccountDetails,
} from '@/apps/userconsole/reducers/account'
import { getMfaEnabled, getMfaDevices } from '@/apps/userconsole/reducers/mfa'
import {
  enableMfaDeviceRequest,
  disableMfaDeviceRequest,
} from '@/apps/userconsole/reducers/registry'
import { getMfa } from '@/reducers/auth'
import {
  getVerifySaasCurrentUserMfaFactorRequest,
  fetchMfaDevicesRequest,
} from '@/reducers/asyncRequests/registry'

import {
  challengeSaasCurrentUserMfaFactor,
  verifySaasCurrentUserMfaFactor,
  resetVerifySaasCurrentUserMfaFactorRequest,
} from '../../../../actions/auth'
import {
  fetchAccountDetailsIfNeeded,
  resetUpdateAccountDetailsRequest,
  resetUpdateAccountPasswordRequest,
  updateAccountDetails,
  updateAccountPassword,
} from '../../../../apps/userconsole/actions/account'
import {
  disableMfa,
  enableMfa,
  fetchMfaDevices,
  fetchMfaStatus,
} from '../../../../apps/userconsole/actions/mfa'

import UserAccountSettings from './UserAccountSettings'

import type { ThunkDispatch, MfaState, AsyncAction } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'
import type { MfaDevice } from '@/apps/userconsole/reducers/mfa/types'

interface StateProps {
  accountDetails: UserProfile
  ui: AccountUI
  fetchAccountDetailsRequest: AsyncRequestState
  updateAccountDetailsRequest: AsyncRequestState
  updateAccountPasswordRequest: AsyncRequestState
  mfa: MfaState
  mfaEnabled: boolean | null
  mfaDevices: MfaDevice[] | null
  fetchMfaDevicesRequest: AsyncRequestState
  disableMfaDeviceRequest: AsyncRequestState
  enableMfaDeviceRequest: AsyncRequestState
  verifySaasCurrentUserMfaFactorRequest: AsyncRequestState
}

interface DispatchProps {
  fetchAccountDetailsIfNeeded: () => Promise<any>
  updateAccountDetails: (accountDetails: SaasUserProfile & SaasUserRules) => void
  updateAccountPassword: (data: any) => void
  fetchMfaStatus: () => Promise<unknown>
  fetchMfaDevices: () => Promise<unknown>
  resetUpdateAccountDetailsRequest: () => void
  resetUpdateAccountPasswordRequest: () => void
  enableMfa: () => Promise<unknown>
  disableMfa: () => Promise<unknown>
  challengeSaasCurrentUserMfaFactor: (args: {
    device_id: string
  }) => Promise<AsyncAction<'CHALLENGE_CURRENT_USER_MFA_FACTOR', unknown>>
  verifySaasCurrentUserMfaFactor: (args: {
    device_id: string
    pass_code: string
  }) => Promise<AsyncAction<'VERIFY_CURRENT_USER_MFA_FACTOR', SaasAuthResponse>>
  resetVerifySaasCurrentUserMfaFactorRequest: () => void
}

type ConsumerProps = RouteComponentProps

const mapStateToProps = (state): StateProps => ({
  accountDetails: getAccountDetails(state)!,
  ui: getAccountUI(state),
  fetchAccountDetailsRequest: fetchAccountDetailsRequest(state),
  updateAccountDetailsRequest: updateAccountDetailsRequest(state),
  updateAccountPasswordRequest: updateAccountPasswordRequest(state),
  mfaEnabled: getMfaEnabled(state),
  mfaDevices: getMfaDevices(state),
  fetchMfaDevicesRequest: fetchMfaDevicesRequest(state),
  disableMfaDeviceRequest: disableMfaDeviceRequest(state),
  enableMfaDeviceRequest: enableMfaDeviceRequest(state),
  mfa: getMfa(state),
  verifySaasCurrentUserMfaFactorRequest: getVerifySaasCurrentUserMfaFactorRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchAccountDetailsIfNeeded: () => dispatch(fetchAccountDetailsIfNeeded()),
  fetchMfaStatus: () => dispatch(fetchMfaStatus()),
  fetchMfaDevices: () => dispatch(fetchMfaDevices()),
  resetUpdateAccountDetailsRequest: () => dispatch(resetUpdateAccountDetailsRequest()),
  resetUpdateAccountPasswordRequest: () => dispatch(resetUpdateAccountPasswordRequest()),
  updateAccountDetails: (accountDetails) => dispatch(updateAccountDetails(accountDetails)),
  updateAccountPassword: (data) => dispatch(updateAccountPassword(data)),
  enableMfa: () => dispatch(enableMfa()),
  disableMfa: () => dispatch(disableMfa()),
  resetVerifySaasCurrentUserMfaFactorRequest: () =>
    dispatch(resetVerifySaasCurrentUserMfaFactorRequest()),
  challengeSaasCurrentUserMfaFactor: ({ device_id }) =>
    dispatch(challengeSaasCurrentUserMfaFactor({ device_id })),
  verifySaasCurrentUserMfaFactor: ({ device_id, pass_code }) =>
    dispatch(verifySaasCurrentUserMfaFactor({ device_id, pass_code })),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(UserAccountSettings),
)
