/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ByokConfigurationParameters } from '@modules/cloud-api/v1/types'

import { BYOK_TOGGLE_CHANGED, GET_BYOK_CONFIGURATION_PARAMETER } from '../../constants/actions'

import type { Action, AsyncAction, ReduxState } from '@/types/redux'

type ByokToggleChanged =
  | Action<typeof BYOK_TOGGLE_CHANGED>
  | AsyncAction<typeof GET_BYOK_CONFIGURATION_PARAMETER, ByokConfigurationParameters>

const applicationId = `applicationId`
const serviceAccountEmail = `serviceAccountEmail`
const cloudStorageServiceAgentEmail = `cloudStorageServiceAgentEmail`

export interface State {
  isEnabled: boolean
  applicationId: string
  serviceAccountEmail: string
  cloudStorageServiceAgentEmail: string
}

const initialState = {
  isEnabled: false,
  applicationId: ``,
  serviceAccountEmail: ``,
  cloudStorageServiceAgentEmail: ``,
}

export default function byokToggleReducer(
  state: State = initialState,
  action: ByokToggleChanged,
): State {
  if (action.type === BYOK_TOGGLE_CHANGED) {
    return {
      ...state,
      isEnabled: action.meta.newValue,
    }
  }

  if (action.type === GET_BYOK_CONFIGURATION_PARAMETER) {
    if (action.payload && action.payload.parameters && action.payload.parameters.length > 0) {
      // the API always returns an array of parameters, even if there's only one and we can assume the first one is what we're grabbing
      for (const parameter of action.payload.parameters) {
        if (parameter.name === applicationId) {
          state.applicationId = parameter.value
        }

        if (parameter.name === serviceAccountEmail) {
          state.serviceAccountEmail = parameter.value
        }

        if (parameter.name === cloudStorageServiceAgentEmail) {
          state.cloudStorageServiceAgentEmail = parameter.value
        }
      }
    }
  }

  return state
}

export const isByokToggleEnabled = (state: ReduxState) => state.byokToggle.isEnabled
export const byokApplicationId = (state: ReduxState) => state.byokToggle.applicationId
export const byokServiceAccountEmail = (state: ReduxState) => state.byokToggle.serviceAccountEmail
export const byokCloudStorageServiceAgentEmail = (state: ReduxState) =>
  state.byokToggle.cloudStorageServiceAgentEmail
