/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiComboBox, EuiFormRow } from '@elastic/eui'

import { ProductType } from '../../../types'

import type { FunctionComponent } from 'react'
import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

const ProductTypeSelect: FunctionComponent<Props & WrappedComponentProps> = ({
  onChange,
  isLoading,
  intl: { formatMessage },
  values,
}) => {
  const options = [
    {
      label: formatMessage({
        id: 'billing-usage.filters.type.deployments',
        defaultMessage: 'Hosted deployments',
      }),
      value: ProductType.Deployments,
    },
    {
      label: formatMessage({
        id: 'billing-usage.filters.type.projects',
        defaultMessage: 'Serverless projects',
      }),
      value: ProductType.Projects,
    },
  ]

  return (
    <EuiFormRow
      fullWidth={true}
      label={formatMessage({
        id: 'billing-usage.filters.type',
        defaultMessage: 'Instances',
      })}
    >
      <EuiComboBox
        aria-label={formatMessage({
          id: 'billing-usage.filters.type',
          defaultMessage: 'Instances',
        })}
        options={options}
        selectedOptions={options.filter(({ value }) => values.includes(value))}
        onChange={(productTypes) =>
          onChange({ productTypes: productTypes.map(({ value }) => value) as ProductType[] })
        }
        isClearable={false}
        isDisabled={isLoading}
        fullWidth={true}
      />
    </EuiFormRow>
  )
}

export default injectIntl(ProductTypeSelect)
