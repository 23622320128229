/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { getEmptyRoleAssignments } from '@modules/role-assignments-lib'
import type { OrganizationRoleId } from '@modules/role-assignments-lib/types'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useConfig } from '@modules/cui/ConfigContext'

import RoleBadge from './RoleBadge'
import { getRoleBadgeInfoList } from './lib'

import type { FunctionComponent } from 'react'

export type Props = {
  roleAssignments: RoleAssignments | undefined
}

const RoleBadges: FunctionComponent<Props> = ({ roleAssignments = getEmptyRoleAssignments() }) => {
  const [isFlagReady, flags] = useFlagsWhenLoaded()
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const customRolesEnabled = isAdminConsole || (isFlagReady && flags.serverlessCustomRoles)

  const organizationRoleAssignments =
    roleAssignments.organization?.map(({ role_id }) => role_id as OrganizationRoleId) ?? []

  const roleList = getRoleBadgeInfoList(roleAssignments, customRolesEnabled)

  return (
    <EuiFlexGroup wrap={true} gutterSize='xs' responsive={false}>
      {organizationRoleAssignments.map((roleId) => (
        <EuiFlexItem grow={false} key={roleId}>
          <RoleBadge
            roleBadgeInfo={{
              roleLabel: roleId,
            }}
          />
        </EuiFlexItem>
      ))}

      {roleList.map((roleBadgeInfo, index) => (
        <EuiFlexItem grow={false} key={`specific-badge-${index}`}>
          <RoleBadge roleBadgeInfo={roleBadgeInfo} />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}

export default RoleBadges
