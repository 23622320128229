/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { parse } from 'query-string'
import { includes } from 'lodash'

import { EuiLoadingSpinner, EuiSpacer, EuiText, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { CuiRouterLinkButtonEmpty } from '@modules/cui/RouterButton'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import MfaForm from '@modules/mfa-login/MfaForm'
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'

import { marketplaceConversionUrl } from '@/lib/urlBuilder'
import { isRequireActivationError } from '@/components/CreateAccountForm/lib'

import UsernameAndPasswordForm from './UsernameAndPasswordForm'

import type { AllProps } from './types'

export default class PasswordBasedLogin extends Component<AllProps> {
  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderCheckingAuthMethods()}
        {this.renderSwitchMethod()}
      </div>
    )
  }

  renderSwitchMethod() {
    const { canSwitchMethod, location } = this.props

    if (!canSwitchMethod) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer />
        <div style={{ textAlign: `center` }}>
          <CuiRouterLinkButtonEmpty
            data-test-id='switch-to-sso'
            to={{ pathname: '/login/sso', search: location.search }}
          >
            <EuiText size='s'>
              <FormattedMessage
                id='log-in.switch-to-sso'
                defaultMessage='Switch to Single Sign-On'
              />
            </EuiText>
          </CuiRouterLinkButtonEmpty>
        </div>
      </Fragment>
    )
  }

  renderCheckingAuthMethods() {
    const { isCheckingAuthMethods } = this.props

    if (!isCheckingAuthMethods) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer />
        <div data-test-id='checking-for-sso'>
          <EuiFlexGroup gutterSize='s' justifyContent='center'>
            <EuiFlexItem grow={false}>
              <EuiText size='s'>
                <FormattedMessage
                  id='log-in.checking-for-sso'
                  defaultMessage='Checking whether SSO is available'
                />
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size='m' />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </Fragment>
    )
  }

  renderForm() {
    const {
      loginRequest,
      registrationButtons,
      mfa,
      resetAuthorizeSaasOauthTokenRequest,
      authorizeSaasOauthTokenRequest,
      username,
      openIdRedirectUrl,
      registerUrl,
      isGovCloud,
      location,
      isFlowV2,
      onMfaSuccess,
    } = this.props

    if (mfa && mfa.mfa_required) {
      return (
        <PrivacySensitiveContainer>
          <MfaForm
            devices={mfa.mfa_devices as SaasAuthMfaDeviceResponse[]}
            stateId={mfa.state_id!}
            isFlowV2={isFlowV2}
            onSuccess={(data) => {
              onMfaSuccess({
                ...data,
                ...this.getRedirectUrl(),
              })
            }}
          />
        </PrivacySensitiveContainer>
      )
    }

    return (
      <UsernameAndPasswordForm
        username={username}
        isGovCloud={isGovCloud}
        loginRequest={loginRequest}
        registrationButtons={registrationButtons}
        onSubmit={this.login}
        openIdRedirectUrl={openIdRedirectUrl}
        registerUrl={registerUrl}
        resetAuthorizeSaasOauthTokenRequest={resetAuthorizeSaasOauthTokenRequest}
        authorizeSaasOauthTokenRequest={authorizeSaasOauthTokenRequest}
        location={location}
        isFlowV2={isFlowV2}
      />
    )
  }

  getRedirectUrl(): { oktaRedirectUrl?: string; redirectTo?: string } {
    const { location, openIdRedirectUrl } = this.props
    const query = parse(location.search.slice(1))
    const { fromURI, redirectTo: redirectRaw } = query
    const { state } = location

    if (state?.hasExistingSubscription || includes(fromURI, marketplaceConversionUrl())) {
      return { oktaRedirectUrl: `${fromURI}&hasSubscription=true` }
    }

    const oktaRedirectUrl = typeof fromURI === `string` ? fromURI : undefined
    const redirectFromLocation = typeof redirectRaw === `string` ? redirectRaw : undefined
    const redirectTo = openIdRedirectUrl ?? redirectFromLocation

    return { oktaRedirectUrl, redirectTo }
  }

  login = (credentials: { username: string; password: string }) => {
    const { loginAndRedirect, onUnverifiedUser } = this.props

    const params = {
      ...this.getRedirectUrl(),
      credentials,
    }

    loginAndRedirect(params)
      .then((res) => {
        if (res?.isUnverifiedUser) {
          onUnverifiedUser?.(credentials.username)
        }
      })
      .catch((error) => {
        const needsActivation = isRequireActivationError(error)

        if (needsActivation) {
          onUnverifiedUser?.(credentials.username)
        }
      })
  }
}
