/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiContextMenuPanelProps } from '@elastic/eui'
import { EuiButton, EuiContextMenuPanel, EuiPopover } from '@elastic/eui'

import genericMessages from '@modules/project-lib/genericMessages'
import type { AnyUserProject } from '@modules/project-user-api/types'

import DeleteProjectButton from './DeleteProjectButton'
import ResumeProjectButton from './ResumeProjectButton'

export type Props = {
  project: AnyUserProject
}

const UserProjectActionsButton: React.FunctionComponent<Props> = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false)

  const items: EuiContextMenuPanelProps['items'] = []

  if (project.metadata.suspended_at) {
    items.push(
      <ResumeProjectButton key='resume' project={project} onComplete={() => setIsOpen(false)} />,
    )
  }

  items.push(
    <DeleteProjectButton key='delete' project={project} onComplete={() => setIsOpen(false)} />,
  )

  return (
    <EuiPopover
      button={
        <EuiButton
          fill={false}
          iconType='arrowDown'
          iconSide='right'
          onClick={() => setIsOpen(!isOpen)}
        >
          <FormattedMessage {...genericMessages.actions} />
        </EuiButton>
      }
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize='none'
    >
      <EuiContextMenuPanel items={items} />
    </EuiPopover>
  )
}

export default UserProjectActionsButton
