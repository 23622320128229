/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { doesSaasEntityHaveExpiredTrial } from '@modules/profile-lib/trial'
import { useProjectsMetadata } from '@modules/project-user-components/SupplyProjectsMetadata'
import { isMarketplaceUser, isUnsubscribedMarketplaceUser } from '@modules/profile-lib/marketplaces'
import { useSaasUser } from '@modules/profile-lib/hooks'
import { useGetEntitlementsV1 } from '@modules/billing-lib/hooks'

export const useCreateProjectButtonProps = (): {
  isDisabled: boolean
  inActiveTrial: boolean
  canCreateProject: boolean
} => {
  const saasUser = useSaasUser()
  const { projectsLength, isReady } = useProjectsMetadata()

  const organizationId = saasUser?.user.organization_id
  const { data: entitlements } = useGetEntitlementsV1(organizationId, {
    enabled: Boolean(organizationId),
  })

  if (!saasUser || !isReady || !entitlements) {
    return {
      isDisabled: true,
      inActiveTrial: false,
      canCreateProject: false,
    }
  }

  const { is_trial: isTrial } = saasUser.subscription
  const hasExpiredTrial = doesSaasEntityHaveExpiredTrial(saasUser)
  const hasProjects = Boolean(projectsLength)

  if (isTrial) {
    return {
      isDisabled: false,
      inActiveTrial: !hasExpiredTrial,
      canCreateProject: !hasExpiredTrial && !hasProjects,
    }
  }

  if (isMarketplaceUser(saasUser)) {
    return {
      isDisabled: isUnsubscribedMarketplaceUser(saasUser),
      inActiveTrial: false,
      canCreateProject: !isUnsubscribedMarketplaceUser(saasUser),
    }
  }

  return {
    isDisabled: false,
    inActiveTrial: false,
    canCreateProject: entitlements.resources_allowed === 'any',
  }
}
