/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ScriptLoader from 'react-script-loader-hoc'

import { EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'

const VIDYARD_EMBED_JS_URL = 'https://play.vidyard.com/embed/v4.js'

type VidyardPlayerProps = {
  scriptsLoadedSuccessfully: boolean
  maxWidth: string
  maxHeight: string
  type: string
  uuid: string
  aspect?: '4:3' | '16:9' | '16:10'
  muted?: boolean
  autoplay?: boolean
}

function VidyardPlayer({
  scriptsLoadedSuccessfully,
  maxWidth,
  maxHeight,
  type,
  uuid,
  aspect,
  muted = false,
  autoplay = false,
}: VidyardPlayerProps) {
  const containerRef = React.useRef<any>()

  React.useEffect(() => {
    // @ts-ignore
    if (scriptsLoadedSuccessfully && window.VidyardV4) {
      // @ts-ignore
      window.VidyardV4.api
        .renderPlayer({
          aspect,
          container: containerRef.current,
          height: maxHeight,
          type,
          uuid,
          width: maxWidth,
          muted: muted ? 1 : 0,
          cc: null,
          autoplay: autoplay ? 1 : 0,
        })
        .catch((e: any) => {
          console.error(e.message)
          return (
            <CuiAlert type='danger' data-test-id='vidyard-player-error'>
              <FormattedMessage
                id='vidyard-player-error'
                defaultMessage='Oops, looks like something went wrong.'
              />
            </CuiAlert>
          )
        })
    }
  }, [scriptsLoadedSuccessfully])

  if (!scriptsLoadedSuccessfully) {
    return (
      <EuiFlexItem style={{ alignItems: 'center' }}>
        <EuiLoadingSpinner size='xl' />
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexItem grow={false}>
      <div ref={containerRef} />
    </EuiFlexItem>
  )
}

export default ScriptLoader(VIDYARD_EMBED_JS_URL)(VidyardPlayer)
