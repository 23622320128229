/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, type SerializedStyles } from '@emotion/react'

import type { EuiPanelProps } from '@elastic/eui'
import { EuiPanel, EuiSpacer, EuiTitle, EuiText } from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'

import Header from './Header'

import type { ReactNode, FunctionComponent } from 'react'
import type { LandingPageContext } from '@/components/LandingPageContainer/types'

import './landingPageContainer.scss'

interface Props {
  children?: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  image?: string
  darkImage?: string
  panelProps?: EuiPanelProps
  pageContext?: LandingPageContext
  onPageContextChange?: (type: LandingPageContext) => void
  isLoading?: boolean
  infoMessage?: ReactNode
  hideHeader?: boolean
  imageCssOverride?: SerializedStyles
}

const LandingPageContainer: FunctionComponent<Props> = ({
  children,
  title,
  subtitle,
  image,
  darkImage,
  isLoading,
  pageContext,
  onPageContextChange,
  panelProps,
  infoMessage,
  hideHeader = false,
  imageCssOverride,
}) => {
  const defaultImageCss = {
    margin: '0 auto',
    display: 'block',
    width: '200px',
    height: '180px',
  }

  return (
    <div className='cloud-landing-page'>
      {!hideHeader && !isLoading && (
        <Header pageContext={pageContext} onPageContextSwitch={onPageContextChange} />
      )}

      <EuiSpacer size='xl' className='spacer-before-content' />

      <div className='cloud-landing-page-content'>
        <EuiPanel
          className='cloud-landing-page-panel cloud-landing-page-form-panel cloud-landing-page-panel-narrow'
          hasShadow={true}
          {...panelProps}
        >
          {image && (
            <Fragment>
              <CuiThemedIcon
                size='original'
                lightType={image}
                darkType={darkImage ? darkImage : image}
                css={[defaultImageCss, imageCssOverride]}
              />

              <EuiSpacer size='l' />
            </Fragment>
          )}

          {title && (
            <EuiTitle className='cloud-landing-page-form-panel-title' size='m'>
              <h1>{title}</h1>
            </EuiTitle>
          )}

          {subtitle && (
            <Fragment>
              <EuiSpacer size='m' />

              <EuiText size='m' textAlign='center' color='subdued'>
                {subtitle}
              </EuiText>
            </Fragment>
          )}

          <EuiSpacer size='xl' />

          {children}
        </EuiPanel>
        {infoMessage}
      </div>
    </div>
  )
}

export default LandingPageContainer
