/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router'

import { EuiEmptyPrompt, EuiLoadingSpinner, EuiPageTemplate } from '@elastic/eui'

import Breadcrumbs from '@modules/cui/Breadcrumbs'
import { useGetRoleMappingsQuery } from '@modules/security-idp-lib/hooks'

import { organizationRoleMappingCrumbs } from '@/lib/crumbBuilder'
import { organizationRoleMappingUrl } from '@/apps/userconsole/urls'

import { editPageSectionStyle } from '../styles'

import RoleMappingBody from './RoleMappingBody'
import RoleMappingHeader from './RoleMappingHeader'

import type { Props } from './types'

const breadcrumbs = [...organizationRoleMappingCrumbs()]
const bodyStyle = { ...editPageSectionStyle, maxWidth: '1200px' }

const CreateOrEditRoleMapping = (props: Props) => {
  const {
    organizationId,
    permissions: { isLoading: isLoadingPermissions },
  } = props

  const { roleMappingName: roleMappingNameParam } = useParams<{ roleMappingName: string }>()
  const history = useHistory()

  const { data: roleMappings, isLoading: isLoadingRoleMappings } =
    useGetRoleMappingsQuery(organizationId)
  const isLoading = isLoadingPermissions || isLoadingRoleMappings
  const matchingRoleMapping = roleMappings?.mappings.find((rm) => rm.name === roleMappingNameParam)

  if (!isLoading && roleMappingNameParam && !matchingRoleMapping) {
    history.push(organizationRoleMappingUrl())
  }

  return (
    <EuiPageTemplate panelled={true} bottomBorder={'extended'}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <div css={bodyStyle}>
        <EuiPageTemplate.Header>
          <RoleMappingHeader isEditMode={!!roleMappingNameParam} />
        </EuiPageTemplate.Header>
        <EuiPageTemplate.Section>
          {isLoading ? (
            <EuiEmptyPrompt
              icon={<EuiLoadingSpinner size='l' />}
              title={
                <FormattedMessage
                  id='organization.security.edit-role-mapping.loading'
                  defaultMessage='Loading role mapping'
                />
              }
              color='subdued'
            />
          ) : (
            <RoleMappingBody {...props} originalRoleMapping={matchingRoleMapping} />
          )}
        </EuiPageTemplate.Section>
      </div>
    </EuiPageTemplate>
  )
}

export default CreateOrEditRoleMapping
