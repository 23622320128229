/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import InstanceConfigurationVersionChange from '../ExplainChanges/InstanceConfigurationVersionChange'
import InstanceConfigurationIdChange from '../ExplainChanges/InstanceConfigurationIdChange'

import type { Difference } from '../../../../lib/stackDeployments/planDiffs/types'
import type { DifferenceFormatter } from '../types'

export const instanceConfigurationVersionChangeFormatter: DifferenceFormatter = {
  handles: `ic-version-changed`,
  formatter: ({ difference }) => {
    const { target, meta } = difference as Difference<{
      currentVersion: string
      nextVersion: string
      icId: string
      tierId: string
    }>
    const { currentVersion, nextVersion, icId, tierId } = meta!
    return {
      id: `ic-version-changed`,
      type: target,
      testParams: [currentVersion, nextVersion, icId, tierId],
      message: (
        <InstanceConfigurationVersionChange
          oldVersion={currentVersion}
          currentVersion={nextVersion}
          icId={icId}
          tierId={tierId}
        />
      ),
    }
  },
}

export const instanceConfigurationIdChangeFormatter: DifferenceFormatter = {
  handles: `ic-id-changed`,
  formatter: ({ difference }) => {
    const { target, meta } = difference as Difference<{
      currentId: string
      nextId: string
      tierId: string
    }>
    const { currentId, nextId, tierId } = meta!
    return {
      id: `ic-id-changed`,
      type: target,
      testParams: [currentId, nextId, tierId],
      message: (
        <InstanceConfigurationIdChange oldId={currentId} currentId={nextId} tierId={tierId} />
      ),
    }
  },
}
