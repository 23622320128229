/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { jsx } from '@emotion/react'

import { EuiFlexGroup, EuiFlexItem, EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import UserProjectRoleAssignmentsEditor from '@modules/role-assignments-user-components/ProjectRoleAssignmentsEditor'
import AdminProjectRoleAssignmentsEditor from '@modules/role-assignments-admin-components/ProjectRoleAssignmentsEditor'
import {
  deleteResourcesRoleAssignments,
  isResourcesRoleAssignmentsEmpty,
} from '@modules/role-assignments-lib'
import type { RoleScopeType } from '@modules/role-assignments-lib/types'

import RolePanel from './RolePanel'
import {
  toggleOrganizationBillingRoleAssignment,
  toggleOrganizationOwnerRoleAssignment,
  isOrganizationRoleSelected,
} from './lib'

import type { ReactNode } from 'react'
import type { AllProps as Props, State } from './types'

class RoleAssignmentsPanel extends Component<Props, State> {
  state: State = {
    isInstanceAccessSelected: !isResourcesRoleAssignmentsEmpty(this.props.roleAssignments),
  }

  render() {
    const { roleScopeTypes } = this.props

    if (roleScopeTypes === null) {
      return <EuiSkeletonText lines={8} />
    }

    return (
      <EuiFlexGroup direction='column' gutterSize='m'>
        {roleScopeTypes.has('organization') && (
          <Fragment>
            <EuiFlexItem>{this.renderOrganizationOwnerPanel()}</EuiFlexItem>
            <EuiFlexItem>{this.renderOrganizationBillingPanel()}</EuiFlexItem>
          </Fragment>
        )}

        <EuiFlexItem>{this.renderInstanceAccessPanel(roleScopeTypes)}</EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderOrganizationOwnerPanel(): JSX.Element {
    return (
      <RolePanel
        title={
          <FormattedMessage
            id='roles-assignments.organization-owner'
            defaultMessage='Organization owner'
          />
        }
        description={
          <FormattedMessage
            id='roles-assignments.organization-owner-description'
            defaultMessage='Highest role within the organization. Can manage members, access all features and all deployments.'
          />
        }
        isChecked={isOrganizationRoleSelected(this.props.roleAssignments, 'organization-admin')}
        isDisabled={false}
        testId='organization-owner-switch'
        onChangeRole={this.onSwitchOrganizationOwner}
      />
    )
  }

  renderOrganizationBillingPanel(): JSX.Element {
    return (
      <RolePanel
        title={
          <FormattedMessage
            id='roles-assignments.organization-billing'
            defaultMessage='Billing admin'
          />
        }
        description={
          <FormattedMessage
            id='roles-assignments.organization-billing-description'
            defaultMessage='Access to all invoices and payment methods. Can make subscription changes.'
          />
        }
        isChecked={
          isOrganizationRoleSelected(this.props.roleAssignments, 'billing-admin') ||
          isOrganizationRoleSelected(this.props.roleAssignments, 'organization-admin')
        }
        isDisabled={isOrganizationRoleSelected(this.props.roleAssignments, 'organization-admin')}
        testId='organization-billing-switch'
        onChangeRole={this.onSwitchOrganizationBilling}
      />
    )
  }

  renderInstanceAccessPanel(roleScopeTypes: Set<RoleScopeType>): ReactNode {
    if (
      !roleScopeTypes.has('deployment') &&
      !roleScopeTypes.has('project-elasticsearch') &&
      !roleScopeTypes.has('project-observability') &&
      !roleScopeTypes.has('project-security')
    ) {
      return null
    }

    const { isInstanceAccessSelected } = this.state

    const isOrganizationOwnerSelected = isOrganizationRoleSelected(
      this.props.roleAssignments,
      'organization-admin',
    )

    return (
      <RolePanel
        title={
          <FormattedMessage
            id='roles-assignments.instance-access'
            defaultMessage='Instance access'
          />
        }
        description={
          <FormattedMessage
            id='roles-assignments.instance-access-description'
            defaultMessage='Grant access to one or more instances.'
          />
        }
        isChecked={isOrganizationOwnerSelected || isInstanceAccessSelected}
        isDisabled={isOrganizationOwnerSelected}
        testId='instance-access'
        onChangeRole={this.onSwitchInstanceAccess}
      >
        {!isOrganizationOwnerSelected && isInstanceAccessSelected && (
          <Fragment>
            <EuiSpacer size='xl' />
            {roleScopeTypes.has('platform') ? (
              <AdminProjectRoleAssignmentsEditor
                organizationId={this.props.organizationId}
                roleAssignments={this.props.roleAssignments}
                onChangeRoleAssignments={this.props.onChangeRoleAssignments}
              />
            ) : (
              <UserProjectRoleAssignmentsEditor
                organizationId={this.props.organizationId}
                roleAssignments={this.props.roleAssignments}
                onChangeRoleAssignments={this.props.onChangeRoleAssignments}
              />
            )}
          </Fragment>
        )}
      </RolePanel>
    )
  }

  onSwitchOrganizationOwner = (checked: boolean): void => {
    this.setState((prevState) => {
      if (checked) {
        return {
          isInstanceAccessSelected: false,
        }
      }

      return prevState
    })

    const roleAssignments = toggleOrganizationOwnerRoleAssignment(
      this.props.roleAssignments,
      this.props.organizationId,
    )

    this.props.onChangeRoleAssignments(roleAssignments)
  }

  onSwitchOrganizationBilling = (): void => {
    const roleAssignments = toggleOrganizationBillingRoleAssignment(
      this.props.roleAssignments,
      this.props.organizationId,
    )

    this.props.onChangeRoleAssignments(roleAssignments)
  }

  onSwitchInstanceAccess = (): void => {
    this.setState(
      (prevState) => ({
        isInstanceAccessSelected: !prevState.isInstanceAccessSelected,
      }),
      () => {
        if (!this.state.isInstanceAccessSelected) {
          const roleAssignments = deleteResourcesRoleAssignments(this.props.roleAssignments)
          this.props.onChangeRoleAssignments(roleAssignments)
        }
      },
    )
  }
}

export default RoleAssignmentsPanel
