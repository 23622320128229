/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'
import { withStackDeploymentRouteParams } from '@/components/StackDeploymentEditor'
import { fetchTrustedEnvsRequest } from '@/reducers/asyncRequests/registry'
import { getTrustedEnvs } from '@/reducers/trustedEnvs'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { fetchTrustedEnvs } from '@/actions/trustManagement'

import CreateDeploymentTrustRelationship from './CreateDeploymentTrustRelationship'

import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type { StateProps, DispatchProps, ReceivedProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { stackDeploymentId }: ReceivedProps & WithStackDeploymentRouteParamsProps,
): StateProps => ({
  deployment: getStackDeployment(state, stackDeploymentId)!,
  fetchTrustedEnvsRequest: fetchTrustedEnvsRequest(state),
  trustedEnvs: getTrustedEnvs(state),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchTrustedEnvs: () => dispatch(fetchTrustedEnvs()),
})

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ReceivedProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateDeploymentTrustRelationship),
)
