/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages, useIntl } from 'react-intl'

import {
  isDefaultRetentionDaysWithinRange,
  isMaximumRetentionDaysWithinRange,
  MAX_DEFAULT_RETENTION_DAYS,
  MAX_MAXIMUM_RETENTION_DAYS,
  MIN_DEFAULT_RETENTION_DAYS,
  MIN_MAXIMUM_RETENTION_DAYS,
} from '@modules/project-lib/dataRetention'

const messages = defineMessages({
  outOfBoundsRetentionError: {
    id: 'project.dataRetention.retentionPeriodOutOfBoundsError',
    defaultMessage: 'Please enter a value between {min} and {max}',
  },
  defaultGreaterThanMaxError: {
    id: 'project.dataRetention.defaultGreaterThanMaxError',
    defaultMessage:
      'Default data retention period cannot be longer than maximum data retention period',
  },
  defaultMustBeOnWhenMaxIsOnError: {
    id: 'project.dataRetention.defaultMustBeOnWhenMaxIsOnError',
    defaultMessage: 'Default data retention period must be enabled when maximum is enabled',
  },
})

export function useDataRetentionValidationErrors(
  defaultRetentionDays: number,
  maximumRetentionDays: number,
) {
  const { formatMessage } = useIntl()

  function getDefaultDataRetentionValidationError(): string | null {
    if (defaultRetentionDays === Infinity && maximumRetentionDays === Infinity) {
      return null
    }

    if (!isDefaultRetentionDaysWithinRange(defaultRetentionDays)) {
      return formatMessage(messages.outOfBoundsRetentionError, {
        min: MIN_DEFAULT_RETENTION_DAYS,
        max: MAX_DEFAULT_RETENTION_DAYS,
      })
    }

    if (defaultRetentionDays > maximumRetentionDays) {
      return formatMessage(messages.defaultGreaterThanMaxError)
    }

    return null
  }

  function getMaximumDataRetentionValidationError(): string | null {
    if (maximumRetentionDays === Infinity) {
      return null
    }

    if (isMaximumRetentionDaysWithinRange(maximumRetentionDays)) {
      return null
    }

    return formatMessage(messages.outOfBoundsRetentionError, {
      min: MIN_MAXIMUM_RETENTION_DAYS,
      max: MAX_MAXIMUM_RETENTION_DAYS,
    })
  }

  function getDataRetentionValidationError(): string | null {
    if (maximumRetentionDays !== Infinity && defaultRetentionDays === Infinity) {
      return formatMessage(messages.defaultMustBeOnWhenMaxIsOnError)
    }

    return null
  }

  return {
    getDefaultDataRetentionValidationError,
    getMaximumDataRetentionValidationError,
    getDataRetentionValidationError,
  }
}
