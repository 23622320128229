/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { NodeStats } from '@modules/ui-types'

import { FETCH_NODE_STATS } from '../constants/actions'

import type { ReduxState } from '@/types/redux'

type Action = {
  type: typeof FETCH_NODE_STATS
  meta: {
    deploymentId: string
  }
  error?: boolean
  payload?: {
    nodes: { [descriptor: string]: NodeStats }
  }
}

export interface State {
  [descriptor: string]: Record<string, NodeStats>
}

const initialState = {}

export default function nodeStatsReducer(state: State = initialState, action: Action) {
  if (action.type === FETCH_NODE_STATS) {
    if (!action.error && action.payload) {
      const { deploymentId } = action.meta
      const descriptor = createDescriptor(deploymentId)

      return {
        ...state,
        [descriptor]: action.payload.nodes,
      }
    }
  }

  return state
}

function getNodeStatsForDeployment(
  state: State,
  deploymentId: string,
): Record<string, NodeStats> | undefined {
  return state[createDescriptor(deploymentId)]
}

function _getNodeStatsForInstance(
  state: State,
  deploymentId: string,
  instanceName: string,
): NodeStats | undefined {
  const allNodeStats = getNodeStatsForDeployment(state, deploymentId)

  return allNodeStats
    ? Object.values(allNodeStats).find(({ name }) => instanceName === name)
    : undefined
}

function createDescriptor(deploymentId: string) {
  return deploymentId
}

export const getNodeStatsForInstance = (
  state: ReduxState,
  deploymentId: string,
  instanceName: string,
) => _getNodeStatsForInstance(state.nodeStats, deploymentId, instanceName)
