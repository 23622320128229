/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type {
  ResourceType,
  RoleAssignmentsTableItem,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import {
  useGetElasticsearchProjectRolesQuery,
  useGetSecurityProjectRolesQuery,
} from '@modules/project-admin-lib/hooks/get'
import type { components } from '@modules/project-user-api/v1/types'
import { CustomRoleBadge } from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary/CustomRoleBadge'

import type { FunctionComponent } from 'react'

export const AdminCustomRoleBadge: FunctionComponent<{
  item: RoleAssignmentsTableItem<ResourceType>
  roleDescriptor: TableItemRoleDescriptor
}> = ({ item, roleDescriptor }) => {
  const elasticsearchQuery = useGetElasticsearchProjectRolesQuery(item.id, {
    enabled: item.resourceType === 'elasticsearch',
  })

  const securityQuery = useGetSecurityProjectRolesQuery(item.id, {
    enabled: item.resourceType === 'security',
  })

  let data: components['schemas']['ProjectRolesByProjectID'] | undefined
  let isLoading = false

  if (item.resourceType === 'elasticsearch') {
    data = elasticsearchQuery.data
    isLoading = elasticsearchQuery.isLoading
  } else if (item.resourceType === 'security') {
    data = securityQuery.data
    isLoading = securityQuery.isLoading
  }

  if (isLoading) {
    return null
  }

  const customRoles: string[] = !data
    ? []
    : (data[item.id]?.roles ?? []).map((role: { name: string; description: string }) => role.name)

  return (
    <CustomRoleBadge
      key={item.id.concat(roleDescriptor.roleId)}
      roleDescriptor={roleDescriptor}
      customRoles={customRoles}
    />
  )
}
