/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { StrictOmit } from '@modules/ts-essentials'

import account from './account'
import { billingDetails } from './billing'
import logs from './logs'
import metrics from './metrics'
import mfa from './mfa'
import marketplace from './marketplace'
import profile from './profile'
import pricing from './pricing/basePricing'

import type { Action } from '@/types/redux'
import type { AccountState } from './account'
import type { State as PricingState } from './pricing/basePricing'
import type { State as ProfileState } from './profile'
import type { State as MarketplaceState } from './marketplace'
import type { State as MfaState } from './mfa'
import type { State as LogsState } from './logs/logTypes'
import type { BillingState } from './billing'

export interface UserconsoleState {
  account: AccountState
  billingDetails: BillingState
  logs: LogsState
  metrics: any
  mfa: MfaState
  profile: ProfileState
  pricing: PricingState
  marketplace: MarketplaceState
}

// This type just checks that for every key in the state interface above,
// the combined reducer also has a reducer key for it.
type UserconsoleReducer = {
  [T in keyof StrictOmit<UserconsoleState, 'profile'>]: (
    // the reducer function takes the current state, or undefined if we're initialising
    state: UserconsoleState[T] | undefined,

    // and the action. Maybe in the future we'll have one mega action definition.
    action: Action<any>,
  ) => UserconsoleState[T]
} & {
  profile: (state: ProfileState, action: any) => ProfileState
}

const userconsoleReducer: UserconsoleReducer = {
  account,
  billingDetails,
  logs,
  metrics,
  mfa,
  profile,
  pricing,
  marketplace,
}

export default userconsoleReducer
