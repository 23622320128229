/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import {
  EuiButtonEmpty,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiSteps,
  EuiTitle,
} from '@elastic/eui'

import { parseError } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import { getEmptyRoleAssignments, isRoleAssignmentsEmpty } from '@modules/role-assignments-lib'

import SpinButton from '@/components/SpinButton'
import RoleAssignmentsPanel from '@/components/Users/RoleAssignmentsPanel'
import validateEmail from '@/lib/validateEmail'

import type { EmailValidationError, AllProps as Props, State } from './types'
import type { ReactElement } from 'react'
import type { WrappedComponentProps } from 'react-intl'

const messages = defineMessages<EmailValidationError>({
  'malformed-email': {
    id: 'organization.invite-organization-members-flyout.malformed-email',
    defaultMessage: 'The email address is invalid.',
  },
  'member-already-exists': {
    id: 'organization.invite-organization-members-flyout.member-already-exists',
    defaultMessage: 'A member with the same email address already exists.',
  },
  'member-was-already-invited': {
    id: 'organization.invite-organization-members-flyout.member-was-already-invited',
    defaultMessage: 'A member with the same email address was already invited.',
  },
  'too-many-invites': {
    id: 'organization.invite-organization-members-flyout.too-many-invites',
    defaultMessage: 'Too many invites. You can only send up to 20 invites at a time.',
  },
})

const formLabels = defineMessages({
  enterEmail: {
    id: 'organization.invite-organization-members-flyout.email-members',
    defaultMessage: 'Provide email addresses',
  },
  assignRoles: {
    id: 'organization.invite-organization-members-flyout.assign-roles',
    defaultMessage: 'Assign roles',
  },
})

class InviteOrganizationMembersFlyout extends Component<Props & WrappedComponentProps, State> {
  state: State = this.createInitialState()

  componentWillUnmount(): void {
    const { resetOrganizationInvitationsRequest } = this.props
    const { emails } = this.state

    resetOrganizationInvitationsRequest(emails)
  }

  render(): ReactElement {
    const { children } = this.props

    return (
      <Fragment>
        {children({ openFlyout: () => this.setState({ isOpen: true }) })}
        {this.renderFlyout()}
      </Fragment>
    )
  }

  renderFlyout(): ReactElement | null {
    const {
      intl: { formatMessage },
      createOrganizationInvitationsRequest,
      organizationId,
    } = this.props

    const { emails, emailValidationError, hasSingleInvalidEmail, isOpen } = this.state

    if (!isOpen) {
      return null
    }

    const { inProgress } = createOrganizationInvitationsRequest(emails)

    const steps = [
      {
        title: formatMessage(formLabels.enterEmail),
        children: this.renderEmailField(),
      },
      {
        title: formatMessage(formLabels.assignRoles),
        children: (
          <RoleAssignmentsPanel
            organizationId={organizationId}
            roleAssignments={this.state.roleAssignments}
            onChangeRoleAssignments={(roleAssignments) =>
              this.setState({
                roleAssignments,
              })
            }
          />
        ),
      },
    ]

    return (
      <EuiFlyout maxWidth='57rem' onClose={this.onClose}>
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle>
            <h2>
              <FormattedMessage
                id='organization.invite-organization-members-flyout.invite-members'
                defaultMessage='Invite members'
                data-test-id='organization.invite-organization-members-flyout.invite-members'
              />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <EuiSteps steps={steps} />
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty onClick={this.onClose} flush='left'>
                <FormattedMessage
                  id='organization.invite-organization-members-flyout.cancel'
                  defaultMessage='Cancel'
                />
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <SpinButton
                onClick={this.onSendInvitations}
                fill={true}
                isDisabled={
                  emailValidationError !== null ||
                  hasSingleInvalidEmail ||
                  isRoleAssignmentsEmpty(this.state.roleAssignments)
                }
                spin={inProgress}
                data-test-id='organization.invite-organization-members-flyout.send-invites'
              >
                <FormattedMessage
                  id='organization.invite-organization-members-flyout.send-invites'
                  defaultMessage='Send invites'
                />
              </SpinButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderEmailField() {
    const { createOrganizationInvitationsRequest } = this.props

    const { emails, emailValidationError } = this.state
    const { inProgress } = createOrganizationInvitationsRequest(emails)

    const selectedOptions = emails.map((email) => ({
      label: email,
    }))

    return (
      <EuiFormRow
        fullWidth={true}
        helpText={
          <FormattedMessage
            id='organization.invite-organization-members-flyout.help'
            defaultMessage='Add multiple email addresses separated with a space or by pressing Enter.'
          />
        }
        isInvalid={emailValidationError !== null}
        error={
          emailValidationError !== null ? (
            <FormattedMessage
              {...messages[emailValidationError]}
              data-test-id='organization.invite-organization-members-flyout.email-validation-error'
            />
          ) : undefined
        }
      >
        <PrivacySensitiveContainer>
          <EuiComboBox<string>
            autoFocus={true}
            compressed={false}
            delimiter=' '
            isClearable={false}
            isDisabled={inProgress}
            noSuggestions={true}
            onChange={this.onChange}
            onCreateOption={this.onCreateEmail}
            onSearchChange={this.onSearchChange}
            selectedOptions={selectedOptions}
            data-test-id='organization.invite-organization-members-flyout.emails-input'
          />
        </PrivacySensitiveContainer>
      </EuiFormRow>
    )
  }

  createInitialState(): State {
    return {
      emails: [],
      emailValidationError: null,
      hasSingleInvalidEmail: true, // needed to avoid the bug described in https://github.com/elastic/cloud/pull/86807#discussion_r692828128
      isOpen: false,
      roleAssignments: getEmptyRoleAssignments(),
    }
  }

  onChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    this.setState({
      emails: options.map(({ label }) => label),
      emailValidationError: null,
      hasSingleInvalidEmail: options.length === 0,
    })
  }

  onClose = (): void => {
    this.setState(this.createInitialState())
  }

  onCreateEmail = (email: string): boolean | void => {
    const emailValidationError = this.getEmailValidationError(email)

    if (emailValidationError) {
      this.setState({ emailValidationError })

      return false
    }

    this.setState((prevState) => ({
      emails: prevState.emails.concat(email),
      emailValidationError: null,
    }))
  }

  onSearchChange = (searchValue: string): void => {
    if (this.state.emails.length > 0) {
      return
    }

    const email = searchValue.trim()

    if (email === '') {
      this.setState({ hasSingleInvalidEmail: false })
      return
    }

    this.setState({ hasSingleInvalidEmail: !validateEmail(email) })
  }

  onSendInvitations = (): void => {
    const { createOrganizationInvitations } = this.props
    const { emails, roleAssignments } = this.state

    createOrganizationInvitations(emails, roleAssignments)
      .then(() => this.onClose())
      .then(() =>
        addToast({
          family: 'organization.invite-organization-members-flyout',
          color: 'success',
          iconType: 'check',
          title: (
            <FormattedMessage
              id='organization.invite-organization-members-flyout.success'
              defaultMessage='Invites sent!'
            />
          ),
        }),
      )
      .catch((error) =>
        addToast({
          family: 'organization.invite-organization-members-flyout',
          color: 'danger',
          title: (
            <FormattedMessage
              id='organization.invite-organization-members-flyout.failure'
              defaultMessage='Could not send invites'
            />
          ),
          text: parseError(error),
        }),
      )
  }

  getEmailValidationError = (email: string): EmailValidationError | null => {
    const { emails } = this.state

    if (emails.length === 20) {
      return 'too-many-invites'
    }

    const { organizationMemberRows } = this.props

    if (!validateEmail(email)) {
      return 'malformed-email'
    }

    const organizationMemberRow = organizationMemberRows.find(
      ({ email: memberEmail }) => email === memberEmail,
    )

    if (organizationMemberRow) {
      return organizationMemberRow.isInvitation
        ? 'member-was-already-invited'
        : 'member-already-exists'
    }

    return null
  }
}

export default injectIntl(InviteOrganizationMembersFlyout)
