/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge } from '@elastic/eui'

import { getKeyStatus } from './lib'
import { statusMessages } from './messages'

import type { FunctionComponent, ReactElement } from 'react'
import type { ApiKeyRow } from './types'

type KeyStatusProps = {
  apiKey: ApiKeyRow
}

export const ApiKeyStatus: FunctionComponent<KeyStatusProps> = ({ apiKey }): ReactElement => {
  const { status, color } = getKeyStatus(apiKey.expiration_date)

  return (
    <EuiBadge color={color}>
      <FormattedMessage {...statusMessages[status]} />
    </EuiBadge>
  )
}
