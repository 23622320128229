/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { parseError, useEnrollWebAuthnMutation } from '@modules/cloud-lib/users/hooks/webauthn'

import type { DeviceType } from '../../mfa-management/types'

const DeviceOption: React.FunctionComponent<{
  type: DeviceType
  device: SaasAuthMfaDeviceResponse | undefined
  onSelect: (type: DeviceType) => void
}> = ({ type, device, onSelect }) => {
  const { isLoading: isEnrollingWebAuthn, enrollWebAuthn, error } = useEnrollWebAuthnMutation()
  const webAuthnErrorMessage = parseError(error)

  return (
    <EuiPanel hasBorder={true}>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={true}>
          <h2>
            <EuiText size='s'>
              <strong>
                {type === 'GOOGLE' && (
                  <FormattedMessage
                    id='deviceOption.authenticator.title'
                    defaultMessage='Authenticator'
                  />
                )}
                {type === 'EMAIL' && (
                  <FormattedMessage id='deviceOption.email.title' defaultMessage='Email' />
                )}
                {type === 'WEBAUTHN' && (
                  <FormattedMessage
                    id='deviceOption.webauthn.title'
                    defaultMessage='Security key or biometrics'
                  />
                )}
              </strong>
            </EuiText>
          </h2>
          <EuiText size='s'>
            {type === 'GOOGLE' && (
              <FormattedMessage
                id='deviceOption.authenticator.description'
                defaultMessage='Generate verification codes on your mobile device with your preferred security app'
              />
            )}
            {type === 'EMAIL' && (
              <FormattedMessage
                id='deviceOption.email.description'
                defaultMessage='Receive a verification code at your email address'
              />
            )}
            {type === 'WEBAUTHN' && (
              <FormattedMessage
                id='deviceOption.webauthn.description'
                defaultMessage='Authenticate using a hardware security key or biometrics such as face and fingerprint recognition'
              />
            )}
          </EuiText>

          {webAuthnErrorMessage && type === 'WEBAUTHN' && (
            <Fragment>
              <EuiSpacer size='s' />

              <EuiCallOut
                title={
                  <FormattedMessage
                    id='authenticator.webauthn.error'
                    defaultMessage='Could not enable the security key or biometrics method'
                  />
                }
                color='danger'
              >
                {webAuthnErrorMessage}
              </EuiCallOut>
            </Fragment>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {device?.status === 'ACTIVE' ? (
            <EuiIcon type='checkInCircleFilled' size='l' />
          ) : (
            <EuiButton
              data-test-id={`set-up-mfa-${type}`}
              isLoading={isEnrollingWebAuthn}
              onClick={() => {
                if (type === 'WEBAUTHN') {
                  enrollWebAuthn()
                  return
                }

                onSelect(type)
              }}
            >
              <FormattedMessage id='deviceOption.setupButton' defaultMessage='Set up' />
            </EuiButton>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default DeviceOption
