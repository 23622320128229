/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { useIntl } from 'react-intl'

import { EuiFormRow, EuiRadioGroup } from '@elastic/eui'

import { GroupBy } from '../../../types'

import type { FunctionComponent } from 'react'

const GroupBySelect: FunctionComponent<any> = ({ onChange, value }) => {
  const { formatMessage } = useIntl()

  return (
    <EuiFormRow
      fullWidth={true}
      label={formatMessage({
        id: 'billing-usage.filters.groupBy',
        defaultMessage: 'Group by',
      })}
    >
      <EuiRadioGroup
        options={[
          {
            id: GroupBy.Product,
            label: formatMessage({
              id: 'billing-usage.filters.groupBy.product',
              defaultMessage: 'Product',
            }),
            'data-test-subj': 'product',
          },
          {
            id: GroupBy.Instance,
            label: formatMessage({
              id: 'billing-usage.filters.groupBy.instance',
              defaultMessage: 'Instance',
            }),
            'data-test-subj': 'instance',
          },
        ]}
        idSelected={value}
        onChange={(id: GroupBy) => onChange({ groupBy: id })}
        name='Group by'
        data-test-id='billing-usage.group-by'
      />
    </EuiFormRow>
  )
}

export default GroupBySelect
