/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { includes } from 'lodash'
import { parse } from 'query-string'

import { EuiButton, EuiLink, EuiText } from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'
import { CuiRouterLinkButton } from '@modules/cui/RouterButton'

import {
  buildLoginUrl,
  buildMarketplaceConversionRegisterUrl,
  buildRegisterUrl,
  // eslint-disable-next-line import/no-restricted-paths
} from '@/lib/urlUtils'

import messages from './messages'

import type { FunctionComponent, ReactElement, ReactNode } from 'react'

type LocationState = {
  [key: string]: string | boolean
}

export interface Props {
  locationQueryString: string
  locationState?: LocationState
  onClick?: (() => void) | undefined
  isFlowV2?: boolean
}

export const LoginButton: FunctionComponent<Props> = ({ locationQueryString, ...restProps }) => (
  <HeaderButton
    buttonMessage={<FormattedMessage {...messages.login} />}
    descriptionMessage={<FormattedMessage {...messages.loginDescription} />}
    redirectURL={buildLoginUrl({ locationQueryString })}
    {...restProps}
  />
)

export const SignupButton: FunctionComponent<Props> = ({ locationQueryString, ...restProps }) => {
  const { fromURI } = parse(locationQueryString.slice(1))
  const redirectURL = includes(fromURI, `/marketplace-conversion`)
    ? buildMarketplaceConversionRegisterUrl({ search: locationQueryString })
    : buildRegisterUrl({ search: locationQueryString })

  return (
    <HeaderButton
      buttonMessage={<FormattedMessage {...messages.signup} />}
      descriptionMessage={<FormattedMessage {...messages.signupDescription} />}
      redirectURL={redirectURL}
      {...restProps}
    />
  )
}

export const LogoutButton: FunctionComponent<Props> = ({ ...restProps }) => (
  <HeaderButton
    buttonMessage={<FormattedMessage {...messages.logout} />}
    {...restProps}
    redirectURL='/logout'
  />
)

const HeaderButton: FunctionComponent<{
  buttonMessage?: ReactNode
  descriptionMessage?: ReactNode
  onClick?: (() => void) | undefined
  redirectURL: string
  locationState?: LocationState
  isFlowV2?: boolean
}> = ({
  buttonMessage,
  descriptionMessage,
  onClick,
  redirectURL,
  locationState,
  isFlowV2,
}): ReactElement => {
  const [pathname, search] = redirectURL.split('?')
  const linkTo = { pathname, search, state: locationState }
  return (
    <Fragment>
      <EuiText textAlign='right' size='s' className='cloud-landing-page-login-link'>
        {descriptionMessage} {getButton()}
      </EuiText>

      {getMobileLink()}
    </Fragment>
  )

  function getButton() {
    const buttonProps = {
      fill: isFlowV2 ? false : true,
      className: 'cloud-landing-page-login-link',
    }

    if (onClick) {
      return (
        <EuiButton
          {...buttonProps}
          size='s'
          onClick={onClick}
          data-test-id='cloud-landing-page.login-button'
        >
          {buttonMessage}
        </EuiButton>
      )
    }

    return (
      <CuiRouterLinkButton
        {...buttonProps}
        size='s'
        to={linkTo}
        data-test-id='cloud-landing-page.login-link'
      >
        {buttonMessage}
      </CuiRouterLinkButton>
    )
  }

  function getMobileLink() {
    const mobileLinkProps = {
      className: 'cloud-landing-page-login-link-mobile',
    }

    if (onClick) {
      return (
        <EuiLink {...mobileLinkProps} onClick={onClick}>
          {buttonMessage}
        </EuiLink>
      )
    }

    return (
      <CuiLink {...mobileLinkProps} to={linkTo} data-test-id='cloud-landing-page.login-link'>
        {buttonMessage}
      </CuiLink>
    )
  }
}
