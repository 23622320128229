/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { User } from '@modules/cloud-api/v1/types'

import {
  FETCH_CURRENT_USER,
  UPDATE_CURRENT_USER,
  CLEAR_CURRENT_USER,
} from '../../constants/actions'

import type { AsyncAction, ReduxState } from '@/types/redux'

export type State = null | {
  user: User
}

type FetchAction = AsyncAction<typeof FETCH_CURRENT_USER, User>
type UpdateAction = AsyncAction<typeof UPDATE_CURRENT_USER, User>
type ClearAction = AsyncAction<typeof CLEAR_CURRENT_USER, User>

type Action = FetchAction | UpdateAction | ClearAction

const initialState: State = null

export default function usersReducer(state: State = initialState, action: Action): State {
  if (action.type === CLEAR_CURRENT_USER) {
    return initialState
  }

  if (action.type === FETCH_CURRENT_USER || action.type === UPDATE_CURRENT_USER) {
    if (!action.error && action.payload) {
      return {
        user: action.payload,
      }
    }
  }

  return state
}

function _getCurrentUser(state: State): User | null {
  return state ? state.user : null
}

function _getCurrentUserUsername(state: State): string | null {
  const currentUser = _getCurrentUser(state)

  if (!currentUser) {
    return null
  }

  return currentUser.email || currentUser.user_name
}

function _isCurrentUser(state: State, username: string): boolean {
  const currentUser = _getCurrentUser(state)
  return !!currentUser && currentUser.user_name === username
}

export const getCurrentUser = (state: ReduxState) => _getCurrentUser(state.currentUser)

export const getCurrentUserUsername = (state: ReduxState) =>
  _getCurrentUserUsername(state.currentUser)

export const isCurrentUser = (state: ReduxState, username: string) =>
  _isCurrentUser(state.currentUser, username)
