/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  FETCH_ALLOW_EXTRA_VERSIONS_STATUS,
  UPDATE_ALLOW_EXTRA_VERSIONS_STATUS,
} from '../../constants/actions'

import type { ReduxState } from '@/types/redux'

type AllowExtraVersionsStatus = {
  organizationId: string
  bypass_version_whitelist: boolean
}

type FetchAction = {
  type: typeof FETCH_ALLOW_EXTRA_VERSIONS_STATUS
  error?: boolean
  payload?: AllowExtraVersionsStatus
}

type Action = FetchAction

export type State = AllowExtraVersionsStatus | null

const initialState: State = null

export default function allowExtraVersionStatusReducer(
  state: State = initialState,
  action: Action,
): State {
  if (
    action.type === FETCH_ALLOW_EXTRA_VERSIONS_STATUS ||
    action.type === UPDATE_ALLOW_EXTRA_VERSIONS_STATUS
  ) {
    if (!action.error && action.payload) {
      return action.payload
    }
  }

  return state
}

const _getAllowExtraVersionsStatus = (state: State): AllowExtraVersionsStatus | null => {
  if (state) {
    return state
  }

  return null
}

export const getAllowExtraVersionsStatus = (state: ReduxState) =>
  _getAllowExtraVersionsStatus(state.allowExtraVersionsStatus)
