/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentsSearchResponse, SearchRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import searchDeploymentsQuery from '@/components/StackDeploymentSearch/searchDeploymentsQuery'
import { transformResourceQuery } from '@/components/StackDeploymentSearch/DeploymentFilterContext/queryParser'
import { searchDeployments } from '@/actions/stackDeployments'
import { searchStackDeploymentsRequest } from '@/reducers/asyncRequests/registry'
import { getStackDeploymentsFromSearch } from '@/reducers/stackDeploymentSearches'

import type { ThunkAction, ReduxState } from '@/types/redux'

export function getAllOrganizationDeploymentsSearchResults(
  state: ReduxState,
  organizationId: string,
): DeploymentsSearchResponse | null {
  const queryId = getSearchQueryId({ allDeployments: true, organizationId })

  return getStackDeploymentsFromSearch(state, queryId)
}

export function getOrganizationDeploymentsSearchResults(
  state: ReduxState,
  organizationId: string,
): DeploymentsSearchResponse | null {
  const queryId = getSearchQueryId({ allDeployments: false, organizationId })

  return getStackDeploymentsFromSearch(state, queryId)
}

export function searchOrganizationDeploymentsRequest(
  state: ReduxState,
  organizationId: string,
): AsyncRequestState {
  const queryId = getSearchQueryId({ allDeployments: false, organizationId })

  return searchStackDeploymentsRequest(state, queryId)
}

export function searchAllOrganizationDeployments({
  organizationId,
}: {
  organizationId: string
}): ThunkAction {
  const queryId = getSearchQueryId({ allDeployments: true, organizationId })

  return _searchOrganizationDeployments({
    query: searchDeploymentsQuery(),
    organizationId,
    queryId,
  })
}

export function searchOrganizationDeployments({
  query,
  organizationId,
}: {
  query: SearchRequest
  organizationId: string
}): ThunkAction {
  const queryId = getSearchQueryId({ allDeployments: false, organizationId })

  return _searchOrganizationDeployments({ query, organizationId, queryId })
}

function _searchOrganizationDeployments({
  query,
  organizationId,
  queryId,
}: {
  query: SearchRequest
  organizationId: string
  queryId: string
}): ThunkAction {
  if (query.query?.bool === undefined) {
    throw new Error('Expected organization search query to contain a bool node')
  }

  const matchOrganization = transformResourceQuery({
    path: `info.settings.metadata.organization_id`,
    queryType: `match`,
    query: { query: String(organizationId) },
  })

  query.query.bool.filter = [...(query.query.bool.filter || []), matchOrganization]

  return searchDeployments({ queryId, query })
}

function getSearchQueryId({
  allDeployments,
  organizationId,
}: {
  allDeployments: boolean
  organizationId: string
}): string {
  return `organization-owned-deployments/${organizationId}/${allDeployments ? 'all' : 'search'}`
}
