/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { debounce, isEqual, uniqWith } from 'lodash'

export type Fetcher<Query, BatchResponse> = (queries: Query[]) => Promise<BatchResponse>

export type QueryMatcher<Query, BatchResponse, Response> = (
  batchResponse: BatchResponse,
  queries: Query[],
) => Response[]

type DebouncedPromise<Query, Response> = {
  queries: Query[]
  resolve: (response: Response[]) => void
  reject: (error: unknown) => void
}

class QueryBatcher<Query, BatchResponse, Response> {
  private fetcher: Fetcher<Query, BatchResponse>

  private queryMatcher: QueryMatcher<Query, BatchResponse, Response>

  private debouncedFetch: () => void

  private promises: Array<DebouncedPromise<Query, Response>>

  /**
   * Creates a QueryBatcher instance.
   *
   * Queries are debounced and sent together in batches. If no queries are added after the wait interval,
   * a new batch will be created for the queries added after the quiet period.
   *
   * Since multiple queries can be sent in a batch, a queryMatcher function must be passed so it knows which
   * response belongs to the query.
   *
   * @param {Fetcher} fetcher The function that makes the actual batch request.
   * @param {QueryMatcher} queryMatcher The function that matches a response with a given query.
   * @param {number} [wait=10] The wait interval in milliseconds.
   */
  constructor(
    fetcher: Fetcher<Query, BatchResponse>,
    queryMatcher: QueryMatcher<Query, BatchResponse, Response>,
    wait: number = 10,
  ) {
    this.fetcher = fetcher
    this.queryMatcher = queryMatcher
    this.debouncedFetch = debounce(this.fetch.bind(this), wait)
    this.promises = []
  }

  /**
   * Sugar method for addAll
   *
   * @param {Query} query The query to be added to the batch.
   * @returns {Promise<Reponse[]>} A promise containing the responses that match the query.
   */
  add(query: Query): Promise<Response[]> {
    return this.addAll([query])
  }

  /**
   * Adds queries to be sent with the next batch.
   *
   * @param {Query[]} queries The queries to be added to the batch.
   * @returns {Promise<Reponse[]>} A promise containing the responses that matc the query.
   */
  addAll(queries: Query[]): Promise<Response[]> {
    this.debouncedFetch()

    return new Promise((resolve, reject) => {
      this.promises.push({
        queries,
        resolve,
        reject,
      })
    })
  }

  private fetch = async () => {
    const promisesCopy = this.promises.map((promise) => promise)
    this.promises = []
    const queries = promisesCopy.flatMap((promise) => promise.queries)

    try {
      // deduplicates queries since we don't want to
      // send duplicate queries for each batch
      const dedupedQueries = uniqWith(queries, isEqual)
      const batchResponse = await this.fetcher(dedupedQueries)

      for (const promise of promisesCopy) {
        this.matchQuery(promise, batchResponse)
      }
    } catch (error) {
      for (const promise of promisesCopy) {
        promise.reject(error)
      }
    }
  }

  private matchQuery(
    promise: DebouncedPromise<Query, Response>,
    batchResponse: Awaited<BatchResponse>,
  ) {
    try {
      // when queryMatcher throws an error, we want to make sure the promise
      // is rejected individually instead of rejecting the entire batch
      promise.resolve(this.queryMatcher(batchResponse, promise.queries))
    } catch (error) {
      promise.reject(error)
    }
  }
}

export default QueryBatcher
