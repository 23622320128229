/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SearchRequest, DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type {
  ClusterSnapshot,
  StackDeploymentCreateRequest,
  RegionId,
  VersionNumber,
} from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { searchDeployments } from '@/actions/stackDeployments'
import { getRegionName } from '@/reducers/providers'

import { isFeatureActivated } from '../../../../../../selectors'

import SetupSnapshots from './SetupSnapshots'

type StateProps = {
  getRegionName: (regionId: RegionId) => void
  hasDefaultSnapshotRepository: boolean
}

type DispatchProps = {
  onSearch: (request: SearchRequest) => void
}

type ConsumerProps = {
  editorState: StackDeploymentCreateRequest
  onUpdateSnapshotSource: (source?: DeploymentSearchResponse | null) => void
  onChangeSnapshot: (source?: ClusterSnapshot | null) => void
  regionId: RegionId
  showRegion: boolean
  version: VersionNumber
  disabled?: boolean
  selectedSnapshotDeployment: DeploymentSearchResponse | null
}

const mapStateToProps = (state): StateProps => ({
  getRegionName: (regionId) => getRegionName(state, regionId),
  hasDefaultSnapshotRepository: isFeatureActivated(state, Feature.defaultSnapshotRepository),
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onSearch: (searchRequest: SearchRequest) =>
    dispatch(searchDeployments({ queryId: `deployments`, query: searchRequest })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SetupSnapshots)
