/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

import { EuiButton, EuiCallOut, EuiSpacer } from '@elastic/eui'

import { getRemainingDaysToExpiry, getX509CertificateInfo } from '@modules/security-idp-lib'

import { userAuthenticationUrl } from '@/apps/userconsole/urls'

export const ONE_MONTH_IN_DAYS = 30
export const SIX_MONTHS_IN_DAYS = ONE_MONTH_IN_DAYS * 6

const getTitleAndExplanation: (remainingDaysToCertExpiry: number | null) => {
  title: JSX.Element
  explanation: JSX.Element
} = (remainingDaysToCertExpiry) => {
  if (remainingDaysToCertExpiry === null) {
    return {
      title: (
        <FormattedMessage
          id='organization.security.certificate-expiry-warning.cert-not-yet-valid-title'
          defaultMessage='Users in your organization cannot log in with SAML SSO'
        />
      ),
      explanation: (
        <FormattedMessage
          id='organization.security.certificate-expiry-warning.cert-not-yet-valid-explanation'
          defaultMessage="Your public x509 certificate's validity period hasn't started yet, which prevents users from logging in with SAML SSO. Provide a valid certificate to restore SAML SSO access."
        />
      ),
    }
  }

  if (remainingDaysToCertExpiry <= 0) {
    return {
      title: (
        <FormattedMessage
          id='organization.security.certificate-expiry-warning.expired-title'
          defaultMessage='Users in your organization cannot log in with SAML SSO'
        />
      ),
      explanation: (
        <FormattedMessage
          id='organization.security.certificate-expiry-warning.expired-explanation'
          defaultMessage='Your public x509 certificate has expired, which prevents users from logging in with SAML SSO. Provide a valid certificate to restore SAML SSO access.'
        />
      ),
    }
  }

  return {
    title: (
      <FormattedMessage
        id='organization.security.certificate-expiry-warning.title'
        defaultMessage='Your public x509 certificate expires in {remainingDaysToCertExpiry} days'
        values={{ remainingDaysToCertExpiry }}
      />
    ),
    explanation: (
      <FormattedMessage
        id='organization.security.certificate-expiry-warning.explanation'
        defaultMessage='Your public x509 certificate expires soon, which will prevent users from logging in with SAML SSO. Provide a valid certificate within {remainingDaysToCertExpiry} days to maintain SAML SSO access.'
        values={{ remainingDaysToCertExpiry }}
      />
    ),
  }
}

const CertificateExpiryWarning = ({
  publicCertificate,
  readonly,
}: {
  publicCertificate: string
  readonly: boolean | undefined
}) => {
  const history = useHistory()
  const x509Cert = getX509CertificateInfo(publicCertificate)
  const remainingDaysToCertExpiry = x509Cert ? getRemainingDaysToExpiry(x509Cert) : undefined

  // If the certificate is invalid, we don't show the warning
  if (remainingDaysToCertExpiry === undefined) {
    // The ESLint disable below is important for returning an empty fragment, which helps ensure that the unit test verifies the component renders correctly.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment />
  }

  // If the certificate is valid for more than 6 months, we don't show the warning
  if (remainingDaysToCertExpiry !== null && remainingDaysToCertExpiry > SIX_MONTHS_IN_DAYS) {
    // The ESLint disable below is important for returning an empty fragment, which helps ensure that the unit test verifies the component renders correctly.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment />
  }

  const isNotValidYetOrExpiringInOneMonth =
    remainingDaysToCertExpiry === null || remainingDaysToCertExpiry <= ONE_MONTH_IN_DAYS
  const color = isNotValidYetOrExpiringInOneMonth ? 'danger' : 'warning'

  const { title, explanation } = getTitleAndExplanation(remainingDaysToCertExpiry)

  return (
    <div>
      <EuiCallOut title={title} color={color} iconType='warning'>
        <p>{explanation}</p>
        {!readonly && (
          <EuiButton
            color={color}
            fill={true}
            onClick={() => history.push(userAuthenticationUrl())}
          >
            <FormattedMessage
              id='organization.security.certificate-expiry-warning.action'
              defaultMessage='Update certificate'
            />
          </EuiButton>
        )}
      </EuiCallOut>
      <EuiSpacer size='l' />
    </div>
  )
}

export default CertificateExpiryWarning
