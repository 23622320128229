/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  experienceLabel: {
    id: `signup.discovery-questions.elastic-experience-label`,
    defaultMessage: `When it comes to Elastic, I'm... *`,
  },
  trialIntentLabel: {
    id: `signup.discovery-questions.evaluation-purpose-label`,
    defaultMessage: `Right now, I'd like to... *`,
  },
  useCaseDetailPlaceholder: {
    id: 'use-case-level-options.use_case_detail',
    defaultMessage: `Tell us about your use case`,
  },
  requireField: {
    id: `signup.discovery-questions.required-field`,
    defaultMessage: `This field is required.`,
  },
  requiredUseCaseDetailFieldMessage: {
    id: `signup.discovery-questions.required-detail-field`,
    defaultMessage: `Please provide some detail.`,
  },
})

export const experienceLevelOptionLabels = defineMessages({
  new: {
    id: 'experience-level-options.new',
    defaultMessage: 'New',
  },
  experienced: {
    id: 'experience-level-options.experienced',
    defaultMessage: 'Experienced',
  },
  an_expert: {
    id: 'experience-level-options.an_expert',
    defaultMessage: 'An expert',
  },
})

export const useCaseOptionLabels = defineMessages({
  search: {
    id: 'use-case-level-options.search',
    defaultMessage: 'Search - Building search experiences in applications, sites or other',
  },
  observability: {
    id: 'use-case-level-options.observability',
    defaultMessage: 'Observability - Logs, metrics, traces, and synthetics',
  },
  security: {
    id: 'use-case-level-options.security',
    defaultMessage:
      'Security - Analytics (logs/events), SIEM, endpoint protection, or cloud security',
  },
  something_else: {
    id: 'use-case-level-options.something_else',
    defaultMessage: 'Something else',
  },
})

export const trialIntentOptionLabels = defineMessages({
  evaluate: {
    id: 'trial-intent-options.evaluate',
    defaultMessage: 'Evaluate Elastic for my project or use case',
  },
  migrate: {
    id: 'trial-intent-options.migrate',
    defaultMessage: 'Migrate an existing Elasticsearch environment',
  },
  pricing_info: {
    id: 'trial-intent-options.pricing_info',
    defaultMessage: 'Get pricing information',
  },
  learn: {
    id: 'trial-intent-options.learn',
    defaultMessage: 'Learn more about Elastic',
  },
  do_something_else: {
    id: 'trial-intent-options.something_else',
    defaultMessage: 'Do something else',
  },
})
