/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { size } from 'lodash'

import type {
  ClusterInstanceConfigurationInfo,
  ClusterInstanceInfo,
} from '@modules/cloud-api/v1/types'
import type {
  Allocator,
  AnyResourceInfo,
  AsyncRequestState,
  SliderInstanceType,
  StackDeployment,
} from '@modules/ui-types'

import { getInstanceNames } from '@/lib/stackDeployments/selectors/instances'
import { getAllocator } from '@/reducers/allocators'
import { getAllocatorRequest, setDiskQuotaRequest } from '@/reducers/asyncRequests/registry'

import { fetchAllocator } from '../../../../actions/allocators'
import { resetSetDiskQuotaRequest } from '../../../../actions/clusters'
import { setStackDeploymentDiskQuota } from '../../../../actions/stackDeployments'

import DiskQuotaOverrideModal from './DiskQuotaOverrideModal'

import type { ThunkDispatch } from '@/types/redux'

type StateProps = {
  canApplyToAll: boolean
  setDiskQuotaRequest: AsyncRequestState
  allocator?: Allocator
  fetchAllocatorRequest: AsyncRequestState
}

type DispatchProps = {
  setDiskQuota: (args: {
    diskQuota: number | null
    defaultDiskQuota: number
    previousDiskQuota: number
    applyToAll: boolean
  }) => Promise<any>
  resetSetDiskQuotaRequest: () => void
  fetchAllocator: () => Promise<any>
}

type ConsumerProps = {
  deployment: StackDeployment
  resourceKind: SliderInstanceType
  resource: AnyResourceInfo
  instance: ClusterInstanceInfo
  close: () => void
  instanceConfiguration?: ClusterInstanceConfigurationInfo
}

export interface AllProps extends StateProps, DispatchProps {
  deployment: StackDeployment
  instance: ClusterInstanceInfo
  close: () => void
  instanceConfiguration?: ClusterInstanceConfigurationInfo
}

const mapStateToProps = (state: any, { instance, resource }: ConsumerProps): StateProps => {
  const instanceNames = getInstanceNames({ instance, resource, applyToAll: true })
  const canApplyToAll = size(instanceNames) > 1

  return {
    canApplyToAll,
    setDiskQuotaRequest: setDiskQuotaRequest(state, resource.region, resource.id),
    allocator: getAllocator(state, resource.region, instance.allocator_id || ``),
    fetchAllocatorRequest: getAllocatorRequest(state, resource.region, instance.allocator_id || ``),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment, instance, resourceKind, resource }: ConsumerProps,
): DispatchProps => ({
  setDiskQuota: ({ diskQuota, previousDiskQuota, defaultDiskQuota, applyToAll }) =>
    dispatch(
      setStackDeploymentDiskQuota({
        deployment,
        resourceKind,
        resource,
        instanceIds: getInstanceNames({ instance, resource, applyToAll }),
        diskQuota,
        previousDiskQuota,
        defaultDiskQuota,
      }),
    ),
  resetSetDiskQuotaRequest: () => dispatch(resetSetDiskQuotaRequest(resource.region, resource.id)),
  fetchAllocator: () => dispatch(fetchAllocator(resource.region, instance.allocator_id || ``)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DiskQuotaOverrideModal)
