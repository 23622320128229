/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiSkeletonText,
  EuiButtonEmpty,
  EuiText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiSteps,
} from '@elastic/eui'

import type { AccountTrustRelationship } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui/Alert'
import history from '@modules/utils/history'

import { createDeploymentTrustRelationshipUrl, securityUrl } from '@/lib/urlBuilder'
import { getOrganizationId } from '@/lib/stackDeployments/selectors/metadata'

import {
  createUpdateTrustRequestFromGetResponse,
  getTrustLevelFromRelationship,
  getTrustRelationshipFromDeployment,
  getTrustRelationshipId,
  isAccountRelationship,
} from '../../../lib/stackDeployments/trustRelationships'
import EnterOrganizationId from '../components/EnterOrganizationId'
import EnterOtherAccountRemoteDeployments from '../components/EnterOtherAccountRemoteDeployments'
import TrustLevelSelector from '../components/TrustLevelSelector'
import SelectOwnAccountRemoteDeployments from '../components/SelectOwnAccountRemoteDeployments'
import EnterName from '../components/EnterName'
import { flattenTrustedEnvs } from '../helpers'
import TrustRelationshipNavigationSteps from '../components/TrustRelationshipNavigationSteps'
import ProceedToAddingApiKeys from '../components/ProceedToAddingApiKeys'

import AccountRelationshipInstructions from './Instructions'

import type { WrappedComponentProps } from 'react-intl'
import type { TrustLevel } from '@/lib/stackDeployments/selectors/crossClusterReplication'
import type { AllProps } from './types'

interface State {
  name: string
  trustLevel: TrustLevel
  trustedEnvironmentId: string
  trustedClusterIds: string[]
  didCreate: boolean
}

const messages = defineMessages({
  organizationStepTitle: {
    id: 'deploymentTrustManagement.account.stepTitles.organization',
    defaultMessage: 'Add organization ID',
  },
  deploymentsStepTitle: {
    id: 'deploymentTrustManagement.account.stepTitles.deployments',
    defaultMessage: 'Select trusted deployments',
  },
  nameStepTitle: {
    id: 'deploymentTrustManagement.account.stepTitles.name',
    defaultMessage: 'Name the environment',
  },
})

class ManageAccountTrustRelationship extends React.Component<
  AllProps & WrappedComponentProps,
  State
> {
  state: State = this.createInitialState()

  componentDidMount(): void {
    const { previouslyUsedEnv, fetchTrustedEnvs, trustedEnvs, fetchCurrentAccount } = this.props

    fetchCurrentAccount()

    if (previouslyUsedEnv) {
      fetchTrustedEnvs()

      if (trustedEnvs && !isEmpty(trustedEnvs)) {
        this.setPreviouslyUsedEnvDetails()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { previouslyUsedEnv, trustedEnvs } = this.props

    if (previouslyUsedEnv && isEmpty(prevProps.trustedEnvs) && !isEmpty(trustedEnvs)) {
      // We're using a template, and the trustedEnvs were loaded in
      this.setPreviouslyUsedEnvDetails()
    }
  }

  componentWillUnmount(): void {
    this.props.resetFetchCurrentAccount()
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element {
    const { deployment, fetchCurrentAccountRequest, authMechanism } = this.props

    if (!fetchCurrentAccountRequest.isDone) {
      return <EuiSkeletonText />
    }

    if (authMechanism === `api-keys`) {
      return <ProceedToAddingApiKeys deployment={deployment} />
    }

    if (this.state.didCreate) {
      return (
        <Fragment>
          <TrustRelationshipNavigationSteps currentStep={2} />
          <EuiSpacer size='xl' />
          <AccountRelationshipInstructions
            deployment={deployment}
            trustRelationship={this.getTrustRelationshipFromState()}
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        {this.renderForm()}
        {this.renderError()}
        {this.renderButtons()}
      </Fragment>
    )
  }

  renderForm(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props

    const trustDetailsStep = {
      title: formatMessage(messages.deploymentsStepTitle),
      children: this.renderDeploymentsFields(),
    }
    const nameStep = {
      title: formatMessage(messages.nameStepTitle),
      children: this.renderNameFields(),
    }

    if (this.props.previouslyUsedEnv) {
      return (
        <Fragment>
          <TrustRelationshipNavigationSteps currentStep={2} />
          <EuiSpacer />
          {trustDetailsStep.children}
        </Fragment>
      )
    }

    if (this.isDeploymentInUsersOrg()) {
      return trustDetailsStep.children
    }

    if (this.isEditing()) {
      return <EuiSteps steps={[trustDetailsStep, nameStep]} />
    }

    const organizationStep = {
      title: formatMessage(messages.organizationStepTitle),
      children: this.renderOrganizationFields(),
    }

    return (
      <Fragment>
        <TrustRelationshipNavigationSteps currentStep={2} />
        <EuiSpacer />
        <EuiSteps steps={[organizationStep, trustDetailsStep, nameStep]} />
      </Fragment>
    )
  }

  renderOrganizationFields(): JSX.Element | null {
    return (
      <Fragment>
        <EuiText>
          <FormattedMessage
            id='deploymentTrustManagement.account.organizationInstructions'
            defaultMessage='Paste the Organization ID from the previous step or find it on the Organization page of the environment you want to trust, next to the name of the organization.'
          />
        </EuiText>
        <EuiSpacer />
        <EuiFormRow>
          <EnterOrganizationId
            organizationId={this.state.trustedEnvironmentId}
            onChange={(organizationId) => {
              this.setState({ trustedEnvironmentId: organizationId })
            }}
          />
        </EuiFormRow>
      </Fragment>
    )
  }

  renderDeploymentsFields(): JSX.Element {
    const { deployment, userOrgId } = this.props
    const { trustLevel, trustedClusterIds } = this.state

    return (
      <Fragment>
        <EuiSpacer size='s' />
        <EuiFormRow>
          <TrustLevelSelector
            trustLevel={trustLevel}
            onChange={(trustLevel) => {
              this.setState({ trustLevel })
            }}
            showNone={userOrgId === getOrganizationId({ deployment })}
          />
        </EuiFormRow>

        {trustLevel === `specific` && (
          <Fragment>
            <EuiSpacer />

            {this.isDeploymentInUsersOrg() ? (
              <SelectOwnAccountRemoteDeployments
                deployment={deployment}
                trustedClusterIds={trustedClusterIds}
                onChange={(trustedClusterIds) => this.setState({ trustedClusterIds })}
              />
            ) : (
              <EnterOtherAccountRemoteDeployments
                trustedClusterIds={trustedClusterIds}
                onChange={(trustedClusterIds) => this.setState({ trustedClusterIds })}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderError(): JSX.Element | null {
    const { updateStackDeploymentRequest } = this.props

    if (!updateStackDeploymentRequest.error) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <CuiAlert type='danger' data-test-id='update-deployment-request-error'>
          {updateStackDeploymentRequest.error}
        </CuiAlert>
      </EuiFlexItem>
    )
  }

  renderButtons(): JSX.Element {
    const { deployment, updateStackDeploymentRequest } = this.props

    const isDisabled =
      !this.state.trustedEnvironmentId ||
      (this.state.trustLevel === 'specific' && this.state.trustedClusterIds.length === 0)

    return (
      <Fragment>
        <EuiSpacer size='xl' />
        <EuiFlexGroup justifyContent='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiButton
              type='button'
              data-test-id='save-trust-relationship-button'
              disabled={isDisabled}
              onClick={() => this.onSave()}
              isLoading={updateStackDeploymentRequest.inProgress}
              fill={true}
            >
              {this.isEditing() ? (
                <FormattedMessage
                  id='deploymentTrustManagement.account.submitButton.edit'
                  defaultMessage='Update trust'
                />
              ) : (
                <FormattedMessage
                  id='deploymentTrustManagement.account.submitButton.create'
                  defaultMessage='Create trust'
                />
              )}
            </EuiButton>
          </EuiFlexItem>
          {!this.isEditing() && (
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                onClick={() =>
                  history.push(createDeploymentTrustRelationshipUrl(deployment.id, undefined))
                }
              >
                <FormattedMessage id='deploymentTrustManagement.back' defaultMessage='< Back' />
              </EuiButtonEmpty>
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
              <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderNameFields(): JSX.Element | null {
    return (
      <EuiFormRow>
        <EnterName
          name={this.state.name}
          onChange={(name) => {
            this.setState({ name })
          }}
        />
      </EuiFormRow>
    )
  }

  isEditing(): boolean {
    return Boolean(this.props.match.params.trustRelationshipId)
  }

  isDeploymentInUsersOrg(): boolean {
    const { userOrgId } = this.props
    return userOrgId === this.state.trustedEnvironmentId
  }

  createInitialState(): State {
    const {
      deployment,
      match: {
        params: { trustRelationshipId },
      },
    } = this.props

    if (trustRelationshipId) {
      const trustRelationship = getTrustRelationshipFromDeployment({
        deployment,
        trustRelationshipType: 'accounts',
        trustRelationshipId,
      })

      if (trustRelationship) {
        return {
          name: trustRelationship.name || ``,
          didCreate: false,
          trustLevel: getTrustLevelFromRelationship(trustRelationship),
          trustedEnvironmentId: getTrustRelationshipId({ trustRelationship }),
          trustedClusterIds: trustRelationship.trust_allowlist || [],
        }
      }
    }

    return {
      name: ``,
      didCreate: false,
      trustLevel: `all`,
      trustedEnvironmentId: ``,
      trustedClusterIds: [],
    }
  }

  getTrustRelationshipFromState(): AccountTrustRelationship {
    const { trustedEnvironmentId, trustLevel, trustedClusterIds, name } = this.state

    const trustFields = {
      account_id: trustedEnvironmentId,
      trust_all: trustLevel === `all`,
      name,
    }

    const optionalFields = trustLevel === `specific` ? { trust_allowlist: trustedClusterIds } : {}

    return { ...trustFields, ...optionalFields }
  }

  onSave(): void {
    const { deployment, updateStackDeployment } = this.props

    const trustRelationship = this.getTrustRelationshipFromState()

    const payload = createUpdateTrustRequestFromGetResponse({
      deployment,
      trustRelationships: [trustRelationship],
      type: `accounts`,
    })

    updateStackDeployment(payload).then(() => {
      if (!this.isEditing()) {
        // Show instructions on create. We scroll to top to mimic a route
        // change. The instructions can't feasibly be a proper route because the
        // trust relationship creation is done via a generic deployment update
        // and doesn't directly return the new relationship's ID.
        this.setState({ didCreate: true })
        window.scrollTo(0, 0)
      } else {
        history.push(securityUrl(deployment.id))
      }
    })
  }

  setPreviouslyUsedEnvDetails() {
    const { trustedEnvs, previouslyUsedEnv } = this.props
    const flatEnvs = flattenTrustedEnvs(trustedEnvs)
    const previouslyUsedEnvObj = flatEnvs.find((env) => env.name === previouslyUsedEnv)

    if (!previouslyUsedEnvObj) {
      return
    }

    if (!isAccountRelationship(previouslyUsedEnvObj)) {
      return
    }

    this.setState({
      name: previouslyUsedEnvObj.name || ``,
      trustLevel: `all`,
      trustedEnvironmentId: previouslyUsedEnvObj.account_id,
      trustedClusterIds: [], // default this to nothing because we want the user to select this anew
    })
  }
}

export default injectIntl(ManageAccountTrustRelationship)
