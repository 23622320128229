/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import {
  EuiPanel,
  EuiSwitch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTextColor,
  EuiText,
  euiBackgroundColor,
  transparentize,
  withEuiTheme,
} from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import type { ReactNode, FunctionComponent } from 'react'

type Props = {
  children?: ReactNode
  isChecked: boolean
  isDisabled: boolean
  title: ReactNode
  description: ReactNode
  testId: string
  onChangeRole: (checked: boolean) => void
}

interface PropsWithTheme extends Props, WithEuiThemeProps {}

const RolePanel: FunctionComponent<PropsWithTheme> = ({
  isChecked,
  isDisabled,
  title,
  description,
  testId,
  theme,
  onChangeRole,
  children,
}) => {
  const defaultFlexGroupStyle = css({
    margin: `-${theme.euiTheme.size.base}!important`,
    borderRadius: theme.euiTheme.border.radius.medium,
    padding: theme.euiTheme.size.base,
    backgroundColor: 'transparent',
    gap: theme.euiTheme.size.base,
  })

  const disabledFlexGroupStyle = css({
    backgroundColor: transparentize(theme.euiTheme.colors.lightShade, 0.15),
  })

  const selectedFlexGroupStyle = css({
    backgroundColor:
      theme.colorMode === 'DARK'
        ? theme.euiTheme.colors.lightShade
        : transparentize(euiBackgroundColor(theme, 'primary'), 0.37),
    border: 0,
  })

  const selectedRoleStyle = css({
    borderColor: theme.euiTheme.colors.primary,
  })

  return (
    <EuiPanel
      disabled={isDisabled}
      hasShadow={false}
      hasBorder={true}
      css={isChecked && selectedRoleStyle}
    >
      <EuiFlexGroup
        gutterSize='none'
        css={[
          defaultFlexGroupStyle,
          isChecked && selectedFlexGroupStyle,
          isDisabled && disabledFlexGroupStyle,
        ]}
        justifyContent='spaceBetween'
      >
        <EuiFlexItem grow={true}>
          <EuiText size='m'>
            <h5>{title}</h5>

            <EuiTextColor color='subdued'>
              <EuiText size='s'>{description}</EuiText>
            </EuiTextColor>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem grow={false} css={css({ alignItems: `start`, alignSelf: `center` })}>
          <EuiSwitch
            disabled={isDisabled}
            checked={isChecked}
            onChange={() => {
              onChangeRole(!isChecked)
            }}
            label=''
            showLabel={false}
            data-test-id={testId}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {children}
    </EuiPanel>
  )
}

export default withEuiTheme(RolePanel)
