/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'

import type { RegionId, StackDeploymentCreateRequest, VersionNumber } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import {
  searchStackDeploymentsRequest,
  fetchVersionsRequest,
  fetchSnapshotRepositoriesRequest,
  fetchRegionListRequest,
  fetchDeploymentTemplatesRequest,
} from '@/reducers/asyncRequests/registry'
import { getStackDeploymentsFromSearch } from '@/reducers/stackDeploymentSearches'
import { getRegionIds, getRegion } from '@/reducers/regions'
import { getProviders } from '@/reducers/providers'
import { getGlobalDeploymentTemplates } from '@/reducers/globalDeploymentTemplates'
import { getDeploymentTemplates, getDeploymentTemplate } from '@/reducers/deploymentTemplates'
import { getActiveVersionStacks } from '@/reducers/elasticStack'
import { getSupportedDeploymentTemplates } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import { getDefaultRegionId } from '@/reducers'

import { clearVersions, fetchVersions } from '../../../actions/elasticStack'
import { createDeployment, searchDeployments } from '../../../actions/stackDeployments'
import {
  fetchDeploymentTemplate,
  fetchDeploymentTemplatesIfNeeded,
  fetchGlobalDeploymentTemplates,
} from '../../../actions/topology/deploymentTemplates'
import { fetchRegionIfNeeded } from '../../../actions/regions'
import { fetchRegionListForDeploymentCreatePageIfNeeded } from '../../../actions/regionEqualizer'
import { fetchSnapshotRepositories } from '../../../actions/snapshotRepositories'
import { getConfigForKey, isFeatureActivated } from '../../../selectors'
import { getSupportedGlobalDeploymentTemplates } from '../../../lib/globalDeploymentTemplates'

import CreateStackDeploymentEditorDependencies from './CreateStackDeploymentEditorDependencies'

import type { ReduxState } from '@/types/redux'
import type {
  ConsumerProps,
  DispatchProps,
  StateProps,
} from './CreateStackDeploymentEditorDependencies'
import type { RouteComponentProps } from 'react-router-dom'

type ConsumerPropsWithRoute = ConsumerProps & RouteComponentProps

const trialQueryId = `trial:deployments`

const mapStateToProps = (state: ReduxState, { location }: ConsumerPropsWithRoute): StateProps => {
  const { regionId: rawRegionId } = parse(location.search)
  const regionId = Array.isArray(rawRegionId) ? rawRegionId[0] : rawRegionId || undefined

  const regionIds = getRegionIds(state)
  const defaultRegionId =
    regionId && regionIds?.includes(regionId) ? regionId : getDefaultRegionId(state)

  return {
    regionIds,
    defaultRegionId,
    fetchRegionListRequest: fetchRegionListRequest(state),
    profile: getProfile(state),
    providers: getProviders(state),
    showRegion: getConfigForKey(state, `APP_PLATFORM`) === `saas`,
    showSnapshotVersions: isFeatureActivated(state, Feature.enableTrialVersionSelection),
    areVersionsWhitelisted: getConfigForKey(state, `APP_PLATFORM`) === `saas`,
    searchTrialResults: getStackDeploymentsFromSearch(state, trialQueryId),
    searchTrialResultsRequest: searchStackDeploymentsRequest(state, trialQueryId),
    inTrial: state.profile?.inTrial ?? false,

    getDeploymentTemplates: getDeploymentTemplatesFromParams,
    getGlobalDeploymentTemplates: getSupportedGlobalTemplates,
    getRegion: getRegionWithEditorState,
    getActiveVersionStacks: getVersionStacksWithEditorState,
    getVersionsRequest: getVersionsRequestWithEditorState,
    getFetchSnapshotRepositoriesRequest: getFetchSnapshotRepositoriesRequestWithEditorState,
    getFetchDeploymentTemplatesRequest: (
      regionId: string,
      version: string,
      showMaxZones: boolean,
    ) => fetchDeploymentTemplatesRequest(state, regionId, version, String(showMaxZones)),
    getDeploymentTemplate: (
      regionId: RegionId,
      templateId: string,
      stackVersion: VersionNumber | null,
    ) => getDeploymentTemplate(state, regionId, templateId, stackVersion),
  }

  function getDeploymentTemplatesFromParams(regionId: string, version: string) {
    const deploymentTemplates = getDeploymentTemplates(state, regionId, version)
    const supportedDeploymentTemplates = getSupportedDeploymentTemplates(deploymentTemplates)

    return supportedDeploymentTemplates
  }

  function getSupportedGlobalTemplates() {
    const globalDeploymentTemplates = getGlobalDeploymentTemplates(state)
    const supportedGlobalTemplates =
      getSupportedGlobalDeploymentTemplates(globalDeploymentTemplates)

    return supportedGlobalTemplates
  }

  function getRegionWithEditorState(editorState: StackDeploymentCreateRequest) {
    const regionId = editorState.regionId!
    return getRegion(state, regionId)!
  }

  function getVersionStacksWithEditorState(editorState: StackDeploymentCreateRequest) {
    const regionId = editorState.regionId!
    return getActiveVersionStacks(state, regionId)
  }

  function getVersionsRequestWithEditorState(editorState: StackDeploymentCreateRequest) {
    const regionId = editorState.regionId!
    return fetchVersionsRequest(state, regionId)
  }

  function getFetchSnapshotRepositoriesRequestWithEditorState(
    editorState: StackDeploymentCreateRequest,
  ) {
    const regionId = editorState.regionId!
    return fetchSnapshotRepositoriesRequest(state, regionId)
  }
}

const mapDispatchToProps: DispatchProps = {
  createDeployment,
  fetchDeploymentTemplates: fetchDeploymentTemplatesIfNeeded,
  fetchGlobalDeploymentTemplates,
  fetchDeploymentTemplate,
  fetchRegion: fetchRegionIfNeeded,
  fetchRegionListIfNeeded: fetchRegionListForDeploymentCreatePageIfNeeded,
  fetchSnapshotRepositories,
  fetchVersions,
  clearVersions,
  searchTrialDeployments: () =>
    searchDeployments({
      queryId: trialQueryId,
      query: {
        query: {
          match_all: {},
        },
      },
    }),
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerPropsWithRoute>(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateStackDeploymentEditorDependencies),
)
