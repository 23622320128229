/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment, type RefObject } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiHorizontalRule,
  EuiIcon,
  EuiPopover,
  EuiSkipLink,
} from '@elastic/eui'

import history from '@modules/utils/history'
import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type { CloudAppName, ProfileState, Theme } from '@modules/ui-types'
import { BreadcrumbsContainer } from '@modules/cui/Breadcrumbs'
import { loginUrl, logoutUrl } from '@modules/auth/urls'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'
import TrialHeaderBadge from '@modules/trial-components/HeaderBadge'

import { accountUrl, billingUrl } from '@/apps/userconsole/urls'
import DriftChat from '@/apps/userconsole/components/DriftChat'
import { userSettingsUrl } from '@/lib/urlBuilder'
import { DriftChatHeaderMenuItem } from '@/apps/userconsole/components/DriftChat/DriftChatHeaderMenuItem'

import SearchAnythingBox from '../SearchAnythingBox'
import CustomerProfile from '../CustomerProfile'

import HeaderNavigation from './HeaderNavigation'
import Help from './Help'

import type { WrappedComponentProps } from 'react-intl'

import './chromeHeader.scss'
import './chromeHeaderDark.scss'

export interface ChromeHeaderProps {
  loggedIn: boolean
  hasUserSettings: boolean
  theme: Theme
  appName: CloudAppName
  profile: ProfileState
  setTheme: (theme: Theme) => void
  hideTrialIndicator?: boolean
  showHelp?: boolean
  getStackDeploymentById: (id: string) => DeploymentGetResponse | undefined
  isHeroku: boolean
  cloudPortalEnabled: boolean
  showBreadcrumbs?: boolean
  showOrganization: boolean
  username: string | null
  isDriftEnabled?: boolean
  showDrift?: true

  breadcrumbsRef?: RefObject<HTMLDivElement> | null
  announcementsRef?: RefObject<HTMLDivElement> | null
}

interface State {
  isUserMenuOpen: boolean
}

const ariaMessages = defineMessages({
  ariaUserMenu: {
    id: 'chrome-header.aria-user-menu',
    defaultMessage: 'User menu',
  },
})

/* !IMPORTANT: this component renders for logged out users too,
 * for example when the "Not Found" page renders for them.
 * As such, any components that render in here should be "logged in aware"
 * You can check whether the user is logged in via code like this:
 *
 * ```
 * const loggedIn: boolean = hasUnexpiredSession(state)
 * ```
 *
 * `hasUnexpiredSession` is exported from `lib/auth`.
 */
class ChromeHeader extends Component<ChromeHeaderProps & WrappedComponentProps, State> {
  state: State = {
    isUserMenuOpen: false,
  }

  render() {
    const { showBreadcrumbs = true, announcementsRef = null, breadcrumbsRef = null } = this.props

    return (
      <header data-test-id='chrome-header'>
        <EuiHeader data-test-id='page-header' theme='dark' tabIndex={-1}>
          <EuiHeaderSection>
            {showBreadcrumbs && (
              <div data-test-id='announcements-breadcrumbs' ref={announcementsRef} />
            )}
          </EuiHeaderSection>
          <EuiHeaderSection>
            <EuiHeaderSectionItem>
              <EuiSkipLink
                position='fixed'
                destinationId='cloudPortalPage'
                overrideLinkBehavior={true}
              >
                <FormattedMessage
                  id='cloud-header-nav.skip-links'
                  defaultMessage='Skip to main content'
                />
              </EuiSkipLink>
            </EuiHeaderSectionItem>
          </EuiHeaderSection>
          <HeaderNavigation />
          <EuiHeaderSection side='right'>
            {this.renderLoggedInSections()}
            <EuiHeaderSectionItem>{this.renderUserMenu()}</EuiHeaderSectionItem>
          </EuiHeaderSection>
        </EuiHeader>

        {showBreadcrumbs && <BreadcrumbsContainer ref={breadcrumbsRef} />}
      </header>
    )
  }

  renderLoggedInSections() {
    const { appName, hideTrialIndicator, loggedIn, showHelp, showDrift, isDriftEnabled, isHeroku } =
      this.props

    const showSearch = appName === 'adminconsole'
    const showTrialInfo = appName === 'userconsole' && !isHeroku

    if (!loggedIn) {
      return null
    }

    return (
      <Fragment>
        {showSearch && (
          <PermissionsGate permissions={[{ type: 'allocator', action: 'search' }]}>
            <EuiHeaderSectionItem>
              <div data-test-id='headerSearchBar'>
                <SearchAnythingBox onBeforeExpand={() => this.closeUserMenu()} />
              </div>
            </EuiHeaderSectionItem>
          </PermissionsGate>
        )}

        {showTrialInfo && !hideTrialIndicator && (
          <EuiHeaderSectionItem className='chromeHeader-trialUser'>
            <TrialHeaderBadge />
          </EuiHeaderSectionItem>
        )}

        {showDrift && isDriftEnabled && (
          <EuiHeaderSectionItem>
            <DriftChat>{DriftChatHeaderMenuItem}</DriftChat>
          </EuiHeaderSectionItem>
        )}

        {showHelp && (
          <EuiHeaderSectionItem>
            <Help />
          </EuiHeaderSectionItem>
        )}
      </Fragment>
    )
  }

  renderUserMenu() {
    const {
      cloudPortalEnabled,
      hasUserSettings,
      intl: { formatMessage },
      loggedIn,
      setTheme,
      theme,
      showOrganization,
    } = this.props

    const { isUserMenuOpen } = this.state

    return (
      <EuiPopover
        id='chromeHeader-userMenuPopover'
        anchorPosition='downCenter'
        panelPaddingSize='none'
        panelClassName='chromeHeader-userMenuPopover'
        ownFocus={true}
        isOpen={isUserMenuOpen}
        closePopover={() => this.closeUserMenu()}
        button={
          <EuiHeaderSectionItemButton
            onClick={() => this.toggleUserPopover()}
            data-test-id='userMenuButton'
            aria-label={formatMessage(ariaMessages.ariaUserMenu)}
          >
            {loggedIn ? (
              <EuiAvatar size='s' name={this.renderUsername()} aria-hidden={true} />
            ) : (
              <EuiIcon type='glasses' size='m' />
            )}
          </EuiHeaderSectionItemButton>
        }
      >
        <div className='chromeHeader-popoverItem-header'>
          <CustomerProfile />
        </div>

        <EuiHorizontalRule margin='none' />

        {loggedIn && (hasUserSettings || cloudPortalEnabled) && (
          <EuiButtonEmpty
            onClick={() => {
              history.push(userSettingsUrl())
              this.closeUserMenu()
            }}
            color='text'
            className='chromeHeaderLink'
            data-test-id='SettingsButton'
            iconType='user'
            role='link'
          >
            <FormattedMessage id='chrome-header.settings' defaultMessage='Profile' />
          </EuiButtonEmpty>
        )}

        {loggedIn && cloudPortalEnabled && (
          <Fragment>
            <PermissionsGate
              permissions={[
                {
                  type: 'billing-organization',
                  action: 'create',
                },
              ]}
            >
              <EuiButtonEmpty
                onClick={() => {
                  history.push(billingUrl())
                  this.closeUserMenu()
                }}
                color='text'
                className='chromeHeaderLink'
                data-test-id='billingButton'
                iconType='payment'
                role='link'
              >
                <FormattedMessage id='chrome-header.billing' defaultMessage='Billing' />
              </EuiButtonEmpty>
            </PermissionsGate>

            <EuiButtonEmpty
              onClick={() => {
                history.push(accountUrl())
                this.closeUserMenu()
              }}
              color='text'
              className='chromeHeaderLink'
              data-test-id='organizationButton'
              iconType='users'
              role='link'
            >
              {showOrganization ? (
                <FormattedMessage id='chrome-header.organization' defaultMessage='Organization' />
              ) : (
                <FormattedMessage id='chrome-header.account' defaultMessage='Account' />
              )}
            </EuiButtonEmpty>
          </Fragment>
        )}

        {theme === `light` ? (
          <div>
            <EuiButtonEmpty
              onClick={() => {
                setTheme(`dark`)
                this.closeUserMenu()
              }}
              color='text'
              className='chromeHeaderLink'
              iconType='moon'
            >
              <FormattedMessage id='chrome-header.night-mode' defaultMessage='Dark mode' />
            </EuiButtonEmpty>
          </div>
        ) : (
          <div>
            <EuiButtonEmpty
              onClick={() => {
                setTheme(`light`)
                this.closeUserMenu()
              }}
              color='text'
              className='chromeHeaderLink'
              iconType='sun'
            >
              <FormattedMessage id='chrome-header.light-mode' defaultMessage='Light mode' />
            </EuiButtonEmpty>
          </div>
        )}

        {loggedIn ? (
          <div>
            <EuiButtonEmpty
              onClick={() => {
                history.push(logoutUrl())
                this.closeUserMenu()
              }}
              color='text'
              className='chromeHeaderLink'
              data-test-id='LogoutButton'
              iconType='exit'
            >
              <FormattedMessage id='chrome-header.log-out' defaultMessage='Log out' />
            </EuiButtonEmpty>
          </div>
        ) : (
          <div>
            <EuiButtonEmpty
              onClick={() => {
                history.push(loginUrl())
                this.closeUserMenu()
              }}
              color='text'
              className='chromeHeaderLink'
              data-test-id='LoginButton'
              iconType='user'
            >
              <FormattedMessage id='chrome-header.log-in' defaultMessage='Log in' />
            </EuiButtonEmpty>
          </div>
        )}
      </EuiPopover>
    )
  }

  renderUsername() {
    const {
      username,
      intl: { formatMessage },
    } = this.props

    if (!username) {
      return formatMessage({
        id: 'customer-profile.unauthenticated',
        defaultMessage: 'Unauthenticated',
      })
    }

    return username.toUpperCase()
  }

  toggleUserPopover() {
    const { isUserMenuOpen } = this.state
    this.setState({ isUserMenuOpen: !isUserMenuOpen })
  }

  closeUserMenu() {
    this.setState({ isUserMenuOpen: false })
  }
}

export default injectIntl(ChromeHeader)
