/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import {
  setInitialPasswordRequest,
  verifyAccountRequest,
} from '@/apps/userconsole/reducers/registry'
import { getLoginRequest } from '@/reducers/asyncRequests/registry'

import { parseCreationQueryParams } from '../../../../../lib/conversionFlow'
import { verifyAccount } from '../../../actions/account'

import PasswordUpdateViaEmailLink from './PasswordUpdateViaEmailLink'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const query = parse(location.search.slice(1))
  const activate = parseCreationQueryParams({ query })

  return {
    activate,
    email: typeof query.email === `string` ? query.email : undefined,
    expires: Number.parseInt(String(query.e), 10),
    redirectTo: typeof query.redirectTo === `string` ? query.redirectTo : undefined,
    source: typeof query.source === `string` ? query.source : undefined,
    activationHash: typeof query.ah === `string` ? query.ah : undefined,
    verificationHash: typeof query.vh === `string` ? query.vh : undefined,
    loginRequest: getLoginRequest(state),
    verifyAccountRequest: verifyAccountRequest(state),
    setInitialPasswordRequest: setInitialPasswordRequest(state),
  }
}

const mapDispatchToProps = {
  verifyAccount,
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(PasswordUpdateViaEmailLink),
)
