/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { parse } from 'query-string'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import type { StackVersionConfig } from '@modules/cloud-api/v1/types'
import type { BillingSubscriptionLevel } from '@modules/ui-types'
import { CuiLink } from '@modules/cui/Link'
import Header from '@modules/cui/Header'
import { getSubscriptionQuery } from '@modules/billing-lib/utils'

import { getDeploymentNodeConfigurations } from '@/lib/stackDeployments/selectors/topologyElements'
import { registerUrl } from '@/lib/urlBuilder'
import { getDeploymentVersionSetter, getDeploymentNameSetter } from '@/lib/stackDeployments/crud'

import PricingSteps from '../PricingSteps'
import PricedArchitectureSummary from '../../Topology/PricedArchitectureSummary'
import ExternalLink from '../../../../../components/ExternalLink'
import { contactUs, signUp } from '../../../urls'

import type { RouteComponentProps } from 'react-router'
import type { WrappedComponentProps } from 'react-intl'
import type { ReactNode } from 'react'
import type { CreateEditorComponentConsumerProps as ConsumerProps } from '../../../../../components/StackDeploymentEditor/types'

type FetchBasePricesSettings = {
  regionId: string
  level?: BillingSubscriptionLevel
  marketplace?: boolean
}

type StateProps = {
  stackVersions: StackVersionConfig[] | null
}

type DispatchProps = {
  fetchBasePrices: ({ regionId, level, marketplace }: FetchBasePricesSettings) => void
}

export type Props = StateProps &
  DispatchProps &
  ConsumerProps &
  WrappedComponentProps &
  RouteComponentProps

type State = {
  selectedSubscription: BillingSubscriptionLevel
  showMarketplacePrices: boolean
  getMarketplacePrices: boolean
}

class StackDeploymentPricingEditor extends Component<Props, State> {
  state: State = {
    selectedSubscription: this.getDefaultSubscription(),
    showMarketplacePrices: false,
    getMarketplacePrices: false,
  }

  render() {
    return (
      <EuiPage>
        <EuiPageBody>
          <EuiPageSection paddingSize='m'>
            <div className='pricingPage' data-app='appContentBody'>
              <Header
                name={
                  <FormattedMessage
                    id='pricing-calculator-page.title'
                    defaultMessage='Elastic Cloud (Elasticsearch Service) Pricing Calculator'
                  />
                }
              />
              <EuiText>
                <FormattedMessage
                  id='stack-pricing.lead-in-text'
                  defaultMessage='Use the pricing calculator to get a reference price for a given configuration of Elastic. Depending on your use case, you choose the optimal {hardwareProfileLink}. Each hardware profile is a unique blend of virtual storage, RAM, and vCPUs. Depending on the hardware profile, you adjust the size of the resources (RAM, vCPU and disk capacity) you dedicate to the constituents of the Stack. At the end of the day, Elastic Cloud simply charges against the total capacity consumed. You are also charged for data transfer and storage (DTS) costs.'
                  values={{
                    hardwareProfileLink: (
                      <ExternalLink href='https://www.elastic.co/guide/en/cloud/current/ec-configure-deployment-settings.html#ec-hardware-profiles'>
                        <FormattedMessage
                          id='stack-pricing.hardware-profile'
                          defaultMessage='hardware profile'
                        />
                      </ExternalLink>
                    ),
                  }}
                />
              </EuiText>

              <EuiSpacer />

              <EuiText>
                <FormattedMessage
                  id='stack-pricing.note-text'
                  defaultMessage={`Note that your exact optimal configuration, and consequent pricing, depends on your actual workload. If you're not sure, we recommend that you start with Elastic Cloud monthly billing and configure the resources to adequately support your workload to understand the associated cost. You can start by signing up for a {registrationLink}. Or, you can {contactUsLink} to come up with an optimal configuration, and associated price, for your use case.`}
                  values={{
                    registrationLink: (
                      <CuiLink to={registerUrl()}>
                        <FormattedMessage
                          id='stack-pricing.registration-link'
                          defaultMessage='free trial'
                        />
                      </CuiLink>
                    ),
                    contactUsLink: (
                      <ExternalLink href='https://www.elastic.co/contact'>
                        <FormattedMessage
                          id='stack-pricing.contact-us-link'
                          defaultMessage='contact us'
                        />
                      </ExternalLink>
                    ),
                  }}
                />
              </EuiText>

              <EuiSpacer />

              <EuiFlexGroup>
                <EuiFlexItem grow={3}>{this.renderSteps()}</EuiFlexItem>
                <EuiFlexItem>{this.renderPricedArchitectureSidebar()}</EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiPageSection>
        </EuiPageBody>
      </EuiPage>
    )
  }

  renderSteps() {
    const {
      showRegion,
      availableVersions,
      region,
      setRegion,
      editorState,
      onChange,
      setDeploymentTemplate,
      globalDeploymentTemplates,
      setGlobalTemplate,
      deploymentTemplates,
    } = this.props
    const { selectedSubscription, showMarketplacePrices } = this.state

    return (
      <PricingSteps
        showMarketplacePrices={showMarketplacePrices}
        toggleMarketplacePrices={(showMarketplacePrices) =>
          this.toggleMarketplacePrices(showMarketplacePrices)
        }
        editorState={editorState}
        setDeploymentName={getDeploymentNameSetter({ onChange })}
        setDeploymentTemplate={setDeploymentTemplate}
        globalDeploymentTemplates={globalDeploymentTemplates}
        setGlobalTemplate={setGlobalTemplate}
        availableVersions={availableVersions}
        setVersion={getDeploymentVersionSetter({ editorState, onChange })}
        showRegion={showRegion!}
        region={region}
        onChange={onChange}
        setRegion={setRegion}
        restoreFromSnapshot={false}
        selectedSubscription={selectedSubscription}
        deploymentTemplates={deploymentTemplates}
      />
    )
  }

  renderPricedArchitectureSidebar() {
    const { editorState } = this.props
    const { regionId } = editorState

    if (!regionId) {
      return null
    }

    const baseSummaryProps = this.getPricedSummaryProps()

    const props = {
      ...baseSummaryProps,
      sticky: true,
      showSubscriptionOptions: true,
      showMarketplacePricesToggle: this.state.showMarketplacePrices,
      onChangeSubscription: ({
        subscription,
        getMarketplacePrices,
      }: {
        subscription: BillingSubscriptionLevel
        getMarketplacePrices?: boolean
      }) => this.onChangeSubscription({ subscription, getMarketplacePrices }),
    }

    return <PricedArchitectureSummary {...props} />
  }

  getPricedSummaryProps() {
    const startTrialButton = (
      <EuiFlexGroup className='pricing-summary-trialButtons' justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiButton
            rel='noopener'
            fill={false}
            href={contactUs}
            data-test-id='pricing-page-contact-sales-btn'
          >
            <FormattedMessage id='stack-pricing.contact-sales' defaultMessage='Contact Sales' />
          </EuiButton>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            rel='noopener'
            fill={true}
            data-test-id='pricing-page-start-trial-btn'
            href={signUp({ baymax: `cloud`, storm: `cta1`, elektra: `pricing-page` })}
          >
            <FormattedMessage id='stack-pricing.start-trial' defaultMessage='Start trial' />
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    )

    const disclaimer = (
      <div className='pricing-disclaimer'>
        <EuiSpacer size='s' />

        <EuiText size='xs'>
          <FormattedMessage
            id='create-deployment-configure.small-price-disclaimer'
            defaultMessage='* {dataTransfer} fees may apply.'
            values={{
              dataTransfer: (
                <ExternalLink href='https://www.elastic.co/blog/elasticsearch-service-data-transfer-and-snapshot-storage-pricing'>
                  <FormattedMessage
                    id='stack-pricing.data-transfer'
                    defaultMessage='Data transfer and snapshot storage'
                  />
                </ExternalLink>
              ),
            }}
          />
        </EuiText>

        <EuiSpacer size='s' />

        <EuiText size='xs'>
          <FormattedMessage
            id='create-deployment-configure.contact-for-discount'
            defaultMessage='{contactUs} to discuss discounting for prepaid commitments and additional purchasing options.'
            values={{
              contactUs: (
                <ExternalLink href='https://www.elastic.co/contact#sales'>
                  <FormattedMessage
                    id='contact-for-discount.contact-us'
                    defaultMessage='Contact us'
                  />
                </ExternalLink>
              ),
            }}
          />
        </EuiText>
      </div>
    )

    const { editorState } = this.props
    const { selectedSubscription } = this.state
    const { deployment, deploymentTemplate, regionId } = editorState

    const instanceConfigurations =
      (deploymentTemplate && deploymentTemplate.instance_configurations) || []

    const nodeConfigurations = getDeploymentNodeConfigurations({ deployment })

    return {
      regionId: regionId!,
      selectedSubscription,
      instanceConfigurations,
      nodeConfigurations,
      disclaimer,
      actionButton: startTrialButton,
      emptyDeploymentMessage: (
        <Fragment>
          <EuiSpacer />
          <EuiText>
            <FormattedMessage
              id='stack-deployment-pricing-editor.empty-deployment'
              defaultMessage='Please select a solution to see pricing.'
            />
          </EuiText>
        </Fragment>
      ),
      render: (className: string, content: ReactNode) => (
        <EuiFlexItem grow={false} className={className}>
          {content}
        </EuiFlexItem>
      ),
    }
  }

  onChangeSubscription({
    subscription,
    getMarketplacePrices,
  }: {
    subscription: BillingSubscriptionLevel
    getMarketplacePrices?: boolean
  }) {
    const { fetchBasePrices, editorState } = this.props
    const { regionId } = editorState

    if (regionId) {
      this.setState({ selectedSubscription: subscription })
      fetchBasePrices({ regionId, level: subscription, marketplace: getMarketplacePrices })
    }
  }

  getDefaultSubscription() {
    const {
      location: { search },
    } = this.props
    const { level } = parse(search.slice(1))
    return getSubscriptionQuery(Array.isArray(level) ? level[0] : level)
  }

  toggleMarketplacePrices(showMarketplacePrices) {
    this.setState({
      showMarketplacePrices,
      getMarketplacePrices: showMarketplacePrices,
    })
  }
}

export default injectIntl(StackDeploymentPricingEditor)
