/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { mapValues, keyBy, pickBy } from 'lodash'

import type { Dictionary } from '@modules/ts-essentials'

import { getCookie } from './cookies'

const marketoCookieKeys = [
  'mktg_camp',
  'mktg_cnt',
  'mktg_cta',
  'mktg_gclid',
  'mktg_q',
  'mktg_mdm',
  'mktg_pg',
  'mktg_plcmt',
  'mktg_src',
  'mktg_tech',
  'mktg_trm',
  'utm_campaign_1pc',
  'utm_source_1pc',
  'utm_medium_1pc',
  'utm_term_1pc',
  'utm_content_1pc',
  'utm_id_1pc',
  'euid',
]

export function getMarketoTrackingParamsFromCookies(): Dictionary<string | undefined> {
  return pickBy(mapValues(keyBy(marketoCookieKeys), getCookie))
}
