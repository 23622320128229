/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

import {
  EuiCallOut,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSwitch,
  EuiText,
  useEuiTheme,
} from '@elastic/eui'

import {
  DEFAULT_MAXIMUM_RETENTION_DAYS,
  MAX_MAXIMUM_RETENTION_DAYS,
  MIN_MAXIMUM_RETENTION_DAYS,
} from '@modules/project-lib/dataRetention'
import { useToggle } from '@modules/utils/hooks/useToggle'

import type { FC } from 'react'

const messages = defineMessages({
  calloutTitle: {
    id: `maximumDataRetentionPeriodSection.calloutTitle`,
    defaultMessage: `Editing this setting can affect your project data`,
  },
  calloutText: {
    id: `maximumDataRetentionPeriodSection.calloutText`,
    defaultMessage: `Editing this setting will replace the data retention set for all data streams of this project that currently have a longer data retention defined. <strong>Data older than the new maximum retention period you set will be permanently deleted.</strong>`,
  },
  sectionDescription: {
    id: `maximumDataRetentionPeriodSection.sectionDescription`,
    defaultMessage: `Configure the maximum length of time that data can be retained in any data streams of this project.`,
  },
  switchLabel: {
    id: `maximumDataRetentionPeriodSection.switchLabel`,
    defaultMessage: `Enforce maximum data retention period`,
  },
  retentionPeriodLabel: {
    id: `maximumDataRetentionPeriodSection.retentionPeriodLabel`,
    defaultMessage: `Maximum data retention period`,
  },
  retentionPeriodUnit: {
    id: `maximumDataRetentionPeriodSection.retentionPeriodUnit`,
    defaultMessage: `days`,
  },
})

type MaximumDataRetentionPeriodSectionProps = {
  onChange: (value: number) => void
  value: number
  error?: string
}

const MaximumDataRetentionPeriodSection: FC<MaximumDataRetentionPeriodSectionProps> = ({
  onChange,
  value,
  error,
}) => {
  const { formatMessage } = useIntl()

  const {
    euiTheme: {
      font: {
        weight: { semiBold },
      },
    },
  } = useEuiTheme()

  const [isSwitchChecked, toggleSwitchChecked] = useToggle(Number.isFinite(value))

  return (
    <EuiFlexGroup direction='column' gutterSize='l'>
      <EuiFlexItem>
        <EuiSwitch
          label={
            <EuiText
              css={{
                fontWeight: semiBold,
              }}
            >
              {formatMessage(messages.switchLabel)}
            </EuiText>
          }
          checked={isSwitchChecked}
          onChange={() => {
            onChange(isSwitchChecked ? Infinity : DEFAULT_MAXIMUM_RETENTION_DAYS)

            toggleSwitchChecked()
          }}
        />
      </EuiFlexItem>
      {isSwitchChecked && (
        <EuiFlexItem>
          <EuiCallOut
            iconType='alert'
            color='warning'
            title={formatMessage(messages.calloutTitle)}
            heading='h3'
          >
            <p>
              {formatMessage(messages.calloutText, {
                strong: (msg) => <strong>{msg}</strong>,
              })}
            </p>
          </EuiCallOut>
        </EuiFlexItem>
      )}
      <EuiFlexItem>
        <EuiText size='s'>{formatMessage(messages.sectionDescription)}</EuiText>
      </EuiFlexItem>
      {isSwitchChecked && (
        <EuiFlexItem>
          <EuiFormRow
            label={formatMessage(messages.retentionPeriodLabel)}
            error={error}
            isInvalid={error !== undefined}
          >
            <EuiFieldNumber
              min={MIN_MAXIMUM_RETENTION_DAYS}
              max={MAX_MAXIMUM_RETENTION_DAYS}
              append={formatMessage(messages.retentionPeriodUnit)}
              step={1}
              value={Number.isInteger(value) ? (value as number) : ''}
              onChange={(e) => {
                onChange(parseInt(e.target.value, 10))
              }}
              fullWidth={true}
            />
          </EuiFormRow>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default MaximumDataRetentionPeriodSection
