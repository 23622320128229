/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'query-string'
import { omit } from 'lodash'

import type { CreateSaasUserRequest } from '@modules/cloud-api/v1/types'
import { asAjaxRequestError } from '@modules/utils/ajax'
import type { AsyncRequestError } from '@modules/ui-types'

import {
  applyGoogleTrackingV4ForSocialLoginSubmitEvent,
  socialLoginSubmitEvent,
  socialSignUpSubmitEvent,
} from '@/apps/userconsole/lib/googleTracking'
import { getMarketoTrackingParamsFromCookies } from '@/lib/marketo'

type UserInvitePayloadKey =
  | 'has_accepted_terms_and_policies'
  | 'wants_informational_emails'
  | 'tracking_data'
  | 'source'
  | 'settings'

export const getCreateUserPayload = (
  queryString: string,
): Pick<CreateSaasUserRequest, UserInvitePayloadKey> => {
  const query = parse(queryString.slice(1))

  const { source, settings, ...rest } = query
  const trackingData = { ...omit(rest, `fromURI`), ...getMarketoTrackingParamsFromCookies() }

  return {
    has_accepted_terms_and_policies: true,
    wants_informational_emails: true,
    tracking_data: trackingData,
    source: typeof source === `string` ? source : undefined,
    settings: typeof settings === `string` ? settings : undefined,
  }
}

export const applyGoogleAnalytics = ({ isCreateUser }: { isCreateUser?: boolean }) => {
  if (isCreateUser) {
    applyGoogleTrackingV4ForSocialLoginSubmitEvent(socialSignUpSubmitEvent)

    return
  }

  applyGoogleTrackingV4ForSocialLoginSubmitEvent(socialLoginSubmitEvent)
}

export const isRequireActivationError = (error: AsyncRequestError): boolean => {
  const errorReasons = asAjaxRequestError(error)?.body?.errors ?? []
  return errorReasons.some((each) => each.code === `auth.activation_required`)
}
