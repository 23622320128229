/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'

import { googleReCaptchaEnterpriseUrl } from '@/apps/userconsole/urls'

import type { AllProps as Props } from './types'
import type { ReactChild } from 'react'

interface State {
  error: ReactChild | null
  isVerifyingCaptcha: boolean
}

class GoogleReCaptchaProvider extends Component<Props, State> {
  state = {
    error: null,
    isVerifyingCaptcha: false,
  }

  componentDidMount() {
    const { googleReCaptchaEnterpriseKey } = this.props
    const scriptSrc = `${googleReCaptchaEnterpriseUrl}?render=${googleReCaptchaEnterpriseKey}`
    const script = document.createElement('script')

    script.src = scriptSrc
    script.async = true
    document.body.appendChild(script)
  }

  render() {
    const { children } = this.props
    const { error, isVerifyingCaptcha } = this.state
    return children({
      captchaError: error,
      verifyCaptcha: this.verifyCaptcha,
      isVerifyingCaptcha,
    })
  }

  verifyCaptcha = ({ isActivatePendingUser }: { isActivatePendingUser?: boolean } = {}) => {
    const { action, googleReCaptchaEnterpriseKey } = this.props

    if (!googleReCaptchaEnterpriseKey) {
      this.onVerifyCaptcha()
      return
    }

    this.setState({ isVerifyingCaptcha: true, error: null }, () => {
      const grecaptcha = window.grecaptcha

      if (grecaptcha) {
        try {
          grecaptcha.enterprise.ready(() => {
            grecaptcha.enterprise
              .execute(googleReCaptchaEnterpriseKey, { action })
              .then((token) => {
                this.setState({ isVerifyingCaptcha: false, error: null }, () => {
                  this.onVerifyCaptcha(token, { isActivatePendingUser })
                })
              })
          })
        } catch (e) {
          this.onVerifyCaptchaError()
        }
      } else {
        this.onVerifyCaptchaError()
      }
    })
  }

  onVerifyCaptchaError = () => {
    this.setState({
      isVerifyingCaptcha: false,
      error: (
        <CuiAlert type='error'>
          <FormattedMessage
            id='google-recaptcha-provider'
            defaultMessage="Something went wrong. Please try again, or email {support} if you're still having trouble."
            values={{
              support: (
                <EuiLink href='mailto:support@elastic.co?subject=Elastic%20Cloud%20Signup%20Query'>
                  support@elastic.co
                </EuiLink>
              ),
            }}
          />
        </CuiAlert>
      ),
    })
  }

  onVerifyCaptcha = (
    token?: string,
    { isActivatePendingUser }: { isActivatePendingUser?: boolean } = {},
  ) => {
    const { onVerifyCaptcha } = this.props
    onVerifyCaptcha(token, { isActivatePendingUser })
  }
}

export default GoogleReCaptchaProvider
