/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, useEuiTheme } from '@elastic/eui'

import useFlowV2BackgroundDecoration from '@modules/utils/hooks/background'

import Header from './Header'

import type { ReactNode, FunctionComponent } from 'react'
import type { LandingPageContext } from './types'

interface Props {
  children?: ReactNode
  pageContext?: LandingPageContext
  onPageContextChange?: (type: LandingPageContext) => void
  isLoading?: boolean
  isFlowV2?: boolean
  fullHeight?: boolean
}

const LandingPageOuterContainer: FunctionComponent<Props> = ({
  children,
  isLoading,
  pageContext,
  onPageContextChange,
  isFlowV2,
  fullHeight = true,
}) => {
  const { euiTheme, colorMode } = useEuiTheme()

  const containerStyle = css([
    {
      padding: euiTheme.size.xl,
    },
    fullHeight && {
      minHeight: '100%',
    },
  ])

  const backgroundDecoration = useFlowV2BackgroundDecoration()

  if (isFlowV2) {
    return (
      <EuiFlexGroup
        direction='column'
        css={[containerStyle, backgroundDecoration]}
        data-test-id='landing-page-container-new'
      >
        {!isLoading && (
          <div
            css={css({
              position: 'absolute',
              paddingLeft: euiTheme.size.xl,
              paddingRight: euiTheme.size.xl,
              right: 0,
              left: 0,
            })}
          >
            <Header
              pageContext={pageContext}
              onPageContextSwitch={onPageContextChange}
              isFlowV2={isFlowV2}
            />
          </div>
        )}

        <EuiFlexItem
          css={{
            paddingTop: euiTheme.size.xxxxl,
            paddingBottom: '110px',
          }}
        >
          <EuiFlexGroup justifyContent='center' alignItems='stretch' direction='column'>
            <div>{children}</div>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <EuiFlexGroup
      direction='column'
      css={[
        containerStyle,
        css({
          backgroundColor:
            colorMode === 'LIGHT' ? euiTheme.colors.lightestShade : euiTheme.colors.emptyShade,
        }),
      ]}
      data-test-id='landing-page-container-old'
    >
      {!isLoading && (
        <EuiFlexItem grow={false} css={css({ position: 'relative' })}>
          <Header pageContext={pageContext} onPageContextSwitch={onPageContextChange} />
        </EuiFlexItem>
      )}

      <EuiSpacer size='l' />

      <div>{children}</div>
    </EuiFlexGroup>
  )
}

export default LandingPageOuterContainer
