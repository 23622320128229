/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { SaasAuthResponse } from '@modules/cloud-api/v1/types'

import {
  getVerifySaasCurrentUserMfaFactorRequest,
  fetchMfaDevicesRequest,
} from '@/reducers/asyncRequests/registry'
import { getMfa } from '@/reducers/auth'
import { getMfaEnabled, getMfaDevices } from '@/apps/userconsole/reducers/mfa'
import {
  enableMfaDeviceRequest,
  disableMfaDeviceRequest,
} from '@/apps/userconsole/reducers/registry'

import { disableMfa, enableMfa, fetchMfaDevices, fetchMfaStatus } from '../../actions/mfa'
import {
  challengeSaasCurrentUserMfaFactor,
  verifySaasCurrentUserMfaFactor,
  resetVerifySaasCurrentUserMfaFactorRequest,
} from '../../../../actions/auth'

import AccountSecurity from './AccountSecurity'

import type { ThunkDispatch, AsyncAction } from '@/types/redux'

type DispatchProps = {
  fetchMfaStatus: () => Promise<unknown>
  enableMfa: () => Promise<unknown>
  disableMfa: () => Promise<unknown>
  fetchMfaDevices: () => Promise<unknown>
  challengeSaasCurrentUserMfaFactor: (args: {
    device_id: string
  }) => Promise<AsyncAction<'CHALLENGE_CURRENT_USER_MFA_FACTOR', unknown>>
  verifySaasCurrentUserMfaFactor: (args: {
    device_id: string
    pass_code: string
  }) => Promise<AsyncAction<'VERIFY_CURRENT_USER_MFA_FACTOR', SaasAuthResponse>>
  resetVerifySaasCurrentUserMfaFactorRequest: () => void
}

const mapStateToProps = (state) => ({
  mfaEnabled: getMfaEnabled(state),
  mfaDevices: getMfaDevices(state),
  mfa: getMfa(state),
  fetchMfaDevicesRequest: fetchMfaDevicesRequest(state),
  disableMfaDeviceRequest: disableMfaDeviceRequest(state),
  enableMfaDeviceRequest: enableMfaDeviceRequest(state),
  verifySaasCurrentUserMfaFactorRequest: getVerifySaasCurrentUserMfaFactorRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchMfaStatus: () => dispatch(fetchMfaStatus()),
  enableMfa: () => dispatch(enableMfa()),
  disableMfa: () => dispatch(disableMfa()),
  resetVerifySaasCurrentUserMfaFactorRequest: () =>
    dispatch(resetVerifySaasCurrentUserMfaFactorRequest()),
  fetchMfaDevices: () => dispatch(fetchMfaDevices()),
  challengeSaasCurrentUserMfaFactor: ({ device_id }) =>
    dispatch(challengeSaasCurrentUserMfaFactor({ device_id })),
  verifySaasCurrentUserMfaFactor: ({ device_id, pass_code }) =>
    dispatch(verifySaasCurrentUserMfaFactor({ device_id, pass_code })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSecurity)
