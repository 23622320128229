/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Component } from 'react'
import { flatMap } from 'lodash'

import { EuiSpacer } from '@elastic/eui'

import type { Invoice, Statement } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui/Table'
import type { Props as CuiTableProps, PaginationProps } from '@modules/cui/Table/types'

import BillingTablePagination from '../BillingTablePagination'
import { getFullYear } from '../lib/date'

import type { ReactElement } from 'react'

type Document = Statement | Invoice

export interface Props extends CuiTableProps<Document> {
  documents: Document[]
}

interface State {
  filterByYear?: number
}

class BillingDocuments extends Component<Props, State> {
  state = {
    filterByYear: undefined,
  }

  render(): ReactElement | null {
    return (
      <CuiTable
        pageSize={5}
        rows={this.getGridRows()}
        renderPagination={this.renderPagination}
        {...this.props}
      />
    )
  }

  renderPagination = ({ activePage, pageCount, onChangePage }: PaginationProps): ReactElement => {
    const billingYears = this.getBillingYears()
    return (
      <Fragment>
        <EuiSpacer size='m' />

        <BillingTablePagination
          billingYears={billingYears.sort()}
          activePage={activePage}
          pageCount={pageCount}
          onChangePage={onChangePage}
          onFilterByYear={this.onFilterByYear}
        />
      </Fragment>
    )
  }

  getGridRows(): Document[] {
    const { documents } = this.props
    const { filterByYear } = this.state

    if (!documents) {
      return []
    }

    if (!filterByYear) {
      return documents
    }

    return documents.filter((statement) => {
      const [startYear, endYear] = this.getDocumentYears(statement)
      return filterByYear === startYear || filterByYear === endYear
    })
  }

  getBillingYears(): number[] {
    const { documents } = this.props
    return [...new Set<number>(flatMap(documents, (document) => this.getDocumentYears(document)))]
  }

  getDocumentYears({ period_start_date, period_end_date }: Document): number[] {
    const startYear = getFullYear(period_start_date)
    const endYear = getFullYear(period_end_date)

    return [startYear, endYear]
  }

  onFilterByYear = (filterByYear?: number): void => {
    this.setState({ filterByYear })
  }
}

export default BillingDocuments
