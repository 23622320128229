/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { debounce } from 'lodash'

import { EuiFieldSearch } from '@elastic/eui'

const debounced = debounce((cb) => cb(), 300)

const messages = defineMessages({
  filterProjects: {
    id: 'roles-assignments.filter-instances',
    defaultMessage: 'Filter by name or ID',
  },
})

const ProjectRoleAssignmentsFilter: React.FunctionComponent<{
  onChange: (filterQuery: string) => void
}> = ({ onChange }) => {
  const { formatMessage } = useIntl()

  return (
    <EuiFieldSearch
      aria-label={formatMessage(messages.filterProjects)}
      placeholder={formatMessage(messages.filterProjects)}
      fullWidth={true}
      onChange={(e) => debounced(() => onChange(e.target.value))}
    />
  )
}

export default ProjectRoleAssignmentsFilter
