/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { useGetBillingDetails } from '@modules/billing-lib/hooks'

import { BillingDetailsContext } from '../BillingDetailsContext'

import type { BillingDetailsContextProps } from '../types'
import type { ConsumerProps } from './types'

const BillingDetailsProvider = ({ organization_id, children }: ConsumerProps) => {
  const { data, isFetching, isError, refetch } = useGetBillingDetails(
    { pathParameters: { organization_id } },
    { enabled: !!organization_id },
  )

  const getBillingDetailsData = (): BillingDetailsContextProps => ({
    loading: isFetching || !organization_id,
    error: isError,
    data,
    refetch,
  })

  return (
    <BillingDetailsContext.Provider value={getBillingDetailsData()}>
      {children}
    </BillingDetailsContext.Provider>
  )
}

export default BillingDetailsProvider
