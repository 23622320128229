/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { SearchOptimizationOptionInfo } from './types'

const searchOptimizationOptions: SearchOptimizationOptionInfo[] = [
  {
    slug: 'general_purpose',
    name: {
      id: 'createProject.subType.generalPurpose.name',
      defaultMessage: 'General purpose',
    },
    title: {
      id: 'createProject.subType.generalPurpose.title',
      defaultMessage: 'General purpose',
    },
    description: {
      id: 'createProject.subType.generalPurpose.description',
      defaultMessage: 'Suitable for general search use cases across various data types.',
    },
    disabled: false,
  },
  {
    slug: 'vector',
    name: { id: 'createProject.subType.vector.name', defaultMessage: 'Vector Search' },
    title: { id: 'createProject.subType.vector.title', defaultMessage: 'Optimized for Vectors' },
    description: {
      id: 'createProject.subType.vector.description',
      defaultMessage: 'Suitable for search use cases using vectors and near real-time retrieval.',
    },
    disabled: false,
  },
  {
    slug: 'timeseries',
    name: { id: 'createProject.subType.timeseries.name', defaultMessage: 'Time Series' },
    title: {
      id: 'createProject.subType.timeseries.title',
      defaultMessage: 'Optimized for Time Series',
    },
    description: {
      id: 'createProject.subType.timeseries.description',
      defaultMessage:
        'Suitable for retention and analysis of high volume time-series data, such as logs or other data streams.',
    },
    disabled: true,
  },
]

export default searchOptimizationOptions
