/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FETCH_CLUSTER_HEALTH } from '../../constants/actions'

import type { ReduxState } from '@/types/redux'
import type { ClusterHealth, FetchClusterHealthAction } from './clusterTypes'

export interface State {
  [descriptor: string]: ClusterHealth | null
}

const createClusterHealth = (source: FetchClusterHealthAction['payload']): ClusterHealth => ({
  status: source.status,
  shards: {
    active: source.active_shards,
    unassigned: source.unassigned_shards,
    initializing: source.initializing_shards,
    relocating: source.relocating_shards,
  },
})

function clusterHealthReducer(
  clusterState: ClusterHealth | null = null,
  action: FetchClusterHealthAction,
): ClusterHealth | null {
  switch (action.type) {
    case FETCH_CLUSTER_HEALTH:
      if (action.payload) {
        return {
          ...clusterState,
          ...createClusterHealth(action.payload),
        }
      }

      return clusterState

    default:
      return clusterState
  }
}

export default function clusterHealthsReducer(
  state: State = {},
  action: FetchClusterHealthAction,
): State {
  if (action.error || !action.payload) {
    return state
  }

  switch (action.type) {
    case FETCH_CLUSTER_HEALTH:
      const { deploymentId } = action.meta

      return {
        ...state,
        [deploymentId]: clusterHealthReducer(state[deploymentId], action),
      }

    default:
      return state
  }
}

function _getClusterHealth(state: State, deploymentId: string): ClusterHealth | null {
  return state[deploymentId] || null
}

export const getClusterHealth = (state: ReduxState, deploymentId: string) =>
  _getClusterHealth(state.clusterHealth, deploymentId)
