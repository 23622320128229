/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiText, EuiSpacer, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type {
  DeploymentCreateRequest,
  DeploymentGetResponse,
  DeploymentUpdateRequest,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement, SliderInstanceType } from '@modules/ui-types'

import TopologyPanel from '@/components/Topology/DeploymentTemplates/components/DeploymentInfrastructure/TopologyElement/TopologyPanel'
import {
  isHot,
  isFrozen,
  isDedicatedML,
  isDedicatedMaster,
  getNodeRoles,
} from '@/lib/stackDeployments/selectors/nodeRoles'
import { isAutoscaleableTier } from '@/lib/stackDeployments/selectors/autoscaling'
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'
import {
  getSliderUserSettings,
  getSliderPlan,
} from '@/lib/stackDeployments/selectors/stackDeployment'
import { getSliderDefinition } from '@/lib/sliders/definitions'

import ScrollIntoView from '../../../../../ScrollIntoView'
import { getDefaultUserSettings } from '../../../../../../lib/stackDeployments/userSettings'
import { hashMatchesNodeConfiguration } from '../../../../../../lib/stackDeployments/topology'
import { USER_SETTINGS_DOC_LINKS } from '../helpers'

import Messages from './Messages'
import Summary from './Summary'
import NormalizeSizing from './NormalizeSizing'
import SizePicker from './SizePicker'
import ZoneCount from './ZoneCount'
import UserSettings from './UserSettings'
import TopologyPanelRow from './TopologyPanelRow'
import Heading from './Heading'
import {
  getBlobStorageHelpText,
  makeAutoscalingMaxSizePickerOptions,
  makeAutoscalingMinSizePickerOptions,
  makeCurrentSizePickerOptions,
} from './SizePicker/helpers'
import CpuInfo from './SizePicker/CpuInfo'
import MLAutoscalingInfo from './SizePicker/MLAutoscalingInfo'
import ForecastWindow from './ForecastWindow'

import type { NormalizeSizingProps } from './NormalizeSizing'
import type { WrappedComponentProps } from 'react-intl'

export type AllProps = {
  id: string
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentUnderEdit?: DeploymentGetResponse
  sliderInstanceType: SliderInstanceType
  topologyElement: AnyTopologyElement
  templateTopologyElement?: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  onChange: undefined | ((path: string[], value: any) => void) // path is scoped to the topology element
  onPlanChange: undefined | ((path: string[], value: any) => void) // path is scoped to the parent plan
  onlyShowPricingFactors: boolean
  inTrial: boolean
  isAutoscalingEnabled: boolean
  maxZoneCount: number
  dedicatedMasterThreshold?: number
  subscription?: string | null
  maxInstanceCountForEnvironment: number
  showUserSettings: boolean
  maxInstanceCount?: number
  isReadOnly?: boolean
  isICInTemplate: boolean
}

type Props = AllProps & WrappedComponentProps

type State = {
  isTitlePopoverOpen: boolean
  isAdvancedAutoscalingOpen: boolean
}

class TopologyElement extends Component<Props, State> {
  state = {
    isTitlePopoverOpen: false,
    isAdvancedAutoscalingOpen: false,
  }

  render(): JSX.Element {
    const { topologyElement, instanceConfiguration, onlyShowPricingFactors, showUserSettings } =
      this.props

    const headerContent = (
      <React.Fragment>
        <Heading {...this.props} />
        <Messages {...this.props} />
      </React.Fragment>
    )

    return (
      <ScrollIntoView
        whenLocationMatches={(hash) => hashMatchesNodeConfiguration(hash, topologyElement)}
      >
        <div
          data-test-subj='topologyElement'
          data-test-topologyelementid={topologyElement.id}
          data-id={instanceConfiguration.name}
        >
          <TopologyPanel headerContent={headerContent} footerContent={this.renderSummary()}>
            {this.shouldDisplayAutoscalingSettings()
              ? this.renderAutoscalingFields()
              : this.renderStaticSizeFields()}

            {this.renderAvailabilityZones()}

            {showUserSettings && !onlyShowPricingFactors && this.renderUserSettings()}
          </TopologyPanel>
        </div>
      </ScrollIntoView>
    )
  }

  renderAutoscalingFields(): JSX.Element {
    return (
      <Fragment>
        {this.renderMinSize()}
        {this.renderSize()}
        {this.renderMaxSize()}
        {this.renderForecastWindow()}
      </Fragment>
    )
  }

  renderStaticSizeFields(): JSX.Element {
    return <Fragment>{this.renderSize()}</Fragment>
  }

  renderMinSize(): JSX.Element | null {
    const {
      deployment,
      instanceConfiguration,
      maxInstanceCountForEnvironment,
      topologyElement,
      maxInstanceCount,
      intl: { formatMessage },
    } = this.props

    if (!this.shouldDisplayAutoscalingSettings()) {
      return null
    }

    if (!(topologyElement as ElasticsearchClusterTopologyElement).autoscaling_min) {
      return null
    }

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-minSizeLabel'
        defaultMessage='Minimum size <nowrap>per zone</nowrap>'
        values={{
          nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
        }}
      />
    )

    const ariaLabel = formatMessage({
      id: 'deploymentTemplateInfrastructure-templateTopologyElement-minSizeLabel.aria-label',
      defaultMessage: 'Minimum size per zone',
    })

    const sliderInstanceType = instanceConfiguration.instance_type
    const sliderNodeTypes = instanceConfiguration.node_types
    const isBlobStorage = isFrozen({ topologyElement })

    const controlRenderer = (normalizeSizingProps: NormalizeSizingProps) => (
      <EuiFlexGroup responsive={false} gutterSize='s' alignItems='baseline'>
        <EuiFlexItem>
          <SizePicker
            data-test-id='topologyElement-autoscalingMinSize'
            {...normalizeSizingProps}
            options={makeAutoscalingMinSizePickerOptions({
              ...normalizeSizingProps,
              deployment,
              topologyElement,
              sliderInstanceType,
              sliderNodeTypes,
              isBlobStorage,
              canShowZero: true,
            })}
            maxInstanceCount={
              maxInstanceCount
                ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
                : maxInstanceCountForEnvironment
            }
            topologyElement={topologyElement}
            sliderInstanceType={sliderInstanceType}
            sliderNodeTypes={sliderNodeTypes}
            isBlobStorage={isBlobStorage}
            // overrides to have SizePicker get/set `autoscaling_min` instead of `size`
            size={normalizeSizingProps.autoscalingMinSize || 0}
            onChangeSize={
              normalizeSizingProps.onChangeAutoscaling &&
              ((minValue) => normalizeSizingProps.onChangeAutoscaling!({ minValue }))
            }
            aria-label={ariaLabel}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: '2rem' }} />
      </EuiFlexGroup>
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow alignItems='baseline' label={label}>
          <NormalizeSizing
            {...this.props}
            instanceConfiguration={instanceConfiguration}
            autoscalingSizeFilter='constrainToLimits'
          >
            {controlRenderer}
          </NormalizeSizing>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderMaxSize(): JSX.Element | null {
    const {
      deployment,
      instanceConfiguration,
      maxInstanceCountForEnvironment,
      topologyElement,
      maxInstanceCount,
      intl: { formatMessage },
    } = this.props

    if (!this.shouldDisplayAutoscalingSettings()) {
      return null
    }

    if (!(topologyElement as ElasticsearchClusterTopologyElement).autoscaling_max) {
      return null
    }

    const label = (
      <FormattedMessage
        id='deploymentTemplateInfrastructure-templateTopologyElement-maxSizeLabel'
        defaultMessage='Maximum size <nowrap>per zone</nowrap>'
        values={{
          nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
        }}
      />
    )

    const ariaLabel = formatMessage({
      id: 'deploymentTemplateInfrastructure-templateTopologyElement-maxSizeLabel.aria-label',
      defaultMessage: 'Maximum size per zone',
    })

    const sliderInstanceType = instanceConfiguration.instance_type
    const sliderNodeTypes = instanceConfiguration.node_types
    const isBlobStorage = isFrozen({ topologyElement })

    const controlRenderer = (normalizeSizingProps: NormalizeSizingProps) => (
      <EuiFlexGroup responsive={false} gutterSize='s' alignItems='baseline'>
        <EuiFlexItem>
          <SizePicker
            data-test-id='topologyElement-autoscalingMaxSize'
            {...normalizeSizingProps}
            options={makeAutoscalingMaxSizePickerOptions({
              ...normalizeSizingProps,
              deployment,
              topologyElement,
              sliderInstanceType,
              sliderNodeTypes,
              isBlobStorage,
              canShowZero: true,
            })}
            maxInstanceCount={
              maxInstanceCount
                ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
                : maxInstanceCountForEnvironment
            }
            topologyElement={topologyElement}
            sliderInstanceType={sliderInstanceType}
            sliderNodeTypes={sliderNodeTypes}
            isBlobStorage={isBlobStorage}
            // overrides to have SizePicker get/set `autoscaling_max` instead of `size`
            size={normalizeSizingProps.autoscalingMaxSize || 0}
            onChangeSize={
              normalizeSizingProps.onChangeAutoscaling &&
              ((maxValue) => normalizeSizingProps.onChangeAutoscaling!({ maxValue }))
            }
            helpText={
              <FormattedMessage
                id='deploymentInfrastructure-topologyElement-autoscalingMaxHelpText'
                defaultMessage='We will never autoscale this tier beyond the maximum size per zone.'
              />
            }
            aria-label={ariaLabel}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: '2rem' }}>
          {isDedicatedML({ topologyElement }) && <MLAutoscalingInfo />}
        </EuiFlexItem>
      </EuiFlexGroup>
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow alignItems='baseline' label={label}>
          <NormalizeSizing
            {...this.props}
            instanceConfiguration={instanceConfiguration}
            autoscalingSizeFilter='constrainToLimits'
          >
            {controlRenderer}
          </NormalizeSizing>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderSize(): JSX.Element | null {
    const {
      deployment,
      instanceConfiguration,
      maxInstanceCountForEnvironment,
      topologyElement,
      maxInstanceCount,
      isAutoscalingEnabled,
      intl: { formatMessage },
    } = this.props

    if (
      this.shouldDisplayAutoscalingSettings() &&
      (topologyElement as ElasticsearchClusterTopologyElement).autoscaling_min
    ) {
      return null
    }

    const label = (() => {
      if (this.shouldDisplayAutoscalingSettings()) {
        return this.props.deploymentUnderEdit
          ? {
              formatted: (
                <FormattedMessage
                  id='deploymentInfrastructure-topologyElement-currentSizeLabel'
                  defaultMessage='Current size <nowrap>per zone</nowrap>'
                  values={{
                    nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
                  }}
                />
              ),
              ariaLabel: formatMessage({
                id: 'deploymentInfrastructure-topologyElement-currentSizeLabel.aria-label',
                defaultMessage: 'Current size per zone',
              }),
            }
          : {
              formatted: (
                <FormattedMessage
                  id='deploymentInfrastructure-topologyElement-initialSizeLabel'
                  defaultMessage='Initial size <nowrap>per zone</nowrap>'
                  values={{
                    nowrap: (content) => <span style={{ whiteSpace: 'nowrap' }}>{content}</span>,
                  }}
                />
              ),
              ariaLabel: formatMessage({
                id: 'deploymentInfrastructure-topologyElement-initialSizeLabel.aria-label',
                defaultMessage: 'Initial size per zone',
              }),
            }
      }

      return {
        formatted: (
          <FormattedMessage
            id='deploymentInfrastructure-topologyElement-sizeLabel'
            defaultMessage='Size per zone'
          />
        ),
        ariaLabel: formatMessage({
          id: 'deploymentInfrastructure-topologyElement-sizeLabel.aria-label',
          defaultMessage: 'Size per zone',
        }),
      }
    })()

    const sliderInstanceType = instanceConfiguration.instance_type
    const sliderNodeTypes = instanceConfiguration.node_types
    const isBlobStorage = isFrozen({ topologyElement })

    // autoscaling components show zero in the dropdown; otherwise we render the panel X
    const version = getUpsertVersion({ deployment })
    const canShowZero = isAutoscalingEnabled && isAutoscaleableTier({ topologyElement, version })

    const controlRenderer = (normalizeSizingProps: NormalizeSizingProps) => (
      <EuiFlexGroup responsive={false} gutterSize='s' alignItems='baseline'>
        <EuiFlexItem>
          <SizePicker
            data-test-id='topologyElement-currentSize'
            {...normalizeSizingProps}
            options={makeCurrentSizePickerOptions({
              ...normalizeSizingProps,
              deployment,
              topologyElement,
              sliderInstanceType,
              sliderNodeTypes,
              isBlobStorage,
              canShowZero,
              isAutoscalingEnabled,
            })}
            maxInstanceCount={
              maxInstanceCount
                ? Math.min(maxInstanceCount, maxInstanceCountForEnvironment)
                : maxInstanceCountForEnvironment
            }
            topologyElement={topologyElement}
            sliderInstanceType={sliderInstanceType}
            sliderNodeTypes={sliderNodeTypes}
            isBlobStorage={isBlobStorage}
            helpText={
              <Fragment>
                {getBlobStorageHelpText({
                  resourceIn: normalizeSizingProps.resource,
                  size: normalizeSizingProps.size,
                  storageMultiplier: normalizeSizingProps.storageMultiplier,
                  isBlobStorage,
                })}{' '}
                {this.shouldDisplayAutoscalingSettings() && (
                  <FormattedMessage
                    id='deploymentInfrastructure-topologyElement-autoscalingCurrentSizeHelpText'
                    defaultMessage='To maintain this size while autoscaling is enabled, set the maximum size per zone to match this value.'
                  />
                )}
              </Fragment>
            }
            aria-label={label.ariaLabel}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: '2rem' }}>
          {normalizeSizingProps.cpuMultiplier && <CpuInfo />}
        </EuiFlexItem>
      </EuiFlexGroup>
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow alignItems='baseline' label={label.formatted}>
          <NormalizeSizing {...this.props} autoscalingSizeFilter='constrainToLimits'>
            {controlRenderer}
          </NormalizeSizing>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderForecastWindow(): JSX.Element | undefined {
    const { topologyElement, onChange } = this.props

    if (!isHot({ topologyElement })) {
      return
    }

    const esTopologyElement = topologyElement as ElasticsearchClusterTopologyElement

    const policy = esTopologyElement.autoscaling_policy_override_json as
      | { proactive_storage: { forecast_window: string } }
      | undefined
    const forecastWindow = policy?.proactive_storage.forecast_window

    const label = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-forecastWindowLabel'
        defaultMessage='Forecast window'
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow label={label} alignItems='center'>
          <EuiFlexGroup responsive={false} gutterSize='s' alignItems='baseline'>
            <EuiFlexItem grow={false}>
              <ForecastWindow
                forecastWindow={forecastWindow}
                onChange={
                  onChange &&
                  ((value) =>
                    onChange(
                      [`autoscaling_policy_override_json`, `proactive_storage`, `forecast_window`],
                      value,
                    ))
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderAvailabilityZones(): JSX.Element {
    const { id, topologyElement, instanceConfiguration, onChange, maxZoneCount } = this.props

    // Sometimes the default set it outside what is available for certain instance configs
    const defaultZoneCount = Math.min(topologyElement.zone_count || 0, maxZoneCount)

    const label = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-availabilityZonesLabel'
        defaultMessage='Availability zones'
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <TopologyPanelRow label={label} alignItems='center'>
          <ZoneCount
            id={id}
            zoneCount={defaultZoneCount}
            maxZoneCount={maxZoneCount}
            onChange={onChange && ((value) => onChange([`zone_count`], value))}
            sliderInstanceType={instanceConfiguration.instance_type}
            sliderNodeTypes={getNodeRoles({
              topologyElement,
            })}
            isDedicatedMaster={isDedicatedMaster({ topologyElement })}
          />
        </TopologyPanelRow>
      </Fragment>
    )
  }

  renderSummary(): JSX.Element {
    const { instanceConfiguration } = this.props
    const label = (
      <strong>
        <FormattedMessage
          id='deploymentInfrastructure-topologyElement-summaryLabel'
          defaultMessage='Total (size x zone)'
        />
      </strong>
    )
    const topologyElement = this.props.topologyElement as ElasticsearchClusterTopologyElement

    return (
      <TopologyPanelRow label={label}>
        <NormalizeSizing {...this.props} autoscalingSizeFilter='constrainToLimits'>
          {(normalizeSizingProps) => (
            <Summary
              {...normalizeSizingProps}
              topologyElement={topologyElement}
              sliderInstanceType={instanceConfiguration.instance_type}
              instanceConfiguration={instanceConfiguration}
              isMachineLearning={isDedicatedML({ topologyElement })}
              isFrozen={isFrozen({ topologyElement })}
              autoscalingMin={topologyElement.autoscaling_min?.value}
              zoneCount={topologyElement.zone_count!}
            />
          )}
        </NormalizeSizing>
      </TopologyPanelRow>
    )
  }

  renderUserSettings(): JSX.Element | undefined {
    const { deployment, topologyElement, instanceConfiguration, onChange } = this.props

    const label = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-userSettingsLabel'
        defaultMessage='User settings'
      />
    )

    const sliderInstanceType = instanceConfiguration.instance_type
    const plan = getSliderPlan({ deployment, sliderInstanceType })

    if (!plan) {
      return // sanity
    }

    const settings = getSliderUserSettings({
      sliderInstanceType,
      plan,
      nodeConfiguration: topologyElement,
    })
    const defaultSettings = getDefaultUserSettings(sliderInstanceType)
    const setSettings =
      onChange &&
      ((value: string) =>
        onChange([instanceConfiguration.instance_type ?? '', `user_settings_yaml`], value))

    const {
      messages: { prettyName },
      userSettingsFileName,
    } = getSliderDefinition({ sliderInstanceType })
    const docLink = instanceConfiguration.instance_type
      ? USER_SETTINGS_DOC_LINKS[instanceConfiguration.instance_type]
      : undefined

    return (
      <Fragment>
        <EuiSpacer size='l' />
        <TopologyPanelRow label={label}>
          <EuiText>
            <UserSettings
              settings={settings == null ? defaultSettings : settings}
              onChange={setSettings}
              isReadOnly={!onChange}
              prettyName={prettyName}
              fileName={userSettingsFileName}
              docLink={docLink}
            />
          </EuiText>
        </TopologyPanelRow>
      </Fragment>
    )
  }

  onClickInfoPopover() {
    this.setState({ isTitlePopoverOpen: !this.state.isTitlePopoverOpen })
  }

  closeInfoPopOver() {
    this.setState({ isTitlePopoverOpen: false })
  }

  shouldDisplayAutoscalingSettings(): boolean {
    const { isAutoscalingEnabled, topologyElement, deployment } = this.props
    const version = getUpsertVersion({ deployment }) || undefined

    return Boolean(isAutoscalingEnabled) && isAutoscaleableTier({ topologyElement, version })
  }
}

export default injectIntl(TopologyElement)
