/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer } from '@elastic/eui'

import LandingPageContainer from '../LandingPageContainer'
import UserAuthNotice from '../UserAuthNotice/UserAuthNotice'

import UserRegistrationForm from './Form'
import OnPremPanel from './OnPremPanel'

import type { FunctionComponent } from 'react'

const GovCloudSignUpPageTitle: FunctionComponent = () => (
  <div style={{ whiteSpace: 'normal' }}>
    <FormattedMessage
      id='govcloud-signup.panel.title'
      defaultMessage='Start your free Elastic Cloud trial on AWS GovCloud'
    />
  </div>
)

interface Props {
  createUser: ({ email, password, captcha_token }) => Promise<any>
  downloads: boolean
  source?: string
}

export const GovCloudSignUpPage: FunctionComponent<Props> = ({ createUser, downloads, source }) => (
  <LandingPageContainer
    title={<GovCloudSignUpPageTitle />}
    pageContext={{ name: 'signup' }}
    infoMessage={downloads && <OnPremPanel />}
  >
    <UserAuthNotice source={source} />

    <EuiSpacer size='m' />

    <UserRegistrationForm createUser={createUser} isGovCloud={true} />
  </LandingPageContainer>
)
