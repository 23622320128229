/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { stringify } from 'querystring'

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { EuiButtonEmpty, EuiFlexGroup, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import cloudIcon from '@/files/rate_card_icon.svg'
import arrowIcon from '@/files/icon-long-arrow-right-24-sky-blue.svg'
import { billingUsageUrl } from '@/apps/userconsole/urls'

import type { FunctionComponent } from 'react'

export const getUsageUrlParams = () => {
  const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
  const endDate = moment().format('YYYY-MM-DD')
  return stringify({ startDate, endDate })
}

interface Props {
  organizationId: string
}

const ViewRatesCard: FunctionComponent<Props> = ({ organizationId }) => {
  const history = useHistory()

  return (
    <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} borderRadius='m' grow={false}>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
        gutterSize='none'
      >
        <img src={cloudIcon} />

        <EuiSpacer size='xxl' />

        <EuiText size='xs'>
          <h2>
            <FormattedMessage id='view-rates-card.title' defaultMessage='View consumption rates' />
          </h2>
        </EuiText>

        <EuiSpacer size='m' />

        <EuiButtonEmpty
          onClick={() =>
            history.push({
              pathname: billingUsageUrl(organizationId),
              search: getUsageUrlParams(),
            })
          }
          iconSide='right'
          iconType={arrowIcon}
          data-test-id='view-hourly-rate-button'
        >
          <FormattedMessage id='view-rates-card.button.message' defaultMessage='Show' />
        </EuiButtonEmpty>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default ViewRatesCard
