/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import type { EuiBetaBadgeProps, IconType } from '@elastic/eui'
import {
  EuiSkeletonText,
  EuiButtonEmpty,
  EuiIcon,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import history from '@modules/utils/history'
import type { ElasticsearchClusterTrustSettings } from '@modules/cloud-api/v1/types'
import type { AnyTrustRelationship } from '@modules/ui-types'

import { createDeploymentTrustRelationshipUrl, securityUrl } from '@/lib/urlBuilder'
import { getConfigForKey } from '@/store'
import {
  getAccountTrustRelationships,
  getDirectTrustRelationships,
  getExternalTrustRelationships,
  isApiKeysRelationship,
} from '@/lib/stackDeployments/trustRelationships'
import {
  API_KEYS_AVAILABLE_VERSION,
  API_KEYS_GA_AVAILABLE_VERSION,
  shouldShowApiKeys,
} from '@/lib/apiKeys'
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { lt, gte } from '@/lib/semver'

import { getTargetEnvironmentType } from '../helpers'
import TrustRelationshipNavigationSteps from '../components/TrustRelationshipNavigationSteps'

import ReusableTrustRelationships from './ReusableTrustRelationships'

import type { RelationshipType } from '../types'
import type { MessageDescriptor, WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

type CardData = {
  value: RelationshipType
  icon: IconType
  messages: {
    title: MessageDescriptor
    description: MessageDescriptor
    certificateOnlyTitle?: MessageDescriptor
    certificateOnlyTooltip?: MessageDescriptor
  }
}

type State = {
  selected: RelationshipType | null
  previouslyUsedEnvName: string | null
  createNewTrust: boolean
  showAuthTypeSelector: boolean
  authMechanism: string | null
}

const essCard: CardData = {
  value: 'ess',
  icon: 'logoCloud',
  messages: defineMessages({
    title: { id: 'deploymentTrustManagement.create.ess.title', defaultMessage: 'Elastic Cloud' },
    description: {
      id: 'deploymentTrustManagement.create.ess.description',
      defaultMessage: 'Trust deployments of an Elastic Cloud organization.',
    },
  }),
}

const eceCard: CardData = {
  value: 'ece',
  icon: 'logoCloudEnterprise',
  messages: defineMessages({
    title: {
      id: 'deploymentTrustManagement.create.ece.title',
      defaultMessage: 'Elastic Cloud Enterprise',
    },
    description: {
      id: 'deploymentTrustManagement.create.ece.description',
      defaultMessage: 'Trust deployments of an Elastic Cloud Enterprise environment.',
    },
  }),
}

const onPremCard: CardData = {
  value: 'self-managed',
  icon: 'logoElasticsearch',
  messages: defineMessages({
    title: { id: 'deploymentTrustManagement.create.onPrem.title', defaultMessage: 'Self-managed' },
    description: {
      id: 'deploymentTrustManagement.create.onPrem.description',
      defaultMessage:
        'Trust Elasticsearch clusters or Elasticsearch nodes of a self-managed Elastic environment.',
    },
  }),
}

class CreateDeploymentTrustRelationship extends React.Component<
  AllProps & WrappedComponentProps,
  State
> {
  state: State = {
    selected: null,
    previouslyUsedEnvName: null,
    createNewTrust: false,
    showAuthTypeSelector: false,
    authMechanism: null,
  }

  componentDidMount() {
    const { fetchTrustedEnvs } = this.props
    fetchTrustedEnvs()
  }

  render() {
    const { deployment, trustedEnvs, fetchTrustedEnvsRequest } = this.props

    const cards: CardData[] = [essCard, eceCard, onPremCard]

    const filteredTrustedEnvs = this.filterAllEnvsOnThisDeployment()

    const showCreateNewComponent =
      this.isTrustedEnvsEmpty(filteredTrustedEnvs) || this.state.createNewTrust

    if (fetchTrustedEnvsRequest.inProgress || !trustedEnvs) {
      return (
        <Fragment>
          <EuiSkeletonText />
          <EuiSpacer />
        </Fragment>
      )
    }

    if (this.state.showAuthTypeSelector) {
      return this.renderChooseAuthType()
    }

    return (
      <div>
        <TrustRelationshipNavigationSteps currentStep={0} />
        <EuiSpacer />
        {this.renderReusableTrustComponent(filteredTrustedEnvs)}
        {showCreateNewComponent && (
          <div data-test-id='create-deployment-trust-relationship-create-new-options'>
            <EuiFlexGroup gutterSize='l'>
              {cards.map((card, i) => (
                <EuiFlexItem key={i} style={{ maxWidth: `22rem` }}>
                  <EuiCard
                    selectable={{
                      onClick: () => this.setState({ selected: card.value }),
                      isSelected: this.state.selected === card.value,
                    }}
                    icon={<EuiIcon type={card.icon} size='xl' />}
                    title={<FormattedMessage {...card.messages.title} />}
                    description={<FormattedMessage {...card.messages.description} />}
                  />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>

            <EuiSpacer size='xxl' />

            <EuiFlexGroup justifyContent='flexStart'>
              <EuiFlexItem grow={false}>
                <EuiButton
                  type='button'
                  data-test-id='save-trust-relationship-button'
                  disabled={this.state.selected === null}
                  onClick={() => this.setState({ showAuthTypeSelector: true })}
                  fill={true}
                >
                  <FormattedMessage
                    id='deploymentTrustManagement.create.next'
                    defaultMessage='Next'
                  />
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
                  <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        )}
      </div>
    )
  }

  renderChooseAuthType() {
    const { deployment } = this.props

    const showApiKeys = shouldShowApiKeys(deployment)
    const version = getVersion({ deployment })

    return (
      <Fragment>
        <TrustRelationshipNavigationSteps currentStep={1} />
        <EuiSpacer />

        <EuiFlexGroup gutterSize='l'>
          <EuiFlexItem key={`api-keys`} style={{ maxWidth: `22rem` }}>
            <EuiCard
              isDisabled={!showApiKeys}
              selectable={{
                onClick: () => this.setState({ authMechanism: `api-keys` }),
                isSelected: this.state.authMechanism === `api-keys`,
              }}
              title={
                <FormattedMessage
                  id='deploymentTrustManagement.create.api-keys.title'
                  defaultMessage='API keys'
                />
              }
              description={
                <FormattedMessage
                  id='deploymentTrustManagement.create.api-keys.description'
                  defaultMessage='Fine-grained access to remote indices. You need an API key provided by the remote cluster administrator.'
                />
              }
              footer={
                <FormattedMessage
                  id='deploymentTrustManagement.create.api-keys.footer'
                  defaultMessage='Both clusters must be on version {v} or above.'
                  values={{
                    v: API_KEYS_AVAILABLE_VERSION,
                  }}
                />
              }
              betaBadgeProps={this.getBetaBadgeProps(version, showApiKeys)}
            />
          </EuiFlexItem>
          <EuiFlexItem key={`certificates`} style={{ maxWidth: `22rem` }}>
            <EuiCard
              selectable={{
                onClick: () => this.setState({ authMechanism: `certificates` }),
                isSelected: this.state.authMechanism === `certificates`,
              }}
              title={
                <FormattedMessage
                  {...{
                    id: 'deploymentTrustManagement.create.certificates.title',
                    defaultMessage: 'Certificates',
                  }}
                />
              }
              description={
                <FormattedMessage
                  {...{
                    id: 'deploymentTrustManagement.create.certificates.description',
                    defaultMessage:
                      'Full access to the remote cluster. You need the TLS certificate of the remote cluster.',
                  }}
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xxl' />

        <EuiFlexGroup justifyContent='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiButton
              type='button'
              data-test-id='save-trust-relationship-button'
              disabled={this.state.authMechanism === null}
              onClick={this.onNext}
              fill={true}
            >
              <FormattedMessage id='deploymentTrustManagement.create.next' defaultMessage='Next' />
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
              <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderReusableTrustComponent(filteredTrustedEnvs) {
    const { fetchTrustedEnvsRequest } = this.props

    if (fetchTrustedEnvsRequest.error) {
      return (
        <CuiAlert type='danger' data-test-id='reusable-trust-relationship.error'>
          {fetchTrustedEnvsRequest.error}
        </CuiAlert>
      )
    }

    if (this.isTrustedEnvsEmpty(filteredTrustedEnvs)) {
      return null
    }

    return (
      <Fragment>
        <ReusableTrustRelationships
          onSelected={(value) => this.onSelectedPreviouslyCreatedEnv(value)}
          trustedEnvs={filteredTrustedEnvs}
        />

        <EuiSpacer size='l' />

        <EuiButton
          onClick={() => this.setState({ createNewTrust: true })}
          data-test-id='create-deployment-trust-relationship-show-new-button'
        >
          <FormattedMessage
            id='deploymentTrustManagement.create.intro'
            defaultMessage='Trust a new environment'
          />
        </EuiButton>

        <EuiSpacer size='l' />
      </Fragment>
    )
  }

  getBetaBadgeProps(version: string | null, showApiKeys: boolean): EuiBetaBadgeProps {
    if (!version) {
      return {
        label: ``,
      }
    }

    // the card is entirely disabled if the version is before Beta version
    // so here we're showing a badge only when the version is between Beta and GA
    if (lt(version, API_KEYS_GA_AVAILABLE_VERSION) && gte(version, API_KEYS_AVAILABLE_VERSION)) {
      return {
        label: `Beta`,
        color: `accent`,
        tooltipContent: showApiKeys
          ? ``
          : `API key method is only available in Elasticsearch versions ${API_KEYS_AVAILABLE_VERSION}+`,
      }
    }

    // if we're not in the Beta range, we don't show a badge
    return {
      label: ``,
    }
  }

  filterAllEnvsOnThisDeployment() {
    const { trustedEnvs, deployment } = this.props
    const existingAccountEnvs = getAccountTrustRelationships({ deployment })
    const existingExternalEnvs = getExternalTrustRelationships({ deployment })
    const existingDirectEnvs = getDirectTrustRelationships({ deployment })

    // filter out any relationships that are already trusted by this deployment
    const filteredAccounts = this.filterExistingEnvs(
      `accounts`,
      trustedEnvs,
      existingAccountEnvs,
      `account_id`,
    )
    const filteredDirect = this.filterExistingEnvs(`direct`, trustedEnvs, existingDirectEnvs, `uid`)
    const filteredExternal = this.filterExistingEnvs(
      `external`,
      trustedEnvs,
      existingExternalEnvs,
      `trust_relationship_id`,
    )

    const filteredTrustedEnvs = {
      accounts: filteredAccounts,
      direct: filteredDirect,
      external: filteredExternal,
    }

    return filteredTrustedEnvs
  }

  filterExistingEnvs(
    type,
    trustedEnvs: ElasticsearchClusterTrustSettings,
    existingEnvs,
    matchingKey,
  ): AnyTrustRelationship[] {
    const { deployment } = this.props

    if (!trustedEnvs[type]) {
      return []
    }

    let filteredEnvs: AnyTrustRelationship[] = trustedEnvs[type]

    if (!shouldShowApiKeys(deployment)) {
      // filter out any that are api-keys as this deployment is too old
      filteredEnvs = filteredEnvs.filter((env) => !isApiKeysRelationship(env))
    }

    return filteredEnvs.filter(
      (env) => !existingEnvs.find((existingEnv) => existingEnv[matchingKey] === env[matchingKey]),
    )
  }

  isTrustedEnvsEmpty(filteredTrustedEnvs) {
    if (
      isEmpty(filteredTrustedEnvs.accounts) &&
      isEmpty(filteredTrustedEnvs.direct) &&
      isEmpty(filteredTrustedEnvs.external)
    ) {
      return true
    }

    return false
  }

  onSelectedPreviouslyCreatedEnv(value) {
    if (!value) {
      return
    }

    this.setState(
      {
        selected: getTargetEnvironmentType(value),
        previouslyUsedEnvName: value.name,
        authMechanism: value.type === `proxy` ? `api-keys` : `certificate`,
      },
      () => {
        this.onNext()
      },
    )
  }

  onNext = () => {
    const destination = this.getDestination()

    if (!destination) {
      return
    }

    if (this.state.previouslyUsedEnvName) {
      return history.push(
        `${destination}?type=${this.state.selected}&auth=${this.state.authMechanism}&previouslyUsedEnv=${this.state.previouslyUsedEnvName}`,
      )
    }

    history.push(`${destination}?type=${this.state.selected}&auth=${this.state.authMechanism}`)
  }

  getDestination = (): string | null => {
    const { deployment } = this.props
    const isEce = getConfigForKey(`APP_PLATFORM`) === 'ece'

    switch (this.state.selected) {
      case 'api-keys':
        return createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
      case 'ess':
        return isEce
          ? createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
          : createDeploymentTrustRelationshipUrl(deployment.id, 'accounts')
      case 'ece':
        if (this.state.authMechanism === `api-keys`) {
          return createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
        }

        return isEce
          ? createDeploymentTrustRelationshipUrl(deployment.id, 'external')
          : createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
      case 'self-managed':
        return createDeploymentTrustRelationshipUrl(deployment.id, 'direct')
      default:
        return null
    }
  }
}

export default CreateDeploymentTrustRelationship
