/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage, useIntl } from 'react-intl'
import React, { useCallback, useEffect, useState } from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCallOut,
  EuiCopy,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiTextColor,
  EuiToolTip,
} from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'

type Props = {
  deployment: StackDeployment
  closeConfirmModal: () => void
  onDeleteButtonConfirm: () => void
}

export default ({ deployment, closeConfirmModal, onDeleteButtonConfirm }: Props) => {
  const [deleteValue, setDeleteValue] = useState<string>()
  const [pristine, setPristine] = useState(true)
  const { formatMessage } = useIntl()
  const copyToClipboardMessage = formatMessage({
    id: 'deployment-delete-deployment.copyToClipboard.body',
    defaultMessage: 'Copy deployment name to clipboard',
  })

  const name = deployment.name || 'unnamed'
  const isNameConfirmed = deleteValue?.trim() === name.trim()

  const submitDelete = useCallback(() => {
    setPristine(false)

    if (isNameConfirmed) {
      onDeleteButtonConfirm()
    }
  }, [isNameConfirmed, onDeleteButtonConfirm])

  useEffect(() => {
    if (deleteValue !== undefined) {
      setPristine(false)
    }
  }, [deleteValue])

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeConfirmModal} role='alertdialog'>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <FormattedMessage
              id='deployment-delete-deployment.title'
              defaultMessage='Delete {name} deployment?'
              values={{
                name,
              }}
            />
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody style={{ minHeight: '180px' }}>
          <EuiCallOut
            heading='p'
            size='s'
            title={
              <FormattedMessage
                id='deployment-delete-deployment.delete.confirmation.callout'
                defaultMessage='This action cannot be undone and permanently deletes the {name} and all related with that deployment data'
                values={{
                  name: (
                    <EuiTextColor color='accent'>
                      {name}{' '}
                      <EuiCopy textToCopy={name}>
                        {(copy) => (
                          <EuiToolTip content={copyToClipboardMessage}>
                            <EuiButtonIcon
                              aria-label={copyToClipboardMessage}
                              iconType='copy'
                              color='accent'
                              size='xs'
                              onClick={copy}
                            />
                          </EuiToolTip>
                        )}
                      </EuiCopy>
                    </EuiTextColor>
                  ),
                }}
              />
            }
            color='warning'
            iconType='warning'
          />
          <EuiFormRow
            hasEmptyLabelSpace={true}
            label={
              <FormattedMessage
                id='deployment-delete-deployment.delete.label'
                defaultMessage='Type the deployment name to confirm'
              />
            }
            error={
              <FormattedMessage
                id='deployment-delete-deployment.delete.error'
                defaultMessage='The deployment name you typed does not match {name}'
                values={{
                  name: <strong>{name}</strong>,
                }}
              />
            }
            isInvalid={!pristine && !isNameConfirmed}
            fullWidth={true}
          >
            <EuiFieldText
              name='delete-text'
              data-test-id='delete-text'
              value={deleteValue ?? ''}
              placeholder={name}
              onChange={(e) => setDeleteValue(e.target.value)}
              isInvalid={!pristine && !isNameConfirmed}
              fullWidth={true}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeConfirmModal} data-test-id='delete-text-cancel'>
            <FormattedMessage id={`deployment-delete-deployment.cancel`} defaultMessage='Cancel' />
          </EuiButtonEmpty>

          <EuiButton
            onClick={submitDelete}
            data-test-id='delete-text-confirm'
            fill={true}
            color='danger'
          >
            <FormattedMessage
              id='deployment-shut-down-and-hide-deployment.confirm'
              defaultMessage='Delete'
            />
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
