/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'querystring'

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'
import history from '@modules/utils/history'

import { withLDWrapper } from '@/apps/userconsole/components/LaunchdarklyWrapper'
import { goToUrl } from '@/actions/search'
import { fetchEolStatusIfNeeded } from '@/actions/eolStatus'
import { getConfigForKey, isFeatureActivated } from '@/selectors'
import { marketplaceConversionUrl } from '@/lib/urlBuilder'
import { fetchAuthzRoles } from '@/actions/authzRoles'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import { fetchProfileRequest } from '@/apps/userconsole/reducers/registry'

import { fetchProfileIfNeeded } from '../../actions/profile'

import App from './App'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ThunkDispatch } from '@/types/redux'

const mapStateToProps = (state): StateProps => {
  const { search } = history.location
  const query = parse(search.slice(1))

  return {
    profile: getProfile(state),
    fetchProfileRequest: fetchProfileRequest(state),
    isFullStoryActivated: isFeatureActivated(state, Feature.userconsoleRunFullStory),
    pollingInterval: getConfigForKey(state, `POLLING_INTERVAL`),
    isMarketplaceConversion: history.location.pathname === marketplaceConversionUrl(),
    isOrganizationInvitation: Boolean(query.invitationToken),
    launchDarklyClientInitializationTimeoutSeconds: getConfigForKey(
      state,
      'LAUNCHDARKLY_CLIENT_INITIALIZATION_TIMEOUT_SECONDS',
    ),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchProfileIfNeeded: (params) => dispatch(fetchProfileIfNeeded(params)),
  fetchEolStatusIfNeeded: () => dispatch(fetchEolStatusIfNeeded()),
  fetchAuthzRoles: () => dispatch(fetchAuthzRoles()),
  push: (url) => dispatch(goToUrl(url)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withLDWrapper(App))
