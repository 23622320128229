/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'

export function filterMfaDevices({
  devices,
  onlyActive,
  onlyStrong,
}: {
  devices: SaasAuthMfaDeviceResponse[] | undefined
  onlyActive?: true
  onlyStrong?: true
}): SaasAuthMfaDeviceResponse[] {
  return (devices || [])
    .filter((device) => !onlyActive || device.status === 'ACTIVE')
    .filter((device) => !onlyStrong || device.device_type !== 'SMS')
}

export function hasStrongMfaDevice(devices: SaasAuthMfaDeviceResponse[] | undefined): boolean {
  return filterMfaDevices({ devices, onlyActive: true, onlyStrong: true }).length > 0
}

export function hasOnlyWeakMfaDevice(devices: SaasAuthMfaDeviceResponse[] | undefined): boolean {
  return (
    filterMfaDevices({ devices, onlyActive: true }).length > 0 &&
    filterMfaDevices({ devices, onlyActive: true, onlyStrong: true }).length === 0
  )
}
