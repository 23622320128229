/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { ProjectType } from '@modules/ui-types/projects'

import type { MessageDescriptor } from 'react-intl'

export const getSolutionContent = (
  solution: ProjectType,
): {
  label: ProjectType
  title: MessageDescriptor
  description: MessageDescriptor
} => {
  const allSolutions = {
    elasticsearch: {
      label: 'elasticsearch' as ProjectType,
      ...defineMessages({
        title: {
          defaultMessage: 'Elasticsearch',
          id: 'create-project-component.fullymanaged-label-es',
        },
      }),
      ...defineMessages({
        description: {
          defaultMessage: 'Your data will be stored in the cloud provider of your choice.',
          id: 'create-project-component.fullymanaged-description-es',
        },
      }),
    },
    observability: {
      label: 'observability' as ProjectType,
      ...defineMessages({
        title: {
          defaultMessage: 'Observability',
          id: 'create-project-component.fullymanaged-label-observability',
        },
      }),
      ...defineMessages({
        description: {
          defaultMessage: 'Your data will be stored in the cloud provider of your choice.',
          id: 'create-project-component.fullymanaged-description-observability',
        },
      }),
    },
    security: {
      label: 'security' as ProjectType,
      ...defineMessages({
        title: {
          defaultMessage: 'Security',
          id: 'create-project-component.fullymanaged-label-security',
        },
      }),
      ...defineMessages({
        description: {
          defaultMessage: 'Your data will be stored in the cloud provider of your choice.',
          id: 'create-project-component.fullymanaged-description-security',
        },
      }),
    },
  }

  return allSolutions[solution]
}
