// @ts-nocheck
export class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`)
  }
}

export class BadRequestException extends Error {
  name: string
  message: string

  constructor(message: string) {
    super()
    this.name = 'BadRequestException'
    this.message = message
  }
}

export function assert(
  condition: any,
  msg: string = 'no additional info provided',
): asserts condition {
  if (!condition) {
    throw new Error('Assertion Error: ' + msg)
  }
}

export function noop(..._args: unknown[]): void {}

// This is a helper function to assert that a value is not undefined or null. If it is, an error is thrown.
// This avoids using `!` to assert (tell the compiler) that a value is not undefined or null and provides a
// more descriptive error message.
export function assertNotUndefinedOrNull<T>(
  value: T | undefined | null,
  errorMessage?: string,
): asserts value is T {
  assert(value !== undefined || value !== null, errorMessage || 'Value is undefined or null')
}
