/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import type { BillingSubscriptionLevel } from '@modules/ui-types'

const messages = defineMessages({
  standardSupportName: {
    id: `standard-subscription.support-name`,
    defaultMessage: `Limited`,
  },
  goldSupportName: {
    id: `gold-subscription.support-name`,
    defaultMessage: `Base`,
  },
  platinumSupportName: {
    id: `platinum-subscription.support-name`,
    defaultMessage: `Enhanced`,
  },
  enterpriseSupportName: {
    id: `enterprise-subscription.support-name`,
    defaultMessage: `Premium`,
  },
})

export const getSupportNameBasedOnLevel = (level: BillingSubscriptionLevel) =>
  ({
    standard: <FormattedMessage {...messages.standardSupportName} />,
    gold: <FormattedMessage {...messages.goldSupportName} />,
    platinum: <FormattedMessage {...messages.platinumSupportName} />,
    enterprise: <FormattedMessage {...messages.enterpriseSupportName} />,
  }[level])
