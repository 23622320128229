/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { PureComponent, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { parse } from 'query-string'

import { EuiFieldText, EuiFormRow, EuiSpacer } from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import { submitButtonStyle } from '@modules/access-management-components/styles'

import { initializeAuthForm } from '@/lib/authForm'
import GoogleReCaptchaProvider from '@/apps/userconsole/components/GoogleReCaptchaProvider'
import { getCreateUserPayload } from '@/components/CreateAccountForm/lib'

import SpinButton from '../../SpinButton'
import OpenIdSignUp from '../OpenIdSignUp'
import TermsOfService from '../../CreateAccountForm/TermsOfService'
import PasswordField from '../../PasswordField'
import CreateAccountFormErrorCallout from '../../CreateAccountForm/CreateAccountFormErrorCallout'
import GovcloudNotice from '../../UserAuthNotice/GovcloudNotice'
import validateEmail from '../../../lib/validateEmail'

import type { ReactElement } from 'react'
import type { AllProps as Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

import './userRegistrationForm.scss'

export interface State {
  email: string
  password: string
  error: ReactElement | null
  isValidPassword: boolean
  activate?: boolean
}

const messages = {
  emailError: {
    id: `cloud-sign-up-form.email-error`,
    defaultMessage: `Please use a valid email`,
  },
}

class UserRegistrationForm extends PureComponent<Props & WrappedComponentProps, State> {
  formRef: HTMLFormElement | null = null

  state: State = {
    password: '',
    email: '',
    error: null,
    isValidPassword: false,
  }

  componentDidMount(): void {
    initializeAuthForm({
      form: this.formRef,
      onFormReady: (autoFillDetected) => {
        if (autoFillDetected) {
          const formElements: any = this.formRef!.elements
          const usernameInput = formElements.email
          const passwordInput = formElements.password
          this.setState({ email: usernameInput.value, password: passwordInput.value })
        }
      },
    })
  }

  render(): ReactElement {
    const {
      loginRequest,
      createUserRequest,
      createMarketplaceUserRequest,
      formId,
      isGovCloud,
      showSocialLogin,
      source,
      location,
      isMarketplace,
      redirectTo,
      isFlowV2,
      intl: { formatMessage },
    } = this.props
    const { error, isValidPassword } = this.state

    const { search } = location
    const { partner } = parse(search.slice(1))

    const useSecondaryButtonLabel =
      source === `training` ||
      source === `community` ||
      source === `support` ||
      source === `partners` ||
      partner

    const fieldText = {
      email: {
        id: 'cloud-signup-page.form.email',
        defaultMessage: 'Email',
      },
      password: {
        id: 'cloud-signup-page.form.password',
        defaultMessage: 'Password',
      },
    }

    return (
      <div id='cloud-signup-form-wrapper-id' className='cloud-signup-form-wrapper'>
        <GoogleReCaptchaProvider action='registration' onVerifyCaptcha={this.createUser}>
          {({ verifyCaptcha, isVerifyingCaptcha, captchaError }) => {
            const requestInProgress =
              createUserRequest.inProgress ||
              loginRequest.inProgress ||
              createMarketplaceUserRequest.inProgress ||
              isVerifyingCaptcha

            return (
              <Fragment>
                <form
                  id={formId}
                  name={formId}
                  onSubmit={(e) => this.onSubmit(e, verifyCaptcha)}
                  className='cloud-signup-form'
                  data-test-id='cloud-signup-form'
                  ref={(el) => {
                    this.formRef = el
                  }}
                >
                  <PrivacySensitiveContainer>
                    <EuiFormRow
                      isInvalid={!!error}
                      error={error}
                      fullWidth={true}
                      display='rowCompressed'
                      label={!isFlowV2 && <FormattedMessage {...fieldText.email} />}
                    >
                      <EuiFieldText
                        data-test-subj='email-input-id'
                        name='email'
                        icon={isFlowV2 ? 'email' : 'user'}
                        value={this.state.email}
                        fullWidth={true}
                        isInvalid={!!error}
                        onFocus={this.clearError}
                        onBlur={this.validateField}
                        onChange={this.onChangeEmail}
                        disabled={requestInProgress}
                        placeholder={isFlowV2 ? formatMessage(fieldText.email) : undefined}
                        aria-label={formatMessage(fieldText.email)}
                      />
                    </EuiFormRow>

                    <EuiSpacer size='m' />

                    <PasswordField
                      hidePlaceholder={!isFlowV2}
                      fullWidth={true}
                      name='password'
                      label={!isFlowV2 && <FormattedMessage {...fieldText.password} />}
                      onChange={this.onChangePassword}
                      hasStrengthIndicator={true}
                      disabled={requestInProgress}
                      isMarketplace={isMarketplace}
                      aria-label={formatMessage(fieldText.password)}
                    />

                    <CreateAccountFormErrorCallout
                      captchaError={captchaError}
                      stageActivation={(e) =>
                        this.onSubmit(e, verifyCaptcha, { isActivatePendingUser: true })
                      }
                    />
                  </PrivacySensitiveContainer>
                  {!createUserRequest.error && <EuiSpacer size='m' />}

                  <SpinButton
                    type='submit'
                    disabled={!isValidPassword || !this.isValidEmail()}
                    fill={true}
                    buttonProps={{ fullWidth: true }}
                    spin={requestInProgress}
                    css={submitButtonStyle}
                    data-test-id='create-account-form-button'
                    data-track-id={`signup-button-v${isFlowV2 ? '2' : '1'}`}
                  >
                    {useSecondaryButtonLabel ? (
                      <FormattedMessage
                        data-test-id='cloud-signup-secondary-button'
                        id='cloud-signup.secondary-button-label'
                        defaultMessage='Create account'
                      />
                    ) : (
                      <FormattedMessage
                        data-test-id='cloud-signup-default-button'
                        id='cloud-signup-page.form.button-submit'
                        defaultMessage='Sign up with email'
                      />
                    )}
                  </SpinButton>

                  {isFlowV2 && <EuiSpacer size='m' />}
                </form>

                {showSocialLogin && (
                  <OpenIdSignUp
                    disabled={requestInProgress}
                    redirectTo={redirectTo}
                    isFlowV2={isFlowV2}
                  />
                )}
              </Fragment>
            )
          }}
        </GoogleReCaptchaProvider>

        <EuiSpacer size={isFlowV2 ? 'l' : 'm'} />

        <TermsOfService />

        {isGovCloud && (
          <Fragment>
            <EuiSpacer />

            <GovcloudNotice isSignup={true} />
          </Fragment>
        )}
      </div>
    )
  }

  onChangePassword = (
    input: HTMLInputElement,
    { isValidPassword }: { isValidPassword: boolean },
  ): void => {
    this.setState({ password: input.value, isValidPassword })
  }

  onChangeEmail = (e: React.BaseSyntheticEvent): void => {
    if (e.target) {
      const input = e.target
      this.setState({
        email: input.value,
      })
    }
  }

  isValidEmail = (): boolean => validateEmail(this.state.email)

  validateField = (e: React.BaseSyntheticEvent): void => {
    const {
      target: { value },
    } = e

    if (value && !validateEmail(value)) {
      this.setState({
        error: <FormattedMessage {...messages.emailError} />,
      })
    }
  }

  clearError = () => {
    this.setState({ error: null })
  }

  createUser = (
    captcha_token: string,
    { isActivatePendingUser }: { isActivatePendingUser?: boolean } = {},
  ) => {
    const { createUser, createPendingSaasUser, location } = this.props
    const { password, email } = this.state
    const { search } = location

    if (isActivatePendingUser) {
      return createPendingSaasUser({
        email,
        password,
        ...getCreateUserPayload(search),
        captcha_token,
      })
    }

    return createUser({ email, password, captcha_token })
  }

  onSubmit = (
    e: React.BaseSyntheticEvent,
    verifyCaptcha: ({ isActivatePendingUser }: { isActivatePendingUser?: boolean }) => void,
    { isActivatePendingUser }: { isActivatePendingUser?: boolean } = {},
  ) => {
    e.preventDefault()
    verifyCaptcha({ isActivatePendingUser })
  }
}

export default injectIntl(UserRegistrationForm)
