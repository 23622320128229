/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { memo, useMemo } from 'react'

import { EuiScreenReaderLive } from '@elastic/eui'

import type { RoutableBreadcrumb } from '@modules/ui-types'

import type { FC } from 'react'

interface ScreenReaderRouteAnnouncementsProps {
  breadcrumbs: RoutableBreadcrumb[] | undefined
  /**
   * A string value used for component memoization
   * The component will be re-rendered only in the case of a "prefix" change
   */
  prefix: string
}

const SEPARATOR = ' - '

const ScreenReaderAnnouncementsComponent: FC<ScreenReaderRouteAnnouncementsProps> = ({
  breadcrumbs,
  prefix,
}) => {
  const announcements = useMemo(() => {
    const reversed = [...(breadcrumbs ?? [])].reverse()

    return reversed.map((item, index, array) => (
      <React.Fragment key={`${prefix}_${index}`}>
        {item.text}
        {array.length !== index + 1 ? SEPARATOR : null}
      </React.Fragment>
    ))
  }, [breadcrumbs, prefix])

  return (
    <EuiScreenReaderLive focusRegionOnTextChange={true}>
      <React.Fragment>{announcements}</React.Fragment>
    </EuiScreenReaderLive>
  )
}

export const ScreenReaderAnnouncements = memo(
  ScreenReaderAnnouncementsComponent,
  (prevProps, nextProps) => prevProps.prefix === nextProps.prefix,
)
