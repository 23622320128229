/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { usePermissionsBatch } from '@modules/permissions-lib/hooks'
import type { PermissionsBatch, PermissionsBatchResults } from '@modules/permissions-lib/types'

import type { ComponentType } from 'react'

export type WithPermissionsProps<PermissionKey extends string> = Record<
  'permissions',
  PermissionsBatchResults<PermissionKey>
>

const withPermissions =
  <PermissionKey extends string, T extends WithPermissionsProps<PermissionKey>>(
    permissionsBatch: PermissionsBatch<PermissionKey>,
  ) =>
  (
    WrappedComponent: ComponentType<T>,
  ): ComponentType<Omit<T, keyof WithPermissionsProps<PermissionKey>>> => {
    const ComponentWithPermissions = (
      props: Omit<T, keyof WithPermissionsProps<PermissionKey>>,
    ) => {
      const permissions = usePermissionsBatch(permissionsBatch)

      return <WrappedComponent {...(props as T)} permissions={permissions} />
    }

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
    ComponentWithPermissions.displayName = `withPermissions(${displayName})`

    return ComponentWithPermissions
  }

export default withPermissions
