/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import {
  EuiEmptyPrompt,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiTextColor,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import { addToast } from '@modules/cui/Toasts'
import { parseError } from '@modules/cui/Alert'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import SpinButton from '../../SpinButton'
import VideoPlayer from '../../../../modules/VideoPlayer'

import type { IntlShape } from 'react-intl'

const messages = defineMessages({
  button: {
    id: `organization.organization-empty-prompt.button`,
    defaultMessage: `Create organization`,
  },
  description: {
    id: `organization.organization-empty-prompt.description`,
    defaultMessage: `Join an organization, or create your own and start a trial. To become a member of an existing organization, ask the administrator of that organization to send you an invite.`,
  },
  error: {
    id: `organization.organization-empty-prompt.error`,
    defaultMessage: `Could not create organization {organizationName}`,
  },
  placeholder: {
    id: `organization.organization-empty-prompt.placeholder`,
    defaultMessage: `Organization name (optional)`,
  },
  success: {
    id: `organization.organization-empty-prompt.success`,
    defaultMessage: `Organization {organizationName} has been created`,
  },
  title: {
    id: `organization.organization-empty-prompt.title`,
    defaultMessage: `Organization and billing`,
  },
})

export interface Props {
  intl: IntlShape
  createOrganization: (organization: { name?: string }) => Promise<any>
  createOrganizationRequest: AsyncRequestState
}

type State = {
  editing: boolean
  editingName: string
}

class OrganizationEmptyPrompt extends Component<Props, State> {
  state: State = {
    editing: false,
    editingName: ``,
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <EuiPanel style={{ margin: 'auto', maxWidth: 706, minHeight: 550 }}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiEmptyPrompt
              titleSize='l'
              title={<h2>{formatMessage(messages.title)}</h2>}
              body={
                <EuiTextColor color='subdued'>{formatMessage(messages.description)}</EuiTextColor>
              }
              actions={this.renderFooter()}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    )
  }

  renderFooter() {
    const {
      intl: { formatMessage },
      createOrganizationRequest,
    } = this.props

    const { editingName } = this.state

    return (
      <EuiFlexGroup direction='column'>
        <EuiFlexGroup gutterSize='s' justifyContent='center' responsive={false}>
          <EuiFlexItem style={{ maxWidth: 290 }}>
            <EuiFormRow hasEmptyLabelSpace={true}>
              <PrivacySensitiveContainer>
                <EuiFieldText
                  aria-label='organizationName'
                  autoFocus={true}
                  id='organization.organization-empty-prompt.name'
                  data-test-id='organization.organization-empty-prompt.name'
                  onChange={(e) =>
                    this.setState({ editingName: (e.target as HTMLInputElement).value })
                  }
                  onKeyDown={({ key }) => {
                    if (key === 'Enter') {
                      this.createOrganization()
                    }
                  }}
                  placeholder={formatMessage(messages.placeholder)}
                  value={editingName}
                />
              </PrivacySensitiveContainer>
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace={true}>
              <SpinButton
                data-test-id='organization.organization-empty-prompt.button'
                color='primary'
                fill={true}
                onClick={() => this.createOrganization()}
                spin={createOrganizationRequest.inProgress}
              >
                {formatMessage(messages.button)}
              </SpinButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <VideoPlayer uuid='N4nUokdoVp86s51NUt6yPP' maxWidth={`456`} />
      </EuiFlexGroup>
    )
  }

  createOrganization() {
    const {
      intl: { formatMessage },
      createOrganization,
    } = this.props

    const { editingName } = this.state

    const trimmedName = editingName.trim()
    const organizationName = trimmedName === '' ? undefined : trimmedName

    createOrganization({ name: organizationName })
      .then(() =>
        addToast({
          family: 'organization.organization-empty-prompt',
          color: 'success',
          iconType: 'check',
          title: formatMessage(messages.success, { organizationName }),
        }),
      )
      .catch((error) =>
        addToast({
          family: 'organization.organization-empty-prompt',
          color: 'danger',
          title: formatMessage(messages.error, { organizationName }),
          text: parseError(error),
        }),
      )
  }
}

export default injectIntl(OrganizationEmptyPrompt)
