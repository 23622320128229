/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiPanel,
  EuiAccordion,
  EuiSpacer,
  EuiText,
  EuiStat,
  EuiFlexGrid,
  EuiFlexItem,
} from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'

import messages from '../../messages'
import UsageStat from '../UsageStat'

import { deploymentUsageRates } from './constants'

import type { FunctionComponent } from 'react'
import type { DeploymentCosts } from './types'

interface Props {
  isLoading: boolean
  totalUsage: number
  costs: DeploymentCosts
}

const DedicatedDeployments: FunctionComponent<Props> = ({ isLoading, costs, totalUsage }) => (
  <EuiPanel hasShadow={false} hasBorder={true}>
    <EuiAccordion
      id='deployment'
      initialIsOpen={true}
      extraAction={
        <EuiStat
          isLoading={isLoading}
          textAlign='right'
          title={
            <UsageStat
              title={
                <CuiElasticConsumptionUnits value={totalUsage} unit='none' withSymbol={false} />
              }
              subText={<FormattedMessage {...messages.ecuUnit} />}
            />
          }
          description={
            <EuiText size='s'>
              <FormattedMessage {...messages.currentUsage} />
            </EuiText>
          }
          descriptionElement='div'
          titleSize='xs'
        />
      }
      buttonContent={
        <EuiText size='s'>
          <h4>
            <FormattedMessage {...messages.hostedDeployments} />
          </h4>
        </EuiText>
      }
    >
      <EuiSpacer size='s' />
      <EuiPanel color='transparent' hasBorder={false} paddingSize='s'>
        <EuiFlexGrid columns={2} gutterSize='m' responsive={false}>
          {deploymentUsageRates.map(({ dimension, description, dataTestId }) => (
            <EuiFlexItem key={dimension}>
              <EuiStat
                isLoading={isLoading}
                title={
                  <UsageStat
                    title={
                      <CuiElasticConsumptionUnits
                        value={costs[dimension] || 0}
                        unit='none'
                        withSymbol={false}
                      />
                    }
                    data-test-id={dataTestId}
                    subText={<FormattedMessage {...messages.ecuUnit} />}
                  />
                }
                description={<EuiText size='s'>{description}</EuiText>}
                descriptionElement='div'
                titleSize='xs'
              />
            </EuiFlexItem>
          ))}
        </EuiFlexGrid>
      </EuiPanel>
    </EuiAccordion>
  </EuiPanel>
)

export default DedicatedDeployments
