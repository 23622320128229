/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { useIntl } from 'react-intl'
import { renderRoutes } from 'react-router-config'
import { Router } from 'react-router-dom'

import history from '@modules/utils/history'

import { renderRedirects } from '@/lib/router'
import { getReduxState } from '@/store'

import AppRouterEvents from './AppRouterEvents'
import { AppRouterContext } from './AppRouterContext'

import type { ReduxState } from '@/types/redux'
import type { RedirectConfig } from '@/lib/router'
import type { RouteConfig } from 'react-router-config'
import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  getRedirects: (getRedirectsProps: { state: ReduxState }) => RedirectConfig[]
  getRoutes: (getRoutesProps: { state: ReduxState; intl: IntlShape }) => RouteConfig[]
}

const AppRouter: FunctionComponent<Props> = ({ getRedirects, getRoutes }) => {
  const intl = useIntl()
  const state = getReduxState()

  const redirects = getRedirects({ state })
  const routes = getRoutes({ state, intl })

  return (
    <Router history={history}>
      <AppRouterContext.Provider value={{ routes }}>
        {renderRedirects(redirects)}
        {renderRoutes(routes)}
        <AppRouterEvents routes={routes} />
      </AppRouterContext.Provider>
    </Router>
  )
}

export default AppRouter
