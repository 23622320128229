/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export function isNonNull<T>(value: T | null): value is T {
  return value !== null
}

// Force a union instead of an intersection of the provided union's keys via an
// always-true conditional which iterates through and collates the constituent
// types' keys.
//
// Cribbed from https://stackoverflow.com/posts/49402091/revisions, and more
// explanation can be found there.
type KeysOfUnion<T> = T extends T ? keyof T : never

export const keysOf = Object.keys as <T>(obj: T) => Array<KeysOfUnion<T>>
// ts-unused-exports:disable-next-line
export const entriesOf = Object.entries as <T>(obj: T) => Array<
  Exclude<
    {
      [K in KeysOfUnion<T>]-?: [K, Required<T>[K]]
    }[KeysOfUnion<T>],
    undefined
  >
>

export type NonEmptyArray<T> = [T, ...T[]]

export function nonEmptyArray<T>(arr: T[] | null | undefined): NonEmptyArray<T> {
  if (!arr) {
    throw new Error('Expected a non-empty array, but got: ' + String(arr))
  }

  if (arr.length === 0) {
    throw new Error('Expected a non-empty array, but it was empty')
  }

  return arr as NonEmptyArray<T>
}

export function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  return arr != null && arr.length > 0
}

export function hasSingleElement<T>(arr: T[]): arr is [T] {
  return arr.length === 1
}

export type FixedSizeArray<T, L extends number> = number extends L
  ? T[] // If L is not a literal type, default to T[]
  : _FixedSizeArray<T, L>

type _FixedSizeArray<T, L extends number, R extends T[] = []> = R['length'] extends L
  ? R // If the desired length is reached, return the tuple
  : _FixedSizeArray<T, L, [...R, T]> // Recursively build the tuple
