/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { identity, cloneDeep } from 'lodash'
import React, { Component } from 'react'

import type { EuiButtonColor, EuiButtonSize } from '@elastic/eui'

import type {
  DeploymentSearchResponse,
  DeploymentGetResponse,
  DeploymentUpdateRequest,
  DeploymentTemplateInfoV2,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, AnyClusterPlanInfo, SliderInstanceType } from '@modules/ui-types'
import type { DeepPartial } from '@modules/ts-essentials'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { hasOngoingResourceConfigurationChange } from '@/lib/stackDeployments/selectors/configurationChanges'
import { createUpdateRequestFromGetResponse } from '@/lib/stackDeployments/updates'

import DeploymentLockGate from '../../DeploymentLockGate'
import DangerButton from '../../DangerButton'
import { mergeDeep } from '../../../lib/immutability-helpers'

import type { DangerButtonModalProps } from '../../DangerButton'
import type { ReactElement, PropsWithChildren } from 'react'

type StackDeployment = DeploymentSearchResponse | DeploymentGetResponse

type StateProps = {
  regionId: string
  hideExtraFailoverOptions: boolean
  hidePlanDetails: boolean
  showAdvancedEditor: boolean
  updateStackDeploymentRequest: AsyncRequestState
  deploymentTemplate?: DeploymentTemplateInfoV2
}

type DispatchProps = {
  updateDeployment: (params: { deploymentId: string; deployment: DeploymentUpdateRequest }) => void
  resetUpdateDeployment: (deploymentId: string) => void
}

type ConsumerProps = {
  fill?: boolean
  size?: EuiButtonSize
  color?: EuiButtonColor
  isEmpty?: boolean
  disabled?: boolean
  deployment: StackDeployment
  sliderInstanceType: SliderInstanceType
  planAttemptUnderRetry: AnyClusterPlanInfo
  pruneOrphans?: boolean
  confirmTitle?: ReactElement
  customizeModal: (
    props: QuickDeploymentUpdateCustomModalProps,
  ) => DangerButtonModalProps | undefined
  transformBeforeSave?: (deployment: DeploymentUpdateRequest) => DeploymentUpdateRequest
  ['data-test-id']?: string
}

type Props = PropsWithChildren<StateProps & DispatchProps & ConsumerProps>

type DefaultProps = {
  transformBeforeSave: (deployment: DeploymentUpdateRequest) => DeploymentUpdateRequest
}

type AdditionalModalProps = {
  updatePayload: DeploymentUpdateRequest
  onChange: (
    changes: DeepPartial<DeploymentUpdateRequest>,
    settings?: { shallow?: boolean },
  ) => void
}

export type QuickDeploymentUpdateCustomModalProps = Props & AdditionalModalProps

type State = {
  updatePayload: DeploymentUpdateRequest
}

class QuickDeploymentUpdateButton extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps = {
    transformBeforeSave: identity,
  }

  state: State = this.getInitialState()

  getInitialState(): State {
    const {
      deployment,
      deploymentTemplate,
      planAttemptUnderRetry,
      sliderInstanceType,
      pruneOrphans,
    } = this.props

    const updatePayload = createUpdateRequestFromGetResponse({
      deployment,
      deploymentTemplate,
      planAttemptUnderRetry: planAttemptUnderRetry && planAttemptUnderRetry.plan,
      planAttemptSliderInstanceType: sliderInstanceType,
      pruneOrphans,
    })

    return {
      updatePayload,
    }
  }

  render() {
    const {
      ['data-test-id']: dataTestSubj,
      children,
      color = `warning`,
      disabled,
      fill = false,
      isEmpty,
      size = `s`,
      updateStackDeploymentRequest,
      sliderInstanceType,
      deployment,
    } = this.props

    // Pretty sure this has to exist, if you follow the long chain of components that result in rendering this component.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const resource = deployment.resources[sliderInstanceType][0]!
    const ongoingChanges = hasOngoingResourceConfigurationChange({ resource })

    return (
      <DeploymentLockGate>
        <PermissionsGate
          permissions={[
            {
              type: 'deployment',
              action: 'update',
              id: deployment.id,
            },
          ]}
        >
          {({ hasPermissions }) => (
            <DangerButton
              color={color}
              disabled={disabled || ongoingChanges || !hasPermissions}
              spin={updateStackDeploymentRequest.inProgress}
              data-test-id={dataTestSubj}
              isEmpty={isEmpty}
              fill={fill}
              size={size}
              onConfirm={this.confirmedUpdate}
              onClose={this.revertState}
              modal={this.getModalProps()}
            >
              {children}
            </DangerButton>
          )}
        </PermissionsGate>
      </DeploymentLockGate>
    )
  }

  getModalProps() {
    const { customizeModal } = this.props
    const { updatePayload } = this.state
    return (
      customizeModal && customizeModal({ ...this.props, updatePayload, onChange: this.onChange })
    )
  }

  onChange = (
    changes: DeepPartial<DeploymentUpdateRequest>,
    { shallow }: { shallow?: boolean } = {},
  ) => {
    if (shallow) {
      // NOTE: when doing shallow changes, ensure you actually send us whole objects!
      return this.onChangeShallow(changes as Partial<DeploymentUpdateRequest>)
    }

    return this.onChangeDeep(changes)
  }

  onChangeShallow = (changes: Partial<DeploymentUpdateRequest>) => {
    const { updatePayload } = this.state

    const nextUpdatePayload: DeploymentUpdateRequest = {
      ...updatePayload,
      ...changes,
    }

    this.setState({ updatePayload: nextUpdatePayload })
  }

  onChangeDeep = (changes: DeepPartial<DeploymentUpdateRequest>) => {
    const { updatePayload } = this.state
    const nextUpdatePayload = mergeDeep(updatePayload, changes)

    this.setState({ updatePayload: nextUpdatePayload })
  }

  confirmedUpdate = () => {
    const {
      deployment: { id },
      updateDeployment,
      transformBeforeSave,
    } = this.props

    const { updatePayload } = this.state

    const deployment = transformBeforeSave(cloneDeep(updatePayload))

    updateDeployment({ deploymentId: id, deployment })
  }

  revertState = () => {
    this.setState(this.getInitialState())
  }
}

export default QuickDeploymentUpdateButton
