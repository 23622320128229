/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'

import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPageHeader,
  EuiSkeletonText,
  EuiSpacer,
  EuiSplitPanel,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'
import CopyButton from '@modules/cui/CopyButton'
import ProjectEndpoints from '@modules/project-components/ProjectPage/Endpoints'
import {
  getDisplayProjectId,
  getOnlyRegionId,
  getPrettyElasticsearchProjectSubtype,
} from '@modules/project-lib/display'
import genericMessages from '@modules/project-lib/genericMessages'
import { apiKeysUrl, managementUrl } from '@modules/project-lib/kibanaLinks'
import type { ProjectID } from '@modules/project-user-api/v1/types'
import UserBoostWindow from '@modules/project-user-components/BoostWindow'
import EditableProjectName from '@modules/project-user-components/EditableProjectName'
import OpenProjectButton from '@modules/project-user-components/OpenProjectButton'
import ProjectActionsButton from '@modules/project-user-components/ProjectActionsButton'
import UserSearchPower from '@modules/project-user-components/SearchPower'
import UserSecurityProjectFeatures from '@modules/project-user-components/SecurityProjectFeatures'
import { useGetProjectQuery } from '@modules/project-user-lib/hooks/get'
import { projectCrumbs } from '@modules/project-user-pages/breadcrumbs'
import type { ProjectType } from '@modules/ui-types/projects'
import ProjectApiErrorCallout from '@modules/project-components/ProjectApiErrorCallout'
import { CuiRouterLink } from '@modules/cui/RouterButton'
import AutoOpsMetrics from '@modules/auto-ops-components/Metrics'
import DataRetention from '@modules/project-user-components/DataRetention/DataRetention'
import ProjectTypeIconAndLabel from '@modules/project-components/ProjectList/ProjectTypeIconAndLabel'

// eslint-disable-next-line import/no-restricted-paths
import { accountMembersUrl } from '@/apps/userconsole/urls'

const UserProjectPage: React.FunctionComponent = () => {
  const { projectType, projectId } = useParams<{ projectType: ProjectType; projectId: ProjectID }>()
  const query = useGetProjectQuery(projectType, projectId)
  const { formatMessage } = useIntl()

  const headerRightSideItems = query.data
    ? [<ProjectActionsButton project={query.data} />, <OpenProjectButton project={query.data} />]
    : []

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={projectCrumbs({
          projectType,
          projectId,
          projectName: query.data?.name,
        })}
      />

      <EuiPageHeader
        bottomBorder={true}
        pageTitle={query.data?.name || getDisplayProjectId(projectId)}
        rightSideGroupProps={{ gutterSize: 'm' }}
        rightSideItems={headerRightSideItems}
      />

      <EuiSpacer />

      {renderContent()}
    </div>
  )

  function renderContent() {
    if (query.isError) {
      return <ProjectApiErrorCallout error={query.error} />
    }

    if (query.isLoading) {
      return <EuiSkeletonText />
    }

    if (!query.isSuccess) {
      return null // discriminate
    }

    const { data } = query

    const showPerformanceControls = data.type === 'elasticsearch'

    const showElasticsearchSubtype = data.type === 'elasticsearch'

    return (
      <div>
        <EuiSpacer size='m' />

        <EuiTitle size='s'>
          <h2>
            <FormattedMessage id='project.heading.overview' defaultMessage='Overview' />
          </h2>
        </EuiTitle>

        <EuiSpacer size='l' />

        <EuiFlexGroup gutterSize='l' justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems='baseline'>
              <EuiFlexItem grow={false} style={{ width: '7rem' }}>
                <strong>
                  <FormattedMessage {...genericMessages.projectName} />
                </strong>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EditableProjectName project={data} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='m' />
            <EuiFlexGroup>
              <EuiFlexGroup alignItems='baseline'>
                <EuiFlexItem grow={false} style={{ width: '7rem' }}>
                  <strong>
                    <FormattedMessage id='project.label.id' defaultMessage='Project ID' />
                  </strong>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup gutterSize='s' responsive={false}>
                    <EuiFlexItem grow={false}>
                      <span>{getDisplayProjectId(data.id)}...</span>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} style={{ marginTop: -2 }}>
                      <CopyButton
                        asLink={true}
                        value={data.id}
                        aria-label={formatMessage({
                          id: 'project.label.copy.ariaLabel',
                          defaultMessage: 'Copy project ID to clipboard',
                        })}
                      >
                        <EuiIcon type='copyClipboard' />
                      </CopyButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems='baseline'>
              <EuiFlexItem grow={false} style={{ width: '12rem' }}>
                <strong>
                  <FormattedMessage
                    id='project.label.provider'
                    defaultMessage='Cloud provider & region'
                  />
                </strong>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>{getOnlyRegionId(data.region_id)}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='m' />
            <EuiFlexGroup alignItems='baseline'>
              <EuiFlexItem grow={false} style={{ width: '12rem' }}>
                <strong>
                  <FormattedMessage {...genericMessages.type} />
                </strong>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup gutterSize='xs' responsive={false} alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup gutterSize='s'>
                      <ProjectTypeIconAndLabel type={data.type} />
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  {showElasticsearchSubtype && (
                    <EuiFlexItem grow={false}>
                      <EuiBadge>
                        <FormattedMessage
                          {...getPrettyElasticsearchProjectSubtype(data.optimized_for)}
                        />
                      </EuiBadge>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems='baseline'>
              <EuiFlexItem grow={false} style={{ width: '10rem' }}>
                <strong>
                  <FormattedMessage {...genericMessages.connectionDetails} />
                </strong>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <ProjectEndpoints project={data} />
              </EuiFlexItem>
            </EuiFlexGroup>
            {data.type === 'security' && (
              <Fragment>
                <EuiSpacer size='m' />
                <EuiFlexGroup alignItems='baseline'>
                  <EuiFlexItem grow={false} style={{ width: '10rem' }}>
                    <strong>
                      <FormattedMessage
                        id='project.securityProjectFeatures'
                        defaultMessage='Project features'
                      />
                    </strong>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <UserSecurityProjectFeatures project={data} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </Fragment>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xxl' />

        <EuiFlexGroup gutterSize='l'>
          <EuiFlexItem>
            <EuiSplitPanel.Outer grow={true} hasBorder={true}>
              <EuiSplitPanel.Inner paddingSize='m'>
                <EuiTitle size='xs'>
                  <h3>
                    <FormattedMessage
                      id='project.dataManagementLink.heading'
                      defaultMessage='Data and integrations'
                    />
                  </h3>
                </EuiTitle>
                <EuiSpacer size='s' />
                <EuiText size='s'>
                  <FormattedMessage
                    id='project.dataManagementLink.description'
                    defaultMessage='Update your project data, including storage settings, indices, and data views, directly in your project.'
                  />
                </EuiText>
              </EuiSplitPanel.Inner>
              <EuiSplitPanel.Inner grow={false}>
                <EuiLink
                  href={managementUrl(data)}
                  aria-label={formatMessage({
                    id: 'project.dataManagementLink.linkText.ariaLabel',
                    defaultMessage: 'Manage data and integrations in project',
                  })}
                >
                  <FormattedMessage
                    id='project.dataManagementLink.linkText'
                    defaultMessage='Manage in project'
                  />
                </EuiLink>
              </EuiSplitPanel.Inner>
            </EuiSplitPanel.Outer>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSplitPanel.Outer grow={true} hasBorder={true}>
              <EuiSplitPanel.Inner paddingSize='m'>
                <EuiTitle size='xs'>
                  <h3>
                    <FormattedMessage id='project.apiKeysLink.heading' defaultMessage='API keys' />
                  </h3>
                </EuiTitle>
                <EuiSpacer size='s' />
                <EuiText size='s'>
                  <FormattedMessage
                    id='project.apiKeysLink.description'
                    defaultMessage='Access your project and interact with its data programmatically using Elasticsearch APIs.'
                  />
                </EuiText>
              </EuiSplitPanel.Inner>
              <EuiSplitPanel.Inner grow={false}>
                <EuiLink
                  href={apiKeysUrl(data)}
                  aria-label={formatMessage({
                    id: 'project.apiKeysLink.linkText.ariaLabel',
                    defaultMessage: 'Manage API keys in project',
                  })}
                >
                  <FormattedMessage
                    id='project.apiKeysLink.linkText'
                    defaultMessage='Manage project API keys'
                  />
                </EuiLink>
              </EuiSplitPanel.Inner>
            </EuiSplitPanel.Outer>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSplitPanel.Outer grow={true} hasBorder={true}>
              <EuiSplitPanel.Inner paddingSize='m'>
                <EuiTitle size='xs'>
                  <h3>
                    <FormattedMessage id='project.usersLink.heading' defaultMessage='Members' />
                  </h3>
                </EuiTitle>
                <EuiSpacer size='s' />
                <EuiText size='s'>
                  <FormattedMessage
                    id='project.usersLink.description'
                    defaultMessage='Add members and manage their access to this project or other resources of your organization.'
                  />
                </EuiText>
              </EuiSplitPanel.Inner>
              <EuiSplitPanel.Inner grow={false}>
                <CuiRouterLink to={accountMembersUrl()}>
                  <FormattedMessage
                    id='project.usersLink.linkText'
                    defaultMessage='Manage members'
                  />
                </CuiRouterLink>
              </EuiSplitPanel.Inner>
            </EuiSplitPanel.Outer>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xxl' />

        <AutoOpsMetrics
          region={getOnlyRegionId(data.region_id)}
          projectId={projectId}
          projectType={projectType}
        />

        <EuiTitle size='s'>
          <h2>
            <FormattedMessage
              id='project.heading.searchLakeSettings'
              defaultMessage='Search AI Lake Settings'
            />
          </h2>
        </EuiTitle>

        {showPerformanceControls && (
          <Fragment>
            <EuiSpacer size='l' />
            <UserSearchPower project={data} />
            <EuiSpacer size='l' />
            <UserBoostWindow project={data} />
          </Fragment>
        )}

        <EuiSpacer size='l' />
        <DataRetention project={data} />
      </div>
    )
  }
}

export default UserProjectPage
