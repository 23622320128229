/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { EuiText, useEuiTheme } from '@elastic/eui'

import type { FC } from 'react'

type DataRetentionSummaryProps = {
  defaultRetentionDays: number
  maximumRetentionDays: number
}

const messages = defineMessages({
  maximum: {
    id: 'project.dataRetention.summary.maximumLabel',
    defaultMessage: 'Max',
  },
  default: {
    id: 'project.dataRetention.summary.defaultLabel',
    defaultMessage: 'Default',
  },
  valueInDays: {
    id: 'project.dataRetention.summary.value',
    defaultMessage: '{amount} days',
  },
})

const DataRetentionSummary: FC<DataRetentionSummaryProps> = ({
  defaultRetentionDays,
  maximumRetentionDays,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      {defaultRetentionDays !== Infinity && (
        <SummaryItem label={formatMessage(messages.maximum)} value={maximumRetentionDays} />
      )}
      <SummaryItem label={formatMessage(messages.default)} value={defaultRetentionDays} />
    </Fragment>
  )
}

type SummaryItemProps = {
  label: string
  value: number
}

const SummaryItem: FC<SummaryItemProps> = ({ label, value }) => {
  const {
    euiTheme: {
      font: {
        weight: { semiBold, medium },
      },
      colors: { mediumShade },
    },
  } = useEuiTheme()

  const { formatMessage } = useIntl()

  return (
    <EuiText size='s'>
      <span
        css={{
          color: mediumShade,
          fontWeight: medium,
        }}
      >
        {label}:{' '}
      </span>
      <span
        css={{
          fontWeight: semiBold,
        }}
        data-test-subj={`data-retention-${label.toLowerCase()}`}
      >
        {Number.isInteger(value) ? formatMessage(messages.valueInDays, { amount: value }) : '∞'}
      </span>
    </EuiText>
  )
}

export default DataRetentionSummary
