/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiCopy,
  EuiFormLabel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiLink,
  EuiButtonEmpty,
} from '@elastic/eui'

import type { MetadataItem } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { stringifyTag } from '@/lib/tags'
import { getDeploymentTags } from '@/lib/stackDeployments/selectors/metadata'

import DeploymentTagsFlyout from './DeploymentTagsFlyout'
import { messages } from './messages'
import DeploymentTag from './DeploymentTag'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

import '../Overview/overview.scss'
import './deploymentTags.scss'

const MAX_TAGS_LENGTH = 3

type State = {
  isFlyoutOpen: boolean
  isExpanded: boolean
}

type Props = {
  deployment: StackDeployment
}

type AddOrEditTagButtonProps = {
  disabled: boolean
  onAddOrEditTag: () => void
}

const AddTagsButton: FunctionComponent<AddOrEditTagButtonProps> = ({
  disabled,
  onAddOrEditTag,
}) => (
  <EuiFlexItem grow={0}>
    <EuiButtonEmpty
      flush='both'
      disabled={disabled}
      data-test-id='deployment-tags.add-tags'
      onClick={onAddOrEditTag}
    >
      <FormattedMessage id='deployment-tags.add-tags-link' defaultMessage='Add tags' />
    </EuiButtonEmpty>
  </EuiFlexItem>
)

const EditTagsBtn: FunctionComponent<AddOrEditTagButtonProps> = ({ disabled, onAddOrEditTag }) => (
  <EuiFlexItem style={{ alignSelf: `center` }}>
    <EuiButtonEmpty
      flush='both'
      disabled={disabled}
      data-test-id='deployment-tags.edit-tags'
      onClick={onAddOrEditTag}
    >
      <FormattedMessage id='deployment-tags.edit-tags-link' defaultMessage='Edit' />
    </EuiButtonEmpty>
  </EuiFlexItem>
)

class DeploymentTags extends Component<Props & WrappedComponentProps, State> {
  state: State = {
    isFlyoutOpen: false,
    isExpanded: false,
  }

  render() {
    return (
      <Fragment>
        <EuiFormLabel data-test-id='deployment-tags.title'>
          <EuiFlexGroup
            alignItems='center'
            gutterSize='none'
            justifyContent='flexStart'
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <FormattedMessage id='deployment-tags.title' defaultMessage='Tags' />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormLabel>

        <EuiSpacer size='m' />

        {this.renderTagsContent()}

        {this.renderEditFlyout()}
      </Fragment>
    )
  }

  renderTagsContent() {
    const { deployment } = this.props
    const tags = getDeploymentTags({ deployment })

    return (
      <EuiFlexGroup
        wrap={true}
        gutterSize='m'
        className='deployment-tags'
        alignItems='center'
        responsive={false}
        data-test-id='deployment-tags.tags-list'
      >
        {tags.length > 0 ? (
          this.renderTagsList(tags)
        ) : (
          <PermissionsGate
            permissions={[
              {
                type: 'deployment',
                action: 'update',
                id: deployment.id,
              },
            ]}
          >
            {({ hasPermissions }) => (
              <AddTagsButton disabled={!hasPermissions} onAddOrEditTag={() => this.onAddTag()} />
            )}
          </PermissionsGate>
        )}
      </EuiFlexGroup>
    )
  }

  renderTagsList(tags: MetadataItem[]) {
    const {
      intl: { formatMessage },
      deployment,
    } = this.props
    const { isExpanded } = this.state

    const remainingTags = tags.length > MAX_TAGS_LENGTH ? tags.length - MAX_TAGS_LENGTH : 0

    return (
      <Fragment>
        {tags.map((tag, index) => (
          <EuiFlexItem
            className={isExpanded || index < MAX_TAGS_LENGTH ? 'show' : 'hide'}
            key={index}
            grow={false}
          >
            <DeploymentTag tag={tag}>
              <EuiCopy textToCopy={stringifyTag(tag)}>
                {(copy) => (
                  <EuiButtonIcon
                    iconType='copyClipboard'
                    color='text'
                    aria-label={formatMessage(messages.copyClipboardTag)}
                    onClick={copy}
                  />
                )}
              </EuiCopy>
            </DeploymentTag>
          </EuiFlexItem>
        ))}
        <EuiFlexItem style={{ minWidth: `50%` }}>
          <EuiFlexGroup alignItems='center'>
            {tags.length > MAX_TAGS_LENGTH && (
              <EuiFlexItem grow={false}>
                <EuiLink onClick={this.switchIsExpanded}>
                  {isExpanded ? (
                    <FormattedMessage id='deployment-tags.less-link' defaultMessage='show less' />
                  ) : (
                    <FormattedMessage
                      id='deployment-tags.more-link'
                      defaultMessage='{remainingTags} more'
                      values={{ remainingTags }}
                    />
                  )}
                </EuiLink>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <PermissionsGate
                permissions={[
                  {
                    type: 'deployment',
                    action: 'update',
                    id: deployment.id,
                  },
                ]}
              >
                {({ hasPermissions }) => (
                  <EditTagsBtn disabled={!hasPermissions} onAddOrEditTag={() => this.onAddTag()} />
                )}
              </PermissionsGate>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </Fragment>
    )
  }

  renderEditFlyout() {
    const {
      deployment,
      deployment: { id: deploymentId },
    } = this.props

    const tags = getDeploymentTags({ deployment })

    if (!this.state.isFlyoutOpen) {
      return null
    }

    return <DeploymentTagsFlyout deploymentId={deploymentId} tags={tags} onClose={this.onClose} />
  }

  onClose = () => {
    this.setState({ isFlyoutOpen: false })
  }

  onAddTag = () => {
    this.setState({ isFlyoutOpen: true })
  }

  switchIsExpanded = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }))
  }
}

export default injectIntl(DeploymentTags)
