/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, merge, isEmpty } from 'lodash'

import type {
  DeploymentTemplateInfoV2,
  ElasticsearchClusterPlan,
  ElasticsearchPayload,
  ElasticsearchResourceInfo,
} from '@modules/cloud-api/v1/types'
import type { AnyPlan, SliderInstanceType } from '@modules/ui-types'
import type {
  PayloadForSliderInstanceType,
  ResourceInfoForSliderInstanceType,
} from '@modules/ui-types/sliders'

import { getBlankPlan, getBlankNodeConfigurationPerTemplate } from './templateBlanks'
import { setDefaultStrategy } from './strategies'
import { getPlanInfo } from './selectors/fundamentals'
import { getFirstSliderResourceFromTemplate } from './selectors/deploymentTemplates'

import type { PlanState } from './selectors/fundamentals'

export function getElasticsearchPayloadFromResource({
  resource,
  planSourceState = `best_effort`,
  planAttemptUnderRetry,
  planAttemptSliderInstanceType = `elasticsearch`,
}: {
  resource: ElasticsearchResourceInfo
  planSourceState?: PlanState
  planAttemptUnderRetry?: AnyPlan | null
  planAttemptSliderInstanceType?: SliderInstanceType
}): ElasticsearchPayload | null {
  const { region } = resource
  const esPlan = getEsPlan()

  if (esPlan === null) {
    return null
  }

  return {
    region,
    ref_id: resource.ref_id,
    plan: esPlan,
  }

  function getEsPlan(): ElasticsearchClusterPlan | null {
    const planAttempt = getPlanAttempt({
      sliderInstanceType: `elasticsearch`,
      planAttemptSliderInstanceType,
      planAttemptUnderRetry,
    })

    if (planAttempt) {
      const plan = cloneDeep(planAttempt)
      return plan
    }

    const planInfo = getPlanInfo({
      resource,
      state: planSourceState,
    })

    if (!planInfo) {
      return null
    }

    const { plan } = planInfo

    if (!plan) {
      return null
    }

    delete plan.transient

    setDefaultStrategy({ plan })

    return plan as ElasticsearchClusterPlan
  }
}

export function getSliderPayload<T extends Exclude<SliderInstanceType, 'elasticsearch'>>({
  sliderInstanceType,
  deploymentTemplate,
  resource,
  planSourceState = `best_effort`,
  planAttemptUnderRetry,
  planAttemptSliderInstanceType,
  templateBlank = false,
  firstEsResource,
}: {
  sliderInstanceType: T
  deploymentTemplate?: DeploymentTemplateInfoV2
  resource?: ResourceInfoForSliderInstanceType<T> | undefined
  planSourceState?: PlanState
  planAttemptUnderRetry?: AnyPlan | null
  planAttemptSliderInstanceType: SliderInstanceType
  templateBlank?: boolean
  firstEsResource?: ElasticsearchPayload
}): PayloadForSliderInstanceType<T> | null {
  if (templateBlank) {
    return getBlankTemplatePlan()
  }

  return getClusterUpdatePlan()

  function getBlankTemplatePlan() {
    if (!deploymentTemplate) {
      return null
    }

    const sliderResource = getFirstSliderResourceFromTemplate({
      deploymentTemplate: deploymentTemplate.deployment_template,
      sliderInstanceType,
    })

    if (!sliderResource) {
      return null
    }

    const basePlan = getBlankPlan<T>({
      plan: sliderResource.plan,
    })
    const plan = getSliderPlan(basePlan)

    if (plan === null) {
      return null
    }

    const { region, ref_id } = firstEsResource!

    // Try as I might, can't get it working without the cast -- guessing it's
    // something to do with not having a TS-operable discriminant (i.e. string
    // literal or similar) in the plan object.
    const payload = {
      region,
      ref_id: `main-${sliderInstanceType}`,
      elasticsearch_cluster_ref_id: ref_id,
      plan,
    } as PayloadForSliderInstanceType<T>

    return payload
  }

  function getClusterUpdatePlan(): PayloadForSliderInstanceType<T> | null {
    const { region, ref_id, elasticsearch_cluster_ref_id } = resource!
    const basePlanInfo = getPlanInfo<ResourceInfoForSliderInstanceType<T>>({
      resource: resource!,
      state: planSourceState,
    })

    if (!basePlanInfo) {
      return null
    }

    const basePlan = basePlanInfo.plan
    const plan = getSliderPlan(basePlan)

    if (plan === null) {
      return null
    }

    // Try as I might, can't get it working without the cast -- guessing it's
    // something to do with not having a TS-operable discriminant (i.e. string
    // literal or similar) in the plan object.
    const payload = {
      region,
      ref_id,
      elasticsearch_cluster_ref_id,
      plan,
    } as PayloadForSliderInstanceType<T>

    return payload
  }

  function getSliderPlan<T extends AnyPlan>(basePlan: T | null = null): T | null {
    const planAttempt = getPlanAttempt({
      sliderInstanceType,
      planAttemptSliderInstanceType,
      planAttemptUnderRetry,
    })

    if (planAttempt) {
      return <T>cloneDeep(planAttempt)
    }

    if (basePlan) {
      delete basePlan.transient

      setDefaultStrategy({ plan: basePlan })

      if (deploymentTemplate && isEmpty(basePlan.cluster_topology)) {
        merge(
          basePlan,
          getBlankNodeConfigurationPerTemplate({
            sliderInstanceType,
            deploymentTemplate,
          }),
        )
      }
    }

    return basePlan
  }
}

function getPlanAttempt<T extends SliderInstanceType>({
  sliderInstanceType,
  planAttemptSliderInstanceType,
  planAttemptUnderRetry,
}: {
  sliderInstanceType: T
  planAttemptSliderInstanceType: SliderInstanceType
  planAttemptUnderRetry?: AnyPlan | null
}): PayloadForSliderInstanceType<T>['plan'] | null | undefined {
  if (planAttemptSliderInstanceType !== sliderInstanceType) {
    return null
  }

  return planAttemptUnderRetry
}
