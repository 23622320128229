/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSkeletonText } from '@elastic/eui'

import {
  type NormalisedResourceRoleAssignment,
  type Resource,
  type ResourceType,
} from '@modules/role-assignments-lib/types'
import {
  filterItemsByIdOrName,
  specificRoleAssignmentsToTableItems,
} from '@modules/role-assignments-lib'
import ProjectRoleAssignmentsFilter from '@modules/role-assignments-components/ProjectRoleAssignmentsFilter'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { CuiAlert } from '@modules/cui/Alert'
import { useConfig } from '@modules/cui/ConfigContext'

import ProjectRoleAssignmentsTable from './ProjectRoleAssignmentsTable'

import type { FunctionComponent } from 'react'

type UserDeploymentsAndProjectsQueryResult<T extends ResourceType = ResourceType> = {
  resourceType: T
  data: Array<Resource<T>> | undefined
  isLoading: boolean
  error: unknown
}

export type MemberOrApiKey = 'member' | 'api-key'

export interface ProjectRoleAssignmentsSummaryProps {
  memberOrApiKey: MemberOrApiKey
  organizationDeploymentsAndProjectsQueryResults: UserDeploymentsAndProjectsQueryResult[]
  roleAssignments: Array<NormalisedResourceRoleAssignment<ResourceType>>
}

const ProjectRoleAssignmentsSummary: FunctionComponent<ProjectRoleAssignmentsSummaryProps> = ({
  memberOrApiKey,
  organizationDeploymentsAndProjectsQueryResults: queryResults,
  roleAssignments,
}) => {
  const [filterQuery, setFilterQuery] = useState('')

  const [isFlagReady, flags] = useFlagsWhenLoaded()

  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const customRolesEnabled = isAdminConsole || (isFlagReady && flags.serverlessCustomRoles)

  if (queryResults.some(({ isLoading }) => isLoading)) {
    return <EuiSkeletonText />
  }

  const items = queryResults.flatMap((queryResult) =>
    specificRoleAssignmentsToTableItems(
      queryResult.resourceType,
      queryResult.data,
      roleAssignments,
    ).filter(({ roles }) => roles.length !== 0),
  )
  const filteredItems = filterItemsByIdOrName(items, filterQuery)

  return (
    <EuiFlexGroup direction='column' gutterSize='l'>
      {renderError()}

      <EuiFlexItem>
        {memberOrApiKey === 'member' ? (
          <FormattedMessage
            id='roles-assignments.role-assignments-summary.member'
            defaultMessage='This member has specific access to the following instances:'
          />
        ) : (
          <FormattedMessage
            id='roles-assignments.role-assignments-summary.api-key'
            defaultMessage='This API keys provides access to the following instances:'
          />
        )}
      </EuiFlexItem>

      <EuiFlexItem>
        <ProjectRoleAssignmentsFilter onChange={setFilterQuery} />
      </EuiFlexItem>

      <EuiFlexItem>
        <ProjectRoleAssignmentsTable
          items={filteredItems}
          customRolesEnabled={customRolesEnabled}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  function renderError() {
    const queryResult = queryResults.find(({ error }) => error)

    if (queryResult === undefined) {
      return null
    }

    return (
      <EuiFlexItem>
        <CuiAlert type='danger'>{queryResult.error}</CuiAlert>
      </EuiFlexItem>
    )
  }
}

export default ProjectRoleAssignmentsSummary
