/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge } from '@elastic/eui'

import type { FunctionComponent } from 'react'

type Props = {
  oldId: string
  currentId: string
  tierId: string
}

const InstanceConfigurationIdChange: FunctionComponent<Props> = ({ oldId, currentId, tierId }) => {
  const currentIdBadge = <EuiBadge color='hollow'>{currentId}</EuiBadge>

  const oldIdBadge = (
    <EuiBadge color='hollow'>
      <del>{oldId}</del>
    </EuiBadge>
  )

  const tierIdBadge = <EuiBadge color='hollow'>{tierId}</EuiBadge>

  const explainIcVersionChange = (
    <FormattedMessage
      id='explain-changes.change-ic-id'
      defaultMessage='Migrate {tierIdBadge} from {oldIdBadge} to {currentIdBadge}'
      values={{
        tierIdBadge,
        oldIdBadge,
        currentIdBadge,
      }}
    />
  )

  return explainIcVersionChange
}

export default InstanceConfigurationIdChange
