/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, omitBy } from 'lodash'

import { handleUnauthorizedAction } from '@modules/auth/auth'
import history from '@modules/utils/history'
import { captureApmError, startHttpTransaction } from '@modules/utils/apm'

// eslint-disable-next-line import/no-restricted-paths
import { getConfigForKey } from '@/store'

import type { ApiErrorCollection } from './types'

export type PathParameters = Record<string, string | number | boolean>

export const fetchAsJson = async <T>(
  url: string,
  opts: Parameters<typeof fetch>[1] = {},
  resetApmTransaction: boolean = true,
): Promise<T> => {
  const httpTransaction = startHttpTransaction(opts.method || 'get', url)

  const response = await fetch(url, {
    ...opts,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...opts.headers,
    },
  })

  if (resetApmTransaction && httpTransaction) {
    httpTransaction.end()
  }

  if (!response.ok) {
    if (response.status === 401) {
      const isHeroku = getConfigForKey(`APP_FAMILY`) === `heroku`

      handleUnauthorizedAction({
        isHeroku,
        location: window.location,
        setLocation: (redirectUrl) => history.replace(redirectUrl),
      })
    }

    const responseText = await response.text()

    try {
      const error = { ...JSON.parse(responseText), statusCode: response.status }
      captureApmError(new Error(`${opts.method} failed: [${error.message}]`))

      return Promise.reject(error)
    } catch {
      captureApmError(new Error(`${opts.method} failed: [${responseText}]`))
      return Promise.reject(responseText || response.statusText)
    }
  }

  try {
    return await response.json()
  } catch (error) {
    return Promise.resolve({} as T) // case when response body is empty (SyntaxError: Unexpected end of JSON input)
  }
}

type QueryParamValue = string | number | boolean | string[] | undefined

const emptiableTypes = new Set(['object', 'string', 'undefined'])

export const applyParameters = (
  parameterizedUrl: string,
  {
    pathParameters = {},
    queryParameters = {},
  }: {
    pathParameters?: Record<string, string | number | boolean>
    queryParameters?: Record<string, QueryParamValue>
  } = {},
): string => {
  const url = parameterizedUrl.replace(
    /{(\w+)}/g,
    (_, paramName) => pathParameters[paramName]?.toString() || '',
  )

  const cleanParameters = omitBy(
    queryParameters,
    (queryParameterValue) =>
      emptiableTypes.has(typeof queryParameterValue) && isEmpty(queryParameterValue),
  )

  const keyValueParameterPairs = Object.entries(cleanParameters).map(([key, value = '']) => [
    key,
    value.toString(),
  ])

  const search = new URLSearchParams(keyValueParameterPairs).toString()

  if (search) {
    return `${url}?${search}`
  }

  return url
}

export function isApiErrorCollection(err: any): err is ApiErrorCollection {
  return err && err.errors && Array.isArray(err.errors) && err.errors.length > 0 && err.statusCode
}
