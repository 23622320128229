/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

import type { KeyStatusBadge } from '../types'

export type ExpirationOption =
  | 'never'
  | 'custom'
  | '12 hour'
  | '1 week'
  | '1 month'
  | '2 month'
  | '3 month'
  | '1 year'

export const diffFromExpirationToToday = (
  expiration: ExpirationOption,
  now: moment.Moment,
): string | null => {
  if (expiration === 'never') {
    return null
  }

  if (expiration === 'custom') {
    return '1d'
  }

  if (expiration === '12 hour') {
    return '12h'
  }

  return diff(expirationDate(now, expiration), now)
}

export const diff = (future: moment.Moment, now: moment.Moment): string =>
  `${diffInDays(future, now)}d`

export const expirationDate = (now: moment.Moment, expiration: ExpirationOption): moment.Moment => {
  const [amount, unit] = expiration.split(' ')

  return moment(now).add(amount, unit as 'hour' | 'week' | 'month' | 'year')
}

export const customExpirationDate = (now: moment.Moment, future: moment.Moment): moment.Moment =>
  moment(now).add(diffInDays(future, now), 'day')

const diffInDays = (future: moment.Moment, now: moment.Moment): number =>
  future.diff(moment(now).startOf('day'), 'day')

export const getKeyStatus = (expDate: string | undefined): KeyStatusBadge => {
  if (expDate === undefined) {
    // API Keys that Never expires
    return {
      status: 'active',
      color: 'success',
    }
  }

  const expirationThreshold = moment().add(7, 'days')

  if (moment(expDate).isBefore(moment())) {
    return {
      status: 'expired',
      color: 'lightGrey',
    }
  }

  if (moment(expDate).isBefore(expirationThreshold)) {
    return {
      status: 'expiresSoon',
      color: 'warning',
    }
  }

  return {
    status: 'active',
    color: 'success',
  }
}
