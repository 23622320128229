/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'

import DocLink from '@/components/DocLink'
import { deploymentEditUrl } from '@/lib/urlBuilder'
import { withStackDeploymentRouteParams } from '@/components/StackDeploymentEditor'

import messages from './messages'

import type { FunctionComponent } from 'react'

interface Props {
  hasColdTier: boolean
  stackDeploymentId: string
}

const DataLossRiskWarningMessage: FunctionComponent<Props> = ({
  hasColdTier,
  stackDeploymentId,
}) => {
  const message = hasColdTier
    ? messages.dataLossRiskMessageWithColdTier
    : messages.dataLossRiskMessage
  return (
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={false}>
        <div>
          <FormattedMessage
            {...message}
            values={{
              hotTier: (
                <DocLink link='hotTier'>
                  <FormattedMessage
                    id='deployment-health-problems.data-loss-risk.hot-tier'
                    defaultMessage='hot tier'
                  />
                </DocLink>
              ),
              warmTier: (
                <DocLink link='warmTier'>
                  <FormattedMessage
                    id='deployment-health-problems.data-loss-risk.warm-tier'
                    defaultMessage='warm tier'
                  />
                </DocLink>
              ),
              ...(hasColdTier && {
                coldTier: (
                  <DocLink link='coldTier'>
                    <FormattedMessage
                      id='deployment-health-problems.data-loss-risk.cold-tier'
                      defaultMessage='cold tier'
                    />
                  </DocLink>
                ),
              }),
              learnMore: (
                <DocLink link='faultToleranceDocLink'>
                  <FormattedMessage
                    id='deployment-health-problems.data-loss-risk.learn-more'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        </div>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <CuiLink to={deploymentEditUrl(stackDeploymentId)} style={{ whiteSpace: 'nowrap' }}>
          <FormattedMessage
            id='deployment-health-problems.edit-config'
            defaultMessage='Edit configuration'
          />
        </CuiLink>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default withStackDeploymentRouteParams(DataLossRiskWarningMessage)
