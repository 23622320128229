/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiButtonEmpty, EuiFlexItem } from '@elastic/eui'

import { getThemeColors } from '@modules/cui/theme'
import { useSaasUser } from '@modules/profile-lib/hooks'
import {
  doesSaasEntityHaveExpiredTrial,
  getSaasEntityTrialDaysRemaining,
} from '@modules/profile-lib/trial'

// eslint-disable-next-line import/no-restricted-paths
import { isMarketplaceSubscription } from '@/lib/marketPlace'

import TrialSummary from '../TrialSummary'

const TrialHeaderBadge: React.FunctionComponent = () => {
  const saasUser = useSaasUser()

  if (!saasUser?.user) {
    return null
  }

  if (saasUser.trials.length === 0) {
    return null
  }

  const isMarketplace = isMarketplaceSubscription(saasUser.user.domain)
  const expired = doesSaasEntityHaveExpiredTrial(saasUser)
  const daysRemaining = getSaasEntityTrialDaysRemaining(saasUser)

  const renderBadge = () => {
    if (expired) {
      return (
        <EuiFlexItem grow={false}>
          <EuiBadge style={{ cursor: 'inherit' }}>
            <FormattedMessage id='trialHeaderBadge.expired' defaultMessage='Trial expired' />
          </EuiBadge>
        </EuiFlexItem>
      )
    }

    const colors = getThemeColors()

    return (
      <EuiFlexItem grow={false}>
        <EuiBadge style={{ cursor: 'inherit' }} color={colors.euiColorVis5}>
          <FormattedMessage
            id='trialHeaderBadge.daysRemaining'
            defaultMessage='Trial - {daysRemaining} {daysRemaining, plural, one {day} other {days}} left'
            values={{
              daysRemaining,
            }}
          />
        </EuiBadge>
      </EuiFlexItem>
    )
  }

  return (
    <TrialSummary>
      {({ triggerModal }) => {
        const badge = renderBadge()

        if (isMarketplace) {
          return badge
        }

        return (
          <EuiButtonEmpty data-test-id='header-trial-badge-button' onClick={triggerModal}>
            {badge}
          </EuiButtonEmpty>
        )
      }}
    </TrialSummary>
  )
}

export default TrialHeaderBadge
