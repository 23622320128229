/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { add, mergeWith, isEmpty } from 'lodash'

import type { LineItem, Costs, Dimension, DeploymentCosts } from '@modules/cloud-api/v1/types'
import type { BillingSubscriptionLevel, UserProfile, ProfileState } from '@modules/ui-types'
import type {
  BillingActivity,
  DeploymentCostsAggregation,
  Subscription,
} from '@modules/ui-types/billing'
import type { PaymentDetails } from '@modules/billing-api/customTypes'

const DEFAULT_SUBSCRIPTION_LEVEL: BillingSubscriptionLevel = 'enterprise'

const LEVELS: BillingSubscriptionLevel[] = ['standard', 'gold', 'platinum', 'enterprise']

interface BillingSubscriptionWithHourlyRate extends Subscription {
  hourlyRate: number
}

export function getSubscriptionsWithHourlyRates({
  subscription,
  activity,
}: {
  subscription: Subscription
  activity?: BillingActivity
}): BillingSubscriptionWithHourlyRate {
  const hourlyRate = activity?.[subscription.value] || 0

  return {
    ...subscription,
    hourlyRate,
  }
}
export const getHourlyRateForLevel = ({
  subscription,
  activity,
}: {
  subscription: BillingSubscriptionLevel
  activity?: BillingActivity
}) => activity?.[subscription] || 0

export const getSubscriptionQuery = (
  level: string | null | undefined,
): BillingSubscriptionLevel => {
  const billingLevels: BillingSubscriptionLevel[] = [`standard`, `gold`, `platinum`, `enterprise`]

  return billingLevels.find((each) => each === level) ?? DEFAULT_SUBSCRIPTION_LEVEL
}

export const isElasticStaff = ({ email }: { email: string | null | undefined }): boolean => {
  if (!email) {
    return false
  }

  return email.endsWith(`@elastic.co`)
}

export function isTrialUser(profile: UserProfile): boolean {
  return profile.inTrial
}

export function isTrialNotStartedUser({ inTrial, trials }: UserProfile): boolean {
  return inTrial && !trials.length
}

export function isNotPayingUser(profile: UserProfile, paymentDetails?: PaymentDetails): boolean {
  return (
    !profile.invoicable &&
    !profile.is_paying &&
    isEmpty(paymentDetails?.recurly_billing_info) &&
    paymentDetails?.external_billing_info?.card == null
  )
}

export function isTrialOrNotPayingUser(
  profile: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  if (isTrialUser(profile)) {
    // always show for trial users
    return true
  }

  // if a user is not in trial and is not yet paying (for whatever reason), we want to show this
  // but only if they don't already have CC details added
  return isNotPayingUser(profile, paymentDetails)
}

function isMonthlyCustomer({ contract_type }: UserProfile): boolean {
  return contract_type === 'monthly'
}

export function isAnnualCustomer({ contract_type }: UserProfile): boolean {
  return contract_type === 'annual'
}

export function isPrepaidConsumptionCustomer({ contract_type }: UserProfile): boolean {
  return contract_type === 'consumption'
}

export function isLapsedAnnualPrepaidCustomer(
  profile: UserProfile,
  lineItems: LineItem[] | undefined,
  dateReference = new Date(),
): boolean {
  return (
    isPrepaidConsumptionCustomer(profile) &&
    !hasActivePrepaidConsumptionLineItems(lineItems, dateReference)
  )
}

export function hasActivePrepaidConsumptionLineItems(
  lineItems: LineItem[] | undefined,
  dateReference = new Date(),
): boolean {
  return Boolean(
    lineItems?.some(
      (item) => isActiveItem(item, dateReference) && item.type === 'prepaid_consumption',
    ),
  )
}

export function isCreditCardCustomer(
  { is_paying }: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  return (
    is_paying &&
    (!isEmpty(paymentDetails?.recurly_billing_info) ||
      !isEmpty(paymentDetails?.external_billing_info?.card))
  )
}

export function isAnnualCreditCardCustomer(
  profile: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  return isAnnualCustomer(profile) && isCreditCardCustomer(profile, paymentDetails)
}

// Customers that are manually switched from purchase orders to CC without
// having CC info on file and end up in this weird state.
function isIncompleteCreditCardCustomer(
  { is_paying, invoicable }: UserProfile,
  paymentDetails?: PaymentDetails,
) {
  return (
    is_paying &&
    !invoicable &&
    isEmpty(paymentDetails?.recurly_billing_info) &&
    isEmpty(paymentDetails?.external_billing_info?.card)
  )
}

function isPurchaseOrderCustomer(
  { is_paying, invoicable, email }: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  return (
    ((is_paying && invoicable) || isElasticStaff({ email })) &&
    isEmpty(paymentDetails?.recurly_billing_info) &&
    isEmpty(paymentDetails?.external_billing_info?.card)
  )
}

export function isMonthlyPurchaseOrderCustomer(
  profile: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  return isMonthlyCustomer(profile) && isPurchaseOrderCustomer(profile, paymentDetails)
}

export function isAnnualPurchaseOrderCustomer(
  profile: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  return isAnnualCustomer(profile) && isPurchaseOrderCustomer(profile, paymentDetails)
}

export function isPrepaidAccount(profile: UserProfile, paymentDetails?: PaymentDetails): boolean {
  if (!profile) {
    return false
  }

  return isPrepaidConsumptionCustomer(profile) || isPurchaseOrderCustomer(profile, paymentDetails)
}

export function canAddPaymentMethod(
  profile: UserProfile,
  paymentDetails?: PaymentDetails,
): boolean {
  return (
    isMonthlyPurchaseOrderCustomer(profile, paymentDetails) ||
    isIncompleteCreditCardCustomer(profile, paymentDetails)
  )
}

export function getConsumptionTypeDisplayName(type: string): string {
  if (type === 'prepaid_consumption') {
    return 'Order line'
  }

  if (type === 'credit') {
    return 'Credit'
  }

  return type
}

type TimeRange = { start: string; end: string }

export function isActiveItem({ start, end }: TimeRange, dateReference: Date): boolean {
  return new Date(start) < dateReference && new Date(end) > dateReference
}

export function isExpiredItem({ end }: TimeRange, dateReference: Date): boolean {
  return new Date(end) < dateReference
}

export function isFutureItem({ start }: TimeRange, dateReference: Date): boolean {
  return new Date(start) > dateReference
}

export function getActiveItems<T extends TimeRange>(items: T[], dateReference: Date): T[] {
  return items.filter((item) => isActiveItem(item, dateReference))
}

export function getExpiredItems<T extends TimeRange>(items: T[], dateReference: Date): T[] {
  return items.filter((item) => isExpiredItem(item, dateReference))
}

export function getFutureItems<T extends TimeRange>(items: T[], dateReference: Date): T[] {
  return items.filter((item) => isFutureItem(item, dateReference))
}

function getTotalCostForDimensions(dimensionTypes: Array<Dimension['type']>, costs: Costs) {
  return costs.dimensions.reduce((currentCost, { cost, type }) => {
    if (dimensionTypes.includes(type)) {
      return currentCost + cost
    }

    return currentCost
  }, 0)
}

function getDataTransferCost(costs: Costs): number {
  return getTotalCostForDimensions(['data_in', 'data_internode', 'data_out'], costs)
}

function getStorageCost(costs: Costs): number {
  return getTotalCostForDimensions(['storage_bytes', 'storage_api'], costs)
}

export function getDataTransferAndStorageCost(costs: Costs): number {
  return getDataTransferCost(costs) + getStorageCost(costs)
}

export function getCapacityCost(costs: Costs): number {
  return costs.dimensions.find(({ type }) => type === 'capacity')?.cost || 0
}

export function aggregateCostsForDeployment({
  deployment_id: id,
  deployment_name: name,
  costs,
}: DeploymentCosts): DeploymentCostsAggregation {
  if (!costs) {
    return {
      id,
      name,
      capacity: 0,
      dataTransfer: 0,
      storage: 0,
      total: 0,
    }
  }

  return {
    id,
    name,
    capacity: getCapacityCost(costs),
    dataTransfer: getDataTransferCost(costs),
    storage: getStorageCost(costs),
    total: costs.total,
  }
}

export function aggregateCosts(
  deploymentsCosts: DeploymentCostsAggregation[],
): DeploymentCostsAggregation {
  return deploymentsCosts.reduce((aggs, aggregation) => mergeWith(aggs, aggregation, add), {
    capacity: 0,
    dataTransfer: 0,
    storage: 0,
    total: 0,
  } as DeploymentCostsAggregation)
}

export const defaultSubscriptionLevelByUser = ({
  profile,
  trialDefaultTierFlag,
}: {
  profile: ProfileState | UserProfile
  trialDefaultTierFlag?: BillingSubscriptionLevel
}): BillingSubscriptionLevel => {
  if (!profile || !profile.inTrial) {
    return DEFAULT_SUBSCRIPTION_LEVEL
  }

  if (trialDefaultTierFlag && LEVELS.includes(trialDefaultTierFlag)) {
    return trialDefaultTierFlag
  }

  return DEFAULT_SUBSCRIPTION_LEVEL
}
