/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { CuiAlert, parseError } from '@modules/cui/Alert'
import { CuiLink } from '@modules/cui/Link'
import { rootUrl } from '@modules/utils/rootUrls'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import AcceptInvitationTitle from '@/apps/userconsole/components/AcceptInvitation/AcceptInvitationTitle'
import PasswordUpdateViaEmailLink from '@/apps/userconsole/components/CreatePassword/PasswordUpdateViaEmailLink'
import PasswordBasedLogin from '@/components/Login/PasswordBasedLogin'
import {
  buildAcceptInvitationRedirectUrl,
  buildAcceptOrganizationInvitationRedirectUrl,
  buildLoginUrl,
  buildRegisterUrl,
} from '@/lib/urlUtils'
import CreateAccountForm from '@/components/CreateAccountForm'
import GovcloudNotice from '@/components/UserAuthNotice/GovcloudNotice'

import PageContextSwitcher from './PageContextSwitcher'

import type { AllProps as Props } from './types'
import type { ReactElement, ReactNode } from 'react'

class AcceptInvitation extends PureComponent<Props> {
  componentDidMount(): void {
    const { activationHash, fetchOrganizationInvitation, source } = this.props

    if (source === 'organization-invite' && activationHash) {
      fetchOrganizationInvitation({ invitationToken: activationHash })
    }
  }

  render(): ReactElement {
    const {
      fetchOrganizationInvitationRequest: { inProgress },
      organizationInvitation,
      source,
      mfa: { mfa_required: isMfaRequired },
    } = this.props

    return (
      <PasswordUpdateViaEmailLink
        title={
          <AcceptInvitationTitle
            inProgress={inProgress}
            organizationName={organizationInvitation?.organization.name}
            source={source}
            isMfaRequired={isMfaRequired}
          />
        }
      >
        {this.renderContent()}
      </PasswordUpdateViaEmailLink>
    )
  }

  renderContent(): ReactElement {
    const {
      fetchOrganizationInvitationRequest: { inProgress },
      pageContext,
    } = this.props

    if (inProgress) {
      return <EuiSkeletonText lines={8} />
    }

    const invitationErrorMessage = this.getInvitationErrorMessage()

    if (invitationErrorMessage) {
      return (
        <CuiAlert type='error' className='fs-unmask' data-test-id='accept-invite-error'>
          {invitationErrorMessage}
        </CuiAlert>
      )
    }

    return pageContext.name === 'signup' ? this.renderCreateAccountForm() : this.renderLoginForm()
  }

  renderCreateAccountForm(): ReactElement {
    const {
      email,
      expires,
      activationHash,
      pageContext,
      isGovCloud,
      organizationInvitation,
      location,
      source,
    } = this.props

    const { search } = location

    const stagedUserEmail = organizationInvitation?.email ?? email

    return (
      <Fragment>
        <CreateAccountForm
          location={location}
          stagedUser={{
            email: stagedUserEmail,
            expires,
            activationHash,
            isReadOnly: source !== 'organization-invite',
          }}
          redirectTo={this.getRedirectURL()}
        />

        <EuiSpacer />

        <PageContextSwitcher
          source={source}
          pageContext={pageContext}
          activationHash={activationHash}
          loginUrl={buildLoginUrl({ locationQueryString: search })}
        />

        {isGovCloud && (
          <Fragment>
            <EuiSpacer />

            <GovcloudNotice isSignup={true} />
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderLoginForm(): ReactElement {
    const {
      activationHash,
      isGovCloud,
      location,
      pageContext,
      organizationInvitation,
      source,
      mfa,
    } = this.props

    const { search } = location
    const username = organizationInvitation?.email
    const isMfaRequired = mfa.mfa_required

    return (
      <Fragment>
        <PasswordBasedLogin
          username={username}
          location={location}
          loginAndRedirect={this.loginAndRedirect}
          canSwitchMethod={false}
          openIdRedirectUrl={this.getRedirectURL()}
          registerUrl={buildRegisterUrl({ search })}
          mfa={mfa}
        />

        {!isMfaRequired && (
          <Fragment>
            <EuiSpacer />

            <PageContextSwitcher
              source={source}
              pageContext={pageContext}
              activationHash={activationHash}
              loginUrl={buildLoginUrl({ locationQueryString: search })}
            />

            {isGovCloud && (
              <Fragment>
                <EuiSpacer />

                <GovcloudNotice />
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }

  getInvitationErrorMessage(): ReactNode {
    const {
      fetchOrganizationInvitationRequest: { error },
      organizationInvitation,
      source,
    } = this.props

    const organizationName = (
      <PrivacySensitiveContainer nativeElementType='span'>
        {organizationInvitation?.organization.name}
      </PrivacySensitiveContainer>
    )

    const loginLink = (
      <CuiLink to={rootUrl()}>
        <FormattedMessage id='accept-invite.login' defaultMessage='Login' />
      </CuiLink>
    )

    if (this.isInvalidInvitationLink()) {
      return (
        <FormattedMessage
          id='accept-invite-error.invalid'
          defaultMessage='Your invite link is not valid. {loginLink}'
          values={{ loginLink }}
        />
      )
    }

    if (source === 'organization-invite' && organizationInvitation?.accepted_at !== undefined) {
      return (
        <FormattedMessage
          id='accept-invite-error.already-accepted'
          defaultMessage='The invite link has already been used to join the organization. {loginLink}'
          values={{ loginLink }}
        />
      )
    }

    if (source === 'organization-invite' && organizationInvitation?.expired) {
      return (
        <FormattedMessage
          id='accept-invite-error.expired'
          defaultMessage='Your invite has expired. To join organization {organizationName}, ask one of the account administrators to send you a new invite. {loginLink}'
          values={{ organizationName, loginLink }}
        />
      )
    }

    if (error) {
      return parseError(error)
    }

    return null
  }

  getRedirectURL(): string {
    const {
      organizationInvitation,
      source,
      location: { search },
    } = this.props

    if (source === 'organization-invite' && organizationInvitation) {
      return buildAcceptOrganizationInvitationRedirectUrl({
        invitationType: 'organization',
        organizationInvitation,
      })
    }

    return buildAcceptInvitationRedirectUrl(search)
  }

  isInvalidInvitationLink(): boolean {
    const { email, expires, activationHash, source } = this.props

    if (source === 'organization-invite') {
      return !activationHash
    }

    return !activationHash || !email || !expires
  }

  loginAndRedirect = ({ credentials }): Promise<any> => {
    const { loginAndRedirect } = this.props

    return loginAndRedirect({
      credentials: { email: credentials.username, password: credentials.password },
      redirectTo: this.getRedirectURL(),
    })
  }
}

export default AcceptInvitation
