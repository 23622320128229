/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Suspense } from 'react'

import type { EuiLinkButtonProps, EuiButtonProps } from '@elastic/eui'
import { EuiButton, EuiLink } from '@elastic/eui'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

// We use lazy loading here to prevent the Edit flow in AC from attempting to load recurly
const LazyCreditCardModalButton = React.lazy(() => import(`./CreditCardModalButton`))

const ButtonFallback = (props: EuiButtonProps) => <EuiButton {...props} isDisabled={true} />
const LinkFallback = (props: EuiLinkButtonProps) => <EuiLink {...props} disabled={true} />

const CreditCardModalButton: FunctionComponent<Props> = (props: Props) => {
  const Button = props.type === `full` ? ButtonFallback : LinkFallback

  return (
    <Suspense
      fallback={<Button data-test-id='credit-card-modal-loading-button'>{props.children}</Button>}
    >
      <LazyCreditCardModalButton {...props} />
    </Suspense>
  )
}

export default CreditCardModalButton
