/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import { useConfig } from '@modules/cui/ConfigContext'
import type Feature from '@modules/utils/feature'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import type { FunctionComponent } from 'react'

interface Props {
  feature: Feature
  ldFlag?: string
}

interface FeatureToggleProps {
  isFeatureEnabled?: boolean
}

export const CuiFeatureEnabled: FunctionComponent<FeatureToggleProps> = ({
  children,
  isFeatureEnabled,
}) => <Fragment>{isFeatureEnabled && children}</Fragment>

export const CuiFeatureDisabled: FunctionComponent<FeatureToggleProps> = ({
  children,
  isFeatureEnabled,
}) => <Fragment>{!isFeatureEnabled && children}</Fragment>

const toggledChildren = ({ children, isFeatureEnabled }) =>
  React.Children.map(children, (child) => {
    if (
      React.isValidElement(child) &&
      (child as React.ReactElement<any>).type !== CuiFeatureEnabled &&
      (child as React.ReactElement<any>).type !== CuiFeatureDisabled
    ) {
      console.error(
        'CuiFeatureFlagGate only accepts children of type CuiFeatureEnabled and CuiFeatureDisabled. CuiFeatureDisabled is [optional]',
      )
    }

    return React.cloneElement(child, {
      isFeatureEnabled,
    })
  })

export const CuiFeatureFlagGate: FunctionComponent<Props> = ({ feature, ldFlag, children }) => {
  const isConfigFeatureFlagEnabled = Boolean(useConfig(feature))
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  // Is Feature enabled via feature flag configs
  if (!isConfigFeatureFlagEnabled) {
    return <Fragment>{toggledChildren({ children, isFeatureEnabled: false })}</Fragment>
  }

  if (typeof ldFlag !== 'undefined' && !isFlagUsable) {
    return <EuiSkeletonText data-test-id='ld-feature-flag-loading' />
  }

  // Is Feature available via feature flag configs and LaunchDarkly if flag present
  return (
    <Fragment>
      {toggledChildren({
        children,
        isFeatureEnabled: typeof ldFlag === 'undefined' || Boolean(flags[ldFlag]),
      })}
    </Fragment>
  )
}
