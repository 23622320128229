/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AjaxResult } from '@modules/utils/ajax'
import type { ElasticsearchId, RegionId } from '@modules/ui-types'

import { DOWNLOAD_CLUSTER_LOGS } from '../../constants/actions'

import type { AsyncAction, ReduxState } from '@/types/redux'

export interface State {
  [descriptor: string]: AjaxResult
}

interface DownloadLogsAction extends AsyncAction<typeof DOWNLOAD_CLUSTER_LOGS, AjaxResult> {
  meta: {
    deploymentId: string
    refId: string
  }
}

export default function clustersLogsReducer(state: State = {}, action: DownloadLogsAction): State {
  if (action.type === DOWNLOAD_CLUSTER_LOGS) {
    if (!action.error && action.payload) {
      const { deploymentId, refId } = action.meta
      const descriptor = createDescriptor(deploymentId, refId)

      return {
        ...state,
        [descriptor]: action.payload,
      }
    }
  }

  return state
}

function _getClusterLogs(state: State, deploymentId: string, refId: string) {
  return state[createDescriptor(deploymentId, refId)]
}

function createDescriptor(deploymentId: string, refId: string) {
  return `${deploymentId}/${refId}`
}

export const getClusterLogs = (state: ReduxState, regionId: RegionId, clusterId: ElasticsearchId) =>
  _getClusterLogs(state.clusterLogs, regionId, clusterId)
