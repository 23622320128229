/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  currentMonth: {
    id: 'cost-analysis.current-month',
    defaultMessage: 'Current month',
  },
  monthToDateUsage: {
    id: 'cost-analysis.month-usage',
    defaultMessage: 'Month-to-date usage',
  },
  monthToDateUsageSubtext: {
    id: 'cost-analysis.month-usage.subtext',
    defaultMessage: 'for all hosted deployments and serverless projects',
  },
  monthToDateUsageTip: {
    id: 'cost-analysis.month-to-date.tip',
    defaultMessage:
      "This amount reflects the total costs across all serverless projects and hosted deployments in your organization for the current month, up until today's date.",
  },
})
