/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'
import React, { Component } from 'react'
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl'

import type { EuiFormRowProps, EuiFieldTextProps } from '@elastic/eui'
import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSteps,
  EuiSelect,
  EuiDatePicker,
  EuiText,
  EuiCallOut,
  EuiSpacer,
} from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { isRoleAssignmentsEmpty } from '@modules/role-assignments-lib'

import SpinButton from '@/components/SpinButton'
import RoleAssignmentsPanel from '@/components/Users/RoleAssignmentsPanel'
import DocLink from '@/components/DocLink'

import messages from './messages'
import { customExpirationDate, diffFromExpirationToToday, diff, expirationDate } from './lib'

import type { ExpirationOption } from './lib'
import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

type Props = {
  onChangeName: NonNullable<EuiFieldTextProps['onChange']>
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void
  onChangeExpiration: (expiration: string | null) => void
  onNext: () => void
  organizationId: string | undefined
  invalidKeyName: boolean
  roleAssignments: RoleAssignments
  errors: EuiFormRowProps['error']
  defaultExpiration: ExpirationOption
  showNeverExpirationOption: boolean
  showRoleAssignments: boolean
} & WrappedComponentProps

type FooterProps = {
  onCancel: () => void
  onNext: () => void
  generateKeyRequest: AsyncRequestState
  roleAssignments: RoleAssignments
  showRoleAssignments: boolean
}
interface State {
  customExpiration: moment.Moment
  selectedExpirationDropdown: ExpirationOption
  roleAssignments: RoleAssignments
}

const tomorrow = moment().startOf('day').add(1, 'day')

class GenerateKeyForm extends Component<Props, State> {
  state: State = {
    selectedExpirationDropdown: this.props.defaultExpiration,
    customExpiration: tomorrow,
    roleAssignments: this.props.roleAssignments,
  }

  render() {
    const {
      errors,
      invalidKeyName,
      roleAssignments,
      onChangeName,
      onNext,
      organizationId,
      intl: { formatMessage },
      onChangeRoleAssignments,
      showRoleAssignments,
    } = this.props

    const helpText = !invalidKeyName ? <FormattedMessage {...messages.keyNameHelpText} /> : null

    const onKeyDown: EuiFieldTextProps['onKeyDown'] = (e) => {
      if (e.key === 'Enter') {
        onNext()
      }
    }

    const steps = [
      {
        title: formatMessage(messages.addName),
        children: (
          <EuiForm>
            <EuiFormRow
              helpText={helpText}
              error={errors}
              isInvalid={invalidKeyName}
              label={formatMessage(messages.keyNameColumn)}
            >
              <EuiFieldText
                fullWidth={true}
                name='name'
                onKeyDown={onKeyDown}
                onChange={onChangeName}
              />
            </EuiFormRow>
          </EuiForm>
        ),
      },
      {
        title: formatMessage(messages.setExpirationDate),
        children: this.renderSelectExpirationDate(),
      },
      ...(showRoleAssignments && organizationId
        ? [
            {
              title: formatMessage(messages.assignRoles),
              children: (
                <EuiFlexGroup direction='column'>
                  <EuiFlexItem>
                    <EuiCallOut
                      color='warning'
                      title={<FormattedMessage {...messages.assignRolesHelpTextTitle} />}
                    >
                      <FormattedMessage
                        {...messages.assignRolesHelpText}
                        values={{
                          kibanaLink: (
                            <DocLink link='apiKeysKibanaLink'>
                              <FormattedMessage {...messages.kibanaText} />
                            </DocLink>
                          ),
                          elasticsearchLink: (
                            <DocLink link='apiKeysElasticsearchLink'>
                              <FormattedMessage {...messages.elasticsearchText} />
                            </DocLink>
                          ),
                        }}
                      />
                    </EuiCallOut>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <RoleAssignmentsPanel
                      organizationId={organizationId}
                      roleAssignments={roleAssignments}
                      onChangeRoleAssignments={onChangeRoleAssignments}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
            },
          ]
        : []),
    ]

    return <EuiSteps steps={steps} />
  }

  renderSelectExpirationDate() {
    const {
      defaultExpiration,
      showNeverExpirationOption,
      intl: { formatMessage },
    } = this.props

    const options = [
      ...(showNeverExpirationOption
        ? [
            {
              value: 'never',
              text: formatMessage({
                id: 'api-keys.never',
                defaultMessage: 'Never',
              }),
            },
          ]
        : [
            {
              value: '12 hour',
              text: formatMessage({
                id: 'api-keys.12-hours',
                defaultMessage: '12 hours',
              }),
            },
          ]),
      {
        value: '1 week',
        text: formatMessage({
          id: 'api-keys.1-week',
          defaultMessage: '1 week',
        }),
      },
      {
        value: '1 month',
        text: formatMessage({
          id: 'api-keys.1-month',
          defaultMessage: '1 month',
        }),
      },
      {
        value: '2 month',
        text: formatMessage({
          id: 'api-keys.2-months',
          defaultMessage: '2 months',
        }),
      },
      {
        value: '3 month',
        text: formatMessage({
          id: 'api-keys.3-months',
          defaultMessage: '3 months',
        }),
      },
      {
        value: '1 year',
        text: formatMessage({
          id: 'api-keys.1-year',
          defaultMessage: '1 year',
        }),
      },
      {
        value: 'custom',
        text: formatMessage({
          id: 'api-keys.custom',
          defaultMessage: 'Custom',
        }),
      },
    ]

    return (
      <EuiFlexGroup direction='column' gutterSize='xs'>
        <EuiFlexItem>
          <EuiText size='xs' style={{ fontWeight: '300', marginTop: '-20px' }}>
            <FormattedMessage
              id='api-keys.subtitle'
              defaultMessage='API keys can be valid up to one year.'
            />
          </EuiText>
        </EuiFlexItem>
        <EuiSpacer size='m' />
        <EuiFlexItem>
          <EuiText size='xs' style={{ fontWeight: '600' }}>
            <FormattedMessage id='api-keys.expires' defaultMessage='Expires' />
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFlexGroup direction='column' gutterSize='s'>
                <EuiFlexItem>
                  <EuiSelect
                    defaultValue={defaultExpiration}
                    fullWidth={true}
                    options={options}
                    onChange={(e) =>
                      this.onChangeExpirationDropdown(e.target.value as ExpirationOption)
                    }
                  />
                </EuiFlexItem>
                {this.state.selectedExpirationDropdown === 'never' && (
                  <EuiFlexItem>
                    <EuiText size='xs' style={{ fontWeight: '200' }}>
                      <FormattedMessage
                        id='api-keys.no-expiration-warning'
                        defaultMessage='To reinforce the security of your organization, we recommend refreshing API keys at least once every 3 months'
                      />
                    </EuiText>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>

            {this.state.selectedExpirationDropdown === 'custom' && (
              <EuiFlexItem>
                <EuiDatePicker
                  selected={this.state.customExpiration}
                  minDate={tomorrow}
                  maxDate={moment().startOf('day').add(1, 'year')}
                  onChange={(date) => this.onChangeExpirationDatePicker(date)}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiSpacer size='m' />
        <EuiFlexItem>
          {this.state.selectedExpirationDropdown === 'never' ? (
            <EuiText size='s' style={{ fontWeight: '400' }}>
              <FormattedMessage
                id='api-keys.never-description'
                defaultMessage="The API key won't expire."
              />
            </EuiText>
          ) : (
            <EuiText size='s' style={{ fontWeight: '400' }}>
              <FormattedMessage
                id='api-keys.the-api-key-expires-on-x'
                defaultMessage='The API key will expire on {expiration}.'
                values={{
                  expiration: (
                    <strong>
                      <FormattedDate
                        value={this.getExpirationDatePreview()}
                        year='numeric'
                        month='short'
                        day='numeric'
                        hour='numeric'
                        minute='numeric'
                      />
                    </strong>
                  ),
                }}
              />
            </EuiText>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  onChangeExpirationDropdown = (expiration: ExpirationOption) => {
    this.setState({ selectedExpirationDropdown: expiration }, () =>
      this.props.onChangeExpiration(diffFromExpirationToToday(expiration, moment())),
    )
  }

  onChangeExpirationDatePicker(date: moment.Moment | null) {
    if (date === null) {
      return
    }

    this.setState({ customExpiration: date }, () =>
      this.props.onChangeExpiration(diff(date, moment())),
    )
  }

  getExpirationDatePreview(): Date {
    if (this.state.selectedExpirationDropdown === 'custom') {
      return customExpirationDate(moment(), this.state.customExpiration).toDate()
    }

    return expirationDate(moment(), this.state.selectedExpirationDropdown).toDate()
  }
}

export default injectIntl(GenerateKeyForm)

export const GenerateKeyFormFooter: FunctionComponent<FooterProps> = ({
  generateKeyRequest,
  roleAssignments,
  showRoleAssignments,
  onCancel,
  onNext,
}) => (
  <EuiFlexGroup justifyContent='spaceBetween'>
    <EuiFlexItem grow={false}>
      <EuiButtonEmpty onClick={() => onCancel()}>
        <FormattedMessage {...messages.apiKeyCancel} />
      </EuiButtonEmpty>
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <SpinButton
        spin={generateKeyRequest.inProgress}
        onClick={() => onNext()}
        fill={true}
        isDisabled={showRoleAssignments && isRoleAssignmentsEmpty(roleAssignments)}
      >
        <FormattedMessage {...messages.createApiKey} />
      </SpinButton>
    </EuiFlexItem>
  </EuiFlexGroup>
)
