/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'
import { isEqual } from 'lodash'

import type { AuthzCheckResponse, AuthzCheck, AuthzResult } from '@modules/cloud-api/v1/types'
import { fetchAsJson } from '@modules/query/helpers'
import { checkAuthorizationUrl } from '@modules/cloud-api/v1/urls'
import type { Fetcher, QueryMatcher } from '@modules/query/QueryBatcher'
import QueryBatcher from '@modules/query/QueryBatcher'

import type { UseQueryResult } from 'react-query'

export const queryMatcher: QueryMatcher<AuthzCheck, AuthzCheckResponse, AuthzResult> = (
  authzCheckResponse: AuthzCheckResponse,
  authzChecks: AuthzCheck[],
): AuthzResult[] =>
  authzCheckResponse.results.filter(({ check }) =>
    authzChecks.some((authzCheck) => isEqual(check, authzCheck)),
  )

const fetcher: Fetcher<AuthzCheck, AuthzCheckResponse> = async (checks: AuthzCheck[]) =>
  fetchAsJson<AuthzCheckResponse>(checkAuthorizationUrl(), {
    method: 'post',
    body: JSON.stringify({
      checks,
    }),
  })

// batcher must be a singleton, otherwise queries are not debounced together
const batcher: QueryBatcher<AuthzCheck, AuthzCheckResponse, AuthzResult> = new QueryBatcher(
  fetcher,
  queryMatcher,
)

const useCheckAuthorizationQuery = ({
  enabled,
  checks,
}: {
  enabled: boolean
  checks: AuthzCheck[]
}): UseQueryResult<AuthzResult[]> =>
  useQuery({
    enabled,
    queryKey: ['checkAuthorizationQuery', checks],
    queryFn: async () => batcher.addAll(checks),
  })

export default useCheckAuthorizationQuery
