/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut } from '@elastic/eui'

import type { ProfileState } from '@modules/ui-types'

import SupportPortalTile from '../SupportPortalTile'

import type { FunctionComponent } from 'react'

interface Props {
  profile: NonNullable<ProfileState>
}

const HelpSupportArea: FunctionComponent<Props> = ({ profile }) => {
  if (!profile) {
    return (
      <EuiCallOut title='error' color='danger' iconType='error'>
        <p>
          <FormattedMessage
            id='help.support-portal.error'
            defaultMessage='The profile is not available'
          />
        </p>
      </EuiCallOut>
    )
  }

  return <SupportPortalTile />
}

export default HelpSupportArea
