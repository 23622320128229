/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { SaasUserResponse, SaasUserUpdateDiscoveryQuestions } from '@modules/cloud-api/v1/types'
import { putSaasCurrentUserDiscoveryQuestionsUrl } from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'

export const putDiscoveryQuestions = async (payload: Partial<SaasUserUpdateDiscoveryQuestions>) =>
  fetchAsJson<SaasUserResponse>(putSaasCurrentUserDiscoveryQuestionsUrl(), {
    method: 'put',
    body: JSON.stringify(payload),
  })
