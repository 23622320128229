/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalHeaderTitle,
  EuiTextColor,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import { getSaasEntityTrialDaysRemaining } from '@modules/profile-lib/trial'

type Props = {
  saasUser: SaasUserResponse
}

const Header: React.FunctionComponent<Props> = ({ saasUser }) => {
  const titleId = useGeneratedHtmlId()

  const daysRemaining = getSaasEntityTrialDaysRemaining(saasUser)

  return (
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={true}>
        <EuiModalHeaderTitle id={titleId}>
          <FormattedMessage id='trialSummary.title' defaultMessage='Trial summary' />
        </EuiModalHeaderTitle>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiTextColor color='warning'>
          <FormattedMessage
            id='trialSummary.daysRemaining'
            defaultMessage='{daysRemaining} {daysRemaining, plural, one {day} other {days}} left in your trial'
            values={{ daysRemaining }}
          />
        </EuiTextColor>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default Header
