/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  BillingHistory,
  ItemsCosts,
  LineItem,
  Prepaids,
  ChartItems,
  AzureMarketplaceSubscription,
} from '@modules/cloud-api/v1/types'
import type { GetCostsOverviewUrlParams } from '@modules/cloud-api/v1/urls'
import type { AccountCosts, AccountCostsSummary } from '@modules/ui-types'

import {
  FETCH_DEPLOYMENTS_COSTS,
  FETCH_ACCOUNT_ITEMS_COSTS,
  FETCH_DEPLOYMENT_COST_ITEMS,
  FETCH_ACCOUNT_COSTS_OVERVIEW,
  FETCH_PREPAID_BALANCE_LINE_ITEMS,
  FETCH_BILLING_HISTORY,
  FETCH_ORGANIZATION_PREPAIDS,
  FETCH_MARKETPLACE_BILLING_DETAILS,
} from '@/constants/actions'
import { getAsyncRequestState } from '@/reducers/asyncRequests'
import { replaceIn } from '@/lib/immutability-helpers'

import type { ReduxState } from '@/types/redux'

export type UserState = {
  costs?: AccountCosts
  deploymentItemsCosts?: ItemsCosts
  deploymentItemsCostsByDeployment?: ItemsCosts
  costsOverview?: Record<string, AccountCostsSummary>
  prepaidBalanceLineItems?: LineItem[]
  billingHistory?: BillingHistory
  prepaids?: Prepaids
  costsCharts?: ChartItems
  costsChartsByDeployment?: Record<string, ChartItems>
  marketplaceBillingDetails?: AzureMarketplaceSubscription // for now we have only details for Azure
}

export type State = Record<string, UserState>

const initialState: State = {}

export default function billingReducer(state = initialState, action): State {
  if (!action.error && action.payload) {
    if (action.type === FETCH_DEPLOYMENTS_COSTS) {
      const {
        payload,
        meta: { timePeriod, organizationId },
      } = action

      return replaceIn(state, [organizationId, 'costs'], { ...payload, timePeriod })
    }

    if (action.type === FETCH_ACCOUNT_ITEMS_COSTS) {
      const {
        payload,
        meta: { timePeriod, organizationId },
      } = action

      return replaceIn(state, [organizationId, 'deploymentItemsCosts'], {
        ...payload,
        timePeriod,
      })
    }

    if (action.type === FETCH_DEPLOYMENT_COST_ITEMS) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'deploymentItemsCostsByDeployment'], payload)
    }

    if (action.type === FETCH_ACCOUNT_COSTS_OVERVIEW) {
      const {
        payload,
        meta: { organizationId, from, to },
      } = action
      const {
        costs: { total },
        trials,
      } = payload

      const dataKey = from && to ? `${from}_${to}` : 'current'

      return replaceIn(state, [organizationId, 'costsOverview', dataKey], {
        ...payload,
        isTrialConversionUser: trials > 0 && trials !== total,
        paidUsage: total - trials,
      })
    }

    if (action.type === FETCH_PREPAID_BALANCE_LINE_ITEMS) {
      const {
        payload: { line_items },
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'prepaidBalanceLineItems'], line_items)
    }

    if (action.type === FETCH_BILLING_HISTORY) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'billingHistory'], payload)
    }

    if (action.type === FETCH_MARKETPLACE_BILLING_DETAILS) {
      const {
        payload,
        meta: { organizationId },
      } = action

      return replaceIn(state, [organizationId, 'marketplaceBillingDetails'], payload)
    }
  }

  if (action.type === FETCH_ORGANIZATION_PREPAIDS) {
    const {
      payload,
      meta: { organizationId },
    } = action

    return replaceIn(state, [organizationId, 'prepaids'], payload)
  }

  return state
}

function _getAccountDeploymentsCosts(
  state: State,
  organizationId: string,
): AccountCosts | undefined {
  return state[organizationId]?.costs
}

interface GetAccountCostsOverviewProps extends GetCostsOverviewUrlParams {
  state: State
}

function _getAccountCostsOverview({
  state,
  organizationId,
  from,
  to,
}: GetAccountCostsOverviewProps): AccountCostsSummary | undefined {
  const dataKey = from && to ? `${from}_${to}` : 'current'

  return state[organizationId]?.costsOverview?.[dataKey]
}

function _getPrepaidBalanceLineItems(state: State, organizationId: string): LineItem[] | undefined {
  return state[organizationId]?.prepaidBalanceLineItems
}

function _getBillingHistory(state: State, organizationId: string): BillingHistory | undefined {
  return state[organizationId]?.billingHistory
}

function _getOrganizationPrepaids(state: State, organizationId: string): Prepaids | undefined {
  return state[organizationId]?.prepaids
}

function _getMarketplaceBillingDetails(
  state: State,
  organizationId: string,
): AzureMarketplaceSubscription | undefined {
  return state[organizationId]?.marketplaceBillingDetails
}

export const fetchBillingHistoryRequest = getAsyncRequestState(FETCH_BILLING_HISTORY)

interface GetAccountCostsOverviewParams extends GetCostsOverviewUrlParams {
  state: ReduxState
}

export const getAccountCostsOverview = ({ state, ...restProps }: GetAccountCostsOverviewParams) =>
  _getAccountCostsOverview({ state: state.billing, ...restProps })

export const getPrepaidBalanceLineItems = (state: ReduxState, organizationId: string) =>
  _getPrepaidBalanceLineItems(state.billing, organizationId)

export const getBillingHistory = (state: ReduxState, organizationId: string) =>
  _getBillingHistory(state.billing, organizationId)

export const getOrganizationPrepaids = (state: ReduxState, organizationId: string) =>
  _getOrganizationPrepaids(state.billing, organizationId)

export const getAccountDeploymentsCosts = (state: ReduxState, organizationId: string) =>
  _getAccountDeploymentsCosts(state.billing, organizationId)

export const getMarketplaceBillingDetails = (state: ReduxState, organizationId: string) =>
  _getMarketplaceBillingDetails(state.billing, organizationId)
