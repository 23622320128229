/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'
import React from 'react'

import type { SearchFilterConfig } from '@elastic/eui'

import type { WrappedComponentProps } from 'react-intl'

const messages = defineMessages({
  roleLabel: {
    id: `apikeys-filter-context.status-label`,
    defaultMessage: `Status`,
  },
})

export function getFilters({
  intl: { formatMessage },
}: WrappedComponentProps): SearchFilterConfig[] {
  const filters: NonNullable<SearchFilterConfig[]> = [
    {
      name: formatMessage(messages.roleLabel),
      type: `field_value_selection`,
      filterWith: `includes`,
      field: `status`,
      multiSelect: `or`,
      options: getStatusOptions(),
    },
  ]

  return filters
}

function getStatusOptions() {
  return [
    {
      value: 'active',
      view: renderValue('Active'),
    },
    {
      value: 'expiresSoon',
      view: renderValue('Expires soon'),
    },
    {
      value: 'expired',
      view: renderValue('Expired'),
    },
  ]
}

function renderValue(value) {
  return <div>{value}</div>
}
