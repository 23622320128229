/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import Feature from '@modules/utils/feature'

import { hasEnabledSnapshots } from '@/lib/stackDeployments/selectors/snapshots'
import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { getClusterSnapshots } from '@/reducers/clusterSnapshots'

import { isFeatureActivated } from '../../../selectors'
import { getConfigForKey } from '../../../store'
import { withStackDeploymentRouteParams } from '../../StackDeploymentEditor'

import ClusterSnapshots from './ClusterSnapshots'

import type { ReduxState } from '@/types/redux'
import type { StateProps, ConsumerProps } from './ClusterSnapshots'

const mapStateToProps = (state: ReduxState, { stackDeploymentId }): StateProps => {
  const deployment = getStackDeployment(state, stackDeploymentId)
  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`
  const isHeroku = getConfigForKey(`APP_FAMILY`) === `heroku`
  const esResource = deployment && getFirstEsClusterFromGet({ deployment })
  const snapshotsEnabled = esResource && hasEnabledSnapshots({ resource: esResource })

  return {
    snapshotSettingsEnabled: snapshotsEnabled === false ? snapshotsEnabled : true,
    stackDeployment: getStackDeployment(state, stackDeploymentId),
    snapshotHistory: getClusterSnapshots(state, stackDeploymentId),
    hasDefaultSnapshotRepository: isFeatureActivated(state, Feature.defaultSnapshotRepository),
    isUserConsole,
    isHeroku,
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, ConsumerProps>(mapStateToProps)(
    withTransaction(`Elasticsearch snapshots`, `component`)(ClusterSnapshots),
  ),
)
