/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { combineReducers } from 'redux'

import type { RegionId, CloudAppConfig } from '@modules/ui-types'

import adminconsoleReducers from '../apps/adminconsole/reducers'
import userconsoleReducers from '../apps/userconsole/reducers'
import { getConfigForKeyFrom } from '../store'

import adminconsoleLoggingSettings from './adminconsoleLoggingSettings'
import adminconsoles from './adminconsoles'
import allocatorLoggingSettings from './allocatorLoggingSettings'
import allocators from './allocators'
import allocatorSearch from './allocatorSearch'
import apiBaseUrl from './apiBaseUrl'
import apiKeys from './apiKeys'
import apms from './apms'
import apmTokens from './apms/apmTokens'
import appSearchToEnterpriseSearchMigrationProgress from './appSearchToEnterpriseSearchMigrationProgress'
import asyncRequests from './asyncRequests'
import auth from './auth'
import authMethods from './authMethods'
import authzRoles from './authzRoles'
import billing from './billing'
import blogs from './blogs'
import blueprint from './blueprint'
import ccsEligibleRemotes from './ccsEligibleRemotes'
import ccsSettings from './ccsSettings'
import cloudStatus from './status'
import apiConsole from './apiConsole'
import clusterDiagnosticBundles from './clusterDiagnosticBundles'
import clusterHealth from './clusters/clusterHealth'
import clusterKeystore from './clusterKeystore'
import clusterLogs from './clusterLogs'
import clusterRecoveryInfo from './clusters/recoveryInfo'
import clusters from './clusters'
import clustersCredentials from './clusters/clustersCredentials'
import clusterSnapshots from './clusterSnapshots'
import config from './config'
import constructorLoggingSettings from './constructorLoggingSettings'
import containerSets from './containerSets'
import coordinatorDemotions from './runners/demoteCoordinator'
import coordinators from './runners/fetchCoordinators'
import currentAccount from './currentAccount'
import currentUser from './currentUser'
import deleteAllocator from './allocators/deleteAllocator'
import deploymentAlias from './deploymentAlias'
import deploymentDomainName from './deploymentDomainName'
import deploymentExtensions from './deploymentExtensions'
import deploymentHeapDumps from './deploymentHeapDumps'
import deploymentHealth from './deploymentHealth'
import deploymentProxy from './deploymentProxy'
import deploymentTemplates from './deploymentTemplates'
import eolStatus from './eolStatus'
import feeds from './feeds'
import globalDeploymentTemplates from './globalDeploymentTemplates'
import instanceConfigurations from './instanceConfigurations'
import instanceTypes from './instanceTypes'
import licenses from './licenses'
import localUsers from './localUsers'
import mfaDevices from './mfaDevices'
import newTempShieldUser from './clusters/newTempShieldUser'
import nodeStats from './nodeStats'
import notificationMessages from './notificationMessages'
import organizations from './organizations'
import oktaGroups from './oktaGroups'
import pendingTemplate from './pendingTemplate'
import phoneHomeConfig from './phoneHomeConfig'
import phoneHomeDisabled from './phoneHomeDisabled'
import planActivityDetail from './planActivityDetail'
import platform from './platform'
import providers, { _getDefaultProvider, getDefaultProvider } from './providers'
import proxies from './proxies'
import regions, { getRegionIds } from './regions'
import resourceComments from './resourceComments'
import runnerLoggingSettings from './runnerLoggingSettings'
import runners from './runners'
import saasOrganizations from './saasOrganizations'
import saasUsers from './saasUsers'
import searchForAnything from './searchForAnything'
import snapshotRepositories from './snapshotRepositories'
import snapshotStatus from './snapshotStatus'
import stackDeploymentDryRuns from './stackDeploymentDryRuns'
import stackDeploymentTemplateMigration from './stackDeploymentTemplateMigration'
import stackDeployments from './stackDeployments'
import stackDeploymentSearches from './stackDeploymentSearches'
import storedProcedures from './storedProcedures'
import theme from './theme'
import tls from './tls'
import trafficFilters from './trafficFilters'
import trustManagement from './trustManagement'
import trustedEnvs from './trustedEnvs'
import upgradeAssistantStatus from './upgradeAssistantStatus'
import vacate from './vacate'
import vacateValidate from './vacateValidate'
import vacateEsClusters from './vacateEsClusters'
import versions from './elasticStack'
import certificateAuthority from './certificateAuthority'
import allowExtraVersionsStatus from './allowExtraVersionsStatus'
import snapshotDeploymentDependencies from './snapshotDeploymentConfiguration'
import byokToggle from './byokToggle'

import type { ReduxState } from '@/types/redux'

export function getReducers(config: CloudAppConfig) {
  // @ts-ignore I think the problem here is that we haven't typed all the actions...?
  return combineReducers<ReduxState>({
    ...getCommonReducers(),
    ...getAppSpecificReducers(config),
  })
}

function getCommonReducers() {
  return {
    adminconsoleLoggingSettings,
    adminconsoles,
    allocatorLoggingSettings,
    allocators,
    allocatorSearch,
    apiBaseUrl,
    apiConsole,
    apiKeys,
    apms,
    apmTokens,
    appSearchToEnterpriseSearchMigrationProgress,
    asyncRequests,
    auth,
    authMethods,
    authzRoles,
    billing,
    blogs,
    blueprint,
    ccsEligibleRemotes,
    ccsSettings,
    cloudStatus,
    clusterDiagnosticBundles,
    clusterHealth,
    clusterKeystore,
    clusterLogs,
    clusterRecoveryInfo,
    clusters,
    clustersCredentials,
    clusterSnapshots,
    config,
    constructorLoggingSettings,
    containerSets,
    coordinatorDemotions,
    coordinators,
    currentAccount,
    currentUser,
    deleteAllocator,
    deploymentAlias,
    deploymentDomainName,
    deploymentExtensions,
    deploymentHeapDumps,
    deploymentProxy,
    deploymentTemplates,
    eolStatus,
    deploymentHealth,
    feeds,
    globalDeploymentTemplates,
    instanceConfigurations,
    instanceTypes,
    licenses,
    localUsers,
    mfaDevices,
    newTempShieldUser,
    nodeStats,
    notificationMessages,
    organizations,
    oktaGroups,
    pendingTemplate,
    phoneHomeConfig,
    phoneHomeDisabled,
    planActivityDetail,
    platform,
    providers,
    proxies,
    regions,
    resourceComments,
    runnerLoggingSettings,
    runners,
    saasOrganizations,
    saasUsers,
    searchForAnything,
    snapshotRepositories,
    snapshotStatus,
    stackDeploymentDryRuns,
    stackDeploymentTemplateMigration,
    stackDeployments,
    stackDeploymentSearches,
    storedProcedures,
    theme,
    tls,
    trafficFilters,
    trustManagement,
    trustedEnvs,
    upgradeAssistantStatus,
    vacate,
    vacateValidate,
    vacateEsClusters,
    versions,
    certificateAuthority,
    allowExtraVersionsStatus,
    snapshotDeploymentDependencies,
    byokToggle,
  }
}

function getAppSpecificReducers(config: CloudAppConfig) {
  if (getConfigForKeyFrom(config, `APP_NAME`) === `userconsole`) {
    return userconsoleReducers
  }

  return adminconsoleReducers // SaaS adminconsole and Cloud Enterprise
}

export const getDefaultRegionId = (state: ReduxState): RegionId => {
  const defaultProvider = getDefaultProvider(state)
  const defaultRegionId = defaultProvider?.regions[0]?.identifier ?? getRegionIds(state)?.[0]

  return defaultRegionId as string
}
