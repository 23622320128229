/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EUI_MODAL_CONFIRM_BUTTON, EuiOverlayMask, EuiConfirmModal } from '@elastic/eui'

import history from '@modules/utils/history'

import { accountMembersUrl } from '@/apps/userconsole/urls'

import type { ReactElement } from 'react'

type State = {
  isModalDismissed: boolean
}

type Props = {
  organizationName: string
}

class MultipleOrganizationsError extends Component<Props, State> {
  state = { isModalDismissed: false }

  render(): ReactElement | null {
    const { isModalDismissed } = this.state

    if (isModalDismissed) {
      return null
    }

    const { organizationName } = this.props

    return (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <FormattedMessage
              id='organization.accept-organization-invitation.multiple-organizations-error.title'
              defaultMessage='You cannot join organization {organizationName}'
              values={{ organizationName }}
            />
          }
          onCancel={() => this.setState({ isModalDismissed: true })}
          cancelButtonText={
            <FormattedMessage
              id='organization.accept-organization-invitation.multiple-organizations-error.cancel'
              defaultMessage='Cancel'
            />
          }
          onConfirm={() => {
            history.push(accountMembersUrl())
          }}
          confirmButtonText={
            <FormattedMessage
              id='organization.accept-organization-invitation.multiple-organizations-error.view-my-organization'
              defaultMessage='View my organization'
            />
          }
          defaultFocusedButton={EUI_MODAL_CONFIRM_BUTTON}
          style={{ minWidth: '768px', minHeight: '215px' }}
          data-test-id='organization.accept-organization-invitation.multiple-organizations-error'
        >
          <FormattedMessage
            id='organization.accept-organization-invitation.multiple-organizations-error.body'
            defaultMessage='You cannot belong to multiple organizations. To join {organizationName}, you must leave your current organization and click the invite link again.'
            values={{ organizationName }}
          />
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }
}

export default MultipleOrganizationsError
