/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { useLocation } from 'react-router-dom'

import { EuiFlexGroup } from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'

// eslint-disable-next-line import/no-restricted-paths
import elasticLogo from '@/files/elastic-logo-H-color.svg'
// eslint-disable-next-line import/no-restricted-paths
import elasticLogoDark from '@/files/elastic-logo-H-color-dark.svg'

import { LoginButton, LogoutButton, SignupButton } from './HeaderButton'

import type { FunctionComponent, ReactElement } from 'react'
import type { LandingPageContext } from '../types'

import './header.scss'

export interface Props {
  pageContext?: LandingPageContext
  onPageContextSwitch?: (pageContext: LandingPageContext) => void
  isFlowV2?: boolean
}

const Header: FunctionComponent<Props> = ({ pageContext, onPageContextSwitch, isFlowV2 }) => {
  const { search } = useLocation()

  function renderButton(): ReactElement | null {
    if (!pageContext) {
      return null
    }

    const onClick = onPageContextSwitch
      ? () => onPageContextSwitch(getSwitchedContext())
      : undefined
    const { name: pageContextName, contextSwitchLocationState } = pageContext
    const buttonProps = {
      locationQueryString: search,
      onClick,
      locationState: contextSwitchLocationState,
      isFlowV2,
    }

    if (pageContextName === 'signup') {
      return <LoginButton {...buttonProps} />
    }

    if (pageContextName === 'loggedIn') {
      return <LogoutButton {...buttonProps} />
    }

    return <SignupButton {...buttonProps} />
  }

  function getSwitchedContext(): LandingPageContext {
    const pageContextName = pageContext?.name

    if (pageContextName === 'signup') {
      return { name: 'login' }
    }

    return { name: 'signup' }
  }

  return (
    <EuiFlexGroup justifyContent={isFlowV2 ? 'spaceBetween' : 'flexEnd'} alignItems='center'>
      <div css={css({ minHeight: '30px' })}>
        <CuiThemedIcon
          size='xxl'
          css={css(
            isFlowV2
              ? { width: 'auto', height: '39px' }
              : {
                  width: 'auto',
                  height: '58px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                },
          )}
          lightType={elasticLogo}
          darkType={elasticLogoDark}
        />
      </div>
      {renderButton()}
    </EuiFlexGroup>
  )
}

export default Header
