/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment, PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { capitalize } from 'lodash'
import { css, jsx } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Link } from 'react-router-dom'

import type { WithEuiThemeProps } from '@elastic/eui'
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonEmpty,
  EuiCallOut,
  EuiSpacer,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  withEuiTheme,
  EuiImage,
  EuiFlexGrid,
  EuiLink,
} from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'
import { getSupportNameBasedOnLevel } from '@modules/billing-lib/supportName'
import { defaultSubscriptionLevelByUser } from '@modules/billing-lib/utils'
import type { Subscription } from '@modules/ui-types/billing'

import applyCreditIcon from '@/files/apply-credit.svg'
import { pricingTableUrl } from '@/apps/userconsole/urls'
import SelectSubscriptionBody from '@/apps/userconsole/components/Billing/SelectSubscription/SelectSubscriptionBody'
import { supportUrl } from '@/lib/urlBuilder'

import { ModalCss, ModalTitleCss } from './styles'

import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'
import type { ReactElement } from 'react'
import type { AllProps, AvailableBillingSubscriptionLevel } from './types'
import type { WrappedComponentProps } from 'react-intl'

const formId = 'prepaid-credit-activation-form'

interface State {
  selectedSubscription: AvailableBillingSubscriptionLevel
  showChangeSubscriptionModal: boolean
}

type Props = AllProps & WithEuiThemeProps & WrappedComponentProps & LDProps

class PrepaidCreditActivationModal extends PureComponent<Props, State> {
  state: State = {
    selectedSubscription: this.getDefaultLevel(),
    showChangeSubscriptionModal: false,
  }

  render(): ReactElement {
    const {
      onClose,
      onSubmit,
      onCodeChange,
      isLoading,
      isSubmitted,
      inputError,
      generalError,
      theme,
      intl: { formatMessage },
    } = this.props
    const { showChangeSubscriptionModal, selectedSubscription } = this.state

    if (showChangeSubscriptionModal) {
      return (
        <EuiModal
          onClose={onClose}
          css={ModalCss({
            theme,
            changeSubscriptionModal: showChangeSubscriptionModal,
            isModalAnimating: false,
          })}
          maxWidth={false}
        >
          <SelectSubscriptionBody
            selectedSubscription={selectedSubscription}
            onSelectSubscription={this.onSelectSubscription}
            disabledSubscriptions={['standard']}
          />
          <EuiModalFooter>
            <div>
              <EuiFlexGroup justifyContent='flexEnd'>
                <EuiFlexItem>
                  <EuiButton
                    fullWidth={false}
                    data-test-id='save-subscription'
                    onClick={this.closeChangeSubscriptionModal}
                    fill={true}
                  >
                    <FormattedMessage id='update-subscription.save' defaultMessage='Save' />
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiModalFooter>
        </EuiModal>
      )
    }

    return (
      <EuiModal
        onClose={onClose}
        css={ModalCss({
          theme,
          changeSubscriptionModal: showChangeSubscriptionModal,
          isModalAnimating: isSubmitted,
        })}
      >
        <EuiImage
          size={200}
          css={css({ marginTop: 20 })}
          src={applyCreditIcon}
          alt={formatMessage({
            id: 'prepaid-credit-modal.add-ecu-credits-illustration',
            defaultMessage: 'Add ECU credits illustration',
          })}
        />
        <EuiModalHeader>
          <EuiModalHeaderTitle css={css(ModalTitleCss)} data-test-id='modalTitle'>
            {this.getTitle()}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody data-test-id='modalBody'>
          <EuiForm
            id={formId}
            component='form'
            onSubmit={(event) => {
              event.preventDefault()
              const data = new FormData(event.target as HTMLFormElement)
              const activation_code = data.get('activationCode') as string

              onSubmit({ activation_code, subscription_level: selectedSubscription })
            }}
          >
            <EuiFormRow
              isInvalid={Boolean(inputError)}
              error={inputError}
              fullWidth={true}
              label={
                <FormattedMessage
                  id='prepaid-credit-modal.label'
                  defaultMessage='Activation code'
                />
              }
            >
              <EuiFieldText
                name='activationCode'
                placeholder='XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
                fullWidth={true}
                isInvalid={Boolean(inputError)}
                isLoading={isLoading}
                disabled={isLoading || isSubmitted}
                onChange={onCodeChange}
                data-test-id='activationCodeTextField'
              />
            </EuiFormRow>
          </EuiForm>

          {generalError && this.renderGeneralError()}

          <EuiSpacer size='l' />
          {this.renderNotificationMessage()}
          <EuiSpacer size='m' />

          {this.renderSelectSubscription()}
        </EuiModalBody>

        <EuiModalFooter>{this.getFooter()}</EuiModalFooter>
      </EuiModal>
    )
  }

  renderNotificationMessage = (): JSX.Element => {
    const { profile } = this.props

    if (profile.level === 'standard') {
      return (
        <EuiText size='xs' color='warning'>
          <FormattedMessage
            id='prepaid-credit-modal.incompatible-message'
            defaultMessage='By adding prepaid Elastic Consumption Unit (ECU) credits, your subscription plan will be automatically upgraded to the Enterprise level at the Activation date agreed in your contract. Select Edit subscription to check other compatible plans.'
          />
        </EuiText>
      )
    }

    return (
      <EuiText size='xs' color='warning'>
        <FormattedMessage
          id='prepaid-credit-modal.compatible-message'
          defaultMessage='While your current subscription plan is compatible with prepaid Elastic Consumption Unit (ECU) credits, we recommend that you upgrade to Enterprise to get more cost saving options and optimize how you spend your ECUs.'
        />
      </EuiText>
    )
  }

  renderSelectSubscription = (): JSX.Element => {
    const { isSubmitted } = this.props
    const { selectedSubscription } = this.state

    return (
      <EuiFlexGroup direction='column' alignItems='flexStart' gutterSize='xs'>
        <EuiFlexGrid columns={3} gutterSize='s'>
          <EuiFlexItem>
            <FormattedMessage
              id='prepaid-credit-modal.subscription-selection.subscription'
              defaultMessage='Subscription'
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <strong>{capitalize(selectedSubscription)}</strong>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiLink
              onClick={this.openChangeSubscriptionModal}
              disabled={isSubmitted}
              data-test-id='change-subscription'
            >
              <FormattedMessage
                id='prepaid-credit-modal.change-subscription'
                defaultMessage='Edit'
              />
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexItem>
            <FormattedMessage
              id='prepaid-credit-modal.subscription-selection.support'
              defaultMessage='Support'
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <strong>{getSupportNameBasedOnLevel(selectedSubscription)}</strong>
          </EuiFlexItem>
        </EuiFlexGrid>

        <EuiSpacer size='s' />
        <EuiText color='subdued' size='xs'>
          <FormattedMessage
            id='prepaid-credit-modal.subscription-selection-message'
            defaultMessage='For detailed rates see <pricingLink>Pricing table</pricingLink>.'
            values={{
              pricingLink: (content) => (
                <Link to={pricingTableUrl()} target='blank'>
                  {content}
                </Link>
              ),
            }}
          />
        </EuiText>
      </EuiFlexGroup>
    )
  }

  renderGeneralError = (): JSX.Element => (
    <Fragment>
      <EuiSpacer size='m' />
      <EuiCallOut
        title={
          <FormattedMessage
            id='prepaid-credit-modal.general-error-title'
            defaultMessage='Something went wrong'
          />
        }
        color='danger'
        iconType='alert'
        data-test-id='generalError'
      >
        <FormattedMessage
          id='prepaid-credit-modal.general-error-message'
          defaultMessage="Try again. {support} if it still isn't working."
          values={{
            support: (
              <CuiLink to={supportUrl()} target='_blank'>
                <FormattedMessage
                  id='prepaid-credit-modal.contact-support'
                  defaultMessage='Contact support'
                />
              </CuiLink>
            ),
          }}
        />
      </EuiCallOut>
    </Fragment>
  )

  getTitle = (): JSX.Element => {
    const { isSubmitted } = this.props

    return isSubmitted ? (
      <FormattedMessage id='prepaid-credit-modal.title-submitted' defaultMessage='Credits added' />
    ) : (
      <FormattedMessage id='prepaid-credit-modal.title' defaultMessage='Add ECU credits' />
    )
  }

  getFooter = (): JSX.Element => {
    const { onClose, isLoading, isSubmitted } = this.props

    return isSubmitted ? (
      <EuiButton fill={true} onClick={onClose} data-test-id='closeButton'>
        <FormattedMessage id='prepaid-credit-modal.close' defaultMessage='Close' />
      </EuiButton>
    ) : (
      <Fragment>
        <EuiButtonEmpty onClick={onClose} disabled={isLoading} data-test-id='closeButton'>
          <FormattedMessage id='prepaid-credit-modal.cancel' defaultMessage='Cancel' />
        </EuiButtonEmpty>

        <EuiButton
          type='submit'
          form={formId}
          fill={true}
          disabled={isLoading}
          data-test-id='submitButton'
        >
          <FormattedMessage id='prepaid-credit-modal.add' defaultMessage='Add' />
        </EuiButton>
      </Fragment>
    )
  }

  openChangeSubscriptionModal = (): void => {
    this.setState({ showChangeSubscriptionModal: true })
  }

  closeChangeSubscriptionModal = (): void => {
    this.setState({ showChangeSubscriptionModal: false })
  }

  onSelectSubscription = (subscription: Subscription): void => {
    this.setState({ selectedSubscription: subscription.value as AvailableBillingSubscriptionLevel })
  }

  getDefaultLevel(): AvailableBillingSubscriptionLevel {
    const { profile, flags } = this.props

    // standard level is not available
    if (profile.level === 'standard') {
      return defaultSubscriptionLevelByUser({
        profile,
        trialDefaultTierFlag: flags?.trialDefaultTierFlag,
      }) as AvailableBillingSubscriptionLevel
    }

    return (
      profile.level ||
      defaultSubscriptionLevelByUser({
        profile,
        trialDefaultTierFlag: flags?.trialDefaultTierFlag,
      })
    )
  }
}

export default withEuiTheme(injectIntl(withLDConsumer()(PrepaidCreditActivationModal)))
