/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { css } from '@emotion/react'

import type { UseEuiTheme } from '@elastic/eui'

export const submitButtonStyle = css({
  '&:disabled': {
    backgroundColor: 'rgba(105, 112, 125, 0.2)',
  },
})

export const outlineButtonStyle = ({ euiTheme }: UseEuiTheme) =>
  css({
    backgroundColor: euiTheme.colors.emptyShade,
    border: `1px solid ${euiTheme.colors.lightShade}`,
  })
