/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'
import ProjectRoleAssignmentsEditor from '@modules/role-assignments-components/ProjectRoleAssignmentsEditor'
import usePermissions from '@modules/permissions-lib/hooks'
import { useListDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'
import { useServerlessFeatureFlag } from '@modules/launchdarkly'

import type { FunctionComponent } from 'react'

const UserProjectRoleAssignmentsEditor: FunctionComponent<{
  organizationId: string
  roleAssignments: RoleAssignments
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void
}> = ({ organizationId, roleAssignments, onChangeRoleAssignments }) => {
  const isServerlessEnabled = useServerlessFeatureFlag()

  const showDeployments = usePermissions([{ type: 'deployment', action: 'list' }]).hasPermissions

  const hasListElasticsearchPermission = usePermissions([
    { type: 'project-elasticsearch', action: 'list' },
  ]).hasPermissions
  const hasListObservabilityPermission = usePermissions([
    { type: 'project-observability', action: 'list' },
  ]).hasPermissions
  const hasListSecurityPermission = usePermissions([
    { type: 'project-security', action: 'list' },
  ]).hasPermissions

  const showElasticsearchProjects = isServerlessEnabled && hasListElasticsearchPermission
  const showObservabilityProjects = isServerlessEnabled && hasListObservabilityPermission
  const showSecurityProjects = isServerlessEnabled && hasListSecurityPermission

  const deploymentQuery = useListDeploymentsQuery({
    enabled: showDeployments,
  })
  const elasticsearchQuery = useListElasticsearchProjectsQuery({
    enabled: showElasticsearchProjects,
  })
  const observabilityQuery = useListObservabilityProjectsQuery({
    enabled: showObservabilityProjects,
  })
  const securityQuery = useListSecurityProjectsQuery({
    enabled: showSecurityProjects,
  })

  return (
    <ProjectRoleAssignmentsEditor
      organizationId={organizationId}
      roleAssignments={roleAssignments}
      deploymentQuery={{
        data: deploymentQuery.data?.deployments,
        isEnabled: showDeployments,
        isLoading: deploymentQuery.isLoading,
        error: deploymentQuery.error,
      }}
      elasticsearchQuery={{
        data: elasticsearchQuery.data?.items,
        isEnabled: showElasticsearchProjects,
        isLoading: elasticsearchQuery.isLoading,
        error: elasticsearchQuery.error,
      }}
      observabilityQuery={{
        data: observabilityQuery.data?.items,
        isEnabled: showObservabilityProjects,
        isLoading: observabilityQuery.isLoading,
        error: observabilityQuery.error,
      }}
      securityQuery={{
        data: securityQuery.data?.items,
        isEnabled: showSecurityProjects,
        isLoading: securityQuery.isLoading,
        error: securityQuery.error,
      }}
      onChangeRoleAssignments={onChangeRoleAssignments}
    />
  )
}

export default UserProjectRoleAssignmentsEditor
