/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isNumber, max } from 'lodash'

import type {
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
  DiscreteSizes,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement } from '@modules/ui-types'

import {
  castSize,
  getInstanceCount,
  getSize,
} from '../../../../../../../lib/deployments/conversion'

import type { FunctionComponent, ReactElement } from 'react'

type AutoscalingTopologyElement = AnyTopologyElement &
  Pick<
    ElasticsearchClusterTopologyElement,
    'autoscaling_max' | 'autoscaling_min' | 'size' | 'autoscaling_policy_override_json'
  >
type AutoscalingSizeFilter = (
  size: number | undefined,
  min: number | undefined,
  max: number | undefined,
) => number | undefined
interface AutoscalingSizeFilters {
  fixToMin: AutoscalingSizeFilter
  constrainToLimits: AutoscalingSizeFilter
}

export interface Props {
  topologyElement: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  maxNodeCountForEnvironment?: number
  capMaxNodeCount?: boolean
  onChange: undefined | ((path: string[], value: any) => void)
  autoscalingSizeFilter: keyof AutoscalingSizeFilters
  currentSize?: number
  children: (props: NormalizeSizingProps) => ReactElement | null
}

export interface NormalizeSizingProps {
  resource: DiscreteSizes['resource']
  sizes: number[]
  size: number
  autoscalingMinSize: number | undefined
  autoscalingMaxSize: number | undefined
  exactSize?: number
  exactInstanceCount?: number
  nodeCount: number
  nodeCountDisabled: boolean
  maxSize: number
  maxNodeCount: number
  storageMultiplier?: number
  cpuMultiplier?: number
  onChangeSize: undefined | ((value: number) => void)
  onChangeNodeCount: undefined | ((value: number) => void)
  onChangeAutoscaling:
    | undefined
    | ((value: {
        minValue?: number
        maxValue?: number
        size?: number
        policyOverride?: Record<string, any>
      }) => void)
  hasIncompatibleSize: boolean
  instanceConfiguration: InstanceConfiguration
}

// An upper limit if we need to cap the node count,
// but there isn't an entry for the environment.
const MAX_NODE_COUNT = 128

const autoscalingSizeFilters: AutoscalingSizeFilters = {
  fixToMin: (size, min) => (isNumber(min) ? min : size),
  constrainToLimits: (size, minValue, maxValue) => {
    if (isNumber(minValue) && isNumber(size) && size < minValue) {
      return minValue
    }

    if (isNumber(maxValue) && isNumber(size) && size > maxValue) {
      return maxValue
    }

    return size
  },
}

const NormalizeSizing: FunctionComponent<Props> = ({
  topologyElement,
  instanceConfiguration,
  maxNodeCountForEnvironment,
  capMaxNodeCount,
  onChange,
  autoscalingSizeFilter: filterName,
  currentSize,
  children,
}) => {
  // Sizing can be in aggregate (new style, where node count is derived from
  // memory size being a multiple of the instance configuration's max size) or
  // exact (old style, where memory per node and node count are discrete). This
  // is a layer between node configs and the simple components that don't want
  // to know about any of that.

  // extract all the variables we need
  const {
    discrete_sizes: { sizes },
    storage_multiplier: storageMultiplier,
    // @ts-ignore
    cpu_multiplier: cpuMultiplier,
  } = instanceConfiguration
  const {
    memory_per_node: exactSize,
    node_count_per_zone: exactInstanceCount,
    autoscaling_min,
    autoscaling_max,
  } = topologyElement as ElasticsearchClusterTopologyElement // some topology elements are legacy sizing props
  const size = topologyElement.size && topologyElement.size.value
  const resource = instanceConfiguration.discrete_sizes.resource || `memory`

  const usesNewSizing = !isNumber(exactSize) || !isNumber(exactInstanceCount)
  const autoscalingSizeFilter = autoscalingSizeFilters[filterName]

  const maxSize = Math.max(...sizes)
  const instanceSize = getSize({
    resource,
    size: { value: size! },
    exactSize,
    exactInstanceCount,
    instanceConfiguration,
  })
  const autoscalingMinSize =
    autoscaling_min &&
    getSize({
      resource,
      size: { value: autoscaling_min.value },
      exactSize,
      exactInstanceCount,
      instanceConfiguration,
    })
  const autoscalingMaxSize =
    autoscaling_max &&
    getSize({
      resource,
      size: { value: autoscaling_max.value },
      exactSize,
      exactInstanceCount,
      instanceConfiguration,
    })
  const nodeCount = getInstanceCount({
    size,
    sizes,
    exactInstanceCount,
  })
  const nodeCountFallback = capMaxNodeCount ? MAX_NODE_COUNT : Infinity

  const props: NormalizeSizingProps = {
    hasIncompatibleSize: hasIncompatibleSize(),
    resource,
    sizes,
    size: instanceSize,
    exactSize,
    exactInstanceCount,
    nodeCount,
    nodeCountDisabled: (size! || exactSize!) < maxSize,
    maxSize,
    autoscalingMinSize,
    autoscalingMaxSize,
    maxNodeCount: maxNodeCountForEnvironment || nodeCountFallback,
    storageMultiplier,
    cpuMultiplier,
    instanceConfiguration,
    onChangeSize:
      onChange &&
      ((value: number) => {
        // clean up old sizing if present
        if (!usesNewSizing) {
          onChange([`memory_per_node`], undefined)
          onChange([`node_count_per_zone`], undefined)
        }

        onChange([`size`], { value, resource })
      }),
    onChangeNodeCount:
      onChange &&
      ((value: number) => {
        // clean up old sizing if present
        if (!usesNewSizing) {
          onChange([`memory_per_node`], undefined)
          onChange([`node_count_per_zone`], undefined)
        }

        onChange([`size`], { value: maxSize * value, resource })
      }),
    onChangeAutoscaling:
      onChange &&
      (({ minValue, maxValue, size: newSize, policyOverride }) => {
        const autoscalingTopologyElement = topologyElement as AutoscalingTopologyElement
        const newTopologyElement = { ...autoscalingTopologyElement }

        if (isNumber(minValue)) {
          newTopologyElement.autoscaling_min = { value: minValue, resource }
        }

        if (isNumber(maxValue)) {
          newTopologyElement.autoscaling_max = { value: maxValue, resource }
        }

        if (policyOverride) {
          newTopologyElement.autoscaling_policy_override_json = policyOverride
        }

        const filteredSize =
          newSize ||
          autoscalingSizeFilter(
            currentSize,
            newTopologyElement.autoscaling_min?.value,
            newTopologyElement.autoscaling_max?.value,
          )

        if (isNumber(filteredSize)) {
          newTopologyElement.size = { value: filteredSize, resource }
        }

        // we may be changing multiple fields -- so replace the whole element,
        // because onChange is atomic and successive calls will be lost
        onChange([], newTopologyElement)
      }),
  }

  return children(props)

  function hasIncompatibleSize(): boolean {
    if (usesNewSizing) {
      return false
    }

    const memorySizes = sizes.map((value) =>
      castSize({
        size: value,
        from: resource,
        to: `memory`,
        storageMultiplier,
      }),
    )

    const maxMemorySize = max(memorySizes)

    if (exactSize === maxMemorySize) {
      return false
    }

    if (exactInstanceCount === 1 && memorySizes.includes(exactSize!)) {
      return false
    }

    return true
  }
}

export default NormalizeSizing
