/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { pki } from 'node-forge'

import type { CertificateExpiryPeriod, X509CertificateInfo } from './types'

const PEM_PREFIX = '-----BEGIN CERTIFICATE-----'
const PEM_POSTFIX = '-----END CERTIFICATE-----'

export const isCertificatePemFormat = (cert: string) =>
  cert.startsWith(PEM_PREFIX) && cert.trimEnd().endsWith(PEM_POSTFIX)

export const getPemFormatCertificate = (cert: string | undefined): string => {
  if (!cert) {
    return ''
  }

  if (isCertificatePemFormat(cert)) {
    return cert
  }

  const base64DecodedCert = Buffer.from(cert, 'base64').toString('utf-8')

  if (isCertificatePemFormat(base64DecodedCert)) {
    return base64DecodedCert
  }

  // cert is base64-encoded DER format
  return `${PEM_PREFIX}\n${cert}\n${PEM_POSTFIX}`
}

export const getX509CertificateInfo = (
  cert: string | undefined,
): X509CertificateInfo | undefined => {
  try {
    const pemCert = getPemFormatCertificate(cert || '')
    const x509 = pki.certificateFromPem(pemCert)
    return {
      validFrom: new Date(x509.validity.notBefore),
      validTo: new Date(x509.validity.notAfter),
    }
  } catch (e) {
    return undefined // return undefined for invalid certificates
  }
}

export const isCertificateValid = (cert: string | undefined): boolean => {
  const x509Cert = getX509CertificateInfo(cert)

  if (!x509Cert) {
    return false
  }

  const remainingDaysToCertExpiry = getRemainingDaysToExpiry(x509Cert)

  if (!remainingDaysToCertExpiry || remainingDaysToCertExpiry <= 0) {
    return false
  }

  return true
}

export const DAY_IN_MS = 1000 * 60 * 60 * 24

export const getRemainingDaysToExpiry = (
  certificateExpiryPeriod: CertificateExpiryPeriod,
): number | null | undefined => {
  const { validTo, validFrom } = certificateExpiryPeriod

  const now = new Date()
  const isNotValidYet = now.getTime() - validFrom.getTime() < 0

  if (isNotValidYet) {
    return null
  }

  const remainingTime = validTo.getTime() - now.getTime()

  return Math.ceil(remainingTime / DAY_IN_MS)
}
