/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { debounce } from 'lodash'

import type { EuiBasicTableColumn, Search } from '@elastic/eui'
import { EuiInMemoryTable, EuiSkeletonText, EuiText } from '@elastic/eui'

import type {
  RoleAssignmentsTableItem,
  ResourceRoleAssignment,
  ResourceType,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import { useConfig } from '@modules/cui/ConfigContext'
import { UserRoleSelector } from '@modules/role-assignments-user-components/ProjectRoleAssignmentsEditor/UserRoleSelector'
import { AdminRoleSelector } from '@modules/role-assignments-admin-components/ProjectRoleAssignmentsEditor/AdminRoleSelector'

type Props<T extends ResourceType, R extends ResourceRoleAssignment<T>> = {
  resourceType: T
  items: Array<RoleAssignmentsTableItem<T>>
  filteredItems: Array<RoleAssignmentsTableItem<T>>
  isLoading: boolean
  customRolesEnabled: boolean
  onSearch: (query: string) => void
  tableItemsToRoleAssignment: (items: Array<RoleAssignmentsTableItem<T>>) => R[]
  onChangeRoleAssignments: (roleAssignments: Array<ResourceRoleAssignment<T>>) => void
}

const debounced = debounce((cb) => cb(), 300)

const ProjectRoleAssignmentsTable = <T extends ResourceType, R extends ResourceRoleAssignment<T>>({
  resourceType,
  items,
  filteredItems,
  isLoading,
  customRolesEnabled,
  onSearch,
  tableItemsToRoleAssignment,
  onChangeRoleAssignments,
}: Props<T, R>) => {
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  if (isLoading) {
    return <EuiSkeletonText />
  }

  const search: Search = {
    onChange: ({ query }) => debounced(() => onSearch(query?.text ?? '')),
    box: {
      incremental: true,
    },
  }

  return (
    <EuiInMemoryTable
      items={filteredItems}
      columns={getColumns(
        resourceType,
        items,
        tableItemsToRoleAssignment,
        onChangeRoleAssignments,
        customRolesEnabled,
        isAdminConsole,
      )}
      pagination={true}
      search={search}
      data-test-subj='individualRoleAssignmentsTable'
    />
  )
}

const getColumns = <T extends ResourceType, R extends ResourceRoleAssignment<T>>(
  resourceType: T,
  items: Array<RoleAssignmentsTableItem<T>>,
  tableItemsToRoleAssignment: (items: Array<RoleAssignmentsTableItem<T>>) => R[],
  onChangeRoleAssignments: (roleAssignments: Array<ResourceRoleAssignment<T>>) => void,
  customRolesEnabled: boolean,
  isAdminConsole: boolean,
): Array<EuiBasicTableColumn<RoleAssignmentsTableItem<T>>> => {
  const singleSelection = resourceType === 'deployment' || !customRolesEnabled
  return [
    {
      name: <FormattedMessage id='roles-assignments.name' defaultMessage='Name' />,
      field: 'name',
      sortable: ({ name }) => name.toLowerCase(),
      render: (name: string) => <EuiText size='s'>{name}</EuiText>,
      truncateText: true,
      width: '50%',
    },
    {
      name: (
        <FormattedMessage
          id={'roles-assignments.role'}
          defaultMessage={'{count, plural, one {Role} other {Roles}}'}
          values={{
            count: singleSelection ? 1 : 2,
          }}
        />
      ),
      field: 'roles',
      render: (roles: TableItemRoleDescriptor[], { id, roleManagementUrl, isDisabled }) =>
        isAdminConsole ? (
          <AdminRoleSelector
            resourceType={resourceType}
            items={items}
            tableItemsToRoleAssignment={tableItemsToRoleAssignment}
            onChangeRoleAssignments={onChangeRoleAssignments}
            roles={roles}
            id={id}
            roleManagementUrl={roleManagementUrl}
            isDisabled={isDisabled}
          />
        ) : (
          <UserRoleSelector
            resourceType={resourceType}
            items={items}
            tableItemsToRoleAssignment={tableItemsToRoleAssignment}
            onChangeRoleAssignments={onChangeRoleAssignments}
            roles={roles}
            id={id}
            roleManagementUrl={roleManagementUrl}
            isDisabled={isDisabled}
            customRolesEnabled={customRolesEnabled}
          />
        ),
      width: '50%',
    },
  ]
}

export default ProjectRoleAssignmentsTable
