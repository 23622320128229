/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { EuiText, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import { getProviderInfoById } from '@modules/project-lib/providers'
import type { CSP, Region } from '@modules/cluster-user-api/v1/types'

import type { FunctionComponent } from 'react'

type Props = {
  region: string
  regions: Region[] | []
  provider: CSP
}

const AdvancedSettings: FunctionComponent<Props> = ({ region, provider, regions }) => {
  const providerInfo = getProviderInfoById(provider)

  if (!regions) {
    return null
  }

  const selectedRegion = regions.find(({ id }) => id === region)

  return (
    <EuiFlexGroup
      gutterSize='s'
      responsive={false}
      alignItems='center'
      justifyContent='center'
      css={css({ marginRight: 10 })}
    >
      <EuiFlexItem style={{ justifyContent: 'center' }} grow={false}>
        <EuiIcon type={providerInfo.iconType} aria-label={providerInfo.title} size='xxl' />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText size='s'>{selectedRegion?.name || ''}</EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default AdvancedSettings
