/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { parse } from 'query-string'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import {
  activateMarketplaceUserRequest,
  createOrganizationRequest,
  subscribeMarketplaceUserRequest,
} from '@/reducers/asyncRequests/registry'
import { createOrganization } from '@/actions/organizations'

import { activateMarketplaceUser, subscribeMarketplaceUser } from '../../../../../actions/saasUsers'

import MarketplaceConversion from './MarketplaceConversion'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { profile } = state
  const query = parse(location.hash.slice(1))

  return {
    token: typeof query.token === 'string' ? query.token : undefined,
    partner: typeof query.partner === 'string' ? query.partner : undefined,
    agreementId: typeof query.agreementId === 'string' ? query.agreementId : undefined,
    productId: typeof query.productId === 'string' ? query.productId : undefined,
    profile,
    hasSubscription: Boolean(query.hasSubscription),
    subscribeMarketplaceUserRequest: subscribeMarketplaceUserRequest(state),
    activateMarketplaceUserRequest: activateMarketplaceUserRequest(state),
    createOrganizationRequest: createOrganizationRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  subscribeMarketplaceUser: ({ token, partner, profile }) =>
    dispatch(subscribeMarketplaceUser({ token, partner, profile })),
  activateMarketplaceUser: (payload) => dispatch(activateMarketplaceUser(payload)),
  createOrganization: () => dispatch(createOrganization({})),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(MarketplaceConversion),
)
