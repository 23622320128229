/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import withBillingDetails from '@modules/billing-lib/billingDetails/withBillingDetails'
import withPermissions from '@modules/permissions-components/PermissionsGate/withPermissions'

import { getProfile } from '@/apps/userconsole/reducers/profile'
import { shouldShowOrganization } from '@/components/Organization/lib'

import Billing from './Billing'

import type { StateProps, ConsumerProps } from './types'
import type { ReduxState } from '@/types/redux'

const mapStateToProps = (state: ReduxState): StateProps => {
  const profile = getProfile(state)

  return {
    profile,
    organizationId: profile?.organization_id,
    showOrganization: shouldShowOrganization(profile),
  }
}

export default withRouter(
  connect<StateProps, null, ConsumerProps>(mapStateToProps)(
    withPermissions({
      hasBillingPermissions: {
        permissionsToCheck: [{ type: 'billing-organization', action: 'create' }],
      },
    })(withBillingDetails(Billing)),
  ),
)
