/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import cx from 'classnames'
import { omit } from 'lodash'

import type { EuiFieldPasswordProps } from '@elastic/eui'
import { EuiFieldPassword, EuiFormRow, EuiInputPopover } from '@elastic/eui'

import PasswordStrengthIndicator, { validatePassword } from './PasswordStrengthIndicator'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement } from 'react'

import './passwordField.scss'

interface Props extends WrappedComponentProps, Omit<EuiFieldPasswordProps, 'onChange'> {
  fullWidth?: boolean
  hasFocus?: boolean
  hasStrengthIndicator?: boolean
  hideVisibilityToggle?: boolean
  hidePlaceholder?: boolean
  placeholder?: string
  isInvalid?: boolean
  error?: React.ReactChild
  label: React.ReactNode
  name: string
  required?: boolean
  disabled?: boolean
  isMarketplace?: boolean
  onChange: (input: HTMLInputElement, { isValidPassword }: { isValidPassword: boolean }) => void
}

interface State {
  clientError: ReactElement | null
  password: string
  isPopoverOpen: boolean
}

const messages = defineMessages({
  showPassword: {
    id: 'user-settings-profile.showPassword',
    defaultMessage: 'Show',
  },
  hidePassword: {
    id: 'user-settings-profile.hidePassword',
    defaultMessage: 'Hide',
  },
  defaultPlaceholder: {
    id: 'user-settings-profile.placeholderPassword',
    defaultMessage: 'Password',
  },
})

class PasswordField extends PureComponent<Props, State> {
  input: HTMLInputElement | null = null

  state = {
    clientError: null,
    password: '',
    isPopoverOpen: false,
  }

  componentDidMount() {
    const { hasFocus } = this.props

    if (this.input !== null && hasFocus) {
      this.input.focus()
    }
  }

  render() {
    const { hasStrengthIndicator } = this.props

    if (hasStrengthIndicator) {
      return this.renderStrengthIndicatorEnabledInput()
    }

    return this.renderInputField()
  }

  renderStrengthIndicatorEnabledInput() {
    const { fullWidth, isMarketplace } = this.props
    const { password, isPopoverOpen } = this.state

    return (
      <EuiInputPopover
        attachToAnchor={false}
        panelClassName={cx('password-field-popover', { 'wide-password-panel': isMarketplace })}
        isOpen={isPopoverOpen}
        closePopover={this.closePopover}
        panelPaddingSize='m'
        input={this.renderInputField()}
        disableFocusTrap={true}
        fullWidth={fullWidth}
      >
        <PasswordStrengthIndicator password={password} />
      </EuiInputPopover>
    )
  }

  renderInputField() {
    const {
      label,
      name,
      required,
      error,
      isInvalid,
      fullWidth,
      hidePlaceholder,
      placeholder,
      disabled,
      intl: { formatMessage },
      hideVisibilityToggle,
      ...rest
    } = this.props
    const { clientError } = this.state

    const placeholderText = placeholder || formatMessage(messages.defaultPlaceholder)

    return (
      <EuiFormRow
        label={label}
        className='password-field-component'
        isInvalid={isInvalid || !!clientError}
        error={error || clientError}
        fullWidth={fullWidth}
      >
        <EuiFieldPassword
          data-test-id='password-field'
          isInvalid={isInvalid || !!clientError}
          name={name}
          type={hideVisibilityToggle ? 'password' : 'dual'}
          onChange={this.onChangeInputField}
          onFocus={this.onFocusInputField}
          onBlur={this.onBlurInputField}
          className='password-field-input'
          required={required}
          inputRef={(el) => {
            this.input = el
          }}
          fullWidth={fullWidth}
          placeholder={hidePlaceholder ? undefined : placeholderText}
          disabled={disabled}
          {...omit(rest, ['onChange', 'isMarketplace', 'hasStrengthIndicator'])}
        />
      </EuiFormRow>
    )
  }

  onChangeInputField = (e) => {
    const input = e.target
    const password = input.value
    const isValidPassword = validatePassword(password)
    const clientError = isValidPassword ? null : this.state.clientError
    this.setState({ password, isPopoverOpen: !isValidPassword, clientError }, () => {
      this.props.onChange(input, { isValidPassword })
    })
  }

  onBlurInputField = () => {
    const { hasStrengthIndicator } = this.props
    const { password } = this.state
    const isValidPassword = validatePassword(password)

    this.closePopover()

    if (password && hasStrengthIndicator) {
      this.setState({
        clientError: isValidPassword ? null : (
          <FormattedMessage
            id='password-field.invalid-password'
            defaultMessage='Minimum password requirements not met'
          />
        ),
      })
      return
    }

    this.setState({ clientError: null })
  }

  onFocusInputField = () => {
    this.setState({ clientError: null }, () => {
      this.openPopover()
    })
  }

  openPopover = () => {
    const { password } = this.state
    const isValidPassword = validatePassword(password)
    this.setState({ isPopoverOpen: !isValidPassword })
  }

  closePopover = () => {
    this.setState({ isPopoverOpen: false })
  }
}

export default injectIntl(PasswordField)
