/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
export function loginUrl(): string {
  return `/login`
}

export function basicLoginUrl(): string {
  return `/login/basic`
}

export function ssoLoginUrl(): string {
  return `/login/sso`
}

export function logoutUrl(): string {
  return `/logout`
}

export function registrationUrl(): string {
  return `/registration`
}

export function serverlessRegistrationUrl(): string {
  return `/serverless-registration`
}

export function acceptInviteUrl(): string {
  return `/account/accept-invite`
}

export function resetPasswordUrl(): string {
  return `/account/reset-password`
}

export function forgotPasswordUrl(): string {
  return `/forgot`
}
