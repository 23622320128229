/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { capitalize } from 'lodash'
import { useCallback, useEffect, useState, Fragment } from 'react'
import { FormattedMessage, useIntl, type MessageDescriptor } from 'react-intl'

import type { EuiFieldTextProps, EuiSuperSelectOption } from '@elastic/eui'
import {
  EuiAccordion,
  EuiButton,
  EuiCallOut,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormFieldset,
  EuiFormLabel,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiSplitPanel,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { Region } from '@modules/cluster-user-api/v1/types'
import { getThemeColors, getThemeVariables } from '@modules/cui/theme'
import {
  useBoundProviderAndRegionState,
  useCreateProject,
} from '@modules/project-creation-lib/hooks'
import { isProjectType } from '@modules/project-creation-lib/types'
import { pricingLinkBuilder, projectTypeStartingUrl } from '@modules/project-creation-lib/urls'
import type { ElasticsearchOptimizedFor } from '@modules/project-user-api/v1/types'
import type { ProjectType } from '@modules/ui-types/projects'
import history from '@modules/utils/history'
import genericMessages from '@modules/project-lib/genericMessages'
import ProjectApiErrorCallout from '@modules/project-components/ProjectApiErrorCallout'
import { useSaasUser } from '@modules/profile-lib/hooks'
import type { NonEmptyArray } from '@modules/ts-utils'

import AdvancedSettings from './AdvancedSettings'
import ProjectCreationContainer from './ProjectCreationContainer'
import ProviderSelector from './ProviderSelector'
import SelectSubType from './SelectSubType'

import type { FunctionComponent } from 'react'

type Props = {
  solution: {
    label: ProjectType
    title: MessageDescriptor
    description: MessageDescriptor
  }
  regions: NonEmptyArray<Region>
}

const ProjectCreation: FunctionComponent<Props> = ({
  regions,
  solution: { label, title, description },
}) => {
  const { formatMessage } = useIntl()

  const [isSettingsOpen, setSettingsOpen] = useState(false)

  const [projectName, setProjectNameValue] = useState<string>('')

  const [defaultProjectName, setDefaultProjectName] = useState<string>('')

  const [optimizedFor, setOptimizedFor] = useState<ElasticsearchOptimizedFor>('general_purpose')

  const {
    selectedRegion,
    selectedProvider,
    providers,
    providerRegions,
    setSelectedProvider,
    setSelectedRegion,
  } = useBoundProviderAndRegionState(regions)

  const regionOptions: Array<EuiSuperSelectOption<Region>> = providerRegions.map((region) => ({
    value: region,
    inputDisplay: region.name,
  }))

  const onProjectNameChange: EuiFieldTextProps['onChange'] = useCallback((event) => {
    setProjectNameValue(event.target.value)
  }, [])

  useEffect(() => {
    setDefaultProjectName(
      formatMessage(genericMessages.defaultProjectName, {
        label: capitalize(label),
      }),
    )
  }, [formatMessage, label])

  const { createProject, isLoading, isError, projectError, organizationError } = useCreateProject()

  const saasUser = useSaasUser()
  const isTrial = saasUser?.subscription.is_trial

  if (!isProjectType(label)) {
    return null
  }

  const {
    euiBreakpoints: { m: euiBreakpointM },
  } = getThemeVariables()

  const { euiColorPrimaryText } = getThemeColors()

  const buttonContent = () => {
    const message = isSettingsOpen ? (
      <FormattedMessage id='accordion-button.collapse' defaultMessage='Hide' />
    ) : (
      <FormattedMessage id='accordion-button.expand' defaultMessage='Edit settings' />
    )

    return (
      <EuiText size='xs' css={css({ color: euiColorPrimaryText })}>
        {message}
      </EuiText>
    )
  }

  const extraAction = () =>
    !isSettingsOpen && (
      <AdvancedSettings region={selectedRegion.id} provider={selectedProvider} regions={regions} />
    )

  return (
    <ProjectCreationContainer
      title={
        <Fragment>
          <EuiTitle size='m'>
            <h1>
              <FormattedMessage
                id='create-project-component.fullymanaged-supertitle'
                defaultMessage='Confirm your project settings'
              />
            </h1>
          </EuiTitle>
          <EuiSpacer size='xs' />
        </Fragment>
      }
      step={2}
    >
      <EuiFlexGroup direction='column' alignItems='center'>
        <EuiFlexItem>
          <EuiFlexGroup direction='column' alignItems='center' gutterSize='s'>
            <EuiFlexItem>
              <EuiText color='subdued'>
                <p>
                  <FormattedMessage {...description} />
                </p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiSplitPanel.Outer
            css={css`
              min-width: 768px;
              max-width: 960px;

              @media only screen and (max-width: ${euiBreakpointM}) {
                width: 100%;
              }
            `}
          >
            <EuiSplitPanel.Inner paddingSize='xl'>
              <EuiFlexGroup alignItems='center' gutterSize='s'>
                <EuiIcon type={`logo${capitalize(label)}`} size='xl' />
                <EuiTitle data-test-subj={`project-creation-title-${label}`} size='s'>
                  <h2>
                    <FormattedMessage {...title} />
                  </h2>
                </EuiTitle>
              </EuiFlexGroup>
              <EuiHorizontalRule />
              <EuiFlexItem>
                <EuiFormRow
                  label={
                    <FormattedMessage id='create-project-component.name' defaultMessage='Name' />
                  }
                >
                  <EuiFieldText
                    value={projectName}
                    placeholder={defaultProjectName}
                    onChange={onProjectNameChange}
                    fullWidth={true}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              {label === 'elasticsearch' && (
                <Fragment>
                  <EuiSpacer size='l' />
                  <SelectSubType value={optimizedFor} onChange={setOptimizedFor} />
                </Fragment>
              )}

              <EuiSpacer size='l' />
              <EuiFormFieldset
                legend={{
                  children: (
                    <FormattedMessage
                      id='create-project-component.settings'
                      defaultMessage='Settings'
                    />
                  ),
                }}
              >
                <EuiAccordion
                  css={css`
                    .euiAccordion__triggerWrapper {
                      flex-direction: row-reverse;
                    }
                  `}
                  id='advancedSettings'
                  buttonContent={buttonContent()}
                  arrowDisplay='none'
                  initialIsOpen={false}
                  extraAction={extraAction()}
                  onToggle={() => setSettingsOpen(!isSettingsOpen)}
                >
                  <EuiSpacer size='s' />
                  <EuiFlexItem>
                    <ProviderSelector
                      value={selectedProvider}
                      availableProviders={providers}
                      onChange={setSelectedProvider}
                    />

                    <EuiSpacer size='m' />

                    <EuiSuperSelect
                      fullWidth={true}
                      prepend={
                        <EuiFormLabel style={{ width: '150px' }}>
                          <FormattedMessage id='project-creation.region' defaultMessage='Region' />
                        </EuiFormLabel>
                      }
                      valueOfSelected={selectedRegion}
                      options={regionOptions}
                      onChange={setSelectedRegion}
                    />
                    <EuiSpacer size='m' />
                  </EuiFlexItem>
                </EuiAccordion>
              </EuiFormFieldset>
            </EuiSplitPanel.Inner>
            <EuiPanel paddingSize='xl'>
              <EuiFlexGroup justifyContent='flexEnd' alignItems='center'>
                <EuiFlexItem>
                  <EuiText size='s' color='subdued'>
                    {label === 'elasticsearch' ? (
                      <Fragment>
                        <FormattedMessage
                          id='project-creation.create-es-project-pricing'
                          defaultMessage='Pay for the performance you need. {link}.'
                          values={{
                            link: (
                              <EuiLink
                                href={pricingLinkBuilder(label)}
                                rel='noopener noreferrer'
                                target='_blank'
                              >
                                <FormattedMessage
                                  id='project-creation.create-project-pricing-learnmore'
                                  defaultMessage='See pricing details'
                                />
                              </EuiLink>
                            ),
                          }}
                        />
                        {isTrial && (
                          <Fragment>
                            <br />
                            <FormattedMessage
                              id='project-creation.create-es-project-trial-pricing'
                              defaultMessage='Free during the <strong>trial period</strong>.'
                              values={{
                                strong: (content) => <strong>{content}</strong>,
                              }}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <FormattedMessage
                        id='project-creation.create-project-pricing'
                        defaultMessage='Pricing is based on how you consume {title}. {link}'
                        values={{
                          title: <FormattedMessage {...title} />,
                          link: (
                            <EuiLink
                              href={pricingLinkBuilder(label)}
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              <FormattedMessage
                                id='project-creation.create-project-pricing-learnmore'
                                defaultMessage='See pricing details'
                              />
                            </EuiLink>
                          ),
                        }}
                      />
                    )}
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill={true}
                    onClick={handleCreateProject}
                    isLoading={isLoading}
                    data-test-subj='create-project-button'
                  >
                    <FormattedMessage
                      id='project-creation.create-project'
                      defaultMessage='Create project'
                    />
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
              {isError && (
                <EuiFlexItem>
                  <EuiSpacer size='m' />
                  {renderErrors()}
                </EuiFlexItem>
              )}
            </EuiPanel>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
      </EuiFlexGroup>
    </ProjectCreationContainer>
  )

  function renderErrors() {
    if (organizationError) {
      return (
        <EuiCallOut
          title={
            <FormattedMessage
              id='alert.something-went-wrong'
              defaultMessage='Something went wrong'
            />
          }
          color='danger'
        >
          {organizationError.message}
        </EuiCallOut>
      )
    }

    if (projectError) {
      if (typeof projectError === 'string') {
        return <ProjectApiErrorCallout error={projectError} />
      }

      return (
        <ProjectApiErrorCallout
          error={projectError}
          children={(error) => {
            if (error?.code === 'projects.create_project.max_trial_projects.forbidden') {
              return formatMessage({
                id: 'project-creation.too-many-projects',
                defaultMessage: 'Your organization has reached the current project limit.',
              })
            }

            return error?.message
          }}
        />
      )
    }

    return null
  }

  function handleCreateProject() {
    createProject({
      projectName: projectName || defaultProjectName,
      region: selectedRegion.id,
      label,
      optimizedFor,
      onSuccess: (data) => history.push(projectTypeStartingUrl(data.type, data.id)),
    })
  }
}

export default ProjectCreation
