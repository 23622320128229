/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { difference, differenceWith, groupBy, isEqual, mapValues, omit } from 'lodash'

import type { ProjectRoleAssignments } from '@modules/cloud-api/v1/types'
import type { Dictionary } from '@modules/ts-essentials'
import type { ProjectType } from '@modules/ui-types/projects'

import { filterRoleAssignments, normaliseProjectRoleAssignments } from '.'

import type {
  ResourceRoleAssignment,
  ResouceRoleAssignmentsDiff,
  NormalisedResourceRoleAssignment,
} from './types'

const diffProjectRoleAssignments = (
  original: ProjectRoleAssignments | undefined,
  changed: ProjectRoleAssignments | undefined,
): ResouceRoleAssignmentsDiff => {
  const { added: addedElasticsearch, removed: removedElasticsearch } = diff(
    normaliseProjectRoleAssignments('elasticsearch')(original?.elasticsearch),
    normaliseProjectRoleAssignments('elasticsearch')(changed?.elasticsearch),
  )

  const { added: addedObservability, removed: removedObservability } = diff(
    normaliseProjectRoleAssignments('observability')(original?.observability),
    normaliseProjectRoleAssignments('observability')(changed?.observability),
  )

  const { added: addedSecurity, removed: removedSecurity } = diff(
    normaliseProjectRoleAssignments('security')(original?.security),
    normaliseProjectRoleAssignments('security')(changed?.security),
  )

  return {
    added: {
      elasticsearch: toProjectRoleAssignments(addedElasticsearch),
      observability: toProjectRoleAssignments(addedObservability),
      security: toProjectRoleAssignments(addedSecurity),
    },
    removed: {
      elasticsearch: toProjectRoleAssignments(removedElasticsearch),
      observability: toProjectRoleAssignments(removedObservability),
      security: toProjectRoleAssignments(removedSecurity),
    },
  }

  function diff<T extends ProjectType>(
    originalRoleAssignments: Array<NormalisedResourceRoleAssignment<T>> = [],
    changedRoleAssignments: Array<NormalisedResourceRoleAssignment<T>> = [],
  ): {
    added: Array<NormalisedResourceRoleAssignment<T>>
    removed: Array<NormalisedResourceRoleAssignment<T>>
  } {
    const allRoleAssignments = diffAllRoleAssignments()
    const specificRoleAssignments = diffPredefinedAssignments()
    const applicationRoles = diffCustomAssignments()

    return {
      added: [
        ...allRoleAssignments.added,
        ...specificRoleAssignments.added,
        ...applicationRoles.added,
      ],

      removed: [
        ...allRoleAssignments.removed,
        ...specificRoleAssignments.removed,
        ...applicationRoles.removed,
      ],
    }

    function diffCustomAssignments(): {
      added: Array<NormalisedResourceRoleAssignment<T>>
      removed: Array<NormalisedResourceRoleAssignment<T>>
    } {
      const originalApplicationRoles = originalRoleAssignments.filter(
        (item) => item.application_roles?.length,
      )

      const changedApplicationRoles = changedRoleAssignments.filter(
        (item) => item.application_roles?.length,
      )

      return {
        added: differenceWith(changedApplicationRoles, originalApplicationRoles, isEqual),
        removed: differenceWith(originalApplicationRoles, changedApplicationRoles, isEqual),
      }
    }

    function diffAllRoleAssignments(): {
      added: Array<NormalisedResourceRoleAssignment<T>>
      removed: Array<NormalisedResourceRoleAssignment<T>>
    } {
      const originalAllRoleAssignments = filterRoleAssignments(
        originalRoleAssignments,
        'allRoleAssignments',
      )

      const changedAllRoleAssignments = filterRoleAssignments(
        changedRoleAssignments,
        'allRoleAssignments',
      )

      return {
        added: differenceWith(changedAllRoleAssignments, originalAllRoleAssignments, isEqual),
        removed: differenceWith(originalAllRoleAssignments, changedAllRoleAssignments, isEqual),
      }
    }

    function diffPredefinedAssignments(): {
      added: Array<NormalisedResourceRoleAssignment<T>>
      removed: Array<NormalisedResourceRoleAssignment<T>>
    } {
      const originalSpecificRoleAssignments = filterRoleAssignments(
        originalRoleAssignments,
        'specificPredefinedRoleAssignments',
      )

      const originalIds = getIdsByRoleId(originalSpecificRoleAssignments)

      const changedSpecificRoleAssignments = filterRoleAssignments(
        changedRoleAssignments,
        'specificPredefinedRoleAssignments',
      )

      const changedIds = getIdsByRoleId(changedSpecificRoleAssignments)

      const added = diffIds(changedSpecificRoleAssignments, originalIds).filter(
        ({ ids = [] }) => ids.length > 0,
      )

      const removed = diffIds(originalSpecificRoleAssignments, changedIds).filter(
        ({ ids = [] }) => ids.length > 0,
      )

      return { added, removed }

      function getIdsByRoleId(
        roleAssignments: Array<NormalisedResourceRoleAssignment<T>>,
      ): Record<string, string[]> {
        const roleAssignmentsGroupedByRoleId = groupBy(roleAssignments, 'role_id')

        return mapValues(roleAssignmentsGroupedByRoleId, (groupedRoleAssignments) =>
          groupedRoleAssignments.flatMap(({ ids }) => ids ?? []),
        )
      }

      function diffIds(
        roleAssignments: Array<NormalisedResourceRoleAssignment<T>>,
        idsByRoleId: Dictionary<string[]>,
      ): Array<NormalisedResourceRoleAssignment<T>> {
        return roleAssignments.map(
          (roleAssignment): NormalisedResourceRoleAssignment<T> => ({
            ...roleAssignment,
            ids: difference(roleAssignment.ids, idsByRoleId[roleAssignment.role_id] ?? []),
          }),
        )
      }
    }
  }
}

const toProjectRoleAssignments = (
  roleAssignments: Array<NormalisedResourceRoleAssignment<ProjectType>>,
): Array<ResourceRoleAssignment<ProjectType>> =>
  roleAssignments.map((roleAssignment) => {
    if (roleAssignment.all) {
      return roleAssignment
    }

    const convertedRoleAssigment = {
      ...roleAssignment,
      project_ids: roleAssignment.ids,
    }

    return omit(convertedRoleAssigment, 'ids')
  })

export default diffProjectRoleAssignments
