/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiHealth } from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import { CuiHelpTipIcon } from '@modules/cui/HelpTipIcon'
import type { CuiTableColumn } from '@modules/cui/Table'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'

import {
  buildCapacityItemsV2,
  buildDataTransferItems,
  buildStorageItems,
  buildSyntheticsItems,
} from './utils'

import type { ItemsCostsV2, DimensionData } from './types'
import type { FunctionComponent } from 'react'

interface Props {
  itemsCosts: ItemsCostsV2
}

const InstanceUsageTable: FunctionComponent<Props> = ({ itemsCosts }) => {
  const columns = buildColumns()

  function buildColumns(): Array<CuiTableColumn<DimensionData>> {
    return [
      {
        key: 'usage-type',
        label: (
          <FormattedMessage id='billing-deployment-usage.usage-type' defaultMessage='Usage type' />
        ),
        render: ({ dimension, color }) =>
          dimension ? <EuiHealth color={color}>{dimension}</EuiHealth> : '',
        width: '150px',
        footer: {
          render: () => (
            <span>
              <FormattedMessage id='billing-deployment-usage.total' defaultMessage='Total usage' />
              <CuiHelpTipIcon iconType='iInCircle'>
                <FormattedMessage
                  id='billing-deployment-usage.tip-icon'
                  defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
                />
              </CuiHelpTipIcon>
            </span>
          ),
        },
      },
      {
        key: 'components',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-component'
            defaultMessage='Components'
          />
        ),
        render: ({ component }) => component,
        footer: { render: () => null },
        header: {
          className: 'components-header',
        },
      },
      {
        key: 'size',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-size'
            defaultMessage='Latest size (zones x storage | RAM | vCPU)'
          />
        ),
        render: ({ size }) => size,
        footer: { render: () => null },
        header: {
          className: 'size-header',
        },
      },
      {
        key: 'quantity',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-quantity'
            defaultMessage='Quantity'
          />
        ),
        render: ({ quantity }) => quantity,
        width: '150px',
        footer: { render: () => null },
      },
      {
        key: 'hourly-rate',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-rate'
            defaultMessage='Price (in ECU)'
          />
        ),
        render: ({ rate }) => rate,
        footer: { render: () => null },
        width: '180px',
      },
      {
        key: 'total',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-total'
            defaultMessage='Total (in ECU)'
          />
        ),
        render: ({ total }) => (
          <CuiElasticConsumptionUnits
            value={total as number}
            unit='none'
            dp={4}
            withSymbol={false}
          />
        ),
        width: '120px',
        footer: {
          render: () => (
            <CuiElasticConsumptionUnits
              value={itemsCosts.costs.total || 0}
              unit='none'
              dp={4}
              withSymbol={true}
              symbolPosition='before'
            />
          ),
        },
        align: 'right',
      },
    ]
  }

  return (
    <CuiTable
      className='billing-deployment-usage-table'
      responsive={false}
      hasFooterRow={true}
      rows={[
        ...buildCapacityItemsV2(itemsCosts),
        ...buildDataTransferItems(itemsCosts),
        ...buildStorageItems(itemsCosts),
        ...buildSyntheticsItems(itemsCosts),
      ]}
      columns={columns}
    />
  )
}

export default InstanceUsageTable
