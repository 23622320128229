/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-admin-lib/hooks/list'
import { useSearchOrganizationDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'
import type { ResourceQueryResult } from '@modules/role-assignments-lib/types'

import type { UseQueryOptions } from 'react-query'

export const useGetOrganizationDeploymentsAndProjectsQuery = (
  organizationId: string,
  options?: Pick<UseQueryOptions, 'enabled'>,
): ResourceQueryResult[] => {
  const enabled = options?.enabled
  const deploymentQuery = useSearchOrganizationDeploymentsQuery(organizationId, { enabled })
  const elasticsearchQuery = useListElasticsearchProjectsQuery(
    { organization_id: organizationId },
    { enabled },
  )
  const observabilityQuery = useListObservabilityProjectsQuery(
    { organization_id: organizationId },
    { enabled },
  )
  const securityQuery = useListSecurityProjectsQuery(
    { organization_id: organizationId },
    { enabled },
  )
  const isEnabled = enabled ?? true
  const queryResults: ResourceQueryResult[] = [
    {
      resourceType: 'deployment',
      data: deploymentQuery.data?.deployments,
      isLoading: deploymentQuery.isLoading,
      error: deploymentQuery.error,
      isEnabled,
    },
    {
      resourceType: 'elasticsearch',
      data: elasticsearchQuery.data?.items,
      isLoading: elasticsearchQuery.isLoading,
      error: elasticsearchQuery.error,
      isEnabled,
    },
    {
      resourceType: 'observability',
      data: observabilityQuery.data?.items,
      isLoading: observabilityQuery.isLoading,
      error: observabilityQuery.error,
      isEnabled,
    },
    {
      resourceType: 'security',
      data: securityQuery.data?.items,
      isLoading: securityQuery.isLoading,
      error: securityQuery.error,
      isEnabled,
    },
  ]
  return queryResults.filter(({ isEnabled }) => isEnabled)
}
