/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation } from 'react-query'

import {
  resumeElasticsearchProject,
  resumeObservabilityProject,
  resumeSecurityProject,
} from '@modules/project-user-api/v1/callers'
import type { ProjectID } from '@modules/project-user-api/v1/types'
import type { QueryFunctionReturnType } from '@modules/query/types'
import type { ProjectType } from '@modules/ui-types/projects'
import { queryClient } from '@modules/query'

import { makeQueryKey } from './helpers'

/**
 * Returns the appropriate hook for the provided project type.
 */
export function getResumeProjectMutationHook(
  projectType: ProjectType,
):
  | typeof useResumeElasticsearchProjectMutation
  | typeof useResumeObservabilityProjectMutation
  | typeof useResumeSecurityProjectMutation {
  switch (projectType) {
    case 'observability':
      return useResumeObservabilityProjectMutation
    case 'security':
      return useResumeSecurityProjectMutation
    default:
      return useResumeElasticsearchProjectMutation
  }
}

const useResumeElasticsearchProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof resumeElasticsearchProject>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      resumeElasticsearchProject({
        pathParameters: { id },
      }),
    onSuccess: (_, { id }) => onProjectResumed('elasticsearch', id),
  })

const useResumeObservabilityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof resumeObservabilityProject>,
    unknown,
    { id: ProjectID }
  >({
    mutationFn: ({ id }) =>
      resumeObservabilityProject({
        pathParameters: { id },
      }),
    onSuccess: (_, { id }) => onProjectResumed('elasticsearch', id),
  })

const useResumeSecurityProjectMutation = () =>
  useMutation<QueryFunctionReturnType<typeof resumeSecurityProject>, unknown, { id: ProjectID }>({
    mutationFn: ({ id }) =>
      resumeSecurityProject({
        pathParameters: { id },
      }),
    onSuccess: (_, { id }) => onProjectResumed('elasticsearch', id),
  })

const onProjectResumed = (projectType: ProjectType, projectId: ProjectID) => {
  const queryKey = makeQueryKey(
    (
      {
        elasticsearch: 'getElasticsearchProject',
        observability: 'getObservabilityProject',
        security: 'getSecurityProject',
      } as const
    )[projectType],
    projectId,
  )
  queryClient.invalidateQueries(queryKey)
}
