/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { updateDeployment } from '@/actions/stackDeployments'
import { getDeploymentTemplate } from '@/reducers/deploymentTemplates'
import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getRegionId, getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { updateStackDeploymentRequest } from '@/reducers/asyncRequests/registry'

import SSOPortOverrideNotification from './SSOPortOverrideNotification'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './SSOPortOverrideNotification'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const deploymentTemplateId = getDeploymentTemplateId({ deployment })
  const version = getVersion({ deployment })
  const regionId = getRegionId({ deployment })

  return {
    deploymentTemplate: getDeploymentTemplate(state, regionId, deploymentTemplateId || ``, version),
    updateDeploymentRequest: updateStackDeploymentRequest(state, deployment.id),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  updateDeployment: (args) => dispatch(updateDeployment(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SSOPortOverrideNotification)
