/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { type ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiScreenReaderOnly } from '@elastic/eui'

import type { StatusValue } from './types'

type Props = {
  status: StatusValue | null | undefined
  pending: boolean | undefined
}

export const StatusScreenReaderOnly = ({ status, pending }: Props) => {
  let statusElement: ReactElement | undefined

  switch (status) {
    case 'stopped':
      statusElement = (
        <FormattedMessage id='status.stopped.screenReaderOnly' defaultMessage='Status Stopped' />
      )
      break
    case 'warning':
      statusElement = (
        <FormattedMessage id='status.warning.screenReaderOnly' defaultMessage='Status Warning' />
      )
      break
    case 'maintenance':
      statusElement = (
        <FormattedMessage
          id='status.maintenance.screenReaderOnly'
          defaultMessage='Status Maintenance'
        />
      )
      break
    case true:
      statusElement = statusElement = (
        <FormattedMessage id='status.ok.screenReaderOnly' defaultMessage='Status Ok' />
      )
      break
    case false:
      statusElement = (
        <FormattedMessage id='status.error.screenReaderOnly' defaultMessage='Status Error' />
      )
      break

    default: {
      if (pending) {
        statusElement = (
          <FormattedMessage id='status.pending.screenReaderOnly' defaultMessage='Status Pending' />
        )
      }
    }
  }

  return statusElement ? (
    <EuiScreenReaderOnly>
      <p>{statusElement}</p>
    </EuiScreenReaderOnly>
  ) : null
}
