/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiFormLabel, EuiSpacer } from '@elastic/eui'

import type { ElasticsearchClusterPlan } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui/Boundary'

import { hasHealthyEsPlan } from '@/lib/stackDeployments/selectors/configurationChangeHealth'

import { planOverridePaths } from '../../../../config/clusterPaths'
import { getConfigForKey } from '../../../../store'

import PlanOverride from './PlanOverride'

import type { FunctionComponent } from 'react'

type Props = {
  checkboxIdPrefix?: string
  deployment: StackDeployment
  hideExtraFailoverOptions: boolean
  basedOnAttempt?: boolean
  plan: ElasticsearchClusterPlan
  onChange: (path: string[], value: any) => void
}

const FailoverOptions: FunctionComponent<Props> = ({
  checkboxIdPrefix,
  deployment,
  plan,
  onChange,
  hideExtraFailoverOptions,
  basedOnAttempt,
}) => {
  const healthyPlan = hasHealthyEsPlan({ deployment })
  const isSad = basedOnAttempt || !healthyPlan

  const isAdminConsole = getConfigForKey(`APP_NAME`) === `adminconsole`

  // ESS AC, GovCloud AC, etc.
  const isAnySaasAdminconsole = getConfigForKey(`APP_PLATFORM`) === `saas` && isAdminConsole

  const showSkipSnapshot = isSad || isAnySaasAdminconsole
  const hasSkipSnapshot = plan.transient?.plan_configuration?.skip_snapshot ?? false

  return (
    <Fragment>
      <EuiFormLabel>
        <FormattedMessage
          id='edit-stack-deployment-failover-options.failover'
          defaultMessage='Failover'
        />
      </EuiFormLabel>

      <EuiSpacer size='s' />

      <FormattedMessage
        id='edit-stack-deployment-failover-options.description'
        defaultMessage='Choose the failover options you want to use while applying these configuration changes:'
      />

      <EuiSpacer size='s' />

      <PlanOverride
        id={`${checkboxIdPrefix ? `${checkboxIdPrefix}-` : ``}failover-extended-maintenance`}
        plan={plan}
        onChange={onChange}
        path={planOverridePaths.extendedMaintenance}
        label={
          <FormattedMessage
            id='edit-stack-deployment-failover-options.extended-maintenance'
            defaultMessage='Extended maintenance'
          />
        }
        description={
          <FormattedMessage
            id='edit-stack-deployment-failover-options.extended-maintenance-description'
            defaultMessage='Recommended when your cluster is overwhelmed by requests and you need to increase its capacity. Applying configuration changes to an undersized cluster without extended maintenance could take longer, make the cluster completely unresponsive, and cause the changes to fail.'
          />
        }
        help={
          <FormattedMessage
            id='edit-stack-deployment-failover-options.extended-maintenance-help'
            defaultMessage='All cluster nodes go into maintenance mode and the <strong>cluster becomes unavailable</strong> while the configuration changes are in progress.'
            values={{
              strong: (content) => <strong>{content}</strong>,
            }}
          />
        }
      />

      {showSkipSnapshot && (
        <PlanOverride
          id={`${checkboxIdPrefix ? `${checkboxIdPrefix}-` : ``}failover-skip-snapshot`}
          plan={plan}
          onChange={onChange}
          path={planOverridePaths.skipSnapshot}
          label={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.skip-snapshot'
              defaultMessage='Skip snapshot'
            />
          }
          description={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.skip-snapshot-description'
              defaultMessage='If your deployment is stuck, overloaded, or otherwise unhealthy, select this option to reapply the plan but disable the snapshot attempt.'
            />
          }
          help={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.skip-snapshot-help'
              defaultMessage='The system does not take a snapshot of the cluster before applying the changes. You will not be able to restore lost data and configurations to their previous state.'
            />
          }
        >
          {hasSkipSnapshot && !isSad && (
            <Fragment>
              <EuiSpacer size='m' />

              <EuiCallOut
                size='s'
                color='warning'
                title={
                  <FormattedMessage
                    id='edit-stack-deployment-failover-options.skip-snapshot-warning'
                    defaultMessage='Do not skip snapshot on a healthy deployment unless you are an advanced user.'
                  />
                }
              />
            </Fragment>
          )}
        </PlanOverride>
      )}

      {isSad && !hideExtraFailoverOptions && (
        <PlanOverride
          id={`${checkboxIdPrefix ? `${checkboxIdPrefix}-` : ``}failover-reallocate`}
          plan={plan}
          onChange={onChange}
          path={planOverridePaths.reallocateInstances}
          label={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.reallocate'
              defaultMessage='Reallocate'
            />
          }
          description={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.reallocate-description'
              defaultMessage='Recommended if an allocator is having issues, or you want to free up space.'
            />
          }
          help={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.reallocate-help'
              defaultMessage='Creates new containers for all instances, and places them on a new allocator.'
            />
          }
        />
      )}

      {isAdminConsole && (
        <PlanOverride
          id={`${checkboxIdPrefix ? `${checkboxIdPrefix}-` : ``}failover-dry-run`}
          plan={plan}
          onChange={onChange}
          path={planOverridePaths.dryRun}
          label={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.dry-run'
              defaultMessage='Dry Run (Experimental)'
            />
          }
          description={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.dry-run-description'
              defaultMessage='Recommended to see what changes a plan would do to a deployment.'
            />
          }
          help={
            <FormattedMessage
              id='edit-stack-deployment-failover-options.dry-run-help'
              defaultMessage='Dry run plans will run a simulation without making changes to the deployment.'
              values={{
                strong: (content) => <strong>{content}</strong>,
              }}
            />
          }
        />
      )}
    </Fragment>
  )
}

export default withErrorBoundary(FailoverOptions)
