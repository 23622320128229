/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import ReduxMiddleware from './middleware/ReduxMiddleware'
import EuiMiddleware from './middleware/EuiMiddleware'
import IntlMiddlware from './middleware/IntlMiddleware'
import ReactQueryMiddleware from './middleware/ReactQueryMiddleware'
import ApmMiddleware from './middleware/ApmMiddleware'
import ConfigMiddleware from './middleware/ConfigMiddleware'
import AuthMiddleware from './middleware/AuthMiddleware'
import applyMiddleware from './applyMiddleware'

const RootApp: React.FunctionComponent<{ app: React.ComponentType }> = ({ app: App }) =>
  applyMiddleware(
    [
      ApmMiddleware,
      ReduxMiddleware,
      IntlMiddlware,
      EuiMiddleware,
      ReactQueryMiddleware,
      ConfigMiddleware,
      AuthMiddleware,
    ],
    <App />,
  )

export default RootApp
