/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { CostsOverview } from '@modules/cloud-api/v1/types'
import type { AccountCosts, AsyncRequestState, UserProfile } from '@modules/ui-types'

import { getAccountCostsOverview, getAccountDeploymentsCosts } from '@/reducers/billing'
import {
  fetchAccountCostsOverviewRequest,
  fetchDeploymentsCostsRequest,
} from '@/reducers/asyncRequests/registry'

import { fetchAccountCostsOverviewIfNeeded } from '../../../../actions/billing'

import CostOverview from './CostOverview'

import type { ThunkDispatch } from '@/types/redux'

interface DispatchProps {
  fetchAccountCostsOverviewIfNeeded: ({ organizationId }: { organizationId: string }) => void
}

interface StateProps {
  accountCosts?: AccountCosts
  accountCostOverview?: CostsOverview
  fetchAccountCostsOverviewRequest: AsyncRequestState
  fetchDeploymentsCostsRequest: AsyncRequestState
}

interface ConsumerProps {
  profile: UserProfile
}

const mapStateToProps = (state, ownProps: ConsumerProps): StateProps => {
  const {
    profile: { organization_id },
  } = ownProps

  return {
    accountCosts: getAccountDeploymentsCosts(state, organization_id!),
    accountCostOverview: getAccountCostsOverview({ state, organizationId: organization_id! }),
    fetchAccountCostsOverviewRequest: fetchAccountCostsOverviewRequest(state),
    fetchDeploymentsCostsRequest: fetchDeploymentsCostsRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchAccountCostsOverviewIfNeeded: ({ organizationId }) =>
    dispatch(fetchAccountCostsOverviewIfNeeded({ organizationId })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CostOverview)
