/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import sluggish from 'sluggish'

import type { EuiSuperSelectOption } from '@elastic/eui'
import { EuiSpacer, EuiText, EuiButtonIcon, EuiSuperSelect } from '@elastic/eui'

import type {
  GlobalDeploymentTemplateInfo,
  DeploymentTemplateInfoV2,
} from '@modules/cloud-api/v1/types'
import type { VersionNumber } from '@modules/ui-types'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'
import { useProfile } from '@modules/profile-lib/hooks'

// eslint-disable-next-line import/no-restricted-paths
import { getCorrectTemplateForGlobalTemplate } from '@/lib/stackDeployments/defaultDeploymentTemplate'
// eslint-disable-next-line import/no-restricted-paths
import { isIncompatibleVersionForGlobalTemplate } from '@/lib/globalDeploymentTemplates'
// eslint-disable-next-line import/no-restricted-paths
import { isTrialEligibleTemplate } from '@/lib/deploymentTemplates/metadata'
// eslint-disable-next-line import/no-restricted-paths
import DocLink from '@/components/DocLink'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

export interface Props extends WrappedComponentProps {
  currentTemplate?: DeploymentTemplateInfoV2
  stackTemplates?: GlobalDeploymentTemplateInfo[]
  onChange: (template) => void
  version: VersionNumber
  disabled?: boolean
  deploymentTemplates: DeploymentTemplateInfoV2[] | null
}

const SelectHardwareProfile: FunctionComponent<Props> = ({
  currentTemplate,
  stackTemplates,
  version,
  disabled,
  intl: { formatMessage },
  deploymentTemplates,
  onChange,
}) => {
  const profile = useProfile()

  if (!currentTemplate || !stackTemplates) {
    return null
  }

  const stackTemplateOptions = stackTemplates.map<EuiSuperSelectOption<string>>((template) => {
    const templateMissingSelectedVersion = isIncompatibleVersionForGlobalTemplate(template, version)
    const trialTemplateUnavailable = profile?.is_trial && !isTrialEligibleTemplate(template)
    const disabledOption = templateMissingSelectedVersion || trialTemplateUnavailable
    const templateInfo = getTemplateInfo(template, deploymentTemplates)
    return {
      ...template,
      name: templateInfo.name,
      value: template.template_category_id || '',
      disabled: Boolean(disabledOption),
      'data-test-id': `hardwareProfile-${sluggish(template.template_category_id)}`,
      dropdownDisplay: (
        <HardwareProfile
          template={{ ...template, ...templateInfo }}
          currentTemplate={currentTemplate}
          inTrial={profile?.is_trial}
        />
      ),
      inputDisplay: templateInfo.name,
    }
  })

  const messages = defineMessages({
    ess: {
      id: `select-hardware-profile-label`,
      defaultMessage: `Hardware profile`,
    },
    popoverText: {
      id: `hardware-profile-template`,
      defaultMessage: `Learn more about hardware profiles.`,
    },
  })
  const label = formatMessage(messages.ess)
  const fieldName = 'hardware-profile-select-box'

  const prepend = [
    label,
    <CuiToggleablePopoverForClassComp
      anchorPosition='upCenter'
      toggleButton={(toggleButton) => (
        <EuiButtonIcon
          aria-label={formatMessage(messages.popoverText)}
          className='hardwareProfileIcon'
          onClick={toggleButton}
          iconType='iInCircle'
          color='text'
        />
      )}
    >
      <EuiText size='s' style={{ width: '300px' }}>
        <FormattedMessage
          data-test-id='ess-hardware-profile-tooltip'
          id='select-hardware-profile.tooltip'
          defaultMessage='A hardware profile deploys the Elastic Stack on virtual hardware. Each profile has a different blend of storage, RAM, and vCPU. {learnMore}'
          values={{
            learnMore: (
              <DocLink link='templatesDocLink'>
                <FormattedMessage
                  id='select-hardware-profile.learn-more'
                  defaultMessage='Learn more'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>
    </CuiToggleablePopoverForClassComp>,
  ]

  return (
    <EuiSuperSelect
      name={fieldName}
      disabled={disabled}
      fullWidth={true}
      popoverProps={{ css: { '.euiFormControlLayout__prepend': { width: 180, gap: 0 } } }}
      prepend={prepend}
      hasDividers={true}
      options={stackTemplateOptions}
      valueOfSelected={currentTemplate.template_category_id || ''}
      onChange={(selected) => onSelectHardwareProfile(selected, stackTemplateOptions, onChange)}
      data-test-id={fieldName}
      itemClassName='hardware-profile-select-item'
      aria-label={label}
    />
  )
}

const HardwareProfile: FunctionComponent<{
  template: any
  currentTemplate: any
  inTrial: boolean | undefined
}> = ({ template, currentTemplate, inTrial }) => {
  const trialTemplateUnavailable = inTrial && template.disabled
  const trialDescription = (
    <FormattedMessage
      id='create-deployment-from-template.hardware-profile.trial'
      defaultMessage='Not available in trial'
    />
  )
  const isSelectedTemplate = template.template_category_id === currentTemplate?.template_category_id

  return (
    <Fragment>
      <EuiText
        data-test-id='hardware-profile-label'
        size='s'
        className={
          isSelectedTemplate ? 'hardware-profile-label-selected' : 'hardware-profile-label'
        }
      >
        <strong>{template.name}</strong>
      </EuiText>

      <EuiSpacer size='xs' />

      <EuiText size='s' color='subdued'>
        {trialTemplateUnavailable ? trialDescription : template.description}
      </EuiText>
    </Fragment>
  )
}

const onSelectHardwareProfile = (selected, options, onChange) => {
  const selectedOption = options.find((option) => option.template_category_id === selected)
  onChange(selectedOption)
}

const getTemplateInfo = (
  globalTemplate: GlobalDeploymentTemplateInfo,
  deploymentTemplates: DeploymentTemplateInfoV2[] | null,
) => {
  if (!deploymentTemplates) {
    return {
      name: globalTemplate.name,
      description: globalTemplate.description,
    }
  }

  const matchingTemplate = getCorrectTemplateForGlobalTemplate({
    deploymentTemplates,
    globalTemplate,
  })

  if (!matchingTemplate) {
    return {
      name: globalTemplate.name,
      description: globalTemplate.description,
    }
  }

  return {
    name: matchingTemplate.name,
    description: matchingTemplate.description,
  }
}

export default injectIntl(SelectHardwareProfile)
