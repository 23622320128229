/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiTitle } from '@elastic/eui'

import Feature from '@modules/utils/feature'
import Header from '@modules/cui/Header'

import { isFeatureActivated } from '../../store'
import ApiKeys from '../ApiKeys'
import { userSettingsCrumbs } from '../../lib/crumbBuilder'

import UpdateProfile from './UpdateProfile'

import type { FunctionComponent } from 'react'

const UserSettings: FunctionComponent = () => {
  const isRbacEnabled = isFeatureActivated(Feature.manageRbac)

  return (
    <Fragment>
      <Header
        name={<FormattedMessage id='user-settings.title' defaultMessage='User settings' />}
        breadcrumbs={userSettingsCrumbs()}
      />

      {isRbacEnabled && (
        <EuiFlexGroup direction='column'>
          <EuiFlexItem>
            <UpdateProfile />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiHorizontalRule />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h3>
                <FormattedMessage id='user-settings.apiKeys.heading' defaultMessage='API keys' />
              </h3>
            </EuiTitle>
          </EuiFlexItem>

          <EuiFlexItem>
            <ApiKeys />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </Fragment>
  )
}

export default UserSettings
