/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getInstanceConfigurations } from '@/reducers/instanceConfigurations'

import { fetchInstanceConfigurationsIfNeeded } from '../../../../actions/topology/instanceConfigurations'

import DeploymentConfigurationChangeExplain from './DeploymentConfigurationChangeExplain'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deploymentUnderEdit }: ConsumerProps): StateProps => {
  const regionId = getRegionId({ deployment: deploymentUnderEdit })

  return {
    instanceConfigurations: getInstanceConfigurations(state, regionId),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deploymentUnderEdit }: ConsumerProps,
): DispatchProps => {
  const regionId = getRegionId({ deployment: deploymentUnderEdit })

  return {
    fetchInstanceConfigurations: () =>
      regionId && dispatch(fetchInstanceConfigurationsIfNeeded(regionId)),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeploymentConfigurationChangeExplain)
