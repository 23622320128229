/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const DEFAULT_SEARCH_POWER = 2
export const DEFAULT_BOOST_DAYS = 8
export const DEFAULT_TIER = 'essentials'

export const SearchPowerTicks = [
  {
    value: 1,
    messageDescriptor: {
      id: 'pricing-list.security.search-power.onDemand',
      defaultMessage: 'On-demand',
    },
  },
  {
    value: 2,
    messageDescriptor: {
      id: 'pricing-list.security.search-power.performant',
      defaultMessage: 'Performant',
    },
  },
  {
    value: 3,
    messageDescriptor: {
      id: 'pricing-list.security.search-power.highThroughput',
      defaultMessage: 'High-throughput',
    },
  },
]

export const TierOptions = [
  {
    id: 'essentials',
    label: (
      <FormattedMessage id='pricing-list.security.tier.essentials' defaultMessage='Essentials' />
    ),
  },
  {
    id: 'complete',
    label: <FormattedMessage id='pricing-list.security.tier.complete' defaultMessage='Complete' />,
  },
]
