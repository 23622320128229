/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getVerifySaasCurrentUserMfaFactorRequest } from '@/reducers/asyncRequests/registry'
import {
  removeMfaDeviceRequest,
  enableMfaDeviceRequest,
  disableMfaDeviceRequest,
  enrollMfaDeviceRequest,
  activateMfaDeviceRequest,
} from '@/apps/userconsole/reducers/registry'
import { getMfaEnabled, getMfaDevices } from '@/apps/userconsole/reducers/mfa'

import {
  verifySaasCurrentUserMfaFactor,
  resetVerifySaasCurrentUserMfaFactorRequest,
  challengeSaasCurrentUserMfaFactor,
} from '../../../actions/auth'
import {
  activateMfaDevice,
  disableMfa,
  enableMfa,
  enrollMfaDevice,
  fetchMfaDevices,
  fetchMfaStatus,
  removeMfaDevice,
  resetMfaEnrollment,
} from '../../../apps/userconsole/actions/mfa'

import MfaProvider from './MfaProvider'

const mapStateToProps = (state) => ({
  activateMfaDeviceRequest: (device_id) => activateMfaDeviceRequest(state, device_id),
  disableMfaDeviceRequest: disableMfaDeviceRequest(state),
  enableMfaDeviceRequest: enableMfaDeviceRequest(state),
  enrollMfaDeviceRequest: (device_type) => enrollMfaDeviceRequest(state, device_type),
  mfaEnabled: getMfaEnabled(state),
  mfaDevices: getMfaDevices(state),
  removeMfaDeviceRequest: (device_id) => removeMfaDeviceRequest(state, device_id),
  verifySaasCurrentUserMfaFactorRequest: getVerifySaasCurrentUserMfaFactorRequest(state),
})

const mapDispatchToProps = {
  fetchMfaStatus,
  enableMfa,
  disableMfa,
  fetchMfaDevices,
  enrollMfaDevice,
  activateMfaDevice,
  removeMfaDevice,
  resetMfaEnrollment,
  challengeSaasCurrentUserMfaFactor,
  resetVerifySaasCurrentUserMfaFactorRequest,
  verifySaasCurrentUserMfaFactor,
}

export default connect(mapStateToProps, mapDispatchToProps)(MfaProvider)
