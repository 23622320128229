/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { ProductLineItem } from '@modules/billing-api/v1/types'
import type { DeploymentDimension } from '@modules/billing-api/customTypes'

import type { ReactElement } from 'react'

export interface UsageRate {
  dimension: string
  description: ReactElement
  dataTestId: string
}

export enum PeriodTab {
  Current = 'current',
  Previous = 'previous',
}

export enum Project {
  Security = 'security',
  Observability = 'observability',
  Elasticsearch = 'elasticsearch',
}

export enum Extras {
  Addons = 'serverless_addons',
  Support = 'serverless_support',
}

export type DeploymentCosts = Partial<Record<DeploymentDimension, number>>

export type ProjectCosts = Record<
  Project | Extras,
  Array<{
    type: string
    total_ecu: ProductLineItem['total_ecu']
  }>
>
