/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { isArray } from 'lodash'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { EuiSideNav } from '@elastic/eui'

import type {
  SaasUserResponse,
  DeploymentGetResponse,
  DeploymentTemplateInfoV2,
  DeploymentHealth,
} from '@modules/cloud-api/v1/types'
import type { ElasticsearchCluster, ProfileState, AsyncRequestState } from '@modules/ui-types'
import type { EolStatusResponse } from '@modules/ui-types/eolStatus'
import history from '@modules/utils/history'
import Feature from '@modules/utils/feature'
import type { EuiSideNavItem } from '@modules/utils/navigation'
import { createItem } from '@modules/utils/navigation'
import { buildProjectsMenu } from '@modules/project-user-pages/navigation'
import type { WithPermissionsProps } from '@modules/permissions-components/PermissionsGate/withPermissions'

import { hasSizedSliderResource, getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { isSliderSupportedForDeployment } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getDisplayName } from '@/lib/stackDeployments/selectors/display'
import { getSupportedSliderInstanceTypesWithoutEs } from '@/lib/sliders/support'
import { getSliderPrettyName } from '@/lib/sliders/messages'

import { getHerokuCluster } from '../../../../lib/heroku'
import { getRouteParams, matchRoute } from '../../../../lib/router'
import {
  clusterSnapshotUrl,
  createDeploymentUrl,
  deploymentExtensionCreateUrl,
  deploymentExtensionsUrl,
  deploymentExtensionUrl,
  deploymentsUrl,
  deploymentUrl,
  deploymentUrls,
  deploymentFeaturesUrl,
  supportUrl,
  logsMonitoringUrl,
} from '../../../../lib/urlBuilder'
import {
  apiKeysUrl,
  trafficFiltersUrl,
  accountDetailsUrl,
  accountSecurityUrl,
  trustManagementUrl,
} from '../../urls'
import { isPortalUrl } from '../../../../lib/portal'
import {
  getDeploymentHealthProblems,
  getHealthStatusNavigationIcon,
} from '../../../../lib/healthProblems'
import { isFeatureActivated } from '../../../../store'

import messages from './navigationMessages'

import type { UnregisterCallback } from 'history'
import type { RouteComponentProps } from 'react-router-dom'
import type { RouteConfig } from 'react-router-config'
import type { IntlShape } from 'react-intl'
import type { ReactNode, ReactElement } from 'react'
import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'

import '../../../../components/ChromeNavigation/chromeNavigation.scss'

type RouteParams = {
  regionId: string
  deploymentId: string
  snapshotName: string
  extensionId: string
}

type RenderNavLinkProps = {
  href?: string
  children: ReactNode
  className?: string
  exact?: boolean
  beta?: boolean
}

export type Props = {
  intl: IntlShape
  routes: RouteConfig[]
  location: RouteComponentProps['location']
  deployment?: ElasticsearchCluster
  deploymentTemplate?: DeploymentTemplateInfoV2
  defaultRegionId?: string
  regionId?: string
  deploymentId?: string
  stackDeploymentId?: string
  stackDeployment?: DeploymentGetResponse
  profile?: ProfileState
  fetchDeploymentTemplates: (params: {
    regionId: string
    stackVersion: string
    showMaxZones: boolean
  }) => void
  saasUserProfile?: SaasUserResponse
  lookupSaasUsers: boolean
  isHeroku: boolean
  isPortalFeatureEnabled: boolean
  serverlessFeature: boolean
  showHelpPage: boolean
  showDeploymentFeaturesPage: boolean
  showSecurityPage: boolean
  eolStatus: EolStatusResponse | null
  deploymentHealthRequest?: AsyncRequestState
  health?: DeploymentHealth | null
} & WithPermissionsProps<'hasListApiKeysPermission'>

type State = {
  isSideNavOpenOnMobile: boolean
}

class UserconsoleChromeNavigation extends Component<Props & LDProps, State> {
  state: State = {
    isSideNavOpenOnMobile: false,
  }

  clearOnNavigateHook: UnregisterCallback | null = null

  componentDidMount() {
    const deploymentActive = isDeploymentActive(this.props)

    if (deploymentActive) {
      this.fetchDeploymentTemplatesWithStackVersion()
    }

    this.clearOnNavigateHook = history.listen(this.onNavigate)
  }

  componentDidUpdate(prevProps) {
    const prevDeploymentVersion = prevProps.deployment?.plan?.version || null
    const deploymentVersion = this.props.deployment?.plan.version || null
    const isActive = isDeploymentActive(this.props)

    if (!isActive) {
      return
    }

    const wasActive = isDeploymentActive(prevProps)

    if (
      !wasActive ||
      prevProps?.deployment?.id !== this.props.deployment?.id ||
      deploymentVersion !== prevDeploymentVersion
    ) {
      this.fetchDeploymentTemplatesWithStackVersion()
    }
  }

  componentWillUnmount() {
    if (this.clearOnNavigateHook) {
      this.clearOnNavigateHook()
      this.clearOnNavigateHook = null
    }
  }

  render() {
    const {
      location,
      intl: { formatMessage },
      routes,
      showHelpPage,
      showDeploymentFeaturesPage,
      serverlessFeature,
      isHeroku,
      flags,
    } = this.props

    const params = getRouteParams<RouteParams>(routes, location.pathname)

    const { snapshotName } = params

    const deploymentsActive = isDeploymentActive(this.props)
    const createDeploymentActive = isCreateDeploymentActive(this.props)
    const deploymentFeaturesActive = isDeploymentFeaturesActive(this.props)

    const createDeploymentItem = createDeploymentActive
      ? [
          createItem({
            name: formatMessage(messages.createDeployment),
            href: createDeploymentUrl(),
            [`data-test-id`]: `deployment-section-create-deployment-link`,
          }),
        ]
      : undefined

    const deploymentItems = deploymentsActive
      ? this.deploymentNavigation({
          location,
          snapshotName,
        })
      : createDeploymentItem

    const deploymentFeatureItems = deploymentFeaturesActive
      ? this.deploymentFeaturesNavigation()
      : []
    const accountItems = this.accountNavigation()

    const euiSideNavItems: EuiSideNavItem[] = []

    if (isHeroku) {
      const herokuCluster = getHerokuCluster()

      if (herokuCluster) {
        euiSideNavItems.push(
          ...this.deploymentNavigation({
            location,
            snapshotName,
          }),
        )
      }
    } else {
      euiSideNavItems.push(
        createItem({
          name: formatMessage(messages.deployments),
          href: deploymentsUrl(),
          items: deploymentItems,
          [`data-test-id`]: `deployment-section-link`,
        }),
      )
    }

    if (serverlessFeature && flags?.showServerlessFlag) {
      euiSideNavItems.push(buildProjectsMenu(this.props))
    }

    if (!isHeroku) {
      if (showDeploymentFeaturesPage) {
        euiSideNavItems.push(
          createItem({
            name: formatMessage(messages.deploymentFeatures),
            href: deploymentFeaturesUrl(),
            items: deploymentFeatureItems,
            [`data-test-id`]: `deployment-features-index-link`,
          }),
        )
      }

      accountItems.forEach((item) => {
        euiSideNavItems.push(item)
      })
    }

    if (showHelpPage) {
      euiSideNavItems.push(
        createItem({
          name: formatMessage(messages.support),
          href: supportUrl(),
        }),
      )
    }

    const isNavItemSelected = (item: EuiSideNavItem) => {
      if (!item.href) {
        return false
      }

      return location.pathname.startsWith(item.href)
    }

    const euiSideNavItemsWithSelectedItem = setSelectedItem(euiSideNavItems, isNavItemSelected)

    return (
      <EuiSideNav
        mobileTitle={formatMessage(messages.mobileTitle)}
        isOpenOnMobile={this.state.isSideNavOpenOnMobile}
        toggleOpenOnMobile={this.toggleOpenOnMobile}
        items={euiSideNavItemsWithSelectedItem}
        renderItem={renderNavLink}
        className='cloud-navigation'
      />
    )
  }

  toggleOpenOnMobile = () => {
    this.setState({
      isSideNavOpenOnMobile: !this.state.isSideNavOpenOnMobile,
    })
  }

  onNavigate = (location) => {
    if (!isPortalUrl(location.pathname)) {
      this.setState({
        isSideNavOpenOnMobile: false,
      })
    }
  }

  fetchDeploymentTemplatesWithStackVersion = () => {
    const { regionId, deployment, stackDeployment, fetchDeploymentTemplates } = this.props
    const stackVersion = stackDeployment
      ? getVersion({ deployment: stackDeployment })
      : deployment?.plan.version

    if (regionId == null) {
      return // avoid loading templates without region
    }

    if (stackVersion == null) {
      return // avoid loading templates without version qualification
    }

    fetchDeploymentTemplates({ regionId, stackVersion, showMaxZones: true })
  }

  deploymentNavigation({ location, snapshotName }) {
    const {
      deployment,
      deploymentTemplate,
      stackDeploymentId,
      stackDeployment,
      intl: { formatMessage },
      eolStatus,
      health,
      deploymentHealthRequest,
    } = this.props

    const id = stackDeploymentId!
    const urls = deploymentUrls(id)
    const version = stackDeployment ? getVersion({ deployment: stackDeployment }) : undefined

    const elasticsearchSubItems = [
      createItem({
        name: formatMessage(messages.clusterSnapshots),
        href: urls.snapshots,
        [`data-test-id`]: `deployment-index-elasticsearch-snapshots-link`,
        items: snapshotName
          ? [
              createItem({
                name: snapshotName,
                href: clusterSnapshotUrl(id, snapshotName),
              }),
            ]
          : undefined,
      }),
      createItem({
        name: formatMessage(messages.apiConsole),
        href: urls.console,
        'data-test-id': `deployment-console-link`,
      }),
    ]

    const editItem = createItem({
      name: formatMessage(messages.deploymentEdit),
      href: urls.edit,
      'data-test-id': `deployment-edit-link`,
    })

    // Compute all the deployment problems so we can figure out
    // if we should show a warning icon in the healthStatusItem
    // from the navigation bar.
    const [problems] = getDeploymentHealthProblems({
      deployment: stackDeployment!,
      eolStatus,
      health,
      deploymentHealthRequest,
    })

    const elasticsearchItems = createItem({
      name: formatMessage(messages.deploymentElasticsearch),
      href: urls.elasticsearch,
      'data-test-id': `deployment-index-elasticsearch-overview-link`,
      items: elasticsearchSubItems,
    })

    const monitoringSectionNavItems = createItem({
      name: formatMessage(messages.deploymentMonitoring),
      href: urls.monitoring,
      'data-test-id': `deployment-index-monitoring-link`,
      items: [
        createItem({
          name: formatMessage(messages.healthStatus),
          href: urls.healthStatus,
          icon: getHealthStatusNavigationIcon(problems),
          'data-test-id': `deployment-health-status`,
        }),
        createItem({
          name: formatMessage(messages.loggingMonitoring),
          href: logsMonitoringUrl(id),
          [`data-test-id`]: `deployment-index-logging-monitoring-link`,
        }),
        createItem({
          name: formatMessage(messages.deploymentMetrics),
          href: urls.metrics,
          'data-test-id': `deployment-index-elasticsearch-metrics-link`,
        }),
      ],
    })

    const sliderItems = getSupportedSliderInstanceTypesWithoutEs()
      .filter((sliderInstanceType) =>
        isSliderSupportedForDeployment({
          deployment: stackDeployment || undefined,
          deploymentTemplate: deploymentTemplate?.deployment_template,
          sliderInstanceType,
        }),
      )
      .filter((sliderInstanceType) =>
        stackDeployment
          ? hasSizedSliderResource({
              deployment: stackDeployment,
              resourceType: sliderInstanceType,
            })
          : false,
      )
      .map((sliderInstanceType) =>
        createItem({
          name: formatMessage(getSliderPrettyName({ sliderInstanceType, version })),
          href: urls[sliderInstanceType],
          'data-test-id': `deployment-index-${sliderInstanceType}-overview-link`,
        }),
      )

    const activityItem = createItem({
      name: formatMessage(messages.deploymentActivity),
      href: urls.deploymentActivity,
      'data-test-id': `deployment-index-activity-link`,
      isSelected: location.pathname.startsWith(urls.deploymentActivity),
    })

    const securityItem = createItem({
      name: formatMessage(messages.deploymentSecurity),
      href: urls.security,
      [`data-test-id`]: `deployment-index-elasticsearch-security-link`,
    })

    const deploymentItems = [
      editItem,
      monitoringSectionNavItems,
      elasticsearchItems,
      ...sliderItems,
      activityItem,
      securityItem,
    ]

    return [
      createItem({
        name: getDeploymentName(),
        href: urls.root,
        'data-test-id': `deployment-index-overview-link`,
        id: `deployment-index-overview-link`,
        items: deploymentItems,
      }),
    ]

    function getDeploymentName() {
      if (stackDeployment) {
        return getDisplayName({ deployment: stackDeployment })
      }

      if (stackDeploymentId) {
        return stackDeploymentId.slice(0, 6)
      }

      if (deployment) {
        return deployment.displayName
      }

      return 'unknown'
    }
  }

  accountNavigation() {
    const {
      intl: { formatMessage },
      showSecurityPage,
      isPortalFeatureEnabled,
    } = this.props

    const accountNavigationItems: EuiSideNavItem[] = []

    if (!isPortalFeatureEnabled) {
      accountNavigationItems.push(
        createItem({
          name: formatMessage(messages.accountProfile),
          href: accountDetailsUrl(),
          [`data-test-id`]: `account-index-details-link`,
        }),
      )

      if (showSecurityPage) {
        accountNavigationItems.push(
          createItem({
            name: formatMessage(messages.accountSecurity),
            href: accountSecurityUrl(),
            [`data-test-id`]: `account-index-security-link`,
          }),
        )
      }
    }

    return accountNavigationItems
  }

  deploymentFeaturesNavigation() {
    const {
      location,
      intl: { formatMessage },
      profile,
      routes,
      permissions: { hasListApiKeysPermission },
    } = this.props
    const params = getRouteParams<RouteParams>(routes, location.pathname)
    const featuresNavigationItems: EuiSideNavItem[] = []
    const { extensionId } = params
    const creatingExtension = isDeploymentExtensionCreateActive(this.props)
    const editingExtension = isDeploymentExtensionEditActive(this.props)

    if (hasListApiKeysPermission) {
      featuresNavigationItems.push(
        createItem({
          name: formatMessage(messages.accountApiKeys),
          href: apiKeysUrl(),
          [`data-test-id`]: `deployment-features-index-api-keys-link`,
        }),
      )
    }

    if (isFeatureActivated(Feature.trafficFiltering)) {
      featuresNavigationItems.push(
        createItem({
          name: formatMessage(messages.accountTrafficFilters),
          href: trafficFiltersUrl(),
          [`data-test-id`]: `deployment-features-index-traffic-filters-link`,
        }),
      )
    }

    if (isFeatureActivated(Feature.crossEnvCCSCCR)) {
      featuresNavigationItems.push(
        createItem({
          name: formatMessage(messages.trustManagement),
          href: trustManagementUrl(),
          [`data-test-id`]: `deployment-features-index-trust-management-link`,
        }),
      )
    }

    if (profile && (profile.allow_bundles || profile.allow_plugins)) {
      const deploymentExtensionsItems = [
        ...(editingExtension
          ? [
              createItem({
                name: extensionId!,
                href: deploymentExtensionUrl(extensionId!),
                [`data-test-id`]: `deployment-features-deployment-edit-extensions-link`,
              }),
            ]
          : []),
        ...(creatingExtension
          ? [
              createItem({
                name: formatMessage(messages.createExtension),
                href: deploymentExtensionCreateUrl(),
                [`data-test-id`]: `deployment-features-deployment-create-extensions-link`,
              }),
            ]
          : []),
      ]

      featuresNavigationItems.push(
        createItem({
          name: formatMessage(messages.extensionsOverview),
          href: deploymentExtensionsUrl(),
          items: deploymentExtensionsItems,
          [`data-test-id`]: `deployment-features-deployment-extensions-link`,
        }),
      )
    }

    return featuresNavigationItems
  }
}

export default injectIntl(withLDConsumer()(UserconsoleChromeNavigation))

function renderNavLink({
  href = `/`,
  children,
  exact = false,
  ...rest
}: RenderNavLinkProps): ReactElement {
  return (
    <NavLink to={href} exact={exact} activeClassName='euiSideNavItemButton-isSelected' {...rest}>
      {children}
    </NavLink>
  )
}

function setSelectedItem(
  items: EuiSideNavItem[],
  isSelected: (item: EuiSideNavItem) => boolean,
): EuiSideNavItem[] {
  return items.map(handleItemSelection)

  function handleItemSelection(item: EuiSideNavItem): EuiSideNavItem {
    if (isArray(item.items)) {
      return {
        ...item,
        items: setSelectedItem(item.items, isSelected),
        isSelected: isSelected(item),
      }
    } else if (isSelected(item)) {
      return {
        ...item,
        isSelected: true,
      }
    }

    return item
  }
}

function isDeploymentActive({ routes, location, deployment = { wasDeleted: false } }) {
  return (
    matchRoute(routes, location.pathname, deploymentUrl(`:deploymentId`), {
      exact: false,
    }) && !deployment.wasDeleted
  )
}

function isCreateDeploymentActive({ routes, location }) {
  return matchRoute(routes, location.pathname, createDeploymentUrl())
}

function isDeploymentExtensionEditActive({ routes, location }) {
  return matchRoute(routes, location.pathname, deploymentExtensionUrl(`:extensionId`))
}

function isDeploymentExtensionCreateActive({ routes, location }) {
  return matchRoute(routes, location.pathname, deploymentExtensionCreateUrl())
}

function isDeploymentFeaturesActive({ routes, location }) {
  return matchRoute(routes, location.pathname, deploymentFeaturesUrl(), { exact: false })
}
