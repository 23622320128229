/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiText, EuiSpacer } from '@elastic/eui'

import type {
  InstanceConfiguration,
  ElasticsearchClusterTopologyElement,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement } from '@modules/ui-types'

import { isRiskyWithSingleZone, isFrozen } from '@/lib/stackDeployments/selectors/nodeRoles'
import { getEuiColor } from '@/lib/healthProblems'

import DocLink from '../../../../../DocLink'

import { getRenderOptions } from './helpers'

import type { SeverityLevel } from '@/lib/healthProblems'
import type { ReactNode } from 'react'

export interface Props {
  id: string
  topologyElement: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  inTrial: boolean
}

type MessageWithLevel = { level: SeverityLevel; message: ReactNode }

export default class Messages extends Component<Props> {
  render(): JSX.Element | null {
    const messages = this.getMessages()

    if (messages.length === 0) {
      return null
    }

    const calloutColor = getEuiColor(messages)

    const content = (
      <EuiText>
        {messages.length === 1 ? (
          messages[0]?.message
        ) : (
          <ul>
            {messages.map((message, i) => (
              <li key={i}>{message.message}</li>
            ))}
          </ul>
        )}
      </EuiText>
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiCallOut data-test-id='instance-config-callout' color={calloutColor} role='alert'>
          {content}
        </EuiCallOut>
      </Fragment>
    )
  }

  getMessages = (): MessageWithLevel[] =>
    [this.getDataLossWarningMessage(), this.getTrialMessage()].filter(
      (v) => v,
    ) as MessageWithLevel[]

  getDataLossWarningMessage = (): MessageWithLevel | undefined => {
    const { topologyElement } = this.props

    const dataLossRisk =
      isRiskyWithSingleZone({ topologyElement }) && topologyElement.zone_count === 1

    if (!dataLossRisk) {
      return
    }

    return {
      level: `warning`,
      message: (
        <FormattedMessage
          id='zone-info.one-zone-configurations'
          defaultMessage='You are at risk of data loss with fault tolerance set to a single zone. { learnMore }'
          values={{
            learnMore: (
              <DocLink link='faultToleranceDocLink'>
                <FormattedMessage
                  id='uc.configure-instance.fault-tolerance-link'
                  defaultMessage='Learn more...'
                />
              </DocLink>
            ),
          }}
        />
      ),
    }
  }

  getTrialMessage(): MessageWithLevel | undefined {
    const { topologyElement, instanceConfiguration, inTrial } = this.props
    const { size } = topologyElement
    const {
      discrete_sizes: { default_size: defaultSize },
      storage_multiplier,
      cpu_multiplier,
    } = instanceConfiguration
    const esTopologyElement = topologyElement as ElasticsearchClusterTopologyElement
    const isHotNode = esTopologyElement.elasticsearch?.node_attributes?.data === `hot`
    const isSmallerThanDefault = size && size.value < (defaultSize || 0)
    const smallTrial = inTrial && isHotNode && isSmallerThanDefault
    const isBlobStorage = isFrozen({ topologyElement })
    const sizeText = getRenderOptions({
      instanceResource: `memory`,
      storageMultiplier: storage_multiplier,
      cpuMultiplier: cpu_multiplier,
      value: defaultSize || 0,
      primaryKey: `storage`,
      secondaryKey: `memory`,
      isBlobStorage,
      isMachineLearning: false,
    })
    const { cpu_text, primary_text, secondary_text } = sizeText
    const minSize = `${primary_text} | ${secondary_text} | ${cpu_text}`

    if (!smallTrial) {
      return
    }

    return {
      level: `info`,
      message: (
        <FormattedMessage
          id='zone-info.size-configurations'
          defaultMessage='During your trial, we recommend using {minSize} per zone. Using less could affect the performance of your deployment. { learnMore }'
          values={{
            minSize,
            learnMore: (
              <DocLink link='planForProduction'>
                <FormattedMessage
                  id='uc.configure-instance.fault-tolerance-link'
                  defaultMessage='Learn more...'
                />
              </DocLink>
            ),
          }}
        />
      ),
    }
  }
}
