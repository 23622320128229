/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import { addToast } from '@modules/cui/Toasts'

import searchOptimizationOptions from './searchOptimizationOptions'

import type {
  DisabledSearchOptimizationOptionInfo,
  EnabledSearchOptimizationOptionInfo,
  SearchOptimizationOptionInfo,
} from './types'
import type { ReactNode } from 'react'

export interface OptimizationOptionProps {
  slug: S
  title: string
  description: string
  disabled: boolean
  value: S | undefined
  notifyButton: ReactNode
  checked: boolean
  onChange: (value: S) => void
}

interface PresentOptimizationOptionsProps {
  value: S | undefined
  onChange: (value: EnabledSearchOptimizationOptionInfo['slug']) => void
  OptimizationOptionComponent: React.ComponentType<OptimizationOptionProps>
}

type S = SearchOptimizationOptionInfo['slug']

/*
 * This component is used to present the optimization options for the user to select.
 * It maps over the searchOptimizationOptions and renders the OptimizationOptionComponent,
 * which is a custom component passed as a prop.
 * The notifyButton is displayed only when the optimization option is disabled.
 * The notifyButton is used to notify the user that they are interested in the disabled optimization option.
 * The value is the currently selected optimization option.
 */
const PresentOptimizationOptions = ({
  value,
  onChange,
  OptimizationOptionComponent: PresentOptimizationOptionComponent,
}: PresentOptimizationOptionsProps) => {
  const { formatMessage } = useIntl()

  const [notifyButtonFlags, setNotifyButtonFlags] = useState<
    Record<DisabledSearchOptimizationOptionInfo['slug'], boolean> | undefined
  >(undefined)

  return (
    <Fragment>
      {searchOptimizationOptions.map(({ slug, name, title, description, disabled }, i) => {
        const notifyButton = disabled && (
          <EuiButton
            data-track-id={`notify-about-${slug}`}
            size='s'
            iconType='check'
            color={notifyButtonFlags?.[slug] ? 'success' : 'primary'}
            onClick={() => {
              setNotifyButtonFlags({ ...notifyButtonFlags, [slug]: true })
              addToast({
                family: 'project-subtype-notify-me',
                title: (
                  <FormattedMessage
                    id='createProject.subType.notifyToast'
                    defaultMessage='Thanks for your feedback.'
                  />
                ),
                color: 'success',
              })
            }}
          >
            <FormattedMessage
              id='createProject.subType.notifyButton'
              defaultMessage="I'm interested<srOnly> in {name}</srOnly>"
              values={{
                srOnly: (content) => <div className='euiScreenReaderOnly'>{content}</div>,
                name: formatMessage(name),
              }}
            />
          </EuiButton>
        )

        return (
          <PresentOptimizationOptionComponent
            slug={slug}
            value={value}
            title={formatMessage(title)}
            description={formatMessage(description)}
            checked={slug === value}
            key={i}
            disabled={disabled}
            notifyButton={notifyButton}
            onChange={onChange}
          />
        )
      })}
    </Fragment>
  )
}

export default PresentOptimizationOptions
