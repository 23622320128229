/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { FunctionComponent } from 'react'

const UserconsoleDeploymentSecurityEditorBefore5x: FunctionComponent = () => (
  <FormattedMessage
    id='deployment-security-editor-before-5x.not-available-uc'
    defaultMessage='It is no longer possible to manage Shield users in 2.x via Elastic Cloud, please upgrade Elasticsearch.'
  />
)

export default UserconsoleDeploymentSecurityEditorBefore5x
