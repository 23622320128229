/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { Subscription } from '@modules/ui-types/billing'

const messages = defineMessages({
  modalTitle: {
    id: `update-subscription-modal.title`,
    defaultMessage: `Subscribe to Elastic Cloud`,
  },
  modalChangePlanDescription: {
    id: `update-subscription-modal.change-plan-description`,
    defaultMessage: `You can change your plan anytime in the <billingConsole>Billing Console</billingConsole>.`,
  },
  modalChooseSubscriptionDescription: {
    id: `update-subscription-modal.choose-subscription-description`,
    defaultMessage: `Choose a subscription level that's right for you. <docLink>Compare plans and pricing options</docLink>.`,
  },
  standard: {
    id: `update-subscription.standard.pretty-name`,
    defaultMessage: `Standard`,
  },
  gold: {
    id: `update-subscription.gold.pretty-name`,
    defaultMessage: `Gold`,
  },
  platinum: {
    id: `update-subscription.platinum.pretty-name`,
    defaultMessage: `Platinum`,
  },
  enterprise: {
    id: `update-subscription.enterprise.pretty-name`,
    defaultMessage: `Enterprise`,
  },
  serverlessTitle: {
    id: `update-subscription.serverlessTitle`,
    defaultMessage: `Serverless projects`,
  },
  hostedTitle: {
    id: `update-subscription.hostedTitle`,
    defaultMessage: `Hosted deployments`,
  },
  standardSupportName: {
    id: `update-subscription.standard.support-name`,
    defaultMessage: `Limited support`,
  },
  goldSupportName: {
    id: `update-subscription.gold.support-name`,
    defaultMessage: `Base support`,
  },
  platinumSupportName: {
    id: `update-subscription.platinum.support-name`,
    defaultMessage: `Enhanced support`,
  },
  enterpriseSupportName: {
    id: `update-subscription.enterprise.support-name`,
    defaultMessage: `Premium support`,
  },
  // Support
  standardSupport: {
    id: `update-subscription.standard.features.standard-support`,
    defaultMessage: `Ticket-based support for up to 2 support contacts`,
  },
  goldSupport: {
    id: `update-subscription.standard.features.gold-support`,
    defaultMessage: `Business hours only support for up to 6 support contacts`,
  },
  platinumSupport: {
    id: `update-subscription.standard.features.platinum-support`,
    defaultMessage: `24/7/365 support for up to 8 support contacts`,
  },
  enterpriseSupport: {
    id: `update-subscription.standard.features.enterprise-support`,
    defaultMessage: `24/7/365 support for up to 8 support contacts`,
  },
  // Serverless
  serverlessAbilityFeature: {
    id: `update-subscription.serverless.features.ability`,
    defaultMessage: `Ability to deploy Elasticsearch, Observability and Security`,
  },
  // Hosted
  // Standard
  coreFeatures: {
    id: `update-subscription.standard.features.core-features`,
    defaultMessage: `Core Elastic Stack security features`,
  },
  capabilities: {
    id: `update-subscription.standard.features.capabilities`,
    defaultMessage: `Capabilities such as Elastic APM, SIEM, and Maps`,
  },
  canvasAndLens: {
    id: `update-subscription.standard.features.canvas-lens`,
    defaultMessage: `Canvas & Lens`,
  },
  // Gold
  configurableMonitoring: {
    id: `update-subscription.gold.features.configurable-monitoring`,
    defaultMessage: `Configurable monitoring retention policy`,
  },
  customExtensions: {
    id: `update-subscription.gold.features.custom-extensions`,
    defaultMessage: `Custom extensions`,
  },
  pdfPng: {
    id: `update-subscription.gold.features.pdf-png`,
    defaultMessage: `PDF and PNG reports`,
  },
  // Platinum
  machineLearning: {
    id: `update-subscription.platinum.features.machine-learning`,
    defaultMessage: `Machine learning with anomaly detection and forecasting`,
  },
  graph: {
    id: `update-subscription.platinum.features.graph`,
    defaultMessage: `Graph exploration and analytics`,
  },
  // Enterprise
  searchableSnapshots: {
    id: `update-subscription.enterprise.searchable-snapshots`,
    defaultMessage: `Searchable snapshots`,
  },
  searchableColdAndFrozenTiers: {
    id: 'update-subscription.enterprise.searchable-frozen-tiers',
    defaultMessage: 'Support for searchable cold and frozen tiers',
  },
  elasticMapsServer: {
    id: 'update-subscription.enterprise.maps-server',
    defaultMessage: 'Elastic Maps Server',
  },
  everythingInPlatinum: {
    id: `update-subscription.enterprise.everything-in-platinum`,
    defaultMessage: `Everything in Platinum, plus`,
  },
  everythingInStandard: {
    id: `update-subscription.enterprise.everything-in-standard`,
    defaultMessage: `Everything in Standard, plus`,
  },
  everythingInGold: {
    id: `update-subscription.enterprise.everything-in-gold`,
    defaultMessage: `Everything in Gold, plus`,
  },
  recommended: {
    id: `update-subscription.recommended`,
    defaultMessage: `Recommended`,
  },
  pricingPage: {
    id: `update-subscription.pricing-page`,
    defaultMessage: `See our {pricingPage}.`,
  },
  from: {
    id: `update-subscription.price-from`,
    defaultMessage: `from`,
  },
  cancel: {
    id: `update-subscription.cancel-update`,
    defaultMessage: `Cancel`,
  },
  save: {
    id: `update-subscription.save-update`,
    defaultMessage: `Save subscription`,
  },
  usageWarning: {
    id: `update-subscription.usage-warning`,
    defaultMessage: `You are using {usageLevel} features that must be removed before you can change your subscription level. {seeRequiredChanges}.`,
  },
  seeRequiredChanges: {
    id: `update-subscription.usage-warning.see-changes`,
    defaultMessage: `See required changes`,
  },
  confirmSubscriptionTitle: {
    id: `confirm-subscription.title`,
    defaultMessage: `Change to {selectedSubscription}`,
  },
  confirmSubscriptionCancel: {
    id: `confirm-subscription.cancel`,
    defaultMessage: `No thanks `,
  },
  confirmSubscriptionConfirm: {
    id: `confirm-subscription.confirm`,
    defaultMessage: `Yes, update subscription `,
  },
  upgradeConfirmSubscriptionDescription: {
    id: `confirm-upgrade-subscription.description`,
    defaultMessage: `Your subscription and benefits increase immediately. If you change to a lower subscription
    later, it will not take affect until the next billing cycle. Do you want to continue?`,
  },
  firstBillingCycleConfirmSubscriptionDescription: {
    id: `first-billing-cycle-update.description`,
    defaultMessage: `Your subscription and benefits change immediately. Do you want to continue?`,
  },
  downgradeConfirmSubscriptionDescription: {
    id: `confirm-downgrade-subscription.description`,
    defaultMessage: `Your subscription change begins with the next billing cycle. {subscriptionLevels} features are not available with the selected subscription. Do you still want to continue?`,
  },
  usageDetailsErrorMessage: {
    id: `usage-details-error.message`,
    defaultMessage: `There was a problem with the request`,
  },
})

export const billingSubscriptions = (formatMessage): Subscription[] => [
  {
    value: 'standard',
    prettyName: formatMessage(messages.standard),
    supportName: formatMessage(messages.standardSupportName),
    supportFeatures: [formatMessage(messages.standardSupport)],
    serverlessFeatures: [formatMessage(messages.serverlessAbilityFeature)],
    hostedFeatures: [
      formatMessage(messages.coreFeatures),
      formatMessage(messages.capabilities),
      formatMessage(messages.canvasAndLens),
    ],
  },
  {
    value: 'gold',
    prettyName: formatMessage(messages.gold),
    supportName: formatMessage(messages.goldSupportName),
    supportFeatures: [formatMessage(messages.goldSupport)],
    serverlessFeatures: [formatMessage(messages.serverlessAbilityFeature)],
    hostedDescription: formatMessage(messages.everythingInStandard),
    hostedFeatures: [
      formatMessage(messages.configurableMonitoring),
      formatMessage(messages.customExtensions),
      formatMessage(messages.pdfPng),
    ],
  },
  {
    value: 'platinum',
    prettyName: formatMessage(messages.platinum),
    supportName: formatMessage(messages.platinumSupportName),
    supportFeatures: [formatMessage(messages.platinumSupport)],
    serverlessFeatures: [formatMessage(messages.serverlessAbilityFeature)],
    hostedDescription: formatMessage(messages.everythingInGold),
    hostedFeatures: [formatMessage(messages.machineLearning), formatMessage(messages.graph)],
  },
  {
    value: 'enterprise',
    prettyName: formatMessage(messages.enterprise),
    supportName: formatMessage(messages.enterpriseSupportName),
    supportFeatures: [formatMessage(messages.enterpriseSupport)],
    serverlessFeatures: [formatMessage(messages.serverlessAbilityFeature)],
    hostedDescription: formatMessage(messages.everythingInPlatinum),
    hostedFeatures: [
      formatMessage(messages.searchableSnapshots),
      formatMessage(messages.searchableColdAndFrozenTiers),
      formatMessage(messages.elasticMapsServer),
    ],
    recommended: true,
  },
]

export default messages
