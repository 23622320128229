/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

import type { X509CertificateInfo } from '@modules/security-idp-lib/types'
import { getRemainingDaysToExpiry } from '@modules/security-idp-lib'

export const messages = defineMessages({
  certIsValidInNDays: {
    id: 'organization.security.user-authentication.certificate-expiry-days',
    defaultMessage: 'The certificate will expire {days, plural, =1 {today} other {in # days}}.',
  },
  certExpired: {
    id: 'organization.security.user-authentication.certificate-expired',
    defaultMessage: 'The certificate has expired. Check the validity period.',
  },
  certNotYetValid: {
    id: 'organization.security.user-authentication.certificate-not-yet-valid',
    defaultMessage: 'The certificate is not valid yet. Check the validity period.',
  },
  certInvalid: {
    id: 'organization.security.user-authentication.certificate-invalid',
    defaultMessage: `Can't read the certificate. Check that it is valid and complete.`,
  },
  showConfig: {
    id: `organization.security.user-authentication.show-config`,
    defaultMessage: `Show configuration`,
  },
  hideConfig: {
    id: `organization.security.user-authentication.hide-config`,
    defaultMessage: `Hide configuration`,
  },
})

export const getCertExpiryInfoMessage = (x509CertificateInfo: X509CertificateInfo | undefined) => {
  if (x509CertificateInfo === undefined) {
    return messages.certInvalid
  }

  const remainingDaysToCertExpiry = getRemainingDaysToExpiry(x509CertificateInfo)

  if (remainingDaysToCertExpiry === null) {
    return messages.certNotYetValid
  }

  if (typeof remainingDaysToCertExpiry === 'number' && remainingDaysToCertExpiry <= 0) {
    return messages.certExpired
  }

  return messages.certIsValidInNDays
}

export const getToggleConfigMessage = (isConfigExpanded) =>
  isConfigExpanded ? messages.hideConfig : messages.showConfig
