/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { ElasticsearchCluster, StackDeployment } from '@modules/ui-types'

import DeploymentSecurityEditor from '../../../../components/DeploymentSecurity/DeploymentSecurityEditor'
import { gte } from '../../../../lib/semver'

import UserconsoleDeploymentSecurityEditorBefore5x from './UserconsoleDeploymentSecurityEditorBefore5x'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment | null
  cluster?: ElasticsearchCluster | null
}

const DeploymentSecurity: FunctionComponent<Props> = ({ deployment, cluster }) => {
  if (deployment === null) {
    return null
  }

  if (cluster == null) {
    return null
  }

  const { plan } = cluster

  if (!plan.isActive) {
    return null
  }

  if (plan.version && gte(plan.version, `5.0.0`)) {
    return <DeploymentSecurityEditor deployment={deployment} cluster={cluster} />
  }

  return <UserconsoleDeploymentSecurityEditorBefore5x />
}

export default DeploymentSecurity
