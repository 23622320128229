/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const editFormMessages = defineMessages({
  requiredField: {
    id: `organization.security.edit-user-authentication.required-field`,
    defaultMessage: `This field is required.`,
  },
  invalidUrl: {
    id: `organization.security.edit-user-authentication.invalid-url`,
    defaultMessage: `This value must be a valid URL with a protocol (http:// or https://).`,
  },
  notInPemFormat: {
    id: `organization.security.edit-user-authentication.not-in-pem-format`,
    defaultMessage: `The certificate must be in PEM format.`,
  },
  invalidLoginIdentifierPrefix: {
    id: `organization.security.edit-user-authentication.invalid-login-identifier-prefix`,
    defaultMessage: `The login identifier prefix must be 1 to 25 characters long, begin and end with alphanumeric characters, and contain only lowercase alphanumeric characters and hyphens.`,
  },
})

export const updateIdpConfigErrorMessages = defineMessages({
  'org.idp.change_with_enforcement_enabled': {
    id: `organization.security.edit-user-authentication.error-change-with-enforcement-enabled`,
    defaultMessage: `SSO enforcement is currently enabled on your organization. Changing the identity provider config while enforcement is enabled has the risk blocking access to your account if the IdP is misconfigured. Please disable SSO enforcement before making changes to the IdP configuration.`,
  },
  'org.idp.no_domain_claim': {
    id: `organization.security.edit-user-authentication.error-no-domain-claim`,
    defaultMessage: `You must claim ownership of at least one domain before registering an identity provider.`,
  },
  'org.idp.login_identifier_conflict': {
    id: `organization.security.edit-user-authentication.error-invalid-certificate`,
    defaultMessage: `Conflict with existing org with same login identifier. Please try again or choose a different login identifier prefix.`,
  },
  unknownError: {
    id: `organization.security.edit-user-authentication.error-unknown`,
    defaultMessage: `Unknown error updating authentication settings. Changes were not applied. Try again.`,
  },
})
