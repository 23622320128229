/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'query-string'
import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { withStackDeploymentRouteParams } from '@/components/StackDeploymentEditor'
import { updateStackDeploymentRequest } from '@/reducers/asyncRequests/registry'
import { getTrustedEnvs } from '@/reducers/trustedEnvs'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { getConfigForKey } from '@/selectors'
import { fetchTrustedEnvs } from '@/actions/trustManagement'

import { updateDeployment, resetUpdateDeployment } from '../../../actions/stackDeployments'

import ManageDirectTrustRelationship from './ManageDirectTrustRelationship'

import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type { StateProps, DispatchProps, ReceivedProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { stackDeploymentId, location }: ReceivedProps & WithStackDeploymentRouteParamsProps,
): StateProps => {
  const query = parse(location.search)
  const deployment = getStackDeployment(state, stackDeploymentId)!

  return {
    previouslyUsedEnv:
      typeof query.previouslyUsedEnv === 'string' ? query.previouslyUsedEnv : undefined,
    trustedEnvs: getTrustedEnvs(state),
    deployment,
    isEce: getConfigForKey(state, `APP_PLATFORM`) === `ece`,
    updateStackDeploymentRequest: updateStackDeploymentRequest(state, stackDeploymentId),
    authMechanism: query.auth as string | undefined,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { stackDeploymentId }: ReceivedProps & WithStackDeploymentRouteParamsProps,
): DispatchProps => ({
  updateStackDeployment: (payload) =>
    dispatch(
      updateDeployment({ deploymentId: stackDeploymentId, deployment: payload, redirect: false }),
    ),
  resetUpdateStackDeployment: () => dispatch(resetUpdateDeployment(stackDeploymentId!)),
  fetchTrustedEnvs: () => dispatch(fetchTrustedEnvs()),
})

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ReceivedProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(ManageDirectTrustRelationship),
)
