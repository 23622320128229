/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect, useState } from 'react'
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk' // eslint-disable-line no-restricted-imports

import Feature from '@modules/utils/feature'
import { makeLaunchDarklyContext } from '@modules/launchdarkly'
import { useProfile } from '@modules/profile-lib/hooks'
import type { MiddlewareComponent } from '@modules/app/types'
import ProfileProvider from '@modules/profile-lib/ProfileProvider'

import { getConfigForKey } from '@/store'
import AppLoadingRoot from '@/components/AppLoadingRoot'

/**
 * We make the distinction between any initialization (LD is ready to consume)
 * and "complete" initialization (LD has been sent all the information we want
 * to send it, i.e. org ID). Ideally this is done in one execution of the
 * useEffect function below, but if the user doesn't have an associated org at
 * the time of the first initialization, we want to update the LD association
 * when one becomes associated.
 */
type Initialized = null | 'pending-org' | 'complete'

// We split this into two parts because the `useLDClient` hook is predicated on being wrapped by `LDProvider`.
export const LaunchDarklyInitializer: React.FunctionComponent = ({ children }) => {
  const launchDarklyId = getConfigForKey(Feature.launchdarklyId)

  const profile = useProfile()
  const ldClient = useLDClient()

  const [isInitialized, setIsInitialized] = useState<Initialized>(
    launchDarklyId ? null : 'complete',
  )

  useEffect(() => {
    async function initializeLaunchDarkly() {
      // pass through silently if LD is not enabled in the config -- probably
      // redundant with the useState initializer, as the config is static, but
      // better safe than sorry
      if (!launchDarklyId) {
        setIsInitialized('complete')
        return
      }

      // ensure dependencies are ready
      if (!profile || !ldClient) {
        return
      }

      // bail if we have "complete" initialization, with an org ID attached
      if (isInitialized === 'complete') {
        return
      }

      // bail if we're waiting for an org ID and still don't have it
      if (isInitialized === 'pending-org' && !profile.organization_id) {
        return
      }

      try {
        await ldClient.waitForInitialization(5)
        await ldClient.identify(makeLaunchDarklyContext(profile), undefined, () =>
          setIsInitialized(profile.organization_id ? 'complete' : 'pending-org'),
        )
      } catch (_error) {
        setIsInitialized('complete')
      }
    }

    initializeLaunchDarkly()
  }, [launchDarklyId, profile, ldClient, isInitialized])

  if (!isInitialized) {
    return <AppLoadingRoot />
  }

  return <Fragment>{children}</Fragment>
}

const LaunchDarklyMiddleware: MiddlewareComponent = ({ children }) => {
  const launchDarklyId = getConfigForKey(Feature.launchdarklyId)

  if (!launchDarklyId) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <LDProvider clientSideID={launchDarklyId}>
      <LaunchDarklyInitializer>{children}</LaunchDarklyInitializer>
    </LDProvider>
  )
}

LaunchDarklyMiddleware.middleware = {
  displayName: 'LaunchDarklyMiddleware',
  dependencies: [ProfileProvider],
}

export default LaunchDarklyMiddleware
