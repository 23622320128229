/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { EuiModal } from '@elastic/eui'

import { useEnhancedUserProfile } from '@modules/profile-lib/hooks'

import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'

import TrialSummaryModalContent from './TrialSummaryModalContent'
import { useDeploymentTemplateFromDeployment, useTrialDeployment } from './hooks'

import type { FC } from 'react'

type Props = {
  closeModal: () => void
}

const StatefulTrialSummaryModal: FC<Props> = ({ closeModal }) => {
  const profile = useEnhancedUserProfile()

  const { data: trialDeployment, isLoading: isLoadingTrialDeployment } = useTrialDeployment()

  const { data: deploymentTemplates, isLoading: isLoadingDeploymentTemplate } =
    useDeploymentTemplateFromDeployment(trialDeployment)

  const templateId = trialDeployment && getDeploymentTemplateId({ deployment: trialDeployment })

  const deploymentTemplate = deploymentTemplates?.find((template) => template.id === templateId)

  return (
    <EuiModal
      onClose={closeModal}
      css={css({ width: 675, padding: 20 })}
      data-test-id='trial-summary-modal'
    >
      <TrialSummaryModalContent
        isFetchingData={isLoadingTrialDeployment || isLoadingDeploymentTemplate}
        closeModal={closeModal}
        profile={profile}
        deployment={trialDeployment}
        deploymentTemplate={deploymentTemplate ?? null}
      />
    </EuiModal>
  )
}

export default StatefulTrialSummaryModal
