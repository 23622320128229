/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AllocatorInfo, MetadataItem } from '@modules/cloud-api/v1/types'
import type { Allocator, RegionId } from '@modules/ui-types'

import { ADD_ALLOCATOR_TAG, FETCH_ALLOCATOR, REMOVE_ALLOCATOR_TAG } from '@/constants/actions'
import { replaceIn } from '@/lib/immutability-helpers'

import createAllocator from './createAllocator'

import type { AsyncAction, ReduxState } from '@/types/redux'

export interface State {
  [descriptor: string]: Allocator
}

interface FetchAction extends AsyncAction<typeof FETCH_ALLOCATOR, AllocatorInfo> {
  meta: {
    regionId: string
    ip: string
    selfUrl: string
  }
}

interface AddTagAction extends AsyncAction<typeof ADD_ALLOCATOR_TAG, MetadataItem[]> {
  meta: {
    regionId: RegionId
    ip: string
    id: string
  }
}

interface RemoveTagAction extends AsyncAction<typeof REMOVE_ALLOCATOR_TAG, MetadataItem[]> {
  meta: {
    regionId: RegionId
    ip: string
    id: string
  }
}

export type Action = FetchAction | AddTagAction | RemoveTagAction

const initialState: State = {}

function createDescriptor(regionId: RegionId, allocatorId: string): string {
  return `${regionId}/${allocatorId}`
}

export default function allocatorsReducer(state = initialState, action: Action): State {
  if (
    action.type !== FETCH_ALLOCATOR &&
    action.type !== ADD_ALLOCATOR_TAG &&
    action.type !== REMOVE_ALLOCATOR_TAG
  ) {
    return state
  }

  if (action.error || !action.payload) {
    return state
  }

  const { regionId, ip } = action.meta
  const descriptor = createDescriptor(regionId, ip)

  if (action.type === FETCH_ALLOCATOR) {
    return {
      ...state,
      [descriptor]: createAllocator(regionId, ip, action.meta.selfUrl, action.payload),
    }
  }

  const currentAllocatorState = state[descriptor]

  if (!currentAllocatorState) {
    return state
  }

  return {
    ...state,
    [descriptor]: replaceIn(currentAllocatorState, ['tags'], action.payload),
  }
}

export function _getAllocator(
  state: State | undefined,
  regionId: RegionId,
  allocatorId: string,
): Allocator | undefined {
  return state?.[createDescriptor(regionId, allocatorId)]
}

export const getAllocator = (state: ReduxState, regionId: RegionId, allocatorId: string) =>
  _getAllocator(state.allocators, regionId, allocatorId)
