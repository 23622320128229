/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPopover,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { projectsUrl } from '@modules/project-user-lib/urls'
import { useToggle } from '@modules/utils/hooks/useToggle'
import { CuiRouterLink } from '@modules/cui/RouterButton'

import type { FunctionComponent } from 'react'
import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
}

const ProjectsPortalPanelTitle: FunctionComponent<Props> = ({ intl }) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  const { formatMessage } = intl

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
      <EuiFlexItem grow={0}>
        <CuiRouterLink
          to={projectsUrl()}
          color='text'
          data-test-id='portal-ess-title-link'
          className='cloud-portal-tile-title'
        >
          <EuiTitle size='xs'>
            <h2>
              <FormattedMessage
                id='project.portalPanel.title'
                defaultMessage='Serverless projects'
              />
            </h2>
          </EuiTitle>
        </CuiRouterLink>
      </EuiFlexItem>
      <EuiFlexItem grow={0}>
        <EuiPopover
          button={
            <EuiButtonIcon
              iconType='questionInCircle'
              onClick={togglePopover}
              aria-label={formatMessage({
                id: 'project.portalPanel.title.info',
                defaultMessage: 'more information about projects',
              })}
            />
          }
          anchorPosition='upCenter'
          isOpen={isPopoverOpen}
          closePopover={() => setPopoverOpen(false)}
        >
          <EuiText style={{ width: 300 }} size='s'>
            <p>
              <FormattedMessage
                id='project.portalPanel.title-popover'
                defaultMessage='Serverless projects focus on one Elastic solution at a time. You can choose between Elasticsearch, Observability, and Security. Projects decouple indexing and search, allowing great flexibility, performance, and stability. They are fully managed by Elastic and autoscale to your workloads. You pay based on your usage. <link>Learn more</link>'
                values={{
                  link: (content) => (
                    <EuiLink href='https://ela.st/serverless-learn-more' target='_blank'>
                      {content}
                    </EuiLink>
                  ),
                }}
              />
            </p>
          </EuiText>
        </EuiPopover>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default injectIntl(ProjectsPortalPanelTitle)
