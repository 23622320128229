/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import securityRealms from './securityRealms'
import securityCluster from './securityCluster'
import activeDirectoryAuthProviders from './activeDirectoryAuthProviders'
import ldapAuthProviders from './ldapAuthProviders'
import samlAuthProviders from './samlAuthProviders'
import runnerSearch from './runnerSearch'
import searchUsers from './searchUsers'

import type { State as SecurityRealmsState } from './securityRealms'
import type { State as SecurityClusterState } from './securityCluster'
import type { State as ActiveDirectoryAuthProvidersState } from './activeDirectoryAuthProviders'
import type { State as LdapAuthProvidersState } from './ldapAuthProviders'
import type { State as SamlAuthProvidersState } from './samlAuthProviders'
import type { State as RunnerSearchState } from './runnerSearch'
import type { State as SearchUsersState } from './searchUsers'
import type { Action } from '@/types/redux'

/** Represents all the available adminconsole-specific Redux state */
export type AdminconsoleState = {
  activeDirectoryAuthProviders: ActiveDirectoryAuthProvidersState
  ldapAuthProviders: LdapAuthProvidersState
  runnerSearch: RunnerSearchState
  samlAuthProviders: SamlAuthProvidersState
  securityRealms: SecurityRealmsState
  securityCluster: SecurityClusterState
  searchUsers: SearchUsersState
}

// A type that ensures that we have an appropriate reducer for each key in our
// combined state type.
type AdminconsoleReducer = {
  [T in keyof AdminconsoleState]: (
    state: AdminconsoleState[T] | undefined,
    action: Action<any>,
  ) => AdminconsoleState[T]
}

const adminconsoleReducer: AdminconsoleReducer = {
  activeDirectoryAuthProviders,
  ldapAuthProviders,
  runnerSearch,
  samlAuthProviders,
  securityRealms,
  securityCluster,
  searchUsers,
}

export default adminconsoleReducer
