/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SecurityDeployment } from '@modules/cloud-api/v1/types'
import type { RegionId } from '@modules/ui-types'

import { FETCH_SECURITY_CLUSTER } from '../constants/actions'

import type { AdminconsoleState } from '.'
import type { AsyncAction } from '@/types/redux'

export type State = {
  [regionId: string]: SecurityDeployment
}

interface FetchSecurityClusterAction
  extends AsyncAction<typeof FETCH_SECURITY_CLUSTER, SecurityDeployment> {
  meta: { regionId: RegionId }
}

export default function reducer(state: State = {}, action: FetchSecurityClusterAction): State {
  switch (action.type) {
    case FETCH_SECURITY_CLUSTER:
      if (action.error && (action.payload as any).response?.status === 404) {
        const newState = { ...state }
        delete newState[action.meta.regionId]
        return newState
      } else if (!action.error && action.payload) {
        return {
          ...state,
          [action.meta.regionId]: action.payload,
        }
      }

      return state

    default:
      return state
  }
}

const _getSecurityCluster = (state: State, regionId: RegionId) => state[regionId]

export const getSecurityCluster = (state: AdminconsoleState, regionId: RegionId) =>
  _getSecurityCluster(state.securityCluster, regionId)
