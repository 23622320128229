/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect, useState } from 'react'

import type React from 'react'
import type { z } from 'zod'

/**
 * A local storage persistence hook that validates against a supplied zod schema.
 */
export const useTypedLocalStorage = <TSchema extends z.ZodTypeAny, TData extends z.infer<TSchema>>(
  storageKey: string,
  schema: TSchema,
  initialValue: TData,
) => {
  function getFromLocalStorage() {
    let jsonValue: any

    try {
      const rawValue = localStorage.getItem(storageKey)
      jsonValue = rawValue == null ? null : JSON.parse(rawValue)
    } catch {
      jsonValue = null
    }

    const parsedValue = schema.safeParse(jsonValue ?? {})
    return parsedValue.success ? (parsedValue.data as TData) : undefined
  }

  // initialize from local storage if present and valid; otherwise, initial value
  const [value, setValue] = useState<TData>(getFromLocalStorage() ?? initialValue)

  // persist to local storage as a side-effect
  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return <[TData, React.Dispatch<React.SetStateAction<TData>>]>[value, setValue]
}
