/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEqual, mapValues } from 'lodash'

import { useGetUserProfileQuery } from '@modules/profile-lib/hooks'
import type { AuthzCheck, AuthzResult, Scope } from '@modules/cloud-api/v1/types'
import { useConfig } from '@modules/cui/ConfigContext'

import {
  type Behavior,
  type NonEmptyPermissionsArray,
  type PermissionToCheck,
  type PermissionsCheckResult,
  type PermissionsBatch,
  type PermissionsBatchResults,
  isPlatformOwnedPermissionCheck,
  isResourceInstancePermissionCheck,
} from '../types'

import useCheckAuthorizationQuery from './useCheckAuthorizationQuery'

const usePermissions = (
  permissionsToCheck: NonEmptyPermissionsArray,
  behavior: Behavior = 'every',
): PermissionsCheckResult => {
  const { isOrganizationIdFetched, organizationId } = useOrganizationId()

  const checks = makeAuthorizationChecks({
    defaultOrganizationId: organizationId,
    permissionsToCheck,
  })

  const checkAuthorizationQuery = useCheckAuthorizationQuery({
    enabled: isOrganizationIdFetched,
    checks,
  })

  return checkPermissions(checkAuthorizationQuery.data, behavior)
}

export const usePermissionsBatch = <PermissionKey extends string>(
  permissionsBatch: PermissionsBatch<PermissionKey>,
): PermissionsBatchResults<PermissionKey> => {
  const { isOrganizationIdFetched, organizationId } = useOrganizationId()

  const authzChecks: Record<PermissionKey, { checks: AuthzCheck[]; behavior: Behavior }> =
    mapValues(permissionsBatch, ({ permissionsToCheck, behavior }) => {
      const checks = makeAuthorizationChecks({
        defaultOrganizationId: organizationId,
        permissionsToCheck,
      })

      return {
        checks,
        behavior: behavior ?? 'every',
      }
    })

  const flatAuthzChecks = Object.values(authzChecks).flatMap(({ checks }) => checks)

  const { data } = useCheckAuthorizationQuery({
    enabled: isOrganizationIdFetched,
    checks: flatAuthzChecks,
  })

  if (data === undefined) {
    // query is still loading return false to all results
    const results = mapValues(authzChecks, () => false)
    return { isLoading: true, ...results }
  }

  const results: Record<PermissionKey, boolean> = mapValues(authzChecks, ({ checks, behavior }) => {
    // maps permission keys to their respective hasPermissions boolean result
    const matchedResults = data.filter(({ check }) =>
      checks.some((authzCheck) => isEqual(check, authzCheck)),
    )

    return checkPermissions(matchedResults, behavior).hasPermissions
  })

  return {
    isLoading: false,
    ...results,
  }
}

export const useOrganizationId = (): {
  isOrganizationIdFetched: boolean
  organizationId: string | undefined
} => {
  const isAdminconsole = useConfig('APP_NAME') === 'adminconsole'
  const profileQuery = useGetUserProfileQuery({ enabled: !isAdminconsole })
  const isProfileFetched = profileQuery.data !== undefined

  if (isAdminconsole) {
    return {
      isOrganizationIdFetched: true,
      organizationId: undefined,
    }
  }

  return {
    isOrganizationIdFetched: isProfileFetched,
    organizationId: profileQuery.data?.user.organization_id,
  }
}

export const makeAuthorizationChecks = ({
  defaultOrganizationId,
  permissionsToCheck,
}: {
  defaultOrganizationId: string | undefined
  permissionsToCheck: NonEmptyPermissionsArray
}): AuthzCheck[] =>
  permissionsToCheck.map((permissionToCheck) => {
    const owner: Scope | undefined = getOwner(defaultOrganizationId, permissionToCheck)
    const instance: Scope | undefined = getInstance(permissionToCheck)

    return {
      ...(owner !== undefined && { owner }),
      kind: permissionToCheck.type,
      action: permissionToCheck.action,
      ...(instance !== undefined && { instance }),
    }
  })

export const checkPermissions = (
  results: AuthzResult[] | undefined,
  behavior: Behavior,
): PermissionsCheckResult => {
  if (results === undefined) {
    return {
      hasPermissions: false,
      isLoading: true,
    }
  }

  return {
    hasPermissions: results.length > 0 && results[behavior](({ ok }) => ok),
    isLoading: false,
  }
}

const getOwner = (
  defaultOrganizationId: string | undefined,
  permissionToCheck: PermissionToCheck,
): Scope | undefined => {
  // platform-owned permission checks should not pass a owner property
  if (isPlatformOwnedPermissionCheck(permissionToCheck)) {
    return undefined
  }

  // if permissionCheck has no organizationId default to defaultOrganizationId.
  const organizationId = permissionToCheck.organizationId ?? defaultOrganizationId

  // no organizationId was passed and user does not belong to any organization, in this case,
  // check if user has permission for all organizations (usually a platform admin).
  if (organizationId === undefined) {
    return { all: true }
  }

  return { id: organizationId }
}

const getInstance = (permissionToCheck: PermissionToCheck): Scope | undefined => {
  if (isResourceInstancePermissionCheck(permissionToCheck)) {
    return permissionToCheck.id === '*' ? { all: true } : { id: permissionToCheck.id }
  }

  return undefined
}

export default usePermissions
