/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getAllRoleAssignments } from '@modules/role-assignments-lib'
import type {
  NormalisedResourceRoleAssignment,
  ResourceQueryResult,
  ResourceType,
} from '@modules/role-assignments-lib/types'
import { useGetOrganizationDeploymentsAndProjectsQuery } from '@modules/role-assignments-admin-components/ProjectRoleAssignmentsSummary/lib'
import { useGetUserDeploymentsAndProjectsQuery } from '@modules/role-assignments-user-components/ProjectRoleAssignmentsSummary/lib'
import { useConfig } from '@modules/cui/ConfigContext'

import type { OverriddenRoleAndNewRole, OverridingRole } from '../../lib/types'
import type { DeploymentOrProject } from '../types'

export const getOverridingRoleInfo = ({
  addedRoleAssignments,
  removedRoleAssignments,
  userResourceList,
  roleAssignments,
}: {
  addedRoleAssignments: NormalisedResourceRoleAssignment[]
  removedRoleAssignments: NormalisedResourceRoleAssignment[]
  userResourceList: DeploymentOrProject[]
  roleAssignments: NormalisedResourceRoleAssignment[]
}):
  | {
      overriddenRoles: OverriddenRoleAndNewRole[]
      overridingAllRoles: OverridingRole[]
    }
  | undefined => {
  const addedAllRoleAssignments = getAllRoleAssignments(addedRoleAssignments)

  const overridingNoneRoles: OverridingRole[] = []

  const overriddenByAllOrNoneRoles = removedRoleAssignments.filter((role) => {
    const isRoleOverriddenByNoneRole = !roleAssignments.some(
      (assignedRole) => assignedRole.resourceType === role.resourceType,
    )
    return (
      isRoleOverriddenByAllRoles({ addedAllRoleAssignments, role }) || isRoleOverriddenByNoneRole
    )
  })

  const overriddenRoles: OverriddenRoleAndNewRole[] = formatOverriddenRoles({
    overriddenRoles: overriddenByAllOrNoneRoles,
    userResourceList,
    addedAllRoleAssignments,
    overridingNoneRoles,
  })

  if (overriddenRoles.length === 0) {
    return undefined
  }

  const overridingAllRoles: OverridingRole[] = addedAllRoleAssignments
    .filter((role) =>
      overriddenRoles.some((overriddenRole) => role.resourceType === overriddenRole.resourceType),
    )
    .map((role) => {
      const { role_id: roleId, resourceType } = role

      return {
        roleId,
        resourceType,
      } as OverridingRole
    })
    .concat(overridingNoneRoles)

  return {
    overriddenRoles,
    overridingAllRoles,
  }
}

const updateOverridingNoneRole = (
  resourceType: ResourceType,
  overridingNoneRoles: OverridingRole[],
): OverridingRole => {
  const existingOverridingNonRole = overridingNoneRoles.find(
    (overridingNoneRole) => overridingNoneRole.resourceType === resourceType,
  )

  if (existingOverridingNonRole) {
    return existingOverridingNonRole
  }

  const newNoneRole = {
    resourceType,
    roleId: null,
  }
  overridingNoneRoles.push(newNoneRole)

  return newNoneRole
}

export const isRoleOverriddenByAllRoles = ({
  addedAllRoleAssignments,
  role,
}: {
  addedAllRoleAssignments: NormalisedResourceRoleAssignment[]
  role: NormalisedResourceRoleAssignment
}): boolean => {
  const sameResourceOverridingAllRoles = addedAllRoleAssignments.filter(
    (addedRole) => addedRole.resourceType === role.resourceType,
  )

  if (!sameResourceOverridingAllRoles.length) {
    return false
  }

  return !sameResourceOverridingAllRoles.some(
    (addedAllRole) =>
      addedAllRole.role_id === role.role_id &&
      role.application_roles?.every((el) => addedAllRole.application_roles?.includes(el)),
  )
}

export const formatOverriddenRoles = ({
  overriddenRoles,
  userResourceList,
  addedAllRoleAssignments,
  overridingNoneRoles,
}: {
  overriddenRoles: NormalisedResourceRoleAssignment[]
  userResourceList: DeploymentOrProject[]
  addedAllRoleAssignments: NormalisedResourceRoleAssignment[]
  overridingNoneRoles: any[]
}): OverriddenRoleAndNewRole[] =>
  overriddenRoles
    .flatMap((role) => {
      const { role_id: roleId, resourceType, ids: resourceIds = [] } = role

      return resourceIds.map((resourceId) => {
        const userResourceName = userResourceList.find(
          (resource) => resource.id === resourceId,
        )?.name

        if (!userResourceName) {
          return
        }

        const newRoleId =
          addedAllRoleAssignments.find((addedRole) => addedRole.resourceType === resourceType)
            ?.role_id ||
          // if there is no new added roles, it means that it is overridden by a none role
          updateOverridingNoneRole(resourceType as ResourceType, overridingNoneRoles).roleId

        return {
          resourceId,
          resourceName: userResourceName,
          newRoleId,
          resourceType: resourceType as ResourceType,
          roleId,
        }
      })
    })
    .filter((role) => role?.resourceName) as OverriddenRoleAndNewRole[]

export const useGetDeploymentsAndProjectsQuery = (
  organizationId: string = '',
): ResourceQueryResult[] => {
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  const organizationDeploymentsAndProjects = useGetOrganizationDeploymentsAndProjectsQuery(
    organizationId,
    { enabled: isAdminConsole },
  )

  const userDeploymentsAndProjects = useGetUserDeploymentsAndProjectsQuery({
    enabled: !isAdminConsole,
  })

  return isAdminConsole ? organizationDeploymentsAndProjects : userDeploymentsAndProjects
}
