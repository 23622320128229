/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import history from '@modules/utils/history'

// eslint-disable-next-line import/no-restricted-paths
import { billingUsageUrl } from '@/apps/userconsole/urls'

type Props = {
  closeModal: () => void
}

const Pricing: React.FunctionComponent<Props> = ({ closeModal }) => (
  <Fragment>
    <EuiTitle size='xs'>
      <h2>
        <FormattedMessage id='trialSummary.pricingTitle' defaultMessage='Understand pricing' />
      </h2>
    </EuiTitle>
    <EuiSpacer size='s' />
    <EuiLink
      href={billingUsageUrl()}
      onClick={(e) => {
        e.preventDefault()
        history.push(billingUsageUrl())
        closeModal()
      }}
    >
      <FormattedMessage id='trialSummary.pricingLink' defaultMessage='View your current usage' />
    </EuiLink>
    <EuiSpacer size='s' />
    <EuiText size='xs' color='subdued'>
      <FormattedMessage
        id='trialSummary.pricingLinkDescription'
        defaultMessage='See how pricing works based on your current trial usage.'
      />
    </EuiText>
  </Fragment>
)

export default Pricing
