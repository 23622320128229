/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import CreateStackDeploymentEditorDependencies from '../../../../components/StackDeploymentEditor/CreateStackDeploymentEditorDependencies'

import StackDeploymentPricingEditor from './StackDeploymentPricingEditor'

import type { CreateEditorComponentConsumerProps } from '../../../../components/StackDeploymentEditor/types'
import type { FunctionComponent } from 'react'

type Props = unknown

const StackDeploymentPricing: FunctionComponent<Props> = () => (
  <CreateStackDeploymentEditorDependencies
    createDeploymentFromTemplateProps={{
      inTrial: false,
      smallestSize: true,
    }}
  >
    {(props: CreateEditorComponentConsumerProps) => <StackDeploymentPricingEditor {...props} />}
  </CreateStackDeploymentEditorDependencies>
)

export default StackDeploymentPricing
