/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCopy,
  EuiContextMenuItem,
  EuiFieldText,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiTextColor,
  EuiButtonIcon,
  EuiCallOut,
  EuiFormRow,
  EuiToolTip,
} from '@elastic/eui'

import genericMessages from '@modules/project-lib/genericMessages'
import usePermissions from '@modules/permissions-lib/hooks'
import type { AnyProject } from '@modules/project-api/types'

export interface DeleteProjectButtonProps {
  project: AnyProject
  onDelete: () => unknown
  onComplete: () => void
  isLoading: boolean
  isDisabled?: boolean
}

const DeleteProjectButton: React.FunctionComponent<DeleteProjectButtonProps> = ({
  project,
  onDelete,
  onComplete,
  isLoading,
  isDisabled: isDisabledByInvoker,
}) => {
  const { hasPermissions: hasDeletePermission } = usePermissions([
    { type: `project-${project.type}`, action: 'delete', id: project.id },
  ])
  const [isConfirming, setIsConfirming] = useState(false)
  const [pristine, setPristine] = useState(true)
  const [enteredProjectName, setEnteredProjectName] = useState<string>()
  const { formatMessage } = useIntl()

  const isNameConfirmed = enteredProjectName?.trim() === project.name.trim()
  const isDisabled = isDisabledByInvoker || !hasDeletePermission
  const color = isDisabled ? 'disabled' : 'danger'

  const submitDelete = useCallback(() => {
    setPristine(false)

    if (isNameConfirmed) {
      onDelete()
    }
  }, [isNameConfirmed, onDelete])

  useEffect(() => {
    setPristine(true)
  }, [isConfirming])

  useEffect(() => {
    if (enteredProjectName !== undefined) {
      setPristine(false)
    }
  }, [enteredProjectName])

  return (
    <Fragment>
      {renderButton()}
      {isConfirming && renderModal()}
    </Fragment>
  )

  function renderButton() {
    return (
      <EuiContextMenuItem
        key='delete'
        icon={<EuiIcon type='trash' size='m' color={color} />}
        onClick={openModal}
        disabled={isDisabled}
      >
        <EuiTextColor color={color}>
          <FormattedMessage id='project.delete.button' defaultMessage='Delete project' />
        </EuiTextColor>
      </EuiContextMenuItem>
    )
  }

  function renderModal() {
    const copyToClipboardMessage = formatMessage({
      id: 'project.copyToClipboard.body',
      defaultMessage: 'Copy project name to clipboard',
    })

    return (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} role='alertdialog'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='project.delete.title'
                defaultMessage='Delete {name} project?'
                values={{
                  name: project.name,
                }}
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody style={{ minHeight: '180px' }}>
            <EuiCallOut
              heading='p'
              size='s'
              title={
                <FormattedMessage
                  id='project.delete.confirmation.callout'
                  defaultMessage='This action cannot be undone and permanently deletes the {name} and all related with that project data'
                  values={{
                    name: (
                      <EuiTextColor color='accent'>
                        {project.name}{' '}
                        <EuiCopy textToCopy={project.name}>
                          {(copy) => (
                            <EuiToolTip content={copyToClipboardMessage}>
                              <EuiButtonIcon
                                aria-label={copyToClipboardMessage}
                                iconType='copy'
                                color='accent'
                                size='xs'
                                onClick={copy}
                              />
                            </EuiToolTip>
                          )}
                        </EuiCopy>
                      </EuiTextColor>
                    ),
                  }}
                />
              }
              color='warning'
              iconType='warning'
            />
            <EuiFormRow
              hasEmptyLabelSpace={true}
              label={
                <FormattedMessage
                  id='project.delete.label'
                  defaultMessage='Type the project name to confirm'
                />
              }
              error={
                <FormattedMessage
                  id='project.delete.error'
                  defaultMessage='The project name you typed does not match {name}'
                  values={{
                    name: <strong>{project.name}</strong>,
                  }}
                />
              }
              isInvalid={!pristine && !isNameConfirmed}
              fullWidth={true}
            >
              <EuiFieldText
                name='confirmation-input'
                placeholder={project.name}
                value={enteredProjectName ?? ''}
                onChange={(e) => setEnteredProjectName(e.target.value)}
                isInvalid={!pristine && !isNameConfirmed}
                fullWidth={true}
              />
            </EuiFormRow>
          </EuiModalBody>

          <EuiModalFooter>
            {!isLoading && (
              <EuiButtonEmpty onClick={closeModal}>
                <FormattedMessage {...genericMessages.cancel} />
              </EuiButtonEmpty>
            )}

            <EuiButton onClick={submitDelete} fill={true} color='danger' isLoading={isLoading}>
              <FormattedMessage
                {...(isLoading ? genericMessages.deleting : genericMessages.delete)}
              />
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  function openModal() {
    setIsConfirming(true)
  }

  function closeModal() {
    setIsConfirming(false)
    setEnteredProjectName(undefined)
    onComplete()
  }
}

export default DeleteProjectButton
