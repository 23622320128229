/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'

import type { DeploymentMetadata } from '@modules/cloud-api/v1/types'
import type { SaasUserDomain, UserProfile, UserSubscription } from '@modules/ui-types'

import { awsBillingUrl, azureBillingUrl, gcpBillingUrl } from '@/apps/userconsole/urls'
import { getDeploymentTags } from '@/lib/stackDeployments/selectors/metadata'

export const isAWSUser = (profile: UserProfile): boolean => profile.domain === 'aws'
export const isGCPUser = (profile: UserProfile): boolean => profile.domain === 'gcp'

export const isAzureUser = (profile: UserProfile): boolean => profile.domain === 'azure'

export const isAzurePlusUser = (profile: UserProfile): boolean => {
  const { integrated_marketplace_account } = profile
  return isAzureUser(profile) && integrated_marketplace_account
}

export const isMarketPlaceUser = (profile: UserProfile): boolean =>
  isAWSUser(profile) || isGCPUser(profile) || isAzureUser(profile)

export const isUnSubscribedMarketPlaceUser = (profile: UserProfile): boolean => {
  const { aws_subscribed, gcp_subscribed, azure_subscribed } = profile
  return (
    (isAWSUser(profile) && !aws_subscribed) ||
    (isGCPUser(profile) && !gcp_subscribed) ||
    (isAzureUser(profile) && !azure_subscribed)
  )
}

export const isRejectedUsageMarketPlaceUser = (profile: UserProfile): boolean => {
  const { azure_subscribed, marketplace_attributes } = profile

  return (
    isAzureUser(profile) &&
    !azure_subscribed &&
    Boolean(marketplace_attributes?.azure_usage_event_rejected_reason)
  )
}

export function isIntegratedAzurePlusDeployment(deployment: {
  metadata?: DeploymentMetadata
}): boolean {
  // is azure++ deployment managed by ms
  const tags = getDeploymentTags({ deployment })

  if (isEmpty(tags)) {
    return false
  }

  return tags.some(({ key }) => key === 'azure_subscription_id')
}

type MarketplaceDisplayName = 'AWS' | 'GCP' | 'Azure'

export function marketplaceDisplayName(domain: SaasUserDomain): MarketplaceDisplayName {
  return {
    aws: 'AWS',
    gcp: 'GCP',
    azure: 'Azure',
  }[domain]
}

export function marketplaceConsoleLink(domain: SaasUserDomain): string {
  return {
    aws: awsBillingUrl,
    gcp: gcpBillingUrl,
    azure: azureBillingUrl,
  }[domain]
}

export const isMarketplaceSubscription = (
  subscription: string,
): subscription is NonNullable<UserSubscription> =>
  subscription === `aws` || subscription === `gcp` || subscription === `azure`

// GCP and azure don't have a proper trial,
// but in some cases we want to show trial feature, like discovery questions, to new users
export function isMarketplacePseudoTrial(profile: UserProfile): boolean {
  if (!isGCPUser(profile) && !isAzureUser(profile)) {
    return false
  }

  const trial = new Date(profile.created)
  const today = new Date()
  trial.setDate(trial.getDate() + 15)

  return trial.toISOString() > today.toISOString()
}

export function isMarketplaceTrial(profile: UserProfile | null): boolean {
  // TODO remove once we have the new billing API for user types https://github.com/elastic/cloud/issues/93095

  if (!profile) {
    return false
  }

  if (!isMarketplaceSubscription(profile.domain)) {
    return false
  }

  if (!profile.currentTrial) {
    return false
  }

  return profile.is_trial && new Date() < new Date(profile.currentTrial.end)
}
