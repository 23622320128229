/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import type { EuiBasicTableColumn } from '@elastic/eui'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiInMemoryTable,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui'

import { useDeleteDomainClaim } from '@modules/security-idp-lib/hooks'
import { addToast } from '@modules/cui/Toasts'

import { messages } from './messages'

import type { Props, DomainClaim } from './types'
import type { FunctionComponent } from 'react'

const DomainClaimTable: FunctionComponent<Props> = ({
  organizationId,
  isLoading,
  domains = [],
  hasUpdateDomainClaimPermission,
  readonly,
}) => {
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null)
  const { euiTheme } = useEuiTheme()
  const { formatMessage } = useIntl()
  const { mutate: removeDomain, isLoading: isRemovingDomain } = useDeleteDomainClaim()

  const onRemoveDomain = () => {
    if (!selectedDomain || !organizationId) {
      return
    }

    removeDomain(
      { organizationId, domainName: selectedDomain },
      {
        onSuccess: () => {
          addToast({
            family: 'organization.security.domain-claims-table.remove',
            color: 'success',
            iconType: 'check',
            title: (
              <FormattedMessage
                id='organization.security.domain-claims-table.remove.success'
                defaultMessage='Domain {domainName} has been removed.'
                values={{ domainName: selectedDomain }}
              />
            ),
          })
          setSelectedDomain(null)
        },
      },
    )
  }

  const columns: Array<EuiBasicTableColumn<DomainClaim>> = [
    {
      field: 'domainName',
      name: (
        <FormattedMessage
          id='organization.security.domain-claims-table.domain-name-label'
          defaultMessage='Domain name'
        />
      ),
      width: '80%',
      render: (domainName: string) => (
        <EuiText size='s' color={euiTheme.colors.primaryText}>
          {domainName}
        </EuiText>
      ),
    },
  ]

  if (!readonly) {
    columns.push({
      field: 'domainName',
      name: (
        <FormattedMessage
          id='organization.security.domain-claims-table.actions-label'
          defaultMessage='Actions'
        />
      ),
      align: 'right',
      render: (domainName: string) => (
        <EuiButtonIcon
          iconType='trash'
          aria-label={formatMessage(messages.removeDomain)}
          color='danger'
          disabled={!hasUpdateDomainClaimPermission}
          onClick={() => {
            setSelectedDomain(domainName)
          }}
        />
      ),
    })
  }

  return (
    <React.Fragment>
      <EuiInMemoryTable
        loading={isLoading || isRemovingDomain}
        message={
          (isLoading && formatMessage(messages.loadingDomains)) ||
          (domains?.length === 0 && formatMessage(messages.noDomains))
        }
        columns={columns}
        items={domains.map((domain) => ({ domainName: domain }))}
      />
      {selectedDomain && (
        <ConfirmRemoveDomainModal
          domainName={selectedDomain}
          isLoading={isRemovingDomain}
          onRemoveDomain={onRemoveDomain}
          onClose={() => setSelectedDomain(null)}
        />
      )}
    </React.Fragment>
  )
}

const ConfirmRemoveDomainModal = ({ domainName, onRemoveDomain, onClose, isLoading }) => {
  const { formatMessage } = useIntl()
  return (
    <EuiModal onClose={onClose} maxWidth='600px'>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <FormattedMessage
            id='organization.security.domain-claims-table.remove-domain-modal.title'
            defaultMessage='Remove domain?'
          />
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>
          <FormattedMessage
            id='organization.security.domain-claims-table.remove-domain-modal.description'
            defaultMessage='This action will remove {domainName}. Users coming from that domain will no longer be able to access this organization.'
            values={{ domainName: <strong>{domainName}</strong> }}
          />
        </EuiText>
        <EuiSpacer size='l' />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiButtonEmpty onClick={onClose}>
            <FormattedMessage
              id='organization.security.domain-claims-table.remove-domain.cancel-button'
              defaultMessage='Cancel'
            />
          </EuiButtonEmpty>
          <EuiButton
            color='danger'
            fill={true}
            isLoading={isLoading}
            onClick={onRemoveDomain}
            aria-label={formatMessage(messages.removeDomainConfirmation)}
          >
            <FormattedMessage
              id='organization.security.domain-claims-table.remove-domain.remove-domain-button'
              defaultMessage='Remove domain'
            />
          </EuiButton>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default DomainClaimTable
