/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { sumBy } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { Tooltip } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

const ChartTooltip = () => (
  <Tooltip
    footer={({ items }) => {
      const total = sumBy(items, 'value')

      return (
        <EuiFlexGroup>
          <EuiFlexItem>
            <FormattedMessage id='costs-chart-tooltip-total' defaultMessage='Total' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{total.toFixed(4)}</EuiFlexItem>
        </EuiFlexGroup>
      )
    }}
  />
)

export default ChartTooltip
