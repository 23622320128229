/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiConfirmModal, EuiIcon, useEuiTheme } from '@elastic/eui'

import { roleBadgeLabels } from '@modules/role-assignments-lib/messages'
import { resourceRoleLabelByRoleId } from '@modules/role-assignments-lib/types'
import { resourceIconMap } from '@modules/role-assignments-lib'

import { messages } from './messages'

import type { FunctionComponent } from 'react'
import type { OverridingAllRoleProps, AllProps as Props } from './types'

const RoleAssignmentConfirmationModal: FunctionComponent<Props> = ({
  overridingAllRoles,
  onCloseModal,
  onConfirmRoleAssignmentChange,
}) => (
  <EuiConfirmModal
    maxWidth={'600px'}
    buttonColor='danger'
    defaultFocusedButton='confirm'
    title={<FormattedMessage {...messages.modal.title} />}
    cancelButtonText={<FormattedMessage {...messages.modal.cancel} />}
    confirmButtonText={<FormattedMessage {...messages.modal.confirm} />}
    onCancel={onCloseModal}
    onConfirm={onConfirmRoleAssignmentChange}
  >
    <p>
      <FormattedMessage {...messages.modal.opening} />
    </p>
    <p>
      <FormattedMessage {...messages.modal.newRoleAssignment} />
    </p>
    <div>
      {overridingAllRoles.map((role) => {
        const { resourceType, roleId } = role
        return (
          <OverridingAllRole
            resourceType={resourceType}
            roleId={roleId}
            key={`${resourceType}-${roleId}`}
          />
        )
      })}
    </div>
  </EuiConfirmModal>
)

const OverridingAllRole: FunctionComponent<OverridingAllRoleProps> = ({ resourceType, roleId }) => {
  const { euiTheme } = useEuiTheme()
  return (
    <div>
      <EuiIcon type={resourceIconMap[resourceType]} />
      &nbsp;
      <span>
        <FormattedMessage
          {...messages.modal.connectingRoleAndResource}
          values={{
            assignedRole: roleId ? (
              <span style={{ fontWeight: euiTheme.font.weight.semiBold }}>
                <FormattedMessage {...roleBadgeLabels[resourceRoleLabelByRoleId[roleId]]} />
              </span>
            ) : null,
            resourceTypes: <FormattedMessage {...messages.modal[resourceType]} />,
          }}
        />
      </span>
    </div>
  )
}

export default injectIntl(RoleAssignmentConfirmationModal)
