/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEqual } from 'lodash'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiComboBox,
  EuiFormRow,
  EuiSkeletonText,
  EuiCallOut,
  EuiLoadingSpinner,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'

import type { AllProps } from './types'

interface State {
  domains: string[]
  saveClicked: boolean
}

class AllowedDomains extends Component<AllProps> {
  state: State = {
    domains: [],
    saveClicked: false,
  }

  componentDidMount() {
    const { fetchOrganizationIfNeeded, organization } = this.props

    if (!organization) {
      fetchOrganizationIfNeeded()
      return
    }

    if (organization.notifications_allowed_email_domains) {
      this.setState({ domains: organization.notifications_allowed_email_domains })
    }
  }

  componentDidUpdate(prevProps) {
    const { organization } = this.props

    if (prevProps.organization == null && organization != null) {
      if (organization.notifications_allowed_email_domains) {
        this.setState({ domains: organization.notifications_allowed_email_domains })
      }
    }
  }

  componentWillUnmount() {
    this.props.resetUpdateOrganizationRequest()
  }

  render() {
    const { fetchOrganizationRequest, organization, updateOrganizationRequest } = this.props

    if (fetchOrganizationRequest.inProgress || organization == null) {
      return <EuiSkeletonText />
    }

    const selectedOptions = this.state.domains.map((email) => ({
      label: email,
    }))

    const isSaveDisabled = isEqual(
      organization.notifications_allowed_email_domains,
      this.state.domains,
    )

    return (
      <Fragment>
        <EuiFormRow
          label={
            <FormattedMessage
              id='uc.accountDetails.allowedDomains.flyout.allowedDomainsFormRow'
              defaultMessage='Allowed domains'
            />
          }
          helpText={
            <FormattedMessage
              id='uc.accountDetails.allowedDomains.flyout.help'
              defaultMessage='Add multiple allowed domains separated with a space or by pressing Enter.'
            />
          }
        >
          <EuiComboBox
            noSuggestions={true}
            compressed={false}
            delimiter=' '
            isClearable={false}
            selectedOptions={selectedOptions}
            onChange={this.onChange}
            onCreateOption={this.onAddDomain}
          />
        </EuiFormRow>

        <EuiSpacer size='s' />

        {this.state.saveClicked && updateOrganizationRequest.inProgress && (
          <Fragment>
            <EuiLoadingSpinner />
            <EuiSpacer size='s' />
          </Fragment>
        )}

        {this.state.saveClicked &&
          updateOrganizationRequest.isDone &&
          !updateOrganizationRequest.error && (
            <Fragment>
              <EuiCallOut color='success'>
                <FormattedMessage
                  id='uc.accountDetails.allowedDomains.flyout.success'
                  defaultMessage='The list of allowed domains is updated. Run a configuration change on your deployments to apply the new allowlist.'
                />
              </EuiCallOut>
              <EuiSpacer size='s' />
            </Fragment>
          )}

        {updateOrganizationRequest.error && (
          <Fragment>
            <CuiAlert type='danger'>{updateOrganizationRequest.error}</CuiAlert>
            <EuiSpacer size='s' />
          </Fragment>
        )}

        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={this.onSave} isDisabled={isSaveDisabled} color='primary'>
              <FormattedMessage
                id='uc.accountDetails.allowedDomains.flyout.save'
                defaultMessage='Save'
              />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  onAddDomain = (domain: string): void => {
    this.setState({
      domains: this.state.domains.concat(domain),
    })
  }

  onChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    this.setState({
      domains: options.map(({ label }) => label),
    })
  }

  onSave = (): void => {
    const { updateOrganization } = this.props

    if (this.state.domains.length === 0) {
      // @ts-ignore TS wants this to be either undefined or a string array, but the API allows for a `null` value which is required to set this field to
      // not enabled. API isn't able to make the TS allow null, so ignoring it
      updateOrganization({ notifications_allowed_email_domains: null })
    } else {
      updateOrganization({ notifications_allowed_email_domains: this.state.domains })
    }

    this.setState({ saveClicked: true })
  }
}

export default AllowedDomains
