/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonIcon,
  EuiCallOut,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import { CuiAlert } from '@modules/cui/Alert'
import type {
  DeploymentUpdateRequest,
  SnapshotDependencies,
  SnapshotDependency,
} from '@modules/cloud-api/v1/types'

import { DeploymentName } from '@/components/DeploymentName'
import DangerButton from '@/components/DangerButton'
import DocLink from '@/components/DocLink'
import {
  createUpdateRequestFromGetResponse,
  sanitizeUpdateRequestBeforeSend,
} from '@/lib/stackDeployments/updates'

import type { AllProps } from '.'

class SnapshotDeploymentConfiguration extends Component<AllProps> {
  componentDidMount() {
    const { fetchConfig } = this.props
    fetchConfig()
  }

  render() {
    const { snapshotDeploymentConfiguration, fetchConfigRequest } = this.props

    if (snapshotDeploymentConfiguration == null && !fetchConfigRequest.error) {
      return null
    }

    if (fetchConfigRequest.error) {
      return (
        <Fragment>
          <EuiSpacer />
          <EuiCallOut
            data-test-id='snapshot-deployment-configuration-fetch-error'
            title={
              <FormattedMessage
                id='snapshot-deployment-configuration-fetch-error.title'
                defaultMessage='Failed to fetch some snapshot information'
              />
            }
            color='danger'
          >
            <FormattedMessage
              id='snapshot-deployment-configuration-fetch-error.description'
              defaultMessage='We were unable to fetch information about any snapshot dependencies for this deployment. Please let us know via support.'
            />
          </EuiCallOut>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {this.renderAzureMigrateSnapshotBucket()}
        {this.renderAwsGcpMigrateSnapshotBucket()}
        {this.renderDependecies()}
      </Fragment>
    )
  }

  renderAzureMigrateSnapshotBucket() {
    const { snapshotDeploymentConfiguration, updateDeploymentRequest } = this.props

    // we literally check for null in the outer function
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const resource = snapshotDeploymentConfiguration!.resource

    if (resource == null || !resource.can_migrate_to_bucket_per_deployment) {
      return null
    }

    // show a callout to the user about migrating to an individual bucket for this deployment and give the button to do it
    return (
      <Fragment>
        <EuiSpacer />
        <EuiCallOut
          color='warning'
          title={
            <FormattedMessage
              id='snapshot-deployment-azure-migrate.title'
              defaultMessage='Action required: Enable dedicated snapshot repository'
            />
          }
          iconType='warning'
        >
          <EuiText>
            <p>
              <FormattedMessage
                id='snapshot-deployment-azure-migrate.description-1'
                defaultMessage='This deployment currently stores its snapshots in the same repository as other deployments of your organization within the same region. Improve security by storing future snapshots in a repository dedicated to this deployment. {learnMore}'
                values={{
                  learnMore: (
                    <DocLink link='azureDedicatedRepo'>
                      <FormattedMessage
                        id='snapshot-deployment-azure-migrate.learn-more'
                        defaultMessage='Learn more'
                      />
                    </DocLink>
                  ),
                }}
              />
            </p>
          </EuiText>
          <EuiSpacer size='s' />
          <DangerButton
            onConfirm={() => this.migrateBucket()}
            data-test-id='snapshot-deployment-azure-migrate-button'
            color='warning'
            fill={true}
            modal={{
              title: (
                <FormattedMessage
                  id='snapshot-deployment-azure-migrate.confirm-modal-title'
                  defaultMessage='Enable dedicated snapshot repository for this deployment?'
                />
              ),
              body: (
                <Fragment>
                  <FormattedMessage
                    id='snapshot-deployment-azure-migrate.confirm-modal-description'
                    defaultMessage='By enabling a dedicated snapshot repository now, the following configuration changes will be applied to your deployment:'
                  />
                  <ul>
                    <li>
                      <FormattedMessage
                        id='snapshot-deployment-azure-migrate.list-item-1'
                        defaultMessage="A new repository will be automatically created, where you'll find all future snapshots of this deployment."
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id='snapshot-deployment-azure-migrate.list-item-2'
                        defaultMessage='A first snapshot will be taken immediately and added to the new repository.'
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id='snapshot-deployment-azure-migrate.list-item-3'
                        defaultMessage='Previous snapshots will remain available in the shared repository.'
                      />
                    </li>
                  </ul>
                </Fragment>
              ),
              confirmButtonText: (
                <FormattedMessage
                  id='snapshot-deployment-azure-migrate.confirm-modal-button'
                  defaultMessage='Enable dedicated snapshot repository'
                />
              ),
            }}
          >
            <FormattedMessage
              id='snapshot-deployment-azure-migrate.button'
              defaultMessage='Enable dedicated repository'
            />
          </DangerButton>
          {updateDeploymentRequest.inProgress && (
            <Fragment>
              <EuiSpacer size='s' />
              <EuiLoadingSpinner />
            </Fragment>
          )}
          {updateDeploymentRequest.error && (
            <Fragment>
              <EuiSpacer size='s' />
              <CuiAlert type='error'>{updateDeploymentRequest.error}</CuiAlert>
            </Fragment>
          )}
        </EuiCallOut>
      </Fragment>
    )
  }

  renderAwsGcpMigrateSnapshotBucket() {
    const { snapshotDeploymentConfiguration, updateDeploymentRequest } = this.props
    const resource = snapshotDeploymentConfiguration?.resource

    if (resource == null || !resource.can_migrate_to_account_user) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer />
        <EuiCallOut
          color='warning'
          title={
            <FormattedMessage
              id='snapshot-deployment-migrate-aws-gcp.title'
              defaultMessage='Security action required: Enable snapshot repository isolation'
            />
          }
          iconType='warning'
        >
          <EuiText>
            <p>
              <FormattedMessage
                id='snapshot-deployment-migrate-aws-gcp.description-1'
                defaultMessage='This deployment currently has access to snapshots of other deployments in your organization. Improve security by restricting this deployment from accessing snapshots of other deployments in your organization. {learnMore}'
                values={{
                  learnMore: (
                    <DocLink link='snapshotRepositoryMigrationAwsGcp'>
                      <FormattedMessage
                        id='snapshot-deployment-migrate-aws-gcp.learn-more'
                        defaultMessage='Learn more'
                      />
                    </DocLink>
                  ),
                }}
              />
            </p>
          </EuiText>
          <EuiSpacer size='s' />
          <DangerButton
            onConfirm={() => this.migrateBucket()}
            data-test-id='snapshot-deployment-migrate-aws-gcp-button'
            color='warning'
            fill={true}
            modal={{
              title: (
                <FormattedMessage
                  id='snapshot-deployment-migrate-aws-gcp.confirm-modal-title'
                  defaultMessage='Enable snapshot repository isolation?'
                />
              ),
              body: (
                <FormattedMessage
                  id='snapshot-deployment-migrate-aws-gcp.confirm-modal-description'
                  defaultMessage='A configuration change will be applied to this deployment: Your Elastic snapshot repository will receive new credentials with limited access to only the snapshots of this deployment.'
                />
              ),
              confirmButtonText: (
                <FormattedMessage
                  id='snapshot-deployment-migrate-aws-gcp.confirm-modal-button'
                  defaultMessage='Enable snapshot repository isolation'
                />
              ),
            }}
          >
            <FormattedMessage
              id='snapshot-deployment-migrate-aws-gcp.button'
              defaultMessage='Enable snapshot repository isolation'
            />
          </DangerButton>
          {updateDeploymentRequest.inProgress && (
            <Fragment>
              <EuiSpacer size='s' />
              <EuiLoadingSpinner />
            </Fragment>
          )}
          {updateDeploymentRequest.error && (
            <Fragment>
              <EuiSpacer size='s' />
              <CuiAlert type='error'>{updateDeploymentRequest.error}</CuiAlert>
            </Fragment>
          )}
        </EuiCallOut>
      </Fragment>
    )
  }

  renderDependecies() {
    const { snapshotDeploymentConfiguration } = this.props

    // we literally check for null in the outer function
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dependencies = snapshotDeploymentConfiguration!.dependencies

    if (dependencies.resources.length === 0) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='xxl' />
        <EuiTitle size='s' data-test-id='snapshot-deployment-dependencies-title'>
          <h2>
            <FormattedMessage
              id='snapshot-deployment-dependency.title'
              defaultMessage='Snapshot repositories of other deployments'
            />
          </h2>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiText size='s'>
          <FormattedMessage
            id='snapshot-deployment-dependency.explanation'
            defaultMessage='This deployment was created by cloning from the snapshot repository of another deployment. Therefore, this deployment
            still has access to the snapshots taken by the original deployment. If you wish to remove this access, you can do so by clicking the trash
            icon below. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='searchableSnapshotsDepedencyDocLink'>
                  <FormattedMessage
                    id='snapshot-deployment-dependency.learnmore'
                    defaultMessage='Learn more'
                  />
                </DocLink>
              ),
            }}
          />
        </EuiText>
        <EuiSpacer size='m' />
        <EuiPanel>{this.renderDependenciesContent(dependencies)}</EuiPanel>
      </Fragment>
    )
  }

  renderDependenciesContent(dependencies: SnapshotDependencies) {
    const { fetchConfigRequest, deleteDepRequest, snapshotDeploymentConfiguration } = this.props

    if (fetchConfigRequest.inProgress || snapshotDeploymentConfiguration == null) {
      return <EuiLoadingSpinner />
    }

    const columns = [
      {
        label: (
          <FormattedMessage
            id='snapshot-deployment-dependency.table.deployment'
            defaultMessage='Deployment'
          />
        ),
        render: (dep: SnapshotDependency) => (
          <DeploymentName
            dataTestId={`snapshot-deployment-dependencies-dep-${dep.cluster_id}`}
            deployment={{
              id: dep.deployment_id ?? '',
              name: dep.deployment_name ?? `Terminated cluster (${dep.cluster_id})`,
              healthy: true,
            }}
            showHealth={false}
            linkify={dep.deployment_id !== undefined}
          />
        ),
      },
      {
        label: (
          <FormattedMessage
            id='snapshot-deployment-dependency.table.remove-access'
            defaultMessage='Remove Access'
          />
        ),
        render: (dep: SnapshotDependency) => {
          if (deleteDepRequest.inProgress) {
            return <EuiLoadingSpinner />
          }

          if (deleteDepRequest.error) {
            return (
              <CuiAlert type='error' data-test-id='snapshot-deployment-dependencies-delete-error'>
                {deleteDepRequest.error}
              </CuiAlert>
            )
          }

          return (
            <DangerButton
              disabled={false}
              buttonType={EuiButtonIcon}
              buttonProps={{ color: `danger` }}
              isEmpty={false}
              iconType='trash'
              aria-label='remove'
              data-test-id={`snapshot-deployment-dependencies-remove-access-${dep.cluster_id}`}
              modal={{
                title: (
                  <FormattedMessage
                    id='snapshot-deployment-dependency.table.remove-access.confirm-title'
                    defaultMessage='Remove repository access?'
                  />
                ),
                body: (
                  <FormattedMessage
                    id='snapshot-deployment-dependency.table.remove-access.confirm-body'
                    defaultMessage='Are you sure you want to remove access to the snapshot repository of the source deployment? Do not remove
                    access if you are using searchable snapshots from the source deployment. {learnMore}'
                    values={{
                      learnMore: (
                        <DocLink link='searchableSnapshotsDepedencyDocLink'>
                          <FormattedMessage
                            id='snapshot-deployment-dependency.learnmore'
                            defaultMessage='Learn more'
                          />
                        </DocLink>
                      ),
                    }}
                  />
                ),
                confirmButtonText: (
                  <FormattedMessage
                    id='snapshot-deployment-dependency.table.remove-access.confirm-button'
                    defaultMessage='Yes, remove access'
                  />
                ),
              }}
              onConfirm={() => this.removeAccess(dep)}
            />
          )
        },
      },
    ]

    return <CuiTable rows={dependencies.resources} columns={columns} />
  }

  migrateBucket() {
    const { updateDeployment, deployment, deploymentTemplate } = this.props

    const updateDeploymentObj: DeploymentUpdateRequest = createUpdateRequestFromGetResponse({
      deployment,
      deploymentTemplate,
    })

    if (updateDeploymentObj.resources && updateDeploymentObj.resources.elasticsearch) {
      updateDeploymentObj.resources.elasticsearch.map((esCluster) => {
        esCluster.plan.transient = {
          ...esCluster.plan.transient,
          plan_configuration: {
            force_bucket_management_migration: true,
          },
        }
      })

      updateDeployment({
        deploymentId: deployment.id,
        deployment: sanitizeUpdateRequestBeforeSend({ deployment: updateDeploymentObj }),
        redirect: true,
        dryRun: false,
      })
    }
  }

  removeAccess(sourceDeployment: SnapshotDependency) {
    const { deleteDep } = this.props

    deleteDep(sourceDeployment.cluster_id).then(() => {
      // fetch the deps again to update the list
      this.props.fetchConfig()
    })
  }
}

export default SnapshotDeploymentConfiguration
