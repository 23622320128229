/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState, useMemo, Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  useGeneratedHtmlId,
  EuiPanel,
  EuiRange,
  EuiTitle,
  EuiText,
  EuiButtonGroup,
} from '@elastic/eui'

import { useConfig } from '@modules/cui/ConfigContext'
import type { Price } from '@modules/billing-api/v1/types'

import PriceList from '../PriceList'
import { getSecurityPrices } from '../utils'

import {
  filterPricesByTier,
  calculatePricesBasedOnBoostDays,
  calculatePricesBasedOnSearchPower,
} from './utils'
import {
  DEFAULT_SEARCH_POWER,
  SearchPowerTicks,
  DEFAULT_BOOST_DAYS,
  TierOptions,
  DEFAULT_TIER,
} from './constants'

interface SecurityTabProps {
  prices: Price[]
  isLoading: boolean
  addonList: JSX.Element
}

const SecurityTab = ({ prices, isLoading, addonList }: SecurityTabProps) => {
  const [searchPowerTick, setSearchPowerTick] = useState<number>(DEFAULT_SEARCH_POWER)
  const [boostDaysTick, setBoostDaysTick] = useState<number>(DEFAULT_BOOST_DAYS)
  const [tier, setTier] = useState<Price['tier']>(DEFAULT_TIER)
  const htmlId = useGeneratedHtmlId()
  const { formatMessage } = useIntl()
  const showPricingFilters = useConfig('FEATURE_SHOW_PRICING_FILTERS')
  const rawSecurityPrices = getSecurityPrices(prices)

  // TODO The method of calculating the final prices will change.
  const filteredPrices = useMemo(
    (): Price[] =>
      calculatePricesBasedOnSearchPower(
        calculatePricesBasedOnBoostDays(filterPricesByTier(rawSecurityPrices, tier), boostDaysTick),
        searchPowerTick,
      ),
    [rawSecurityPrices, tier, boostDaysTick, searchPowerTick],
  )

  const finalPrices = showPricingFilters ? filteredPrices : rawSecurityPrices

  const renderTier = () => (
    <Fragment>
      <EuiTitle size='xxxs'>
        <h3>
          <FormattedMessage id='pricing-list.security.tier.title' defaultMessage='Tier' />
        </h3>
      </EuiTitle>

      <EuiSpacer size='m' />

      <EuiButtonGroup
        legend={formatMessage({
          id: 'pricing-list.security.tier.tier-selection',
          defaultMessage: 'Tier Selection',
        })}
        options={TierOptions}
        idSelected={tier}
        onChange={(selectedTier: Price['tier']) => setTier(selectedTier)}
        color='primary'
        isFullWidth={true}
      />
    </Fragment>
  )

  const renderBoostDays = () => (
    <Fragment>
      <EuiTitle size='xxxs'>
        <h3>
          <FormattedMessage
            id='pricing-list.security.boost-days.title'
            defaultMessage='Boost days'
          />
        </h3>
      </EuiTitle>

      <EuiSpacer size='s' />

      <EuiText size='xs'>
        <FormattedMessage
          id='pricing-list.security.boost-days.description'
          defaultMessage='Determines how much data can benefit from faster search by combining this setting with your daily ingestion rate.'
        />
      </EuiText>

      <EuiSpacer size='m' />

      <EuiRange
        isLoading={isLoading}
        id={htmlId}
        value={boostDaysTick}
        onChange={(e) => setBoostDaysTick(+e.currentTarget.value)}
        min={2}
        max={14}
        tickInterval={2}
        fullWidth={true}
        showInput={false}
        showRange={true}
        showTicks={true}
        levels={[
          {
            min: 2,
            max: 14,
            color: 'primary',
          },
        ]}
      />
    </Fragment>
  )

  const renderSearchPower = () => (
    <Fragment>
      <EuiTitle size='xxxs'>
        <h5>
          <FormattedMessage
            id='pricing-list.security.search-power.title'
            defaultMessage='Search Power'
          />
        </h5>
      </EuiTitle>

      <EuiSpacer size='s' />

      <EuiText size='xs'>
        <FormattedMessage
          id='pricing-list.security.search-power.description'
          defaultMessage='Controls how fast search are against your project data.'
        />
      </EuiText>

      <EuiSpacer size='m' />

      <EuiRange
        isLoading={isLoading}
        id={htmlId}
        value={searchPowerTick}
        onChange={(e) => setSearchPowerTick(+e.currentTarget.value)}
        min={1}
        max={3}
        step={1}
        fullWidth={true}
        showInput={false}
        showRange={true}
        showTicks={true}
        ticks={SearchPowerTicks.map(({ value, messageDescriptor }) => ({
          label: formatMessage(messageDescriptor),
          value,
          accessibleLabel: formatMessage(messageDescriptor),
        }))}
        levels={[
          {
            min: 1,
            max: 3,
            color: 'primary',
          },
        ]}
      />
    </Fragment>
  )

  const renderFilters = () => (
    <EuiPanel
      grow={false}
      paddingSize='l'
      hasShadow={false}
      hasBorder={true}
      data-test-id='security-filters'
    >
      {renderTier()}
      <EuiHorizontalRule />
      <EuiText size='xs'>
        <FormattedMessage
          id='pricing-list.security.filters.description'
          defaultMessage='These options determine the amount of compute resources allocated to your project.'
        />
      </EuiText>
      <EuiSpacer size='xl' />
      {renderBoostDays()}
      <EuiSpacer size='xl' />
      {renderSearchPower()}
    </EuiPanel>
  )

  return (
    <EuiFlexGroup direction='rowReverse'>
      {showPricingFilters && <EuiFlexItem grow={3}>{renderFilters()}</EuiFlexItem>}
      <EuiFlexItem grow={7}>
        <PriceList prices={finalPrices} isLoading={isLoading} />
        <EuiSpacer />
        <EuiHorizontalRule margin='xxl' />
        {addonList}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default SecurityTab
