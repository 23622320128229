/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiRouterLinkButton } from '@modules/cui/RouterButton'
import { resourceCreationManagedUrl } from '@modules/project-creation-lib/urls'

import { createDeploymentUrl } from '../../../../lib/urlBuilder'
import CustomerOnlyAction from '../../CustomerOnlyAction'

import type { FunctionComponent } from 'react'

const TrialNotStarted: FunctionComponent = () => (
  <div className='cost-analysis-trial-not-started' data-test-id='cost-analysis-trial-not-started'>
    <EuiFlexGrid columns={3} gutterSize='l'>
      <EuiFlexItem>
        <EuiPanel paddingSize='m' hasBorder={true}>
          <EuiTitle size='xs'>
            <h2>
              <FormattedMessage
                id='cost-analysis.trial-not-started.no-deployments'
                defaultMessage='Get started with Elastic Cloud'
              />
            </h2>
          </EuiTitle>

          <EuiSpacer size='m' />

          <EuiText color='subdued' size='s'>
            <FormattedMessage
              id='cost-analysis.start-trial-info'
              defaultMessage='Start your trial by creating your first deployment or project.'
            />
          </EuiText>

          <EuiSpacer size='m' />

          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem grow={false}>
              <CustomerOnlyAction
                message={
                  <FormattedMessage
                    id='cost-analysis.trial-not-started.create-deployment-disabled-help'
                    defaultMessage='Elastic administrators cannot create deployments on behalf of customers.'
                  />
                }
              >
                <CuiRouterLinkButton
                  data-test-id='cost-analysis.trial-not-started.create-deployment'
                  to={createDeploymentUrl()}
                  fill={true}
                >
                  <FormattedMessage
                    id='cost-analysis.trial-not-started.create-deployment'
                    defaultMessage='Create deployment'
                  />
                </CuiRouterLinkButton>
              </CustomerOnlyAction>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <CustomerOnlyAction
                message={
                  <FormattedMessage
                    id='cost-analysis.trial-not-started.create-project-disabled-help'
                    defaultMessage='Elastic administrators cannot create projects on behalf of customers.'
                  />
                }
              >
                <CuiRouterLinkButton
                  data-test-id='cost-analysis.trial-not-started.create-project'
                  to={resourceCreationManagedUrl()}
                >
                  <FormattedMessage
                    id='cost-analysis.trial-not-started.create-project'
                    defaultMessage='Create project'
                  />
                </CuiRouterLinkButton>
              </CustomerOnlyAction>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGrid>
  </div>
)

export default TrialNotStarted
