/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { EsProxyResponse } from '@modules/ui-types'

import { QUERY_DEPLOYMENT_PROXY, CLEAR_DEPLOYMENT_PROXY_RESPONSE } from '../../constants/actions'
import { replaceIn } from '../../lib/immutability-helpers'

import type { ReduxState } from '@/types/redux'

export interface State {
  [descriptor: string]: EsProxyResponse
}

const initialState: State = {}

export default function deploymentProxyReducer(state: State = initialState, action) {
  if (action.type === QUERY_DEPLOYMENT_PROXY) {
    if (action.meta.state === `success`) {
      const { deploymentId } = action.meta
      const descriptor = createDescriptor(deploymentId)

      return {
        ...state,
        [descriptor]: action.payload,
      }
    }

    if (action.meta.state === `failed`) {
      const { deploymentId } = action.meta
      const descriptor = createDescriptor(deploymentId)

      return {
        ...state,
        [descriptor]: {
          status: action.payload.response.status,
          statusText: action.payload.response.statusText,
          body: action.payload.body,
          contentType: `json`,
        },
      }
    }
  }

  if (action.type === CLEAR_DEPLOYMENT_PROXY_RESPONSE) {
    const { deploymentId } = action.meta
    const descriptor = createDescriptor(deploymentId)

    return replaceIn(state, [descriptor], undefined)
  }

  return state
}

function _getDeploymentProxyResponse(state: State, deploymentId: string): EsProxyResponse | null {
  const descriptor = createDescriptor(deploymentId)
  const response = state[descriptor]

  if (!response) {
    return null
  }

  return response
}

function createDescriptor(deploymentId: string): string {
  return deploymentId
}

export const getDeploymentProxyResponse = (state: ReduxState, deploymentId: string) =>
  _getDeploymentProxyResponse(state.deploymentProxy, deploymentId)
