/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import genericMessages from '@modules/mfa-lib/genericMessages'
import type {
  MfaPasscodeChallenge,
  useVerifySaasCurrentUserMfaChallengeMutation,
} from '@modules/cloud-lib/users/hooks/mfa'
import { useChallengeSaasCurrentUserMfaDeviceMutation } from '@modules/cloud-lib/users/hooks/mfa'

import RequestCodeLink from './RequestCodeLink'

const EmailForm: React.FunctionComponent<{
  device: SaasAuthMfaDeviceResponse
  stateId: string
  verifyMutation: ReturnType<typeof useVerifySaasCurrentUserMfaChallengeMutation>
  onSubmit: (challenge: MfaPasscodeChallenge) => void
}> = ({ device, stateId, verifyMutation, onSubmit }) => {
  const { formatMessage } = useIntl()

  const [value, setValue] = useState('')

  const challengeMutation = useChallengeSaasCurrentUserMfaDeviceMutation()

  const { mutate: challengeMutate } = challengeMutation

  useEffect(() => {
    challengeMutate({ deviceId: device.device_id, stateId })
  }, [challengeMutate, device.device_id, stateId])

  const title = (
    <EuiTitle size='m'>
      <h1 style={{ textAlign: 'center' }}>
        <FormattedMessage
          id='mfaLogin.emailForm.title'
          defaultMessage='Check your email for a code'
        />
      </h1>
    </EuiTitle>
  )

  const instructions = (
    <EuiText size='s' textAlign='center'>
      <p>
        <FormattedMessage
          id='mfaLogin.emailForm.instructions1'
          defaultMessage='We sent a 6-digit code to {email}. The code is valid for 5 minutes.'
          values={{
            email: <strong>{device.email_address}</strong>,
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id='mfaLogin.emailForm.instructions2'
          defaultMessage="Didn't receive a code? Wait for a few minutes and {requestCodeLink}."
          values={{
            requestCodeLink: (
              <RequestCodeLink
                onClick={() => challengeMutate({ deviceId: device.device_id, stateId })}
              />
            ),
          }}
        />
      </p>
    </EuiText>
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        onSubmit({ pass_code: value })
      }}
    >
      <EuiFlexGroup direction='column' alignItems='center'>
        <EuiFlexItem>{title}</EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction='column' alignItems='stretch' style={{ maxWidth: '24rem' }}>
            <EuiFlexItem>{instructions}</EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                hasChildLabel={false}
                isInvalid={verifyMutation.isError}
                error={
                  verifyMutation.isError && [
                    <FormattedMessage
                      id='mfaLogin.emailForm.codeError'
                      defaultMessage='This code may be incorrect or expired. Try again or request a new code.'
                    />,
                  ]
                }
              >
                <EuiFieldText
                  aria-label={formatMessage(genericMessages.passCode)}
                  autoFocus={true}
                  autoComplete='off'
                  icon='lock'
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton type='submit' fill={true}>
                <FormattedMessage {...genericMessages.verifyCode} />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </form>
  )
}

export default EmailForm
