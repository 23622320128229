/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { PureComponent, Fragment } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonGroup,
  EuiHorizontalRule,
  EuiSpacer,
  EuiLoadingSpinner,
  EuiCallOut,
  EuiLink,
} from '@elastic/eui'

import { supportUrl } from '@/lib/urlBuilder'
import { getNodeConfigurationsFromStackDeployment } from '@/lib/stackDeployments/selectors/topologyElements'
import { getInstanceConfigurationsFromTemplate } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { autoscalingStatusOnGet } from '@/lib/stackDeployments/selectors/autoscaling'
import { getVersion, getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import ExternalLink from '@/components/ExternalLink'
import DeploymentArchitectureSummary from '@/components/DeploymentArchitecture/DeploymentArchitectureSummary'
import { getHourlyRate } from '@/lib/deployments/architecture'
import TotalPrice from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/PriceButton/TotalPrice'

import { messages } from './messages'
import { PriceView } from './types'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

class UsageSection extends PureComponent<AllProps & WrappedComponentProps> {
  state = {
    priceViewSelected: PriceView.HOURLY,
  }

  componentDidMount() {
    const { fetchBasePrices } = this.props

    fetchBasePrices()
  }

  render() {
    const { deployment, deploymentTemplate, fetchBasePricesRequest } = this.props
    const { priceViewSelected } = this.state

    if (fetchBasePricesRequest.inProgress) {
      return (
        <EuiFlexGroup alignItems='center' justifyContent='center'>
          <EuiLoadingSpinner size='xl' />
        </EuiFlexGroup>
      )
    }

    return (
      <Fragment>
        <DeploymentArchitectureSummary
          priceViewSelected={priceViewSelected}
          version={getVersion({ deployment })!}
          regionId={getRegionId({ deployment })!}
          autoscalingEnabled={autoscalingStatusOnGet({ deployment })!}
          instanceConfigurations={getInstanceConfigurationsFromTemplate({ deploymentTemplate })}
          nodeConfigurations={getNodeConfigurationsFromStackDeployment(deployment)}
          hideColorIndicators={true}
          hideDisclaimer={true}
        />

        <EuiHorizontalRule margin='none' />

        <EuiSpacer size='m' />

        {this.renderDisclaimer()}
      </Fragment>
    )
  }

  renderDisclaimer = () => {
    const {
      deployment,
      basePrices,
      deploymentTemplate,
      level,
      intl: { formatMessage },
    } = this.props
    const regionId = getRegionId({ deployment })!
    const { priceViewSelected } = this.state

    if (!basePrices) {
      return null
    }

    const { hourlyRate } = getHourlyRate({
      basePrices,
      regionId,
      instanceConfigurations: getInstanceConfigurationsFromTemplate({ deploymentTemplate }),
      nodeConfigurations: getNodeConfigurationsFromStackDeployment(deployment),
      level,
    })

    if (!hourlyRate || hourlyRate === 0) {
      return (
        <EuiCallOut
          data-test-id='price-unavailable'
          color='warning'
          size='s'
          title={
            <FormattedMessage
              id='pricing.no-pricing'
              defaultMessage="Pricing for this combination isn't available right now. Please {contactSupport} and let us know."
              values={{
                contactSupport: (
                  <EuiLink href={supportUrl()}>
                    <FormattedMessage
                      id='pricing.no-pricing.contact-support'
                      defaultMessage='contact support'
                    />
                  </EuiLink>
                ),
              }}
            />
          }
        />
      )
    }

    return (
      <Fragment>
        <EuiFlexGroup responsive={false} alignItems='flexStart' style={{ flexGrow: 0 }}>
          <EuiFlexItem grow={false}>
            <EuiButtonGroup
              buttonSize='compressed'
              legend={formatMessage(messages.priceToggle)}
              options={[
                {
                  id: PriceView.HOURLY,
                  label: formatMessage(messages.hourly),
                },
                {
                  id: PriceView.MONTHLY,
                  label: formatMessage(messages.monthly),
                },
              ]}
              idSelected={priceViewSelected}
              onChange={this.onChangePriceView}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup
              direction='column'
              alignItems='flexEnd'
              justifyContent='flexEnd'
              gutterSize='none'
              css={css({ textAlign: 'right' })}
            >
              <div css={css({ textDecorationLine: 'line-through', marginBottom: 8 })}>
                <TotalPrice hourlyRate={hourlyRate} priceViewSelected={priceViewSelected} />
              </div>
              <EuiText>
                <h5>
                  <FormattedMessage
                    id='usage-section.free-trial'
                    defaultMessage='Free during trial'
                  />
                </h5>
              </EuiText>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <EuiFlexItem>
          <EuiText size='xs' color='subdued'>
            <FormattedMessage
              id='usage-section.price-disclaimer'
              defaultMessage='Does not include {dataTransfer}'
              values={{
                dataTransfer: (
                  <ExternalLink
                    showExternalLinkIcon={true}
                    href='https://www.elastic.co/blog/elasticsearch-service-data-transfer-and-snapshot-storage-pricing'
                  >
                    <FormattedMessage
                      id='settings-summary-pricing.data-transfer'
                      defaultMessage='data transfer and snapshot storage'
                    />
                  </ExternalLink>
                ),
              }}
            />
          </EuiText>
        </EuiFlexItem>
      </Fragment>
    )
  }

  onChangePriceView = (priceViewSelected: PriceView) => {
    this.setState({ priceViewSelected })
  }
}

export default injectIntl(UsageSection)
