/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { ReduxState } from '@/types/redux'
import { getEolStatus } from '@/reducers/eolStatus'
import { fetchEolStatusRequest } from '@/reducers/asyncRequests/registry'

import asyncRequest from '../asyncRequests'
import { FETCH_EOL_STATUS } from '../../constants/actions'

function fetchEolStatus() {
  // The CSP for this URL is defined in server/security.ts
  const url = `https://www.elastic.co/support/eol.json`

  return asyncRequest({ type: FETCH_EOL_STATUS, url })
}

export function fetchEolStatusIfNeeded() {
  return (dispatch, getState) => {
    if (!shouldFetchEolStatus(getState())) {
      return Promise.resolve()
    }

    return dispatch(fetchEolStatus())
  }
}

function shouldFetchEolStatus(state: ReduxState) {
  const { inProgress } = fetchEolStatusRequest(state)

  if (inProgress) {
    return false
  }

  const eolStatus = getEolStatus(state)

  return !eolStatus
}
