/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { isString } from 'lodash'

import { EuiSpacer, EuiTitle } from '@elastic/eui'

import { CuiCodeEditor } from '@modules/cui/CodeEditor'
import { CuiAlert } from '@modules/cui/Alert'

import { isValidYaml } from '../../../../../lib/yaml'

import type { ReactNode } from 'react'

type Props = {
  value: string | undefined
  onChange: (value: string) => void
  id: string
  title?: ReactNode
}

export default class ReviewYamlSettingsEditor extends Component<Props> {
  componentDidMount(): void {
    const { value, onChange } = this.props

    if (!isString(value)) {
      return
    }

    onChange(value)
  }

  render(): ReactNode {
    const { id, onChange, title, value } = this.props

    if (!isString(value)) {
      return null
    }

    return (
      <Fragment>
        {title && (
          <Fragment>
            <EuiTitle size='xs'>
              <h4>{title}</h4>
            </EuiTitle>

            <EuiSpacer size='s' />
          </Fragment>
        )}

        <CuiCodeEditor
          data-test-id={id}
          language='yaml'
          value={value}
          height='160px' /* 10 lines worth @ 16px */
          onChange={onChange}
          isReadOnly={false}
        />

        {!isValidYaml(value) && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error' data-test-id='yaml-error-in-user-settings'>
              <FormattedMessage
                id='edit-yaml.modal-error'
                defaultMessage='The YAML settings are invalid, please check your syntax'
              />
            </CuiAlert>
          </Fragment>
        )}

        <EuiSpacer size='m' />
      </Fragment>
    )
  }
}
