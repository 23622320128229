/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { type SetStateAction, type Dispatch, useState, useRef, useEffect, useCallback } from 'react'

/**
 * A useState variant that reverts to the `initial` value after the specified
 * `timeout` period. Use for time-capped state changes, e.g. to show an
 * ephemeral notification.
 * @param initial The baseline value that will be restored after the `timeout`.
 * @param timeout The time in milliseconds after which a state change reverts to
 * the `initial` value.
 * @returns A wrapped `useState` hook.
 */
const useRevertingState = <TState>(
  initial: TState | (() => TState),
  timeout: number,
): [TState, Dispatch<SetStateAction<TState>>] => {
  const [state, _setState] = useState<TState>(initial)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const initialValueRef = useRef(initial)

  useEffect(() => {
    initialValueRef.current = initial
  }, [initial])

  const setState: typeof _setState = useCallback(
    async (valueOrUpdater) => {
      _setState(valueOrUpdater)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => _setState(initialValueRef.current), timeout)
    },
    [timeout],
  )

  return [state, setState]
}

export default useRevertingState
