/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import {
  getElasticsearchProjectStatus,
  getObservabilityProjectStatus,
  getSecurityProjectStatus,
} from '@modules/project-user-api/v1/callers'
import type { ProjectID, ProjectStatus } from '@modules/project-user-api/v1/types'
import type { QueryHook, QueryHookOptions } from '@modules/query/types'
import type { ProjectType } from '@modules/ui-types/projects'
import type { AnyUserProject } from '@modules/project-user-api/types'

import { makeQueryKey } from './helpers'

/**
 * Returns the appropriate hook for the provided project type.
 */
function getGetProjectStatusQueryHook(
  projectType: ProjectType,
):
  | typeof useGetElasticsearchProjectStatusQuery
  | typeof useGetObservabilityProjectStatusQuery
  | typeof useGetSecurityProjectStatusQuery {
  switch (projectType) {
    case 'observability':
      return useGetObservabilityProjectStatusQuery
    case 'security':
      return useGetSecurityProjectStatusQuery
    default:
      return useGetElasticsearchProjectStatusQuery
  }
}

const useGetElasticsearchProjectStatusQuery: QueryHook<typeof getElasticsearchProjectStatus> = (
  id: ProjectID | undefined,
  options?: QueryHookOptions,
) =>
  useQuery(
    makeQueryKey('getElasticsearchProjectStatus', id),
    () =>
      id === undefined
        ? Promise.reject(false)
        : getElasticsearchProjectStatus({
            pathParameters: { id },
          }),
    { refetchInterval, ...options },
  )

const useGetObservabilityProjectStatusQuery: QueryHook<typeof getObservabilityProjectStatus> = (
  id: ProjectID | undefined,
  options?: QueryHookOptions,
) =>
  useQuery(
    makeQueryKey('getObservabilityProjectStatus', id),
    () =>
      id === undefined
        ? Promise.reject(false)
        : getObservabilityProjectStatus({
            pathParameters: { id },
          }),
    { refetchInterval, ...options },
  )

const useGetSecurityProjectStatusQuery: QueryHook<typeof getSecurityProjectStatus> = (
  id: ProjectID | undefined,
  options?: QueryHookOptions,
) =>
  useQuery(
    makeQueryKey('getSecurityProjectStatus', id),
    () =>
      id === undefined
        ? Promise.reject(false)
        : getSecurityProjectStatus({
            pathParameters: { id },
          }),
    { refetchInterval, ...options },
  )

/**
 * Convenience hook for "is this project initializing?". If `optimistic`
 * (default), will return `false` when indeterminate. This may lead to a
 * FOUC-style state switch, so consider the best UX when using.
 *
 * The rule of thumb is that if it is more likely that a project will be
 * initialized (e.g. Project page), be optimistic; if it is more likely that it
 * won't (e.g. Create page), be pessimistic.
 */
export const useIsProjectInitializing = (
  project: AnyUserProject | undefined,
  optimistic?: false,
): boolean => {
  // The `elasticsearch` fallback is just TS sanity. Without a project this
  // will never be used; see next line.
  const useGetProjectStatus = getGetProjectStatusQueryHook(project?.type || 'elasticsearch')
  const { data: projectStatus } = useGetProjectStatus(project?.id, { enabled: Boolean(project) })
  return projectStatus ? projectStatus.phase === 'initializing' : optimistic === false
}

const refetchInterval = (projectStatus: ProjectStatus | undefined): false | number =>
  projectStatus?.phase === 'initialized' ? false : 4000
