/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Feature from '@modules/utils/feature'

import { getDeploymentHealthProblems } from '@/lib/healthProblems'
import { getDeploymentHealth } from '@/reducers/deploymentHealth'
import { getEolStatus } from '@/reducers/eolStatus'
import { fetchDeploymentHealthRequest } from '@/reducers/asyncRequests/registry'

import { isFeatureActivated } from '../../../selectors'

import DeploymentHeader from './DeploymentHeader'

import type { StateProps, ConsumerProps, DispatchProps } from './DeploymentHeader'

const mapStateToProps = (state, { match, stackDeployment }: ConsumerProps): StateProps => {
  const eolStatus = getEolStatus(state)
  const health = stackDeployment ? getDeploymentHealth(state, stackDeployment.id) : null
  const deploymentHealthRequest = stackDeployment
    ? fetchDeploymentHealthRequest(state, stackDeployment.id)
    : undefined
  const [problems] = getDeploymentHealthProblems({
    deployment: stackDeployment!,
    eolStatus,
    health,
    deploymentHealthRequest,
  })

  return {
    match,
    hideClusterInsteadOfDelete: isFeatureActivated(state, Feature.hideClusterInsteadOfDelete),
    hideClusterInsteadOfStop: isFeatureActivated(state, Feature.hideClusterInsteadOfStop),
    lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
    problems,
  }
}

const mapDispatchToProps: DispatchProps = {}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(DeploymentHeader),
)
