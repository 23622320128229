/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*
 * Actions that are related to resource types and not to instances.
 * For example, `create` is an action used for checking if user has permission
 * to create instances of a given resource type.
 */
type ResourceTypeAction = 'create' | 'delete-all' | 'leave' | 'list' | 'reorder' | 'search'

/*
 * Actions the are related to resource instances, require a resource ID.
 * For example, `get` is an action used for checking if user has permission
 * to get a single resource instance.
 */
type ResourceInstanceAction =
  | 'activate'
  | 'capture'
  | 'deactivate'
  | 'delete'
  | 'disable'
  | 'download'
  | 'enable-ccr'
  | 'get'
  | 'maintenance'
  | 'migrate-template'
  | 'move'
  | 'reset-password'
  | 'reset-token'
  | 'restart'
  | 'restart-stateless'
  | 'restore'
  | 'shutdown'
  | 'update'
  | 'upgrade'

/*
 * Platform-scoped resource types.
 * These are higher-level resources that are not related to any organization.
 */
const platformResourceTypes = [
  'admin-user',
  'allocator',
  'comment',
  'constructor',
  'instance-configuration',
  'deployment-heap-dumps',
  'deployment-thread-dump',
  'deployment-template',
  'license',
  'runner',
  'security-deployment',
  'security-realm',
  'snapshot-repository',
  'stack-version',
  'tls',
  'trust-relationship',
] as const

type PlatformResourceType = (typeof platformResourceTypes)[number]

/*
 * These are platform resource types and actions pairs that don't require a resource ID.
 * For example, `license:delete` would generally require a resource ID because `delete`
 * is a `ResourceInstanceAction` and usually requires a resource ID, however, license
 * is a singleton platform-scoped resource, which doesn't have an ID.
 * We need to add them here, otherwise Typescript will complain an `id` must be passed when checking
 * for permissions, because it will infer the type as `AuthorizedPlatformOwnedResourceInstance`.
 */
type DeleteLicense = {
  type: 'license'
  action: 'delete'
}

type UpdateLicense = {
  type: 'license'
  action: 'update'
}

type UpdateTls = {
  type: 'tls'
  action: 'update'
}

// Final platform-scoped resource types and actions that don't require a resource ID.
type AuthorizedPlatformOwnedResourceType =
  | {
      type: PlatformResourceType
      action: ResourceTypeAction
    }
  | DeleteLicense
  | UpdateLicense
  | UpdateTls

// Final platform-scoped resource types and actions that require a resource ID.
type AuthorizedPlatformOwnedResourceInstance = {
  type: PlatformResourceType
  action: ResourceInstanceAction
  id: string | '*'
}

/*
 * Organization-scoped resource types.
 * These are resources that belong to organizations.
 */
type OrganizationResourceType =
  | 'account-info'
  | 'billing-organization'
  | 'api-key'
  | 'feature-usage'
  | 'deployment'
  | 'deployment-logs'
  | 'deployment-metrics'
  | 'extension'
  | 'organization'
  | 'organization-invitation'
  | 'organization-members'
  | 'project-elasticsearch'
  | 'project-observability'
  | 'project-security'
  | 'role-assignment'
  | 'traffic-filter'
  | 'trusted-environment'

/*
 * These are organization resource types and actions pairs that don't require a resource ID.
 * Actions like `delete` or `update` are of type `ResourceInstanceAction`, which means Typescript
 * would infer the type as `AuthorizedOrganizationOwnedResourceInstance` and not
 * and not `AuthorizedOrganizationOwnedResourceType`, which is the correct type.
 * For example, `organization:update` is used to check if the user has permission to update
 * an organization and should not require a resource ID, only the organization ID being updated.
 */
type DeleteOrganizationMembers = {
  organizationId: string
  type: 'organization-members'
  action: 'delete'
}

type GetOrganization = {
  organizationId?: string
  type: 'organization'
  action: 'get'
}

type UpdateOrganization = {
  organizationId?: string
  type: 'organization'
  action: 'update'
}

type UpdateRoleAssignment = {
  organizationId: string
  type: 'role-assignment'
  action: 'update'
}

type UpdateIdp = {
  organizationId?: string
  type: 'organization-idp'
  action: 'update'
}

type GetIdp = {
  organizationId?: string
  type: 'organization-idp'
  action: 'get'
}

type UpdateDomainClaim = {
  organizationId?: string
  type: 'organization-domain-claim'
  action: 'update'
}

type GetDomainClaim = {
  organizationId?: string
  type: 'organization-domain-claim'
  action: 'get'
}

type UpdateRoleMapping = {
  organizationId?: string
  type: 'organization-role-mapping'
  action: 'update'
}

type GetRoleMapping = {
  organizationId?: string
  type: 'organization-role-mapping'
  action: 'get'
}

type DeleteRoleMapping = {
  organizationId?: string
  type: 'organization-role-mapping'
  action: 'delete'
}
/*
 * Final organization-scoped resource types and actions that don't require a resource ID.
 * `organizationId` is optional and when not passed, it passes the profile.organizationId value,
 * that is, is checks if the user has permissions for the organization it's a member of.
 */
type AuthorizedOrganizationOwnedResourceType =
  | {
      organizationId?: string
      type: OrganizationResourceType
      action: ResourceTypeAction
    }
  | DeleteOrganizationMembers
  | GetOrganization
  | UpdateOrganization
  | UpdateRoleAssignment
  | UpdateIdp
  | GetIdp
  | UpdateDomainClaim
  | GetDomainClaim
  | GetRoleMapping
  | UpdateRoleMapping
  | DeleteRoleMapping

// Final organization-scoped resource types and actions that require a resource ID.
type AuthorizedOrganizationOwnedResourceInstance = {
  organizationId?: string
  type: OrganizationResourceType
  action: ResourceInstanceAction
  id: string | '*'
}

// Union of all permission scopes, platform + organization.
export type PermissionToCheck =
  | AuthorizedPlatformOwnedResourceType
  | AuthorizedPlatformOwnedResourceInstance
  | AuthorizedOrganizationOwnedResourceType
  | AuthorizedOrganizationOwnedResourceInstance

// Sugar type to make sure at least one permission item is passed in the array.
export type NonEmptyPermissionsArray = [PermissionToCheck, ...PermissionToCheck[]]

// Result from authorization evaluation.
export type PermissionsCheckResult = {
  hasPermissions: boolean
  isLoading: boolean
}

// Defines behavior for permission checks, `every` makes sure every permission in the array is evaluated to `true` (AND),
// `some` returns `true` when at least one permission is evaluated to `true` (OR).
export type Behavior = 'every' | 'some'

export type PermissionsBatch<PermissionKey extends string> = Record<
  PermissionKey,
  {
    permissionsToCheck: NonEmptyPermissionsArray
    behavior?: Behavior
  }
>
export type PermissionsBatchResults<PermissionKey extends string> = {
  isLoading: boolean
} & Record<PermissionKey, boolean>

// Same as `platformResourceTypes` but a `Set` for faster retrieval.
const platformResourceTypesSet = new Set<PlatformResourceType>(platformResourceTypes)

export const isPlatformOwnedPermissionCheck = (
  permissionToCheck: PermissionToCheck,
): permissionToCheck is
  | AuthorizedPlatformOwnedResourceType
  | AuthorizedPlatformOwnedResourceInstance =>
  platformResourceTypesSet.has(permissionToCheck.type as PlatformResourceType)

export const isResourceInstancePermissionCheck = (
  permissionToCheck: PermissionToCheck,
): permissionToCheck is
  | AuthorizedPlatformOwnedResourceInstance
  | AuthorizedOrganizationOwnedResourceInstance => permissionToCheck.hasOwnProperty('id')
