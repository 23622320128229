/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { z } from 'zod'
import moment from 'moment'

const DEFAULT_PROJECT = 'elasticsearch'

// default CSP and region should be our genesis region AWS US-East-1
const DEFAULT_REGION = 'us-east-1'
const DEFAULT_PROVIDER = 'aws'

const Project = z.union([
  z.literal('elasticsearch'),
  z.literal('observability'),
  z.literal('security'),
])

export const filterSchema = z.object({
  project: Project.default(DEFAULT_PROJECT).catch(DEFAULT_PROJECT),
  provider: z.string().default(DEFAULT_PROVIDER),
  region: z.string().default(DEFAULT_REGION),
  timestamp: z.string().default(moment.utc().format()),
})

export type FilterSchema = z.infer<typeof filterSchema>
