/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { uniq } from 'lodash'
import moment from 'moment'

import type { components } from '@modules/billing-api/v1/types'

import type { UsageMode, InstanceType } from './types'

export const getModeBasedOnUsage = (
  instances: Array<components['schemas']['Instance']>,
): UsageMode => {
  const uniqInstanceTypes = uniq(instances.map(({ type }) => type))

  if (uniqInstanceTypes.length === 0) {
    return 'hybrid'
  }

  if (uniqInstanceTypes.includes('deployment')) {
    if (uniqInstanceTypes.length === 1) {
      return 'stateful'
    }

    // more than 1 item and one is a "deployment"
    return 'hybrid'
  }

  return 'serverless'
}

export const getInstancesType = (
  instances: Array<components['schemas']['Instance']>,
): InstanceType[] => uniq(instances.map(({ type }) => type as InstanceType))

export const getUsageQueryStartDate = (trialStartDate?: string): string => {
  if (trialStartDate) {
    return trialStartDate
  }

  return moment().subtract(14, 'd').startOf('day').utc().format() // last 14 days
}

export const getUsageQueryEndDate = (trialEndDate?: string): string => {
  if (trialEndDate && moment(trialEndDate).isBefore(moment())) {
    return trialEndDate
  }

  return moment().endOf('day').utc().format() // today
}
