/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  ClusterMetadataInfo,
  DeploymentResources,
  DeploymentMetadata,
} from '@modules/cloud-api/v1/types'
import type { AnyResourceInfo, FoundUser } from '@modules/ui-types'

import { getFirstEsClusterFromGet } from './fundamentals'

function getRawMetadataBag({ resource }: { resource: AnyResourceInfo }) {
  if (!resource.info.metadata) {
    return null
  }

  const metadataBag = resource.info.metadata.raw as any

  return metadataBag
}

export function isLocked({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): boolean {
  return Boolean(deployment.resources.elasticsearch[0]?.info.locked)
}

export function isHidden({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
    metadata?: DeploymentMetadata
  }
}): boolean {
  const { metadata, resources } = deployment

  if (!metadata) {
    return false
  }

  const hiddenTopLevel = metadata.hidden === true

  if (hiddenTopLevel) {
    return true
  }

  const [esResource] = resources.elasticsearch

  if (!esResource) {
    return false
  }

  return isHiddenResource({ resource: esResource })
}

export function isHiddenResource({ resource }: { resource: AnyResourceInfo }): boolean {
  const esSettings = resource.info.settings

  if (!esSettings) {
    return false
  }

  return (esSettings.metadata && esSettings.metadata.hidden) || false
}

export function getHiddenTimestamp({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): string | null {
  const { resources } = deployment
  const [esResource] = resources.elasticsearch

  if (!esResource) {
    return null
  }

  const metadataBag = getRawMetadataBag({ resource: esResource })

  if (!metadataBag) {
    return null
  }

  return metadataBag.hidden_timestamp
}

export function getSubscriptionLevel({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): string | null {
  const esCluster = getFirstEsClusterFromGet({ deployment })

  return (
    (esCluster &&
      esCluster.info.settings &&
      esCluster.info.settings.metadata &&
      esCluster.info.settings.metadata.subscription_level) ||
    null
  )
}

export function isPremium({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): boolean {
  const level = getSubscriptionLevel({ deployment })
  return typeof level === `string` && level !== `standard`
}

export function isSystemOwned({
  deployment,
}: {
  deployment: {
    metadata?: DeploymentMetadata
  }
}): boolean {
  if (!deployment.metadata) {
    return false
  }

  const systemOwned = deployment.metadata.system_owned

  return systemOwned || false
}

export function getOrganizationId({
  deployment,
}: {
  deployment: {
    metadata?: DeploymentMetadata
  }
}): string | null {
  if (!deployment.metadata) {
    return null
  }

  const organizationId = deployment.metadata.organization_id

  return organizationId || null
}

export function getClusterMetadata({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): ClusterMetadataInfo | null {
  const elasticsearch = getFirstEsClusterFromGet({ deployment })

  return elasticsearch?.info.metadata || null
}

export function isSSOPortOverridePresent({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): boolean {
  const elasticsearch = getFirstEsClusterFromGet({ deployment })

  return Boolean(elasticsearch?.info.metadata.sso_port_override)
}

export function getDeploymentTags({
  deployment,
}: {
  deployment: {
    metadata?: DeploymentMetadata
  }
}): Array<{ key: string; value: string }> {
  if (deployment.metadata?.tags === undefined) {
    return []
  }

  return deployment.metadata.tags.filter(
    ({ key, value }) => key.trim() !== '' && value.trim() !== '',
  )
}

export function hasCpuHardLimit({ metadata }: { metadata: ClusterMetadataInfo | null }): boolean {
  // The default is for a hard limit to be imposed. Undefined values are the equivalent to a true
  return (metadata?.raw?.resources as any)?.cpu?.hard_limit !== false
}

export function getShieldUsers({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): FoundUser[] {
  const metadata = getClusterMetadata({ deployment })
  return Object.values((metadata?.raw?.shield as any)?.found_users || {})
}

export function hasProxyLogging({
  deployment,
}: {
  deployment: {
    resources: DeploymentResources
  }
}): boolean {
  const metadata = getClusterMetadata({ deployment })

  // If the metadata is not present, we assume that proxy logging is enabled
  if (metadata?.raw?.proxy === undefined) {
    return true
  }

  return Boolean((metadata.raw.proxy as any)?.logging)
}
