/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { merge, uniq, cloneDeep, without, noop } from 'lodash'
import React, { Fragment, Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import type { EuiButtonGroupProps } from '@elastic/eui'
import {
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiButtonIcon,
  EuiCheckbox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormHelpText,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSkeletonText,
  EuiRadio,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTextColor,
  EuiTitle,
  EuiToolTip,
} from '@elastic/eui'

import {
  createTrafficFilterRulesetUrl,
  updateTrafficFilterRulesetUrl,
} from '@modules/cloud-api/v1/urls'
import type {
  TrafficFilterRulesetInfo,
  TrafficFilterRule,
  TrafficFilterRulesetRequest,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, PlatformId } from '@modules/ui-types'
import { addToast } from '@modules/cui/Toasts'
import { CuiAlert } from '@modules/cui/Alert'
import { getPlatform, getPlatformInfoById } from '@modules/utils/platform'

import ApiRequestExample from '@/components/ApiRequestExample'
import SpinButton from '@/components/SpinButton'
import DocLink from '@/components/DocLink'
import { replaceIn } from '@/lib/immutability-helpers'
import { isPrivateLinkRuleset, getPrivateLinkType } from '@/lib/stackDeployments/trafficFiltering'

import { validateIpOrCidr } from './traficFilterSourceValidator'
import messages from './messages'

import type { DocLinkKey } from '@/components/DocLink'
import type { IntlShape } from 'react-intl'

export type Props = {
  intl: IntlShape
  rulesetUnderEdit: TrafficFilterRulesetInfo | null
  rulesetDefaults: Partial<TrafficFilterRulesetRequest> | null
  fetchRegionList: () => Promise<void>
  fetchRegionListRequest: AsyncRequestState
  regionIds?: string[] | null
  defaultRegionId?: string
  getRegionName: (regionId: string) => string
  saveRuleset: (ruleset: TrafficFilterRulesetRequest) => Promise<void>
  saveRulesetRequest: (ruleset: TrafficFilterRulesetRequest) => AsyncRequestState
  resetSaveRulesetRequest: (ruleset: TrafficFilterRulesetRequest) => Promise<void>
  onClose: () => void
}

type State = {
  ruleset: TrafficFilterRulesetRequest
  pristine: boolean
  sourcesPristine: boolean
}

class EditTrafficFilterRulesetFlyout extends Component<Props, State> {
  state: State = {
    ruleset: this.getInitialRulesetState(),
    pristine: true,
    sourcesPristine: true,
  }

  componentDidMount() {
    const { rulesetUnderEdit, fetchRegionList } = this.props
    const isNew = rulesetUnderEdit === null

    if (isNew) {
      fetchRegionList()

      this.setDefaultRegion()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { regionIds, rulesetUnderEdit } = this.props

    if (prevProps.regionIds == null && regionIds != null) {
      const isNew = rulesetUnderEdit === null

      if (!isNew) {
        return
      }

      this.setDefaultRegion()
    }
  }

  componentWillUnmount() {
    const { resetSaveRulesetRequest } = this.props
    const { ruleset } = this.state
    resetSaveRulesetRequest(ruleset)
  }

  render() {
    const {
      intl: { formatMessage },
      rulesetUnderEdit,
      onClose,
      saveRulesetRequest,
    } = this.props

    const { ruleset } = this.state

    const isNew = rulesetUnderEdit === null
    const saveRequest = saveRulesetRequest(ruleset)

    return (
      <EuiFlyout
        ownFocus={true}
        onClose={onClose}
        size='m'
        maxWidth={true}
        aria-labelledby='editTrafficFilterRulesetFlyoutTitle'
        className='fs-unmask'
      >
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='s'>
            <h2 id='editTrafficFilterRulesetFlyoutTitle'>{this.renderFlyoutTitleText()}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <div>{this.renderEditor()}</div>
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType='cross' onClick={onClose} flush='left'>
                {formatMessage(messages.cancelEditing)}
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <SpinButton fill={true} onClick={this.saveChanges} spin={saveRequest.inProgress}>
                {isNew
                  ? formatMessage(messages.createTrafficFilterAction)
                  : formatMessage(messages.editTrafficFilterAction)}
              </SpinButton>

              {isNew ? (
                <ApiRequestExample
                  method='POST'
                  endpoint={createTrafficFilterRulesetUrl()}
                  body={ruleset}
                />
              ) : (
                <ApiRequestExample
                  method='PUT'
                  endpoint={updateTrafficFilterRulesetUrl({ rulesetId: rulesetUnderEdit!.id })}
                  body={ruleset}
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>

          {saveRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />

              <CuiAlert type='error'>{saveRequest.error}</CuiAlert>
            </Fragment>
          )}
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  renderFlyoutTitleText() {
    const {
      intl: { formatMessage },
      rulesetUnderEdit,
    } = this.props

    const { ruleset } = this.state

    const isNew = rulesetUnderEdit === null

    if (isNew) {
      return formatMessage(messages.createTrafficFilter)
    }

    if (ruleset.type === `azure_private_endpoint`) {
      return formatMessage(messages.editVnetTrafficFilter)
    }

    if (ruleset.type === `vpce`) {
      return formatMessage(messages.editVpceTrafficFilter)
    }

    if (ruleset.type === `gcp_private_service_connect_endpoint`) {
      return formatMessage(messages.editPscTrafficFilter)
    }

    if (ruleset.type === `ip`) {
      return formatMessage(messages.editIpTrafficFilter)
    }

    return formatMessage(messages.editTrafficFilter) // sanity
  }

  renderRemoteClusterFields() {
    const { ruleset } = this.state
    const {
      intl: { formatMessage },
    } = this.props

    const findOrgIdTip = (
      <FormattedMessage
        id='edit-traffic-filter-ruleset-flyout.find-org-id'
        defaultMessage='You can find the Organization ID by going to the Organization page in the top right menu.'
      />
    )
    const findClusterIdTip = (
      <FormattedMessage
        id='edit-traffic-filter-ruleset-flyout.find-cluster-id'
        defaultMessage='You can find the Elasticsearch ID by going to the Elasticsearch page of a deployment and hitting the "Copy cluster ID" button.'
      />
    )

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiTitle size='xs'>
          <h3>
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.rules-label'
              defaultMessage='Rules'
            />
          </h3>
        </EuiTitle>
        <EuiSpacer size='xs' />
        <EuiText size='s'>
          <FormattedMessage
            id='edit-traffic-filter-ruleset-flyout.remote-cluster-description'
            defaultMessage='Fill in either one or both of the fields below, but keep in mind that Organization ID takes precedence.'
          />
        </EuiText>
        <EuiSpacer size='m' />
        <EuiFlexGroup direction='column' gutterSize='s'>
          {ruleset.rules.map((rule, index, rules) => {
            const lastRule = index === rules.length - 1
            return (
              <EuiFlexGroup
                gutterSize='m'
                responsive={false}
                alignItems='center'
                key={rule.id || index}
              >
                <EuiFlexItem>
                  <EuiFormRow
                    label={
                      <FormattedMessage
                        id='edit-traffic-filter-ruleset-flyout.remote-cluster-org-id'
                        defaultMessage='Organization ID'
                      />
                    }
                    helpText={
                      lastRule ? (
                        <EuiFormHelpText>
                          <EuiToolTip content={findOrgIdTip}>
                            <FormattedMessage
                              id='edit-traffic-filter-ruleset-flyout.org-id-where-to-find'
                              defaultMessage='How do I find my organization ID?'
                            />
                          </EuiToolTip>
                        </EuiFormHelpText>
                      ) : undefined
                    }
                  >
                    <EuiFieldText
                      id='remoteClusterOrgId'
                      data-test-id='remote-cluster-org-id'
                      value={rule.remote_cluster_org_id || ``}
                      fullWidth={true}
                      onChange={(e) =>
                        this.setState({
                          ruleset: replaceIn(
                            ruleset,
                            [`rules`, String(index), `remote_cluster_org_id`],
                            e.target.value,
                          ),
                        })
                      }
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label={
                      <FormattedMessage
                        id='edit-traffic-filter-ruleset-flyout.remote-cluster-cluster-id'
                        defaultMessage='Elasticsearch ID'
                      />
                    }
                    helpText={
                      lastRule ? (
                        <EuiFormHelpText>
                          <EuiToolTip content={findClusterIdTip}>
                            <FormattedMessage
                              id='edit-traffic-filter-ruleset-flyout.cluster-id-where-to-find'
                              defaultMessage='How do I find my Elasticsearch ID?'
                            />
                          </EuiToolTip>
                        </EuiFormHelpText>
                      ) : undefined
                    }
                  >
                    <EuiFieldText
                      id='remoteClusterClusterId'
                      data-test-id='remote-cluster-cluster-id'
                      value={rule.remote_cluster_id || ``}
                      fullWidth={true}
                      onChange={(e) =>
                        this.setState({
                          ruleset: replaceIn(
                            ruleset,
                            [`rules`, String(index), `remote_cluster_id`],
                            e.target.value,
                          ),
                        })
                      }
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow hasEmptyLabelSpace={!lastRule}>
                    <EuiFlexGroup gutterSize='m' responsive={false} alignItems='center'>
                      <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                          iconType='minusInCircleFilled'
                          disabled={rules.length === 1}
                          aria-label={formatMessage(messages.removeThisRule, { index: index + 1 })}
                          onClick={() =>
                            this.setState({
                              ruleset: replaceIn(ruleset, [`rules`], without(ruleset.rules, rule)),
                            })
                          }
                        />
                      </EuiFlexItem>

                      <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                          iconType='plusInCircleFilled'
                          disabled={!lastRule}
                          aria-label={formatMessage(messages.addAnotherRule)}
                          onClick={() =>
                            this.setState({
                              ruleset: replaceIn(
                                ruleset,
                                [`rules`],
                                [...ruleset.rules, { source: `` }],
                              ),
                            })
                          }
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            )
          })}
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderPrivateLinkFields(cloudProviderId: PlatformId) {
    const {
      intl: { formatMessage },
    } = this.props

    const { ruleset } = this.state

    if (cloudProviderId === 'aws') {
      return (
        <EuiFormRow
          label={
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.endpoint-id-label'
              defaultMessage='Endpoint ID'
            />
          }
          helpText={
            <DocLink link='manageTrafficFiltersVpcFindEndpoint'>
              <FormattedMessage
                id='edit-traffic-filter-ruleset-flyout.vpc-endpoint-where-to-find'
                defaultMessage='How do I find my endpoint ID?'
              />
            </DocLink>
          }
          hasEmptyLabelSpace={true}
        >
          <EuiFieldText
            id='trafficFilterSource'
            data-test-id='private-link-resource-id'
            placeholder={formatMessage(messages.endpointPlaceholder)}
            value={(ruleset.rules && ruleset.rules[0] && ruleset.rules[0].source) || ``}
            fullWidth={true}
            onChange={(e) =>
              this.setState({
                ruleset: replaceIn(ruleset, [`rules`, `0`, `source`], e.target.value),
              })
            }
          />
        </EuiFormRow>
      )
    }

    if (cloudProviderId === 'azure') {
      return (
        <Fragment>
          <EuiFormRow
            label={
              <FormattedMessage
                id='edit-traffic-filter-ruleset-flyout.resource-name-label'
                defaultMessage='Resource name'
              />
            }
            helpText={
              <DocLink link='manageTrafficFiltersVpcFindResourceName'>
                <FormattedMessage
                  id='edit-traffic-filter-ruleset-flyout.vpc-resource-name-where-to-find'
                  defaultMessage='How do I find my resource name?'
                />
              </DocLink>
            }
            hasEmptyLabelSpace={true}
          >
            <EuiFieldText
              id='trafficFilterResourceName'
              placeholder={formatMessage(messages.vnetNamePlaceholder)}
              data-test-id='private-link-resource-name'
              fullWidth={true}
              value={
                (ruleset.rules && ruleset.rules[0] && ruleset.rules[0].azure_endpoint_name) || ``
              }
              onChange={(e) =>
                this.setState({
                  ruleset: replaceIn(
                    ruleset,
                    [`rules`, `0`, `azure_endpoint_name`],
                    e.target.value,
                  ),
                })
              }
            />
          </EuiFormRow>

          <EuiFormRow
            label={
              <FormattedMessage
                data-test-id='private-link-resource-id'
                id='edit-traffic-filter-ruleset-flyout.resource-id-label'
                defaultMessage='Resource ID'
              />
            }
            helpText={
              <DocLink link='manageTrafficFiltersVpcFindResourceId'>
                <FormattedMessage
                  id='edit-traffic-filter-ruleset-flyout.vpc-resource.id-where-to-find'
                  defaultMessage='How do I find my resource ID?'
                />
              </DocLink>
            }
            hasEmptyLabelSpace={true}
          >
            <EuiFieldText
              id='trafficFilterResourceId'
              placeholder={formatMessage(messages.vnetIdPlaceholder)}
              fullWidth={true}
              value={
                (ruleset.rules && ruleset.rules[0] && ruleset.rules[0].azure_endpoint_guid) || ``
              }
              onChange={(e) =>
                this.setState({
                  ruleset: replaceIn(
                    ruleset,
                    [`rules`, `0`, `azure_endpoint_guid`],
                    e.target.value,
                  ),
                })
              }
            />
          </EuiFormRow>
        </Fragment>
      )
    }

    if (cloudProviderId === 'gcp') {
      return (
        <EuiFormRow
          label={
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.psc-id-label'
              defaultMessage='PSC connection ID'
            />
          }
          helpText={
            <DocLink link='manageTrafficFiltersPscFindEndpoint'>
              <FormattedMessage
                id='edit-traffic-filter-ruleset-flyout.psc-endpoint-where-to-find'
                defaultMessage='How do I find the PSC connection ID?'
              />
            </DocLink>
          }
          hasEmptyLabelSpace={true}
        >
          <EuiFieldText
            id='trafficFilterSource'
            data-test-id='private-link-resource-id'
            placeholder={formatMessage(messages.pscPlaceholder)}
            value={(ruleset.rules && ruleset.rules[0] && ruleset.rules[0].source) || ``}
            fullWidth={true}
            onChange={(e) =>
              this.setState({
                ruleset: replaceIn(ruleset, [`rules`, `0`, `source`], e.target.value),
              })
            }
          />
        </EuiFormRow>
      )
    }

    return null
  }

  renderEditor() {
    const {
      intl: { formatMessage },
      defaultRegionId,
    } = this.props

    const { ruleset, pristine, sourcesPristine } = this.state
    const cloudProviderId = getPlatform(ruleset.region)
    const invalidNameEmpty = !pristine && ruleset.name.trim().length === 0

    const rulesSourceValidation = !sourcesPristine ? this.validateRuleSources() : []

    return (
      <Fragment>
        {Boolean(defaultRegionId) || (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiFormRow
              label={
                <FormattedMessage
                  id='edit-traffic-filter-ruleset-flyout.endpoint-cloud-provider-label'
                  defaultMessage='Cloud provider'
                />
              }
            >
              {this.renderCloudProviderPicker()}
            </EuiFormRow>
            <EuiSpacer size='m' />
            <EuiFormRow
              label={
                <FormattedMessage
                  id='edit-traffic-filter-ruleset-flyout.endpoint-region-label'
                  defaultMessage='Region'
                />
              }
              helpText={
                <FormattedMessage
                  id='edit-traffic-filter-ruleset-flyout.region-help-text'
                  defaultMessage='The traffic filter can be applied only to deployments with matching regions. Your consumer services might be in other regions.'
                />
              }
            >
              {this.renderRegionEditor()}
            </EuiFormRow>
            <EuiSpacer size='m' />
          </Fragment>
        )}

        {this.renderFilterTypeEditor()}

        {isPrivateLinkRuleset(ruleset) && (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiText size='s'>
              <FormattedMessage
                id='edit-traffic-filter-ruleset-flyout.service-names-explainer'
                defaultMessage='Setting up your endpoint? Get the right {documentationLink} and review the rest of the prerequisites.'
                values={{
                  documentationLink: (
                    <DocLink link={this.getDocLinkForRegion(cloudProviderId)}>
                      <FormattedMessage
                        id='edit-traffic-filter-ruleset-flyout.service-names-documentation'
                        defaultMessage='Elastic Cloud service name for your region'
                      />
                    </DocLink>
                  ),
                }}
              />
            </EuiText>
          </Fragment>
        )}

        <EuiFormRow
          label={
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.name-label'
              defaultMessage='Name'
            />
          }
          isInvalid={invalidNameEmpty}
          error={
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.name-cannot-be-empty'
              defaultMessage='Enter a name'
            />
          }
          hasEmptyLabelSpace={true}
        >
          <EuiFieldText
            id='trafficFilterName'
            isInvalid={invalidNameEmpty}
            value={ruleset.name}
            onChange={(e) =>
              this.setState({ ruleset: replaceIn(ruleset, [`name`], e.target.value) })
            }
            fullWidth={true}
          />
        </EuiFormRow>

        <EuiFormRow
          label={
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.description-label'
              defaultMessage='Description {optional}'
              values={{
                optional: (
                  <EuiTextColor color='subdued'>
                    <FormattedMessage
                      id='edit-traffic-filter-ruleset-flyout.description-optional-label'
                      defaultMessage='(optional)'
                    />
                  </EuiTextColor>
                ),
              }}
            />
          }
          hasEmptyLabelSpace={true}
        >
          <EuiTextArea
            id='trafficFilterDesc'
            value={ruleset.description}
            fullWidth={true}
            onChange={(e) =>
              this.setState({ ruleset: replaceIn(ruleset, [`description`], e.target.value) })
            }
          />
        </EuiFormRow>

        {isPrivateLinkRuleset(ruleset) && this.renderPrivateLinkFields(cloudProviderId)}

        {ruleset.type === `remote_cluster` && this.renderRemoteClusterFields()}

        {ruleset.type === `ip` && (
          <Fragment>
            <EuiSpacer size='m' />

            <EuiTitle size='xs'>
              <h3>
                <FormattedMessage
                  id='edit-traffic-filter-ruleset-flyout.rules-label'
                  defaultMessage='Rules'
                />
              </h3>
            </EuiTitle>

            <EuiSpacer size='m' />

            <EuiFlexGroup direction='column' gutterSize='s'>
              {ruleset.rules.map((rule, index, rules) => {
                const lastRule = index === rules.length - 1
                const isSourceInvalid = !(rulesSourceValidation[index] ?? true)

                return (
                  <EuiFlexGroup responsive={false} alignItems='center' key={rule.id || index}>
                    <EuiFlexItem>
                      <EuiFormRow
                        label={
                          <FormattedMessage
                            id='edit-traffic-filter-ruleset-flyout.source-label'
                            defaultMessage='Source'
                          />
                        }
                        isInvalid={isSourceInvalid}
                        error={
                          <FormattedMessage
                            id='edit-traffic-filter-ruleset-flyout.source-error-text'
                            defaultMessage='Please enter a valid CIDR or IP address'
                          />
                        }
                      >
                        <EuiFieldText
                          id='trafficFilterSource'
                          value={rule.source}
                          isInvalid={isSourceInvalid}
                          onChange={(e) =>
                            this.setState({
                              sourcesPristine: false,
                              ruleset: replaceIn(
                                ruleset,
                                [`rules`, String(index), `source`],
                                e.target.value,
                              ),
                            })
                          }
                          fullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiFormRow hasEmptyLabelSpace={!isSourceInvalid}>
                        <EuiFlexGroup gutterSize='m' responsive={false} alignItems='baseline'>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                              iconType='minusInCircleFilled'
                              disabled={rules.length === 1}
                              aria-label={formatMessage(messages.removeThisRule, {
                                index: index + 1,
                              })}
                              onClick={() =>
                                this.setState({
                                  sourcesPristine: false,
                                  ruleset: replaceIn(
                                    ruleset,
                                    [`rules`],
                                    without(ruleset.rules, rule),
                                  ),
                                })
                              }
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                              iconType='plusInCircleFilled'
                              disabled={!lastRule}
                              aria-label={formatMessage(messages.addAnotherRule)}
                              onClick={() =>
                                this.setState({
                                  sourcesPristine: false,
                                  ruleset: replaceIn(
                                    ruleset,
                                    [`rules`],
                                    [...ruleset.rules, { source: `` }],
                                  ),
                                })
                              }
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )
              })}
              <EuiFlexItem>
                <EuiFormHelpText>
                  <FormattedMessage
                    id='edit-traffic-filter-ruleset-flyout.source-help-text'
                    defaultMessage='Enter CIDR or IP address e.g. 192.168.132.6/22'
                  />
                </EuiFormHelpText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        )}

        <EuiSpacer size='m' />

        <EuiHorizontalRule />

        <EuiSpacer size='m' />

        <EuiTitle size='xs'>
          <h3>
            <FormattedMessage
              id='edit-traffic-filter-ruleset-flyout.add-to-deployments-label'
              defaultMessage='Add to deployments'
            />
          </h3>
        </EuiTitle>

        <EuiSpacer size='m' />

        <EuiCheckbox
          id='include-traffic-filter-by-default'
          label={formatMessage(messages.includeByDefault)}
          checked={ruleset.include_by_default}
          onChange={() =>
            this.setState({
              ruleset: replaceIn(ruleset, [`include_by_default`], !ruleset.include_by_default),
            })
          }
        />

        <EuiFormHelpText>
          <FormattedMessage
            id='edit-traffic-filter-ruleset-flyout.include-by-default-description'
            defaultMessage='Any future eligible deployments will have this filter applied automatically.'
          />
        </EuiFormHelpText>
      </Fragment>
    )
  }

  renderCloudProviderPicker() {
    const {
      intl: { formatMessage },
      regionIds,
      rulesetUnderEdit,
    } = this.props

    const { ruleset } = this.state
    const selectedCloudProvider = getPlatform(ruleset.region)

    const isNew = rulesetUnderEdit === null

    const buttonGroupProps: EuiButtonGroupProps = {
      color: 'primary',
      isFullWidth: true,
      legend: formatMessage(messages.selectCloudProvider),
      idSelected: selectedCloudProvider,
      options: [],
      onChange: noop,
    }

    if (!isNew) {
      return (
        <EuiButtonGroup
          {...buttonGroupProps}
          options={[getCloudProviderButton(selectedCloudProvider)]}
          isDisabled={true}
        />
      )
    }

    if (!regionIds) {
      return <EuiSkeletonText lines={1} />
    }

    const cloudProviders = uniq(regionIds.map(getPlatform))
    const cloudProviderButtons = cloudProviders.map(getCloudProviderButton)

    return (
      <EuiButtonGroup
        {...buttonGroupProps}
        options={cloudProviderButtons}
        onChange={(cloudProvider) => {
          if (selectedCloudProvider === cloudProvider) {
            return
          }

          const providerRegionIds = regionIds.filter(
            (regionId) => getPlatform(regionId) === cloudProvider,
          )

          const [defaultProviderRegionId] = providerRegionIds

          this.setRegion(defaultProviderRegionId!)
        }}
      />
    )

    function getCloudProviderButton(cloudProvider: PlatformId) {
      const { id, iconType, shortTitle } = getPlatformInfoById(cloudProvider)

      return {
        id,
        iconType,
        label: shortTitle,
        'data-test-id': `cloud-provider-button-${cloudProvider}`,
      }
    }
  }

  renderRegionEditor() {
    const {
      fetchRegionListRequest,
      regionIds,
      rulesetUnderEdit,
      getRegionName,
      intl: { formatMessage },
    } = this.props

    const { ruleset, pristine } = this.state

    const isNew = rulesetUnderEdit === null

    const invalidRegionEmpty = !pristine && !ruleset.region

    if (!isNew) {
      return (
        <div data-test-id='region-readonly'>
          <EuiFieldText value={getRegionName(ruleset.region)} readOnly={true} />
        </div>
      )
    }

    if (!regionIds) {
      return <EuiSkeletonText lines={1} />
    }

    const cloudProvider = getPlatform(ruleset.region)
    const filteredRegionIds = regionIds.filter(
      (regionId) => getPlatform(regionId) === cloudProvider,
    )

    const [defaultProviderRegionId] = filteredRegionIds

    const regionOptions = filteredRegionIds
      .map((regionId) => ({
        value: regionId,
        text: getRegionName(regionId),
      }))
      .sort((a, b) => a.text.localeCompare(b.text))

    return (
      <Fragment>
        <div data-test-id='region-select'>
          <EuiSelect
            fullWidth={true}
            isLoading={fetchRegionListRequest.inProgress}
            options={regionOptions}
            value={ruleset.region || defaultProviderRegionId}
            onChange={(e) => this.setRegion(e.target.value)}
            hasNoInitialSelection={true}
            aria-label={formatMessage(messages.regionSelectAria)}
          />
        </div>

        {invalidRegionEmpty && (
          <EuiFormHelpText>
            <EuiTextColor color='danger'>
              <FormattedMessage
                id='edit-traffic-filter-ruleset-flyout.region-cannot-be-empty'
                defaultMessage='Select a region'
              />
            </EuiTextColor>
          </EuiFormHelpText>
        )}

        {fetchRegionListRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{fetchRegionListRequest.error}</CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderFilterTypeEditor() {
    const {
      intl: { formatMessage },
      rulesetUnderEdit,
      defaultRegionId,
    } = this.props

    const { ruleset } = this.state

    const cloudProviderId = getPlatform(ruleset.region)

    const isEce = Boolean(defaultRegionId)
    const isAzure = cloudProviderId === 'azure'
    const isNew = rulesetUnderEdit === null

    const ipFilteringRadio = (
      <EuiRadio
        id='trafficFilter-selectFilterType-ip'
        label={formatMessage(messages.selectFilterTypeIpAddress)}
        checked={ruleset.type === `ip`}
        onChange={() => {
          const rulesetWithEmptyRules = replaceIn(ruleset, [`rules`], [{ source: `` }])
          const nextRuleset = replaceIn(rulesetWithEmptyRules, [`type`], `ip`)

          this.setState({ ruleset: nextRuleset })
        }}
      />
    )

    const remoteClusterRadio = (
      <EuiRadio
        id='trafficFilter-selectFilterType-remoteCluster'
        data-test-id='remote-cluster-radio'
        label={formatMessage(messages.selectFilterTypeRemoteCluster)}
        checked={ruleset.type === `remote_cluster`}
        onChange={() => {
          const rulesetWithEmptyRules = replaceIn(ruleset, [`rules`], [{ source: `` }])
          const nextRuleset = replaceIn(rulesetWithEmptyRules, [`type`], `remote_cluster`)

          this.setState({ ruleset: nextRuleset })
        }}
      />
    )

    const privateLinkRadio = (
      <EuiRadio
        id='trafficFilter-selectFilterType-vpce'
        label={this.getPrivateLinkRadioLabel(cloudProviderId)}
        checked={isPrivateLinkRuleset(ruleset)}
        data-test-id='private-link-radio'
        onChange={() => {
          const rules: TrafficFilterRule[] = [
            isAzure ? { azure_endpoint_name: ``, azure_endpoint_guid: `` } : { source: `` },
          ]

          const rulesetWithEmptyRules = replaceIn(ruleset, [`rules`], rules)

          const nextRuleset = replaceIn(
            rulesetWithEmptyRules,
            [`type`],
            getPrivateLinkType(cloudProviderId),
          )

          this.setState({ ruleset: nextRuleset })
        }}
      />
    )

    if (!isNew) {
      return null
    }

    return (
      <EuiFormRow
        label={
          <FormattedMessage
            id='edit-traffic-filter-ruleset-flyout.filter-type-label'
            defaultMessage='Filter type'
          />
        }
        labelType='legend'
      >
        {isEce ? (
          <Fragment>
            {ipFilteringRadio}
            <EuiSpacer size='xs' />
            {remoteClusterRadio}
          </Fragment>
        ) : (
          <Fragment>
            {ipFilteringRadio}
            <EuiSpacer size='xs' />
            {privateLinkRadio}
            <EuiSpacer size='xs' />
            {remoteClusterRadio}
          </Fragment>
        )}
      </EuiFormRow>
    )
  }

  hasValidationErrors(): boolean {
    const { ruleset } = this.state

    if (ruleset.name.trim() === ``) {
      return true
    }

    if (this.validateRuleSources().some((isValid) => !isValid)) {
      return true
    }

    return !ruleset.region
  }

  validateRuleSources() {
    let sourceValidator: (source: string | undefined) => boolean

    switch (this.state.ruleset.type) {
      case 'ip':
        sourceValidator = validateIpOrCidr
        break
      default:
        sourceValidator = () => true
    }

    return this.state.ruleset.rules.map((rule) => sourceValidator(rule.source))
  }

  setDefaultRegion = () => {
    const { regionIds } = this.props

    if (regionIds != null) {
      const cloudProvider = getPlatform(this.state.ruleset.region)
      const providerRegionIds = regionIds.filter(
        (regionId) => getPlatform(regionId) === cloudProvider,
      )

      const [defaultProviderRegionId] = providerRegionIds

      if (defaultProviderRegionId) {
        this.setRegion(defaultProviderRegionId)
      }
    }
  }

  setRegion = (regionId: string) => {
    const { ruleset } = this.state

    const nextCloudProvider = getPlatform(regionId)

    const rulesetInNextRegion = replaceIn(ruleset, [`region`], regionId)
    const rulesetWithNextRules = replaceIn(rulesetInNextRegion, [`rules`], getNextRules())
    const rulesetInNextType = replaceIn(rulesetWithNextRules, [`type`], getNextRulesetType())

    this.setState({
      ruleset: rulesetInNextType,
    })

    function getNextRules() {
      const { rules } = ruleset

      if (!rules) {
        return rules
      }

      // switch into Azure VNet model
      if (nextCloudProvider === 'azure' && ruleset.type === 'vpce') {
        return [{ azure_endpoint_name: ``, azure_endpoint_guid: `` }]
      }

      // switch out of Azure VNet model
      if (nextCloudProvider !== 'azure' && ruleset.type === 'azure_private_endpoint') {
        return [{ source: `` }]
      }

      return rules
    }

    function getNextRulesetType() {
      // respect equivalences when switching Cloud providers
      if (isPrivateLinkRuleset(ruleset)) {
        return getPrivateLinkType(nextCloudProvider)
      }

      return 'ip'
    }
  }

  saveChanges = () => {
    const { rulesetUnderEdit, saveRuleset } = this.props

    const { ruleset } = this.state

    if (this.hasValidationErrors()) {
      this.setState({ pristine: false, sourcesPristine: false })
      return
    }

    saveRuleset(ruleset).then(() => {
      if (rulesetUnderEdit === null) {
        this.addCreateSuccessToast()
      } else {
        this.addUpdateSuccessToast()
      }

      this.setState({ pristine: true, sourcesPristine: true })
    })
  }

  getInitialRulesetState(): TrafficFilterRulesetRequest {
    const { rulesetUnderEdit, defaultRegionId, rulesetDefaults } = this.props

    function transformIntoRequest(
      rulesetInfo: TrafficFilterRulesetInfo,
    ): TrafficFilterRulesetRequest {
      return {
        name: rulesetInfo.name,
        description: rulesetInfo.description,
        type: rulesetInfo.type,
        include_by_default: rulesetInfo.include_by_default,
        region: rulesetInfo.region,
        rules: cloneDeep(rulesetInfo.rules),
      }
    }

    if (rulesetUnderEdit !== null) {
      return transformIntoRequest(rulesetUnderEdit)
    }

    return merge(
      {
        name: ``,
        region: defaultRegionId || ``,
        description: ``,
        type: `ip`,
        rules: [{ source: `` }],
        include_by_default: false,
      },
      rulesetDefaults,
    )
  }

  addCreateSuccessToast() {
    const {
      intl: { formatMessage },
    } = this.props

    const { ruleset } = this.state

    addToast({
      family: `edit-traffic-filter-flyout`,
      id: `edit-traffic-filter-flyout.create-success`,
      color: `success`,
      title: (
        <strong>
          {formatMessage(messages.createSuccessTitle, {
            ruleType: this.getRuleTypeTitle(ruleset),
          })}
        </strong>
      ),
      text: formatMessage(messages.createSuccessText, {
        ruleType: this.getRuleTypeText(ruleset),
        name: <strong>{ruleset.name}</strong>,
      }),
    })
  }

  addUpdateSuccessToast() {
    const {
      intl: { formatMessage },
    } = this.props

    const { ruleset } = this.state

    addToast({
      family: `edit-traffic-filter-flyout`,
      id: `edit-traffic-filter-flyout.update-success`,
      color: `success`,
      title: (
        <strong>
          {formatMessage(messages.updateSuccessTitle, {
            ruleType: this.getRuleTypeTitle(ruleset),
          })}
        </strong>
      ),
      text: formatMessage(messages.updateSuccessText, {
        ruleType: this.getRuleTypeText(ruleset),
        name: <strong>{ruleset.name}</strong>,
      }),
    })
  }

  getRuleTypeTitle(ruleset: TrafficFilterRulesetRequest) {
    const {
      intl: { formatMessage },
    } = this.props

    if (ruleset.type === `azure_private_endpoint`) {
      return formatMessage(messages.resourceInTitle)
    }

    if (ruleset.type === `vpce`) {
      return formatMessage(messages.endpointInTitle)
    }

    if (ruleset.type === `gcp_private_service_connect_endpoint`) {
      return formatMessage(messages.endpointInTitle)
    }

    return formatMessage(messages.ipFilterInTitle)
  }

  getRuleTypeText(ruleset: TrafficFilterRulesetRequest) {
    const {
      intl: { formatMessage },
    } = this.props

    if (ruleset.type === `azure_private_endpoint`) {
      return formatMessage(messages.resourceInText)
    }

    if (ruleset.type === `vpce`) {
      return formatMessage(messages.endpointInText)
    }

    if (ruleset.type === `gcp_private_service_connect_endpoint`) {
      return formatMessage(messages.endpointInText)
    }

    return formatMessage(messages.ipFilterInText)
  }

  getPrivateLinkRadioLabel(cloudProviderId: string): string {
    const {
      intl: { formatMessage },
    } = this.props

    if (cloudProviderId === 'gcp') {
      return formatMessage(messages.pscSelectFilterTypeEndpoint)
    }

    return formatMessage(messages.selectFilterTypeEndpoint)
  }

  getDocLinkForRegion(cloudProviderId: string): DocLinkKey {
    if (cloudProviderId === 'gcp') {
      return 'manageTrafficFiltersPsc'
    }

    if (cloudProviderId === 'azure') {
      return 'manageTrafficFiltersVnet'
    }

    return 'manageTrafficFiltersVpc'
  }
}

export default injectIntl(EditTrafficFilterRulesetFlyout)
