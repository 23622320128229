/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type {
  NormalisedResourceRoleAssignment,
  ResourceType,
} from '@modules/role-assignments-lib/types'
import type { MemberOrApiKey } from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'
import ProjectRoleAssignmentsSummary from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'

import { useGetOrganizationDeploymentsAndProjectsQuery } from './lib'

import type { FunctionComponent } from 'react'

const AdminProjectRoleAssignmentsSummary: FunctionComponent<{
  memberOrApiKey: MemberOrApiKey
  organizationId: string
  roleAssignments: Array<NormalisedResourceRoleAssignment<ResourceType>>
}> = ({ memberOrApiKey, organizationId, roleAssignments }) => {
  const deploymentsAndProjectsQueryResults =
    useGetOrganizationDeploymentsAndProjectsQuery(organizationId)

  return (
    <ProjectRoleAssignmentsSummary
      memberOrApiKey={memberOrApiKey}
      organizationDeploymentsAndProjectsQueryResults={deploymentsAndProjectsQueryResults}
      roleAssignments={roleAssignments}
    />
  )
}

export default AdminProjectRoleAssignmentsSummary
