/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiCallOutProps } from '@elastic/eui'
import { EuiCallOut, EuiLink } from '@elastic/eui'

import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'
import { useListDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'
import { useProjectsMetadata } from '@modules/project-user-components/SupplyProjectsMetadata'

import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import type { FC } from 'react'

export type Props = {
  trialDaysRemaining: number
}

export const CallToAction = () => {
  const isGovCloud = Boolean(useConfig(Feature.hideIrrelevantSectionsFromGovCloud))

  if (isGovCloud) {
    return (
      <EuiLink href='mailto:gov-onboarding@elastic.co'>
        <FormattedMessage
          id='portal-resources.gov-cloud-trial-expiring-contact-us'
          defaultMessage='Contact us'
        />
      </EuiLink>
    )
  }

  return (
    <CreditCardModalButton>
      <FormattedMessage
        id='portal-resources.trial-expiring-subscribe'
        defaultMessage='Subscribe now'
      />
    </CreditCardModalButton>
  )
}

const CalloutMessage = ({
  trialDaysRemaining,
  hasActiveRecource,
}: {
  trialDaysRemaining?: number
  hasActiveRecource: boolean
}) => {
  const values = {
    action: (
      <span data-test-id='trial-status-callout-cta'>
        <CallToAction />
      </span>
    ),
  }

  if (trialDaysRemaining && trialDaysRemaining > 0) {
    return (
      <FormattedMessage
        data-test-id='trial-expiring'
        id='portal-resources.trial-expiring-info'
        defaultMessage='Your trial is expiring soon. {action} to continue using your Elastic Cloud account.'
        values={values}
      />
    )
  }

  if (hasActiveRecource) {
    return (
      <FormattedMessage
        id='portal-resources.trial-expired.resources-active'
        defaultMessage='Your trial has expired. Projects or Deployments created during the trial period will soon be terminated. {action} to continue using your Elastic Cloud account.'
        values={values}
      />
    )
  }

  return (
    <FormattedMessage
      id='portal-resources.trial-expired.resources-terminated'
      defaultMessage='Your trial has expired. {action} to continue using your Elastic Cloud account.'
      values={values}
    />
  )
}

const TrialStatusCallout: FC<Props> = ({ trialDaysRemaining }) => {
  const { projectsLength, isReady: isProjectsMetadataReady } = useProjectsMetadata()
  const { data, isLoading: isDeploymentsDataLoading } = useListDeploymentsQuery()
  const deploymentsLength = data?.deployments.length

  if (!isProjectsMetadataReady || isDeploymentsDataLoading) {
    return null
  }

  const hasActiveRecource = Boolean(projectsLength || deploymentsLength)

  const calloutProps: EuiCallOutProps =
    trialDaysRemaining === 0
      ? {
          color: 'danger',
        }
      : {
          color: 'warning',
        }

  return (
    <EuiCallOut {...calloutProps} data-test-id='trial-status-callout'>
      <p>
        <CalloutMessage
          trialDaysRemaining={trialDaysRemaining}
          hasActiveRecource={hasActiveRecource}
        />
      </p>
    </EuiCallOut>
  )
}

export default TrialStatusCallout
