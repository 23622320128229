/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { sortBy } from 'lodash'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { deviceTypesDisplayOrder } from '@modules/mfa-enforcement/lib'

import DeviceOption from './DeviceOption'

const SelectDevice: React.FunctionComponent<{
  devices: SaasAuthMfaDeviceResponse[]
  onSelect: (device: SaasAuthMfaDeviceResponse) => void
}> = ({ devices, onSelect }) => {
  const sortedDevices = sortBy(devices, ({ device_type }) => deviceTypesDisplayOrder(device_type))

  return (
    <Fragment>
      <EuiTitle size='m'>
        <h1 style={{ textAlign: 'center' }}>
          <FormattedMessage
            id='mfaLogin.chooseMethod.title'
            defaultMessage='Choose an authentication method'
          />
        </h1>
      </EuiTitle>

      <EuiSpacer size='l' />

      <EuiFlexGroup direction='column' alignItems='stretch' style={{ maxWidth: '40rem' }}>
        {sortedDevices.map((device) => (
          <EuiFlexItem key={device.device_type}>
            <DeviceOption device={device} onSelect={onSelect} />
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </Fragment>
  )
}

export default SelectDevice
