/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'

import AddApiKeyFlyout from './AddApiKeyFlyout'

type Props = {
  deployment: StackDeployment
}

type State = {
  isFlyoutOpen: boolean
}

class AddApiKey extends React.Component<Props, State> {
  state: State = {
    isFlyoutOpen: false,
  }

  render(): JSX.Element {
    const { deployment } = this.props
    return (
      <Fragment>
        <EuiButton
          size='s'
          onClick={() => this.setState({ isFlyoutOpen: true })}
          data-test-id='security.add-api-key'
        >
          <FormattedMessage id='add-api-key.btn' defaultMessage='Add API key' />
        </EuiButton>
        {this.state.isFlyoutOpen && (
          <AddApiKeyFlyout
            closeFlyout={() => this.setState({ isFlyoutOpen: false })}
            deployment={deployment}
          />
        )}
      </Fragment>
    )
  }
}

export default AddApiKey
