/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import type { FC } from 'react'

export interface Props {
  trialDaysRemaining: number | undefined
}

const RemainingTrial: FC<Props> = ({ trialDaysRemaining }) => {
  if (trialDaysRemaining) {
    return (
      <span css={css({ fontWeight: 600 })} data-test-id='trial-summary-modal.trial-days-remaining'>
        <FormattedMessage
          id='trial-summary-modal.trial-days-remaining'
          defaultMessage='{trialDaysRemaining} {trialDaysRemaining, plural, one {day} other {days}} left'
          values={{
            trialDaysRemaining,
          }}
        />
      </span>
    )
  }

  return null
}

export default RemainingTrial
