/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { connect } from 'react-redux'

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type { CloudAppName, ProfileState, Theme } from '@modules/ui-types'
import Feature from '@modules/utils/feature'
import { hasUnexpiredSession } from '@modules/auth/auth'
import { getUsername } from '@modules/profile-lib'

import { getProfile } from '@/apps/userconsole/reducers/profile'
import { getCurrentUserUsername } from '@/reducers/currentUser'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { getTheme } from '@/reducers/theme'

import { setTheme } from '../../actions/theme'
import { isFeatureActivated, getConfigForKey } from '../../selectors'
import { hasUserSettings } from '../UserSettings/helpers'
import { shouldShowOrganization } from '../Organization/lib'

import ChromeHeader from './ChromeHeader'

import type { ReduxState } from '@/types/redux'

type StateProps = {
  loggedIn: boolean
  hasUserSettings: boolean
  theme: Theme
  appName: CloudAppName
  profile: ProfileState
  getStackDeploymentById: (id: string) => DeploymentGetResponse | undefined
  cloudPortalEnabled: boolean
  isHeroku: boolean
  username: string | null
  showOrganization: boolean
  isDriftEnabled: boolean
}

type DispatchProps = {
  setTheme: (theme: Theme) => void
}

const mapStateToProps = (state: ReduxState): StateProps => {
  const appName = getConfigForKey(state, `APP_NAME`)!
  const isAdminconsole = getConfigForKey(state, `APP_NAME`) === `adminconsole`
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const profile = getProfile(state)

  const isDriftEnabled = Boolean(
    getConfigForKey(state, 'DRIFT_PLAYBOOK_TRIAL') ||
      getConfigForKey(state, 'DRIFT_PLAYBOOK_MARKETPLACE') ||
      getConfigForKey(state, 'DRIFT_PLAYBOOK_DIRECT_PAYING') ||
      getConfigForKey(state, 'DRIFT_PLAYBOOK_OLD_MARKETPLACE'),
  )

  return {
    loggedIn: hasUnexpiredSession(),
    cloudPortalEnabled: isFeatureActivated(state, Feature.cloudPortalEnabled),
    hasUserSettings: hasUserSettings(state),
    theme: getTheme(state),
    appName,
    profile,
    getStackDeploymentById: (id) => getStackDeployment(state, id),
    isHeroku: getConfigForKey(state, `APP_FAMILY`) === `heroku`,
    username: isAdminconsole ? getCurrentUserUsername(state) : getUsername({ isHeroku, profile }),
    showOrganization: shouldShowOrganization(profile),
    isDriftEnabled,
  }
}

const mapDispatchToProps: DispatchProps = {
  setTheme,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  // Because we rely on `context` within ChromeHeader (via <Search />), we can't treat the
  // component as pure.
  pure: false,
})(ChromeHeader)
