/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cancelButton: {
    id: 'organization.security.role-mapping.cancel-button',
    defaultMessage: 'Cancel',
  },
  saveButton: {
    id: 'organization.security.role-mapping.save-button',
    defaultMessage: 'Save',
  },
  editRoleSuccessfully: {
    id: 'organization.security.role-mapping.edit-role-successfully',
    defaultMessage: 'Role mapping updated',
  },
  createRoleSuccessfully: {
    id: 'organization.security.role-mapping.create-role-successfully',
    defaultMessage: 'Role mapping created',
  },
  errorSavingRoleMapping: {
    id: 'organization.security.role-mapping.error-saving-role-mapping',
    defaultMessage: 'Failed to save the role mapping due to an unknown error. Try again later.',
  },
})

export const formMessages = defineMessages({
  nameRequired: {
    id: `organization.security.role-mapping.name-required`,
    defaultMessage: `A name is required.`,
  },
  ruleRequired: {
    id: `organization.security.role-mapping.rule-required`,
    defaultMessage: `At least one rule is required.`,
  },
  roleAssignmentsRequired: {
    id: `organization.security.role-mapping.role-assignments-required`,
    defaultMessage: `At least one role is required.`,
  },
  attributeValueRequired: {
    id: `organization.security.role-mapping.attribute-value-required`,
    defaultMessage: `An attribute value is required.`,
  },
  roleMappingNameExists: {
    id: `organization.security.role-mapping.name-exists`,
    defaultMessage: `A role mapping with this name already exists. Provide a different name.`,
  },
  duplicateGroupValue: {
    id: `organization.security.role-mapping.duplicate-group-value`,
    defaultMessage: `This value is duplicated. Remove duplicates to keep only one occurrence.`,
  },
})
