/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiButton, EuiCallOut, EuiFieldText, EuiSpacer, EuiText, EuiTextAlign } from '@elastic/eui'

import { useGetOrganizationSsoFindIdp } from '@modules/security-idp-lib/hooks'
import type { FindIdpResult } from '@modules/cloud-api/v1/types'
import { addToast } from '@modules/cui/Toasts'

import TermsOfService from '@/components/CreateAccountForm/TermsOfService'
import validateEmail from '@/lib/validateEmail'

import { messages } from '../messages'
import BackToLoginButton from '../BackToLoginButton'

const EmailForSso = ({
  onReceiveIdpList,
}: {
  onReceiveIdpList: (idpList: FindIdpResult[]) => void
}) => {
  const { formatMessage } = useIntl()
  const [email, setEmail] = useState('')
  const [submittedEmail, setSubmittedEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>(undefined)
  const { data, isLoading: isLoadingIdpList, error } = useGetOrganizationSsoFindIdp(submittedEmail)
  const { idps } = data || {}

  useEffect(() => {
    if (error) {
      addToast({
        id: 'error',
        title: <FormattedMessage {...messages.unknownError} />,
        color: 'danger',
        iconType: 'alert',
      })
    }
  }, [error])

  useEffect(() => {
    if (isValidEmail === false) {
      setErrorMessage(formatMessage(messages.invalidEmail))
      return
    }

    if (idps?.length === 0) {
      setErrorMessage(formatMessage(messages.errorNoIdpDetected))
      return
    }
  }, [isValidEmail, idps])

  useEffect(() => {
    if (idps?.length === 0) {
      setIsValidEmail(undefined)
    }

    if (idps && idps.length > 0) {
      onReceiveIdpList(idps)
    }
  }, [idps])

  const onSubmit = (event) => {
    event.preventDefault()

    if (!validateEmail(email)) {
      setIsValidEmail(false)
      return
    }

    setIsValidEmail(true)
    setSubmittedEmail(email)
  }

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <EuiFieldText
          value={email}
          name='email'
          icon={'email'}
          onChange={(e) => setEmail(e.target.value)}
          onSubmit={onSubmit}
          placeholder={formatMessage(messages.email)}
          aria-label={formatMessage(messages.email)}
        />
        <EuiSpacer size='m' />
        <TermsOfService />
        <EuiSpacer size='m' />
        <EuiButton
          onClick={onSubmit}
          isDisabled={!email}
          isLoading={isLoadingIdpList}
          fill={true}
          fullWidth={true}
          type='submit'
        >
          <FormattedMessage {...messages.loginButton} />
        </EuiButton>
      </form>
      <EuiSpacer size='m' />
      {errorMessage && <ErrorPrompt message={errorMessage} />}
      <EuiSpacer size='m' />
      <BackToLoginButton />
    </Fragment>
  )
}

const ErrorPrompt = ({ message }: { message: string }) => (
  <EuiCallOut color='danger' iconType='alert'>
    <EuiText size='m' textAlign='center' color='danger'>
      <EuiTextAlign textAlign='left'>{message}</EuiTextAlign>
    </EuiText>
  </EuiCallOut>
)

export default EmailForSso
