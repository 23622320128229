/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import type { PropsOf } from '@elastic/eui'
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHealth } from '@elastic/eui'

import { parseError } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import type { ReactElement } from 'react'
import type { OrganizationMemberStatus as MemberStatus } from '../types'
import type { AllProps as Props, ColorMap, ResendButtonStatus, State } from './types'

const colors: ColorMap = {
  active: 'success',
  expired: 'danger',
  pending: 'subdued',
}

const messages = defineMessages<MemberStatus>({
  active: {
    id: 'organization.organization-members.organization-member-status.active',
    defaultMessage: 'Active',
  },
  expired: {
    id: 'organization.organization-members.organization-member-status.invite-expired',
    defaultMessage: 'Invite expired',
  },
  pending: {
    id: 'organization.organization-members.organization-member-status.invite-pending',
    defaultMessage: 'Invite pending',
  },
})

const resendButtonMessages = defineMessages<ResendButtonStatus>({
  resend: {
    id: 'organization.organization-members.organization-member-status.resend-invite',
    defaultMessage: 'Resend invite',
  },
  done: {
    id: 'organization.organization-members.organization-member-status.invite-sent',
    defaultMessage: 'Invite sent',
  },
  sending: {
    id: 'organization.organization-members.organization-member-status.sending',
    defaultMessage: 'Sending...',
  },
})

const RESEND_BUTTON_STATUS_TIMEOUT = 3000

const initialState: State = {
  resendButtonStatus: `resend`,
}

class OrganizationMemberStatus extends Component<Props, State> {
  state: State = initialState

  _resentButtonStatusTimeoutId: number | null = null

  componentWillUnmount() {
    if (this._resentButtonStatusTimeoutId) {
      clearTimeout(this._resentButtonStatusTimeoutId)
    }
  }

  render(): ReactElement {
    const {
      intl: { formatMessage },
      organizationMemberRow: { isInvitation, status },
      organizationId,
      isAdminconsole,
    } = this.props

    const { resendButtonStatus } = this.state

    const resendButtonProps: PropsOf<typeof EuiButtonEmpty> = {
      color: resendButtonStatus === `done` ? `success` : undefined,
      iconType: resendButtonStatus === `done` ? `check` : undefined,
    }

    const color = colors[status]
    const message = messages[status]
    const resendButtonMessage = resendButtonMessages[resendButtonStatus]

    return (
      <EuiFlexGroup responsive={false} wrap={true} gutterSize='s'>
        <EuiFlexItem
          style={{ minWidth: '115px' }}
          data-test-id={`organization.organization-members.organization-member-status.status.${status}`}
        >
          <EuiHealth color={color}>{formatMessage(message)}</EuiHealth>
        </EuiFlexItem>

        {isInvitation && !isAdminconsole && (
          <EuiFlexItem
            grow={false}
            data-test-id='organization.organization-members.organization-member-status.resend'
          >
            <PermissionsGate
              permissions={[
                {
                  organizationId,
                  type: 'organization-invitation',
                  action: 'create',
                },
              ]}
            >
              {({ hasPermissions, isLoading }) => (
                <EuiButtonEmpty
                  {...resendButtonProps}
                  onClick={this.resendInvite}
                  isDisabled={!hasPermissions}
                  isLoading={isLoading || resendButtonStatus === `sending`}
                  style={{ fontWeight: 400, height: '24px' }}
                >
                  {formatMessage(resendButtonMessage)}
                </EuiButtonEmpty>
              )}
            </PermissionsGate>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  resendInvite = (): void => {
    const {
      resendOrganizationInvitation,
      organizationMemberRow: { email },
    } = this.props

    const { resendButtonStatus } = this.state

    if (resendButtonStatus !== `resend`) {
      return
    }

    new Promise<void>((resolve) => {
      this.setState({ resendButtonStatus: `sending` }, () => resolve())
    })
      .then(resendOrganizationInvitation)
      .then(() => {
        this.setState({ resendButtonStatus: `done` }, () => {
          this._resentButtonStatusTimeoutId = window.setTimeout(
            () => this.setState({ resendButtonStatus: `resend` }),
            RESEND_BUTTON_STATUS_TIMEOUT,
          )
        })
      })
      .catch((error) => {
        this.setState({ resendButtonStatus: `resend` })

        addToast({
          family: 'organization.organization-members.organization-member-status',
          color: 'danger',
          title: (
            <FormattedMessage
              id='organization.organization-members.organization-member-status.failure'
              defaultMessage='Could not send invite to {email}'
              values={{ email }}
            />
          ),
          text: parseError(error),
        })
      })
  }
}

export default injectIntl(OrganizationMemberStatus)
