/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep } from 'lodash'

import type {
  DeploymentUpdateRequest,
  DeploymentCreateRequest,
  ElasticsearchPayload,
  ElasticsearchClusterPlan,
  ElasticsearchClusterTopologyElement,
} from '@modules/cloud-api/v1/types'

import {
  getNodeRoles,
  supportsFrozenTierAutoscaling,
  isFrozen,
  isDedicatedML,
} from '@/lib/stackDeployments/selectors/nodeRoles'
import { getFirstEsCluster } from '@/lib/stackDeployments/selectors/stackDeployment'
import { isAnyAutoscalingEnabled } from '@/lib/stackDeployments/selectors/autoscaling'

import { getInTrial } from '../reduxShortcuts/trials'
import { getSliderTrialLimit } from '../sliders/trials'

export type AutoscalingEnabled = 'all' | 'ml' | 'none'

function removeAutoscalingFromTopology(topologyElement: ElasticsearchClusterTopologyElement) {
  delete topologyElement.autoscaling_min
  delete topologyElement.autoscaling_max
  delete topologyElement.autoscaling_policy_override_json
}

export function sanitizeForAutoscaling({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): void {
  const { resources } = deployment

  if (!resources) {
    return
  }

  const esResource = getFirstEsCluster({ deployment })

  if (!esResource || !esResource.plan || !esResource.plan.cluster_topology) {
    return
  }

  if (!isAnyAutoscalingEnabled({ deployment })) {
    // remove autoscaling data
    esResource.plan.cluster_topology.forEach(removeAutoscalingFromTopology)
  } else {
    const ml = esResource.plan.cluster_topology.find((topologyElement) =>
      isDedicatedML({ topologyElement }),
    )

    if (ml) {
      delete ml.size
    }

    const esVersion = esResource.plan.elasticsearch.version

    if (esVersion && !supportsFrozenTierAutoscaling({ version: esVersion })) {
      esResource.plan.cluster_topology
        .filter((topologyElement) => isFrozen({ topologyElement }))
        .forEach(removeAutoscalingFromTopology)
    }
  }

  // remove plan-level autoscaling flag if specified at deployment level
  if (deployment.settings?.autoscaling_enabled !== undefined) {
    delete esResource.plan.autoscaling_enabled
  }
}

export function setMLOnlyAutoscalingEnabled<
  T extends DeploymentUpdateRequest | DeploymentCreateRequest,
>({
  deployment,
  blankTemplate,
  templateOnly = false,
}: {
  deployment: T
  blankTemplate?: ElasticsearchClusterPlan | null
  templateOnly?: boolean
}): T {
  const newDeployment = cloneDeep(deployment)
  // disable the "normal" autoscaling
  const autoscalingDisabled = templateOnly
    ? setAutoscalingDisabledForTemplate({ deployment: newDeployment })
    : setAutoscalingDisabled({ deployment: newDeployment })

  // now go ahead and enable autoscaling for ML only
  const { resources } = autoscalingDisabled

  if (!resources) {
    return autoscalingDisabled // sanity as we should never get into this situation
  }

  const esResource = getFirstEsCluster({ deployment: autoscalingDisabled })

  if (!esResource || !esResource.plan || !esResource.plan.cluster_topology) {
    return autoscalingDisabled // sanity as we should never get into this situation
  }

  const ml = esResource.plan.cluster_topology.find((topologyElement) =>
    isDedicatedML({ topologyElement }),
  )

  if (!ml) {
    return autoscalingDisabled // sanity as we should never get into this situation
  }

  ml.autoscaling_tier_override = true

  const inTrial = getInTrial()
  addAutoscalingMinMaxToTopologyElement(ml, inTrial, blankTemplate)

  return autoscalingDisabled
}

function setMLOnlyAutoscalingDisabled<T extends DeploymentUpdateRequest | DeploymentCreateRequest>({
  deployment,
}: {
  deployment: T
}): T {
  const newDeployment = cloneDeep(deployment)

  // now go ahead and enable autoscaling for ML only
  const { resources } = newDeployment

  if (!resources) {
    return newDeployment // sanity as we should never get into this situation
  }

  const esResource = getFirstEsCluster({ deployment: newDeployment })

  if (!esResource || !esResource.plan || !esResource.plan.cluster_topology) {
    return newDeployment // sanity as we should never get into this situation
  }

  const ml = esResource.plan.cluster_topology.find((topologyElement) =>
    isDedicatedML({ topologyElement }),
  )

  if (!ml) {
    return newDeployment // sanity as we should never get into this situation
  }

  ml.autoscaling_tier_override = false

  return newDeployment
}

export function setAutoscalingEnabled<T extends DeploymentUpdateRequest | DeploymentCreateRequest>({
  deployment,
  blankTemplate,
}: {
  deployment: T
  blankTemplate?: ElasticsearchClusterPlan | null
}): T {
  const newDeployment = cloneDeep(deployment)

  newDeployment.settings ??= {}
  newDeployment.settings.autoscaling_enabled = true

  newDeployment.resources?.elasticsearch?.forEach((resource) =>
    addAutoscalingMinMax(resource, blankTemplate),
  )

  // turn off the ML only autoscaling as this option is mutually exclusive
  return setMLOnlyAutoscalingDisabled({ deployment: newDeployment })
}

export function setAutoscalingDisabled<
  T extends DeploymentUpdateRequest | DeploymentCreateRequest,
>({ deployment }: { deployment: T }): T {
  const newDeployment = cloneDeep(deployment)

  newDeployment.settings ??= {}
  newDeployment.settings.autoscaling_enabled = false

  newDeployment.resources?.elasticsearch?.forEach((resource) => removeAutoscalingMinMax(resource))

  return setMLOnlyAutoscalingDisabled({ deployment: newDeployment })
}

export function setAutoscalingDisabledForTemplate<
  T extends DeploymentUpdateRequest | DeploymentCreateRequest,
>({ deployment }: { deployment: T }): T {
  const newDeployment = cloneDeep(deployment)

  newDeployment.settings ??= {}
  newDeployment.settings.autoscaling_enabled = false

  return setMLOnlyAutoscalingDisabled({ deployment: newDeployment })
}

function addAutoscalingMinMaxToTopologyElement(
  topologyElement: ElasticsearchClusterTopologyElement,
  inTrial: boolean,
  blankTemplate?: ElasticsearchClusterPlan | null,
): void {
  const trialLimit = getSliderTrialLimit({
    inTrial,
    sliderInstanceType: `elasticsearch`,
    sliderNodeTypes: getNodeRoles({ topologyElement }),
  })?.memorySize

  let templateTopologyItem

  if (blankTemplate?.cluster_topology) {
    templateTopologyItem = blankTemplate.cluster_topology.find(
      (topologyItem) => topologyItem.id === topologyElement.id,
    )
  }

  const minSize = templateTopologyItem?.autoscaling_min?.value
  const maxSize = templateTopologyItem?.autoscaling_max?.value

  if (typeof minSize === 'number') {
    const value = trialLimit ? Math.min(minSize, trialLimit) : minSize
    topologyElement.autoscaling_min = { value, resource: 'memory' }
  }

  if (typeof maxSize === 'number') {
    const value = trialLimit ? Math.min(maxSize, trialLimit) : maxSize
    topologyElement.autoscaling_max = { value, resource: 'memory' }
  }
}

function addAutoscalingMinMax(
  resource: ElasticsearchPayload,
  blankTemplate?: ElasticsearchClusterPlan | null,
): void {
  if (!Array.isArray(resource.plan?.cluster_topology)) {
    return
  }

  const inTrial = getInTrial()
  resource.plan.cluster_topology.forEach((topologyElement) => {
    addAutoscalingMinMaxToTopologyElement(topologyElement, inTrial, blankTemplate)
  })
}

function removeAutoscalingMinMax(resource: ElasticsearchPayload): void {
  if (!resource.plan) {
    return
  }

  if (!Array.isArray(resource.plan.cluster_topology)) {
    return
  }

  resource.plan.cluster_topology.forEach((nodeConfiguration) => {
    delete nodeConfiguration.autoscaling_min
    delete nodeConfiguration.autoscaling_max
  })
}
