/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { EuiSpacer } from '@elastic/eui'

import type { ClusterSnapshot, StackDeployment } from '@modules/ui-types'

import { isPendingInitialSnapshot } from '@/lib/stackDeployments/selectors/snapshots'
import { getFirstEsClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'

import ShardFailures from './ShardFailures'
import SnapshotHistory from './SnapshotHistory'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
  snapshotHistory: ClusterSnapshot[] | undefined
  readonly?: boolean
}

const ClusterSnapshotList: FunctionComponent<Props> = ({
  deployment,
  snapshotHistory,
  readonly,
}) => {
  const resource = getFirstEsClusterFromGet({ deployment })

  if (!resource) {
    return null
  }

  const pendingInitialSnapshot = isPendingInitialSnapshot({ resource })

  if (pendingInitialSnapshot) {
    return null
  }

  const failures = snapshotHistory && snapshotHistory[0] ? snapshotHistory[0].failures : []

  return (
    <Fragment>
      {failures.length > 0 && (
        <Fragment>
          <EuiSpacer size='m' />
          <ShardFailures failures={failures} />
          <EuiSpacer size='m' />
        </Fragment>
      )}

      <EuiSpacer size='m' />

      <SnapshotHistory
        deployment={deployment}
        readonly={Boolean(readonly)}
        data-test-id='cluster-snapshots.snapshot-history'
      />
    </Fragment>
  )
}

export default ClusterSnapshotList
