/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import moment from 'moment'

import type { Product } from '@modules/billing-api/v1/types'

import type { DeploymentCosts, ProjectCosts } from './types'

interface DateRange {
  from: string
  to: string
}

export const getPreviousMonthDates = (): DateRange => ({
  from: moment.utc().subtract(1, 'months').startOf('month').format(),
  to: moment.utc().subtract(1, 'months').endOf('month').format(),
})

export const getPrettyPreviousMonthDates = (): string =>
  `${moment().add(-1, 'months').format('MMMM YYYY')}`

export const getCurrentMonthDates = (): DateRange => ({
  from: moment.utc().startOf('month').format(),
  to: moment.utc().endOf('day').format(),
})

export const getPrettyCurrentMonthDates = (): string => {
  const isFirstDayOfMonth = moment().format('D') === '1'

  return isFirstDayOfMonth
    ? moment().format('MMMM D, YYYY')
    : `${moment().format('MMMM')} 1 - ${moment().format('D, YYYY')}`
}

export const getProjectsTotalUsage = (products: Product[] = []): number =>
  products.reduce((curr, { type, total_ecu }) => {
    if (
      [
        'security',
        'observability',
        'elasticsearch',
        'serverless_addons',
        'serverless_support',
      ].includes(type)
    ) {
      return curr + total_ecu
    }

    return curr
  }, 0)

const getProjectInstances = (products: Product[] = []): Product[] =>
  products.filter(({ type }) =>
    [
      'security',
      'elasticsearch',
      'observability',
      'serverless_addons',
      'serverless_support',
    ].includes(type),
  )

export const getDeploymentsTotalUsage = (products: Product[] = []): number => {
  const deploymentProduct = products.find(({ type }) => type === 'deployment')

  return deploymentProduct?.total_ecu || 0
}

export const getDeploymentCosts = (products: Product[] = []): DeploymentCosts => {
  const deploymentInstance = products.find(({ type }) => type === 'deployment')
  const costs = deploymentInstance?.product_line_items.reduce(
    (currProduct, { type, total_ecu }) => {
      currProduct[type] = total_ecu + (currProduct[type] || 0)
      return currProduct
    },
    {},
  )
  return costs || {}
}

export const getProjectCosts = (products: Product[] = []): ProjectCosts | null => {
  const projectInstances = getProjectInstances(products)

  if (!projectInstances.length) {
    return null
  }

  const costs = {} as ProjectCosts

  projectInstances.forEach(({ type, product_line_items }) => {
    const groupedValues = product_line_items.reduce(
      (currProduct, { type: dimensionType, total_ecu }) => {
        currProduct[dimensionType] = total_ecu + (currProduct[dimensionType] || 0)
        return currProduct
      },
      {},
    )

    costs[type] = Object.keys(groupedValues).map((dimensionType) => ({
      type: dimensionType.charAt(0).toUpperCase() + dimensionType.slice(1),
      total_ecu: groupedValues[dimensionType],
    }))
  })

  return costs
}
