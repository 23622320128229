/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt } from '@elastic/eui'

import type { FunctionComponent } from 'react'

const OrganizationNoRolesEmptyPrompt: FunctionComponent = () => (
  <EuiEmptyPrompt
    data-test-subj='organization-no-roles-empty-prompt'
    iconType='lock'
    color='plain'
    hasBorder={false}
    hasShadow={true}
    title={
      <h2>
        <FormattedMessage
          id='organization.no-roles-empty-prompt.title'
          defaultMessage={`Contact your administration for access`}
        />
      </h2>
    }
    body={
      <p>
        <FormattedMessage
          id='organization.no-roles-empty-prompt.subtitle'
          defaultMessage={`To view your organization details, an administrator 
          must assign you a role first.`}
        />
      </p>
    }
  />
)

export default OrganizationNoRolesEmptyPrompt
