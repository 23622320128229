/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { useContext, type FunctionComponent } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import { outlineButtonStyle } from '@modules/access-management-components/styles'
import AuthContext from '@modules/auth/context'

import { applyGoogleAnalytics } from '@/components/CreateAccountForm/lib'
import { buildOpenIdSignUpQuery } from '@/lib/urlUtils'

import googleIcon from '../../../files/google-logo.svg'

import type { GoogleSignUpProps as Props } from '../types'

const GoogleSignUp: FunctionComponent<Props> = ({
  fullText = true,
  isFlowV2 = false,
  location: { search },
  redirectTo,
  loginWithGoogle,
  disabled,
  isCreateUser,
}) => {
  const { setAuthContext } = useContext(AuthContext)

  return (
    <EuiButton
      onClick={() => {
        setAuthContext({ method: 'google' })
        applyGoogleAnalytics({ isCreateUser })
        loginWithGoogle(buildOpenIdSignUpQuery(search, { redirectTo }))
      }}
      fullWidth={true}
      disabled={disabled}
      css={isFlowV2 && outlineButtonStyle}
      data-track-id={`${isCreateUser ? 'signup' : 'login'}-google-button-v${isFlowV2 ? '2' : '1'}`}
    >
      <EuiFlexGroup alignItems='center' justifyContent='center' gutterSize='s' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon size='m' type={googleIcon} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {fullText ? (
            <FormattedMessage
              id='google-signup-form.signup-google'
              defaultMessage='Sign up with Google'
            />
          ) : (
            <FormattedMessage id='google-signup-form.google' defaultMessage='Google' />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiButton>
  )
}

export default GoogleSignUp
