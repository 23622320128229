/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { StackDeploymentCreateRequest } from '@modules/ui-types'

// eslint-disable-next-line import/no-restricted-paths
import { replaceIn } from '@/lib/immutability-helpers'

import type { DefaultSolutionView } from './types'

export function insertSolutionType(
  editorState: StackDeploymentCreateRequest,
  solutionType: DefaultSolutionView | undefined,
): StackDeploymentCreateRequest {
  if (solutionType === undefined) {
    return editorState
  }

  return replaceIn(editorState, ['deployment', 'settings', 'solution_type'], solutionType)
}
