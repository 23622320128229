/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { without } from 'lodash'

import { EuiDescribedFormGroup, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import GovCloudGate from '../../../../components/GovCloudGate'
import AllowedDomains from '../../../../components/Organization/AllowedDomains'
import DocLink from '../../../../components/DocLink'

import ContactTable from './ContactTable'

import type { ElasticLDProps } from '../LaunchdarklyWrapper/launchDarklyFlagTypes'
import type { FunctionComponent, ReactNode } from 'react'

const EMAIL_REGEX = /[^@]+@[^\.]+\..+/

type Props = {
  operationalContacts: string[]
  billingContacts: string[]
  onSave: (type: string, contacts: string[]) => void
  organizationId?: string
  isLaunchDarklyActivated: boolean
} & ElasticLDProps

const ContactForm: FunctionComponent<Props> = ({
  operationalContacts,
  billingContacts,
  onSave,
  organizationId,
}) => {
  const [errors, setErrors] = useState<{ operational: ReactNode; billing: ReactNode }>({
    operational: null,
    billing: null,
  })

  const add = (type: string, contactToAdd: string) => {
    const existingContacts = getContacts(type)
    const nextErrors = getValidationErrors({ existingContacts, contactToAdd, type })
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...nextErrors,
    }))

    if (nextErrors[type]) {
      return Promise.reject()
    }

    const nextContacts = existingContacts.concat([contactToAdd])
    return Promise.resolve(onSave(type, nextContacts))
  }

  const remove = (type: string, contactToRemove: string) => {
    const existingContacts = getContacts(type)
    const nextContacts = without(existingContacts, contactToRemove)
    onSave(type, nextContacts)
  }

  const getContacts = (type: string) => {
    const contactTypes = {
      operational: operationalContacts,
      billing: billingContacts,
    }

    return contactTypes[type]
  }

  function getValidationErrors({ existingContacts, contactToAdd, type }) {
    return {
      [type]: getClientError(),
    }

    function getClientError(): ReactNode {
      const value = contactToAdd.trim()

      if (!EMAIL_REGEX.test(value)) {
        return (
          <FormattedMessage
            id='uc.accountDetails.billingContactForm.invalidEmailError'
            defaultMessage='Not a valid email address'
          />
        )
      }

      if (existingContacts.includes(value)) {
        return (
          <FormattedMessage
            id='uc.accountDetails.billingContactForm.emailAlreadyExistsError'
            defaultMessage='This contact is already included'
          />
        )
      }

      return null
    }
  }

  return (
    <div>
      <EuiSpacer size='m' />

      <EuiFlexGroup direction='column' gutterSize='xl'>
        <GovCloudGate>
          <EuiFlexItem>
            <EuiDescribedFormGroup
              title={
                <h3>
                  <FormattedMessage
                    id='uc.accountDetails.billingContactForm.header'
                    defaultMessage='Billing contacts'
                  />
                </h3>
              }
              description={
                <p>
                  <FormattedMessage
                    id='uc.accountDetails.billing.emails.description'
                    defaultMessage='Customize who receives billing emails in your organization.'
                  />
                </p>
              }
            >
              <ContactTable
                contacts={billingContacts}
                error={errors.billing}
                onRemove={remove.bind(null, 'billing')}
                onAdd={add.bind(null, 'billing')}
              />
            </EuiDescribedFormGroup>
          </EuiFlexItem>
        </GovCloudGate>

        <EuiFlexItem>
          <EuiDescribedFormGroup
            title={
              <h3>
                <FormattedMessage
                  id='uc.accountDetails.operationalContactForm.header'
                  defaultMessage='Operational email contacts'
                />
              </h3>
            }
            description={
              <p>
                <FormattedMessage
                  id='uc.accountDetails.operationial.emails.description'
                  defaultMessage='Add contacts that should receive operational notifications, such as out-of-memory alerts, but would not have access to the console.'
                />
              </p>
            }
          >
            <ContactTable
              contacts={operationalContacts}
              error={errors.operational}
              onRemove={remove.bind(null, 'operational')}
              onAdd={add.bind(null, 'operational')}
            />
          </EuiDescribedFormGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiDescribedFormGroup
            title={
              <h3>
                <FormattedMessage
                  id='uc.accountDetails.allowedDomains.header'
                  defaultMessage='Domain allowlist'
                />
              </h3>
            }
            description={
              <p>
                <FormattedMessage
                  id='uc.accountDetails.allowedDomains.flyout.description'
                  defaultMessage='Provide the list of domains that are allowed to receive email notifications from Elastic Cloud deployments. Allowlists
                    are supported for deployments on versions 7.17.7 and higher, and deployments on versions 8.5.1 and higher. After saving your changes,
                    run a configuration change on each deployment to apply the new allowlist. <link>Learn More</link>'
                  values={{
                    link: (content) => (
                      <DocLink link='organizationsAllowedDomains'>{content}</DocLink>
                    ),
                  }}
                />
              </p>
            }
          >
            {organizationId && <AllowedDomains organizationId={organizationId} />}
          </EuiDescribedFormGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

export default ContactForm
