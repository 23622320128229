/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import type { EuiButtonIconProps, EuiPopoverProps, EuiTextProps } from '@elastic/eui'
import { EuiButtonIcon, EuiPopover, EuiText, htmlIdGenerator } from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'

import type { ReactNode } from 'react'
import type { WrappedComponentProps } from 'react-intl'

const makeId = htmlIdGenerator()

interface Props extends WrappedComponentProps {
  children: NonNullable<ReactNode>
  color?: EuiButtonIconProps['color']
  iconType?: EuiButtonIconProps['iconType']
  anchorPosition?: EuiPopoverProps['anchorPosition']
  textColor?: EuiTextProps['color']
  textSize?: EuiTextProps['size']
  width?: number
}

const messages = defineMessages({
  showHelp: {
    id: 'cui.tooltip-icon.show-help',
    defaultMessage: 'Show help',
  },
})

const CuiHelpTipIcon = ({
  intl: { formatMessage },
  children,
  anchorPosition = 'leftCenter',
  textColor,
  textSize,
  width = 300,
  iconType = 'questionInCircle',
  ...rest
}: Props) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  return (
    <EuiPopover
      className='cuiHelpTipIcon'
      id={makeId()}
      ownFocus={true}
      panelPaddingSize='s'
      anchorPosition={anchorPosition}
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen(false)}
      button={
        <EuiButtonIcon
          aria-label={formatMessage(messages.showHelp)}
          onClick={togglePopover}
          iconType={iconType}
          color='text'
          {...rest}
        />
      }
    >
      <EuiText color={textColor} size={textSize} style={{ width }}>
        {children}
      </EuiText>
    </EuiPopover>
  )
}

const CuiHelpTipIconWithIntl = injectIntl(CuiHelpTipIcon)

export { CuiHelpTipIconWithIntl as CuiHelpTipIcon }
