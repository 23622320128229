/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'

import { EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { isPrepaidConsumptionDirectCustomer } from '@modules/billing-lib/billingDetails/utils'

import ErrorCallout from '../ErrorCallout'

import PrepaidConsumptionSubscription from './PrepaidConsumptionSubscription'

import type { AllProps } from './types'

class BillingSubscription extends Component<AllProps> {
  componentDidMount() {
    this.redirectIfPageIsNotEnabled()
  }

  componentDidUpdate() {
    this.redirectIfPageIsNotEnabled()
  }

  render() {
    const { billingDetails, profile, showPrepaidDetails } = this.props
    const { error, loading, refetch, data } = billingDetails

    if (error) {
      return (
        <Fragment>
          <EuiSpacer size='xxl' />
          <ErrorCallout onReload={refetch} data-test-id='errorCallout' />
        </Fragment>
      )
    }

    if (loading) {
      return <EuiSkeletonText data-test-id='loadingContent' />
    }

    if (isPrepaidConsumptionDirectCustomer(data!)) {
      return (
        <PrepaidConsumptionSubscription
          profile={profile!}
          showPrepaidDetails={showPrepaidDetails}
          billingDetails={billingDetails}
        />
      )
    }

    // this should never happen
    this.redirectIfPageIsNotEnabled()
    return null
  }

  // TODO: remove this redirect once all customer types can see the new UI
  redirectIfPageIsNotEnabled() {
    const { history, billingDetails, redirectToWhenDisabled } = this.props

    if (billingDetails.data && !isPrepaidConsumptionDirectCustomer(billingDetails.data)) {
      history.replace(redirectToWhenDisabled)
    }
  }
}

export default BillingSubscription
