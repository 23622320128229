/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'querystring'

import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { isPrepaidConsumptionDirectCustomer } from '@modules/billing-lib/billingDetails/utils'
import useMarketplaceBillingUIEnabled from '@modules/billing-lib/marketplaces/useMarketplaceBillingUIEnabled'

import CostAnalysis from '@/components/User/CostAnalysis'

import ErrorCallout from '../ErrorCallout'

import BillingUsageOverviewV2 from './BillingUsageOverviewV2'
import BillingUsageByInstance from './BillingUsageByInstance'

import type { FunctionComponent } from 'react'
import type { AllProps } from './types'

const BillingUsage: FunctionComponent<AllProps> = ({ organizationId, billingDetails }) => {
  const { search } = useLocation()
  const instanceId = parse(search).instanceId as string | undefined
  const [instanceName, setInstanceName] = useState<string | undefined>()
  const isMarketplaceUIEnabled = useMarketplaceBillingUIEnabled()
  const shouldShowLoading = !organizationId || billingDetails.loading

  if (billingDetails.error) {
    return (
      <Fragment>
        <EuiSpacer size='xxl' />
        <ErrorCallout onReload={billingDetails.refetch} />
      </Fragment>
    )
  }

  if (shouldShowLoading) {
    return <EuiSkeletonText />
  }

  if (isPrepaidConsumptionDirectCustomer(billingDetails.data!) || isMarketplaceUIEnabled) {
    if (instanceId) {
      return (
        <BillingUsageByInstance
          organizationId={organizationId}
          instanceId={instanceId}
          instanceName={instanceName}
        />
      )
    }

    return (
      <BillingUsageOverviewV2 organizationId={organizationId} setInstanceName={setInstanceName} />
    )
  }

  // Legacy UI
  return (
    <CostAnalysis
      organizationId={organizationId}
      data-test-id='old-billing-usage-ui'
      instanceId={instanceId}
      instanceName={instanceName}
      setInstanceName={setInstanceName}
    />
  )
}

export default BillingUsage
