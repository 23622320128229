/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { type FunctionComponent } from 'react'
import { injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiIcon, EuiSuperSelect } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'
import type { PlatformId } from '@modules/ui-types'
import { getPlatformInfoById } from '@modules/utils/platform'
import type { PlatformInfo } from '@modules/utils/platform'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  platform: PlatformId
  availablePlatforms: PlatformId[]
  onChange: (platform: string) => void
  restoreFromSnapshot: boolean
  disabled?: boolean
  prepend?: boolean
  'aria-labelledby'?: string
}

const SelectCloudPlatform: FunctionComponent<Props> = ({
  restoreFromSnapshot,
  prepend = true,
  intl: { formatMessage },
  platform,
  availablePlatforms,
  onChange,
  disabled,
  'aria-labelledby': ariaLabelledBy,
}) => {
  const label = formatMessage({
    id: 'select-platform-label',
    defaultMessage: 'Cloud provider',
  })

  const platformOptions = availablePlatforms
    .map((key) => getPlatformInfoById(key))
    .map((platformItem) => ({
      inputDisplay: <PlatformOption platform={platformItem} />,
      value: platformItem.id,
    }))

  return (
    <EuiSuperSelect
      fullWidth={true}
      popoverProps={{ css: { '.euiFormControlLayout__prepend': { width: 180 } } }}
      prepend={prepend ? label : undefined}
      options={platformOptions}
      valueOfSelected={platform}
      onChange={onChange}
      disabled={restoreFromSnapshot || disabled}
      data-test-id='platform-combobox'
      aria-label={ariaLabelledBy ?? label}
    />
  )
}

interface PlatformOptionProps {
  platform: PlatformInfo
}
const PlatformOption: FunctionComponent<PlatformOptionProps> = ({ platform }) => (
  <EuiFlexGroup
    className='platform-option'
    data-test-id={`cloud-platform-option-${platform.id}`}
    gutterSize='s'
    alignItems='center'
    responsive={false}
  >
    <EuiFlexItem className='platform-icon' grow={false}>
      <EuiIcon size='m' type={platform.iconType} />
    </EuiFlexItem>

    <EuiFlexItem className='platform-name'>
      <EuiText style={{ paddingLeft: 0 }} size='s'>
        {platform.title}
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default withErrorBoundary(injectIntl(SelectCloudPlatform))
