/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSkeletonTitle,
  EuiSpacer,
  EuiSplitPanel,
  EuiTitle,
} from '@elastic/eui'

import ProjectCreationContainer from './ProjectCreationContainer'

import type { FunctionComponent } from 'react'

const LoadingProjectCreation: FunctionComponent = () => (
  <ProjectCreationContainer
    title={
      <EuiTitle size='m'>
        <h1>
          <FormattedMessage
            id='create-project-component.fullymanaged-supertitle'
            defaultMessage='Confirm your project settings'
          />
        </h1>
      </EuiTitle>
    }
    step={2}
  >
    <EuiFlexGroup direction='column' alignItems='center'>
      <EuiFlexItem
        style={{
          width: '761px',
          margin: 'auto',
        }}
      >
        <EuiSplitPanel.Outer>
          <EuiSplitPanel.Inner
            css={css({
              padding: '40px',
            })}
          >
            <EuiFlexGroup direction='column'>
              <EuiFlexItem>
                <EuiSkeletonTitle size='m' />
                <EuiSpacer size='s' />
                <EuiSkeletonText lines={3} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiSkeletonText lines={5} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
    </EuiFlexGroup>
  </ProjectCreationContainer>
)

export default LoadingProjectCreation
