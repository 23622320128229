/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui'

import usePermissions from '@modules/permissions-lib/hooks'
import type { AnySecurityProject } from '@modules/project-api/types'
import genericMessages from '@modules/project-lib/genericMessages'

import DataRetentionSummary from './DataRetentionSummary'

import type { FC } from 'react'

type DataRetentionDisplayProps = {
  project: AnySecurityProject
  isEditing: boolean
  toggleEditing: () => void
}

const DataRetentionDisplay: FC<DataRetentionDisplayProps> = ({
  project,
  isEditing,
  toggleEditing,
}) => {
  const { formatMessage } = useIntl()

  const { hasPermissions: hasUpdatePermission } = usePermissions([
    { type: `project-${project.type}`, action: 'update', id: project.id },
  ])

  const { default_retention_days, max_retention_days } = project.search_lake?.data_retention ?? {}

  const defaultRetentionDays = default_retention_days ?? Infinity

  const maximumRetentionDays = max_retention_days ?? Infinity

  const linkLabel = formatMessage(isEditing ? genericMessages.close : genericMessages.edit)

  return (
    <EuiFlexGroup
      responsive={false}
      gutterSize='m'
      justifyContent='flexEnd'
      alignItems='baseline'
      style={{ whiteSpace: 'nowrap' }}
    >
      {!isEditing && (
        <DataRetentionSummary
          maximumRetentionDays={maximumRetentionDays}
          defaultRetentionDays={defaultRetentionDays}
        />
      )}
      {hasUpdatePermission && (
        <EuiFlexItem grow={false}>
          <EuiLink
            onClick={toggleEditing}
            aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
              action: linkLabel,
              name: formatMessage(genericMessages.dataRetention),
            })}
          >
            {linkLabel}
          </EuiLink>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default DataRetentionDisplay
