/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'

import { EuiConfirmModal, EuiOverlayMask, EuiProgress } from '@elastic/eui'

import { getConfirmModalProps, renderButton } from './props'

import type { Props, ButtonUnionProps, EuiButtonProps, MouseClickEvent } from './types'

import './cuiButton.scss'

type State = {
  isConfirmModalOpen: boolean
}

export class CuiButtonImpl<T extends ButtonUnionProps = Partial<EuiButtonProps>> extends Component<
  Props<T>,
  State
> {
  state: State = {
    isConfirmModalOpen: false,
  }

  render() {
    return (
      <div>
        {this.renderButton()}
        {this.renderConfirmModal()}
      </div>
    )
  }

  renderButton() {
    const onClick = this.onButtonClickFromEvent
    const children = this.renderButtonChildren()

    return renderButton<T>(this.props, {
      onClick,
      children,
    })
  }

  renderButtonChildren() {
    const { spin, children } = this.props

    // If the empty span is visible when we're not showing the snipper,
    // it causes a CSS problem and off-centers the button content :facepalm:
    const spinStyle = spin ? {} : { display: 'none' }

    return (
      <Fragment>
        {/**
         * As crazy as it sounds, without this wrapper the UI crashes
         * when a page renders a spinning button AND is translated
         * using Google Translator.
         *
         * This is a known issue with [React and Google Translator][1]
         * and unfortunately the `translate` attribute _needs_ to be set
         * here and not in the `renderSpin` function itself.
         *
         * [1]: https://ela.st/374ff66e76
         */}
        <span translate='no' style={spinStyle}>
          {this.renderSpin()}
        </span>
        {children}
      </Fragment>
    )
  }

  renderSpin() {
    const { spin } = this.props

    if (!spin) {
      return null
    }

    return <EuiProgress size='xs' color='primary' position='absolute' />
  }

  renderConfirmModal() {
    const { disabled } = this.props
    const { isConfirmModalOpen } = this.state

    if (!isConfirmModalOpen || disabled) {
      return null
    }

    const modalProps = getConfirmModalProps<T>(this.props, {
      onCancel: this.closeConfirmModal,
      onConfirm: this.onConfirm,
    })

    return (
      <EuiOverlayMask>
        <EuiConfirmModal {...modalProps} />
      </EuiOverlayMask>
    )
  }

  onButtonClickFromEvent = (e: MouseClickEvent) => {
    const { type, onClick } = this.props

    if (e && type !== `submit` && onClick) {
      e.preventDefault()
    }

    this.onButtonClick()
  }

  onButtonClick = () => {
    const { confirm } = this.props

    if (confirm) {
      this.openConfirmModal()
      return
    }

    this.onConfirm()
  }

  openConfirmModal = () => {
    const { confirm, confirmExtensions = {} } = this.props
    const { canOpenModal } = confirmExtensions

    if (!confirm) {
      this.closeConfirmModal() // sanity
      return
    }

    if (typeof canOpenModal === 'function') {
      const isConfirmModalOpen = canOpenModal()
      this.setState({ isConfirmModalOpen })
      return
    }

    this.setState({ isConfirmModalOpen: true })
  }

  onConfirm = async () => {
    const { onClick, confirmExtensions = {} } = this.props
    const { fulfillBeforeModalClose } = confirmExtensions

    if (onClick) {
      const maybePromise = onClick()

      if (fulfillBeforeModalClose) {
        await maybePromise // non-thenable just fullfils immediately
      }
    }

    this.closeConfirmModal()
  }

  closeConfirmModal = () => {
    const { confirm, confirmExtensions = {} } = this.props
    const { onModalClose } = confirmExtensions

    this.setState({ isConfirmModalOpen: false })

    if (!confirm) {
      return
    }

    if (onModalClose) {
      onModalClose()
    }
  }
}
