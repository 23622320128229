/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { fetchFeed } from '@/actions/feeds'
import { fetchUpcomingEvents } from '@/actions/blogs'
import { getFeed, getLang } from '@/reducers/feeds'
import { fetchFeedRequest, fetchUpcomingEventsRequest } from '@/reducers/asyncRequests/registry'
import { getUpcomingEvents } from '@/reducers/blogs'

import CommunityTile from './CommunityTile'

import type { ReduxState } from '@/types/redux'
import type { Feeds } from '@/reducers/feeds'
import type { DispatchProps, StateProps, ConsumerProps, CommunityEvent } from './types'

const getPrimaryEvent = (feed: Feeds, locale: string): CommunityEvent | undefined => {
  const primaryEventData = feed.find(({ badge }) => badge?.en === 'primary_event')

  if (!primaryEventData) {
    return
  }

  return {
    id: primaryEventData.hash,
    title: getLang(primaryEventData.title, locale),
    description: getLang(primaryEventData.description, locale),
    imageUrl: getLang(primaryEventData.image_url, locale),
    linkUrl: getLang(primaryEventData.link_url, locale),
    date: primaryEventData.event_date as string | null,
  }
}

const getCommunityInfo = (feed: Feeds, locale: string): string | undefined => {
  const communityInfoData = feed.find(({ badge }) => badge?.en === 'community_info')

  if (!communityInfoData) {
    return
  }

  return getLang(communityInfoData.description, locale)
}

const mapStateToProps = (state: ReduxState, { intl: { locale } }: ConsumerProps): StateProps => {
  const feedName = `ess-community`
  const version = `1.0.0`
  const fetchFeedRequestState = fetchFeedRequest(state, `${feedName}-${version}`)
  const feed = getFeed(state, feedName, version, 4)
  const fetchUpcomingEventsRequestState = fetchUpcomingEventsRequest(state)

  return {
    isLoading: fetchFeedRequestState.inProgress || fetchUpcomingEventsRequestState.inProgress,
    primaryEvent: getPrimaryEvent(feed, locale),
    communityInfo: getCommunityInfo(feed, locale),
    upcomingEvents: getUpcomingEvents(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  fetchCommunityTileInfo: () => fetchFeed({ feed: `ess-community`, version: `1.0.0` }),
  fetchUpcomingEvents,
}

export default injectIntl(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(CommunityTile),
)
