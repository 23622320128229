/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiSuperSelectOption } from '@elastic/eui'
import { EuiFormRow, EuiSuperSelect } from '@elastic/eui'

interface Props {
  region: string
  regions: string[]
  onSelectRegion: (regionId: string) => void
  isLoading: boolean
}

const RegionControl = ({ region, regions, onSelectRegion, isLoading }: Props) => {
  const regionOptions: Array<EuiSuperSelectOption<string>> = regions.map((id) => ({
    value: id,
    inputDisplay: id,
  }))

  return (
    <EuiFormRow
      fullWidth={true}
      label={<FormattedMessage id='provider-region-control.region' defaultMessage='Region' />}
    >
      <EuiSuperSelect
        id='region-control'
        fullWidth={true}
        options={regionOptions}
        valueOfSelected={region}
        onChange={onSelectRegion}
        disabled={isLoading}
        isLoading={isLoading}
      />
    </EuiFormRow>
  )
}

export default RegionControl
