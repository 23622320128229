/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  restoreStackDeployment,
  resetRestoreStackDeployment,
  fetchDeployment,
} from '@/actions/stackDeployments'
import { fetchInstanceConfigurationIfNeeded } from '@/actions/topology/instanceConfigurations'
import { restoreStackDeploymentRequest } from '@/reducers/asyncRequests/registry'
import { getInstanceConfigurations } from '@/reducers/instanceConfigurations'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'

import RestartStoppedDeployment, {
  ConsumerProps,
  StateProps,
  DispatchProps,
} from './RestartStoppedDeployment'

import type { ReduxState, ThunkDispatch } from '@/types/redux'

const mapStateToProps = (state: ReduxState, { deployment }: ConsumerProps): StateProps => {
  const regionId = getRegionId({ deployment })

  return {
    instanceConfigurations: getInstanceConfigurations(state, regionId)!,
    restoreStackDeploymentRequest: restoreStackDeploymentRequest(state, deployment.id),
    profile: getProfile(state),
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deployment }: ConsumerProps,
): DispatchProps => ({
  restoreStackDeployment: () =>
    dispatch(
      restoreStackDeployment({
        deploymentId: deployment.id,
        restoreSnapshot: true,
      }),
    ),
  resetRestoreStackDeployment: () => dispatch(resetRestoreStackDeployment(deployment.id)),
  fetchInstanceConfigurationIfNeeded: (instanceConfigurationId) =>
    dispatch(
      fetchInstanceConfigurationIfNeeded(getRegionId({ deployment }), instanceConfigurationId),
    ),
  fetchDeployment: () => dispatch(fetchDeployment({ deploymentId: deployment.id })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RestartStoppedDeployment)
