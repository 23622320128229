/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiButton,
  EuiContextMenuItem,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui'

import type { InstanceConfiguration } from '@modules/cloud-api/v1/types'
import type {
  ProfileState,
  AsyncRequestState,
  StackDeployment,
  NodeConfiguration,
  AnyTopologyElement,
} from '@modules/ui-types'
import { parseError } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import ArchitectureVizFromTopology from '@/components/Topology/DeploymentTemplates/components/ArchitectureViz/ArchitectureVizFromTopology'
import { getSupportedSliderInstanceTypes } from '@/lib/sliders/support'
import { getInstanceConfigsFromNodeConfigs } from '@/lib/instanceConfigurations/instanceConfiguration'
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { getLastSizedPlanAttempt } from '@/lib/stackDeployments/selectors/configurationChangeAttempts'

export type StateProps = {
  instanceConfigurations: InstanceConfiguration[]
  restoreStackDeploymentRequest: AsyncRequestState
  profile: ProfileState
}

export type DispatchProps = {
  fetchDeployment: () => void
  restoreStackDeployment: () => void
  resetRestoreStackDeployment: () => void
  fetchInstanceConfigurationIfNeeded: (instanceConfigId: string) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
  onRestart?: () => void
}

type Props = StateProps & DispatchProps & ConsumerProps

type State = {
  requestChainProgress: boolean
  isConfirmModalOpen: boolean
}

class RestartStoppedDeployment extends Component<Props, State> {
  mounted: boolean = false

  state: State = {
    requestChainProgress: false,
    isConfirmModalOpen: false,
  }

  componentDidMount() {
    const { fetchInstanceConfigurationIfNeeded } = this.props
    const nodeConfigurations = this.getNodeConfigurations()

    // load instance configurations of last running plan
    const instanceConfigIds = getInstanceConfigsFromNodeConfigs(nodeConfigurations)

    instanceConfigIds.forEach((instanceConfigId) => {
      fetchInstanceConfigurationIfNeeded(instanceConfigId)
    })

    this.mounted = true
  }

  componentWillUnmount() {
    const { resetRestoreStackDeployment } = this.props
    resetRestoreStackDeployment()
    this.mounted = false
  }

  render() {
    const { restoreStackDeploymentRequest, profile, deployment } = this.props
    const { requestChainProgress } = this.state
    const trialExpired = profile ? profile.inTrial && profile.hasExpiredTrial : false
    const isBusy = requestChainProgress || restoreStackDeploymentRequest.inProgress

    return (
      <Fragment>
        <PermissionsGate
          permissions={[
            {
              type: 'deployment',
              action: 'restore',
              id: deployment.id,
            },
          ]}
        >
          {({ hasPermissions }) => (
            <EuiContextMenuItem
              icon={isBusy ? <EuiLoadingSpinner size='m' /> : 'refresh'}
              data-test-id='deploymentRestart-Btn'
              disabled={trialExpired || isBusy || !hasPermissions}
              onClick={this.openConfirmModal}
            >
              <FormattedMessage
                id='deployment-restart-stopped-deployment.restart-deployment'
                defaultMessage='Restore deployment'
              />
            </EuiContextMenuItem>
          )}
        </PermissionsGate>

        {this.renderConfirmModal()}
      </Fragment>
    )
  }

  renderConfirmModal() {
    const { isConfirmModalOpen } = this.state

    if (!isConfirmModalOpen) {
      return null
    }

    return (
      <EuiOverlayMask>
        <EuiModal onClose={this.closeConfirmModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <FormattedMessage
                id='deployment-restart-stopped-deployment.title'
                defaultMessage='Restore your deployment?'
              />
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>{this.getAppraisal()}</EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={this.closeConfirmModal}>
              <FormattedMessage
                id='deployment-shut-down-and-hide-deployment.cancel'
                defaultMessage='Cancel'
              />
            </EuiButtonEmpty>

            <EuiButton data-test-id='confirmModalConfirmButton' onClick={this.restart}>
              <FormattedMessage
                id='deployment-restart-stopped-deployment.restart-deployment-confirm'
                defaultMessage='Restore'
              />
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  closeConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false })
  }

  openConfirmModal = () => {
    this.setState({ isConfirmModalOpen: true })
  }

  getAppraisal() {
    const { instanceConfigurations, deployment } = this.props
    const nodeConfigurations = this.getNodeConfigurations()

    return (
      <Fragment>
        <FormattedMessage
          id='deployment-restart.body'
          defaultMessage='This will restore your deployment with the following architecture'
        />
        <ArchitectureVizFromTopology
          nodeConfigurations={nodeConfigurations}
          instanceConfigurations={instanceConfigurations}
          version={getVersion({ deployment }) || undefined}
        />
      </Fragment>
    )
  }

  getNodeConfigurations(): NodeConfiguration[] {
    const { deployment } = this.props
    const plans = getSupportedSliderInstanceTypes().map((sliderInstanceType) =>
      getLastSizedPlanAttempt({ sliderInstanceType, deployment }),
    )

    return plans.flatMap((p) => (p?.plan?.cluster_topology as AnyTopologyElement[]) || [])
  }

  restart = () => {
    const { restoreStackDeployment, fetchDeployment, onRestart } = this.props

    if (onRestart) {
      onRestart()
    }

    Promise.resolve()
      .then(() => this.setState({ requestChainProgress: true, isConfirmModalOpen: false }))
      .then(() => restoreStackDeployment())
      .then(() => {
        if (!onRestart) {
          return fetchDeployment()
        }

        return null
      })
      .then(() => {
        if (this.mounted) {
          return this.setState({ requestChainProgress: false })
        }

        return null
      })
      .catch((error) => {
        if (this.mounted) {
          addToast({
            family: `toast-error`,
            color: `danger`,
            iconType: `broom`,
            title: (
              <FormattedMessage
                id='toasts.title-restarting-failed'
                defaultMessage='Restarting failed'
              />
            ),
            text: parseError(error),
          })

          return this.setState({ requestChainProgress: false })
        }

        return null
      })
  }
}

export default RestartStoppedDeployment
