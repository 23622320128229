/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { css } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'

export const linkStyle = ({ theme }: { theme: WithEuiThemeProps['theme'] }) => {
  const hoverColor =
    theme.colorMode === 'LIGHT'
      ? theme.euiTheme.colors.lightShade
      : theme.euiTheme.colors.darkestShade

  return css({
    color: `${theme.euiTheme.colors.emptyShade}!important`,
    textDecoration: 'underline',
    '&:hover': css({
      color: hoverColor,
    }),
  })
}
