/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  DeploymentUpdateRequest,
  DeploymentCreateRequest,
  DeploymentTemplateInfoV2,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
  TopologySize,
} from '@modules/cloud-api/v1/types'
import type {
  StackDeployment,
  AnyClusterPlanInfo,
  AnyTopologyElement,
  VersionNumber,
} from '@modules/ui-types'

import { getConfigForKey } from '../../../store'
import { satisfies } from '../../semver'

import { getEsPlanFromGet } from './fundamentals'
import { isDedicatedML, isData, isFrozen, supportsFrozenTierAutoscaling } from './nodeRoles'
import { getEsPlan, getFirstEsCluster } from './stackDeployment'

import type { AutoscalingEnabled } from '../autoscaling'

export function isAutoscalingAvailable(version: string): boolean {
  const minimumAutoscalingVersion = getConfigForKey('MINIMUM_AUTOSCALING_VERSION')

  return satisfies(version, `>=${minimumAutoscalingVersion}`)
}

export function canEnableAutoscaling({
  deploymentTemplate,
  version,
}: {
  deploymentTemplate?: DeploymentTemplateInfoV2
  version: string
}): boolean {
  if (!isAutoscalingAvailable(version)) {
    // If it's an unsupported version, we don't need to consult the template
    return false
  }

  if (!deploymentTemplate) {
    // If the deployment template isn't loaded yet, we can't be sure
    return false
  }

  return isAutoscalingSupportedInTemplate({
    deploymentTemplate: deploymentTemplate.deployment_template,
  })
}

export function isAutoscaleableTier({
  topologyElement,
  version,
}: {
  topologyElement: AnyTopologyElement
  version: VersionNumber | undefined | null
}): boolean {
  if (isDedicatedML({ topologyElement })) {
    return true
  }

  if (!isData({ topologyElement })) {
    return false
  }

  if (isFrozen({ topologyElement })) {
    const esTopologyElement = topologyElement as ElasticsearchClusterTopologyElement

    if (esTopologyElement.autoscaling_max === undefined) {
      return false
    }

    return supportsFrozenTierAutoscaling({ version })
  }

  return true
}

export function isAutoscalingSupportedInTemplate({
  deploymentTemplate,
}: {
  deploymentTemplate: DeploymentCreateRequest
}): boolean {
  const supportedAtDeploymentLevel =
    typeof deploymentTemplate.settings?.autoscaling_enabled === 'boolean'
  const plan = getEsPlan({ deployment: deploymentTemplate })
  const supportedAtEsLevel = typeof plan?.autoscaling_enabled === 'boolean'

  return supportedAtDeploymentLevel || supportedAtEsLevel
}

export function isAnyAutoscalingEnabledOnGet({
  deployment,
}: {
  deployment: StackDeployment
}): boolean {
  if (autoscalingStatusOnGet({ deployment }) === `none`) {
    return false
  }

  return true
}

export function autoscalingStatusOnGet({
  deployment,
}: {
  deployment: StackDeployment
}): AutoscalingEnabled {
  if (isMLOnlyAutoscalingEnabledOnGet({ deployment })) {
    return `ml`
  }

  return isDeploymentLevelAutoscalingEnabledOnGet({ deployment }) ? `all` : `none`
}

export function isAnyAutoscalingEnabled({
  deployment,
}: {
  deployment: DeploymentUpdateRequest | DeploymentCreateRequest
}): boolean {
  if (getAutoscalingStatus({ deployment }) === `none`) {
    return false
  }

  return true
}

export function getAutoscalingStatus({
  deployment,
}: {
  deployment: DeploymentUpdateRequest | DeploymentCreateRequest
}): AutoscalingEnabled {
  // check to see if ML only autoscaling is enabled
  // then do the normal check for the rest of autoscaling
  if (isMLOnlyAutoscalingEnabled({ deployment })) {
    return `ml`
  }

  return isDeploymentLevelAutoscalingEnabled({ deployment }) ? `all` : `none`
}

function isDeploymentLevelAutoscalingEnabled({
  deployment,
}: {
  deployment: DeploymentUpdateRequest | DeploymentCreateRequest
}): boolean {
  if (deployment.settings?.autoscaling_enabled !== undefined) {
    return deployment.settings.autoscaling_enabled
  }

  const plan = getEsPlan({ deployment })
  return Boolean(plan?.autoscaling_enabled)
}

function isDeploymentLevelAutoscalingEnabledOnGet({
  deployment,
}: {
  deployment: StackDeployment
}): boolean {
  if (deployment.settings?.autoscaling_enabled !== undefined) {
    return deployment.settings.autoscaling_enabled
  }

  const plan = getEsPlanFromGet({ deployment })
  return Boolean(plan?.autoscaling_enabled)
}

function isMLOnlyAutoscalingEnabledInner({
  clusterTopology,
}: {
  clusterTopology?: ElasticsearchClusterTopologyElement[]
}) {
  if (!clusterTopology) {
    return false
  }

  const ml = clusterTopology.find((topologyElement) => isDedicatedML({ topologyElement }))

  return ml?.autoscaling_tier_override ?? false
}

function isMLOnlyAutoscalingEnabledOnGet({ deployment }: { deployment: StackDeployment }): boolean {
  const { resources } = deployment

  if (!resources) {
    return false
  }

  // We assume that we have an elasticsearch element
  const clusterTopology =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    deployment.resources.elasticsearch[0]!.info.plan_info.current?.plan?.cluster_topology

  return isMLOnlyAutoscalingEnabledInner({ clusterTopology })
}

function isMLOnlyAutoscalingEnabled({
  deployment,
}: {
  deployment: DeploymentUpdateRequest | DeploymentCreateRequest
}): boolean {
  const { resources } = deployment

  if (!resources) {
    return false // sanity as we should never get into this situation
  }

  const esResource = getFirstEsCluster({ deployment })

  if (!esResource || !esResource.plan || !esResource.plan.cluster_topology) {
    return false // sanity as we should never get into this situation
  }

  return isMLOnlyAutoscalingEnabledInner({ clusterTopology: esResource.plan.cluster_topology })
}

export function getMaxedOutCapacityTopologyElements({
  deployment,
}: {
  deployment: StackDeployment
}): ElasticsearchClusterTopologyElement[] {
  const maxedOutCapacityTopologyElements: ElasticsearchClusterTopologyElement[] = []

  const plan = getEsPlanFromGet({ deployment })

  if (!plan || !plan.cluster_topology) {
    return maxedOutCapacityTopologyElements
  }

  plan.cluster_topology.forEach((topologyElement) => {
    if (!topologyElement.autoscaling_max || !topologyElement.size) {
      return
    }

    if (isDedicatedML({ topologyElement })) {
      if (topologyElement.autoscaling_max.value === topologyElement.autoscaling_min?.value) {
        return
      }
    }

    if (topologyElement.autoscaling_max.value === 0 && topologyElement.size.value === 0) {
      return
    }

    if (topologyElement.autoscaling_max.value <= topologyElement.size.value) {
      maxedOutCapacityTopologyElements.push(topologyElement)
    }
  })

  return maxedOutCapacityTopologyElements
}

export function displayAutoscalingLimitReached({
  size,
  autoscalingMax,
  autoscalingMin,
}: {
  size: number
  autoscalingMax: number
  autoscalingMin?: number
}): boolean {
  if (!isFinite(size)) {
    return false
  }

  if (autoscalingMax === 0 && size === 0) {
    return false
  }

  if (autoscalingMin === autoscalingMax) {
    return false
  }

  if (size >= autoscalingMax) {
    return true
  }

  return false
}

export function isAutoscalingGeneratedPlanAttempt({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo
}): boolean {
  return planAttempt.source?.facilitator === `autoscaling`
}

export function isAutoscalingTerminationPlanAttempt({
  planAttempt,
}: {
  planAttempt: AnyClusterPlanInfo
}): boolean {
  return planAttempt.source?.action === `terminate-deployment`
}

export function getAutoscalingMaxForInstanceTemplate({
  instanceConfiguration,
  resource,
}: {
  instanceConfiguration: InstanceConfiguration
  resource: 'memory' | 'storage'
}): TopologySize {
  const maximumSize =
    instanceConfiguration.discrete_sizes.sizes[
      instanceConfiguration.discrete_sizes.sizes.length - 1
    ]
  const defaultSize = instanceConfiguration.discrete_sizes.default_size

  return {
    resource,
    value: maximumSize || defaultSize || 0,
  }
}

export function isTopologyConfiguredForAutoscaling(topologyElements: AnyTopologyElement[]) {
  return (topologyElements as ElasticsearchClusterTopologyElement[]).some(
    (topologyElement) =>
      typeof topologyElement.autoscaling_min?.value === 'number' ||
      typeof topologyElement.autoscaling_max?.value === 'number',
  )
}
