/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React from 'react'

import {
  CapacityColor,
  DataTransferColor,
  StorageColor,
  SyntheticsColor,
} from '@/components/User/BillingUsage/constants'
import { getSliderPrettyName } from '@/lib/sliders/messages'

import type { Dimension, DtsDimensionCosts, ItemsCostsV2, DimensionData } from './types'
import type { ReactNode } from 'react'

export function buildCapacityItemsV2(itemsCosts: ItemsCostsV2) {
  const capacityLabel = (
    <FormattedMessage id='billing-usage.capacity-dimension' defaultMessage='Capacity' />
  )

  if (!itemsCosts.resources.length) {
    return []
  }

  const result = buildCapacityItemsBasedOnCosts(capacityLabel, itemsCosts)

  if (!result.length) {
    return [buildEmptyItem(capacityLabel, CapacityColor)]
  }

  return [
    ...result,
    {
      dimension: null,
      color: CapacityColor,
      component: '',
      size: '',
      quantity: null,
      rate: '',
      total: getCapacityCost(itemsCosts),
    },
  ]
}

function buildDtsItems(
  label: ReactNode,
  types: Array<Dimension['type']>,
  items: DtsDimensionCosts[],
  itemsCosts: ItemsCostsV2,
  color: string,
) {
  const itemsByType = items.filter(({ type }) => types.includes(type as Dimension['type']))

  if (!itemsByType.length) {
    return [buildEmptyItem(label, color)]
  }

  const result: DimensionData[] = itemsByType.map((item, index) => ({
    dimension: index === 0 ? label : null,
    component: item.name,
    color,
    size: '',
    quantity: item.quantity.formatted_value,
    rate: item.rate.formatted_value,
    total: item.cost,
  }))

  result.push({
    color,
    dimension: null,
    component: '',
    size: '',
    quantity: null,
    rate: '',
    total: getTotalCostForDimensions(types, itemsCosts),
  })

  return result
}

export function buildDataTransferItems(itemsCosts: ItemsCostsV2) {
  return buildDtsItems(
    <FormattedMessage id='billing-usage.data-transfer-dimension' defaultMessage='Data transfer' />,
    ['data_in', 'data_internode', 'data_out'],
    itemsCosts.data_transfer,
    itemsCosts,
    DataTransferColor,
  )
}

export function buildStorageItems(itemsCosts: ItemsCostsV2) {
  return buildDtsItems(
    <FormattedMessage id='billing-usage.storage-dimension' defaultMessage='Storage' />,
    ['storage_bytes', 'storage_api'],
    itemsCosts.storage,
    itemsCosts,
    StorageColor,
  )
}

export function buildSyntheticsItems(itemsCosts: ItemsCostsV2) {
  return buildDtsItems(
    <FormattedMessage id='billing-usage.synthetics-dimension' defaultMessage='Synthetics' />,
    ['synthetics_browser', 'synthetics_lightweight'],
    itemsCosts.synthetics,
    itemsCosts,
    SyntheticsColor,
  )
}

function buildEmptyItem(dimension: ReactNode, color: string): DimensionData {
  return {
    dimension,
    color,
    component: '',
    size: '-',
    quantity: 0,
    rate: '0',
    total: 0,
  }
}

function buildCapacityItemsBasedOnCosts(label: ReactNode, itemsCosts: ItemsCostsV2) {
  return itemsCosts.resources.map((item, index) => ({
    dimension: index === 0 ? label : null,
    // TODO: remove empty string check when backend returns item.kind value
    component: item.kind ? (
      <FormattedMessage {...getSliderPrettyName({ sliderInstanceType: item.kind })} />
    ) : (
      ''
    ),
    color: CapacityColor,
    size: item.name,
    quantity: item.hours.formatted_value,
    rate: item.price_per_hour.formatted_value,
    total: item.price,
  }))
}

function getCapacityCost(itemsCostsV2: ItemsCostsV2): number {
  return itemsCostsV2.costs.dimensions.find(({ type }) => type === 'capacity')?.cost || 0
}

function getTotalCostForDimensions(
  dimensionTypes: Array<Dimension['type']>,
  itemsCosts: ItemsCostsV2,
) {
  return itemsCosts.costs.dimensions.reduce((currentCost, { cost, type }) => {
    if (dimensionTypes.includes(type)) {
      return currentCost + cost
    }

    return currentCost
  }, 0)
}
