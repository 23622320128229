/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiFieldText, EuiFormLabel, EuiFormRow } from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  name?: string
  onChange: (nextName: string) => void
  disabled?: boolean
  isAdminconsole?: boolean
  placeholder?: string
}

type State = {
  name: string
  isNamePristine: boolean
}

class NameDeployment extends Component<Props, State> {
  state = {
    isNamePristine: true,
    name: this.props.name || '',
  }

  componentDidUpdate(prevProps) {
    const { name } = this.props

    if (name !== prevProps.name && name !== this.state.name) {
      this.setState({ name: name || '', isNamePristine: true })
    }
  }

  render() {
    const { isAdminconsole } = this.props

    if (isAdminconsole) {
      return this.renderNameInput(
        <EuiFormLabel style={{ width: 180 }}>
          <FormattedMessage defaultMessage='Name' id='name-deployment-label' />
        </EuiFormLabel>,
      )
    }

    return (
      <EuiFormRow
        label={
          <FormattedMessage id='create-deployment.name-deployment.title' defaultMessage='Name' />
        }
      >
        {this.renderNameInput()}
      </EuiFormRow>
    )
  }

  renderNameInput = (prepend?: React.ReactElement) => {
    const {
      disabled,
      intl: { formatMessage },
    } = this.props
    const { name, isNamePristine } = this.state
    const nameAriaLabel = formatMessage({
      id: 'name-deployment.aria-label',
      defaultMessage: 'Name',
    })

    const placeholder = this.getPlaceholder()

    return (
      <PrivacySensitiveContainer>
        <EuiFieldText
          fullWidth={true}
          prepend={prepend}
          data-test-id='deployment-name'
          name='deployment-name'
          value={!name || (name === placeholder && isNamePristine) ? '' : name}
          onChange={this.onNameType}
          onBlur={this.onNameBlur}
          placeholder={placeholder}
          disabled={disabled}
          aria-label={nameAriaLabel}
        />
      </PrivacySensitiveContainer>
    )
  }

  onNameType = (e) => {
    this.setState({ name: e.target.value, isNamePristine: false })
  }

  onNameBlur = () => {
    this.props.onChange(this.state.name || this.getPlaceholder())
  }

  getPlaceholder = () => {
    const {
      intl: { formatMessage },
      placeholder,
    } = this.props

    return (
      placeholder ||
      formatMessage({
        id: 'select-solution.name-deployment.placeholder',
        defaultMessage: 'My deployment',
      })
    )
  }
}

export default injectIntl(NameDeployment)
