/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { stringify, parse } from 'query-string'

import { EuiSpacer } from '@elastic/eui'

import { useGetProvidersQuery, useGetPricesQuery } from '@modules/cloud-lib/billing/hooks'
import type { Provider } from '@modules/cloud-api/v1/types'

import { Product } from '../types'

import Filters from './Filters'
import PriceList from './PriceList'
import {
  getDefaultRegionIdentifier,
  getDefaultProviderName,
  getRegionsForAGivenProvider,
  getRegionIdentifierForAGivenProvider,
  clearInvalidFilters,
  getAvailableRegions,
} from './utils'

import type { Filters as FiltersState } from './Filters/types'

const defaultFilters: FiltersState = {
  provider: undefined,
  region: undefined,
  timestamp: undefined,
  include_deprecated_skus: undefined,
  is_marketplace: undefined,
  marketplace_pricing: undefined,
}

const StackHosted = () => {
  const history = useHistory()
  const filtersFromQuery = parse(history.location.search.slice(1), { parseBooleans: true })
  const providersQuery = useGetProvidersQuery({
    onSuccess: (providers: Provider[]) => {
      setFilters(getDefaultFilters(providers))
    },
  })

  const getDefaultFilters = (providers?: Provider[]): FiltersState => {
    if (providers) {
      return {
        ...defaultFilters,
        provider: getDefaultProviderName(providers),
        region: getDefaultRegionIdentifier(providers),
        ...clearInvalidFilters(filtersFromQuery, providers),
      }
    }

    return { ...defaultFilters, ...clearInvalidFilters(filtersFromQuery) }
  }

  const [filters, setFilters] = useState<FiltersState>(getDefaultFilters(providersQuery.data))
  const shouldFetchPricing =
    !!filters.region && getAvailableRegions(providersQuery.data).includes(filters.region)
  const pricesQuery = useGetPricesQuery(
    {
      region: filters.region!,
      isMarketplace: filters.is_marketplace ?? null,
      timestamp: filters.timestamp ?? null,
      includeDeprecatedSkus: filters.include_deprecated_skus ?? null,
    },
    {
      enabled: shouldFetchPricing,
    },
  )

  const updateQueryParams = (newFilters: Partial<FiltersState>): void => {
    const queryString = stringify({
      ...filtersFromQuery,
      ...newFilters,
      productType: Product.STACK_HOSTED,
    })

    history.replace(`${history.location.pathname}?${queryString}`)
  }

  const onFiltersChange = (newFilters: Partial<FiltersState>) => {
    setFilters({
      ...filters,
      ...newFilters,
      // if provider changed, reset the previously selected region
      ...(newFilters.provider && {
        region: getRegionIdentifierForAGivenProvider(providersQuery.data, newFilters.provider),
      }),
    })
  }

  useEffect(() => {
    updateQueryParams(filters)
  }, [filters])

  return (
    <Fragment>
      <Filters
        {...filters}
        isLoading={providersQuery.isFetching || pricesQuery.isLoading}
        providers={providersQuery.data || []}
        regions={getRegionsForAGivenProvider(providersQuery.data, filters.provider)}
        provider={filters.provider}
        region={filters.region}
        onFiltersChange={onFiltersChange}
      />

      <EuiSpacer size='xl' />

      <PriceList isLoading={pricesQuery.isLoading} prices={pricesQuery.data} />
    </Fragment>
  )
}

export default StackHosted
