/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { last } from 'lodash'

import { useGetCostsInstancesV2 } from '@modules/billing-lib/hooks'
import { useProfile, useGetOrganizationProfileQuery } from '@modules/profile-lib/hooks'

import {
  getInstancesType,
  getModeBasedOnUsage,
  getUsageQueryEndDate,
  getUsageQueryStartDate,
} from './utils'

import type { UsageModeProps } from './types'

const useUsageMode = (organizationId?: string): UsageModeProps => {
  const profile = useProfile()
  const organizationIdOfCurrentUser = profile?.organization_id

  const orgQuery = useGetOrganizationProfileQuery(
    {
      organizationId: organizationIdOfCurrentUser,
    },
    { enabled: Boolean(organizationIdOfCurrentUser) },
  )
  // last trial
  const trial = last(orgQuery.data?.trials || [])
  // if is_trial is false it means that trial ends and we should return hybrid mode by a default
  const shouldReturnHybridMode = profile !== undefined && !profile.is_trial

  const costsInstancesQuery = useGetCostsInstancesV2(
    {
      pathParameters: {
        organization_id: organizationId || organizationIdOfCurrentUser,
      },
      queryParameters: {
        from: getUsageQueryStartDate(trial?.start),
        to: getUsageQueryEndDate(trial?.end),
      },
    },
    {
      enabled: !orgQuery.isLoading && !shouldReturnHybridMode,
    },
  )

  if (shouldReturnHybridMode) {
    return {
      mode: 'hybrid',
      types: [],
      isReady: true,
    }
  }

  return {
    mode: getModeBasedOnUsage(costsInstancesQuery.data?.instances || []),
    types: getInstancesType(costsInstancesQuery.data?.instances || []),
    isReady: costsInstancesQuery.isSuccess || costsInstancesQuery.isError,
  }
}

// ts-unused-exports:disable-next-line
export default useUsageMode
