/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import ReactDOM from 'react-dom'

import RootApp from './RootApp'
import UnhandledApplicationLoadError from './UnhandledApplicationLoadError'

export function sharedEntryPoint(app: React.ComponentType) {
  const appRoot = document.getElementById(`app-root`)

  try {
    ReactDOM.render(<RootApp app={app} />, appRoot)
  } catch (error) {
    ReactDOM.render(<UnhandledApplicationLoadError error={error} />, appRoot)
  }
}
