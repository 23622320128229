/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { useIntl } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { messages } from '../messages'

const ConfirmRemovingRoleMappingModal = ({
  roleMappingName,
  onRemoveRoleMapping: onRemoveDomain,
  onClose,
  isLoading,
}: {
  roleMappingName: string
  onRemoveRoleMapping: () => void
  onClose: () => void
  isLoading: boolean
}) => {
  const { formatMessage } = useIntl()
  return (
    <EuiModal onClose={onClose} maxWidth='600px'>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {formatMessage(messages.modalRemoveRoleMappingTitle)}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>
          {formatMessage(messages.modalRemoveRoleMappingMessage, {
            roleMappingName,
            b: (msg) => <strong>{msg}</strong>,
          })}
        </EuiText>
        <EuiSpacer size='l' />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiButtonEmpty onClick={onClose}>
            {formatMessage(messages.modalRemoveRoleMappingCancel)}
          </EuiButtonEmpty>
          <EuiButton
            color='danger'
            fill={true}
            isLoading={isLoading}
            onClick={onRemoveDomain}
            aria-label={formatMessage(messages.modalRemoveRoleMappingConfirm)}
          >
            {formatMessage(messages.modalRemoveRoleMappingConfirm)}
          </EuiButton>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default ConfirmRemovingRoleMappingModal
