/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { Region, StackDeployment } from '@modules/ui-types'
import type { DeploymentTemplateInfoV2, DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'

import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getRegion } from '@/reducers/regions'
import { updateDeployment } from '@/actions/stackDeployments'

import DedicatedMastersUnnecessary from './DedicatedMastersUnnecessary'

import type { ReduxState } from '@/types/redux'

type StateProps = {
  region: Region | undefined
}

type DispatchProps = {
  updateDeployment: (args: {
    deploymentId: string
    deployment: DeploymentUpdateRequest
    redirect?: boolean
    dryRun?: boolean
  }) => Promise<unknown>
}

type ConsumerProps = {
  stackDeployment?: StackDeployment | null
  deploymentTemplate?: DeploymentTemplateInfoV2
}

export type AllProps = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (state: ReduxState, { stackDeployment }): StateProps => {
  const regionId = getRegionId({ deployment: stackDeployment })!

  return {
    region: getRegion(state, regionId),
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  updateDeployment: (args) => dispatch(updateDeployment(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DedicatedMastersUnnecessary)
