/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { htmlIdGenerator } from '@elastic/eui'

import type { UnavailableVersionUpgrades, VersionNumber } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui/Boundary'
import type { NonEmptyArray } from '@modules/ts-utils'
import { isNonEmptyArray } from '@modules/ts-utils'

import { rcompare } from '../../../lib/semver'

import CreateEsVersion from './CreateEsVersion'
import UpgradeEsVersion from './UpgradeEsVersion'

import type { ReactNode } from 'react'

type Props = {
  availableVersions: VersionNumber[]
  unavailableVersionUpgrades?: UnavailableVersionUpgrades
  version: string | null
  lastVersion?: string | null
  onUpdate: (newVersion: string, lastVersion?: string | null) => void
  isCreate?: boolean
  checkVersionDisabled?: (version: VersionNumber) => ReactNode
}

type State = {
  id: string
}

const makeId = htmlIdGenerator()

class EsVersion extends Component<Props, State> {
  state: State = {
    id: makeId(),
  }

  componentDidMount(): void {
    const { onUpdate, version, availableVersions, lastVersion } = this.props

    if (version == null && isNonEmptyArray(availableVersions)) {
      onUpdate(defaultVersion(availableVersions), lastVersion)
    }
  }

  componentDidUpdate(): void {
    const { onUpdate, version, availableVersions, lastVersion } = this.props

    if (version == null && isNonEmptyArray(availableVersions)) {
      onUpdate(defaultVersion(availableVersions), lastVersion)
    }
  }

  render(): ReactNode {
    const {
      version,
      lastVersion,
      onUpdate,
      availableVersions,
      unavailableVersionUpgrades,
      isCreate,
      checkVersionDisabled,
    } = this.props

    // If we don't have a version, a default is found on mount/update
    if (!version) {
      return null
    }

    if (isCreate) {
      return (
        <CreateEsVersion
          id={this.state.id}
          version={version}
          onUpdate={onUpdate}
          availableVersions={availableVersions}
        />
      )
    }

    return (
      <UpgradeEsVersion
        id={this.state.id}
        lastVersion={lastVersion}
        version={version}
        onUpdate={onUpdate}
        availableVersions={availableVersions}
        unavailableVersionUpgrades={unavailableVersionUpgrades}
        checkVersionDisabled={checkVersionDisabled}
      />
    )
  }
}

export default withErrorBoundary(EsVersion)

function defaultVersion(versions: NonEmptyArray<string>) {
  const strings = versions.slice().sort(rcompare) as NonEmptyArray<string>

  return strings[0]
}
