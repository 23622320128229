/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { rootUrl } from '@modules/utils/rootUrls'

import { createDeploymentUrl } from '@/lib/urlBuilder'
import { setUpMfaUrl } from '@/apps/userconsole/urls'

const urls = [
  '/home',
  '/user',
  '/account',
  '/access-denied',
  '/marketplace-conversion',
  '/billing',
  '/marketplace/registration',
  '/onboarding',
  setUpMfaUrl(),
]

const urlsWithoutGlobalHeader = [
  '/access-denied', // access-denied has own HttpError header
  '/marketplace-conversion', // marketplace pages are displayed in iframe without header
  '/marketplace/registration',
  '/onboarding',
  setUpMfaUrl(),
]

export const shouldDisplayGlobalHeader = (path: string): boolean =>
  path === rootUrl() || !urlsWithoutGlobalHeader.some((url) => path.startsWith(url))

export const isPortalUrl = (path: string): boolean =>
  path === rootUrl() || urls.some((url) => path.startsWith(url))

const isDeploymentGettingStartedUrl = (path: string) => {
  const regex = /\/deployments\/(.*?)\/getting-started/
  return regex.test(path)
}

export const isHideNavigationUrl = (path: string): boolean => {
  if (isDeploymentGettingStartedUrl(path)) {
    return true
  }

  if (path === setUpMfaUrl()) {
    return true
  }

  const urlsWithoutNav = [createDeploymentUrl(), `/projects/create`]
  return urlsWithoutNav.some((url) => path.startsWith(url))
}
