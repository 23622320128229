/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import type { EuiTitleProps } from '@elastic/eui'
import {
  EuiHorizontalRule,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import Breadcrumbs from '../Breadcrumbs'

import type { FunctionComponent, ReactNode } from 'react'

type Breadcrumb = {
  to?: string
  text: NonNullable<ReactNode>
}

type Props = {
  name?: ReactNode
  children?: ReactNode
  breadcrumbs?: Breadcrumb[]
  size?: EuiTitleProps['size']
  className?: string
  subHeading?: ReactNode
  bottomBorder?: boolean
}

const Header: FunctionComponent<Props> = ({
  name,
  children,
  breadcrumbs,
  className,
  size = 'l',
  subHeading,
  bottomBorder = true,
}: Props) => (
  <Fragment>
    <Breadcrumbs breadcrumbs={breadcrumbs} />

    {name && (
      <Fragment>
        <EuiPageHeader className={className}>
          <EuiPageHeaderSection>
            <EuiTitle size={size} data-test-id='deployment-title'>
              <h1 data-test-id='cloud-page-title'>{name}</h1>
            </EuiTitle>

            {subHeading && (
              <Fragment>
                <EuiSpacer size='s' />
                <EuiText>{subHeading}</EuiText>
              </Fragment>
            )}
          </EuiPageHeaderSection>

          {children && <EuiPageHeaderSection>{children}</EuiPageHeaderSection>}
        </EuiPageHeader>

        {bottomBorder && <EuiHorizontalRule margin='m' />}
        <EuiSpacer size='m' />
      </Fragment>
    )}
  </Fragment>
)

export default Header
