/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { TrafficFilterRulesetInfo, TrafficFilterRule } from '@modules/cloud-api/v1/types'

import type { FunctionComponent } from 'react'

type Props = {
  rulesetType: TrafficFilterRulesetInfo['type']
  rule: TrafficFilterRule
}

const TrafficFilterRulesetRuleSource: FunctionComponent<Props> = ({ rulesetType, rule }) => {
  if (rulesetType === 'azure_private_endpoint') {
    return (
      <div>
        {rule.azure_endpoint_name}.{rule.azure_endpoint_guid}
      </div>
    )
  }

  if (rulesetType === `remote_cluster`) {
    const esId = (
      <span>
        <FormattedMessage
          id='traffic-filter-ruleset-rule-source.remote-cluster-id'
          defaultMessage='Elasticsearch ID'
        />
        : {rule.remote_cluster_id}
      </span>
    )
    const orgId = (
      <span>
        <FormattedMessage
          id='traffic-filter-ruleset-rule-source.remote-cluster-org-id'
          defaultMessage='Organization ID'
        />
        : {rule.remote_cluster_org_id}
      </span>
    )
    return (
      <div>
        {orgId} | {esId}
      </div>
    )
  }

  return <div>{rule.source}</div>
}

export default TrafficFilterRulesetRuleSource
