/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiSpacer } from '@elastic/eui'

import type { AnyResourceInfo, SliderInstanceType } from '@modules/ui-types'
import { CuiButton } from '@modules/cui/Button'
import history from '@modules/utils/history'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { getResourceVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { getSliderPrettyName } from '@/lib/sliders/messages'

import ResourceExternalLinks from '../../StackDeployments/ResourceExternalLinks'

import type { FunctionComponent } from 'react'

type Props = {
  deploymentId: string
  resource: AnyResourceInfo
  sliderInstanceType: SliderInstanceType
  editUrl: string
}

const OverviewDisabled: FunctionComponent<Props> = ({
  resource,
  sliderInstanceType,
  editUrl,
  deploymentId,
}) => (
  <EuiFlexGroup gutterSize='l' alignItems='flexStart' justifyContent='spaceBetween'>
    <EuiFlexItem grow={false}>
      <div>
        <EuiText>
          <p data-test-id='slider-is-disabled'>
            <FormattedMessage
              id='slider-overview.disabled-message'
              defaultMessage='{name} is not running on this deployment.'
              values={{
                name: (
                  <FormattedMessage
                    {...getSliderPrettyName({
                      sliderInstanceType,
                      version: getResourceVersion({ resource }),
                    })}
                  />
                ),
              }}
            />
          </p>
        </EuiText>

        <EuiSpacer />

        <PermissionsGate
          // This case is little odd because the button just navigates
          // to another page, but there's no point doing that if you can't
          // perform the actions on the next page.
          permissions={[
            {
              type: 'deployment',
              action: 'update',
              id: deploymentId,
            },
          ]}
        >
          {({ hasPermissions }) => (
            <CuiButton
              disabled={!hasPermissions}
              color='primary'
              fill={true}
              onClick={() => history.push(editUrl)}
            >
              <FormattedMessage
                id='slider-overview.update-settings'
                defaultMessage='Edit deployment'
              />
            </CuiButton>
          )}
        </PermissionsGate>
      </div>
    </EuiFlexItem>

    <ResourceExternalLinks
      wrapWithFlexItem={true}
      sliderInstanceType={sliderInstanceType}
      resource={resource}
    />
  </EuiFlexGroup>
)

export default OverviewDisabled
