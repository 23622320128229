/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEuiTheme, type EuiThemeColorModeStandard } from '@elastic/eui'

// eslint-disable-next-line import/no-restricted-paths
import bgBottomRightLight from '@/files/landing-page-pattern-bottom-right.svg'
// eslint-disable-next-line import/no-restricted-paths
import bgTopLeftLight from '@/files/landing-page-pattern-top-left.svg'
// eslint-disable-next-line import/no-restricted-paths
import bgBottomRightDark from '@/files/landing-page-pattern-bottom-right-dark.svg'
// eslint-disable-next-line import/no-restricted-paths
import bgTopLeftDark from '@/files/landing-page-pattern-top-left-dark.svg'

type ColorModeImageMap = Record<EuiThemeColorModeStandard, string>

const bgTopLeft: ColorModeImageMap = {
  LIGHT: bgTopLeftLight,
  DARK: bgTopLeftDark,
}

const bgBottomRight: ColorModeImageMap = {
  LIGHT: bgBottomRightLight,
  DARK: bgBottomRightDark,
}

const useFlowV2BackgroundDecoration = () => {
  const {
    colorMode,
    euiTheme: {
      size: { xl: euiSizeXL },
      colors: { body: euiBodyColor },
      breakpoint: { l: euiBreakpointL },
    },
  } = useEuiTheme()

  return {
    backgroundColor: euiBodyColor,
    [`@media(min-width: ${euiBreakpointL}px)`]: {
      backgroundImage: `url("${bgBottomRight[colorMode]}"), url("${bgTopLeft[colorMode]}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `right bottom, left ${euiSizeXL}`,
      backgroundSize: '400px',
    },
  }
}

export default useFlowV2BackgroundDecoration
