/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import {
  ControlledFilterQuery,
  createQueryExecutor,
  CuiFilterContext,
} from '@modules/cui/FilterContext'
import type { OnFilterChangeParams } from '@modules/cui/FilterContext'

import { getQueryModel, defaultFields, schema } from './schema'
import { getFilters } from './filters'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent, ComponentPropsWithoutRef } from 'react'
import type { ApiKeyRow } from '../types'

interface Props extends WrappedComponentProps {
  query: ControlledFilterQuery
  apiKeys?: ComponentPropsWithoutRef<typeof CuiFilterContext>['records']
  onChange: (params: OnFilterChangeParams<ApiKeyRow>) => void
}

const messages = defineMessages({
  searchBarPlaceholder: {
    id: `api-keys-filter-context.placeholder`,
    defaultMessage: `Search API keys`,
  },
})

const ApiKeysFilterContext: FunctionComponent<Props> = ({ intl, query, onChange, apiKeys }) => {
  const { formatMessage } = intl

  const executeQuery = createQueryExecutor({
    defaultFields,
    getQueryModel,
  })

  const filters = getFilters({ intl })

  const placeholder = formatMessage(messages.searchBarPlaceholder)

  return (
    <CuiFilterContext
      query={query}
      onChange={onChange}
      records={apiKeys ? apiKeys : []}
      schema={schema}
      filters={filters}
      executeQuery={executeQuery}
      placeholder={placeholder}
    />
  )
}

export default injectIntl(ApiKeysFilterContext)
