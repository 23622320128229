/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { Price } from '@modules/billing-api/v1/types'

export const getPriceHashmap = (prices: Price[] = []): Record<string, number> =>
  prices
    .filter((item) => item.preview_prices)
    .reduce((hashmap, item) => {
      const tempHashmap = {}
      const tiers = item.preview_prices?.tiers

      if (tiers) {
        let tieredPreviewPriceSum = 0

        tiers.forEach((tier) => {
          // lets storage prices with tiers as `sku_min_max`
          tempHashmap[`${item.id}_${tier.min}_${tier.max}`] = tier.price
          tieredPreviewPriceSum += tier.price
        })
        // add parent item to hashmap - its needed to know if a given item has tiers with a preview prices
        // based on this we want to display `Free through the promotional period` for a parent PLI
        tempHashmap[item.id] = tieredPreviewPriceSum
      }

      if (item.preview_prices?.price) {
        tempHashmap[item.id] = item.preview_prices.price
      }

      return { ...hashmap, ...tempHashmap }
    }, {})

export const canPreviewPriceBeApplied = ({
  currentPrice,
  previewPrice,
}: {
  currentPrice?: number
  previewPrice?: number
}): boolean => !!previewPrice && currentPrice === 0
