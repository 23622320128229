/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'url'

import type { UrlWithStringQuery } from 'url'

export const isCurrentPath = (path: string) => location.pathname === path

export function parseUrl(urlStr: string | undefined): UrlWithStringQuery | undefined {
  if (urlStr) {
    try {
      // TODO: revert back to the WHATWG URL API (or even better, URL.parse) after https://issues.chromium.org/issues/40063064 is fixed
      return parse(urlStr)
    } catch (parseErr) {
      // ignore
    }
  }

  return undefined
}
