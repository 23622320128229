/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { parse } from 'query-string'

import { Product } from '../types'

export const getDefaultProductType = (rawQueryString: string): Product => {
  const filtersQuery = parse(rawQueryString, { arrayFormat: 'comma' })
  const productType = filtersQuery?.productType || Product.STACK_HOSTED

  if (productType === Product.SERVERLESS || productType === Product.STACK_HOSTED) {
    return productType as Product
  }

  return Product.STACK_HOSTED
}
