/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ProjectType } from '@modules/ui-types/projects'

// https://ela.st/elasticsearch-pricing
// https://ela.st/security-pricing
// https://ela.st/observability-pricing links have been created,
// this is not an automated process, in case we add another solution the short link should be manually created

export const pricingLinkBuilder = (solution: ProjectType) => `https://ela.st/${solution}-pricing`

export function resourceCreationManagedUrl(): string {
  return `/projects/create`
}

export function projectTypeCreationUrl(projectType: ProjectType): string {
  return `/projects/create/${projectType}/start`
}

export function projectTypeStartingUrl(projectType: ProjectType, id: string): string {
  return `/projects/create/${projectType}/${id}/starting`
}

export function projectTypeOnboardingStartingUrl(
  projectType: ProjectType,
  id: string,
  onboardingToken?: string | null,
): string {
  return `/onboarding/projects/create/${projectType}/${id}/starting${
    onboardingToken ? `?onboarding_token=${onboardingToken}` : ''
  }`
}

export function deploymentTypeOnboardingStartingUrl(
  id: string,
  onboardingToken?: string | null,
): string {
  return `/onboarding/stateful/create/${id}/starting${
    onboardingToken ? `?onboarding_token=${onboardingToken}` : ''
  }`
}
