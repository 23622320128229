/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { clone, flatMap, findIndex, uniqBy } from 'lodash'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiIcon, EuiText } from '@elastic/eui'

import type { DeploymentTemplateInfoV2, InstanceConfiguration } from '@modules/cloud-api/v1/types'
import type { NodeConfiguration, ZoneSummaryInstance } from '@modules/ui-types'
import { CuiTable } from '@modules/cui/Table'
import type { CuiTableColumn } from '@modules/cui/Table'

import {
  findInstanceByExactMatch,
  findInstanceByPartialMatch,
  getArchitectureDescription,
  getInstancesByType,
  isMatchingInstance,
} from '@/lib/deployments/architecture'
import { getTopologyElementName } from '@/lib/sliders/messages'
import { getSupportedSliderInstanceTypes } from '@/lib/sliders/support'
import {
  getVersion,
  getTopology,
  getResources,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { getDeploymentNodeConfigurations } from '@/lib/stackDeployments/selectors/topologyElements'

import messages from '../messages'

import ChangeSummary from './ChangeSummary'
import HardwareSummary from './HardwareSummary'

import type { HardwareComparisonProps as Props, ZoneSummaryInstanceDiff } from './types'

const makeLabel = (content: JSX.Element) => (
  <EuiText size='xs'>
    <strong>{content}</strong>
  </EuiText>
)

const HardwareComparison: React.FunctionComponent<Props> = ({
  deployment,
  deploymentTemplates,
  migratedTemplatePayload,
}): JSX.Element => {
  const { formatMessage } = useIntl()
  const version = getVersion({ deployment }) || undefined
  // These will be used for the new hardware as we're getting all the ICs in the avaialble templates
  const instanceConfigurations = uniqBy(
    flatMap<DeploymentTemplateInfoV2, InstanceConfiguration>(
      deploymentTemplates,
      ({ instance_configurations }) => instance_configurations,
    ),
    'id',
  )

  const nodeConfigurations: NodeConfiguration[] = flatMap(
    getResources({ deployment }),
    (resource) => getTopology({ resource }),
  )
  // For the existing hardware we use the ICs returned in the deployment itself
  const { keys: existingHardware } = getArchitectureDescription({
    nodeConfigurations,
    instanceConfigurations: deployment.instance_configurations,
    version,
  })

  const migratedNodeConfigurations = getDeploymentNodeConfigurations({
    deployment: migratedTemplatePayload,
  })
  const { keys: migratedHardware } = getArchitectureDescription({
    nodeConfigurations: migratedNodeConfigurations,
    instanceConfigurations,
    version,
  })

  const columns: Array<CuiTableColumn<ZoneSummaryInstance & { isAddition?: boolean }>> = [
    {
      id: `component`,
      label: makeLabel(<FormattedMessage {...messages.componentTitle} />),
      render: ({ sliderInstanceType, topologyId }) => {
        const topologyElement = nodeConfigurations.find(({ id }) => id === topologyId)

        if (!topologyElement) {
          return null // sanity
        }

        return (
          <EuiText size='s'>
            <strong>
              {getTopologyElementName(
                {
                  topologyElement,
                  sliderInstanceType,
                  version,
                },
                formatMessage,
              )}
            </strong>
          </EuiText>
        )
      },
      width: `220px`,
      truncateText: false,
      verticalAlign: `top`,
    },
    {
      id: `current`,
      label: makeLabel(<FormattedMessage {...messages.currentConfiguration} />),
      render: (instance) => {
        const topologyElement = nodeConfigurations.find(({ id }) => id === instance.topologyId)

        if (!topologyElement) {
          return null // sanity
        }

        return instance.isAddition ? null : (
          <HardwareSummary instance={instance} topologyElement={topologyElement} />
        )
      },
      truncateText: false,
    },
    {
      id: `icon`,
      render: ({ isAddition }) =>
        isAddition ? <EuiIcon type='plus' color='success' /> : <EuiIcon type='sortRight' />,
      width: `40px`,
    },
    {
      id: `new`,
      label: makeLabel(<FormattedMessage {...messages.newConfiguration} />),
      render: (instance) => {
        const topologyElement = nodeConfigurations.find(({ id }) => id === instance.topologyId)

        if (!topologyElement) {
          return null // sanity
        }

        if (instance.isAddition) {
          return <HardwareSummary instance={instance} topologyElement={topologyElement} />
        }

        const newInstances = getInstancesByType(migratedHardware, instance.sliderInstanceType)
        const instanceExactMatch = findInstanceByExactMatch(newInstances, instance)
        const instancePartialMatch = findInstanceByPartialMatch(newInstances, instance)

        const diffs = getDiffs(instance, instancePartialMatch)

        return (
          <ChangeSummary
            exact={instanceExactMatch}
            partial={instancePartialMatch}
            diffs={diffs}
            topologyElement={topologyElement}
          />
        )
      },
      truncateText: false,
    },
  ]

  const rows = getSupportedSliderInstanceTypes().reduce((prevInstances, sliderInstanceType) => {
    const existingSliderInstances = getInstancesByType(existingHardware, sliderInstanceType)

    const newInstances = getInstancesByType(migratedHardware, sliderInstanceType)
    const remainingNewInstances = clone(newInstances)

    for (const instance of existingSliderInstances) {
      const instanceExactMatch = findInstanceByExactMatch(newInstances, instance)
      const instancePartialMatch = findInstanceByPartialMatch(newInstances, instance)

      const matchedInstance = instanceExactMatch || instancePartialMatch
      const matchedIndex =
        matchedInstance &&
        findIndex(remainingNewInstances, (remainingInstance) =>
          isMatchingInstance(remainingInstance, matchedInstance),
        )

      if (typeof matchedIndex === `number` && matchedIndex > -1) {
        remainingNewInstances.splice(matchedIndex, 1)
      }
    }

    const newSliderInstances: Array<ZoneSummaryInstance & { isAddition: boolean }> =
      remainingNewInstances.map((instance) => ({
        ...instance,
        isAddition: true,
      }))

    return [...prevInstances, ...existingSliderInstances, ...newSliderInstances]
  }, [])

  return <CuiTable<ZoneSummaryInstance> columns={columns} rows={rows} />
}

export function getDiffs(
  oldInstance: ZoneSummaryInstance,
  newInstance: ZoneSummaryInstance | undefined,
): ZoneSummaryInstanceDiff | null {
  if (newInstance === undefined) {
    return null
  }

  const totalSizeDiff =
    oldInstance.totalSize && newInstance.totalSize
      ? newInstance.totalSize - oldInstance.totalSize
      : 0
  const cpuMultiplierDiff = getCpuDiff(oldInstance, newInstance)
  const storageMultiplierDiff =
    oldInstance.storageMultiplier && newInstance.storageMultiplier
      ? newInstance.storageMultiplier - oldInstance.storageMultiplier
      : 0

  return {
    totalSizeDiff,
    cpuMultiplierDiff,
    storageMultiplierDiff,
  }
}

function getCpuDiff(oldInstance, newInstance) {
  if (!oldInstance.cpuMultiplier || !newInstance.cpuMultiplier) {
    return 0
  }

  const diff = newInstance.cpuMultiplier - oldInstance.cpuMultiplier
  const absDiff = Math.abs(diff)

  if (absDiff < 0.005) {
    return 0 // this diff is too small to worry about
  }

  return diff
}

export default HardwareComparison
