/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchClusterTrustSettings } from '@modules/cloud-api/v1/types'

import { FETCH_TRUSTED_ENVS } from '../../constants/actions'

import type { ReduxState } from '@/types/redux'

export type State = ElasticsearchClusterTrustSettings

type FetchAction = {
  type: typeof FETCH_TRUSTED_ENVS
  error?: boolean
  payload?: ElasticsearchClusterTrustSettings
}

const initialState: State = {}

export default function trustedEnvsReducer(
  state: State = initialState,
  action: FetchAction,
): State {
  if (action.type === FETCH_TRUSTED_ENVS) {
    if (!action.error && action.payload) {
      return {
        ...state,
        ...action.payload,
      }
    }
  }

  return state
}

const _getTrustedEnvs = (state: State): ElasticsearchClusterTrustSettings => state

export function getTrustedEnvs(state: ReduxState) {
  return _getTrustedEnvs(state.trustedEnvs)
}
