/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import moment from 'moment'

import type { Provider, Region } from '@modules/cloud-api/v1/types'

import type { Filters } from './Filters/types'

export const getDefaultProviderName = (providers: Provider[]): Provider['name'] | undefined =>
  providers?.find(({ is_default }) => !!is_default)?.name

export const getRegionsForDefaultProvider = (providers: Provider[]): Region[] =>
  providers?.find(({ name }) => name === getDefaultProviderName(providers))?.regions || []

export const getDefaultRegionIdentifier = (
  providers: Provider[],
): Region['identifier'] | undefined => getRegionsForDefaultProvider(providers)?.[0]?.identifier

export const getRegionIdentifierForAGivenProvider = (
  providers: Provider[] = [],
  provider: Provider['name'],
): Region['identifier'] | undefined =>
  getRegionsForAGivenProvider(providers, provider)[0]?.identifier

export const getRegionsForAGivenProvider = (
  providers: Provider[] = [],
  provider?: Provider['name'],
): Region[] => providers.find(({ name }) => name === provider)?.regions || []

export const getAvailableRegions = (providers: Provider[] = []): Array<Region['identifier']> =>
  providers.flatMap((provider) => provider.regions).map(({ identifier }) => identifier)

export const clearInvalidFilters = (
  filters: Partial<Filters>,
  providers?: Provider[],
): Partial<Filters> => {
  const {
    provider,
    region,
    timestamp,
    is_marketplace,
    marketplace_pricing,
    include_deprecated_skus,
  } = filters
  const cleanOptions = { ...filters }

  if (!moment(timestamp).isValid()) {
    delete cleanOptions.timestamp
  }

  if (typeof is_marketplace !== 'boolean') {
    delete cleanOptions.is_marketplace
  }

  if (typeof marketplace_pricing !== 'boolean') {
    delete cleanOptions.marketplace_pricing
  }

  if (typeof include_deprecated_skus !== 'boolean') {
    delete cleanOptions.include_deprecated_skus
  }

  // "providers" might not be fetched yet that why we should remove query-param only if we are 100% sure that it's not correct
  if (providers) {
    if (provider && !providers.find(({ name }) => name === provider)) {
      delete cleanOptions.provider
    }

    if (region && !getAvailableRegions(providers).includes(region)) {
      delete cleanOptions.region
    }
  }

  return cleanOptions
}
