/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { last } from 'lodash'

import type {
  AnyResourceInfo,
  RegionId,
  SliderInstanceType,
  StackDeployment,
} from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { getStackDeployment } from '@/reducers/stackDeployments'

import { withStackDeploymentRouteParams } from '../StackDeploymentEditor'
import { isFeatureActivated } from '../../selectors'

import StackDeploymentSlider from './StackDeploymentSlider'

import type { ReduxState } from '@/types/redux'
import type { WithStackDeploymentRouteParamsProps } from '../StackDeploymentEditor'

type StateProps = {
  deployment: StackDeployment
  resource: AnyResourceInfo
  regionId: RegionId
  sliderInstanceType: SliderInstanceType
  hideDelete: boolean
}

interface DispatchProps {}

type ConsumerProps = WithStackDeploymentRouteParamsProps

const mapStateToProps = (
  state: ReduxState,
  { stackDeploymentId, regionId, location: { pathname = `` } }: ConsumerProps,
): StateProps => {
  const deployment = getStackDeployment(state, stackDeploymentId)!
  const sliderInstanceType = last(pathname.split(`/`)) as SliderInstanceType
  const resource = getFirstSliderClusterFromGet({ deployment, sliderInstanceType })!
  const hideDelete =
    sliderInstanceType === `kibana` && isFeatureActivated(state, Feature.hideKibanaDelete)

  return {
    deployment,
    resource,
    regionId,
    sliderInstanceType,
    hideDelete,
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(mapStateToProps)(StackDeploymentSlider),
)
