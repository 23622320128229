/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiHorizontalRule, EuiLink, EuiSpacer } from '@elastic/eui'

import type { Price } from '@modules/billing-api/v1/types'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import PriceList from '../PriceList'
import { getElasticsearchPrices } from '../utils'

interface ElasticsearchTabProps {
  prices: Price[]
  isLoading: boolean
  addonList: JSX.Element
}

const ElasticsearchTab = ({ prices, isLoading, addonList }: ElasticsearchTabProps) => {
  const [_, flags] = useFlagsWhenLoaded({ isNonAuthPage: true })

  const renderEsCallout = () => (
    <EuiCallOut
      size='s'
      data-test-subj='pricing-list.serverless.es-pricing-info'
      title={
        <FormattedMessage
          id='pricing-list.serverless.es-pricing-info-title'
          defaultMessage='Elasticsearch Serverless projects scale down to a minimum of 4 Search VCUs and 8 Ingest VCUs, incurring a minimum hourly cost of $1.28.'
        />
      }
    >
      <p>
        <FormattedMessage
          id='pricing-list.serverless.es-pricing-info-text'
          defaultMessage='Check our <pricingLink>pricing page</pricingLink> for more details.'
          values={{
            pricingLink: (content) => (
              <EuiLink
                href='https://www.elastic.co/pricing/serverless-search'
                target='_blank'
                external={false}
              >
                {content}
              </EuiLink>
            ),
          }}
        />
      </p>
    </EuiCallOut>
  )

  return (
    <Fragment>
      {flags.es3PricingTransparency && renderEsCallout()}
      <EuiSpacer />
      <PriceList prices={getElasticsearchPrices(prices)} isLoading={isLoading} />
      <EuiHorizontalRule margin='xxl' />
      {addonList}
    </Fragment>
  )
}

export default ElasticsearchTab
