/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, type WrappedComponentProps } from 'react-intl'

import {
  EuiFormRow,
  EuiSwitch,
  EuiIconTip,
  EuiFieldText,
  EuiIcon,
  EuiSpacer,
  EuiCodeBlock,
} from '@elastic/eui'

import { getPlatform, getPlatformInfoById } from '@modules/utils/platform'
import { CuiAlert } from '@modules/cui/Alert'
import type { DeploymentCreateRequest, DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'

import { getConfigForKey } from '@/store'

import ExternalLink from '../ExternalLink'

interface Props extends WrappedComponentProps {
  userSelectedRegionId: string | undefined
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  onByokChange: (deployment: DeploymentCreateRequest | DeploymentUpdateRequest) => void
}

export type StateProps = {
  isByokToggleEnabled: boolean
  byokApplicationId: string
  byokApplicationIdRequest: AsyncRequestState
  byokServiceAccountEmail: string
  byokCloudStorageServiceAgentEmail: string
}

export type DispatchProps = {
  changeByokToggle: (value: boolean) => void
  getByokApplicationId: (regionId: string) => void
}

class ByokToggle extends Component<Props & DispatchProps & StateProps> {
  componentDidMount(): void {
    if (this.props.userSelectedRegionId && getConfigForKey(`APP_FAMILY`) === `saas`) {
      this.props.getByokApplicationId(this.props.userSelectedRegionId)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props & DispatchProps & StateProps>): void {
    if (
      prevProps.userSelectedRegionId !== this.props.userSelectedRegionId &&
      getConfigForKey(`APP_FAMILY`) === `saas`
    ) {
      if (this.props.userSelectedRegionId) {
        this.props.getByokApplicationId(this.props.userSelectedRegionId)
      }
    }
  }

  componentWillUnmount() {
    this.props.changeByokToggle(false)
  }

  render() {
    const {
      userSelectedRegionId,
      isByokToggleEnabled,
      byokApplicationIdRequest,
      byokApplicationId,
      byokServiceAccountEmail,
      byokCloudStorageServiceAgentEmail,
      intl: { formatMessage },
    } = this.props

    const platform = getPlatform(userSelectedRegionId)

    const label = getByokLabel(platform)
    const helpText = getByokHelpText(platform)

    if (
      (platform !== `aws` && platform !== `azure` && platform !== `gcp`) ||
      getConfigForKey(`APP_FAMILY`) !== `saas`
    ) {
      return null
    }

    return (
      <Fragment>
        <EuiFormRow
          label={<FormattedMessage id='byok.title' defaultMessage='Encryption at rest' />}
        >
          <EuiSwitch
            checked={isByokToggleEnabled}
            data-test-id='encryption-at-rest-toggle'
            onChange={() => this.updateToggle()}
            label={
              <Fragment>
                <FormattedMessage
                  id='byok.switch-label'
                  defaultMessage='Use a customer-managed encryption key'
                />
                {` `}
                <EuiIconTip
                  aria-label={formatMessage({
                    id: `deployment-infrastructure.kms.popover.aria`,
                    defaultMessage: `Learn more`,
                  })}
                  content={
                    <FormattedMessage
                      id='byok.switch.tooltip'
                      defaultMessage="Elastic already encrypts your deployment data and snapshots at rest by default, but you can instead choose to perform this encryption using a key that you manage through the cloud provider's Key Management Service (KMS)."
                    />
                  }
                  position='right'
                />
              </Fragment>
            }
          />
        </EuiFormRow>
        <EuiSpacer size='s' />

        {isByokToggleEnabled && (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiFormRow label={label} helpText={helpText}>
              <EuiFieldText
                prepend={<EuiIcon type={getPlatformInfoById(platform).iconType} />}
                data-test-id='encryption-at-rest-key-field'
                onChange={(e) => this.onByokChangeInner(e.target.value)}
                fullWidth={true}
                placeholder={this.getPlaceholder(platform)}
              />
            </EuiFormRow>

            {platform === `azure` && (
              <EuiFormRow
                label={`Azure application ID`}
                helpText={`Id used for granting access to the customer managed key.`}
              >
                <EuiCodeBlock isCopyable={true} paddingSize='s'>
                  {byokApplicationId}
                </EuiCodeBlock>
              </EuiFormRow>
            )}

            {platform === `gcp` && (
              <React.Fragment>
                <EuiFormRow
                  label={`Elastic service account`}
                  helpText={`Service account owned by Elastic used to access customer managed key for encryption.`}
                >
                  <EuiCodeBlock isCopyable={true} paddingSize='s'>
                    {byokServiceAccountEmail}
                  </EuiCodeBlock>
                </EuiFormRow>

                <EuiFormRow
                  label={`Google Cloud Platform cloud storage service agent`}
                  helpText={`Service agent owned by GCP used to access customer managed key for snapshot encryption.`}
                >
                  <EuiCodeBlock isCopyable={true} paddingSize='s'>
                    {byokCloudStorageServiceAgentEmail}
                  </EuiCodeBlock>
                </EuiFormRow>
              </React.Fragment>
            )}

            {byokApplicationIdRequest.error && (
              <Fragment>
                <EuiSpacer size='s' />
                <CuiAlert type='danger'>{byokApplicationIdRequest.error}</CuiAlert>
              </Fragment>
            )}

            <EuiSpacer size='s' />
          </Fragment>
        )}

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  getPlaceholder = (platform: string) => {
    if (platform === `aws`) {
      return 'arn:partition:service:region:account-id:resource-type/resource-id'
    }

    if (platform === `azure`) {
      return 'https://example-byok-key-vault.vault.azure.net/keys/test-key'
    }

    if (platform === `gcp`) {
      return 'projects/your-project/locations/your-location/keyRings/your-keyring/cryptoKeys/your-key'
    }

    return ``
  }

  updateToggle = () => {
    const { isByokToggleEnabled, changeByokToggle } = this.props

    changeByokToggle(!isByokToggleEnabled)
  }

  onByokChangeInner(value: string) {
    const { deployment, onByokChange } = this.props

    if (value === ``) {
      // remove the byok item from the deployment object
      const noByok = {
        ...deployment,
        settings: {
          ...deployment.settings,
          byok: undefined,
        },
      }
      return onByokChange(noByok)
    }

    const newDeployment = {
      ...deployment,
      settings: {
        ...deployment.settings,
        byok: {
          key_resource_path: value,
        },
      },
    }

    onByokChange(newDeployment)
  }
}

export function getByokHelpText(platform: string) {
  if (platform === `aws`) {
    return (
      <FormattedMessage
        defaultMessage='The AWS KMS key must be symmetric and available on the selected cloud region. {find}'
        id='byok.arn.help-text'
        values={{
          find: (
            <ExternalLink href='https://docs.aws.amazon.com/kms/latest/developerguide/find-cmk-id-arn.html'>
              <FormattedMessage defaultMessage='Find your key ARN' id='byok.arn.help-text.find' />
            </ExternalLink>
          ),
        }}
      />
    )
  }

  if (platform === `gcp`) {
    return (
      <FormattedMessage
        defaultMessage='The key must be symmetric encrypt/decrypt and be on the same cloud region with the deployment. {find}'
        id='byok.gcp.help-text'
        values={{
          find: (
            <ExternalLink href='https://cloud.google.com/kms/docs/getting-resource-ids'>
              <FormattedMessage
                defaultMessage='Find your key resource name'
                id='byok.gcp.help-text.find'
              />
            </ExternalLink>
          ),
        }}
      />
    )
  }

  if (platform === `azure`) {
    return (
      <FormattedMessage
        defaultMessage='The RSA key must meet certain requirements. {find}'
        id='byok.azure.help-text'
        values={{
          find: (
            <ExternalLink href='https://www.elastic.co/guide/en/cloud/current/ec-encrypt-with-cmek.html'>
              <FormattedMessage defaultMessage='Learn more' id='byok.azure.help-text.find' />
            </ExternalLink>
          ),
        }}
      />
    )
  }

  return null
}

export function getByokLabel(platform: string) {
  if (platform === `aws`) {
    return (
      <FormattedMessage defaultMessage='AWS KMS key or key alias ARN' id='byok-toggle.arn.label' />
    )
  }

  if (platform === `azure`) {
    return (
      <FormattedMessage
        defaultMessage='Azure Key Vault RSA key identifier'
        id='byok-toggle.azure.label'
      />
    )
  }

  if (platform === `gcp`) {
    return (
      <FormattedMessage defaultMessage='GCP KMS key resource name' id='byok-toggle.gcp.label' />
    )
  }

  return null
}

export default injectIntl(ByokToggle)
