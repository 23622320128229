/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiBetaBadge,
  EuiCheckableCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormFieldset,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { ElasticsearchOptimizedFor } from '@modules/project-user-api/v1/types'

import PresentOptimizationOptions from './PresentOptimizationOptions'

import type { OptimizationOptionProps } from './PresentOptimizationOptions'

type Props = {
  value: ElasticsearchOptimizedFor
  onChange: (value: ElasticsearchOptimizedFor) => void
}

const SelectSubType: React.FunctionComponent<Props> = ({ value, onChange }) => {
  const sectionTitle = (
    <FormattedMessage id='createProject.subType.title' defaultMessage='Configuration' />
  )

  return (
    <EuiFormFieldset
      legend={{
        children: <EuiTitle size='xs'>{sectionTitle}</EuiTitle>,
      }}
    >
      <EuiFlexGroup
        gutterSize='m'
        responsive={false}
        alignItems='flexStart'
        justifyContent='spaceEvenly'
      >
        <PresentOptimizationOptions
          value={value}
          onChange={onChange}
          OptimizationOptionComponent={OptimizationOption}
        />
      </EuiFlexGroup>
    </EuiFormFieldset>
  )
}

const OptimizationOption = ({
  slug,
  title,
  description,
  checked,
  disabled,
  notifyButton,
  onChange,
}: OptimizationOptionProps) => {
  const htmlId = useGeneratedHtmlId()

  const label = (
    <EuiFlexItem>
      <div style={{ whiteSpace: 'nowrap' }}>
        <strong>{title}</strong>
      </div>
      <EuiSpacer size='s' />
      <EuiText size='s'>{description}</EuiText>
    </EuiFlexItem>
  )

  return (
    <EuiFlexGroup
      style={{ position: 'relative', minWidth: '30%' }}
      gutterSize='s'
      direction='column'
    >
      <EuiFlexGroup justifyContent='center'>
        {disabled && <ComingSoonBadge />}
        <EuiCheckableCard
          id={`${htmlId}${slug}`}
          data-test-id={`radio-input-${slug}`}
          label={label}
          checked={checked}
          onChange={() => onChange(slug)}
          disabled={disabled}
        />
      </EuiFlexGroup>
      {disabled && <EuiFlexItem>{notifyButton}</EuiFlexItem>}
    </EuiFlexGroup>
  )
}

const ComingSoonBadge = () => {
  const { formatMessage } = useIntl()

  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translateY(-50%)',
      }}
    >
      <EuiBetaBadge
        label={formatMessage({
          id: 'createProject.subType.comingSoon',
          defaultMessage: 'Coming soon',
        })}
      />
    </div>
  )
}

export default SelectSubType
