/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { PaymentDetails } from '@modules/billing-api/customTypes'

import asyncRequest from '@/actions/asyncRequests'
import { getAsyncRequestState } from '@/reducers/asyncRequests'

import { GET_PAYMENT_DETAILS } from '../../constants/actions'

import type { AsyncAction, ThunkAction } from '@/types/redux'

export interface GetPaymentDetails {
  organization_id: string
}

export const getPaymentDetails = ({
  organization_id,
}: GetPaymentDetails): ThunkAction<
  Promise<AsyncAction<typeof GET_PAYMENT_DETAILS, PaymentDetails>>
> =>
  asyncRequest<typeof GET_PAYMENT_DETAILS, PaymentDetails>({
    type: GET_PAYMENT_DETAILS,
    url: `/api/v1/billing/organization/${organization_id}/payment_details`,
    method: 'get',
  })

export const getPaymentDetailsRequest = getAsyncRequestState(GET_PAYMENT_DETAILS)
