/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiAccordion,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiTextColor,
} from '@elastic/eui'

import type { Region as StackDeploymentRegion } from '@modules/ui-types'
import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'

import CreateDeployment from '../../deployment-creation-components/CreateDeployment'
import { ResourceType } from '../../discovery-questions-lib/utils'
import ChooseCSPRegion from '../../discovery-questions-components/ChooseCSPRegion'

import type { FunctionComponent } from 'react'
import type { Region } from '../../cluster-user-api/v1/types'

interface CreateResourceProps {
  isContinueStep: boolean
  hideSwitchToResource: boolean
  onDeploymentCreation: ({
    region,
    version,
    platform,
    hardware,
  }: {
    region: StackDeploymentRegion
    version: string
    platform: string
    hardware: string
  }) => Promise<void>
  onRegionConfirmed: (region?: Region) => void
  resourceType: ResourceType
  resourceCreationLaunched: boolean
  switchToResource: (rt: ResourceType) => void
  solutionType?: DefaultSolutionView | undefined
}

const CreateResource: FunctionComponent<CreateResourceProps> = ({
  isContinueStep,
  hideSwitchToResource,
  onDeploymentCreation,
  onRegionConfirmed,
  resourceType,
  resourceCreationLaunched,
  switchToResource,
  solutionType,
}) => {
  if (resourceType === ResourceType.Stateful) {
    return (
      <CreateDeployment onDeploymentCreation={onDeploymentCreation} solutionType={solutionType}>
        {!hideSwitchToResource && (
          <Fragment>
            <EuiSpacer size='xl' />
            <EuiCallOut color='success'>
              <p>
                <EuiTextColor color='default'>
                  <strong>
                    <FormattedMessage
                      id='onboarding-create-resource-serverless.title'
                      defaultMessage='Try Elastic Cloud Serverless'
                    />
                  </strong>
                  <FormattedMessage
                    id='onboarding-create-resource-serverless.description'
                    defaultMessage=': our fully managed, autoscaling infrastructure for the easiest way to run your workloads.'
                  />
                </EuiTextColor>{' '}
                <EuiLink
                  data-test-id='onboarding-create-resource-hosted-learn-more.link'
                  href='https://ela.st/compare-serverless'
                  target='_blank'
                >
                  <FormattedMessage
                    id='onboarding-create-resource-hosted-learn-more.link'
                    defaultMessage='Learn more'
                  />
                </EuiLink>
              </p>
              <p>
                <EuiLink
                  data-test-id='onboarding-create-resource-stateful-option.link'
                  color='primary'
                  onClick={() => switchToResource(ResourceType.Serverless)}
                >
                  <FormattedMessage
                    id='onboarding-create-resource-serverless-option.link'
                    defaultMessage='Switch to Elastic Cloud Serverless'
                  />
                </EuiLink>
              </p>
            </EuiCallOut>
          </Fragment>
        )}
      </CreateDeployment>
    )
  }

  const accordionButton = (
    <EuiTextColor color='subdued'>
      <FormattedMessage
        id='onboarding-create-resource-hosted-option.title'
        defaultMessage='Looking to try Elastic Cloud Hosted?'
      />
    </EuiTextColor>
  )

  return (
    <ChooseCSPRegion
      isDisabled={resourceCreationLaunched}
      onRegionConfirmed={onRegionConfirmed}
      isContinueStep={isContinueStep}
    >
      {!hideSwitchToResource && (
        <Fragment>
          <EuiSpacer size='xxl' />
          <EuiFlexGroup direction='column' gutterSize='m'>
            <EuiFlexItem>
              <p>
                <EuiTextColor color='subdued'>
                  <FormattedMessage
                    id='onboarding-create-resource-serverless-provider-location.description'
                    defaultMessage='Additional providers and locations for Elastic Cloud Serverless will be available soon.'
                  />
                </EuiTextColor>
              </p>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id='onboarding-create-resource-hosted-option'
                buttonContent={accordionButton}
              >
                <p>
                  <EuiTextColor color='subdued'>
                    <EuiSpacer size='s' />
                    <FormattedMessage
                      id='onboarding-create-resource-hosted-option.description'
                      defaultMessage='For experts looking for full control: hosted requires you to manage clusters, upgrades, backup, retention, and scaling.'
                    />{' '}
                    <EuiLink
                      data-test-id='onboarding-create-resource-serverless-option.link'
                      color='primary'
                      href='https://ela.st/compare-hosted'
                      target='_blank'
                    >
                      <FormattedMessage
                        id='onboarding-create-resource-hosted-learn-more.link'
                        defaultMessage=' Learn more'
                      />
                    </EuiLink>
                  </EuiTextColor>
                </p>
                <EuiSpacer size='m' />
                <p>
                  <EuiLink
                    data-test-id='onboarding-create-resource-hosted-option.link'
                    color='primary'
                    onClick={() => switchToResource(ResourceType.Stateful)}
                  >
                    <FormattedMessage
                      id='onboarding-create-resource-hosted-option.link'
                      defaultMessage='Switch to Cloud Hosted'
                    />
                  </EuiLink>
                </p>
              </EuiAccordion>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      )}
    </ChooseCSPRegion>
  )
}

export default CreateResource
