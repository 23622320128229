/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiText } from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'
import history from '@modules/utils/history'

import { buildAcceptInvitationUrl } from '@/lib/urlUtils'

import type { FunctionComponent } from 'react'
import type { LandingPageContext } from '@/components/LandingPageContainer/types'
import type { InvitationSource } from './types'

type Props = {
  activationHash?: string
  source?: InvitationSource
  pageContext: LandingPageContext
  loginUrl: string
}

const PageContextSwitcher: FunctionComponent<Props> = ({
  activationHash,
  source,
  pageContext,
  loginUrl,
}) => {
  if (source === 'organization-invite') {
    const linkMessage =
      pageContext.name === 'login' ? (
        <FormattedMessage id='accept-invite.a-new-account' defaultMessage='a new account' />
      ) : (
        <FormattedMessage
          id='accept-invite.an-existing-account'
          defaultMessage='an existing account'
        />
      )

    return (
      <EuiText textAlign='center' size='s'>
        <FormattedMessage
          id='accept-invite.or-join-the-organization-with-x'
          defaultMessage='Join the organization with {link}.'
          values={{
            link: (
              <EuiLink onClick={onClick} data-test-id='accept-invite.page-context-switcher-link'>
                {linkMessage}
              </EuiLink>
            ),
          }}
        />
      </EuiText>
    )
  }

  return (
    <EuiText textAlign='center' size='s'>
      <FormattedMessage
        id='accept-invite.already-have-an-account'
        defaultMessage='Already have an account? {link}.'
        values={{
          link: (
            <CuiLink to={loginUrl} data-test-id='accept-invite.already-have-an-account'>
              <FormattedMessage id='accept-invite.login' defaultMessage='Login' />
            </CuiLink>
          ),
        }}
      />
    </EuiText>
  )

  function onClick() {
    const switchedPageContext: LandingPageContext = {
      name: pageContext.name === 'login' ? 'signup' : 'login',
    }

    history.replace(
      buildAcceptInvitationUrl({ activationHash, source, pageContext: switchedPageContext }),
    )
  }
}

export default PageContextSwitcher
