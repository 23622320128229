/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  // EuiButtonIcon,
  // EuiCallOut,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormLabel,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import { getPlatform, getPlatformInfoById } from '@modules/utils/platform'
// import type { DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'

// import {
//   sanitizeUpdateRequestBeforeSend,
//   createUpdateRequestFromGetResponse,
// } from '@/lib/stackDeployments/updates'
// import DangerButton from '@/components/DangerButton'
import { getByokHelpText, getByokLabel } from '@/components/ByokToggle/ByokToggle'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'

import type { AllProps } from '.'

type State = {
  isFlyoutOpen: boolean
  key: string
}

class ManageByok extends Component<AllProps, State> {
  state = {
    isFlyoutOpen: false,
    key: this.props.deployment.settings?.byok?.key_resource_path || '',
  }

  render() {
    return (
      <PrivacySensitiveContainer>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={
            <h3>
              <FormattedMessage id='managebyok.title' defaultMessage='Encryption at rest' />
            </h3>
          }
          description={
            <FormattedMessage
              id='managebyok.description'
              tagName='p'
              defaultMessage='Deployment data and snapshots are encrypted at rest by default. You can also use your own customer-managed key to further enhance security.'
            />
          }
        >
          <div>
            <EuiButton
              data-test-id='keystore-createSettingsButton'
              size='s'
              onClick={() => this.setState({ isFlyoutOpen: true })}
            >
              <FormattedMessage
                id='managebyok.openflyout-btn'
                defaultMessage='Manage encryption key'
              />
            </EuiButton>
          </div>
        </EuiDescribedFormGroup>

        {this.state.isFlyoutOpen && this.renderFlyout()}
      </PrivacySensitiveContainer>
    )
  }

  renderFlyout() {
    const { deployment } = this.props
    const platform = getPlatform(getRegionId({ deployment }))

    return (
      <EuiFlyout
        onClose={() => this.setState({ isFlyoutOpen: false })}
        aria-labelledby='managebyok-flyoutTitle'
        maxWidth={600}
        ownFocus={true}
      >
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h2 id='managebyok-flyoutTitle'>
              <FormattedMessage
                id='managebyok.flyout.title'
                defaultMessage='Manage encryption key'
              />
            </h2>
          </EuiTitle>
          <EuiHorizontalRule margin='xs' />
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <EuiText>
            <p>
              <FormattedMessage
                id='managebyok.flyout-body.description'
                defaultMessage='This deployment currently uses a customer-managed key to encrypt data and snapshots at rest.'
              />
            </p>
          </EuiText>
          {/* <EuiSpacer /> */}
          {/* <EuiCallOut color='warning'>
            <EuiText size='s'>
              <p>
                <FormattedMessage
                  id='managebyok.flyout-body.callout-warning'
                  defaultMessage='Adding or removing a customer-managed encryption key for an existing deployment requires re-encryption of the deployment data. Existing snapshots will not be re-encrypted and future snapshots will be encryped with the new key.'
                />
              </p>
            </EuiText>
          </EuiCallOut> */}

          <EuiSpacer />

          <EuiFieldText
            readOnly={true}
            prepend={
              <EuiFormLabel style={{ width: `210px` }}>
                <FormattedMessage
                  id='managebyok.flyout.textbox-prepend'
                  defaultMessage='Key management service'
                />
              </EuiFormLabel>
            }
            icon={{ type: getPlatformInfoById(platform).iconType, side: 'left' }}
            fullWidth={true}
            value={`${getPlatformInfoById(platform).title} KMS`}
          />

          <EuiSpacer />

          <EuiFormRow label={getByokLabel(platform)} helpText={getByokHelpText(platform)}>
            <EuiFieldText
              prepend={<EuiIcon type={getPlatformInfoById(platform).iconType} />}
              // append={
              //   <EuiButtonIcon
              //     iconType='trash'
              //     color='danger'
              //     aria-label='Clear'
              //     onClick={() => this.deleteKey()}
              //   />
              // }
              onChange={(e) => this.setState({ key: e.target.value })}
              fullWidth={true}
              value={this.state.key}
              disabled={true}
            />
          </EuiFormRow>
        </EuiFlyoutBody>

        {this.renderFooter()}
      </EuiFlyout>
    )
  }

  renderFooter() {
    return (
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              iconType='cross'
              onClick={() => this.setState({ isFlyoutOpen: false })}
              flush='left'
            >
              <FormattedMessage id='managebyok.flyout.close' defaultMessage='Close' />
            </EuiButtonEmpty>
          </EuiFlexItem>
          {/* TODO: Re-enable this when the API is ready */}
          {/* <EuiFlexItem grow={false}>
            <DangerButton
              onConfirm={() => this.saveKey()}
              fill={true}
              className='managebyok-saveButton'
              data-test-id='managebyok-saveButton'
              modal={{
                title: (
                  <FormattedMessage
                    id='manage-byok.confirm-update-key.title'
                    defaultMessage='Replace customer-managed key?'
                  />
                ),
                body: (
                  <Fragment>
                    <p>
                      <FormattedMessage
                        id='manage-byok.confirm-update-key.body-1'
                        defaultMessage='By confirming this operation, your deployment data will be re-encrypted using the new key. This operation can take some time depending on the size of your deployment. Future snapshots will also be encrypted using this new key.'
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id='manage-byok.confirm-update-key.body-2'
                        defaultMessage='Note that existing snapshots will not be re-encrypted.'
                      />
                    </p>
                  </Fragment>
                ),
              }}
            >
              <FormattedMessage id='keystore.flyout.save' defaultMessage='Save' />
            </DangerButton>
          </EuiFlexItem> */}
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    )
  }

  saveKey() {
    // TODO: Re-enable this when the API is ready
    // const { updateDeployment, deployment, deploymentTemplate } = this.props
    // const updateDeploymentObj: DeploymentUpdateRequest = createUpdateRequestFromGetResponse({
    //   deployment,
    //   deploymentTemplate,
    // })
    // updateDeployment({
    //   deploymentId: deployment.id,
    //   deployment: sanitizeUpdateRequestBeforeSend({ deployment: updateDeploymentObj }),
    //   redirect: true,
    //   dryRun: false,
    // })
  }

  deleteKey() {
    // TODO: Implement delete key
  }
}

export default ManageByok
