/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { StackDeployment } from '@modules/ui-types'

import { hasUnhealthyIlmMigrationPlan } from '@/lib/stackDeployments/selectors/configurationChangeHealth'

import type { Problem } from '../../problems'

/**
 * Reports on issues that are currently being reported by the Deployment Health API.
 * This reporter handles ILM migration health issues.
 */
export function getIlmMigrationProblems(deployment: StackDeployment): Problem[] {
  const problems: Problem[] = []

  if (hasUnhealthyIlmMigrationPlan({ deployment })) {
    problems.push({
      'data-test-id': `es-ilm-migration-failed`,
      kind: `elasticsearch`,
      id: `es-ilm-migration-failed`,
      level: `danger`,
      sticky: true,
      title: (
        <FormattedMessage
          id='deployment-health-problems.migration-failed.title'
          defaultMessage='Index Lifecycle Management migration failed'
        />
      ),
      message: (
        <FormattedMessage
          id='deployment-health-problems.es-ilm-migration-failed'
          defaultMessage='A problem occurred while migrating your deployment to Index Lifecycle Management.'
        />
      ),
    })
  }

  return problems
}
