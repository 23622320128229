/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import {
  useGetDeploymentTemplatesQuery,
  useSearchOrganizationDeploymentsQuery,
} from '@modules/cloud-lib/deployments/hooks'
import { useGetDeploymentQuery } from '@modules/deployment-creation-api/hooks'
import { useOrganizationId } from '@modules/permissions-lib/hooks'
import type { StackDeployment } from '@modules/ui-types'

import { getRegionId, getVersion } from '@/lib/stackDeployments/selectors/fundamentals'

/**
 * A query hook to fetch the deployments of a trial user's organization.
 */
function useSearchTrialDeployment() {
  const { organizationId, isOrganizationIdFetched } = useOrganizationId()

  return useSearchOrganizationDeploymentsQuery(organizationId ?? '', {
    enabled: isOrganizationIdFetched,
  })
}

/**
 * A query hook to fetch the deployment template of a given deployment.
 * @param deployment The deployment to fetch the template for.
 */
export function useDeploymentTemplateFromDeployment(deployment: StackDeployment | undefined) {
  const stackVersion = deployment && getVersion({ deployment })

  const region = (deployment && getRegionId({ deployment })) ?? ''

  return useGetDeploymentTemplatesQuery(
    {
      region,
      stackVersion,
    },
    {
      enabled: deployment !== undefined,
    },
  )
}

/**
 * A query hook to fetch the deployment of a trial user's organization.
 * The difference between this and `useSearchTrialDeployment` is that this hook
 * fetches the deployment details of the first deployment in the search results
 * of the organization's deployments (if any). Also, the search results contain
 * less information than the deployment details fetched by this hook.
 */
export function useTrialDeployment() {
  const { data: searchResults, isLoading: isLoadingTrialDeploymentsSearch } =
    useSearchTrialDeployment()

  const [deploymentSearchResult] = searchResults?.deployments ?? []

  const getDeploymentQueryResult = useGetDeploymentQuery(deploymentSearchResult?.id, {
    enabled: deploymentSearchResult !== undefined,
  })

  return {
    ...getDeploymentQueryResult,
    isLoading: getDeploymentQueryResult.isLoading || isLoadingTrialDeploymentsSearch,
  }
}
