/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage, injectIntl } from 'react-intl'
import React, { useState } from 'react'

import { EuiButton, EuiFormRow, EuiButtonEmpty } from '@elastic/eui'

import { addToast } from '@modules/cui/Toasts'
import { useServerlessGroupBy } from '@modules/billing-lib/featureflags'

import downloadCSV from '@/components/User/BillingUsage/components/Filters/CsvDownloadButton/utils'

import type { Moment } from 'moment/moment'
import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'
import type { GroupBy } from '../../../types'

interface Props {
  startDate: Moment
  endDate: Moment
  organizationId: string
  instanceId?: string
  groupBy: GroupBy
  emptyButtonComponent?: boolean
}

const CsvDownloadButton: FunctionComponent<Props & WrappedComponentProps> = ({
  startDate,
  endDate,
  organizationId,
  instanceId,
  groupBy,
  intl: { formatMessage },
  emptyButtonComponent,
}) => {
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false)
  const ButtonComponent = emptyButtonComponent ? EuiButtonEmpty : EuiButton
  const serverlessGroupByParam = useServerlessGroupBy()

  const onDownloadCSV = async () => {
    setIsDownloadingCSV(true)
    const params = {
      startDate,
      endDate,
      organizationId,
      instanceId,
      serverlessGroupByParam,
      groupBy,
    }

    try {
      await downloadCSV(params)
    } catch {
      addToast({
        family: 'billing-usage.download-csv',
        color: 'danger',
        title: formatMessage({
          id: 'billing-usage.download-csv.failure',
          defaultMessage: 'Could not download CSV',
        }),
      })
    } finally {
      setIsDownloadingCSV(false)
    }
  }

  return (
    <EuiFormRow fullWidth={true} hasEmptyLabelSpace={true}>
      <ButtonComponent
        onClick={onDownloadCSV}
        iconType='download'
        isLoading={isDownloadingCSV}
        data-test-id='billing-usage.download-csv-button'
        fill={false}
      >
        <FormattedMessage id='billing-usage.download-csv' defaultMessage='Download CSV' />
      </ButtonComponent>
    </EuiFormRow>
  )
}

export default injectIntl(CsvDownloadButton)
