/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { type FunctionComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiComboBox, EuiToolTip } from '@elastic/eui'

import type { Region } from '@modules/cloud-api/v1/types'
import { withErrorBoundary } from '@modules/cui/Boundary'
import { getRegionDisplayName } from '@modules/utils/region'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  regionId: string | null
  availableRegions: Region[] | null
  onChange: (regionId: string) => void
  restoreFromSnapshot: boolean
  loading?: boolean
  disabled?: boolean
  prepend?: boolean
  'aria-labelledby'?: string
}

const SelectRegion: FunctionComponent<Props> = ({
  restoreFromSnapshot,
  prepend = true,
  intl: { formatMessage },
  regionId,
  availableRegions,
  onChange,
  loading,
  disabled,
  'aria-labelledby': ariaLabelledBy,
}) => {
  const label = formatMessage({
    id: 'select-region-label',
    defaultMessage: 'Region',
  })

  const regionOptions =
    availableRegions?.map((region) => ({
      label: getRegionDisplayName({ region }),
      value: region.identifier,
    })) ?? []

  const selectedValue = availableRegions?.find((region) => region.identifier === regionId)

  const selectedName = getRegionDisplayName({ region: selectedValue })
  const isDisabled = disabled || restoreFromSnapshot

  const select = (
    <EuiComboBox
      fullWidth={true}
      css={{ '.euiFormControlLayout__prepend': { width: 180 } }}
      prepend={prepend ? label : undefined}
      options={regionOptions}
      selectedOptions={[{ label: selectedName }]}
      onChange={(newRegion) => onChange(newRegion[0]!.value!)}
      singleSelection={{ asPlainText: true }}
      isDisabled={isDisabled}
      isClearable={false}
      isLoading={loading}
      data-test-id='region-combobox'
      aria-label={ariaLabelledBy ?? label}
    />
  )

  return restoreFromSnapshot ? (
    <EuiToolTip
      display='block'
      position='top'
      content={
        <FormattedMessage
          defaultMessage='You cannot change the region as you are restoring data from a snapshot.'
          id='select-region-disabled-tooltip'
        />
      }
    >
      {select}
    </EuiToolTip>
  ) : (
    select
  )
}

export default withErrorBoundary(injectIntl(SelectRegion))
