/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { useHistory } from 'react-router'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer, EuiText } from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'

import { UserSubscriptionValues } from '@/lib/urlUtils'
import { marketplaceDisplayName } from '@/lib/marketPlace'
import { supportUrl } from '@/lib/urlBuilder'

import DocLink from '../DocLink'

import type { FunctionComponent } from 'react'

type Props = {
  partner: string
}

const MarketplaceExistingUserNotice: FunctionComponent<Props> = ({ partner }) => {
  const history = useHistory<any>()
  const {
    location: { state },
  } = history
  const marketplace = UserSubscriptionValues.find((key) => key === partner)
  const marketplaceLabel = marketplace ? marketplaceDisplayName(marketplace) : marketplace

  if (!state?.hasExistingSubscription) {
    return null
  }

  return (
    <div className='info-message'>
      <EuiCallOut
        iconType='pinFilled'
        size='s'
        title={
          <FormattedMessage
            data-test-id='community-info-message'
            id='info-message-marketplace.title.community'
            defaultMessage='{marketplaceLabel, select, AWS {An} Azure {An} other {A}} {marketplaceLabel} marketplace subscription already exists'
            values={{ marketplaceLabel }}
          />
        }
      >
        <EuiText size='xs'>
          {partner === 'gcp' ? (
            <FormattedMessage
              id='info-message-marketplace.support.description-gcp'
              defaultMessage='An Elastic Cloud account is already associated with your {marketplaceLabel} project or {marketplaceLabel} billing account. Log in with the existing account or contact your {marketplaceLabel} administrator for access. {learnMore}. If you need help, contact {support}'
              values={{
                marketplaceLabel,
                learnMore: (
                  <DocLink link='accountBillingGcpDocLink'>
                    <FormattedMessage
                      id='partner-signupMessage.learnMore'
                      defaultMessage='Learn more'
                    />
                  </DocLink>
                ),
                support: (
                  <CuiLink to={supportUrl()}>
                    <FormattedMessage id='support' defaultMessage='Support' />
                  </CuiLink>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id='info-message-marketplace.support.description'
              defaultMessage='An Elastic Cloud account is already associated with your {marketplaceLabel} billing account. Log in with the existing account or contact your {marketplaceLabel} administrator for access.'
              values={{ marketplaceLabel }}
            />
          )}
          <EuiSpacer size='m' />
        </EuiText>
      </EuiCallOut>

      <EuiSpacer size='m' />
    </div>
  )
}

export default MarketplaceExistingUserNotice
