/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { combineReducers } from 'redux'

import type { RegionId } from '@modules/ui-types'

import regionsReducer, {
  getRegion as getRegionFromList,
  getRegionIds as getRegionIdsFromList,
} from './list'
import constructorsReducer, { getConstructors } from './constructors'

import type { ReduxState } from '@/types/redux'
import type { State as ConstructorsState } from './constructors'
import type { State as ListState } from './types'

export type State = {
  list: ListState
  constructors: ConstructorsState
}

function _getRegion(state, regionId) {
  return getRegionFromList(state.list, regionId)
}

function _getRegionIds(state) {
  return getRegionIdsFromList(state.list)
}

function _getRegionConstructors(state, regionId) {
  return getConstructors(state.constructors, regionId)
}

export default combineReducers<State>({
  list: regionsReducer,
  constructors: constructorsReducer,
})

export const getRegion = (state: ReduxState, regionId: RegionId) =>
  _getRegion(state.regions, regionId)

export const getRegionConstructors = (state: ReduxState, regionId: RegionId) =>
  _getRegionConstructors(state.regions, regionId)

export const getRegionIds = (state: ReduxState) => _getRegionIds(state.regions)
