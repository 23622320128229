/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { CuiButtonIcon } from '@modules/cui/Button'

import { getOrganizationId } from '@/lib/stackDeployments/selectors/metadata'

import {
  createUpdateTrustRequestFromGetResponse,
  getTrustRelationshipId,
  getTrustRelationshipsFromDeployment,
  getTrustRelationshipType,
  isAccountRelationship,
  isOwnAccountRelationship,
} from '../../../../lib/stackDeployments/trustRelationships'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

class DeploymentTrustRelationshipDeleteButton extends Component<AllProps & WrappedComponentProps> {
  componentWillUnmount(): void {
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element | null {
    const {
      deployment,
      trustRelationship,
      disabled,
      intl: { formatMessage },
    } = this.props

    const isDeploymentAccount = isOwnAccountRelationship(
      trustRelationship,
      getOrganizationId({ deployment }),
    )

    if (isDeploymentAccount) {
      return (
        <CuiButtonIcon
          iconType='trash'
          aria-label={formatMessage({ id: 'delete' })}
          disabled={true}
        />
      )
    }

    const environmentName = getTrustRelationshipId({ trustRelationship })

    return (
      <CuiButtonIcon
        iconType='trash'
        aria-label={formatMessage({ id: 'delete' })}
        onClick={this.onDelete}
        disabled={disabled}
        confirm={true}
        confirmModalProps={{
          body: isAccountRelationship(trustRelationship) ? (
            <FormattedMessage
              id='deploymentTrustManagement.table.actions.accountTrustRelationshipDelete'
              defaultMessage='This will remove the relationship with the organization {environmentName}.'
              values={{ environmentName }}
            />
          ) : (
            <FormattedMessage
              id='deploymentTrustManagement.table.actions.externalOrDirectTrustRelationshipDelete'
              defaultMessage='This will remove the relationship with the environment {environmentName}.'
              values={{ environmentName }}
            />
          ),
        }}
      />
    )
  }

  onDelete = (): void => {
    const { deployment, trustRelationship, updateStackDeployment } = this.props

    const id = getTrustRelationshipId({ trustRelationship })
    const trustRelationshipType = getTrustRelationshipType(trustRelationship)

    const trustRelationships = getTrustRelationshipsFromDeployment({
      deployment,
      trustRelationshipType,
    })

    const payload = createUpdateTrustRequestFromGetResponse({
      deployment,
      trustRelationships: trustRelationships.filter(
        (_trustRelationship) =>
          getTrustRelationshipId({ trustRelationship: _trustRelationship }) !== id,
      ),
      type: trustRelationshipType,
      replace: true,
    })

    updateStackDeployment(payload)
  }
}

export default injectIntl(DeploymentTrustRelationshipDeleteButton)
