/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type {
  AsyncRequestState,
  ProfileState,
  BasePrice,
  RegionId,
  BillingSubscriptionLevel,
} from '@modules/ui-types'

import { getBasePrices } from '@/apps/userconsole/reducers/pricing/basePricing'
import { fetchBasePricesRequest } from '@/apps/userconsole/reducers/registry'
import { getProfile } from '@/apps/userconsole/reducers/profile'

import { fetchBasePricesIfNeeded } from '../../../../apps/userconsole/actions/pricing'

import PriceButton from './PriceButton'

import type { ThunkDispatch } from '../../../../types/redux'

type StateProps = {
  profile: ProfileState
  basePrices?: BasePrice[]
  fetchBasePricesRequest: AsyncRequestState
}

interface DispatchProps {
  fetchBasePrices: ({ regionId }) => Promise<any>
}

type ConsumerProps = {
  regionId: RegionId
  level?: BillingSubscriptionLevel
}

const mapStateToProps = (state, { regionId, level }): StateProps => ({
  basePrices: getBasePrices(state, regionId, level),
  fetchBasePricesRequest: fetchBasePricesRequest(state, regionId, level),
  profile: getProfile(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch, { level }: ConsumerProps): DispatchProps => ({
  fetchBasePrices: ({ regionId }: { regionId: string }) =>
    dispatch(fetchBasePricesIfNeeded({ regionId, level })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PriceButton)
