/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { deleteApiKeyUrl, getUsersApiKeysUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'
import { REVOKE_CURRENT_USER_API_KEY, REVOKE_USERS_API_KEYS } from '../../constants/actions'

import { fetchAllUsersApiKeys, fetchCurrentUserApiKeys } from './fetchApiKeys'

import type { ApiKeyRow } from '@/components/ApiKeys/types'

export function revokeCurrentUserApiKey(keyId: string) {
  return (dispatch) => {
    const url = deleteApiKeyUrl({ apiKeyId: keyId })

    return dispatch(
      asyncRequest({
        type: REVOKE_CURRENT_USER_API_KEY,
        method: `DELETE`,
        url,
      }),
    ).then(() => dispatch(fetchCurrentUserApiKeys()))
  }
}

export function revokeUsersApiKeys(keys: ApiKeyRow[]) {
  return (dispatch) => {
    const userApiKeys = keys.map((key) => ({
      api_key_id: key.id,
      user_id: key.user_id,
    }))

    const url = getUsersApiKeysUrl()

    return dispatch(
      asyncRequest({
        type: REVOKE_USERS_API_KEYS,
        method: `DELETE`,
        payload: { user_api_keys: userApiKeys },
        url,
      }),
    ).then(() => dispatch(fetchAllUsersApiKeys()))
  }
}

export function resetRevokeCurrentUserApiKeyRequest(...crumbs) {
  return resetAsyncRequest(REVOKE_CURRENT_USER_API_KEY, crumbs)
}

export function resetRevokeUsersApiKeysRequest(...crumbs) {
  return resetAsyncRequest(REVOKE_USERS_API_KEYS, crumbs)
}
