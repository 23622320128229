/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { capitalize } from 'lodash'
import { FormattedMessage, FormattedDate } from 'react-intl'

import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiHorizontalRule,
  EuiCallOut,
  EuiTitle,
} from '@elastic/eui'

import type { UserProfile } from '@modules/ui-types'
import type { WithBillingDetailsProps } from '@modules/billing-lib/billingDetails/types'
import { isPrepaidConsumptionDirectResellerCustomer } from '@modules/billing-lib/billingDetails/utils'

import { numericDateTime } from '@/config/dates'
import OrganizationPrepaids from '@/components/Organization/OrganizationPrepaids'
import SubscriptionManager from '@/components/User/BillingSubscription/components/SubscriptionManager'
import SuggestedContentPanel from '@/apps/userconsole/components/Billing/BillingOverview/SuggestedContentPanel'
import waves from '@/files/waves-lightblue.svg'

import PrepaidAccountDetailsPanel from '../BillingOverview/PrepaidAccountDetailsPanel'

import SubscriptionDetailsCard from './components/SubscriptionDetailsCard'
import PaymentDetailsCard from './components/PaymentDetailsCard'

import type { FunctionComponent } from 'react'

import './prepaidConsumptionSubscription.scss'

export interface Props extends WithBillingDetailsProps {
  profile: UserProfile
  showPrepaidDetails: boolean
}

const PrepaidConsumptionSubscription: FunctionComponent<Props> = ({
  profile,
  showPrepaidDetails,
  billingDetails,
}) => (
  <Fragment>
    {profile.pending_level && (
      <EuiCallOut
        size='s'
        title={
          <FormattedMessage
            id='billing-overview.subscription-updating.message'
            defaultMessage='The subscription update {pending_level} is in process. The changes become effective on {pending_level_valid_from}.'
            values={{
              pending_level: capitalize(profile.pending_level),
              pending_level_valid_from: (
                <FormattedDate
                  data-test-id='expiration-date-column-value'
                  value={profile.pending_level_valid_from}
                  {...numericDateTime}
                />
              ),
            }}
          />
        }
        iconType='clock'
        data-test-id='prepaid-consumption-subscription.pending-subscription'
      />
    )}

    <EuiSpacer size='m' />

    <EuiFlexGroup gutterSize='m' wrap={true}>
      <EuiFlexItem grow={7}>
        <EuiFlexGroup>
          <EuiFlexItem className='content-item'>
            <SubscriptionDetailsCard
              subscriptionLevel={profile.level}
              title={
                isPrepaidConsumptionDirectResellerCustomer(billingDetails.data!) ? (
                  <FormattedMessage
                    id='prepaid-consumption-subscription.title-reseller'
                    defaultMessage='Annual prepaid reseller subscription'
                  />
                ) : (
                  <FormattedMessage
                    id='prepaid-consumption-subscription.title'
                    defaultMessage='Annual prepaid subscription'
                  />
                )
              }
              footer={
                <SubscriptionManager
                  buttonText={
                    <FormattedMessage
                      id='billing-subscription.change-plan-cta'
                      defaultMessage='Change plan'
                    />
                  }
                />
              }
              backgroundImage={waves}
            />
          </EuiFlexItem>
          <EuiFlexItem className='content-item'>
            <PaymentDetailsCard
              details={
                <FormattedMessage
                  id='billing-subscription.payment-details'
                  defaultMessage='Paid by purchase order'
                />
              }
              footer={
                <FormattedMessage
                  id='billing-subscription.payment-footer'
                  defaultMessage='To add more funds, contact your account sales executive.'
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xl' />

        <EuiFlexGroup>
          <EuiFlexItem className='content-item'>
            <PrepaidAccountDetailsPanel
              hasBorder={true}
              profile={profile}
              title={
                <EuiTitle size='xxxs'>
                  <h2>
                    <FormattedMessage
                      id='billing-subscription.available-credits'
                      defaultMessage='Available credits'
                    />
                  </h2>
                </EuiTitle>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        {showPrepaidDetails && (
          <Fragment>
            <EuiSpacer size='s' />

            <EuiHorizontalRule />

            <EuiSpacer size='s' />

            <OrganizationPrepaids organizationId={profile.organization_id} />
          </Fragment>
        )}
      </EuiFlexItem>

      <EuiFlexItem>
        <SuggestedContentPanel
          hasBorder={true}
          data-test-id='billing-details-summary.suggested-content'
          showEcuMessage={true}
          title={
            <EuiText>
              <h6>
                <FormattedMessage
                  id='billing-history.suggested-content-title'
                  defaultMessage='Suggested content'
                />
              </h6>
            </EuiText>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  </Fragment>
)

export default PrepaidConsumptionSubscription
