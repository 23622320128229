/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ProxyOverview } from '@modules/cloud-api/v1/types'
import type { RegionId, RegionProxies } from '@modules/ui-types'

import { FETCH_PROXIES } from '../../constants/actions'

import createProxies from './createProxies'

import type { ReduxState } from '@/types/redux'

export interface State {
  [regionId: string]: RegionProxies
}

type Action = {
  type: typeof FETCH_PROXIES
  error?: boolean
  payload?: ProxyOverview
  meta: {
    regionId: RegionId
    reqId: string
  }
}

const initialState: State = {}

export default function proxiesReducer(proxies: State = initialState, action: Action): State {
  if (action.type === FETCH_PROXIES) {
    if (!action.error && action.payload) {
      const { regionId } = action.meta
      return {
        ...proxies,
        [regionId]: createProxies(action.payload),
      }
    }
  }

  return proxies
}

function _getProxies(state: State, regionId: RegionId): RegionProxies | undefined {
  return state[regionId]
}

export const getProxies = (state: ReduxState, regionId: RegionId) =>
  _getProxies(state.proxies, regionId)
