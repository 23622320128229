/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { Fragment, type FunctionComponent } from 'react'

import {
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  useEuiTheme,
  EuiSkeletonText,
} from '@elastic/eui'

import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import LandingPageSeparator from '@modules/access-management-components/HorizontalSeparator'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useOnboardingToken } from '@modules/discovery-questions-lib/hooks'

import MarketplaceSignUp from './MarketplaceSignUp'
import UserRegistrationForm from './Form'

interface Props {
  createUser: ({ email, password, captcha_token }) => Promise<any>
  isServerlessUserRegistration?: boolean
  isMarketplaceSignupEnabled?: boolean
  redirectTo?: string
}

const title = (
  <FormattedMessage id='signup-cloud-wrapper.title' defaultMessage='Sign up for a free trial' />
)

const subtitle = (
  <FormattedMessage id='signup-cloud-wrapper.subtitle' defaultMessage='No credit card required' />
)

const SignUpPage: FunctionComponent<Props> = ({
  createUser,
  isServerlessUserRegistration,
  isMarketplaceSignupEnabled,
  redirectTo,
}: Props) => {
  const onboardingToken = useOnboardingToken()
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  const { signUpFlowV2 } = flags

  const isFlowV2 = signUpFlowV2 || isServerlessUserRegistration || onboardingToken

  const { euiTheme } = useEuiTheme()

  if (!isFlagUsable) {
    return <EuiSkeletonText lines={10} />
  }

  if (isFlowV2) {
    return (
      <LandingPageOuterContainer pageContext={{ name: 'signup' }} isFlowV2={isFlowV2}>
        <EuiFlexItem grow={false} style={{ alignItems: 'center' }}>
          <EuiFlexGroup alignItems='center' direction='column' gutterSize='m'>
            <EuiFlexItem>
              <LandingPageInnerContainer title={title} isFlowV2={isFlowV2}>
                <UserRegistrationForm
                  createUser={createUser}
                  isFlowV2={isFlowV2}
                  redirectTo={redirectTo}
                />

                {isMarketplaceSignupEnabled && (
                  <Fragment>
                    <EuiSpacer size='l' />

                    <LandingPageSeparator
                      label={
                        <EuiTitle size='m'>
                          <FormattedMessage id='signup-page.separator' defaultMessage='OR' />
                        </EuiTitle>
                      }
                    />
                  </Fragment>
                )}
              </LandingPageInnerContainer>
            </EuiFlexItem>
            {isMarketplaceSignupEnabled && <MarketplaceSignUp isFlowV2={isFlowV2} />}
          </EuiFlexGroup>
        </EuiFlexItem>
      </LandingPageOuterContainer>
    )
  }

  return (
    <LandingPageOuterContainer pageContext={{ name: 'signup' }}>
      <EuiFlexItem grow={false} style={{ alignItems: 'center' }}>
        <EuiFlexGroup
          style={{ maxWidth: 1074, width: '100%' }}
          alignItems='flexStart'
          direction='row'
          gutterSize='l'
        >
          <EuiFlexItem>
            <LandingPageInnerContainer
              title={title}
              subtitle={subtitle}
              panelProps={{ style: { margin: 0, maxWidth: 'none' }, paddingSize: 'l' }}
            >
              <UserRegistrationForm createUser={createUser} />
            </LandingPageInnerContainer>
          </EuiFlexItem>

          <EuiFlexItem
            grow={false}
            css={css(`
              align-items: center;
              color: ${euiTheme.colors.subduedText},
              width: 90px;
              height: 275px;
              paddingTop: 21px;
                &::before,
                &::after {
                  content: '';
                  flex: 1;
                  border-right: 1px solid ${euiTheme.colors.lightShade};
                }
              &::before {
                margin-bottom: .75em;
              }
              &::after {
                margin-top: .75em;
              }
              @media only screen (min-width: ${euiTheme.breakpoint.m}px): {
                height: 60px;
                padding-top: 0;
              }
            `)}
          >
            <EuiTitle size='m'>
              <FormattedMessage id='signup-page.separator' defaultMessage='OR' />
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem>
            <MarketplaceSignUp />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </LandingPageOuterContainer>
  )
}

export default SignUpPage
