/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { NewDeploymentCredentials } from '@modules/ui-types'

import { CLEAR_CLUSTER_CREDENTIALS, RESET_CLUSTER_PASSWORD } from '@/constants/actions'

import type { ReduxState } from '@/types/redux'
import type { ClusterCredentialsAction, ResetPasswordAction } from './clusterTypes'

export interface State {
  [descriptor: string]: NewDeploymentCredentials | undefined
}

function reduceCredentials(
  state: NewDeploymentCredentials | undefined,
  action: ResetPasswordAction,
): NewDeploymentCredentials | undefined {
  const username = action.payload?.username ?? 'elastic'
  const password = action.payload?.password

  if (password !== undefined) {
    return {
      username,
      password,
      resetPassword: true,
    }
  }

  return state
}

function createDescriptor(id: string, refId: string) {
  return `${id}/${refId}`
}

export default function clustersCredentialsReducer(
  state: State = {},
  action: ClusterCredentialsAction,
): State {
  if (action.error) {
    return state
  }

  if (action.type === RESET_CLUSTER_PASSWORD) {
    const { id, refId } = action.meta
    const descriptor = createDescriptor(id, refId)

    if (!descriptor) {
      return state
    }

    return {
      ...state,
      [descriptor]: reduceCredentials(state[descriptor], action),
    }
  }

  if (action.type === CLEAR_CLUSTER_CREDENTIALS) {
    const { id, refId } = action.meta
    const descriptor = createDescriptor(id, refId)

    if (!descriptor) {
      return state
    }

    return Object.keys(state)
      .filter((each) => each !== descriptor)
      .reduce((newState, eachDescriptor: string) => {
        newState[eachDescriptor] = state[eachDescriptor]
        return newState
      }, {})
  }

  return state
}

export function _getClusterCredentials(state: State, id: string, refId: string) {
  const descriptor = createDescriptor(id, refId)
  return state[descriptor]
}

export const getClusterCredentials = (state: ReduxState, id: string, refId: string) =>
  _getClusterCredentials(state.clustersCredentials, id, refId)
