/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'

import type { DeploymentsSearchResponse, SearchRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, ProfileState } from '@modules/ui-types'

import { shouldShowOrganization } from '@/components/Organization/lib'
import { getStackDeploymentsFromSearch } from '@/reducers/stackDeploymentSearches'
import { getDeletedStackDeploymentIds } from '@/reducers/stackDeployments'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import { searchStackDeploymentsRequest } from '@/reducers/asyncRequests/registry'

import { searchDeployments } from '../../../../actions/stackDeployments'
import { fetchProfile } from '../../../../apps/userconsole/actions/profile'
import { getConfigForKey } from '../../../../selectors'

import PortalLandingPage from './PortalLandingPage'

import type { ThunkDispatch, ReduxState } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'
import type { InvitationType } from '@/apps/userconsole/components/AcceptInvitation/types'

interface StateProps {
  hasCloudStatusTile: boolean
  deletedDeploymentIds: string[]
  searchResults: DeploymentsSearchResponse | null
  profile: ProfileState
  searchResultsRequest: AsyncRequestState
  invitationType: InvitationType | null
  invitationToken: string | null
  invitationOrganizationId: string | null
  invitationOrganizationName: string | null
  showOrganization: boolean
}

interface DispatchProps {
  fetchProfile: () => Promise<any>
  searchDeployments: (query: SearchRequest) => void
}

type ConsumerProps = {
  isRouteFSTraced?: boolean
} & RouteComponentProps

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { search } = location
  const query = parse(search.slice(1))

  const invitationType =
    typeof query.invitationType === `string` ? (query.invitationType as InvitationType) : null
  const invitationToken = typeof query.invitationToken === `string` ? query.invitationToken : null
  const invitationOrganizationId =
    typeof query.invitationOrganizationId === `string` ? query.invitationOrganizationId : null
  const invitationOrganizationName =
    typeof query.invitationOrganizationName === `string` ? query.invitationOrganizationName : null
  const appFamily = getConfigForKey(state, `APP_FAMILY`)
  const profile = getProfile(state)

  return {
    hasCloudStatusTile: appFamily === 'saas',
    deletedDeploymentIds: getDeletedStackDeploymentIds(state),
    searchResults: getStackDeploymentsFromSearch(state, `deployments`),
    searchResultsRequest: searchStackDeploymentsRequest(state, `deployments`),
    profile,
    invitationType,
    invitationToken,
    invitationOrganizationId,
    invitationOrganizationName,
    showOrganization: shouldShowOrganization(profile),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  searchDeployments: (query) => dispatch(searchDeployments({ queryId: `deployments`, query })),
  fetchProfile: () => dispatch(fetchProfile()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PortalLandingPage)
