/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { loginUrl } from '@modules/auth/urls'
import { useConfig } from '@modules/cui/ConfigContext'
import { startRouteChange } from '@modules/utils/apm'

import { applyGoogleTrackingV4 } from '@/apps/userconsole/lib/googleTracking'
import { findRoute } from '@/lib/router'
import { isCurrentPath } from '@/lib/urls'
import { registerUrl } from '@/lib/urlBuilder'

import type React from 'react'
import type { RouteConfig } from 'react-router-config'
import type { RouteComponentProps } from 'react-router'

type Props = RouteComponentProps & {
  routes: RouteConfig[]
}

const AppRouterEvents: React.FunctionComponent<Props> = ({ routes, location }) => {
  const googleTrackingId = useConfig('GOOGLE_ANALYTICS_TRACKING_ID_V4')

  useEffect(() => {
    window.scrollTo(0, 0)
    document.querySelector<HTMLElement>(`[autofocus]`)?.focus()

    trackRouteChange({ location, routes })

    if (googleTrackingId && isGoogleTrackingV4EnabledForUrl()) {
      applyGoogleTrackingV4()
    }
  }, [routes, location, googleTrackingId])

  return null
}

function trackRouteChange({
  location,
  routes,
}: {
  location: RouteComponentProps['location']
  routes: RouteConfig[]
}) {
  const route = findRoute(routes, location.pathname)

  if (!route) {
    return
  }

  const { path, params } = route

  startRouteChange(path, params)
}

function isGoogleTrackingV4EnabledForUrl() {
  return isCurrentPath(loginUrl()) || isCurrentPath(registerUrl())
}

export default withRouter(AppRouterEvents)
