/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { StackDeployment } from '@modules/ui-types'

// eslint-disable-next-line import/no-restricted-paths
import { gte } from '@/lib/semver'
// eslint-disable-next-line import/no-restricted-paths
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'

const minimumDefaultSolutioViewVersion = '8.16.0'

export function hasDefaultSolutionViewFeature(editorStackVersion: string) {
  return gte(editorStackVersion, minimumDefaultSolutioViewVersion)
}

export function hasDeploymentSolutionViewFeature(deployment?: StackDeployment) {
  if (!deployment) {
    return false
  }

  const deployedVersion = getVersion({ deployment })
  return deployedVersion ? hasDefaultSolutionViewFeature(deployedVersion) : false
}
