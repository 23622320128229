/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import { find } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiText,
  EuiButtonEmpty,
  EuiButtonIcon,
} from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { DeploymentStatus } from '@modules/ui-types'
import { CuiTable } from '@modules/cui/Table'
import type { CuiTableColumn } from '@modules/cui/Table'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import { deploymentUrl } from '@/lib/urlBuilder'
import DeploymentActionsPopover from '@/components/StackDeploymentSearch/DeploymentsTable/DeploymentActionsPopover'
import DeploymentInfo from '@/components/StackDeploymentSearch/DeploymentsTable/DeploymentInfo'
import { getDeploymentStatus, isPending } from '@/lib/stackDeployments/status'
import { getDeploymentHealthProblems } from '@/lib/healthProblems'
import { sortDeploymentsByRamAndStatus } from '@/lib/stackDeployments/sorting'
import KibanaStatusPopover from '@/components/Portal/components/PortalDeployments/PortalLinks/KibanaStatusPopover'
import ExternalLink from '@/components/ExternalLink'
import { getDeploymentMemoryCapacity } from '@/lib/stackDeployments/selectors/instances'
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors/endpoints'
import { hasOngoingConfigurationChange } from '@/lib/stackDeployments/selectors/configurationChanges'
import {
  getFirstResourceType,
  getVersion,
  getRegionId,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { getDisplayName, getDisplayId } from '@/lib/stackDeployments/selectors/display'

import DeploymentRegion from '../Deployment/DeploymentRegion'

import type { WrappedComponentProps } from 'react-intl'

import './deploymentsTable.scss'

type Props = WrappedComponentProps & {
  deployments: DeploymentSearchResponse[]
  onDeploymentAction: () => void
  pageSize: number | null
}

type DeploymentWithPendingUpdate = {
  id?: string
  status?: DeploymentStatus
}

type State = {
  deploymentWithPendingUpdate?: DeploymentWithPendingUpdate
}

const messages = defineMessages({
  status: {
    id: `deployments-table.status-label`,
    defaultMessage: `Status`,
  },
  name: {
    id: `deployments-table.name-label`,
    defaultMessage: `Deployment name`,
  },
  version: {
    id: `deployments-table.version-label`,
    defaultMessage: `Version`,
  },
  configuration: {
    id: `deployments-table.configuration-label`,
    defaultMessage: `Configuration`,
  },
  stack: {
    id: `deployments-table.stack-label`,
    defaultMessage: `Stack`,
  },
  region: {
    id: `deployments-table.region-label`,
    defaultMessage: `Cloud region`,
  },
  totalStorage: {
    id: `deployments-table.storage-label`,
    defaultMessage: `Total storage`,
  },
  totalRam: {
    id: `deployments-table.ram-label`,
    defaultMessage: `Total RAM`,
  },
  totalCpu: {
    id: `deployments-table.cpu-label`,
    defaultMessage: `Total CPU`,
  },
  autoscaling: {
    id: `deployments-table.autoscaling-label`,
    defaultMessage: `Autoscaling`,
  },
  links: {
    id: `deployments-table.links-label`,
    defaultMessage: `Links`,
  },
  management: {
    id: 'deployments-table.manage-label',
    defaultMessage: 'Deployment management',
  },
})

class DeploymentsTable extends Component<Props, State> {
  state = this.getDefaultState()

  componentDidUpdate() {
    const { deploymentWithPendingUpdate } = this.state

    if (deploymentWithPendingUpdate) {
      this.resetPendingDeploymentState()
    }
  }

  render() {
    const { intl, deployments, pageSize } = this.props
    const { deploymentWithPendingUpdate } = this.state

    const { formatMessage } = intl
    const nameColumn = {
      label: formatMessage(messages.name),
      render: (deployment: DeploymentSearchResponse) => (
        <PrivacySensitiveContainer className='deployments-table-privacy-container'>
          {this.renderDeploymentName(deployment)}
        </PrivacySensitiveContainer>
      ),
      sortKey: [
        (deployment: DeploymentSearchResponse) => getDisplayName({ deployment }),
        (deployment: DeploymentSearchResponse) => getDisplayId({ deployment }),
      ],
      textOnly: false,
      width: '270px',
    }

    const columns: Array<CuiTableColumn<DeploymentSearchResponse>> = [
      nameColumn,

      {
        label: <FormattedMessage id='deployments-table.status' defaultMessage='Status' />,
        render: (deployment: DeploymentSearchResponse) => {
          const { id } = deployment

          return (
            <DeploymentInfo
              data-test-id='deployments-table-status'
              deployment={deployment}
              deploymentInfoItem='status'
              hasPendingUpdate={deploymentWithPendingUpdate?.id === id}
            />
          )
        },
        textOnly: false,
        width: '175px',
        sortKey: (deployment: DeploymentSearchResponse) => {
          const deploymentHealth = getDeploymentStatus({ deployment })
          return deploymentHealth.status
        },
      },

      {
        label: formatMessage(messages.version),
        sortKey: (deployment: DeploymentSearchResponse) => getVersion({ deployment }),
        render: (deployment: DeploymentSearchResponse) => {
          const version = getVersion({ deployment })

          if (version === null) {
            return null
          }

          return (
            <EuiText size='s' data-test-id='deployment-version'>
              {version}
            </EuiText>
          )
        },
        textOnly: false,
        width: `75px`,
      },

      {
        label: formatMessage(messages.totalRam),
        render: (deployment: DeploymentSearchResponse) => (
          <EuiText size='s'>
            <DeploymentInfo deployment={deployment} deploymentInfoItem='ram' />
          </EuiText>
        ),
        textOnly: false,
        width: `75px`,
        sortKey: (deployment: DeploymentSearchResponse) =>
          getDeploymentMemoryCapacity({ deployment }),
      },

      {
        label: formatMessage(messages.region),
        render: (deployment: DeploymentSearchResponse) => (
          <EuiText size='s'>
            <DeploymentRegion regionId={getRegionId({ deployment })!} />
          </EuiText>
        ),
        sortKey: [(deployment: DeploymentSearchResponse) => getRegionId({ deployment })],
        textOnly: false,
        width: '125px',
      },

      {
        label: formatMessage(messages.links),
        render: (deployment: DeploymentSearchResponse) => (
          <EuiFlexGroup
            alignItems='center'
            justifyContent='flexEnd'
            gutterSize='s'
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                href={deploymentUrl(deployment.id)}
                iconType='gear'
                className='deployments-table-management-link'
                aria-label={formatMessage(messages.management)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <DeploymentActionsPopover
                deployment={deployment}
                onDeploymentAction={() => this.onDeploymentAction(deployment)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
        textOnly: false,
        width: '50px',
        align: 'center',
      },
    ]

    return (
      <CuiTable<DeploymentSearchResponse>
        rows={
          deployments
            ? sortDeploymentsByRamAndStatus(deployments).slice(0, deployments.length)
            : undefined
        }
        getRowId={(deployment) => deployment.id}
        pageSize={pageSize || 30}
        getRowTestSubj={(deployment) => `${getFirstResourceType({ deployment })}-row`}
        columns={columns}
        className='stack-deployment-list-table'
      />
    )
  }

  renderDeploymentName(deployment) {
    const { id } = deployment
    const displayName = getDisplayName({ deployment })
    const displayId = getDisplayId({ deployment })
    const kibanaLinkUrl = getDeploymentResourceEndpoint({
      deployment,
      sliderInstanceType: 'kibana',
    })

    if (!kibanaLinkUrl) {
      return <KibanaStatusPopover deploymentId={deployment.id} displayName={displayName} />
    }

    return (
      <Fragment>
        {this.renderDeploymentLink(id, displayName, kibanaLinkUrl)}
        {displayId !== displayName && <EuiFormHelpText>{displayId}</EuiFormHelpText>}
      </Fragment>
    )
  }

  renderDeploymentLink(id: string, displayName: string, kibanaLinkUrl?: string) {
    if (!kibanaLinkUrl) {
      return <EuiButtonEmpty href={deploymentUrl(id)}>{displayName}</EuiButtonEmpty>
    }

    return <ExternalLink href={kibanaLinkUrl}>{displayName}</ExternalLink>
  }

  onDeploymentAction(deployment) {
    const { onDeploymentAction } = this.props

    this.setState(
      {
        deploymentWithPendingUpdate: {
          id: deployment.id,
          status: getDeploymentStatus({ deployment }),
        },
      },
      onDeploymentAction,
    )
  }

  resetPendingDeploymentState() {
    const { deployments } = this.props
    const { deploymentWithPendingUpdate } = this.state
    const { id } = deploymentWithPendingUpdate

    if (deploymentWithPendingUpdate) {
      const deployment = find(deployments, { id })

      if (deployment) {
        const [problems] = getDeploymentHealthProblems({ deployment, eolStatus: null })

        if (isPending(problems, hasOngoingConfigurationChange({ deployment }))) {
          this.setState({ ...this.getDefaultState() })
        }
      }
    }
  }

  getDefaultState() {
    return {
      deploymentWithPendingUpdate: {
        id: undefined,
        status: undefined,
      },
    }
  }
}

export default injectIntl(DeploymentsTable)
