/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { KeyStatus } from './types'

const messages = defineMessages({
  addName: {
    id: 'api-key-flyout.key-name',
    defaultMessage: 'Add name',
  },
  setExpirationDate: {
    id: 'api-key-flyout.expiration-date',
    defaultMessage: 'Set expiration',
  },
  assignRoles: {
    id: 'api-key-flyout.assign-roles',
    defaultMessage: 'Assign roles',
  },
  assignRolesHelpTextTitle: {
    id: 'api-key-flyout.assign-roles.help-text-title',
    defaultMessage: 'This API key is for Elastic Cloud APIs only',
  },
  assignRolesHelpText: {
    id: 'api-key-flyout.assign-roles-help-text',
    defaultMessage:
      'API keys created here grant access to Elastic Cloud APIs for managing the organization and deployments. If you’re looking to access Elasticsearch clusters instead, create an API key in {kibanaLink} or using the {elasticsearchLink}.',
  },
  kibanaText: {
    id: 'api-key-flyout.assign-roles-kibana-text-link',
    defaultMessage: 'Kibana',
  },
  elasticsearchText: {
    id: 'api-key-flyout.assign-roles-elasticsearch-text-link',
    defaultMessage: 'Elasticsearch API',
  },
  keyNameColumn: {
    id: `api-key.name`,
    defaultMessage: `Name`,
  },
  keyCreatedByColumn: {
    id: `api-key.created-by`,
    defaultMessage: `Created by`,
  },
  keyStatus: {
    id: `api-key.status`,
    defaultMessage: `Status`,
  },
  keyExpiresOnColumn: {
    id: `api-key.expires-on`,
    defaultMessage: `Expires`,
  },
  keyCreatedOnColumn: {
    id: `api-key.created-on`,
    defaultMessage: `Created`,
  },
  keyRevokeColumn: {
    id: `api-key.revoke`,
    defaultMessage: `Revoke`,
  },
  keyActionsColumn: {
    id: `api-key.actions`,
    defaultMessage: `Actions`,
  },
  apiKeyCancel: {
    id: `api-key.cancel`,
    defaultMessage: `Cancel`,
  },
  revokeModalTitle: {
    id: `revoke-key.modal-title`,
    defaultMessage: `Revoke API key?`,
  },
  revokeModalBody: {
    id: `revoke-key.modal-body`,
    defaultMessage: `Revoking it affects all of the associated applications.`,
  },
  revokeCancel: {
    id: `revoke-modal.cancel`,
    defaultMessage: `Cancel`,
  },
  revokeConfirm: {
    id: `revoke-modal.confirm`,
    defaultMessage: `Revoke API key`,
  },
  revokeSuccess: {
    id: 'revoke-modal.revoke-key-success',
    defaultMessage: '{keyName} successfully revoked!',
  },
  createApiKey: {
    id: `api-key.create`,
    defaultMessage: `Create API key`,
  },
  generateKeyAuthStep: {
    id: `generate-api-key-auth-step.title`,
    defaultMessage: `Re-authenticate`,
  },
  generateKeyAuthFooterNext: {
    id: `generate-api-auth-footer.next`,
    defaultMessage: `Authenticate`,
  },
  generateKeyClose: {
    id: `generate-api-key.close`,
    defaultMessage: `Close`,
  },
  downloadKey: {
    id: `generate-api-key.download`,
    defaultMessage: `Download API key`,
  },
  keyNameHelpText: {
    id: `generate-api-key.key-name-help-text`,
    defaultMessage: `Provide a unique name for your key.`,
  },
  keyNameError: {
    id: `generate-api-key.key-name-error`,
    defaultMessage: `This name is already in use. Enter another name.`,
  },
  keyNameEmptyError: {
    id: `generate-api-key.key-name-empty-error`,
    defaultMessage: `Key name cannot be empty`,
  },
  generatedKey: {
    id: `generated-key.label`,
    defaultMessage: `Key`,
  },
  generatedApiKeyWarning: {
    id: `generated-key.warning`,
    defaultMessage: `Here's your key. We won't show it again, so make sure to copy or to download it now.`,
  },
})

export default messages

export const statusMessages = defineMessages<KeyStatus>({
  expiresSoon: {
    id: `apikeys.status.expires-soon`,
    defaultMessage: `Expires soon`,
  },
  expired: {
    id: `apikeys.status.expired`,
    defaultMessage: `Expired`,
  },
  active: {
    id: `apikeys.status.active`,
    defaultMessage: `Active`,
  },
})
