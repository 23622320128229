/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiCode,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiLink,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import type { AnyUserProjectApplicationKey } from '@modules/project-user-api/types'
import CopyButton from '@modules/cui/CopyButton'
import genericMessages from '@modules/project-lib/genericMessages'
import type { AnyProject } from '@modules/project-api/types'

const APPLICATIONS: AnyUserProjectApplicationKey[] = ['elasticsearch', 'apm']

type Props = {
  project: AnyProject
}

const ProjectEndpoints: React.FunctionComponent<Props> = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { formatMessage } = useIntl()

  const { name, endpoints } = project
  const linkLabel = formatMessage(genericMessages.view)

  return (
    <Fragment>
      <EuiLink
        onClick={() => setIsOpen(true)}
        aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
          action: linkLabel,
          name: formatMessage(genericMessages.connectionDetails),
        })}
      >
        {linkLabel}
      </EuiLink>

      {isOpen && (
        <EuiFlyout size='m' maxWidth='32rem' onClose={() => setIsOpen(false)}>
          <EuiFlyoutHeader hasBorder={true}>
            <EuiTitle size='m'>
              <h2>
                <FormattedMessage
                  id='project.endpoints.header'
                  defaultMessage='Connect to {name}'
                  values={{ name }}
                />
              </h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <Fragment>
              <EuiTitle size='xxs'>
                <strong>
                  <FormattedMessage
                    id='project.alias.title'
                    defaultMessage='Custom endpoint alias'
                  />
                </strong>
              </EuiTitle>
              <EuiSpacer size='s' />
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {project.alias || <FormattedMessage {...genericMessages.none} />}
              </div>
              <EuiSpacer size='l' />
            </Fragment>

            {APPLICATIONS.map((application) => {
              const endpoint = endpoints?.[application]

              if (!endpoint) {
                return null
              }

              return (
                <Fragment key={application}>
                  <EuiTitle size='xxs'>
                    <strong>
                      <FormattedMessage
                        id='project.endpoint.title'
                        defaultMessage='{name} endpoint'
                        values={{ name: <FormattedMessage {...genericMessages[application]} /> }}
                      />
                    </strong>
                  </EuiTitle>
                  <EuiSpacer size='s' />
                  <EuiFlexGroup gutterSize='m'>
                    <EuiFlexItem
                      grow={false}
                      style={{
                        overflow: 'hidden',
                      }}
                    >
                      <EuiCode
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {endpoint}
                      </EuiCode>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <CopyButton asIconLink={true} value={endpoint} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiSpacer size='l' />
                </Fragment>
              )
            })}
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiButton color='primary' onClick={() => setIsOpen(false)}>
              <FormattedMessage {...genericMessages.close} />
            </EuiButton>
          </EuiFlyoutFooter>
        </EuiFlyout>
      )}
    </Fragment>
  )
}

export default ProjectEndpoints
