/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiTourStep, EuiText, EuiButtonEmpty } from '@elastic/eui'

import { useLocalStorage } from '@modules/utils/hooks/useLocalStorage'
import LocalStorageKey from '@modules/utils/localStorageKeys'

import type { ReactElement } from 'react'

interface Props {
  children: ReactElement
}

export const AutoopsTour = ({ children }: Props) => {
  const [isTourSeen, setIsTourSeen] = useLocalStorage(LocalStorageKey.autoOpsTourStorageKey, false)
  const markTourAsSeen = () => setIsTourSeen(true)

  return (
    <EuiTourStep
      content={
        <EuiText size='m' color='subdued' data-test-subj='infra-AutoopsTour-text'>
          <FormattedMessage
            id='autoops-tour-text'
            defaultMessage='AutoOps is enabled on this deployment 🎉'
          />
        </EuiText>
      }
      isStepOpen={!isTourSeen}
      maxWidth={350}
      onFinish={markTourAsSeen}
      step={1}
      stepsTotal={1}
      title='NEW'
      anchorPosition='downCenter'
      footerAction={
        <EuiButtonEmpty
          data-test-subj='infra-AutoopsTour-dismiss'
          size='s'
          color='text'
          onClick={markTourAsSeen}
        >
          Dismiss
        </EuiButtonEmpty>
      }
    >
      {children}
    </EuiTourStep>
  )
}
