/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect } from 'react'

import { useConfig } from '@modules/cui/ConfigContext'
import type { MiddlewareComponent } from '@modules/app/types'

import makeScheduler, { SchedulerContext } from '../scheduler'

import type { Scheduler } from '../scheduler'

let _scheduler: Scheduler

/**
 * For testing.
 */
export function _setScheduler(scheduler: Scheduler) {
  _scheduler = scheduler
}

const SchedulerMiddleware: MiddlewareComponent = ({ children }) => {
  const interval = useConfig('POLLING_INTERVAL')

  useEffect(() => {
    _scheduler = makeScheduler({ interval })

    _scheduler.start()

    return () => {
      _scheduler.stop()
    }
  }, [interval])

  if (!_scheduler) {
    return null // this will only last a render cycle
  }

  return <SchedulerContext.Provider value={_scheduler}>{children}</SchedulerContext.Provider>
}

SchedulerMiddleware.middleware = {
  displayName: 'SchedulerMiddleware',
  dependencies: [],
}

export default SchedulerMiddleware
