/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  DeploymentClaimRequest,
  DeploymentClaimResponse,
  DeploymentCreateRequest,
  DeploymentCreateResponse,
  DeploymentGetResponse,
} from '@modules/cloud-api/v1/types'
import {
  createDeploymentUrl,
  getDeploymentUrl,
  postInstantDeploymentUrl,
} from '@modules/cloud-api/v1/urls'
import { fetchAsJson } from '@modules/query/helpers'

export const getDeployment = async (deploymentId: string) =>
  fetchAsJson<DeploymentGetResponse>(getDeploymentUrl({ deploymentId }), {
    method: 'get',
  })
export const postDeploymentCreation = async (payload?: Partial<DeploymentCreateRequest>) =>
  fetchAsJson<DeploymentCreateResponse>(createDeploymentUrl(), {
    method: 'post',
    body: JSON.stringify(payload),
  })

export const postInstantDeployment = async (payload?: Partial<DeploymentClaimRequest>) =>
  fetchAsJson<DeploymentClaimResponse>(postInstantDeploymentUrl(), {
    method: 'post',
    body: JSON.stringify(payload),
  })
