/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import BoostWindow from '@modules/project-components/ProjectPage/BoostWindow'
import type { ElasticsearchProject } from '@modules/project-user-api/v1/types'
import {
  usePatchElasticsearchProjectMutation,
  usePatchSecurityProjectMutation,
} from '@modules/project-user-lib/hooks/update'

export type Props = {
  project: ElasticsearchProject
}

const UserBoostWindow: React.FunctionComponent<Props> = ({ project }) => {
  const patchElasticsearchProjectMutation = usePatchElasticsearchProjectMutation()
  const patchSecurityProjectMutation = usePatchSecurityProjectMutation()

  const isLoading =
    patchElasticsearchProjectMutation.isLoading || patchSecurityProjectMutation.isLoading

  return (
    <BoostWindow
      project={project}
      error={patchElasticsearchProjectMutation.error || patchSecurityProjectMutation.error}
      onChange={(boostWindow: number, onSuccess: () => void) => {
        patchElasticsearchProjectMutation.mutate(
          {
            id: project.id,
            body: { search_lake: { boost_window: boostWindow } },
          },
          { onSuccess },
        )
      }}
      isLoading={isLoading}
    />
  )
}

export default UserBoostWindow
