/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import type { EuiTextProps } from '@elastic/eui'
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiText } from '@elastic/eui'

import type { ExternalHyperlink } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import usePermissions from '@modules/permissions-lib/hooks'

import { hostAllocatorUrl } from '../../../lib/urlBuilder'
import { DeploymentAllocatorExternalLinksList } from '../../StackDeployments/ResourceExternalLinks/ExternalLinksList'

import type { FunctionComponent } from 'react'

interface Props {
  allocatorId: string
  externalLinks?: ExternalHyperlink[]
  fetchDeploymentAllocatorsRequest: AsyncRequestState
  healthy?: boolean
  regionId: string
}

type ColorKey = 'healthy' | 'inProgress' | 'onError' | 'unhealthy'

type TextColor = EuiTextProps['color']

type ColorMap = {
  [key in ColorKey]: TextColor
}

const colors: ColorMap = {
  healthy: 'default',
  inProgress: 'subdued',
  onError: 'warning',
  unhealthy: 'danger',
}

const messages = defineMessages({
  healthy: {
    id: `allocator-link.healthy`,
    defaultMessage: `Allocator {ip}`,
  },
  inProgress: {
    id: `allocator-link.healthy`,
    defaultMessage: `Allocator {ip}`,
  },
  onError: {
    id: `allocator-link.healthy`,
    defaultMessage: `Allocator {ip}`,
  },
  unhealthy: {
    id: `allocator-link.unhealthy`,
    defaultMessage: `Unhealthy allocator {ip}`,
  },
})

const AllocatorLink: FunctionComponent<Props> = ({
  allocatorId,
  healthy,
  externalLinks,
  fetchDeploymentAllocatorsRequest,
  regionId,
}) => {
  const health = getAllocatorHealth()

  const { hasPermissions } = usePermissions([{ type: 'allocator', action: 'search' }])

  return (
    <EuiFlexGroup gutterSize='xs' responsive={false} wrap={true}>
      <EuiFlexItem grow={false}>
        <EuiText size='xs' color={health.color} className='node-visualization-allocator-link'>
          <FormattedMessage
            id={health.message.id}
            defaultMessage={health.message.defaultMessage}
            values={{
              ip: hasPermissions ? (
                <Link data-test-id='allocator-link' to={hostAllocatorUrl(regionId, allocatorId)}>
                  {allocatorId}
                </Link>
              ) : (
                allocatorId
              ),
            }}
          />
        </EuiText>
      </EuiFlexItem>

      {hasPermissions && (
        <EuiFlexItem grow={false}>
          <EuiText size='xs'>
            <ExternalLinksList
              externalLinks={externalLinks}
              fetchDeploymentAllocatorsRequest={fetchDeploymentAllocatorsRequest}
            />
          </EuiText>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )

  function getAllocatorHealth() {
    if (fetchDeploymentAllocatorsRequest.inProgress || healthy === undefined) {
      return {
        message: messages.inProgress,
        color: colors.inProgress,
      }
    }

    if (fetchDeploymentAllocatorsRequest.error) {
      return {
        message: messages.onError,
        color: colors.onError,
      }
    }

    if (!healthy) {
      return {
        message: messages.unhealthy,
        color: colors.unhealthy,
      }
    }

    return {
      message: messages.healthy,
      color: colors.healthy,
    }
  }
}

const ExternalLinksList: FunctionComponent<{
  externalLinks?: ExternalHyperlink[]
  fetchDeploymentAllocatorsRequest: AsyncRequestState
}> = ({ externalLinks, fetchDeploymentAllocatorsRequest }) => {
  if (fetchDeploymentAllocatorsRequest.inProgress) {
    return <EuiLoadingSpinner size='s' />
  }

  if (externalLinks === undefined || externalLinks.length === 0) {
    return null
  }

  return <DeploymentAllocatorExternalLinksList links={externalLinks} />
}

export default AllocatorLink
