/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormRow, EuiSuperSelect } from '@elastic/eui'

import type { Region } from '@modules/cloud-api/v1/types'
import { getRegionDisplayName } from '@modules/utils/region'

import type { ReactElement } from 'react'

interface Props {
  region?: string
  regions: Region[]
  onSelectRegion: (regionId: string) => void
  isLoading: boolean
}

class RegionControl extends PureComponent<Props> {
  render(): ReactElement {
    const { isLoading, region, onSelectRegion } = this.props

    return (
      <EuiFormRow
        fullWidth={true}
        label={<FormattedMessage id='provider-region-control.region' defaultMessage='Region' />}
      >
        <EuiSuperSelect
          id='region-control'
          fullWidth={true}
          options={this.getRegionOptions()}
          valueOfSelected={region || ''}
          onChange={onSelectRegion}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </EuiFormRow>
    )
  }

  getRegionOptions() {
    const { regions } = this.props

    return regions.map((region: Region) => ({
      inputDisplay: getRegionDisplayName({ region }),
      value: region.identifier,
    }))
  }
}

export default RegionControl
