/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import type { AsyncRequestState, EsProxyResponseConsole, StackDeployment } from '@modules/ui-types'

import { getStackDeployment } from '@/reducers/stackDeployments'
import { getDeploymentProxyResponse } from '@/reducers/deploymentProxy'
import { getDeploymentProxyRequestHistory, getDeploymentProxyRequest } from '@/reducers/apiConsole'
import { queryDeploymentProxyRequest } from '@/reducers/asyncRequests/registry'

import {
  clearAPIConsoleHistory,
  queryDeploymentProxyForConsole,
  setAPIConsoleRequest,
} from '../../actions/apiConsole'
import { clearDeploymentProxyResponse } from '../../actions/deploymentProxy'
import { withStackDeploymentRouteParams } from '../StackDeploymentEditor'

import APIConsole from './APIConsole'

import type { ReduxState } from '@/types/redux'
import type { WithStackDeploymentRouteParamsProps } from '../StackDeploymentEditor'
import type { ConsoleRequestState } from '../../reducers/apiConsole'

type StateProps = {
  deployment: StackDeployment | undefined
  consoleRequest?: ConsoleRequestState | null
  consoleRequestHistory: ConsoleRequestState[]
  consoleResponse: EsProxyResponseConsole | null
  queryDeploymentProxyRequest: AsyncRequestState
}

type DispatchProps = {
  clearAPIConsoleHistory: () => void
  clearDeploymentProxyResponse: (deploymentId: string) => void
  queryDeploymentProxyForConsole: (
    deployment: StackDeployment,
    consoleRequest: ConsoleRequestState,
  ) => void
  setAPIConsoleRequest: (request: ConsoleRequestState, deployment: StackDeployment) => void
}

type ConsumerProps = WithStackDeploymentRouteParamsProps

const mapStateToProps = (state: ReduxState, { stackDeploymentId }: ConsumerProps): StateProps => ({
  deployment: getStackDeployment(state, stackDeploymentId!),
  consoleRequest: getDeploymentProxyRequest(state, stackDeploymentId!),
  consoleRequestHistory: getDeploymentProxyRequestHistory(state),
  consoleResponse: getDeploymentProxyResponse(state, stackDeploymentId!),
  queryDeploymentProxyRequest: queryDeploymentProxyRequest(state, stackDeploymentId),
})

const mapDispatchToProps: DispatchProps = {
  clearAPIConsoleHistory,
  clearDeploymentProxyResponse,
  queryDeploymentProxyForConsole,
  setAPIConsoleRequest,
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTransaction(`Elasticsearch console`, `component`)(APIConsole)),
)
