/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type {
  DirectTrustRelationship,
  ElasticsearchClusterTrustSettings,
} from '@modules/cloud-api/v1/types'
import type { AnyTrustRelationship } from '@modules/ui-types'
import { getQueryString } from '@modules/cui/lib/querystring'

import {
  isAccountRelationship,
  isApiKeysRelationship,
  isDirectRelationship,
  isExternalRelationship,
} from '@/lib/stackDeployments/trustRelationships'
import { getConfigForKey } from '@/store'

import type { RelationshipType } from './types'

export const envTypes = [`direct`, `accounts`, `external`]

export function flattenTrustedEnvs(
  trustedEnvs: ElasticsearchClusterTrustSettings,
): AnyTrustRelationship[] {
  return envTypes.filter((type) => trustedEnvs[type]).flatMap((type) => trustedEnvs[type])
}

export const getTargetEnvironmentType = (
  trustRelationship: AnyTrustRelationship | null,
): RelationshipType | null => {
  const isEce = getConfigForKey(`APP_PLATFORM`) === `ece`

  // consult the provided trust relationship if we have it (i.e., during edit)
  if (trustRelationship) {
    if (isApiKeysRelationship(trustRelationship)) {
      const tr = trustRelationship as DirectTrustRelationship

      if (tr.type === `proxy`) {
        return `ece`
      }

      if (tr.type === `generic` && tr.trust_all === false) {
        return `self-managed`
      }
    }

    if (isAccountRelationship(trustRelationship)) {
      return isEce ? 'ece' : 'ess'
    }

    if (isExternalRelationship(trustRelationship)) {
      return 'ece'
    }

    if (isDirectRelationship(trustRelationship)) {
      switch (trustRelationship.type) {
        case 'ESS':
          return 'ess'
        case 'ECE':
          return 'ece'
        default:
          return 'self-managed'
      }
    }
  }

  // otherwise ask the querystring if it has a context (i.e., during create)
  const typeFromQuerystring = getQueryString({ key: 'type' })

  if (['ess', 'ece', 'self-managed'].includes(typeFromQuerystring)) {
    return typeFromQuerystring as RelationshipType
  }

  return null
}
