/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type {
  SaasUserProfile,
  SaasUserRules,
  Organization,
  OrganizationRequest,
} from '@modules/cloud-api/v1/types'
import type { AccountUI, AsyncRequestState } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import {
  updateAccountPasswordRequest,
  updateAccountEmailRequest,
  updateAccountDetailsRequest,
  getAccountUI,
  getAccountDetails,
  fetchAccountDetailsRequest,
  confirmEmailChangeRequest,
} from '@/apps/userconsole/reducers/account'
import { getOrganization } from '@/reducers/organizations'
import { fetchOrganizationRequest } from '@/reducers/asyncRequests/registry'

import {
  clearEmailChangeConfirmation,
  fetchAccountDetailsIfNeeded,
  resetUpdateAccountDetailsRequest,
  resetUpdateAccountEmailRequest,
  resetUpdateAccountPasswordRequest,
  updateAccountDetails,
  updateAccountEmail,
  updateAccountPassword,
} from '../../actions/account'
import { fetchOrganizationIfNeeded, updateOrganization } from '../../../../actions/organizations'
import { isFeatureActivated } from '../../../../selectors'
import withLoading from '../../../../lib/apm/withLoading'

import AccountDetails from './AccountDetails'

type StateProps = {
  ui: AccountUI
  fetchAccountDetailsRequest: AsyncRequestState
  updateAccountDetailsRequest: AsyncRequestState
  updateAccountEmailRequest: AsyncRequestState
  updateAccountPasswordRequest: AsyncRequestState
  confirmEmailChangeRequest: AsyncRequestState
  accountDetails: (SaasUserProfile & SaasUserRules) | undefined
  isPortalFeatureEnabled: boolean
  isLaunchDarklyActivated: boolean
  fetchOrganizationRequest: AsyncRequestState
  organization?: Organization
}

type DispatchProps = {
  fetchAccountDetailsIfNeeded: () => void
  resetUpdateAccountEmailRequest: () => void
  resetUpdateAccountPasswordRequest: () => void
  resetUpdateAccountDetailsRequest: () => void
  clearEmailChangeConfirmation: () => void
  updateAccountPassword: (args: { oldPassword: string; newPassword: string }) => void
  updateAccountEmail: (args: { password: string; email: string }) => void
  updateAccountDetails: (accountDetails: SaasUserProfile & SaasUserRules) => void
  fetchOrganizationIfNeeded: (args: { organizationId: string }) => void
  updateOrganization: (args: { organizationId: string; organization: OrganizationRequest }) => void
}

interface ConsumerProps {}

export type Props = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (state): StateProps => {
  const accountDetails = getAccountDetails(state)
  const organizationId = accountDetails?.organization_id || ''

  return {
    accountDetails,
    ui: getAccountUI(state),
    fetchAccountDetailsRequest: fetchAccountDetailsRequest(state),
    updateAccountEmailRequest: updateAccountEmailRequest(state),
    updateAccountPasswordRequest: updateAccountPasswordRequest(state),
    updateAccountDetailsRequest: updateAccountDetailsRequest(state),
    confirmEmailChangeRequest: confirmEmailChangeRequest(state),
    isPortalFeatureEnabled: isFeatureActivated(state, Feature.cloudPortalEnabled),
    isLaunchDarklyActivated: isFeatureActivated(state, Feature.launchdarklyId),
    fetchOrganizationRequest: fetchOrganizationRequest(state, organizationId),
    organization: getOrganization(state, organizationId),
  }
}

const mapDispatchToProps: DispatchProps = {
  fetchAccountDetailsIfNeeded,
  updateAccountEmail,
  updateAccountPassword,
  updateAccountDetails,
  updateOrganization,
  resetUpdateAccountEmailRequest,
  resetUpdateAccountPasswordRequest,
  resetUpdateAccountDetailsRequest,
  clearEmailChangeConfirmation,
  fetchOrganizationIfNeeded,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(
  withLoading<Props>(AccountDetails, (props) => {
    const {
      fetchAccountDetailsIfNeeded: fetch,
      fetchAccountDetailsRequest: request,
      accountDetails,
    } = props

    return {
      transaction: `Account details`,
      fetch,
      request,
      result: accountDetails,
      blockWhileLoading: false,
    }
  }),
)
