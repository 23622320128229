/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useField } from 'formik'

import {
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  useEuiTheme,
} from '@elastic/eui'

import type { RoleMapping } from '@modules/cloud-api/v1/types'
import { CuiFormField } from '@modules/cui/forms'
import { isRoleAssignmentsEmpty } from '@modules/role-assignments-lib'

import RoleBadges from '@/components/Organization/RoleBadges'

import { messages } from './messages'
import RoleAssignmentFlyout from './RoleAssignmentFlyout'
import Row from './Row'

const RoleMappingDetails = ({
  roleMapping,
  organizationId,
}: {
  roleMapping?: RoleMapping
  organizationId: string
}) => {
  const { formatMessage } = useIntl()
  const { euiTheme } = useEuiTheme()
  const [enabledField, _enabledMeta, enabledHelper] = useField('enabled')
  const [roleAssignmentField, roleAssignmentMeta, roleAssignmentHelper] =
    useField('role_assignments')
  const [isRoleAssignmentFlyoutOpen, setIsRoleAssignmentFlyoutOpen] = useState(false)

  const name = roleMapping?.name || ''
  const isEmptyRoleAssignments = isRoleAssignmentsEmpty(roleAssignmentField.value)
  return (
    <EuiPanel hasShadow={false} hasBorder={true}>
      <EuiText>
        <h2>{formatMessage(messages.roleMappingDetailsTitle)}</h2>
      </EuiText>
      <Row
        label={formatMessage(messages.enableMapping)}
        explanation={formatMessage(messages.enableMappingExplanation)}
        valueElement={
          <EuiSwitch
            label=''
            style={{ marginTop: '1rem' }}
            checked={enabledField.value}
            onChange={(e) => enabledHelper.setValue(e.target.checked)}
          />
        }
      />
      <Row
        label={formatMessage(messages.mappingName)}
        explanation={formatMessage(messages.mappingNameExplanation)}
        valueElement={
          <CuiFormField
            name='name'
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
            label={formatMessage(messages.mappingNameLabel)}
            style={{ maxWidth: '400px' }}
            component={EuiFieldText}
            fullWidth={true}
            aria-label={name}
          />
        }
      />
      <Row
        label={formatMessage(messages.roles)}
        explanation={formatMessage(messages.rolesExplanation)}
        valueElement={
          <div>
            <EuiFormRow
              error={roleAssignmentMeta.error}
              isInvalid={!!roleAssignmentMeta.error}
              style={{ paddingTop: '1rem' }}
            >
              <EuiPanel
                hasShadow={false}
                hasBorder={true}
                style={{ backgroundColor: euiTheme.colors.lightestShade, maxWidth: '400px' }}
              >
                <RoleBadges roleAssignments={roleAssignmentField.value} />
              </EuiPanel>
            </EuiFormRow>
            <EuiSpacer />
            <EuiButton onClick={() => setIsRoleAssignmentFlyoutOpen(true)}>
              <EuiText size='s'>
                {formatMessage(
                  isEmptyRoleAssignments ? messages.addRolesButton : messages.editRolesButton,
                )}
              </EuiText>
            </EuiButton>
          </div>
        }
      />
      {isRoleAssignmentFlyoutOpen && (
        <RoleAssignmentFlyout
          organizationId={organizationId}
          roleAssignments={roleAssignmentField.value}
          setRoleAssignments={roleAssignmentHelper.setValue}
          onClose={() => setIsRoleAssignmentFlyoutOpen(false)}
        />
      )}
    </EuiPanel>
  )
}

export default RoleMappingDetails
