/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import { debounce } from 'lodash'

import type { RoutableBreadcrumb } from '@modules/ui-types'
import { withSmallErrorBoundary } from '@modules/cui/Boundary'
import { getRootByApp } from '@modules/cui/Breadcrumbs/rootCrumbBuilder'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'

import { ScreenReaderAnnouncements } from './ScreenReaderAnnouncements'
import ContentResetPortal from './ContentResetPortal'
import BreadcrumbsImpl from './BreadcrumbsImpl'
import BreadcrumbsContext from './BreadcrumbsContext'

import type { FC } from 'react'

type Props = {
  breadcrumbs?: RoutableBreadcrumb[]
}

const extractBreadcrumbsText = (breadRef: HTMLDivElement | null | undefined) =>
  (breadRef?.innerText ?? '').slice(-10)

const Breadcrumbs: FC<Props> = ({ breadcrumbs }) => {
  /**
   * Since breadcrumb items contain ReactNode instead
   * of string values, it was necessary to obtain the current value
   * through _breadRef?.innerText
   * **/

  const { breadcrumbsRef, announcementsRef } = useContext(BreadcrumbsContext)
  const appName = useConfig('APP_NAME')
  const appType = useConfig('CLOUD_UI_APP')
  const cloudPortalEnabled = useConfig(Feature.cloudPortalEnabled)

  const [breadKey, setBreadKey] = useState(extractBreadcrumbsText(breadcrumbsRef?.current))

  const allRoutableBreadcrumbs = useMemo(() => {
    const rootByApp = getRootByApp({
      linkRoot: (breadcrumbs?.length ?? 0) > 0,
      appName,
      appType,
      cloudPortalEnabled,
    })

    return breadcrumbs ? [...rootByApp, ...breadcrumbs] : rootByApp
  }, [breadcrumbs, appName, appType, cloudPortalEnabled])

  const debouncedBreadKeyCalculation = useRef(
    debounce((_breadRef) => {
      /** "breadKey" is needed for memoization of the ScreenReaderAnnouncements
       * component so that rendering occurs only in the case of actual changes
       * in breadcrumb values. **/
      setBreadKey(extractBreadcrumbsText(_breadRef))
    }, 300),
  )

  useEffect(() => {
    const fn = debouncedBreadKeyCalculation.current
    fn(breadcrumbsRef?.current)

    return function cancelDebounce() {
      fn.cancel()
    }
  }, [allRoutableBreadcrumbs, breadcrumbsRef])

  return (
    <React.Fragment>
      {announcementsRef?.current && (
        <ContentResetPortal container={announcementsRef.current}>
          <ScreenReaderAnnouncements breadcrumbs={allRoutableBreadcrumbs} prefix={breadKey} />
        </ContentResetPortal>
      )}
      {breadcrumbsRef?.current && (
        <ContentResetPortal container={breadcrumbsRef.current}>
          <BreadcrumbsImpl breadcrumbs={allRoutableBreadcrumbs} />
        </ContentResetPortal>
      )}
    </React.Fragment>
  )
}

export default withSmallErrorBoundary(Breadcrumbs)
