/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { createContext, useContext } from 'react'

import type { IAnalyticsClient } from '@elastic/ebt/client/src/analytics_client'

import type { EbtAnalytics } from './EbtAnalytics'

type EbtContextType = {
  analytics: IAnalyticsClient
  analyticsInstance: EbtAnalytics
}

const EbtContext = createContext<Partial<EbtContextType>>({
  analytics: undefined,
  analyticsInstance: undefined,
})

EbtContext.displayName = 'EbtContext'

export default EbtContext

export const useEbtContext = () => useContext(EbtContext)
