/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { RemoteResourceRef } from '@modules/cloud-api/v1/types'

import DangerButton from '../DangerButton'

import type { IntlShape } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = {
  intl: IntlShape
  remote: RemoteResourceRef
  onEdit: (remote: RemoteResourceRef) => void
  onRemove: (remote: RemoteResourceRef) => void
}

const messages = defineMessages({
  editRemote: {
    id: `remote-deployment-actions.edit-remote`,
    defaultMessage: `Edit remote deployment`,
  },
  removeRemote: {
    id: `remote-deployment-actions.remove-remote`,
    defaultMessage: `Remove remote deployment`,
  },
  removeRemoteConfirmTitle: {
    id: `remote-deployment-actions.remove-remote-confirm-title`,
    defaultMessage: `Remove remote deployment?`,
  },
  removeRemoteConfirmBody: {
    id: `remote-deployment-actions.remove-remote-confirm-body`,
    defaultMessage: `After the association ends, this deployment won't be able to search across {alias} anymore.`,
  },
  removeRemoteConfirmButtonText: {
    id: `remote-deployment-actions.remove-remote-confirm-button-text`,
    defaultMessage: `Remove`,
  },
})

const RemoteDeploymentActions: FunctionComponent<Props> = ({
  intl: { formatMessage },
  remote,
  onEdit,
  onRemove,
}) => (
  <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        iconType='pencil'
        aria-label={formatMessage(messages.editRemote)}
        onClick={() => onEdit(remote)}
      />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <DangerButton
        buttonType={EuiButtonIcon}
        buttonProps={{ color: `danger` }}
        isEmpty={true}
        iconType='cross'
        aria-label={formatMessage(messages.removeRemote)}
        modal={{
          title: formatMessage(messages.removeRemoteConfirmTitle),
          body: (
            <FormattedMessage
              {...messages.removeRemoteConfirmBody}
              values={{
                alias: <strong>{remote.alias}</strong>,
              }}
            />
          ),
          confirmButtonText: formatMessage(messages.removeRemoteConfirmButtonText),
        }}
        onConfirm={() => onRemove(remote)}
      />
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default injectIntl(RemoteDeploymentActions)
