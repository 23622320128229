/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AsyncRequestState, StackDeployment } from '@modules/ui-types'
import type {
  DeploymentTemplateInfoV2,
  DeploymentUpdateRequest,
  SnapshotConfiguration,
} from '@modules/cloud-api/v1/types'

import {
  fetchSnapshotDeploymentConfiguration,
  deleteSnapshotDeploymentDependency,
} from '@/actions/snapshots'
import {
  getSnapshotDeploymentConfigurationRequest,
  deleteSnapshotDeploymentDependencyRequest,
  updateStackDeploymentRequest,
} from '@/reducers/asyncRequests/registry'
import { getSnapshotDeploymentConfiguration } from '@/reducers/snapshotDeploymentConfiguration'
import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { updateDeployment } from '@/actions/stackDeployments'
import { getRegionId, getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { getDeploymentTemplate } from '@/reducers/deploymentTemplates'

import SnapshotDeploymentConfiguration from './SnapshotDeploymentConfiguration'

import type { ThunkDispatch } from '@/types/redux'

export type StateProps = {
  fetchConfigRequest: AsyncRequestState
  deleteDepRequest: AsyncRequestState
  snapshotDeploymentConfiguration: SnapshotConfiguration | null
  deploymentTemplate?: DeploymentTemplateInfoV2
  updateDeploymentRequest: AsyncRequestState
}

export type DispatchProps = {
  fetchConfig: () => Promise<any>
  deleteDep: (resourceId: string) => Promise<any>
  updateDeployment: (args: {
    deploymentId: string
    deployment: DeploymentUpdateRequest
    redirect?: boolean
    dryRun?: boolean
  }) => Promise<unknown>
}

export type ConsumerProps = {
  deployment: StackDeployment
}

export type AllProps = StateProps & DispatchProps & ConsumerProps

const mapStateToProps = (state, { deployment }: ConsumerProps): StateProps => {
  const deploymentTemplateId = getDeploymentTemplateId({ deployment })
  const version = getVersion({ deployment })
  const regionId = getRegionId({ deployment })

  return {
    fetchConfigRequest: getSnapshotDeploymentConfigurationRequest(state, deployment.id),
    deleteDepRequest: deleteSnapshotDeploymentDependencyRequest(state, deployment.id),
    snapshotDeploymentConfiguration: getSnapshotDeploymentConfiguration(state, deployment.id),
    deploymentTemplate: getDeploymentTemplate(state, regionId, deploymentTemplateId!, version),
    updateDeploymentRequest: updateStackDeploymentRequest(state, deployment.id),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch, { deployment }: ConsumerProps) => ({
  fetchConfig: () => dispatch(fetchSnapshotDeploymentConfiguration({ deployment })),
  deleteDep: (resourceId: string) =>
    dispatch(deleteSnapshotDeploymentDependency({ deployment, resourceId })),
  updateDeployment: (args) => dispatch(updateDeployment(args)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SnapshotDeploymentConfiguration)
