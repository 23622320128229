/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer, EuiText } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'

import { securityUrl } from '@/lib/urlBuilder'

import TrustRelationshipNavigationSteps from './TrustRelationshipNavigationSteps'

type Props = {
  deployment: StackDeployment
  showNav?: boolean
}

const ProceedToAddingApiKeys: FunctionComponent<Props> = function ({ deployment, showNav }) {
  return (
    <Fragment>
      {showNav && (
        <Fragment>
          <TrustRelationshipNavigationSteps currentStep={2} />
          <EuiSpacer />
        </Fragment>
      )}

      <EuiCallOut
        title={
          <FormattedMessage
            id='deploymentTrustManagement.account.apikeys-title'
            defaultMessage='Good news!'
          />
        }
      >
        <EuiText>
          <FormattedMessage
            id='deploymentTrustManagement.account.apikeys-description'
            defaultMessage='No specific configuration is required. You just have to add the API keys of the deployments you want to connect remotely to from the {security} page of your deployment.'
            values={{
              security: (
                <Link to={securityUrl(deployment.id)}>
                  <FormattedMessage
                    id='deploymentTrustManagement.account.apikeys-link'
                    defaultMessage='Security'
                  />
                </Link>
              ),
            }}
          />
        </EuiText>
      </EuiCallOut>
    </Fragment>
  )
}

export default ProceedToAddingApiKeys
