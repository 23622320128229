/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import usePermissions from '@modules/permissions-lib/hooks'
import type {
  NonEmptyPermissionsArray,
  PermissionsCheckResult,
} from '@modules/permissions-lib/types'

import type { FunctionComponent, ReactNode } from 'react'

type ChildrenFunction = (permissionsCheckResult: PermissionsCheckResult) => ReactNode

const PermissionsGate: FunctionComponent<{
  permissions: NonEmptyPermissionsArray
  children: ReactNode | ChildrenFunction
}> = ({ children, permissions }) => {
  const permissionsCheckResult = usePermissions(permissions, 'every')

  if (typeof children === 'function') {
    return children(permissionsCheckResult)
  }

  if (!permissionsCheckResult.hasPermissions) {
    return null
  }

  return children
}

export default PermissionsGate
