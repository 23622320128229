/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { User } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState } from '@modules/ui-types'
import type { DeepPartial } from '@modules/ts-essentials'

import { getCurrentUser } from '@/reducers/currentUser'
import { updateCurrentUserRequest } from '@/reducers/asyncRequests/registry'

import { updateCurrentUser } from '../../../actions/currentUser'

import UpdateProfile from './UpdateProfile'

import type { ThunkDispatch, ReduxState } from '@/types/redux'

interface StateProps {
  currentUser: User | null
  onUpdateProfileRequest: AsyncRequestState
  onChangePasswordRequest: AsyncRequestState
}

interface DispatchProps {
  onUpdateProfile: (user: DeepPartial<User>) => Promise<unknown>
  onChangePassword: (user: DeepPartial<User>) => Promise<unknown>
}

const mapStateToProps: (state: ReduxState) => StateProps = (state) => {
  const currentUser = getCurrentUser(state)

  return {
    currentUser,
    onUpdateProfileRequest: updateCurrentUserRequest(state, 'update_profile'),
    onChangePasswordRequest: updateCurrentUserRequest(state, 'change_password'),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  onUpdateProfile: (user: DeepPartial<User>) => dispatch(updateCurrentUser(user, 'update_profile')),
  onChangePassword: (user: DeepPartial<User>) =>
    dispatch(updateCurrentUser(user, 'change_password')),
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateProfile)
