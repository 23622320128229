/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { AnyUserProject } from '@modules/project-user-api/types'
import { getDeleteProjectMutationHook } from '@modules/project-user-lib/hooks/delete'
import history from '@modules/utils/history'
import { projectsUrl } from '@modules/project-user-lib/urls'
import DeleteProjectButton from '@modules/project-components/ProjectPage/DeleteProjectButton'

export type Props = {
  project: AnyUserProject
  onComplete: () => void
}

const UserDeleteProjectButton: React.FunctionComponent<Props> = ({ project, onComplete }) => {
  const deleteProjectMutation = getDeleteProjectMutationHook(project.type)()

  return (
    <DeleteProjectButton
      project={project}
      onDelete={performDelete}
      onComplete={onComplete}
      isLoading={deleteProjectMutation.isLoading}
    />
  )

  function performDelete() {
    deleteProjectMutation.mutate(
      { id: project.id },
      {
        onSuccess: () => {
          history.push(projectsUrl())
        },
      },
    )
  }
}

export default UserDeleteProjectButton
