/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { EuiText, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import type {
  DeploymentTemplateInfoV2,
  Region as AvailableRegion,
} from '@modules/cloud-api/v1/types'
import type { PlatformId, Region } from '@modules/ui-types'
import { getRegionDisplayName } from '@modules/utils/region'
import { getPlatform, getPlatformInfoById } from '@modules/utils/platform'

const SettingsSummary = ({
  region,
  availableRegions,
  deploymentTemplate,
  availablePlatforms,
  version,
}: {
  region: Region
  version: string | null
  availableRegions: AvailableRegion[]
  availablePlatforms: PlatformId[]
  deploymentTemplate?: DeploymentTemplateInfoV2
}) => (
  <EuiFlexGroup gutterSize='m'>
    <EuiFlexItem grow={false}>
      <ProviderIcon region={region} availablePlatforms={availablePlatforms} />
    </EuiFlexItem>
    <EuiFlexItem>
      <p>
        <ProviderComponent region={region} />{' '}
        <RegionComponent region={region} availableRegions={availableRegions} />
      </p>
      <p>
        <HardwareProfile deploymentTemplate={deploymentTemplate} />{' '}
        <EuiText component='span' size='s' color='subdued'>
          {version}
        </EuiText>
      </p>
    </EuiFlexItem>
  </EuiFlexGroup>
)

const ProviderComponent = ({ region }) => {
  const platform = getPlatform(region.id)

  return (
    <EuiText component='span' size='m' css={{ textTransform: 'uppercase' }}>
      {platform}
    </EuiText>
  )
}

const RegionComponent = ({ region, availableRegions }) => {
  if (!availableRegions) {
    return null
  }

  const displayRegion = availableRegions.find(
    (availableRegion) => availableRegion.identifier === region.id,
  )

  if (!displayRegion) {
    return null
  }

  const displayName = getRegionDisplayName({ region: displayRegion, hideFlagEmoji: true })
  const regex = /\(([^)]+)\)/
  const geoRegionMatch = displayName.match(regex)
  const cloudRegionMatch = displayName.replace(regex, '').trim()

  if (geoRegionMatch && geoRegionMatch[1] && cloudRegionMatch) {
    return (
      <EuiText component='span' size='s'>
        {geoRegionMatch[1]} ({cloudRegionMatch.toLowerCase().replace(/ /g, '-')})
      </EuiText>
    )
  }

  return (
    <EuiText component='span' size='s'>
      {displayName}
    </EuiText>
  )
}

const HardwareProfile = ({ deploymentTemplate }) => {
  if (!deploymentTemplate) {
    return null
  }

  return (
    <EuiText component='span' size='s' color='subdued'>
      {deploymentTemplate.name},
    </EuiText>
  )
}

const ProviderIcon = ({ region, availablePlatforms }) => {
  const platform = getPlatform(region.id)

  const selectedPlatformInfo = availablePlatforms
    .map((key) => getPlatformInfoById(key))
    .find((provider) => provider.id === platform)

  return <EuiIcon type={selectedPlatformInfo.iconType} size='xxl' />
}

export default SettingsSummary
