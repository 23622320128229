/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ThunkDispatch, ReduxState } from '@/types/redux'
import { getUpdateDeploymentDryRunResult } from '@/reducers/stackDeploymentDryRuns'
import { updateStackDeploymentDryRunRequest } from '@/reducers/asyncRequests/registry'

import { updateDeployment, resetUpdateDeploymentDryRun } from '../../actions/stackDeployments'

import StackDeploymentUpdateDryRunWarnings from './StackDeploymentUpdateDryRunWarnings'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deploymentId }: ConsumerProps): StateProps => ({
  updateDeploymentDryRunRequest: updateStackDeploymentDryRunRequest(state, deploymentId),
  updateDeploymentDryRunResult: getUpdateDeploymentDryRunResult(state, deploymentId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { deploymentId, deployment },
): DispatchProps => ({
  updateDeploymentDryRun: () =>
    dispatch(updateDeployment({ deploymentId, deployment, dryRun: true })),
  resetUpdateDeploymentDryRun: () => dispatch(resetUpdateDeploymentDryRun(deploymentId)),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(StackDeploymentUpdateDryRunWarnings)
