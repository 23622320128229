/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { uniq } from 'lodash'

import type { SliderType, SliderInstanceType, WellKnownSliderInstanceType } from '@modules/ui-types'

type AppSubdomainDef = {
  unaliased: string | null
  aliased: string
}

const allKnownSliderTypes: SliderType[] = [
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `data` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `data_content` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `data_hot` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `data_warm` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `data_cold` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `data_frozen` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `ingest` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `master` },
  { sliderInstanceType: `elasticsearch`, sliderNodeType: `ml` },
  { sliderInstanceType: `kibana` },
  { sliderInstanceType: `apm` },
  { sliderInstanceType: `integrations_server` },
  { sliderInstanceType: `appsearch`, sliderNodeType: `appserver` },
  { sliderInstanceType: `appsearch`, sliderNodeType: `worker` },
  { sliderInstanceType: `enterprise_search`, sliderNodeType: `appserver` },
  { sliderInstanceType: `enterprise_search`, sliderNodeType: `connector` },
  { sliderInstanceType: `enterprise_search`, sliderNodeType: `worker` },
]

const allKnownSliderInstanceTypes: SliderInstanceType[] = uniq(
  allKnownSliderTypes
    .filter(Boolean)
    .map((sliderType) => sliderType.sliderInstanceType) as SliderInstanceType[],
)

export const alwaysSupportedSliderInstanceTypes: SliderInstanceType[] = [
  `elasticsearch`,
  `kibana`,
  `apm`,
  `integrations_server`,
  `appsearch`,
  `enterprise_search`,
]

type appSubdomains =
  | 'elasticsearch'
  | 'kibana'
  | 'apm'
  | 'fleet'
  | 'appsearch'
  | 'enterprise_search'
  | 'integrations_server'
  | 'symbols'
  | 'profiling'

export const appSubdomainDefinitions: Record<appSubdomains, AppSubdomainDef> = {
  elasticsearch: { unaliased: null, aliased: 'es' },
  kibana: { unaliased: null, aliased: 'kb' },
  apm: { unaliased: 'apm', aliased: 'apm' },
  fleet: { unaliased: 'fleet', aliased: 'fleet' },
  appsearch: { unaliased: 'ent-search', aliased: 'ent' },
  enterprise_search: { unaliased: 'ent-search', aliased: 'ent' },
  integrations_server: { unaliased: 'apm', aliased: 'apm' },
  symbols: { unaliased: 'symbols', aliased: 'symbols' },
  profiling: { unaliased: 'profiling', aliased: 'profiling' },
}

export function getAllKnownSliderTypes(): SliderType[] {
  return allKnownSliderTypes
}

export function getAllKnownSliderInstanceTypes(): SliderInstanceType[] {
  return allKnownSliderInstanceTypes
}

export function isWellKnownSliderInstanceType(
  sliderInstanceType?: SliderInstanceType,
): sliderInstanceType is WellKnownSliderInstanceType {
  return sliderInstanceType
    ? alwaysSupportedSliderInstanceTypes.includes(sliderInstanceType)
    : false
}
