/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { pick } from 'lodash'

import { getSaasCurrentUserUrl } from '@modules/cloud-api/v1/urls'
import type { UserProfileFromApi } from '@modules/ui-types'
import type {
  SaasUserResponse,
  SaasUserRules,
  SaasUserSubscriptionInfo,
} from '@modules/cloud-api/v1/types'
import { setApmUserContext } from '@modules/utils/apm'

import { FETCH_DRIFT_JWT, FETCH_PROFILE } from '@/apps/userconsole/constants/actions'
import asyncRequest from '@/actions/asyncRequests'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import { fetchProfileRequest } from '@/apps/userconsole/reducers/registry'

import type { ReduxState, Action } from '@/types/redux'

interface SaveProfileAction extends Action<typeof FETCH_PROFILE> {
  payload: SaasUserResponse
}

export function fetchDriftJwt() {
  const url = 'api/v1/saas/inappchat/token'
  return asyncRequest({
    type: FETCH_DRIFT_JWT,
    method: `GET`,
    url,
  })
}

export function fetchProfile() {
  const url = getSaasCurrentUserUrl()

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: FETCH_PROFILE,
        url,
      }),
    ).then(({ payload }) => setApmUserContext(String(payload?.user?.user_id), payload?.user?.email))
}

function shouldFetch(state: ReduxState) {
  const profileRequest = fetchProfileRequest(state)

  if (profileRequest.inProgress) {
    return false
  }

  const profile = getProfile(state)
  return profile === null || profile.pending_level
}

export function fetchProfileIfNeeded(params?: { force: boolean }) {
  return (dispatch, getState) => {
    if ((!params || !params.force) && !shouldFetch(getState())) {
      return Promise.resolve()
    }

    return dispatch(fetchProfile())
  }
}

function extractSubscriptionData(profile: UserProfileFromApi): SaasUserSubscriptionInfo {
  return pick(profile, [
    'aws_subscribed',
    'gcp_subscribed',
    'azure_subscribed',
    'accepted_tos',
    'is_paying',
    'aws_customer_id',
    'integrated_marketplace_account',
    'invoicable',
    'credit_limit',
    'capacity_limit',
    'level',
    'pending_level',
    'pending_level_valid_from',
    'is_trial',
    'contract_type',
    'pending_marketplace_subscription',
    'conversion_timestamp',
    'marketplace_attributes',
  ])
}

function extractRulesData(profile: UserProfileFromApi): SaasUserRules {
  return pick(profile, [
    'wants_informational_emails',
    'allow_bundles',
    'allow_provisioning_without_payment_established',
    'allow_plugins',
    'is_profile_editable',
  ])
}

export function _injectProfile(
  profile: UserProfileFromApi | undefined,
): SaveProfileAction | boolean {
  if (profile) {
    return {
      type: FETCH_PROFILE,
      meta: {},
      payload: {
        user: profile,
        subscription: extractSubscriptionData(profile),
        rules: extractRulesData(profile),
        trials: profile.trials ?? [],
      },
    }
  }

  return false
}
