/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'
import type { CloudAppName } from '@modules/ui-types'

import { getEsPlanFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { getEsPlan } from '@/lib/stackDeployments/selectors/stackDeployment'
import { getAutoscalingStatus } from '@/lib/stackDeployments/selectors/autoscaling'
import { getConfigForKey } from '@/store'

import { DisabledDataTierWarning } from '../DisabledDataTierWarning'

import explainChanges from './explainChanges'

import type { AllProps } from './types'

class DeploymentConfigurationChangeExplain extends Component<AllProps> {
  componentDidMount() {
    this.props.fetchInstanceConfigurations()
  }

  render() {
    const { deployment, deploymentUnderEdit, instanceConfigurations, pruneOrphans } = this.props
    const isAdminConsole = getConfigForKey<CloudAppName>(`APP_NAME`) === 'adminconsole'

    if (!instanceConfigurations) {
      return null
    }

    const explanations = explainChanges({
      newDeployment: deployment,
      oldDeployment: deploymentUnderEdit,
      instanceConfigurations,
      pruneOrphans,
    })

    if (explanations.length === 0) {
      return null
    }

    const isRollingChangesWarnVisible = explanations.every(
      ({ didConfigChanged }) => didConfigChanged === false,
    )
    const oldEsPlan = getEsPlanFromGet({
      deployment: deploymentUnderEdit,
    })
    const newEsPlan = getEsPlan({
      deployment,
    })

    const dataLossPossibleWarning =
      newEsPlan?.transient?.plan_configuration?.override_failsafe === true &&
      newEsPlan?.transient?.plan_configuration?.skip_data_migration === true
    return (
      <Fragment>
        {isRollingChangesWarnVisible && isAdminConsole && (
          <Fragment>
            <EuiCallOut>
              <FormattedMessage
                id='no.changes.detected'
                defaultMessage="Heads-up that while we have not detected any changes that will be made to the deployment, this plan change might still roll the instances. If you're not sure, run a dry-run plan first, check the output to see what strategy that plan will use."
              />
            </EuiCallOut>
            <EuiSpacer size='s' />
          </Fragment>
        )}
        <DisabledDataTierWarning
          current={oldEsPlan}
          next={newEsPlan}
          pruneOrphans={pruneOrphans}
          instanceConfigurations={instanceConfigurations}
          autoscalingStatus={getAutoscalingStatus({ deployment })}
        />
        <div>
          {explanations.map((explanation) => (
            <EuiFlexGroup key={explanation.id} gutterSize='s'>
              <EuiFlexItem grow={false}>
                <div
                  data-test-id={explanation['data-test-id']}
                  data-test-explanation-id={explanation.id}
                  data-test-params={explanation.testParams}
                >
                  {explanation.message}
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          ))}
        </div>
        {dataLossPossibleWarning && (
          <Fragment>
            <EuiSpacer size='s' />
            <EuiCallOut
              color='danger'
              title={
                <FormattedMessage
                  id='deployment-configuration-change.data-loss-alert-title'
                  defaultMessage='Data loss is likely!'
                />
              }
            >
              <FormattedMessage
                id='deployment-configuration-change.data-loss-alert-description'
                defaultMessage='This configuration change will not gracefully move shards and will override all failsafe checks. It is very likely to result in data loss on the deployment. Are you sure you want to continue?'
              />
            </EuiCallOut>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default withErrorBoundary(DeploymentConfigurationChangeExplain)
