/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiHeaderLink } from '@elastic/eui'

import chatIconLight from './chat_icon_light.svg'

export function DriftChatHeaderMenuItem({ triggerChat }: { triggerChat: () => void }) {
  return (
    <EuiHeaderLink
      size='s'
      iconType={chatIconLight}
      data-test-id='drift-header-chat-button'
      onClick={() => {
        triggerChat()
      }}
    >
      <FormattedMessage id='drift-header-menu-item.chat' defaultMessage='Live Chat' />
    </EuiHeaderLink>
  )
}
