/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../../messages'

import { PeriodTab } from './types'

import type { UsageRate } from './types'

export const deploymentUsageRates: UsageRate[] = [
  {
    dimension: 'capacity',
    description: <FormattedMessage {...messages.capacity} />,
    dataTestId: 'usage-card.deployment.capacity',
  },
  {
    dimension: 'data_in',
    description: <FormattedMessage {...messages.dataTransferIn} />,
    dataTestId: 'usage-card.deployment.data_in',
  },
  {
    dimension: 'data_out',
    description: <FormattedMessage {...messages.dataTransferOut} />,
    dataTestId: 'usage-card.deployment.data_out',
  },
  {
    dimension: 'data_internode',
    description: <FormattedMessage {...messages.dataTransferInternode} />,
    dataTestId: 'usage-card.deployment.data_internode',
  },
  {
    dimension: 'storage_api',
    description: <FormattedMessage {...messages.snapshotApis} />,
    dataTestId: 'usage-card.deployment.storage_api',
  },
  {
    dimension: 'storage_bytes',
    description: <FormattedMessage {...messages.snapshotStorage} />,
    dataTestId: 'usage-card.deployment.storage_bytes',
  },
  {
    dimension: 'synthetics_lightweight',
    description: <FormattedMessage {...messages.synthetics_lightweight} />,
    dataTestId: 'usage-card.deployment.synthetics_lightweight',
  },
  {
    dimension: 'synthetics_browser',
    description: <FormattedMessage {...messages.synthetics_browser} />,
    dataTestId: 'usage-card.deployment.synthetics_browser',
  },
]
export const PeriodOptions = [
  {
    id: PeriodTab.Current,
    label: <FormattedMessage {...messages.currentMonth} />,
  },
  {
    id: PeriodTab.Previous,
    label: <FormattedMessage {...messages.previousMonth} />,
  },
]
