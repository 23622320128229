/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'

import type {
  ApmPlan,
  DeploymentCreateRequest,
  DeploymentGetResponse,
  DeploymentUpdateRequest,
  ElasticsearchClusterPlan,
  ElasticsearchClusterSettings,
  ElasticsearchPayload,
  KibanaClusterPlan,
} from '@modules/cloud-api/v1/types'
import type { AnyPlan, AnyTopologyElement, Scripting, SliderInstanceType } from '@modules/ui-types'
import type {
  PayloadForSliderInstanceType,
  ResourceInfoForSliderInstanceType,
} from '@modules/ui-types/sliders'

import { getScriptingFromPlan } from '../../deployments/plan'

export function getSliderResources<T extends SliderInstanceType>({
  deployment,
  sliderInstanceType,
}: {
  deployment: Pick<DeploymentGetResponse, 'resources'>
  sliderInstanceType?: T
}) {
  // not quite sure why it needs this cast, but it doesn't "just work" without it
  return sliderInstanceType
    ? (deployment.resources?.[sliderInstanceType] as
        | Array<ResourceInfoForSliderInstanceType<T>>
        | undefined)
    : undefined
}

export function getSliderPayloads<T extends SliderInstanceType>({
  deployment,
  sliderInstanceType,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  sliderInstanceType: T
}) {
  // not quite sure why it needs this cast, but it doesn't "just work" without it
  return deployment.resources?.[sliderInstanceType] as
    | Array<PayloadForSliderInstanceType<T>>
    | undefined
}

export function getFirstSliderCluster<T extends SliderInstanceType>({
  deployment,
  sliderInstanceType,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  sliderInstanceType: T
}): PayloadForSliderInstanceType<T> | null {
  const resource = getSliderPayloads({ deployment, sliderInstanceType })?.[0]

  if (resource === undefined) {
    return null
  }

  return resource
}

export function getFirstEsCluster({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): ElasticsearchPayload | null {
  return getFirstSliderCluster({ deployment, sliderInstanceType: `elasticsearch` })
}

export function getSliderPlan({
  deployment,
  sliderInstanceType,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  sliderInstanceType?: SliderInstanceType
}): AnyPlan | null {
  const cluster = sliderInstanceType
    ? getFirstSliderCluster({ deployment, sliderInstanceType })
    : null

  if (cluster === null) {
    return null
  }

  return cluster.plan
}

export function getEsPlan({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): ElasticsearchClusterPlan | null {
  return (
    (getSliderPlan({
      deployment,
      sliderInstanceType: `elasticsearch`,
    }) as ElasticsearchClusterPlan) || null
  )
}

export function getKibPlan({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): KibanaClusterPlan | null {
  return (getSliderPlan({ deployment, sliderInstanceType: `kibana` }) as KibanaClusterPlan) || null
}

export function getApmPlan({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): ApmPlan | null {
  return (getSliderPlan({ deployment, sliderInstanceType: `apm` }) as ApmPlan) || null
}

export function getDeploymentSettings({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): ElasticsearchClusterSettings | null {
  const esCluster = getFirstEsCluster({ deployment })

  if (esCluster === null) {
    return null
  }

  return esCluster.settings || null
}

export function getGettingStartedType({
  deployment,
}: {
  deployment: DeploymentGetResponse
}): SliderInstanceType {
  const enterpriseSearch = deployment.resources.enterprise_search
  const appSearch = deployment.resources.appsearch
  const kibana = deployment.resources.kibana

  // If a deployment contains enterprise_search, we show them an enterprise_search gif
  if (!isEmpty(enterpriseSearch)) {
    return `enterprise_search`
  }

  // If a deployment contains appSearch, we show them an appSearch gif
  if (!isEmpty(appSearch)) {
    return `appsearch`
  }

  // Otherwise, show Kibana
  if (!isEmpty(kibana)) {
    return `kibana`
  }

  // For all other cases (ECE), we'll show the ES experience
  return `elasticsearch`
}

export function getSliderUserSettings({
  sliderInstanceType,
  plan,
  nodeConfiguration,
}: {
  sliderInstanceType?: SliderInstanceType
  plan: AnyPlan
  nodeConfiguration: AnyTopologyElement
}): string | undefined {
  // right now we're handling appsearch as if it doesn't have nodes for
  // the purposes of settings -- this will change to
  // doesSliderInstanceTypeHaveNodeTypes(sliderInstanceType) when we amend
  // the UI to handle nodes separately for appsearch
  const hasNodes = sliderInstanceType === `elasticsearch`

  if (hasNodes) {
    // use node-level configuration object
    return (nodeConfiguration[sliderInstanceType] || {}).user_settings_yaml
  }

  // use plan-level configuration object
  return sliderInstanceType ? (plan[sliderInstanceType] || {}).user_settings_yaml : undefined
}

export function getPlugins({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): string[] {
  const plan = getEsPlan({ deployment })

  if (!plan) {
    return []
  }

  const planLevelBuiltInPlugins = plan.elasticsearch.enabled_built_in_plugins

  if (planLevelBuiltInPlugins?.length) {
    return planLevelBuiltInPlugins
  }

  const firstTopologyElementWithBuiltInPlugins = plan.cluster_topology.find(
    (topologyElement) => topologyElement.elasticsearch?.enabled_built_in_plugins?.length,
  )

  if (firstTopologyElementWithBuiltInPlugins) {
    return firstTopologyElementWithBuiltInPlugins.elasticsearch?.enabled_built_in_plugins ?? []
  }

  return []
}

export function getScripting({
  deployment,
}: {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
}): Scripting {
  // Not expected null here
  const plan = getEsPlan({ deployment }) as ElasticsearchClusterPlan
  return getScriptingFromPlan(plan)
}

export function getUserSettingsOverridesAsString({
  plan,
  sliderInstanceType,
}: {
  plan: AnyPlan
  sliderInstanceType: SliderInstanceType
}): string | null {
  if (plan[sliderInstanceType].user_settings_override_json) {
    // we have some overrides json
    return JSON.stringify(plan[sliderInstanceType].user_settings_override_json)
  }

  if (plan[sliderInstanceType].user_settings_override_yaml) {
    // we have some overrides yaml, we never have both though
    return plan[sliderInstanceType].user_settings_override_yaml
  }

  return null
}
