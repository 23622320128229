/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'

import DeploymentArchitectureSummary from './DeploymentArchitectureSummary'
import DeploymentArchitectureViz from './DeploymentArchitectureViz'

import type { FunctionComponent } from 'react'
import type { DeploymentArchitectureProps as Props } from './types'

import './deploymentArchitecture.scss'

const DeploymentArchitecture: FunctionComponent<Props> = ({
  version,
  regionId,
  instanceConfigurations,
  nodeConfigurations,
  autoscalingEnabled,
  priceViewSelected,
}) => (
  <EuiFlexGroup
    className='deployment-architecture-summary'
    data-test-id='deployment-architecture-summary'
  >
    <EuiFlexItem grow={2}>
      <PrivacySensitiveContainer
        id='deployment-summary-items'
        data-test-id='deployment-summary-items'
      >
        <DeploymentArchitectureSummary
          version={version}
          instanceConfigurations={instanceConfigurations}
          nodeConfigurations={nodeConfigurations}
          regionId={regionId}
          autoscalingEnabled={autoscalingEnabled}
          priceViewSelected={priceViewSelected}
        />

        <EuiSpacer size='m' />
      </PrivacySensitiveContainer>
    </EuiFlexItem>

    <EuiFlexItem className='deploymentArchitectureViz'>
      <EuiTitle size='s'>
        <b>
          <FormattedMessage
            id='create-deployment-configure.architecture-viz-title'
            defaultMessage='Architecture'
          />
        </b>
      </EuiTitle>

      <EuiSpacer size='m' />

      <DeploymentArchitectureViz
        instanceConfigurations={instanceConfigurations}
        nodeConfigurations={nodeConfigurations}
        version={version}
      />
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default DeploymentArchitecture
