/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'query-string'

import { loginUrl } from '@modules/auth/urls'

export function fullPageReload() {
  const { origin, pathname, search } = window.location
  const query = parse(search)
  const fromURI = query.fromURI as string

  /* A full page reload is useful when logging in/out, as we might have
   * previously loaded data that has become inconsistent with the new
   * credentials, leading to an uncertain UI state.
   */
  if (pathname === loginUrl() && fromURI) {
    /* pathname /login?fromURI=<fromURI> triggers a logout() event because
     * if users land on '/login' path and are coming from Okta there's a fromURI parameter
     * which informs the app to do so - https://github.com/elastic/cloud/issues/64582.
     * Hence in this case we redirect the user to the value of fromURI - https://github.com/elastic/cloud/issues/77272
     */
    const redirectTo =
      fromURI.startsWith('http://') || fromURI.startsWith('https://')
        ? fromURI
        : `${origin}${fromURI}`
    window.location.replace(redirectTo)
  } else {
    window.location.reload()
  }
}
