/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { range } from 'lodash'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

import type { EuiSelectOption } from '@elastic/eui'
import {
  EuiButton,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiPopover,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import usePermissions from '@modules/permissions-lib/hooks'
import { useProfile } from '@modules/profile-lib/hooks'
import type { AnyElasticsearchProject } from '@modules/project-api/types'
import genericMessages from '@modules/project-lib/genericMessages'
import type { ProjectApiError } from '@modules/ui-types/projects'

// eslint-disable-next-line import/no-restricted-paths
import DocLink from '@/components/DocLink'
// eslint-disable-next-line import/no-restricted-paths
import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import ProjectApiErrorCallout from '../ProjectApiErrorCallout'

export type Props = {
  project: AnyElasticsearchProject
  onChange: (searchPower: number, onSuccess: () => void) => void
  isLoading: boolean
  error: ProjectApiError | null
}

const DEFAULT_VALUE = 7
const MIN_VALUE = 1
const MAX_VALUE = 30
const PRESET_VALUES = [3, 7]
const VALID_VALUES = range(MIN_VALUE, MAX_VALUE + 1)

const messages = defineMessages({
  custom: {
    id: 'project.searchBoost.custom',
    defaultMessage: 'Enter custom value',
  },
  days: {
    id: 'project.searchBoost.days',
    defaultMessage: 'days',
  },
  dropdownLabel: {
    id: 'project.searchBoost.dropdownLabel',
    defaultMessage: 'Select search boost window',
  },
  textboxLabel: {
    id: 'project.searchBoost.textboxLabel',
    defaultMessage: 'Enter custom value from 1 to 30',
  },
})

const BoostWindow: React.FunctionComponent<Props> = ({ project, onChange, isLoading, error }) => {
  const currentBoostWindow = project.search_lake?.boost_window ?? DEFAULT_VALUE

  const inputRef = useRef<HTMLInputElement>(null)
  const htmlId = useGeneratedHtmlId()
  const { formatMessage } = useIntl()
  const searchBoostWindowLabel = formatMessage(genericMessages.searchBoostWindow)

  const isTrial = useProfile()?.is_trial

  const [isTrialLimitationsPopoverOpen, setIsTrialLimitationsPopoverOpen] = useState(true)
  const showTrialLimitationsPopover = () => setIsTrialLimitationsPopoverOpen(true)
  const hideTrialLimitationsPopover = () => setIsTrialLimitationsPopoverOpen(false)

  const isTrialLimitationsError = Boolean(
    typeof error !== 'string' &&
      error?.errors.find((e) => e.code === 'projects.patch_project.unprocessable_entity'),
  )

  useEffect(() => {
    if (error && isTrialLimitationsError) {
      showTrialLimitationsPopover()
    }
  }, [error, isTrialLimitationsError])

  useEffect(() => {
    if (isLoading) {
      hideTrialLimitationsPopover()
    }
  }, [isLoading])

  const { hasPermissions: hasUpdatePermission } = usePermissions([
    { type: `project-${project.type}`, action: 'update', id: project.id },
  ])

  const [isEditing, setIsEditing] = useState(false)
  const [presetBoostWindow, setPresetBoostWindow] = useState<number>(
    PRESET_VALUES.includes(currentBoostWindow) ? currentBoostWindow : 0,
  )
  const [customBoostWindow, setCustomBoostWindow] = useState<number>(
    PRESET_VALUES.includes(currentBoostWindow) ? 1 : currentBoostWindow,
  )

  const proposedBoostWindow = presetBoostWindow || customBoostWindow

  return (
    <EuiPanel data-test-subj='editBoostWindow' paddingSize='l' hasBorder={true}>
      <EuiFlexGroup gutterSize='xl' alignItems='stretch'>
        <EuiFlexItem grow={1}>
          <div style={{ marginRight: '2rem' }}>{renderDescription()}</div>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiFlexGroup direction='column' justifyContent='spaceBetween'>
            {renderDisplay()}
            {renderControl()}
            {renderSaveButtonAndError()}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )

  function renderDescription() {
    return (
      <EuiFlexGroup alignItems='baseline'>
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
          <EuiPanel paddingSize='m' hasBorder={true}>
            <EuiIcon type='inspect' color='primary' size='xl' />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiTitle size='xs'>
            <h3>{searchBoostWindowLabel}</h3>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiText size='s' color='subdued'>
            <p>
              <FormattedMessage
                id='project.searchBoostWindow.description.1'
                defaultMessage='The <strong>Search Boost Window</strong> determines how much data can benefit from faster search.'
                values={{ strong: (content) => <strong>{content}</strong> }}
              />
            </p>
            {isEditing && (
              <Fragment>
                <p>
                  <FormattedMessage
                    id='project.searchBoostWindow.description.2'
                    defaultMessage='When ingested, a certain amount of data is loaded into a cache that makes it super fast to query.'
                  />
                </p>
                <p>
                  <FormattedMessage
                    id='project.searchBoostWindow.description.3'
                    defaultMessage='The system dynamically adjusts the cache allocated to your project based on how much data you ingest during the period defined by your <strong>Search Boost Window</strong>.'
                    values={{ strong: (content) => <strong>{content}</strong> }}
                  />
                </p>
                {!isTrial && (
                  <DocLink link='manageProject' showExternalLinkIcon={true}>
                    <FormattedMessage {...genericMessages.learnMore} />
                  </DocLink>
                )}
              </Fragment>
            )}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  function renderDisplay() {
    const linkLabel = formatMessage(isEditing ? genericMessages.close : genericMessages.edit)

    return (
      <EuiFlexItem grow={false}>
        <EuiSpacer size='xs' />

        <EuiFlexGroup
          responsive={false}
          gutterSize='m'
          justifyContent='flexEnd'
          alignItems='baseline'
          style={{ whiteSpace: 'nowrap' }}
        >
          <EuiFlexItem grow={false}>
            <EuiTextColor color='subdued'>
              <FormattedMessage {...genericMessages.current} />:
            </EuiTextColor>
          </EuiFlexItem>
          <EuiFlexItem grow={false} data-test-subj='currentValue'>
            <strong>
              <FormattedMessage {...genericMessages.nDays} values={{ n: currentBoostWindow }} />
            </strong>
          </EuiFlexItem>
          {hasUpdatePermission && (
            <EuiFlexItem grow={false}>
              <EuiLink
                onClick={toggle}
                aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
                  action: linkLabel,
                  name: searchBoostWindowLabel,
                })}
              >
                {linkLabel}
              </EuiLink>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function renderControl() {
    if (!isEditing) {
      return null
    }

    const options: EuiSelectOption[] = [
      { value: 3, text: formatMessage(genericMessages.nDays, { n: 3 }) },
      { value: 7, text: formatMessage(genericMessages.nDays, { n: 7 }) },
      { value: 0, text: formatMessage(messages.custom) },
    ]

    return (
      <EuiFlexItem grow={true}>
        <EuiFlexGroup gutterSize='l' alignItems='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={<FormattedMessage {...genericMessages.searchBoostWindow} />}>
              <EuiSelect
                id={htmlId}
                aria-label={formatMessage(messages.dropdownLabel)}
                options={options}
                value={presetBoostWindow}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10)
                  setPresetBoostWindow(value)

                  // focus on the custom textbox after the resulting redraw
                  if (value === 0) {
                    setTimeout(() => {
                      inputRef.current?.focus()
                    })
                  }
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {!presetBoostWindow && (
              <EuiFormRow hasEmptyLabelSpace={true}>
                <EuiFlexGroup alignItems='baseline' gutterSize='m'>
                  <EuiFlexItem grow={false}>
                    <EuiFieldNumber
                      inputRef={inputRef}
                      aria-label={formatMessage(messages.textboxLabel)}
                      style={{ width: `7rem` }}
                      min={MIN_VALUE}
                      max={MAX_VALUE}
                      step={1}
                      value={customBoostWindow}
                      onChange={(e) => setCustomBoostWindow(parseInt(e.target.value, 10))}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <FormattedMessage {...messages.days} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function renderSaveButtonAndError() {
    if (!isTrial && !isEditing) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction='column' gutterSize='m' justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction='column' gutterSize='s' alignItems='flexEnd'>
              {!isTrialLimitationsError && <ProjectApiErrorCallout error={error} />}
              {isEditing && renderSaveButton()}
            </EuiFlexGroup>
          </EuiFlexItem>
          {isTrial && renderTrialMessage()}
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function renderSaveButton() {
    const saveButton = (
      <EuiFlexItem grow={false}>
        <EuiButton
          size='s'
          color='primary'
          fill={true}
          isDisabled={
            !VALID_VALUES.includes(proposedBoostWindow) ||
            proposedBoostWindow === currentBoostWindow
          }
          isLoading={isLoading}
          minWidth='6rem'
          onClick={() => {
            onChange(proposedBoostWindow, toggle)
          }}
        >
          <FormattedMessage {...genericMessages.save} />
        </EuiButton>
      </EuiFlexItem>
    )

    if (isTrialLimitationsError) {
      return (
        <EuiPopover
          button={saveButton}
          isOpen={isTrialLimitationsPopoverOpen}
          closePopover={hideTrialLimitationsPopover}
          anchorPosition='upCenter'
        >
          <EuiText css={css({ maxWidth: '290px' })}>
            <FormattedMessage
              id='project.boost-window-trial-limitation'
              defaultMessage='You cannot set a Search Boost Window of more than 7 days during trial. {subscribe} now to unlock more options.'
              values={{
                subscribe: (
                  <CreditCardModalButton onCloseModal={hideTrialLimitationsPopover}>
                    <FormattedMessage id='portal.subscribe' defaultMessage='Subscribe' />
                  </CreditCardModalButton>
                ),
              }}
            />
          </EuiText>
        </EuiPopover>
      )
    }

    return saveButton
  }

  function renderTrialMessage() {
    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup gutterSize='s' justifyContent='flexEnd' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiIcon color='warning' type='alert' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTextColor color='subdued'>
              <FormattedMessage
                id='project.searchBoostWindow.trialLimits'
                defaultMessage='Maximum window of 7 days during trial. <link>Learn more</link>'
                values={{
                  link: (content) => (
                    <DocLink link='manageProject' showExternalLinkIcon={true}>
                      {content}
                    </DocLink>
                  ),
                }}
              />
            </EuiTextColor>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function toggle() {
    setIsEditing(!isEditing)
    setPresetBoostWindow(PRESET_VALUES.includes(currentBoostWindow) ? currentBoostWindow : 0)
    setCustomBoostWindow(PRESET_VALUES.includes(currentBoostWindow) ? 1 : currentBoostWindow)
  }
}

export default BoostWindow
