/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { add, mergeWith, pick, uniq, upperFirst, sortBy } from 'lodash'
import { FormattedMessage } from 'react-intl'
import React from 'react'

import type {
  DeploymentDimension,
  DeploymentInstance,
  ProjectInstance,
  ChartItemType,
} from '@modules/billing-api/customTypes'
import type {
  components,
  Instance,
  Price,
  ProductLineItem,
  ProductLineItemQuantity,
  ProductLineItemTier,
} from '@modules/billing-api/v1/types'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import { ProductType, ServerlessExtras } from '../types'
import { getPriceHashmap } from '../components/PreviewCost/utils'

import type {
  ProductViewInstanceType,
  DeploymentInstanceWithAggregatedCosts,
  TotalDeploymentCostsPerDimension,
  TotalProjectCollapsedCosts,
  PLI,
} from './types'
import type { ReducerState } from '../components/Filters/filtersReducer'
import type { Solution } from '../types'

export const getDeploymentInstances = (instances: Instance[]): DeploymentInstance[] =>
  instances.filter(({ type }) => type === 'deployment') as DeploymentInstance[]

export const getProjectInstances = (instances: Instance[]): ProjectInstance[] =>
  instances.filter(({ type }) =>
    ['security', 'elasticsearch', 'observability'].includes(type),
  ) as ProjectInstance[]

export const getCostForDimensions = (
  dimensionTypes: DeploymentDimension[],
  product_line_items: ProductLineItem[],
): number =>
  product_line_items.reduce((currentCost, { total_ecu, type }) => {
    if (dimensionTypes.includes(type as DeploymentDimension)) {
      return currentCost + total_ecu
    }

    return currentCost
  }, 0)

export const getDeploymentInstancesWithAggregatedCosts = (
  instances: DeploymentInstance[] = [],
): DeploymentInstanceWithAggregatedCosts[] =>
  instances.map(({ id, name, product_line_items, total_ecu }) => ({
    id,
    name,
    capacity: getCostForDimensions(['capacity'], product_line_items),
    dataTransfer: getCostForDimensions(
      ['data_in', 'data_internode', 'data_out'],
      product_line_items,
    ),
    storage: getCostForDimensions(['storage_bytes', 'storage_api'], product_line_items),
    synthetics: getCostForDimensions(
      ['synthetics_lightweight', 'synthetics_browser'],
      product_line_items,
    ),
    total_ecu,
  })) || []

export const getTotalDeploymentCostsPerDimension = (
  instancesWithAggregatedCosts: DeploymentInstanceWithAggregatedCosts[],
): TotalDeploymentCostsPerDimension =>
  instancesWithAggregatedCosts.reduce((aggs, aggregation) => mergeWith(aggs, aggregation, add), {
    capacity: 0,
    dataTransfer: 0,
    storage: 0,
    synthetics: 0,
    total_ecu: 0,
  })

export const getTotalProjectCosts = (instances: ProjectInstance[]): number =>
  instances.reduce((total, { total_ecu }) => total + total_ecu, 0)

export const getTotalProjectCollapsedCosts = (
  instances: ProjectInstance[],
): TotalProjectCollapsedCosts =>
  instances.reduce(
    (totals, { total_ecu, type }) => ({
      total_ecu: total_ecu + totals.total_ecu,
      type: uniq([...totals.type, type]),
    }),
    {
      total_ecu: 0,
      type: [],
    },
  )

export const filterProjectsInstancesBySolutions = (
  instances: ProjectInstance[],
  solutions: Solution[],
): ProjectInstance[] => instances.filter(({ type }) => solutions.includes(type as Solution))

export const getAvailableChartValueTypes = (productTypes: ProductType[], solutions: Solution[]) => {
  const availableValues: ChartItemType[] = []

  if (productTypes.includes(ProductType.Deployments)) {
    availableValues.push('deployment')
  }

  if (productTypes.includes(ProductType.Projects)) {
    availableValues.push(...solutions)
  }

  return availableValues
}

export const getChartDataProductTypes = ({
  projects,
  deployments,
  selectedProductTypes,
}): ProductType[] => {
  const types: ProductType[] = []

  if (selectedProductTypes.includes('deployments') && deployments.length > 0) {
    types.push(ProductType.Deployments)
  }

  if (selectedProductTypes.includes('projects') && projects.length > 0) {
    types.push(ProductType.Projects)
  }

  return types
}

export const getRowColor = (id: string): string => colorForInstances.get(id)

export const getDateRange = (state: ReducerState): { from: string; to: string } => ({
  from: state.startDate.startOf('day').utc().format(),
  to: state.endDate.endOf('day').utc().format(),
})

export const getCurrentProductList = ({
  products,
  filters,
}: {
  products: components['schemas']['Items']['products']
  filters: ReducerState
}): components['schemas']['Items']['products'] =>
  sortBy(
    products
      // Filter data by product type
      .filter((product) => {
        const generalType =
          product.type === 'deployment' ? ProductType.Deployments : ProductType.Projects

        return filters.productTypes.includes(generalType)
      })
      // Filter data by a solution
      .filter((product) => {
        if (
          filters.productTypes.includes(ProductType.Deployments) &&
          product.type === 'deployment'
        ) {
          return true
        }

        // "Addons" and "Support" should be listed if any serverless solution is selected
        if (
          filters.solutions.length !== 0 &&
          [ServerlessExtras.Addons, ServerlessExtras.Support].includes(
            product.type as ServerlessExtras,
          )
        ) {
          return true
        }

        return filters.solutions.includes(product.type as Solution)
      }),
    'type',
  )

export const getProductTypeName = (product: ProductViewInstanceType) => {
  if (product === 'deployment') {
    return (
      <FormattedMessage id='product-type-name.deployment' defaultMessage='Hosted deployments' />
    )
  }

  return <FormattedMessage id='product-type-name.serverless' defaultMessage='Serverless projects' />
}

export const getProductName = (product: ProductViewInstanceType) =>
  ({
    deployment: <FormattedMessage id='product-name.deployment' defaultMessage='Stack Hosted' />,
    elasticsearch: (
      <FormattedMessage id='product-name.elasticsearch' defaultMessage='Elasticsearch' />
    ),
    observability: (
      <FormattedMessage id='product-name.observability' defaultMessage='Observability' />
    ),
    security: <FormattedMessage id='product-name.security' defaultMessage='Security' />,
    serverless_addons: (
      <FormattedMessage id='product-name.addon' defaultMessage='Serverless add-ons' />
    ),
    serverless_support: (
      <FormattedMessage id='product-name.support' defaultMessage='Serverless support' />
    ),
  }[product])

// We dont have specified dimension for serverless and want to make it dynamic and rely on API response
type ServerlessDimension = string

export const getBillingDimensionName = (dimension: DeploymentDimension | ServerlessDimension) => {
  switch (dimension) {
    case 'capacity':
      return <FormattedMessage id='billing-dimension.capacity' defaultMessage='Capacity' />
    case 'data_in':
    case 'data_out':
    case 'data_internode':
      return <FormattedMessage id='billing-dimension.data' defaultMessage='Data transfer' />
    case 'storage_api':
      return <FormattedMessage id='billing-dimension.storage_api' defaultMessage='Snapshot APIs' />
    case 'storage_bytes':
      return (
        <FormattedMessage id='billing-dimension.storage_bytes' defaultMessage='Snapshot storage' />
      )
    case 'synthetics_lightweight':
    case 'synthetics_browser':
      return <FormattedMessage id='billing-dimension.synthetics' defaultMessage='Synthetics' />
    default:
      return upperFirst(dimension)
  }
}

// PLI that is built based on product_line_items[0].quantities[0].tiers
const getTierPLI = ({
  name,
  tier,
  pli,
}: {
  name: string
  tier: ProductLineItemTier
  pli: ProductLineItem
}): PLI => ({
  ...pick(tier, ['quantity', 'rate', 'total_ecu']),
  ...pick(pli, ['type', 'display_quantity', 'sku', 'unit']),
  name,
  sku: `${pli.sku}_${tier.min}_${tier.max}`, // its important to include tiers in sku because its used later on to map preview prices
  isTier: true,
})

// PLI that is built based on product_line_items[0] and if tiers exists
const getTierRootPLI = ({
  pli,
  quantity,
}: {
  pli: ProductLineItem
  quantity: ProductLineItemQuantity
}): PLI =>
  ({
    ...pick(pli, ['name', 'type', 'display_quantity', 'sku', 'unit', 'total_ecu']),
    ...pick(quantity, ['quantity', 'rate', 'from', 'to']),
    isTierRootPLI: true,
  } as PLI)

// PLI that is built based on product_line_items[0].quantities if tiers[] is empty
const getNonTierRootPLI = ({
  pli,
  quantity,
}: {
  pli: ProductLineItem
  quantity: ProductLineItemQuantity
}): PLI =>
  ({
    ...pick(pli, ['name', 'type', 'display_quantity', 'sku', 'unit']),
    ...pick(quantity, ['quantity', 'rate', 'total_ecu', 'from', 'to']),
    isNonTierRootPLI: true,
  } as PLI)

// This method takes a list of `product line_items` and if there is a list of quantities it transforms it
// into a flat list where both Root PLI and connected Tiers are listed on the same level
export const getProductDetailsItems = (product_line_items: ProductLineItem[]): PLI[] =>
  product_line_items.reduce((output: PLI[], pli: ProductLineItem) => {
    // non-tiered PLI
    if (!pli.quantities || pli.quantities.length === 0) {
      output.push(pli)
      return output
    }

    pli.quantities.forEach((quantity) => {
      // if PLI has quantities it means that it has tiers but search period could have included a period where PLI had no tiers.
      // In that case the tiers array will be undefined (non-tiered usage)
      if (!quantity.tiers || quantity.tiers.length === 0) {
        output.push(getNonTierRootPLI({ pli, quantity }))

        return output
      }

      // iterate over tiers and create item that will be displayed on a list
      const tiers = quantity.tiers
        // filter out tiers wit no usage - should be done on a API side as well
        .filter((tier) => tier.quantity.value !== 0)
        .map((tier) =>
          getTierPLI({
            name: `${tier.max ? tier.min + '-' + tier.max : tier.min + '+'} ${pli.unit}`,
            tier,
            pli,
          }),
        )

      output.push(getTierRootPLI({ pli, quantity }))
      output.push(...tiers)

      return output
    })

    return output
  }, [])

export const applyPreviewPrices = (PLIs: PLI[], base_prices: Price[]): PLI[] => {
  const priceHashmap = getPriceHashmap(base_prices)

  return PLIs.map((pli) => ({ ...pli, previewPrice: priceHashmap[pli.sku] }))
}
