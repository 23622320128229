/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { getMarketplaceDisplayName } from '@modules/billing-lib/billingDetails/utils'

import { supportPortalUrl } from '@/apps/userconsole/urls'

const InactiveOverviewPanel = () => {
  const billingDetails = useBillingDetails()

  return (
    <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} borderRadius='m' grow={false}>
      <EuiText size='m'>
        <h6>
          <FormattedMessage id='mp-billing-overview.plan' defaultMessage='Plan' />
        </h6>
      </EuiText>

      <EuiSpacer size='xs' />

      <EuiText size='s'>
        <h2>
          <FormattedMessage id='mp-billing-overview.inactive-title' defaultMessage='Inactive' />
        </h2>
      </EuiText>

      <EuiSpacer size='s' />

      <EuiText size='s'>
        <FormattedMessage
          id='mp-billing-overview.inactive.marketplace-info'
          defaultMessage={`Billed through {channel} account`}
          values={{
            channel: getMarketplaceDisplayName(billingDetails.data?.channel),
          }}
        />
      </EuiText>

      <EuiSpacer size='xxl' />

      <EuiButton element='button' fill={true} href={supportPortalUrl} target='_blank'>
        <FormattedMessage
          id='mp-billing-overview.inactive.contact-button'
          defaultMessage='Contact support'
        />
      </EuiButton>
    </EuiPanel>
  )
}

export default InactiveOverviewPanel
