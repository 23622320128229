/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiCode,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
} from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'

import type { ConsoleRequestState } from '../../reducers/apiConsole'

type Props = {
  history: ConsoleRequestState[]
  onItemClick: (historyItem: ConsoleRequestState) => void
  onClearHistory: () => void
}

const RecentHistoryButton: React.FC<Props> = ({ history, onItemClick, onClearHistory }) => {
  const [isMenuOpen, toggleMenuOpen, setMenuOpen] = useToggle(false)

  const disabled = isEmpty(history)

  return (
    <EuiPopover
      id='apiConsole-recentHistory'
      button={
        <EuiButtonEmpty
          size='xs'
          iconType='clock'
          onClick={toggleMenuOpen}
          disabled={disabled}
          data-test-id='api-console-recent-history-btn'
        >
          <FormattedMessage id='api-console-path.recent' defaultMessage='Recent' />
        </EuiButtonEmpty>
      }
      isOpen={isMenuOpen}
      closePopover={() => setMenuOpen(false)}
      panelPaddingSize='none'
      anchorPosition='downLeft'
    >
      <EuiContextMenuPanel items={getItems()} />
    </EuiPopover>
  )

  function getItems() {
    const historyItems = history.map((historyItem) => (
      <EuiContextMenuItem
        key={`${historyItem.method} ${historyItem.path}`}
        icon='console'
        className='euiContextMenuItem--m'
        onClick={() => selectItem(historyItem)}
      >
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiBadge color='hollow'>{historyItem.method}</EuiBadge>
          </EuiFlexItem>

          <EuiFlexItem>
            <div>
              <EuiCode>{historyItem.path}</EuiCode>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiContextMenuItem>
    ))

    historyItems.push(
      <EuiContextMenuItem key='clear history' icon='broom' onClick={clearHistory}>
        <FormattedMessage id='api-console-path.clear-history' defaultMessage='Clear history' />
      </EuiContextMenuItem>,
    )

    return historyItems
  }

  function selectItem(historyItem: ConsoleRequestState) {
    setMenuOpen(false)

    onItemClick(historyItem)
  }

  function clearHistory() {
    setMenuOpen(false)

    onClearHistory()
  }
}

export default RecentHistoryButton
