/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { AnyUserProject } from '@modules/project-user-api/types'
import {
  usePatchElasticsearchProjectMutation,
  usePatchObservabilityProjectMutation,
  usePatchSecurityProjectMutation,
} from '@modules/project-user-lib/hooks/update'
import EditableProjectName from '@modules/project-components/ProjectPage/EditableProjectName'

export type Props = {
  project: AnyUserProject
}

const UserEditableProjectName = ({ project }: Props) => {
  const patchElasticsearchProjectMutation = usePatchElasticsearchProjectMutation()
  const patchObservabilityProjectMutation = usePatchObservabilityProjectMutation()
  const patchSecurityProjectMutation = usePatchSecurityProjectMutation()

  const isLoading =
    patchElasticsearchProjectMutation.isLoading ||
    patchObservabilityProjectMutation.isLoading ||
    patchSecurityProjectMutation.isLoading

  return (
    <EditableProjectName
      project={project}
      onChange={(name) => {
        switch (project.type) {
          case 'elasticsearch':
            patchElasticsearchProjectMutation.mutate({
              id: project.id,
              body: { name },
            })
            break
          case 'observability':
            patchObservabilityProjectMutation.mutate({
              id: project.id,
              body: { name },
            })
            break
          case 'security':
            patchSecurityProjectMutation.mutate({ id: project.id, body: { name } })
            break
          default:
            break
        }
      }}
      isLoading={isLoading}
    />
  )
}

export default UserEditableProjectName
