/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchClusterPlan } from '@modules/cloud-api/v1/types'

import { isDedicatedML } from '@/lib/stackDeployments/selectors/nodeRoles'

import { hasCurrent, hasNext, isElasticsearch } from './preconditions'

import type { Difference, DifferenceCheck } from './types'

export const diffAutoscaling: DifferenceCheck = {
  preconditions: [hasCurrent, hasNext, isElasticsearch],
  check: ({ current, next, currentDeployment, nextDeployment }) => {
    const currentEs = current as ElasticsearchClusterPlan
    const nextEs = next as ElasticsearchClusterPlan

    const currentML = currentEs.cluster_topology?.find((topologyElement) =>
      isDedicatedML({ topologyElement }),
    )
    const nextML = nextEs.cluster_topology?.find((topologyElement) =>
      isDedicatedML({ topologyElement }),
    )

    const changes: Array<Difference<any>> = []

    if (!currentML?.autoscaling_tier_override && nextML?.autoscaling_tier_override) {
      // it wasn't turned on before, but it is now, means that we have enabled it
      changes.push({
        type: `ml-only-autoscaling-enabled`,
        target: `deployment`,
      })
    }

    if (currentML?.autoscaling_tier_override && !nextML?.autoscaling_tier_override) {
      // it was turned on before, and it is not now, means that we have disabled it
      changes.push({
        type: `ml-only-autoscaling-disabled`,
        target: `deployment`,
      })
    }

    // If it flips its value to true, then it's enabled
    if (!currentEs.autoscaling_enabled && nextEs.autoscaling_enabled) {
      changes.push({
        type: `autoscaling-enabled`,
        target: `deployment`,
      })
    }

    // If it flips its value to false, then it's disabled
    if (currentEs.autoscaling_enabled && !nextEs.autoscaling_enabled) {
      changes.push({
        type: `autoscaling-disabled`,
        target: `deployment`,
      })
    }

    // At one point we pushed autoscaling to the deployment level, so we also need to check there
    if (
      !currentDeployment?.settings?.autoscaling_enabled &&
      nextDeployment?.settings?.autoscaling_enabled
    ) {
      changes.push({
        type: `autoscaling-enabled`,
        target: `deployment`,
      })
    }

    if (
      currentDeployment?.settings?.autoscaling_enabled &&
      !nextDeployment?.settings?.autoscaling_enabled
    ) {
      changes.push({
        type: `autoscaling-disabled`,
        target: `deployment`,
      })
    }

    // If it's same value or not set, it's a no-op
    return changes
  },
}
