/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { AnyResourceInfo, SliderInstanceType, AnyClusterPlanInfo } from '@modules/ui-types'

import { getPlanInfo } from '@/lib/stackDeployments/selectors/fundamentals'
import { getInstanceConfigurations } from '@/reducers/instanceConfigurations'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { autoscalingStatusOnGet } from '@/lib/stackDeployments/selectors/autoscaling'

import { explainTopologyChanges } from '../../../../components/StackDeploymentConfigurationChange/StackConfigurationChangeExplain/explainChangesHelpers'
import { hostsAllocatorsUrl } from '../../../urlBuilder'

import OutOfCapacityCulprits from './OutOfCapacityCulprits'

import type { ReduxState } from '@/types/redux'
import type { ExplainedChange } from '../../../../components/StackDeploymentConfigurationChange/StackConfigurationChangeExplain/types'

type StateProps = {
  allocatorsHref: string | null
  topologyChanges: ExplainedChange[]
}

interface DispatchProps {}

type ConsumerProps = {
  resource: AnyResourceInfo
  resourceType: SliderInstanceType
  planAttempt: AnyClusterPlanInfo
}

const mapStateToProps = (
  state: ReduxState,
  { resource, resourceType, planAttempt }: ConsumerProps,
): StateProps => {
  const { region } = resource

  // We expect to be able to load instance configurations for this region if we are rendering this component.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const instanceConfigurations = getInstanceConfigurations(state, region)!
  const deployment = resource.info.deployment_id
    ? getStackDeployment(state, resource.info.deployment_id)
    : undefined

  const newSource = planAttempt.plan
  const oldSource = getPlanInfo({ resource, state: `last_success` })?.plan
  const topologyChanges = explainTopologyChanges({
    type: resourceType,
    newSource,
    oldSource,
    instanceConfigurations,
    autoscalingStatus: deployment ? autoscalingStatusOnGet({ deployment }) : `none`,
  })

  return {
    allocatorsHref: hostsAllocatorsUrl(region),
    topologyChanges,
  }
}

const mapDispatchToProps = {}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OutOfCapacityCulprits)
