/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useQuery } from 'react-query'

import { fetchAsJson } from '@modules/query/helpers'
import type { GetPricesUrlParams } from '@modules/cloud-api/v1/urls'
import { getSaasProvidersUrl, getPricesUrl } from '@modules/cloud-api/v1/urls'
import type { Provider, Prices } from '@modules/cloud-api/v1/types'
import { defaultBillingOptions } from '@modules/billing-lib/hooks'

export const useGetProvidersQuery = (options?: any) =>
  useQuery(
    ['get-saas-providers'],
    () =>
      fetchAsJson<Provider[]>(getSaasProvidersUrl(), {
        method: 'get',
      }),
    { ...options, ...defaultBillingOptions },
  )

export const useGetPricesQuery = (params: GetPricesUrlParams, options) =>
  useQuery(
    ['get-prices', Object.values(params)],
    () =>
      fetchAsJson<Prices>(getPricesUrl(params), {
        method: 'get',
      }),
    { ...options, ...defaultBillingOptions },
  )
