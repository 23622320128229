/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiText, EuiToolTip } from '@elastic/eui'

const RequestCodeLink: React.FunctionComponent<{
  onClick: () => void
}> = ({ onClick }) => {
  const disabledUntil = useRef<number | null>(null)
  const [secondsRemaining, setSecondsRemaining] = useState<number | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      // short circuit if there's no countdown going on
      if (disabledUntil.current == null) {
        return
      }

      const newSecondsRemaining = Math.floor((disabledUntil.current - new Date().getTime()) / 1000)

      // reset if we've reached zero
      if (newSecondsRemaining < 0) {
        disabledUntil.current = null
        setSecondsRemaining(null)
        return
      }

      // update state and rerender only if there's a change in whole seconds
      if (newSecondsRemaining !== secondsRemaining) {
        setSecondsRemaining(newSecondsRemaining)
      }
    }, 100)

    return () => clearInterval(interval)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const link = (
    <EuiLink
      style={{ whiteSpace: 'nowrap' }}
      disabled={secondsRemaining !== null}
      onClick={() => {
        disabledUntil.current = new Date().getTime() + 30 * 1000
        onClick()
      }}
    >
      <FormattedMessage
        id='mfaLogin.emailForm.requestCodeLink'
        defaultMessage='request a new code'
      />
    </EuiLink>
  )

  if (secondsRemaining == null) {
    return link
  }

  const tooltip = (
    <EuiText size='s'>
      <FormattedMessage
        id='mfaLogin.emailForm.requestCodeTooltip'
        defaultMessage='A new code has been sent. You must wait {secondsRemaining} { secondsRemaining, plural, one {second} other {seconds} } before requesting another
      code.'
        values={{
          secondsRemaining,
        }}
      />
    </EuiText>
  )

  return (
    <EuiToolTip content={tooltip} position='top'>
      {link}
    </EuiToolTip>
  )
}

export default RequestCodeLink
