/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { getCluster } from '@/reducers/clusters'
import { getMetrics } from '@/apps/userconsole/reducers/metrics'
import { fetchMetricsRequest } from '@/apps/userconsole/reducers/registry'
import { getTheme } from '@/reducers/theme'

import { fetchMetrics } from '../../actions/clusters/metrics'
import withPolling from '../../../../lib/withPolling'
import { withStackDeploymentRouteParams } from '../../../../components/StackDeploymentEditor'

import PerformanceMetrics from './PerformanceMetrics'

import type { ReduxState, ThunkDispatch } from '@/types/redux'

const mapStateToProps = (state: ReduxState, { regionId, deploymentId, stackDeploymentId }) => {
  const cluster = getCluster(state, regionId, deploymentId)

  return {
    metrics: cluster ? getMetrics(state, cluster.regionId, cluster.stackDeploymentId!) : null,
    metricsRequest: cluster
      ? fetchMetricsRequest(state, cluster.regionId, cluster.stackDeploymentId)
      : null,
    theme: getTheme(state),
    cluster,
    deploymentId: stackDeploymentId,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchMetrics: (regionId, stackDeploymentId, refId) =>
    dispatch(fetchMetrics({ regionId, stackDeploymentId, refId })),
})

const pollingComponent = withPolling(
  PerformanceMetrics,
  ({ fetchMetrics: refreshMetrics, cluster }) => ({
    onPoll: cluster.isStopped
      ? () => Promise.resolve()
      : () => refreshMetrics(cluster.regionId, cluster.stackDeploymentId, cluster.refId),
    pollImmediately: [
      [`region`, `id`],
      [`cluster`, `id`],
    ],
  }),
)

export default withStackDeploymentRouteParams(
  connect(mapStateToProps, mapDispatchToProps)(pollingComponent),
)
