/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-admin-lib/hooks/list'
import ProjectRoleAssignmentsEditor from '@modules/role-assignments-components/ProjectRoleAssignmentsEditor'
import { useSearchOrganizationDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'

import type { FunctionComponent } from 'react'

const AdminProjectRoleAssignmentsEditor: FunctionComponent<{
  organizationId: string
  roleAssignments: RoleAssignments
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void
}> = ({ organizationId, roleAssignments, onChangeRoleAssignments }) => {
  const deploymentQuery = useSearchOrganizationDeploymentsQuery(organizationId)
  const elasticsearchQuery = useListElasticsearchProjectsQuery({ organization_id: organizationId })
  const observabilityQuery = useListObservabilityProjectsQuery({ organization_id: organizationId })
  const securityQuery = useListSecurityProjectsQuery({ organization_id: organizationId })

  return (
    <ProjectRoleAssignmentsEditor
      organizationId={organizationId}
      roleAssignments={roleAssignments}
      deploymentQuery={{
        data: deploymentQuery.data?.deployments,
        isEnabled: true,
        isLoading: deploymentQuery.isLoading,
        error: deploymentQuery.error,
      }}
      elasticsearchQuery={{
        data: elasticsearchQuery.data?.items,
        isEnabled: true,
        isLoading: elasticsearchQuery.isLoading,
        error: elasticsearchQuery.error,
      }}
      observabilityQuery={{
        data: observabilityQuery.data?.items,
        isEnabled: true,
        isLoading: observabilityQuery.isLoading,
        error: observabilityQuery.error,
      }}
      securityQuery={{
        data: securityQuery.data?.items,
        isEnabled: true,
        isLoading: securityQuery.isLoading,
        error: securityQuery.error,
      }}
      onChangeRoleAssignments={onChangeRoleAssignments}
    />
  )
}

export default AdminProjectRoleAssignmentsEditor
