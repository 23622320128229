/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { parse } from 'query-string'

import Feature from '@modules/utils/feature'

import { getLoginRequest } from '@/reducers/asyncRequests/registry'
import { isMarketplaceSubscription } from '@/lib/marketPlace'
import { isFeatureActivated } from '@/selectors'
import { setInitialPasswordRequest } from '@/apps/userconsole/reducers/registry'

import { setInitialPassword } from '../../actions/account'

import ChangePasswordForm from './ChangePasswordForm'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, StateProps, DispatchProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const query = parse(location.search.slice(1))

  return {
    email: String(query.email),
    expires: Number.parseInt(String(query.e), 10),
    activationHash: typeof query.ah === `string` ? query.ah : undefined,
    isMarketplaceUser:
      typeof query.marketplace === `string` ? isMarketplaceSubscription(query.marketplace) : false,
    isGCPUser: query.marketplace === 'gcp',
    isLaunchDarklyActivated: Boolean(isFeatureActivated(state, Feature.launchdarklyId)),
    loginRequest: getLoginRequest(state),
    setInitialPasswordRequest: setInitialPasswordRequest(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  setInitialPassword,
}

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(ChangePasswordForm),
)
