/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { merge, omit } from 'lodash'

import type {
  ApmPlan,
  ElasticsearchClusterPlan,
  KibanaClusterPlan,
} from '@modules/cloud-api/v1/types'
import type { ElasticsearchCluster } from '@modules/ui-types'

import { isDedicatedML, isDedicatedMaster } from '@/lib/stackDeployments/selectors/nodeRoles'

import { isEnabledConfiguration } from './deployments/conversion'
import { removeNonDedicatedMasters } from './deployments/nodeTypes'
import { updateIn } from './immutability-helpers'

import type { AutoscalingEnabled } from './stackDeployments/autoscaling'

export function isClusterActive(cluster: ElasticsearchCluster): boolean {
  return cluster.plan.isActive
}

function cleanOldFailoverOptions<T extends ElasticsearchClusterPlan | KibanaClusterPlan | ApmPlan>(
  plan: T | null,
): T | null {
  return omit(plan, `failover`, `migrate`, `transient`) as T | null
}

export const preparePlanBeforeUpdateCluster = ({
  plan,
  planOverrides,
  autoscalingStatus,
  removeTransient = true,
}: {
  plan: ElasticsearchClusterPlan
  planOverrides: any
  autoscalingStatus: AutoscalingEnabled
  removeTransient?: boolean
}) => {
  const newPlan = removeTransient ? cleanOldFailoverOptions(plan) : { ...plan }

  const mergedPlan = merge(newPlan, planOverrides)

  // Remove topology elements with zero size, otherwise the API will reject
  // the request
  const filteredPendingPlan = updateIn(mergedPlan, [`cluster_topology`], (topology) => {
    if (autoscalingStatus === `all`) {
      const machineLearning = topology.find((topologyElement) => isDedicatedML({ topologyElement }))

      if (machineLearning) {
        delete machineLearning.size
      }
    }

    const filteredTopologies = getFilteredTopologies(autoscalingStatus, topology)

    // If his plan has dedicated master nodes, then we must ensure that none
    // of the other nodes have the `master` role.
    const hasDedicatedMaster = filteredTopologies.some((topologyElement) => {
      if (autoscalingStatus === `all` || autoscalingStatus === `ml`) {
        return isDedicatedMaster({ topologyElement }) && isEnabledConfiguration(topologyElement)
      }

      return isDedicatedMaster({ topologyElement })
    })

    return hasDedicatedMaster ? removeNonDedicatedMasters(filteredTopologies) : filteredTopologies
  })

  return filteredPendingPlan
}

function getFilteredTopologies(autoscalingStatus, topology) {
  const hasSize = (each) => each.memory_per_node || (each.size && each.size.value > 0)

  if (autoscalingStatus === `all` || autoscalingStatus === `ml`) {
    return topology
  }

  return topology.filter(hasSize)
}
