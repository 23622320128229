/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import type { DeploymentClaimRequest, DeploymentCreateRequest } from '@modules/cloud-api/v1/types'
import { postRefreshToken } from '@modules/profile-api'
import type { QueryFunctionReturnType, QueryHook, QueryHookOptions } from '@modules/query/types'

import { getDeployment, postDeploymentCreation, postInstantDeployment } from './caller'

import type { MutationObserverOptions } from 'react-query'

export const usePostDeploymentCreationMutation = ({ onMutate }) =>
  useMutation<
    QueryFunctionReturnType<typeof postDeploymentCreation>,
    { message },
    { body: Partial<DeploymentCreateRequest> }
  >({
    mutationFn: ({ body }) => postDeploymentCreation(body),
    onMutate,
  })

export const usePostInstantDeploymentMutation = ({
  onSuccess,
  onError,
  onMutate,
}: MutationObserverOptions<
  QueryFunctionReturnType<typeof postInstantDeployment>,
  { message },
  { body: Partial<DeploymentClaimRequest> }
>) =>
  useMutation({
    mutationFn: ({ body }) => postInstantDeployment(body),
    onSuccess: (payload, variables, context) => {
      postRefreshToken()
      onSuccess?.(payload, variables, context)
    },
    onError,
    onMutate,
  })

export const useGetDeploymentQuery: QueryHook<typeof getDeployment> = (
  id: string,
  options?: QueryHookOptions,
) =>
  useQuery(id, () => getDeployment(id), {
    refetchInterval: 8000,
    ...options,
  })
