/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt, EuiImage, EuiPanel, EuiText, EuiTitle, useEuiTheme } from '@elastic/eui'

import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import trialExpiredIllustration from '../../../../files/illustration-hour-glass-128-white-bg.svg'
import trialExpiredIllustrationDark from '../../../../files/illustration-hour-glass-128-ink-bg.svg'

const TrialExpiredTile = () => {
  const { colorMode } = useEuiTheme()

  const images = {
    LIGHT: trialExpiredIllustration,
    DARK: trialExpiredIllustrationDark,
  }

  return (
    <EuiPanel hasShadow={true} grow={false}>
      <EuiEmptyPrompt
        css={css({ maxWidth: '100%' })}
        icon={<EuiImage css={css({ width: 253 })} src={images[colorMode]} alt='' />}
        title={
          <EuiTitle size='s'>
            <h2>
              <FormattedMessage
                id='no-projects-and-deployments-state.expired-trial'
                defaultMessage='Your trial has ended'
              />
            </h2>
          </EuiTitle>
        }
        body={
          <EuiText color='subdued'>
            <FormattedMessage
              id='no-projects-and-deployments-state.expired-trial-info'
              defaultMessage='Thank you for trying Elastic Cloud. Subscribe now to continue using our services.'
            />
          </EuiText>
        }
        actions={[
          <div css={css({ width: '16rem' })}>
            <CreditCardModalButton type='full' fill={true} buttonProps={{ fullWidth: true }}>
              <FormattedMessage
                id='no-projects-and-deployments-state.subscribe'
                defaultMessage='Subscribe'
              />
            </CreditCardModalButton>
          </div>,
        ]}
      />
    </EuiPanel>
  )
}

export default TrialExpiredTile
