/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { parse } from 'query-string'

import { withTransaction } from '@elastic/apm-rum-react'

import Feature from '@modules/utils/feature'

import { loginAndRedirect } from '@/actions/auth'
import { fetchOrganizationInvitationRequest } from '@/reducers/asyncRequests/registry'
import { getOrganizationInvitation } from '@/reducers/organizations'
import { getMfa } from '@/reducers/auth'
import { acceptOrganizationInvitation, fetchOrganizationInvitation } from '@/actions/organizations'
import { isFeatureActivated } from '@/selectors'

import AcceptInvitation from './AcceptInvitation'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { LandingPageContextName } from '@/components/LandingPageContainer/types'
import type { ConsumerProps, DispatchProps, InvitationSource, StateProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { search } = location
  const query = parse(search.slice(1))
  const activationHash = typeof query.ah === `string` ? query.ah : undefined
  const email = typeof query.email === `string` ? query.email : undefined
  const pageContext =
    typeof query['landing-page'] === `string`
      ? { name: query['landing-page'] as LandingPageContextName }
      : { name: 'signup' as LandingPageContextName }

  return {
    email,
    expires: Number.parseInt(String(query.e), 10),
    activationHash,
    source: typeof query.source === `string` ? (query.source as InvitationSource) : undefined,
    pageContext,
    isGovCloud: isFeatureActivated(state, Feature.hideIrrelevantSectionsFromGovCloud),
    organizationInvitation: activationHash
      ? getOrganizationInvitation(state, activationHash)
      : undefined,
    fetchOrganizationInvitationRequest: fetchOrganizationInvitationRequest(
      state,
      activationHash ?? '',
    ),
    mfa: getMfa(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  acceptOrganizationInvitation: ({ invitationToken }) =>
    dispatch(acceptOrganizationInvitation({ invitationToken })),
  fetchOrganizationInvitation: ({ invitationToken }) =>
    dispatch(fetchOrganizationInvitation({ invitationToken })),
  loginAndRedirect: ({ credentials, redirectTo }) =>
    dispatch(
      loginAndRedirect({
        credentials,
        redirectTo,
      }),
    ),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTransaction(`Accept email invitation`, `component`)(AcceptInvitation)),
)
