/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect, useRef } from 'react'

import { EventRegistry } from '@modules/ebt/EventRegistry'
import { useEbtContext } from '@modules/ebt/EbtContext'

type AutoOpsEventType = 'autoOpsButtonClicked'

interface AutoOpsEventSchema {
  deployment: string
}

const useEbtAutoOps = () => {
  const { analytics } = useEbtContext()
  const hasBeenRegister = useRef(false)

  useEffect(() => {
    if (analytics && !hasBeenRegister.current) {
      hasBeenRegister.current = true

      try {
        const autoOpsButtonRegistry = new EventRegistry<AutoOpsEventType, AutoOpsEventSchema>({
          analytics,
          eventType: 'autoOpsButtonClicked',
          schemaProps: {
            deployment: {
              description: 'Did the user click the AutoOps button on deployment?',
            },
          },
        })

        autoOpsButtonRegistry.registerEvent()
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }
    }
  }, [analytics])

  const reportEbtEvent = (type: AutoOpsEventType, args: AutoOpsEventSchema) => {
    try {
      if (analytics) {
        analytics.reportEvent(type, args)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { reportEbtEvent }
}

export default useEbtAutoOps
