/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import { EuiProgress } from '@elastic/eui'

import type { ProfileState, AsyncRequestState } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui/Boundary'

import TrialExpiredPrompt from './TrialExpiredPrompt'
import CreateDeploymentPrompt from './CreateDeploymentPrompt'

export interface Props {
  profile: ProfileState
  fetchProfileRequest: AsyncRequestState
  theme: 'dark' | 'light'
  isGovCloud: boolean
}

class HasNoDeployments extends PureComponent<Props> {
  render() {
    const { fetchProfileRequest } = this.props
    const requestInProgress =
      fetchProfileRequest.inProgress && Boolean(fetchProfileRequest.meta.extend_trial)

    return (
      <div>
        {requestInProgress && <EuiProgress size='xs' color='accent' position='absolute' />}

        {this.renderPrompts(requestInProgress)}
      </div>
    )
  }

  renderPrompts(requestInProgress: boolean) {
    const { profile, theme, isGovCloud } = this.props

    if (!profile) {
      return null
    }

    const { hasExpiredTrial } = profile

    if (hasExpiredTrial) {
      return (
        <TrialExpiredPrompt
          isGovCloud={isGovCloud}
          disableButtons={requestInProgress}
          theme={theme}
        />
      )
    }

    return <CreateDeploymentPrompt />
  }
}

export default withErrorBoundary(HasNoDeployments)
