/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'

import {
  EuiButtonIcon,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
} from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'
import { getIlmMigrationLsKey } from '@modules/utils/localStorageKeys'

import { isResourceAvailable } from '@/lib/stackDeployments/selectors/resources'
import {
  getFirstSliderClusterFromGet,
  getFirstEsClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { hasHealthyIlmMigrationPlan } from '@/lib/stackDeployments/selectors/configurationChangeHealth'

import ExternalLink from '../../ExternalLink'
import { kibanaIlmPoliciesUrl } from '../../../lib/serviceProviderDeepLinks'
import { useForceUpdate } from '../../../hooks'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

import './ilmMigrationSuccess.scss'

interface Props extends WrappedComponentProps {
  stackDeployment: StackDeployment | null
}

const messages = defineMessages({
  dismiss: {
    id: 'ilm-migration-success.dismiss',
    defaultMessage: 'Dismiss',
  },
})

const IlmMigrationSuccess: FunctionComponent<Props> = ({
  stackDeployment,
  intl: { formatMessage },
}) => {
  const forceUpdate = useForceUpdate()

  if (!stackDeployment) {
    return null
  }

  const firstEs = getFirstEsClusterFromGet({ deployment: stackDeployment })

  if (!firstEs || !isResourceAvailable(firstEs)) {
    return null
  }

  if (
    !hasHealthyIlmMigrationPlan({ deployment: stackDeployment }) ||
    localStorage.getItem(getIlmMigrationLsKey({ deploymentId: stackDeployment!.id })) !== `true`
  ) {
    return null
  }

  const kibana = getFirstSliderClusterFromGet({
    deployment: stackDeployment,
    sliderInstanceType: `kibana`,
  })

  return (
    <Fragment>
      <EuiCallOut
        color='success'
        className='ilmMigrationSuccess-callOut'
        title={
          <EuiFlexGroup component='span'>
            <EuiFlexItem component='span'>
              <FormattedMessage
                id='ilm-migration-success.title'
                defaultMessage='Migration complete'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false} component='span'>
              <EuiButtonIcon
                color='text'
                iconType='cross'
                onClick={removeCallout}
                aria-label={formatMessage(messages.dismiss)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        iconType='check'
      >
        <FormattedMessage
          id='ilm-migration-success.description'
          defaultMessage='Your deployment now uses Index Lifecycle Management. {viewPolicies}'
          values={{
            viewPolicies: (
              <ExternalLink href={kibanaIlmPoliciesUrl({ resource: kibana })}>
                <FormattedMessage
                  id='ilm-migration-success.view-policies'
                  defaultMessage='View ILM policies'
                />
                <EuiIcon type='popout' className='docLink-icon' size='s' />
              </ExternalLink>
            ),
          }}
        />
      </EuiCallOut>
      <EuiSpacer />
    </Fragment>
  )

  function removeCallout() {
    localStorage.removeItem(getIlmMigrationLsKey({ deploymentId: stackDeployment!.id }))
    forceUpdate()
  }
}

export default injectIntl(IlmMigrationSuccess)
