/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { type FunctionComponent } from 'react'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiPopover, EuiText } from '@elastic/eui'

import type { components } from '@modules/billing-api/v1/types'
import { useToggle } from '@modules/utils/hooks/useToggle'

interface Props {
  unit: string
  quantity: string
  display_quantity?: components['schemas']['DisplayQuantity']
}

const QuantityTableInfo: FunctionComponent<Props> = ({ unit, quantity, display_quantity }) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  const getMessagePerType = () => {
    if (!display_quantity) {
      return null
    }

    if (unit === 'endpoint') {
      return (
        <FormattedMessage
          id='normalized-quantity.endpoint-message'
          defaultMessage={`Average number ({normalized_quantity}) of active endpoints configured with Elastic Defend prevention, detection, or response capabilities observed in this Project during the selected date range.`}
          values={{
            normalized_quantity: Math.round(display_quantity.value),
          }}
        />
      )
    }

    if (unit === 'asset') {
      return (
        <FormattedMessage
          id='normalized-quantity.asset-message'
          defaultMessage={`This number represents the quantity that is billed over the selected date range. It is calculated based on the total number of active assets ({normalized_quantity}) in your project.`}
          values={{
            normalized_quantity: Math.round(display_quantity.value),
          }}
        />
      )
    }

    return null
  }

  return (
    <EuiFlexGroup gutterSize='xs'>
      <EuiFlexItem grow={false}>{quantity}</EuiFlexItem>
      {display_quantity?.type === 'normalized' && (
        <EuiFlexItem grow={false} data-test-id='normalized-quantity.icon'>
          <EuiPopover
            button={<EuiButtonIcon iconType='iInCircle' onClick={togglePopover} />}
            isOpen={isPopoverOpen}
            closePopover={() => setPopoverOpen(false)}
          >
            <EuiText
              size='s'
              data-test-id='normalized-quantity.message'
              css={css({ maxWidth: 500 })}
            >
              {getMessagePerType()}
            </EuiText>
          </EuiPopover>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default QuantityTableInfo
