/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'

import Feature from '@modules/utils/feature'

import { getMfa } from '@/reducers/auth'
import { getLoginRequest } from '@/reducers/asyncRequests/registry'
import { logout, redirectAfterLogin } from '@/actions/auth'
import { resetLoginRequest } from '@/actions/auth/auth'

import { isFeatureActivated } from '../../selectors'
import { getRegistrationSource } from '../../lib/urlUtils'

import UserconsoleLogin from './UserconsoleLogin'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { hash, search } = location
  const { redirectTo, fromURI, source, partner } = parse(search.slice(1))
  const newBearerToken = getBearerToken(state, hash)

  return {
    redirectTo: typeof redirectTo === `string` ? redirectTo : undefined,
    fromURI: typeof fromURI === `string` ? fromURI : undefined,
    source: typeof source === `string` ? getRegistrationSource(search) : undefined,
    partner: typeof partner === `string` ? partner : undefined,
    newBearerToken,
    mfa: getMfa(state),
    loginRequest: getLoginRequest(state),
    isGovCloud: isFeatureActivated(state, Feature.hideIrrelevantSectionsFromGovCloud),
    isLaunchDarklyActivated: Boolean(isFeatureActivated(state, Feature.launchdarklyId)),
  }
}

const mapDispatchToProps: DispatchProps = {
  logout,
  redirectAfterLogin,
  resetLoginRequest,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UserconsoleLogin)

function getBearerToken(state: ReduxState, hash: string): string | null {
  try {
    if (!isFeatureActivated(state, Feature.basicAuthProxyPass)) {
      return null // the smaller the surface, the better
    }

    const poundlessHash = String(hash).slice(1)
    const hashParams = parse(poundlessHash)
    const newBearerToken = hashParams.bearer_token

    if (typeof newBearerToken !== `string`) {
      return null // the hash might not contain a bearer token, and that's fine
    }

    return newBearerToken
  } catch (err) {
    return null // the hash might not be a query string, and that's fine
  }
}
