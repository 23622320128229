/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
export function organizationsUrl(): string {
  return `/organizations`
}

export function organizationOverviewUrl(organizationId: string): string {
  return `/organizations/${organizationId}`
}

export function organizationMembersUrl(organizationId: string): string {
  return `/organizations/${organizationId}/members`
}

export function organizationBillingOverviewUrl(organizationId: string): string {
  return `/organizations/${organizationId}/billing-overview`
}

export function organizationUsageUrl(organizationId: string): string {
  return `/organizations/${organizationId}/usage`
}

export function organizationBillingHistoryUrl(organizationId: string): string {
  return `/organizations/${organizationId}/billing-history`
}

export function organizationSecurityUrl(organizationId: string): string {
  return `/organizations/${organizationId}/security`
}

export function organizationBillingSubscriptionUrl(organizationId: string): string {
  return `/organizations/${organizationId}/billing-subscription`
}

export function organizationDeploymentsUrl(organizationId: string): string {
  return `/organizations/${organizationId}/deployments`
}

export function organizationProjectsUrl(organizationId: string): string {
  return `/organizations/${organizationId}/projects`
}
