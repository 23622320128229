/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import { getConfigForKey } from '@/store'
import {
  isDedicatedML,
  isDedicatedIngest,
  isDedicatedMaster,
} from '@/lib/stackDeployments/selectors/nodeRoles'
import {
  getVersionOnCreate,
  getFirstSliderClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  isAutoscaleableTier,
  getAutoscalingStatus,
} from '@/lib/stackDeployments/selectors/autoscaling'
import { isStopped } from '@/lib/stackDeployments/selectors/configurationChanges'
import { isEnabledConfiguration } from '@/lib/deployments/conversion'
import { getApmMode, ApmMode } from '@/lib/apmClusters/getApmMode'

import { FocusKeeper } from './FocusKeeper'
import DedicatedIngest from './DedicatedIngest'
import DedicatedMaster from './DedicatedMaster'
import TopologyElement from './TopologyElement'
import DisabledTopologyElement from './DisabledTopologyElement'

import type { AllProps as TopologyElementProps } from './TopologyElement'

const TopologyElementRouter: React.FunctionComponent<TopologyElementProps> = (props) => {
  const {
    deploymentUnderEdit,
    sliderInstanceType,
    topologyElement,
    dedicatedMasterThreshold,
    instanceConfiguration,
    subscription,
    deployment,
  } = props

  const activeElement = useRef<HTMLDivElement>(null)

  const isAwsm = subscription === `aws`

  if (!instanceConfiguration) {
    if (isAwsm) {
      return null
    }

    const isEce = getConfigForKey(`APP_PLATFORM`) === `ece`

    return (
      <Fragment>
        <EuiCallOut color='warning'>
          <FormattedMessage
            id='topologyElement-error'
            defaultMessage='The configuration information [{instanceConfigId}] for this instance was not found in the {deploymentTemplateName}. Please contact support.'
            values={{
              instanceConfigId: topologyElement.instance_configuration_id,
              deploymentTemplateName: isEce ? (
                <FormattedMessage
                  id='topologyElement-error-eceDeploymentTemplateName'
                  defaultMessage='deployment template'
                />
              ) : (
                <FormattedMessage
                  id='topologyElement-error-essDeploymentTemplateName'
                  defaultMessage='hardware profile'
                />
              ),
            }}
          />
        </EuiCallOut>
        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  if (isDedicatedIngest({ topologyElement })) {
    return <DedicatedIngest {...props} />
  }

  if (isDedicatedMaster({ topologyElement })) {
    return <DedicatedMaster {...props} dedicatedMasterThreshold={dedicatedMasterThreshold} />
  }

  const version = getVersionOnCreate({ deployment })
  const autoscalingStatus = getAutoscalingStatus({ deployment })
  const isAnyAutoscalingEnabled =
    autoscalingStatus === `all` ||
    (isDedicatedML({ topologyElement }) && autoscalingStatus === `ml`)

  // for the purposes of the Create/Edit screen, auto-scaled deployments always
  // show their sliders as "enabled" even if *currently* zero-sized
  if (
    !isEnabledConfiguration(topologyElement) &&
    (!isAnyAutoscalingEnabled || !isAutoscaleableTier({ topologyElement, version }))
  ) {
    const resource =
      deploymentUnderEdit &&
      getFirstSliderClusterFromGet({
        deployment: deploymentUnderEdit,
        sliderInstanceType,
      })
    const shouldResetResourceVersion = resource && isStopped({ resource })

    return (
      <FocusKeeper act={props.onChange} refObject={activeElement}>
        {({ act }) => (
          <DisabledTopologyElement
            {...props}
            onChange={act}
            version={shouldResetResourceVersion ? version || undefined : undefined}
          />
        )}
      </FocusKeeper>
    )
  }

  if (sliderInstanceType === `apm`) {
    if (getApmMode({ deployment: deploymentUnderEdit }) === ApmMode.Managed) {
      return (
        <FocusKeeper act={props.onChange} refObject={activeElement}>
          {({ act }) => <TopologyElement {...props} showUserSettings={false} onChange={act} />}
        </FocusKeeper>
      )
    }
  }

  if (sliderInstanceType === 'integrations_server') {
    return (
      <FocusKeeper act={props.onChange} refObject={activeElement}>
        {({ act }) => <TopologyElement {...props} showUserSettings={false} onChange={act} />}
      </FocusKeeper>
    )
  }

  return (
    <FocusKeeper act={props.onChange} refObject={activeElement}>
      {({ act }) => <TopologyElement {...props} onChange={act} />}
    </FocusKeeper>
  )
}

export default TopologyElementRouter
