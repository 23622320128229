/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { EuiCallOut, EuiLink, EuiSpacer } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { ProfileState } from '@modules/ui-types'
import { useGetEntitlementsV1 } from '@modules/billing-lib/hooks'

import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'
import { isEsStopped } from '@/lib/stackDeployments/selectors/configurationChanges'

import TrialWelcome from '../TrialWelcome'

type Props = {
  profile: NonNullable<ProfileState>
  deployments: DeploymentSearchResponse[] | null
  isGovCloud: boolean
}

const TrialState: React.FunctionComponent<Props> = ({ profile, deployments, isGovCloud }) => {
  const entitlementsQuery = useGetEntitlementsV1(profile.organization_id, {
    enabled: Boolean(profile.organization_id),
  })

  if (!deployments) {
    return null
  }

  const emptyDeploymentsArray = deployments.length < 1

  if (profile.hasExpiredTrial) {
    if (emptyDeploymentsArray) {
      return renderTrialEnded()
    }

    if (isEveryEsStopped()) {
      return renderTrialEndedAndDeploymentsStopped()
    }

    return renderTrialEndedAndDeploymentsRunning()
  }

  if (emptyDeploymentsArray && entitlementsQuery.data?.trial_extendable) {
    return renderTrialWelcome()
  }

  return null

  function renderTrialEnded() {
    return (
      <Fragment>
        <EuiCallOut
          data-test-id='trial-ended'
          title={
            <FormattedMessage
              id='deployments.trial-expired'
              defaultMessage='Your trial has ended'
            />
          }
          iconType='clock'
        >
          <p>
            <FormattedMessage
              id='deployments.trial-expired-description'
              defaultMessage='Unfortunately your trial has ended. {action} to continue using the Elasticsearch Service.'
              values={{
                action: isGovCloud ? (
                  <EuiLink href='mailto:gov-onboarding@elastic.co'>
                    <FormattedMessage
                      id='deployments.trial-expired-description.contact-us-link'
                      defaultMessage='Contact us'
                    />
                  </EuiLink>
                ) : (
                  <CreditCardModalButton>
                    <FormattedMessage
                      id='deployments.trial-expired-description.billing-details-link'
                      defaultMessage='Add a credit card'
                    />
                  </CreditCardModalButton>
                ),
              }}
            />
          </p>
        </EuiCallOut>
        <EuiSpacer />
      </Fragment>
    )
  }

  function renderTrialEndedAndDeploymentsStopped() {
    const daysToCleanup = getDaysUntilCleanup()

    return (
      <Fragment>
        <EuiCallOut
          data-test-id='deployment-was-terminated'
          title={
            <FormattedMessage
              id='deployments.trial-expired.was-terminated'
              defaultMessage='Your deployment was terminated'
            />
          }
          color='danger'
          iconType='clock'
        >
          <p className='addCreditCardLink'>
            <FormattedMessage
              id='deployments.trial-expired-description.was-terminated'
              defaultMessage='Your trial period is over and your deployment has been terminated. {addCreditCard} to prevent your deployment from being completely deleted {daysToCleanup}.'
              values={{
                addCreditCard: (
                  <CreditCardModalButton>
                    <FormattedMessage
                      id='deployments.trial-expired-description.was-terminated.billing-details-link'
                      defaultMessage='Add a credit card'
                    />
                  </CreditCardModalButton>
                ),
                daysToCleanup:
                  daysToCleanup > 0 ? (
                    <FormattedMessage
                      id='deployments.trial-expired-description.was-terminated.days'
                      defaultMessage='in {days} {days, plural, one {day} other {days}}'
                      values={{
                        days: daysToCleanup,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id='deployments.trial-expired-description.soon'
                      defaultMessage='soon'
                    />
                  ),
              }}
            />
          </p>
        </EuiCallOut>

        <EuiSpacer />
      </Fragment>
    )
  }

  function renderTrialEndedAndDeploymentsRunning() {
    const daysToTermination = getDaysUntilTermination()
    return (
      <Fragment>
        <EuiCallOut
          data-test-id='deployment-will-be-terminated'
          title={
            <FormattedMessage
              id='deployments.trial-expired.will-be-terminated'
              defaultMessage='Your deployment will be terminated'
            />
          }
          color='warning'
          iconType='clock'
        >
          <p>
            <FormattedMessage
              id='deployments.trial-expired-description.will-be-terminated'
              defaultMessage='Even though your trial has ended, you still have {time} to {addCreditCard} to keep your deployment from being terminated and losing all your data.'
              values={{
                addCreditCard: (
                  <CreditCardModalButton>
                    <FormattedMessage
                      id='deployments.trial-expired-description.will-be-terminated.billing-details-link'
                      defaultMessage='add a credit card'
                    />
                  </CreditCardModalButton>
                ),
                time:
                  daysToTermination > 0 ? (
                    <FormattedMessage
                      id='deployments.trial-expired-description.will-be-terminated.days'
                      defaultMessage='{days} {days, plural, one {day} other {days}}'
                      values={{
                        days: daysToTermination,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id='deployments.trial-expired-description.some-time'
                      defaultMessage='some time'
                    />
                  ),
              }}
            />
          </p>
        </EuiCallOut>
        <EuiSpacer />
      </Fragment>
    )
  }

  function renderTrialWelcome() {
    return <TrialWelcome profile={profile} />
  }

  function isEveryEsStopped() {
    return deployments && deployments.every((deployment) => isEsStopped({ deployment }))
  }

  function getCleanupDate() {
    return getDateOfAction({ daysToAction: 30 })
  }

  function getDaysUntilCleanup() {
    const cleanupDate = getCleanupDate()
    const currentDate = moment().startOf('day')
    return cleanupDate ? Math.floor(moment.duration(cleanupDate.diff(currentDate)).asDays()) : 0
  }

  function getTerminationDate() {
    return getDateOfAction({ daysToAction: 3 })
  }

  function getDaysUntilTermination() {
    const terminationDate = getTerminationDate()
    const currentDate = moment().startOf('day')
    return terminationDate
      ? Math.floor(moment.duration(terminationDate.diff(currentDate)).asDays())
      : 0
  }

  function getDateOfAction({ daysToAction }: { daysToAction: number }) {
    const { currentTrial } = profile

    if (!currentTrial || !currentTrial.end) {
      return null
    }

    const currentDate = moment().startOf('day')

    if (moment(currentTrial.end).isBefore(currentDate)) {
      return moment(currentTrial.end).add(daysToAction, 'days')
    }

    return null
  }
}

export default TrialState
