/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  ecuUnit: {
    id: 'elastic-consumption-units',
    defaultMessage: 'ECU',
  },
  currentUsage: {
    id: 'billing-overview.usage-card.current-usage',
    defaultMessage: 'Current usage',
  },
  currentMonth: {
    id: 'billing-overview.usage-card.current-month',
    defaultMessage: 'Current',
  },
  previousMonth: {
    id: 'billing-overview.usage-card.previous-month',
    defaultMessage: 'Previous Month',
  },
  capacity: {
    id: 'billing-overview.usage-card.capacity',
    defaultMessage: 'Capacity',
  },
  dataTransferIn: {
    id: 'billing-overview.usage-card.data-transfer-in',
    defaultMessage: 'Data transfer in',
  },
  dataTransferOut: {
    id: 'billing-overview.usage-card.data-transfer-out',
    defaultMessage: 'Data transfer out',
  },
  dataTransferInternode: {
    id: 'billing-overview.usage-card.data-transfer-internode',
    defaultMessage: 'Data transfer internode',
  },
  snapshotApis: {
    id: 'billing-overview.usage-card.snapshot-apis',
    defaultMessage: 'Snapshot APIs',
  },
  snapshotStorage: {
    id: 'billing-overview.usage-card.snapshot-storage',
    defaultMessage: 'Snapshot storage',
  },
  synthetics: {
    id: 'billing-overview.usage-card.synthetics',
    defaultMessage: 'Synthetics',
  },
  synthetics_lightweight: {
    id: 'billing-overview.usage-card.synthetics_lightweight',
    defaultMessage: 'Synthetics Lightweight',
  },
  synthetics_browser: {
    id: 'billing-overview.usage-card.synthetics_browser',
    defaultMessage: 'Synthetics Browser',
  },
  genericErrorMessage: {
    id: 'generic-error.billing-message',
    defaultMessage: `Something isn't right. Please wait a moment and try refreshing the page. If the error persists, contact Support.`,
  },
  fullyManagedProjects: {
    id: 'billing-overview.serverless-projects',
    defaultMessage: `Serverless projects`,
  },
  hostedDeployments: {
    id: 'billing-overview.hostedDeployments',
    defaultMessage: `Hosted deployments`,
  },
  observabilityProduct: {
    id: 'billing-overview.products.observability',
    defaultMessage: `Observability`,
  },
  securityProduct: {
    id: 'billing-overview.products.security',
    defaultMessage: `Security`,
  },
  searchProduct: {
    id: 'billing-overview.products.search',
    defaultMessage: `Elasticsearch`,
  },
  extrasSupport: {
    id: 'billing-overview.extras.support',
    defaultMessage: `Serverless support`,
  },
  extrasAddons: {
    id: 'billing-overview.extras.addons',
    defaultMessage: `Serverless add-ons`,
  },
})

export default messages
