/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'

import { ReduxState } from '@/types/redux'
import { fetchDeploymentTemplatesRequest } from '@/reducers/asyncRequests/registry'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { getDeploymentTemplate } from '@/reducers/deploymentTemplates'
import { getVersion, getEsPlanFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'

import { withStackDeploymentRouteParams } from '../routing'

import EditStackDeploymentEditorDependencies from './EditStackDeploymentEditorDependencies'

import type { ConsumerProps, StateProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { regionId, location, stackDeploymentId }: ConsumerProps,
): StateProps => {
  const query = parse(location.search.slice(1))
  const stackDeployment = getStackDeployment(state, stackDeploymentId)!
  const version = getVersion({ deployment: stackDeployment })
  const deploymentTemplateId = getDeploymentTemplateId({ deployment: stackDeployment })
  const deploymentTemplate =
    (deploymentTemplateId &&
      version &&
      getDeploymentTemplate(state, regionId, deploymentTemplateId, version)) ||
    null

  const currentEsPlan = getEsPlanFromGet({ deployment: stackDeployment })
  const pendingEsPlan = getEsPlanFromGet({ deployment: stackDeployment, state: `pending` })

  const noCurrentVersion = !currentEsPlan?.elasticsearch.version
  const noPendingVersion = !pendingEsPlan?.elasticsearch.version

  // if there is no pending or current plan (as evidenced by a lack of version)
  // then we can't show anything other than the last plan attempt
  const showFailedAttempt =
    String(query.fromAttempt) === `true` || (noCurrentVersion && noPendingVersion)

  // this parameter is enabled on the request when loading the page in <ChromeNavigation />
  const showMaxZones = 'true'

  return {
    stackDeployment,
    deploymentTemplate,
    showFailedAttempt,
    fetchDeploymentTemplatesRequest: fetchDeploymentTemplatesRequest(
      state,
      regionId,
      version,
      showMaxZones,
    ),
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, unknown, ConsumerProps>(mapStateToProps)(
    EditStackDeploymentEditorDependencies,
  ),
)
