/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import type { EuiFilePickerProps } from '@elastic/eui'
import { EuiFilePicker, EuiFormRow } from '@elastic/eui'

import type { Extension } from '@modules/cloud-api/v1/types'

import type { FunctionComponent } from 'react'

export type Props = {
  extensionType: Extension['extension_type']
  canEditPlugins: boolean
  canEditBundles: boolean
  onChange: (file: File) => void
}

const zipMimeTypes = [
  `application/zip`,
  `application/x-zip-compressed`,
  `application/x-compressed`,
  `multipart/x-zip`,
].join(`,`)

const messages = defineMessages({
  bundleFile: {
    id: 'deployment-extension-upload.bundle-file',
    defaultMessage: `Bundle file`,
  },
  pluginFile: {
    id: 'deployment-extension-upload.plugin-file',
    defaultMessage: `Plugin file`,
  },
})

const DeploymentExtensionUpload: FunctionComponent<Props> = ({
  extensionType,
  canEditPlugins,
  canEditBundles,
  onChange,
}) => {
  const { formatMessage } = useIntl()

  const onChangeFilePicker: EuiFilePickerProps['onChange'] = useCallback(
    (files) => {
      const file = files?.[0]

      if (file) {
        onChange(file)
      }
    },
    [onChange],
  )

  const formRowLabel = useMemo(
    () => formatMessage(extensionType === `bundle` ? messages.bundleFile : messages.pluginFile),
    [extensionType, formatMessage],
  )

  const canEdit =
    (extensionType === `bundle` && canEditBundles) || (extensionType === `plugin` && canEditPlugins)

  if (!canEdit) {
    return null
  }

  return (
    <EuiFormRow label={formRowLabel}>
      <EuiFilePicker accept={zipMimeTypes} onChange={onChangeFilePicker} />
    </EuiFormRow>
  )
}

export default DeploymentExtensionUpload
