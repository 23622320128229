/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiFlexGroup, EuiText, EuiToolTip, useEuiTheme } from '@elastic/eui'

import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'

import verifyEmailDark from '../../../files/information--dark.svg'
import verifyEmailLight from '../../../files/information--light.svg'

import type { FC, ReactElement } from 'react'

interface Props {
  email: string
  emailSent: boolean
  isPasswordNotice?: boolean
  loading: boolean
  resendMailCoolingOffPeriodInSeconds?: number
  onResendButtonClicked: () => void
  onCancel: () => void
}

const EmailVerification: FC<Props> = ({
  email,
  emailSent: emailSent,
  isPasswordNotice,
  loading,
  resendMailCoolingOffPeriodInSeconds = 30,
  onResendButtonClicked,
  onCancel,
}) => {
  const [waitReclick, setWaitReclick] = useState(false)

  useEffect(() => {
    const timeoutInMilliseconds = resendMailCoolingOffPeriodInSeconds * 1000

    const timeout = setTimeout(() => {
      setWaitReclick(false)
    }, timeoutInMilliseconds)

    return () => {
      clearTimeout(timeout)
    }
  }, [waitReclick, resendMailCoolingOffPeriodInSeconds])

  const { euiTheme } = useEuiTheme()

  const isResendDisabled = waitReclick || loading

  const disabledStyle = css({
    cursor: 'not-allowed',
    '&:hover': {
      textDecoration: 'none !important',
    },
  })

  return (
    <Fragment>
      <LandingPageOuterContainer isFlowV2={true} fullHeight={false}>
        <LandingPageInnerContainer
          image={verifyEmailLight}
          darkImage={verifyEmailDark}
          isFlowV2={true}
          title={
            <EmailVerificationTitle
              emailSent={emailSent}
              isPasswordNotice={isPasswordNotice ?? false}
            />
          }
          subtitle={
            <EmailVerificationSubtitle email={email} isPasswordNotice={isPasswordNotice ?? false} />
          }
          imageCssOverride={css({
            width: '337px',
            height: '229px',
          })}
        >
          <EuiFlexGroup justifyContent='center'>
            <EuiButtonEmpty onClick={onCancel} data-test-id='email-verification-back-button'>
              <FormattedMessage
                id='email-verification.back-to-login-page'
                defaultMessage='Back to login'
              />
            </EuiButtonEmpty>
          </EuiFlexGroup>
        </LandingPageInnerContainer>
      </LandingPageOuterContainer>

      <EuiText
        color='subdued'
        size='s'
        textAlign='center'
        css={css({
          marginBottom: euiTheme.size.xxxl,
        })}
      >
        <FormattedMessage
          id='email-verification.info-message'
          defaultMessage='Not seeing it? Check spam, or <link>resend email.</link>'
          values={{
            // TODO: check this for accessibility
            link: (text: string) => (
              <ResendLinkWrapper isActive={!isResendDisabled}>
                <EuiButtonEmpty
                  type='submit'
                  onClick={() => {
                    if (isResendDisabled) {
                      return
                    }

                    onResendButtonClicked()
                    setWaitReclick(true)
                  }}
                  flush='both'
                  css={[
                    {
                      verticalAlign: 'baseline',
                    },
                    isResendDisabled && disabledStyle,
                  ]}
                  aria-disabled={isResendDisabled}
                  isLoading={loading}
                >
                  {text}
                </EuiButtonEmpty>
              </ResendLinkWrapper>
            ),
          }}
        />
      </EuiText>
    </Fragment>
  )
}

interface ResendLinkWrapperProps {
  isActive: boolean
  children: ReactElement
}

const ResendLinkWrapper: FC<ResendLinkWrapperProps> = ({ isActive, children }) =>
  isActive ? (
    children
  ) : (
    <EuiToolTip
      content={
        <FormattedMessage
          id='email-verification.resend'
          defaultMessage='A verification email was just sent to your email. Please wait 30 seconds to send another.'
        />
      }
    >
      {children}
    </EuiToolTip>
  )

type EmailVerificationTitleProps = {
  emailSent: boolean
  isPasswordNotice: boolean
}

const EmailVerificationTitle: FC<EmailVerificationTitleProps> = ({
  emailSent: isResendEmailVerificationLinkRequestDone,
  isPasswordNotice,
}) => {
  if (isResendEmailVerificationLinkRequestDone) {
    return (
      <FormattedMessage
        data-test-id='email-verification-verification-email-sent'
        id='email-verification.verification-email-sent'
        defaultMessage='Email sent'
      />
    )
  }

  if (isPasswordNotice) {
    return (
      <FormattedMessage
        data-test-id='email-verification-verify-email-title'
        id='email-verification.check-email-title'
        defaultMessage='Check your email'
      />
    )
  }

  return (
    <FormattedMessage
      data-test-id='email-verification-verify-email-title'
      id='email-verification.verify-your-email-title'
      defaultMessage='Please check your email'
    />
  )
}

type EmailVerificationSubtitleProps = {
  isPasswordNotice: boolean
  email: string
}

const EmailVerificationSubtitle: FC<EmailVerificationSubtitleProps> = ({
  isPasswordNotice,
  email,
}) => {
  const checkSpam = (
    <FormattedMessage
      id='email-verification.check-spam-folder'
      defaultMessage="If you haven't received it within a few minutes, check your spam folder."
    />
  )

  if (isPasswordNotice) {
    return (
      <FormattedMessage
        id='email-verification.sent-password-notice'
        defaultMessage='To ensure that your account stays secure, we sent an email with a link to set a new password. {checkSpam}'
        values={{
          checkSpam,
        }}
      />
    )
  }

  return (
    <FormattedMessage
      id='email-verification.sent-email-verification'
      defaultMessage={'We sent a verification link to your inbox: {email}'}
      values={{ email }}
    />
  )
}

export default EmailVerification
