/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiBetaBadge,
  EuiButton,
  EuiCheckableCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { OptimizationOptionProps } from '@modules/project-creation-components/PresentOptimizationOptions'
import PresentOptimizationOptions from '@modules/project-creation-components/PresentOptimizationOptions'
import type { ElasticsearchOptimizedFor } from '@modules/project-user-api/v1/types'
import { StepLayout } from '@modules/cui/Step/StepLayout'

import type { FunctionComponent } from 'react'

type Props = {
  projectSubtype: ElasticsearchOptimizedFor | undefined
  onChangeSearchProjectSubtype: (value: ElasticsearchOptimizedFor) => void
  onSearchProjectSubtypeCaseConfirmed: () => void
  isLaunchButton?: boolean
}

const SelectSearchProjectSubtype: FunctionComponent<Props> = ({
  projectSubtype,
  onChangeSearchProjectSubtype,
  onSearchProjectSubtypeCaseConfirmed,
  isLaunchButton = false,
}: Props) => {
  const [isLaunching, setIsLaunching] = useState(false)
  return (
    <EuiForm
      onSubmit={(e) => {
        setIsLaunching(true)
        e.preventDefault()
        onSearchProjectSubtypeCaseConfirmed()
      }}
      component={'form'}
    >
      <StepLayout
        title={
          <FormattedMessage
            id='discovery-questions-search-project-subtype.title'
            defaultMessage='What configuration would you like for your serverless project?'
          />
        }
        footer={
          <EuiButton
            fill={!isLaunchButton}
            type='submit'
            disabled={projectSubtype === undefined}
            isLoading={isLaunching}
          >
            {isLaunchButton ? (
              <FormattedMessage
                id='discovery-questions-search-project-subtype.launch'
                defaultMessage='Launch'
              />
            ) : (
              <FormattedMessage
                id='discovery-questions-search-project-subtype.continue'
                defaultMessage='Continue'
              />
            )}
          </EuiButton>
        }
      >
        <PresentOptimizationOptions
          value={projectSubtype}
          onChange={onChangeSearchProjectSubtype}
          OptimizationOptionComponent={PresentOptimizationOption}
        />
      </StepLayout>
    </EuiForm>
  )
}

const PresentOptimizationOption = ({
  slug,
  title,
  description,
  checked,
  disabled,
  notifyButton,
  onChange,
}: OptimizationOptionProps) => {
  const htmlId = useGeneratedHtmlId()

  const styleRadio = css`
    :hover {
      transition: transform box-shadow 350ms ease-in-out;
      transform: translateY(-2px);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  `

  const label = (
    <EuiFlexGroup direction='column' gutterSize='xs'>
      <EuiTitle size='xs'>
        <h4>{disabled ? <ComingSoonLabel label={title} /> : title}</h4>
      </EuiTitle>
      <EuiText size='s'>{description}</EuiText>
      {disabled && (
        <Fragment>
          <EuiSpacer size='xs' />
          <div>{notifyButton}</div>
        </Fragment>
      )}
    </EuiFlexGroup>
  )

  return (
    <EuiCheckableCard
      id={htmlId}
      css={styleRadio}
      label={label}
      name='searchProjectSubtype'
      value={slug}
      checked={checked}
      onChange={() => onChange(slug)}
      disabled={disabled}
    />
  )
}

const ComingSoonLabel: FunctionComponent<{ label: string }> = ({ label }) => {
  const { formatMessage } = useIntl()

  const euiTheme = useEuiTheme()

  return (
    <EuiFlexGroup alignItems='center' gutterSize='s'>
      <EuiFlexItem
        grow={false}
        css={css`
          color: ${euiTheme.euiTheme.colors.disabledText};
        `}
      >
        {label}
      </EuiFlexItem>
      <div>
        <EuiBetaBadge
          label={formatMessage({
            id: 'discovery-questions-search-project-subtype.coming-soon',
            defaultMessage: 'Coming soon',
          })}
          size='s'
          css={css`
            display: flex;
            align-items: center;
          `}
        />
      </div>
    </EuiFlexGroup>
  )
}

export default SelectSearchProjectSubtype
