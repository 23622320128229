/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { useIntl } from 'react-intl'
import { Fragment, type FunctionComponent } from 'react'

import { EuiFlexGroup, EuiSkeletonRectangle } from '@elastic/eui'

import { useGetProvidersQuery } from '@modules/cloud-lib/billing/hooks'
import type { PlatformId, Region } from '@modules/ui-types'
import { isPlatformId } from '@modules/discovery-questions-lib/utils'
import { useOnboardingToken } from '@modules/discovery-questions-lib/hooks'
import { selectTemplateByOnboardingToken } from '@modules/deployment-creation-lib/utils'
import { StepLayout } from '@modules/cui/Step/StepLayout'
import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import { insertSolutionType } from '@modules/deployment-creation-wizard/lib'

// eslint-disable-next-line import/no-restricted-paths
import CreateStackDeploymentEditorDependencies from '@/components/StackDeploymentEditor/CreateStackDeploymentEditorDependencies'
// eslint-disable-next-line import/no-restricted-paths
import { selectTemplateWithSameCategory } from '@/lib/stackDeployments/defaultDeploymentTemplate'
// eslint-disable-next-line import/no-restricted-paths
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'

import messages from '../discovery-questions-components/messages'
import { getPlatform } from '../utils/platform'

import CreateDeploymentEditor from './CreateDeploymentEditor'

// eslint-disable-next-line import/no-restricted-paths
import type { CreateEditorComponentConsumerProps } from '@/components/StackDeploymentEditor/types'

export interface CreateDeploymentProps {
  onDeploymentCreation: ({
    region,
    version,
    platform,
    hardware,
  }: {
    region: Region
    version: string
    platform: string
    hardware: string
  }) => Promise<void>
  solutionType?: DefaultSolutionView | undefined
}

const CreateDeployment: FunctionComponent<CreateDeploymentProps> = ({
  onDeploymentCreation,
  children,
  solutionType,
}) => {
  const { formatMessage } = useIntl()

  const { data: providers } = useGetProvidersQuery()

  const onboardingToken = useOnboardingToken()

  if (providers === undefined || providers.length === 0) {
    return loadingSkeleton()
  }

  const providerIds = providers.map(({ name }) => name) as PlatformId[]

  const defaultProvider: PlatformId | undefined = isPlatformId(providerIds[0])
    ? providerIds[0]
    : undefined

  if (!defaultProvider) {
    return loadingSkeleton()
  }

  return (
    <StepLayout
      title={formatMessage(messages.title)}
      description={formatMessage(messages.subtitle)}
    >
      <CreateStackDeploymentEditorDependencies
        isOnboardingFlow={true}
        selectTemplate={selectTemplateByOnboardingToken(
          onboardingToken,
          selectTemplateWithSameCategory,
        )}
      >
        {(props: CreateEditorComponentConsumerProps) => renderCreateDeployment(props)}
      </CreateStackDeploymentEditorDependencies>
    </StepLayout>
  )

  function renderCreateDeployment(props: CreateEditorComponentConsumerProps) {
    const { editorState, deploymentTemplates, region } = props

    if (providers === undefined || region === undefined) {
      return null
    }

    const actualEditorState = insertSolutionType(editorState, solutionType)

    /*
     * FUTURE ENGINEER
     * That's the logic to show the skeleton in SelectOptions and AdvancedSettings
     *
     * cloud-ui/apps/monolith/modules/deployment-creation-components/SelectOptions.tsx
     * cloud-ui/apps/monolith/modules/deployment-creation-components/AdvancedSettings.tsx
     *
     * We had a requirement to only show the toggle when everything is rendering
     */
    const showChildren = actualEditorState.deploymentTemplate && deploymentTemplates && region.id

    return (
      <Fragment>
        <CreateDeploymentEditor
          {...props}
          editorState={actualEditorState}
          providerIds={providerIds}
          providers={providers}
          onDeploymentCreation={() => {
            const version = getUpsertVersion(editorState)
            const platform = getPlatform(region.id)
            return onDeploymentCreation({
              region,
              version: version ?? '',
              platform,
              hardware: editorState.deploymentTemplate?.name ?? '',
            })
          }}
        />
        {showChildren && children && <Fragment>{children}</Fragment>}
      </Fragment>
    )
  }

  function loadingSkeleton() {
    return (
      <StepLayout
        title={<EuiSkeletonRectangle width={300} height={27} />}
        description={<EuiSkeletonRectangle width={300} height={16} />}
        footer={<EuiSkeletonRectangle width={88} height={40} />}
      >
        <EuiFlexGroup>
          <EuiSkeletonRectangle width={196} height={38} />
          <EuiSkeletonRectangle width={88} height={38} />
        </EuiFlexGroup>
      </StepLayout>
    )
  }
}

export default CreateDeployment
