/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiEmptyPrompt, EuiButton, EuiTitle } from '@elastic/eui'

import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { deploymentVersionWithESHealthAPI } from '@/constants/esVersion'
import DocLink from '@/components/DocLink'

import type { FunctionComponent } from 'react'

interface Props {
  onUpgradeClick: () => void
  deploymentId: string
}

export const UpgradeDeploymentPrompt: FunctionComponent<Props> = ({
  onUpgradeClick,
  deploymentId,
}) => (
  <EuiEmptyPrompt
    data-test-subj='upgrade-deployment-prompt'
    iconType='monitoringApp'
    hasBorder={true}
    hasShadow={true}
    title={
      <h2>
        <FormattedMessage
          id='upgrade-deployment-prompt.title'
          defaultMessage={`Better health reporting in Elasticsearch ${deploymentVersionWithESHealthAPI} and later`}
        />
      </h2>
    }
    body={
      <p>
        <FormattedMessage
          id='upgrade-deployment-prompt.subtitle'
          defaultMessage='Learn how to detect and fix issues that impact your day-to-day operations.'
        />
      </p>
    }
    actions={
      <PermissionsGate
        permissions={[
          {
            type: 'deployment',
            action: 'upgrade',
            id: deploymentId,
          },
        ]}
      >
        {({ hasPermissions }) => (
          <EuiButton
            color='primary'
            fill={true}
            onClick={onUpgradeClick}
            disabled={!hasPermissions}
          >
            <FormattedMessage
              id='upgrade-deployment-prompt.upgrade-button-text'
              defaultMessage='Upgrade deployment'
            />
          </EuiButton>
        )}
      </PermissionsGate>
    }
    footer={
      <EuiTitle size='xxs'>
        <h3>
          <FormattedMessage
            id='upgrade-deployment-prompt.footer-text'
            defaultMessage='Want to learn more? {docLink}'
            values={{
              docLink: (
                <DocLink link='troubleshootingClusterIssues' showExternalLinkIcon={true}>
                  <FormattedMessage
                    id='upgrade-deployment-prompt.footer-doc-link'
                    defaultMessage='Read the docs'
                  />
                </DocLink>
              ),
            }}
          />
        </h3>
      </EuiTitle>
    }
  />
)
