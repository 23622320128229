/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiFlexGroupProps } from '@elastic/eui'
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'

import HorizontalSeparator from '@modules/access-management-components/HorizontalSeparator'

import GoogleSignUp from '../../CreateAccountForm/GoogleSignUp'
import MicrosoftSignUp from '../../CreateAccountForm/MicrosoftSignUp'

import type { FunctionComponent } from 'react'

type Props = {
  redirectTo?: string
  disabled?: boolean
  isFlowV2?: boolean
}

const OpenIdSignUp: FunctionComponent<Props> = ({ redirectTo, disabled, isFlowV2 }) => {
  const EuiFlexGroupProps: EuiFlexGroupProps = isFlowV2
    ? {
        alignItems: 'stretch',
        direction: 'column',
        gutterSize: 'm',
      }
    : { alignItems: 'center', gutterSize: 's' }

  return (
    <Fragment>
      {!isFlowV2 && (
        <Fragment>
          <EuiSpacer />
          <HorizontalSeparator
            label={
              <EuiText size='s'>
                <FormattedMessage
                  id='signup-form.or-sign-up-with'
                  defaultMessage='Or sign up with'
                />
              </EuiText>
            }
          />
          <EuiSpacer />
        </Fragment>
      )}

      <EuiFlexGroup {...EuiFlexGroupProps}>
        <EuiFlexItem>
          <GoogleSignUp
            fullText={!!isFlowV2}
            redirectTo={redirectTo}
            disabled={disabled}
            isCreateUser={true}
            isFlowV2={isFlowV2}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <MicrosoftSignUp
            fullText={!!isFlowV2}
            redirectTo={redirectTo}
            disabled={disabled}
            isCreateUser={true}
            isFlowV2={isFlowV2}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  )
}

export default OpenIdSignUp
