/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { parseError } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import history from '@modules/utils/history'
import { portalUrl } from '@modules/utils/rootUrls'

import MultipleOrganizationsError from './MultipleOrganizationsError'

import type { ReactElement } from 'react'
import type { AllProps as Props } from './types'

class AcceptOrganizationInvitation extends Component<Props> {
  componentDidMount(): void {
    const { search, userOrganizationId, invitationOrganizationId } = this.props

    if (userOrganizationId === undefined) {
      this.acceptInvitation()
      return
    }

    search()

    if (userOrganizationId === invitationOrganizationId) {
      this.addSuccessToast()
    }
  }

  componentWillUnmount(): void {
    this.props.resetAcceptOrganizationInvitationRequest()
  }

  render(): ReactElement | null {
    const { userOrganizationId, invitationOrganizationId, invitationOrganizationName } = this.props

    if (userOrganizationId === undefined) {
      return null
    }

    if (userOrganizationId !== invitationOrganizationId && invitationOrganizationName) {
      return <MultipleOrganizationsError organizationName={invitationOrganizationName} />
    }

    return null
  }

  acceptInvitation = (): void => {
    const { acceptOrganizationInvitation, search } = this.props

    acceptOrganizationInvitation()
      .then(this.addSuccessToast)
      .then(() => {
        history.replace(portalUrl())
      })
      .catch(this.addFailureToast)
      .finally(search)
  }

  addSuccessToast = (): void => {
    const { invitationOrganizationName } = this.props

    addToast({
      family: 'organization.accept-organization-invitation',
      color: 'success',
      title: (
        <FormattedMessage
          id='organization.accept-organization-invitation.success'
          defaultMessage='You successfully joined organization {invitationOrganizationName}.'
          values={{ invitationOrganizationName }}
        />
      ),
    })
  }

  addFailureToast = (error): void => {
    addToast({
      family: 'organization.accept-organization-invitation',
      color: 'danger',
      title: (
        <FormattedMessage
          id='organization.accept-organization-invitation.error'
          defaultMessage='Could not accept organization invite.'
        />
      ),
      text: parseError(error),
    })
  }
}

export default AcceptOrganizationInvitation
