/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import {
  TrialIntentOptionsType,
  ExperienceLevelOptionsType,
  UseCaseOptionsType,
  TrialIntentOptionsValueType,
  UseCaseOptionsValueType,
  ExperienceLevelOptionsValueType,
} from '@modules/discovery-questions-lib/utils'

export const experienceLevelOptions: Array<{
  value: ExperienceLevelOptionsValueType
  id: ExperienceLevelOptionsType
}> = [
  { value: ExperienceLevelOptionsValueType.new, id: ExperienceLevelOptionsType.new },
  {
    value: ExperienceLevelOptionsValueType.experienced,
    id: ExperienceLevelOptionsType.experienced,
  },
  { value: ExperienceLevelOptionsValueType.an_expert, id: ExperienceLevelOptionsType.an_expert },
]

export const useCaseOptions: Array<{
  value: UseCaseOptionsValueType
  id: UseCaseOptionsType
}> = [
  { value: UseCaseOptionsValueType.search, id: UseCaseOptionsType.search },
  { value: UseCaseOptionsValueType.observability, id: UseCaseOptionsType.observability },
  { value: UseCaseOptionsValueType.security, id: UseCaseOptionsType.security },
  { value: UseCaseOptionsValueType.something_else, id: UseCaseOptionsType.something_else },
]

export const trialIntentOptions: Array<{
  value: TrialIntentOptionsValueType
  id: TrialIntentOptionsType
}> = [
  { value: TrialIntentOptionsValueType.evaluate, id: TrialIntentOptionsType.evaluate },
  { value: TrialIntentOptionsValueType.migrate, id: TrialIntentOptionsType.migrate },
  { value: TrialIntentOptionsValueType.pricing_info, id: TrialIntentOptionsType.pricing_info },
  { value: TrialIntentOptionsValueType.learn, id: TrialIntentOptionsType.learn },
  {
    value: TrialIntentOptionsValueType.do_something_else,
    id: TrialIntentOptionsType.do_something_else,
  },
]
