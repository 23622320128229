/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLoadingSpinner, EuiSpacer, EuiText } from '@elastic/eui'

import type { AsyncRequestState } from '@modules/ui-types'
import Header from '@modules/cui/Header'
import type { SaasAuthResponse } from '@modules/cloud-api/v1/types'

import DeviceAuthentication from '../../../../components/DeviceAuthentication'
import { accountSecurityCrumbs } from '../../../../lib/crumbBuilder'

import type { MfaDevice } from '../../reducers/mfa/types'
import type { AsyncAction, MfaState } from '@/types/redux'

export type Props = {
  mfa: MfaState
  mfaEnabled: boolean | null
  mfaDevices: MfaDevice[] | null
  fetchMfaStatus: () => void
  enableMfa: () => void
  disableMfa: () => void
  fetchMfaDevices: () => void
  fetchMfaDevicesRequest: AsyncRequestState
  disableMfaDeviceRequest: AsyncRequestState
  enableMfaDeviceRequest: AsyncRequestState
  challengeSaasCurrentUserMfaFactor: (args: { device_id: string }) => Promise<any>
  verifySaasCurrentUserMfaFactorRequest: AsyncRequestState
  resetVerifySaasCurrentUserMfaFactorRequest: () => void
  verifySaasCurrentUserMfaFactor: (args: {
    device_id: string
    pass_code: string
  }) => Promise<AsyncAction<'VERIFY_CURRENT_USER_MFA_FACTOR', SaasAuthResponse>>
}

const title = <FormattedMessage id='uc.accountSecurity.heading' defaultMessage='Account security' />

class AccountSecurity extends Component<Props> {
  componentDidMount() {
    const { fetchMfaStatus, fetchMfaDevices } = this.props
    fetchMfaStatus()
    fetchMfaDevices()
  }

  render() {
    return (
      <div>
        <Header name={title} breadcrumbs={accountSecurityCrumbs()} />

        <EuiText>
          <FormattedMessage
            id='account-security.description'
            defaultMessage='Protect your Elasticsearch Service account, your data, and make sure that the right people have access.'
          />
        </EuiText>
        <EuiSpacer size='m' />
        {this.renderContent()}
      </div>
    )
  }

  renderContent = () => {
    const {
      disableMfa,
      disableMfaDeviceRequest,
      enableMfa,
      enableMfaDeviceRequest,
      fetchMfaDevicesRequest,
      mfaDevices,
      mfa,
      mfaEnabled,
      verifySaasCurrentUserMfaFactorRequest,
      challengeSaasCurrentUserMfaFactor,
      verifySaasCurrentUserMfaFactor,
      resetVerifySaasCurrentUserMfaFactorRequest,
    } = this.props

    if (fetchMfaDevicesRequest.inProgress) {
      return <EuiLoadingSpinner />
    }

    return (
      <Fragment>
        <EuiSpacer />

        <DeviceAuthentication
          mfa={mfa}
          mfaDevices={mfaDevices}
          enableMfa={enableMfa}
          disableMfa={disableMfa}
          challengeSaasCurrentUserMfaFactor={challengeSaasCurrentUserMfaFactor}
          verifySaasCurrentUserMfaFactorRequest={verifySaasCurrentUserMfaFactorRequest}
          resetVerifySaasCurrentUserMfaFactorRequest={resetVerifySaasCurrentUserMfaFactorRequest}
          verifySaasCurrentUserMfaFactor={verifySaasCurrentUserMfaFactor}
          mfaEnabled={mfaEnabled}
          disableMfaDeviceRequest={disableMfaDeviceRequest}
          enableMfaDeviceRequest={enableMfaDeviceRequest}
        />
      </Fragment>
    )
  }

  toggleMfa = () => {
    if (this.props.mfaEnabled) {
      this.props.disableMfa()
    } else {
      this.props.enableMfa()
    }
  }
}

export default AccountSecurity
