/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import AppLoadingRoot from '@/components/AppLoadingRoot'

import type { AllProps as Props } from './types'
import type { ReactElement } from 'react'

class PartnerSignupLogin extends PureComponent<Props> {
  componentDidMount(): void {
    const { history, loginUrl } = this.props
    const {
      location: { state: locationState },
    } = history

    if (typeof locationState?.hasExistingSubscription === 'undefined') {
      history.replace(loginUrl, {
        hasExistingSubscription: true,
      })
    }
  }

  render(): ReactElement {
    return <AppLoadingRoot />
  }
}

export default PartnerSignupLogin
