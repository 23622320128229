/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { toNumber } from 'lodash'

import type {
  ApmInfo,
  ApmPlansInfo,
  ClusterInstanceInfo,
  DeploymentGetResponse,
} from '@modules/cloud-api/v1/types'
import type {
  ApmCluster,
  ApmClusterInstance,
  ApmId,
  ApmMetadata,
  RegionId,
  Url,
} from '@modules/ui-types'

import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'

import stringify from '../../lib/stringify'
import { isTopologySized } from '../../lib/deployments/deployment'

import createPlanMessages from './createPlanMessages'

const createInstanceDisplayName = (rawId) => `Instance #${parseInt(rawId.split(`-`).pop(), 10)}`

function createInstance(clusterId: string, instance: ClusterInstanceInfo): ApmClusterInstance {
  return {
    clusterId,
    displayName: createInstanceDisplayName(instance.instance_name),
    name: instance.instance_name,
    capacity: {
      memory: instance.memory ? instance.memory.instance_capacity : 0,
    },
    allocator: {
      id: instance.allocator_id,
      zone: instance.zone,
    },
    status: {
      isStarted: instance.service_running,
      inMaintenanceMode: instance.maintenance_mode,
      nativeFillPercentage: toNumber(instance.memory?.native_memory_pressure ?? 0),
    },
    version: instance.service_version,
    instanceConfig: {
      id: instance.instance_configuration?.id,
      name: instance.instance_configuration?.name,
      resource: instance.instance_configuration?.resource,
    },
  }
}

function createHrefs(selfUrl) {
  const apmPlan = `${selfUrl}/plan`
  const attempts = `${apmPlan}/attempts`

  return {
    'apm-plan': apmPlan,
    'apm-plan-attempts': attempts,
    upgrade: `${selfUrl}/_upgrade`,
  }
}

export default function createApm({
  regionId: consumerRegionId,
  apmId,
  selfUrl,
  source,
  stackDeployment,
}: {
  regionId: RegionId
  apmId: ApmId
  selfUrl: Url
  source: ApmInfo
  stackDeployment?: DeploymentGetResponse
}): ApmCluster {
  const {
    metadata,
    plan_info,
    topology,
    elasticsearch_cluster,
    healthy,
    external_links: externalLinks,
  } = source

  const regionId = stackDeployment ? getRegionId({ deployment: stackDeployment }) : consumerRegionId

  const { instances } = topology

  const currentPlan = plan_info.current?.plan
  const pendingPlan = plan_info.pending?.plan
  const clusterTopology = currentPlan?.cluster_topology ?? []

  const currentVersion = currentPlan?.apm.version
  const pendingVersion = pendingPlan?.apm.version

  const isPending = pendingVersion != null

  const planMessages = createPlanMessages(
    (isPending ? plan_info.pending?.plan_attempt_log : plan_info.current?.plan_attempt_log) ?? [],
  )
  const runningInstances = instances.filter((instance) => instance.service_running).length
  const notRunningInstances = instances.filter((instance) => !instance.service_running).length
  const planAttemptId = getPlanAttemptId(plan_info)
  const isStopped = runningInstances === 0 || !isTopologySized(clusterTopology)
  const isHidden = Boolean(metadata?.raw?.hidden ?? false)

  const secretToken =
    plan_info.pending?.plan?.apm.system_settings?.secret_token ??
    plan_info.current?.plan?.apm.system_settings?.secret_token

  return {
    id: apmId,
    regionId,
    clusterId: elasticsearch_cluster.elasticsearch_id,
    stackDeploymentId: stackDeployment ? stackDeployment.id : null,
    displayName: apmId,
    healthy,
    isHidden,
    isInitializing: isInitializing(source),
    isStopped,
    isStopping: isStopping(source),
    isRestarting: isRestarting(source),
    isForceRestarting: isForceRestarting(plan_info),
    plan: {
      planAttemptId,
      version: currentVersion,
      healthy: plan_info.healthy,

      // Versions are optional (taken from ES if not set), so if one doesn't exist we just make sure it isn't stopped
      isActive: currentVersion != null || !isStopped,
      isPending,
      waitingForPending: false,
      status: {
        messages: planMessages,
      },
      pending: {
        _source: isPending && plan_info.pending ? stringify(plan_info.pending.plan) : ``,
      },
    },
    instances: {
      healthy: topology.healthy,
      count: {
        total: runningInstances + notRunningInstances,
        notRunning: notRunningInstances,
        running: runningInstances,
      },
      record: topology.instances.map((instance) => createInstance(apmId, instance)),
    },
    _raw: {
      // TODO: We ought to store the whole metadata here, as it contains other information such as the data's version number
      data: metadata?.raw ?? ({} as ApmMetadata),
      plan: plan_info.current && plan_info.current.plan ? plan_info.current.plan : {},
      pendingPlan: plan_info.pending && plan_info.pending.plan ? plan_info.pending.plan : {},
      pendingSource:
        plan_info.pending && plan_info.pending.source ? plan_info.pending.source : null,
    },
    hrefs: createHrefs(selfUrl),
    kind: `apm`,
    externalLinks,
    secretToken,
  }
}

function isStopping(source) {
  if (!source.plan_info.pending) {
    return false
  }

  if (source.status === `stopping`) {
    return true
  }

  const clusterTopology = source.plan_info.pending.plan.cluster_topology ?? []

  return !isTopologySized(clusterTopology)
}

function isRestarting(source) {
  return source.status === `restarting`
}

function isInitializing(source) {
  return source.status === `initializing`
}

function isForceRestarting(planInfo: ApmPlansInfo) {
  const reboot = planInfo.pending?.plan?.transient?.plan_configuration?.cluster_reboot
  return reboot === `forced`
}

function getPlanAttemptId(planInfo) {
  if (planInfo.current) {
    return planInfo.current.plan_attempt_id
  }

  if (planInfo.pending) {
    return planInfo.pending.plan_attempt_id
  }

  return null
}
