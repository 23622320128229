/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { Step } from '@modules/discovery-questions-lib/steps'
import { OnboardingToken } from '@modules/discovery-questions-lib/steps'

import { UseCaseOptionsType } from '../discovery-questions-lib/utils'

import type { ResourceType } from '../discovery-questions-lib/utils'
import type { ProjectType } from '../ui-types/projects'

export const getStepUrl = ({
  resourceType,
  step,
  onboardingToken,
  force = false,
}: {
  resourceType: ResourceType
  step: Step
  onboardingToken?: string
  force?: boolean
}) =>
  `/onboarding/${resourceType}/${step}/${
    onboardingToken ? `?onboarding_token=${onboardingToken}${force ? '&force=true' : ''}` : ''
  }`

export const getProjectTypeFromUseCase = (
  use_case: UseCaseOptionsType | undefined,
): ProjectType | undefined => {
  if (!use_case) {
    return undefined
  }

  if (use_case !== 'search') {
    return use_case as ProjectType
  }

  return 'elasticsearch'
}

export const getUseCaseFromProjectType = (projectType: ProjectType): UseCaseOptionsType => {
  if (projectType === 'elasticsearch') {
    return UseCaseOptionsType.search
  } else if (projectType === 'observability') {
    return UseCaseOptionsType.observability
  }

  return UseCaseOptionsType.security
}

const boardingTokenAdapter = new Map<OnboardingToken | string, OnboardingToken>([
  [OnboardingToken.General, OnboardingToken.GeneralPurpose],
  [OnboardingToken.GeneralPurpose, OnboardingToken.General],
  [OnboardingToken.Vector, OnboardingToken.VectorSearch],
  [OnboardingToken.VectorSearch, OnboardingToken.Vector],
  [OnboardingToken.ServerlessPlayground, OnboardingToken.Playground],
  [OnboardingToken.Playground, OnboardingToken.ServerlessPlayground],
  [OnboardingToken.APM, OnboardingToken.APM],
  [OnboardingToken.Observability, OnboardingToken.Observability],
  [OnboardingToken.Security, OnboardingToken.Security],
])

// Function to convert Serverless Token to Stateful Token and/or vice-versa
export const convertOnboardingToken = (token: string): OnboardingToken | boolean =>
  boardingTokenAdapter.get(token) ?? false
