/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'

import { EuiIcon } from '@elastic/eui'

import { getPrettyProjectType } from '@modules/project-lib/display'
import type { AnyProject } from '@modules/project-api/types'

const ProjectTypeIconAndLabel = ({ type }: { type: AnyProject['type'] }) => (
  <Fragment>
    <EuiIcon type={`logo${capitalize(type)}`} />
    <FormattedMessage {...getPrettyProjectType(type)} />
  </Fragment>
)

export default ProjectTypeIconAndLabel
