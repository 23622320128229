/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiCallOut,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import SpinButton from '../../../SpinButton'

import type { AllProps as Props, State } from './types'

const labels = {
  email: (
    <FormattedMessage
      id='organization.add-member.button.modal.form-label'
      defaultMessage='User ID'
    />
  ),
}

class AddMemberButton extends Component<Props> {
  state: State = {
    isModalOpen: false,
    userId: ``,
  }

  render() {
    if (!this.state.isModalOpen) {
      return (
        <PermissionsGate
          permissions={[
            {
              type: 'organization-members',
              action: 'create',
            },
          ]}
        >
          {({ hasPermissions }) => (
            <EuiButton
              disabled={!hasPermissions}
              onClick={this.showModal}
              data-test-id='add-member-button'
            >
              <FormattedMessage
                id='organization.add-member.button.open-modal'
                defaultMessage='Add Member'
              />
            </EuiButton>
          )}
        </PermissionsGate>
      )
    }

    return this.renderModal()
  }

  renderModal = () => {
    const { addOrganizationMemberRequest } = this.props
    const submitRequest = addOrganizationMemberRequest()
    return (
      <EuiModal onClose={this.closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <FormattedMessage
              id='organization.add-member.button.modal.title'
              defaultMessage='Add Member'
            />
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiText>
            <FormattedMessage
              id='organization.add-member.button.modal.description'
              defaultMessage="As an administrator, you can manually add a user to this organization using the user's ID."
            />
          </EuiText>
          <EuiSpacer />
          <EuiFormRow id='organization.add-member' label={labels.email}>
            <EuiFieldText
              value={this.state.userId}
              data-test-id='add-organization-member-textfield'
              onChange={(e) => this.setState({ userId: e.target.value })}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiFlexGroup gutterSize='xs' direction='column' responsive={false}>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent='flexEnd'>
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={this.closeModal}>
                    <FormattedMessage
                      id='organization.add-member.button.modal.close'
                      defaultMessage='Close'
                    />
                  </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <SpinButton
                    fill={true}
                    onClick={this.addUser}
                    spin={submitRequest.inProgress}
                    data-test-id='add-organization-member-confirm'
                  >
                    <FormattedMessage
                      id='organization.add-member.button.modal.add'
                      defaultMessage='Add User'
                    />
                  </SpinButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            {submitRequest.error && (
              <EuiFlexItem>
                <CuiAlert type='danger'>{submitRequest.error}</CuiAlert>
              </EuiFlexItem>
            )}
            {submitRequest.isDone && !submitRequest.inProgress && !submitRequest.error && (
              <EuiFlexItem>
                <EuiCallOut color='success' iconType='user'>
                  <FormattedMessage
                    id='organization.add-member.button.modal.success'
                    defaultMessage='User added successfully. You can add another user or close this modal.'
                  />
                </EuiCallOut>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  addUser = () => {
    const { organizationId, addOrganizationMembers } = this.props
    addOrganizationMembers({ userId: this.state.userId, organizationId })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  showModal = () => {
    this.setState({ isModalOpen: true })
  }
}

export default AddMemberButton
