/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'
import { getSnapshotStatus } from '@/reducers/snapshotStatus'
import { getSnapshotRestore, getClusterSnapshotByName } from '@/reducers/clusterSnapshots'
import { restoreSnapshotRequest, fetchSnapshotsRequest } from '@/reducers/asyncRequests/registry'

import {
  fetchSnapshots,
  fetchSnapshotStatus,
  restoreSnapshot,
  resetRestoreSnapshot,
} from '../../../../actions/snapshots'

import SnapshotRestoreFlyout from './SnapshotRestoreFlyout'

const terminalSnapshotStates = [`ABORTED`, `SUCCESS`, `FAILED`, `PARTIAL`]

const mapStateToProps = (state: ReduxState, { deployment, snapshotName }) => {
  const snapshot = getClusterSnapshotByName(state, deployment.id, snapshotName)
  const snapshotStatus = getSnapshotStatus(state, deployment.id, snapshotName)
  const isInProgress = snapshotStatus && !terminalSnapshotStates.includes(snapshotStatus.state)

  return {
    deployment,
    snapshotName,
    snapshot,
    snapshotStatus,
    restore: getSnapshotRestore(state, deployment.id),
    restoreSnapshotRequest: restoreSnapshotRequest(state, deployment.id),
    fetchSnapshotsRequest: fetchSnapshotsRequest(state, deployment.id),
    isInProgress,
  }
}

const mapDispatchToProps = (dispatch, { deployment, snapshotName }) => ({
  fetchSnapshots: () => dispatch(fetchSnapshots({ deployment })),
  fetchSnapshotStatus: () => dispatch(fetchSnapshotStatus({ deployment, snapshotName })),
  restoreSnapshot: (payload) => dispatch(restoreSnapshot({ deployment, snapshotName, payload })),
  resetRestoreSnapshot: () => dispatch(resetRestoreSnapshot(deployment.id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotRestoreFlyout)
