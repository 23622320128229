/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { uniqBy, sortBy, cloneDeep, isEmpty } from 'lodash'
import { defineMessages } from 'react-intl'

import type {
  ElasticsearchPayload,
  AccountTrustRelationship,
  CertificateAuthority,
  DeploymentUpdateRequest,
  DirectTrustRelationship,
  ElasticsearchClusterTrustSettings,
  ExternalTrustRelationship,
  TrustRelationshipGetResponse,
} from '@modules/cloud-api/v1/types'
import type { AnyTrustRelationship, StackDeployment } from '@modules/ui-types'

import { getOrganizationId } from '@/lib/stackDeployments/selectors/metadata'
import {
  getDeploymentSettingsFromGet,
  getFirstEsClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'

import { getElasticsearchPayloadFromResource } from './conversions'

import type { MessageDescriptor } from 'react-intl'
import type { TrustLevel } from './selectors/crossClusterReplication'
import type { ApiKey } from '@/components/DeploymentTrustRelationships/types'

export const trustLevelShortLabels: Record<TrustLevel, MessageDescriptor> = defineMessages({
  all: {
    id: `deploymentTrustManagement.shortTrustLevelLabels.all`,
    defaultMessage: `All deployments`,
  },
  specific: {
    id: `deploymentTrustManagement.shortTrustLevelLabels.specific`,
    defaultMessage: `Specific deployments`,
  },
  none: {
    id: `deploymentTrustManagement.shortTrustLevelLabels.none`,
    defaultMessage: `None`,
  },
})

export function getTrustLevelFromRelationship(trustRelationship: AnyTrustRelationship): TrustLevel {
  if (trustRelationship.trust_allowlist?.length) {
    return `specific`
  }

  return trustRelationship.trust_all ? `all` : `none`
}

export function addApiKeysToDeployment({
  payload,
  apiKeys,
}: {
  payload: DeploymentUpdateRequest
  apiKeys: ApiKey[]
}): DeploymentUpdateRequest {
  const secrets = {}

  apiKeys.forEach((apiKey) => {
    secrets[apiKey.settingName] = {
      value: apiKey.secret,
      as_file: false,
    }
  })

  if (payload.resources && payload.resources.elasticsearch && payload.resources.elasticsearch[0]) {
    payload.resources.elasticsearch[0].settings ??= {}
    payload.resources.elasticsearch[0].settings.keystore_contents = { secrets }
  }

  return payload
}

export function createUpdateTrustRequestFromGetResponse({
  deployment,
  trustRelationships,
  type,
  replace = false,
}: {
  deployment: StackDeployment
  trustRelationships: AnyTrustRelationship[]
  type: keyof ElasticsearchClusterTrustSettings
  replace?: boolean
}): DeploymentUpdateRequest {
  const esResource = getFirstEsClusterFromGet({ deployment })

  if (!esResource) {
    throw new Error('No Elasticsearch cluster found.')
  }

  const esPayload =
    cloneDeep(getElasticsearchPayloadFromResource({ resource: esResource })) ??
    ({} as ElasticsearchPayload)

  const updatedTrustRelationships: typeof trustRelationships = replace
    ? trustRelationships
    : uniqBy(
        [...trustRelationships, ...(esResource.info.settings?.trust?.[type] || [])],
        { accounts: 'account_id', external: 'trust_relationship_id' }[type] || 'uid',
      )

  // sanitize for the API
  updatedTrustRelationships.forEach((trustRelationship) => {
    if (type === 'direct') {
      const directTrustRelationship = trustRelationship as DirectTrustRelationship

      // `additional_node_names` not supported for ESS/ECE since they only use Elastic Cloud schema
      if (directTrustRelationship.type !== 'generic') {
        delete directTrustRelationship.additional_node_names
      }

      // omit the `allowlist` property entirely if empty
      if (!directTrustRelationship.trust_allowlist?.length) {
        delete directTrustRelationship.trust_allowlist
      }

      // omit the `scope_id` property entirely if empty
      if (!directTrustRelationship.scope_id) {
        delete directTrustRelationship.scope_id
      }
    }
  })

  esPayload.settings ??= {}
  esPayload.settings.trust = cloneDeep(esResource.info.settings?.trust || {})

  // @ts-ignore we haven't modelled the link between `type` and `AnyTrustRelationship`
  esPayload.settings.trust[type] = updatedTrustRelationships

  const payload: DeploymentUpdateRequest = {
    prune_orphans: false,
    resources: {
      elasticsearch: [esPayload],
    },
  }

  return payload
}

export function getDirectTrustRelationships({
  deployment,
}: {
  deployment: StackDeployment
}): DirectTrustRelationship[] {
  const settings = getDeploymentSettingsFromGet({ deployment })
  return settings?.trust?.direct || []
}

export function getExternalTrustRelationships({
  deployment,
}: {
  deployment: StackDeployment
}): ExternalTrustRelationship[] {
  const settings = getDeploymentSettingsFromGet({ deployment })
  return settings?.trust?.external || []
}

export function getAccountTrustRelationships({
  deployment,
}: {
  deployment: StackDeployment
}): AccountTrustRelationship[] {
  const orgId = getOrganizationId({ deployment })

  if (!orgId) {
    return [] // sanity
  }

  const settings = getDeploymentSettingsFromGet({ deployment })
  const relationships = settings?.trust?.accounts || []

  return sortBy(
    relationships,
    (trustRelationship) =>
      // keep own-account relationship on top
      !isOwnAccountRelationship(trustRelationship, orgId),
  )
}

function findTrustRelationshipById(
  trustRelationships: TrustRelationshipGetResponse[],
  trustRelationshipId: string,
): TrustRelationshipGetResponse | null {
  return (
    trustRelationships.find(({ id, installation_id }) =>
      // ID is for external relationships, and the installation_id matches the local env entry
      [id, installation_id?.replace(/\-/g, ``)].includes(trustRelationshipId),
    ) || null
  )
}

export function getTrustRelationshipDisplayName(
  trustRelationship: AnyTrustRelationship,
  environmentTrustRelationships: TrustRelationshipGetResponse[],
): string {
  const id = getTrustRelationshipId({ trustRelationship })

  return (
    (trustRelationship as DirectTrustRelationship)?.name ||
    findTrustRelationshipById(environmentTrustRelationships, id)?.name ||
    id
  )
}

// system-relevant unique ID
export function getTrustRelationshipId({
  trustRelationship,
}: {
  trustRelationship: AnyTrustRelationship
}): string {
  if (isAccountRelationship(trustRelationship)) {
    return trustRelationship.account_id
  }

  if (isExternalRelationship(trustRelationship)) {
    return trustRelationship.trust_relationship_id
  }

  return trustRelationship.uid || ''
}

// user-relevant ID
export function getTrustRelationshipTargetEnvironmentId({
  trustRelationship,
}: {
  trustRelationship: AnyTrustRelationship
}): string {
  if (isAccountRelationship(trustRelationship)) {
    return trustRelationship.account_id
  }

  if (isExternalRelationship(trustRelationship)) {
    return trustRelationship.trust_relationship_id
  }

  return trustRelationship.scope_id || ''
}

export function getTrustRelationshipType(
  trustRelationship: AnyTrustRelationship,
): keyof ElasticsearchClusterTrustSettings {
  if (isAccountRelationship(trustRelationship)) {
    return 'accounts'
  }

  if (isExternalRelationship(trustRelationship)) {
    return 'external'
  }

  return 'direct'
}

export function getTrustRelationshipsFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: 'accounts'
}): AccountTrustRelationship[]
export function getTrustRelationshipsFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: 'direct'
}): DirectTrustRelationship[]
export function getTrustRelationshipsFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: 'external'
}): ExternalTrustRelationship[]
export function getTrustRelationshipsFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: keyof ElasticsearchClusterTrustSettings
}): AnyTrustRelationship[]
export function getTrustRelationshipsFromDeployment({
  deployment,
  trustRelationshipType,
}: {
  deployment: StackDeployment
  trustRelationshipType: keyof ElasticsearchClusterTrustSettings
}): AnyTrustRelationship[] {
  const settings = getDeploymentSettingsFromGet({ deployment })
  return settings?.trust?.[trustRelationshipType] || []
}

export function getTrustRelationshipFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: 'accounts'
  trustRelationshipId: string
}): AccountTrustRelationship | null
export function getTrustRelationshipFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: 'direct'
  trustRelationshipId: string
}): DirectTrustRelationship | null
export function getTrustRelationshipFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: 'external'
  trustRelationshipId: string
}): ExternalTrustRelationship | null
export function getTrustRelationshipFromDeployment(args: {
  deployment: StackDeployment
  trustRelationshipType: keyof ElasticsearchClusterTrustSettings
  trustRelationshipId: string
}): AnyTrustRelationship | null
export function getTrustRelationshipFromDeployment({
  deployment,
  trustRelationshipType,
  trustRelationshipId,
}: {
  deployment: StackDeployment
  trustRelationshipType: keyof ElasticsearchClusterTrustSettings
  trustRelationshipId: string
}): AnyTrustRelationship | null {
  const settings = getDeploymentSettingsFromGet({ deployment })
  const trustRelationshipsOfType: AnyTrustRelationship[] =
    settings?.trust?.[trustRelationshipType] || []
  const foundTrustRelationship = trustRelationshipsOfType.find(
    (trustRelationship) => getTrustRelationshipId({ trustRelationship }) === trustRelationshipId,
  )
  return foundTrustRelationship || null
}

export function isAccountRelationship(
  trustRelationship?: AnyTrustRelationship,
): trustRelationship is AccountTrustRelationship {
  return Boolean(trustRelationship?.hasOwnProperty(`account_id`))
}

export function isExternalRelationship(
  trustRelationship?: AnyTrustRelationship,
): trustRelationship is ExternalTrustRelationship {
  return Boolean(trustRelationship?.hasOwnProperty(`trust_relationship_id`))
}

export function isApiKeysRelationship(trustRelationship?: AnyTrustRelationship): boolean {
  if (!trustRelationship) {
    return false
  }

  const trustRelationshipDirect = trustRelationship as DirectTrustRelationship

  if (trustRelationshipDirect.type === `proxy`) {
    return true
  }

  if (
    trustRelationshipDirect.type === `generic` &&
    trustRelationshipDirect.trust_all === false &&
    isEmpty(trustRelationshipDirect.additional_node_names) &&
    isEmpty(trustRelationshipDirect.scope_id)
  ) {
    return true
  }

  return false
}

export function isDirectRelationship(
  trustRelationship?: AnyTrustRelationship,
): trustRelationship is DirectTrustRelationship {
  return Boolean(
    !isAccountRelationship(trustRelationship) && !isExternalRelationship(trustRelationship),
  )
}

export function isOwnAccountRelationship(
  trustRelationship: AnyTrustRelationship,
  orgId: string | null,
): trustRelationship is AccountTrustRelationship {
  if (!isAccountRelationship(trustRelationship)) {
    return false
  }

  return Boolean(trustRelationship.account_id === orgId)
}

export function getDirectTrustRelationshipTrustRestrictionSettings(
  trustRelationship: DirectTrustRelationship,
  certificateAuthority: CertificateAuthority | undefined,
): string {
  const subjectNames: string[] = []

  if (certificateAuthority?.recommended_trust_restriction) {
    subjectNames.push(certificateAuthority.recommended_trust_restriction)
  }

  if (trustRelationship.trust_all) {
    subjectNames.push(`*.node.*.cluster.${trustRelationship.scope_id}.account`)
  } else {
    trustRelationship.trust_allowlist?.forEach((clusterId) => {
      subjectNames.push(`*.node.${clusterId}.cluster.${trustRelationship.scope_id}.account`)
    })
  }

  if (trustRelationship.additional_node_names) {
    subjectNames.push(...trustRelationship.additional_node_names)
  }

  const lines = [
    'trust.subject_name:',
    ...subjectNames.map((subjectName) => `  - "${subjectName}"`),
  ]

  return lines.join('\n')
}
