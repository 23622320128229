/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import type { PaymentDetails } from '@modules/billing-api/customTypes'
import { startPageActions } from '@modules/utils/apm'

import { isMarketPlaceUser } from '@/lib/marketPlace'

import BillingStatements from './BillingStatements'
import BillingInvoices from './BillingInvoices'
import BillingHistoryPanel from './BillingHistoryPanel'
import BillingHistoryEmptyPrompt from './BillingHistoryEmptyPrompt'
import MarketplaceUserContent from './MarketplaceUserContent'

import type { GetPaymentDetails } from '@/apps/userconsole/actions/billing/paymentDetails'
import type { ReactElement } from 'react'
import type { AllProps } from './types'

class BillingHistory extends Component<AllProps> {
  // This component manages its own page transactions because it interweaves
  // AWS and non-AWS behaviour, making it unsuitable to use with `withLoading`.
  // Probably this should be two separate components.
  startedTransaction = false

  paymentDetails = {} as PaymentDetails

  componentDidMount(): void {
    const { getPaymentDetails, fetchBillingHistory, profile } = this.props

    if (!profile) {
      return
    }

    const { organization_id } = profile

    fetchBillingHistory({
      organizationId: organization_id!,
    })

    getPaymentDetails({
      organization_id,
    } as GetPaymentDetails).then(({ payload }) => {
      this.paymentDetails = payload as PaymentDetails
    })
  }

  componentDidUpdate(): void {
    if (!this.startedTransaction) {
      this.startedTransaction = true
      startPageActions(`Billing history`)
    }
  }

  render(): ReactElement | null {
    const { fetchBillingHistoryRequest, getPaymentDetailsRequest, profile, billingHistory } =
      this.props

    const error = fetchBillingHistoryRequest.error || getPaymentDetailsRequest.error
    const inProgress = fetchBillingHistoryRequest.inProgress || getPaymentDetailsRequest.inProgress

    if (!profile) {
      return null
    }

    if (error) {
      return (
        <BillingHistoryPanel>
          <CuiAlert type='error'>{error}</CuiAlert>
        </BillingHistoryPanel>
      )
    }

    if (typeof billingHistory === 'undefined') {
      return <EuiSkeletonText />
    }

    const { statements, invoices } = billingHistory
    const hasStatements = statements.length > 0
    const hasInvoices = invoices.length > 0

    return (
      <Fragment>
        {isMarketPlaceUser(profile) && (
          <Fragment>
            <MarketplaceUserContent
              domain={profile.domain}
              hideTitle={hasStatements || hasInvoices}
            />
            <EuiSpacer />
          </Fragment>
        )}

        {!hasStatements && !hasInvoices && (
          <BillingHistoryEmptyPrompt profile={profile} paymentDetails={this.paymentDetails} />
        )}

        {hasStatements && (
          <Fragment>
            <BillingStatements
              loading={inProgress}
              statements={statements}
              title={
                hasStatements && hasInvoices ? (
                  <FormattedMessage
                    id='billing-statements.statements.heading'
                    defaultMessage='Paid by balance'
                  />
                ) : undefined
              }
            />
            <EuiSpacer />
          </Fragment>
        )}

        {hasInvoices && (
          <BillingInvoices
            invoices={invoices}
            loading={inProgress}
            title={
              hasStatements && hasInvoices ? (
                <FormattedMessage
                  id='billing-statements.statements.heading'
                  defaultMessage='Paid by balance'
                />
              ) : undefined
            }
          />
        )}
      </Fragment>
    )
  }
}

export default BillingHistory
