/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { css } from '@emotion/react'
import React, { useContext, useEffect, useRef } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { parse as parseQuery, stringify } from 'query-string'
import { useLocation } from 'react-router'

import { EuiButton, EuiSpacer } from '@elastic/eui'
import { withTransaction } from '@elastic/apm-rum-react'

import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import type { AsyncRequestError } from '@modules/ui-types'
import { AjaxRequestError } from '@modules/ui-types'
import { asAjaxRequestError } from '@modules/utils/ajax'
import AuthContext from '@modules/auth/context'
import { hasRedirectOnMount, redirectOnMount } from '@modules/auth/auth'

import AppLoadingRoot from '@/components/AppLoadingRoot'
import { getSignInQueryData } from '@/lib/urlUtils'

import TermsOfService from '../CreateAccountForm/TermsOfService'
import NotFound from '../ErrorPages/NotFound'

import { SsoError } from './SsoError'
import OrganizationIdpMatching from './OrganizationIdpMatching'
import BackToLoginButton from './OrganizationIdpMatching/BackToLoginButton'

import type { AllProps as Props } from './types'
import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement } from 'react'

const messages = defineMessages({
  login: {
    id: `cloud-sso-page-default-title`,
    defaultMessage: `Log in to your account with SSO`,
  },
  loginButton: {
    id: `cloud-sso-page.login-button`,
    defaultMessage: `Log in with SSO`,
  },
})

function isNonRedirectError(error) {
  if (error instanceof AjaxRequestError) {
    return error.response.type !== `opaqueredirect`
  }

  return true
}

const UserconsoleSso: React.FC<Props & WrappedComponentProps> = (props) => {
  const authContext = useContext(AuthContext)
  const {
    exchangeSsoRequest: {
      error: exchangeSsoError,
      inProgress: exchangeSsoInProgress,
      isDone: exchangeSsoDone,
    },
    initiateSsoRequest: { error: initiateSsoError, inProgress: initiateSsoInProgress },
    history: {
      location: { state: locationState },
    },
    idToken,
    loginIdentifier,
    initiateSsoCheck,
    initiateSsoRedirect,
    exchangeIdToken,
    resetInitiateSsoCheck,
    resetExchangeIdToken,
    state,
    oktaError,
    redirectAfterLogin,
  } = props
  const { search } = useLocation()
  const signInQueryData = getSignInQueryData(search)
  const redirectTo = signInQueryData.redirectTo ?? signInQueryData.fromURI

  const isLoading = initiateSsoInProgress || exchangeSsoInProgress
  const mounted = useRef(false)

  useEffect(() => {
    if (hasRedirectOnMount(null)) {
      redirectOnMount({ redirectAfterLogin, redirectTo, newBearerToken: null })
      return
    }

    onLoadInitiateUrl()
    onLoadExchangeIdToken()
  }, [])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }

    resetRequests()
  }, [loginIdentifier, idToken])

  function onLoadInitiateUrl() {
    if (loginIdentifier) {
      initiateSsoCheck({
        redirectUri: `${location.protocol}//${location.host}/login/sso`,
        state: stringify({
          redirectTo,
          hasAcceptedTermsAndPolicies: true,
          loginIdentifier,
        }),
        loginIdentifier,
      })
    }
  }

  function onClickInitiateUrl(loginIdentifierInput?: string) {
    const _loginIdentifier = loginIdentifierInput || loginIdentifier

    if (_loginIdentifier) {
      authContext.setAuthContext({ method: 'byoidp' })
      initiateSsoRedirect({
        redirectUri: `${location.protocol}//${location.host}/login/sso`,
        state: stringify({
          redirectTo,
          hasAcceptedTermsAndPolicies: true,
          loginIdentifier: _loginIdentifier,
        }),
        loginIdentifier: _loginIdentifier,
      })
    }
  }

  function onLoadExchangeIdToken() {
    const parsedState = parseQuery(String(state))
    const redirectToPath = typeof parsedState.redirectTo === `string` ? parsedState.redirectTo : '/'
    const hasAcceptedTermsAndPolicies = parsedState.hasAcceptedTermsAndPolicies === 'true' || false

    if (idToken) {
      exchangeIdToken(idToken, redirectToPath, hasAcceptedTermsAndPolicies)
    }
  }

  function resetRequests() {
    resetInitiateSsoCheck()
    resetExchangeIdToken()
  }

  if (initiateSsoError && isNonRedirectError(initiateSsoError)) {
    const errorCodes = asAjaxRequestError(initiateSsoError)?.body?.errors?.map((err) => err.code)

    if (errorCodes && errorCodes.length > 0 && errorCodes[0] === 'auth.no_org_for_login_id') {
      return <NotFound data-test-id='sso-org-not-found' withHttpErrorPage={false} />
    }
  }

  const error: AsyncRequestError | undefined =
    oktaError ??
    (initiateSsoError && isNonRedirectError(initiateSsoError) ? initiateSsoError : exchangeSsoError)

  if (!loginIdentifier && !idToken && !error) {
    return (
      <OrganizationIdpMatching
        onClickInitiateUrl={onClickInitiateUrl}
        data-test-id='organization-idp-matching'
      />
    )
  }

  if (
    initiateSsoInProgress ||
    (!oktaError &&
      (exchangeSsoInProgress ||
        (exchangeSsoDone && !exchangeSsoError) ||
        (!exchangeSsoDone && !!idToken && !exchangeSsoError)))
  ) {
    return <AppLoadingRoot />
  }

  return (
    <LandingPageOuterContainer
      pageContext={{
        name: 'login',
        contextSwitchLocationState: locationState,
      }}
      isFlowV2={true}
    >
      <LandingPageInnerContainer
        title={<FormattedMessage data-test-id={messages.login.id} {...messages.login} />}
        panelProps={{
          'aria-live': 'polite',
        }}
        panelCssOverride={css({ maxWidth: '430px' })}
      >
        {renderBody()}
      </LandingPageInnerContainer>
    </LandingPageOuterContainer>
  )

  function renderBody(): ReactElement {
    if (error) {
      return (
        <React.Fragment>
          <EuiSpacer size='m' />
          <SsoError error={error} redirectTo={redirectTo} state={state} />
          <EuiSpacer size='m' />
          <BackToLoginButton
            onClick={() => {
              resetRequests()
            }}
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <TermsOfService />
        <EuiSpacer size='m' />
        <EuiButton
          fill={true}
          fullWidth={true}
          data-test-id='sso-login-button'
          onClick={() => onClickInitiateUrl()}
          isLoading={isLoading}
        >
          <FormattedMessage {...messages.loginButton} />
        </EuiButton>
        <EuiSpacer size='m' />
        <BackToLoginButton />
      </React.Fragment>
    )
  }
}

export default withTransaction(`UserconsoleSso`, `component`)(injectIntl(UserconsoleSso))
