/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiContextMenuItem, EuiIcon, EuiLoadingSpinner, EuiToolTip } from '@elastic/eui'

import type { AsyncRequestState, StackDeployment } from '@modules/ui-types'
import { parseError } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { isSystemOwned } from '@/lib/stackDeployments/selectors/metadata'
import { isEsStopped } from '@/lib/stackDeployments/selectors/configurationChanges'

import DeleteDeploymentModal from './DeleteDeploymentModal'

import type { IntlShape } from 'react-intl'

type Props = {
  deployment: StackDeployment
  fetchDeployment: () => void
  deleteDeployment: () => void
  deleteStackDeploymentRequest: AsyncRequestState
  intl: IntlShape
}

type State = {
  deleteValue: string
  isConfirmModalOpen: boolean
  requestChainProgress: boolean
}

class DeleteDeployment extends Component<Props, State> {
  mounted: boolean = false

  state: State = {
    deleteValue: ``,
    isConfirmModalOpen: false,
    requestChainProgress: false,
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { deleteStackDeploymentRequest, deployment } = this.props
    const { requestChainProgress } = this.state

    const stopped = isEsStopped({ deployment })
    const systemOwned = isSystemOwned({ deployment })
    const helpText = this.getHelpText({ systemOwned, stopped })
    const isBusy = requestChainProgress || deleteStackDeploymentRequest.inProgress

    return (
      <Fragment>
        <PermissionsGate
          permissions={[
            {
              type: 'deployment',
              action: 'delete',
              id: deployment.id,
            },
          ]}
        >
          {({ hasPermissions }) => (
            <EuiContextMenuItem
              className='actionsDropdown-delete-deployment'
              data-test-id='deploymentDelete-Btn'
              disabled={systemOwned || !stopped || isBusy || !hasPermissions}
              icon={isBusy ? <EuiLoadingSpinner size='m' /> : <EuiIcon type='trash' />}
              onClick={this.openConfirmModal}
            >
              <EuiToolTip content={helpText}>
                <FormattedMessage
                  id='deployment-delete.shut-down-deployment'
                  defaultMessage='Delete deployment'
                />
              </EuiToolTip>
            </EuiContextMenuItem>
          )}
        </PermissionsGate>

        {this.renderConfirmModal()}
      </Fragment>
    )
  }

  renderConfirmModal() {
    const { deployment } = this.props
    const { isConfirmModalOpen } = this.state

    if (!isConfirmModalOpen) {
      return null
    }

    return (
      <DeleteDeploymentModal
        deployment={deployment}
        closeConfirmModal={this.closeConfirmModal}
        onDeleteButtonConfirm={() => this.deleteDeployment()}
      />
    )
  }

  deleteDeployment = () => {
    const { deleteDeployment, fetchDeployment } = this.props

    Promise.resolve()
      .then(() => this.setState({ requestChainProgress: true }))
      .then(() => deleteDeployment())
      .then(() => fetchDeployment())
      .then(() => {
        if (this.mounted) {
          return this.setState({ requestChainProgress: false })
        }

        return null
      })
      .catch((error) => {
        if (this.mounted) {
          addToast({
            family: `toast-error`,
            color: `danger`,
            iconType: `broom`,
            title: (
              <FormattedMessage
                id='toasts.title-deleting-failed'
                defaultMessage='Deleting your deployment failed'
              />
            ),
            text: parseError(error),
          })

          return this.setState({ requestChainProgress: false })
        }

        return null
      })
  }

  getHelpText({ systemOwned, stopped }) {
    if (systemOwned) {
      return (
        <FormattedMessage
          id='deployment-delete-deployment.cannot-delete-system-deployment'
          defaultMessage='You cannot delete a system deployment.'
        />
      )
    }

    if (!stopped) {
      return (
        <FormattedMessage
          id='deployment-delete-deployment.terminate-deployment-before-delete'
          defaultMessage='You need to terminate the deployment before you can delete it.'
        />
      )
    }

    return null
  }

  closeConfirmModal = () => this.setState({ isConfirmModalOpen: false })

  openConfirmModal = () => this.setState({ isConfirmModalOpen: true })

  shouldDisableConfirmButton() {
    return this.state.deleteValue !== this.props.deployment.name
  }
}

export default injectIntl(DeleteDeployment)
