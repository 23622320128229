/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { inRange } from 'lodash'

import type { MiddlewareComponent } from './types'
import type { ReactElement } from 'react'

/**
 * The provided component will be wrapped in the middleware components, in order
   from outside in. Be mindful of any dependencies between them! Each middleware
   function must declare its dependencies and these will be cross-checked.
 */
export default function applyMiddleware(
  middleware: MiddlewareComponent[],
  node: ReactElement | null,
): ReactElement {
  // verify dependencies of each middleware component
  middleware.forEach((component, i) => {
    component.middleware.dependencies.forEach((dependency) => {
      if (!inRange(middleware.indexOf(dependency), 0, i)) {
        throw new Error(
          `${component.middleware.displayName} does not appear under ${dependency.middleware.displayName}, which is defined as a dependency.`,
        )
      }
    })
  })

  return (
    middleware.reduceRight((tree, Middleware) => <Middleware>{tree}</Middleware>, node) ?? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <Fragment />
    ) // sanity so we always return a ReactElement
  )
}
