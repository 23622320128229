/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React from 'react'

import { EuiEmptyPrompt, EuiText } from '@elastic/eui'

import { mapTypeToIcon } from '@/components/User/BillingUsage/BillingUsageOverviewV2/types'

import type { IconType } from '@/components/User/BillingUsage/BillingUsageOverviewV2/types'
import type { FunctionComponent } from 'react'

interface Props {
  iconType: IconType
}

const EmptyDataPrompt: FunctionComponent<Props> = ({ iconType }) => {
  const renderIcon = (type: IconType) => mapTypeToIcon[type]

  return (
    <EuiEmptyPrompt
      style={{ margin: 30 }}
      iconType={renderIcon(iconType)}
      body={
        <EuiText size='s'>
          <p>
            <FormattedMessage
              id='billing-usage-list.empty-list'
              defaultMessage='No data matching selected filters'
            />
          </p>
        </EuiText>
      }
    />
  )
}

export default EmptyDataPrompt
