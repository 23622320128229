/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonIcon, EuiSpacer, EuiText } from '@elastic/eui'

import type { VersionNumber, StackDeploymentCreateRequest } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'

// eslint-disable-next-line import/no-restricted-paths
import DocLink from '@/components/DocLink'
// eslint-disable-next-line import/no-restricted-paths
import { isIncompatibleVersionForGlobalTemplate } from '@/lib/globalDeploymentTemplates'
// eslint-disable-next-line import/no-restricted-paths
import { isPrerelease } from '@/components/DeploymentConfigure/EsVersion/groupVersions'

import SelectVersionCombobox from './SelectVersionCombobox'

import type { FunctionComponent } from 'react'
import type { WrappedComponentProps } from 'react-intl'

export interface Props extends WrappedComponentProps {
  availableVersions: VersionNumber[]
  whitelistedVersions: VersionNumber[]
  setVersion: (version: VersionNumber) => void
  version: VersionNumber
  disabled?: boolean
  editorState: StackDeploymentCreateRequest
}

const messages = defineMessages({
  popoverText: {
    id: `select-version-text`,
    defaultMessage: `Learn more about Elastic Stack versions.`,
  },
})

const SelectVersion: FunctionComponent<Props> = (props) => {
  const {
    version,
    availableVersions,
    whitelistedVersions,
    disabled,
    editorState: { globalDeploymentTemplate },
    intl: { formatMessage },
  } = props

  const label = formatMessage({
    id: 'select-version-label',
    defaultMessage: 'Version',
  })

  const prepend = [
    label,
    <CuiToggleablePopoverForClassComp
      anchorPosition='upCenter'
      toggleButton={(toggleButton) => (
        <EuiButtonIcon
          aria-label={formatMessage(messages.popoverText)}
          className='hardwareProfileIcon'
          onClick={toggleButton}
          iconType='iInCircle'
          color='text'
        />
      )}
    >
      <EuiText size='s' style={{ width: '300px' }}>
        <FormattedMessage
          data-test-id='ess-select-version-tooltip'
          id='select-version.tooltip'
          defaultMessage='We make the two latest minor versions of the latest major version available for provisioning. In addition, the latest minor version of the previous major version is also available. {learnMore}'
          values={{
            learnMore: (
              <DocLink link='versionPolicyLink'>
                <FormattedMessage id='select-version.learn-more' defaultMessage='Learn more' />
              </DocLink>
            ),
          }}
        />
      </EuiText>
    </CuiToggleablePopoverForClassComp>,
  ]

  const onChangeVersion = (newVersion: VersionNumber) => {
    const { setVersion } = props
    setVersion(newVersion)
  }

  return (
    <Fragment>
      <SelectVersionCombobox
        key='select-version'
        version={version}
        availableVersions={availableVersions}
        whitelistedVersions={whitelistedVersions}
        id='select-version-for-template'
        onUpdate={onChangeVersion}
        checkVersionDisabled={(v) =>
          isIncompatibleVersionForGlobalTemplate(globalDeploymentTemplate, v)
        }
        disabled={disabled}
        aria-label={label}
        prepend={prepend}
      />

      {isPrerelease(version) && (
        <Fragment>
          <EuiSpacer size='s' />

          <CuiAlert size='s' type='warning'>
            <FormattedMessage
              id='VersionRadio.prerelease-versions-warning'
              defaultMessage='This is a pre-release version of the Elastic Stack that we make available for testing. Do not use for production workloads. You will not be able to upgrade from this pre-release version.'
            />
          </CuiAlert>
        </Fragment>
      )}
    </Fragment>
  )
}

export default injectIntl(SelectVersion)
