/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type {
  MfaWebAuthnChallenge,
  useVerifySaasCurrentUserMfaChallengeMutation,
} from '@modules/cloud-lib/users/hooks/mfa'
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { parseError, useChallengeWebAuthnMutation } from '@modules/cloud-lib/users/hooks/webauthn'

const WebAuthnForm: React.FunctionComponent<{
  device: SaasAuthMfaDeviceResponse
  stateId: string
  verifyMutation: ReturnType<typeof useVerifySaasCurrentUserMfaChallengeMutation>
  onSubmit: (challenge: MfaWebAuthnChallenge) => void
}> = ({ device, stateId, verifyMutation, onSubmit }) => {
  const {
    mutate: challengeMutate,
    isLoading: isChallengeLoading,
    error: challengeError,
  } = useChallengeWebAuthnMutation(onSubmit)

  const { isLoading: isVerifyChallengeLoading, error: verifyChallengeError } = verifyMutation

  const webAuthnErrorMessage = parseError(challengeError ?? verifyChallengeError)

  const onVerify = () => challengeMutate({ deviceId: device.device_id, stateId })

  // When this form is rendered, we want to initiate the WebAuthn flow right away,
  // but only once, thus the useEffect dependency array must be empty.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onVerify(), [])

  return (
    <EuiFlexGroup direction='column' alignItems='center'>
      <EuiFlexItem>
        <EuiText size='m'>
          <FormattedMessage
            id='authenticator.webauthn.complete-authentication'
            defaultMessage='Complete multifactor authentication'
          />
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem>
        {webAuthnErrorMessage && (
          <EuiCallOut
            css={css({ maxWidth: '264px' })}
            color='danger'
            size='m'
            title={
              <FormattedMessage
                id='authenticator.webauthn.error.title'
                defaultMessage="We couldn't authenticate you"
              />
            }
          >
            <FormattedMessage
              id='authenticator.webauthn.error.description'
              defaultMessage='There was an error while verifying your identity. Try again or use a different method. {webAuthnErrorMessage}'
              values={{ webAuthnErrorMessage }}
            />
          </EuiCallOut>
        )}
      </EuiFlexItem>

      <EuiFlexItem grow={true}>
        <EuiButton
          color='primary'
          css={css({ minWidth: '264px' })}
          fill={true}
          size='m'
          onClick={onVerify}
          isLoading={isChallengeLoading || isVerifyChallengeLoading}
        >
          <FormattedMessage id='authenticator.webauthn.verify' defaultMessage='Verify' />
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default WebAuthnForm
