/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { createApiKeyUrl } from '@modules/cloud-api/v1/urls'
import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import { isRoleAssignmentsEmpty } from '@modules/role-assignments-lib'

import asyncRequest from '../asyncRequests'
import { GENERATE_API_KEY } from '../../constants/actions'

export function generateApiKey(
  name: string,
  expiration: string | null,
  roleAssignments: RoleAssignments | undefined,
) {
  return (dispatch) => {
    const payload = {
      description: name,
      ...(!isRoleAssignmentsEmpty(roleAssignments) && {
        role_assignments: roleAssignments,
      }),
      ...(expiration !== null && {
        expiration,
      }),
    }

    const url = createApiKeyUrl()
    return dispatch(
      asyncRequest({
        type: GENERATE_API_KEY,
        method: `POST`,
        payload,
        url,
      }),
    )
  }
}
