/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiPanel, EuiSpacer, EuiLink, EuiCallOut, EuiTitle } from '@elastic/eui'

import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  InstanceConfiguration,
  DeploymentGetResponse,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement, SliderInstanceType } from '@modules/ui-types'
import { CuiLink } from '@modules/cui/Link'

import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'
import { hashMatchesNodeConfiguration } from '@/lib/stackDeployments/topology'
import { deploymentActivityUrl } from '@/lib/urlBuilder'

import ScrollIntoView from '../../../../../ScrollIntoView'

import {
  TopologyElementDescription,
  hasInstancesInDeploymentOfTypeButNotInCurrentPlan,
  getTopologyTitle,
} from './helpers'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactNode } from 'react'

interface Props extends WrappedComponentProps {
  id: string
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  topologyElement: AnyTopologyElement
  templateTopologyElement?: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  onChange?: (path: string[], value: any) => void
  onPlanChange: undefined | ((path: string[], value: any) => void)
  descriptionOverride?: ReactNode
  extraContent?: ReactNode
  version?: string | undefined
  sliderInstanceType: SliderInstanceType
  deploymentUnderEdit?: DeploymentGetResponse
}

class DisabledTopologyElement extends Component<Props> {
  render(): JSX.Element {
    const {
      deployment,
      topologyElement,
      templateTopologyElement,
      instanceConfiguration,
      onChange,
      descriptionOverride,
      extraContent,
      deploymentUnderEdit,
      intl: { formatMessage },
    } = this.props

    const enableButtonText = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-disabled-enableButton'
        defaultMessage='Add capacity'
      />
    )

    const onEnable = onChange ? this.enable : undefined

    const version = getUpsertVersion({ deployment })

    const instancesAlreadyExist = hasInstancesInDeploymentOfTypeButNotInCurrentPlan({
      deploymentUnderEdit,
      topologyElement,
      instanceConfiguration,
    })

    const title = getTopologyTitle(
      templateTopologyElement || topologyElement,
      instanceConfiguration,
      version,
      formatMessage,
    )

    return (
      <ScrollIntoView
        whenLocationMatches={(hash) => hashMatchesNodeConfiguration(hash, topologyElement)}
      >
        <div data-test-subj='topologyElement' data-id={instanceConfiguration.name}>
          <EuiPanel className='topologyPanel'>
            <EuiTitle size='xxs'>
              <h4>{title}</h4>
            </EuiTitle>
            <EuiSpacer size='xs' />
            <TopologyElementDescription
              topologyElement={templateTopologyElement || topologyElement}
              instanceConfiguration={instanceConfiguration}
              descriptionOverride={descriptionOverride}
              version={version}
            />

            {instancesAlreadyExist && (
              <Fragment>
                <EuiSpacer size='xs' />
                <EuiCallOut color='warning' size='s'>
                  <FormattedMessage
                    id='deploymentInfrastructure-disabledTopologyElement-instances-already-exist'
                    defaultMessage='There are already some instances in your deployment of this type. This likely means that a configuration change attempt
                  trying to add them failed. Take a look at the <link>Activity</link> history and see if you can re-attempt the change. Contact support if necessary.'
                    values={{
                      link: (content) => (
                        <CuiLink to={deploymentActivityUrl(deploymentUnderEdit?.id)}>
                          {content}
                        </CuiLink>
                      ),
                    }}
                  />
                </EuiCallOut>
                <EuiSpacer size='xs' />
              </Fragment>
            )}

            {onEnable && (
              <Fragment>
                <EuiSpacer size='s' />
                <EuiLink
                  color='primary'
                  data-test-id={`topologyElement-enableButton-${
                    templateTopologyElement ? templateTopologyElement.id : topologyElement.id
                  }`}
                  onClick={() => onEnable()}
                >
                  + {enableButtonText}
                </EuiLink>
              </Fragment>
            )}
            {extraContent && (
              <Fragment>
                <EuiSpacer size='s' />
                {extraContent}
              </Fragment>
            )}
          </EuiPanel>
        </div>
      </ScrollIntoView>
    )
  }

  enable = (): void => {
    const {
      templateTopologyElement,
      instanceConfiguration,
      onChange,
      version,
      onPlanChange,
      sliderInstanceType,
    } = this.props

    const {
      discrete_sizes: { default_size, resource },
    } = instanceConfiguration

    const defaultZoneCountInTemplate = templateTopologyElement?.zone_count

    // Use the default number of zones from the template, or 1 if there is no
    // template, the count in there is zero for some reason, etc.
    const zoneCount = defaultZoneCountInTemplate || 1

    onChange!([`size`], { value: default_size, resource })
    onChange!([`zone_count`], zoneCount)

    if (version && onPlanChange) {
      onPlanChange([sliderInstanceType, `version`], version)
    }
  }
}

export default injectIntl(DisabledTopologyElement)
