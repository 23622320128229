/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type { DeploymentClaimRequest, StackVersionConfig } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, ProfileState } from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { refreshSaasCurrentUser } from '@/actions/organizations'
import { fetchDeployment, claimInstantStackDeployment } from '@/actions/stackDeployments'
import {
  claimInstantStackDeploymentRequest,
  createStackDeploymentRequest,
} from '@/reducers/asyncRequests/registry'
import { getActiveVersionStacks } from '@/reducers/elasticStack'
import { getProfile } from '@/apps/userconsole/reducers/profile'

import { getConfigForKey, isFeatureActivated } from '../../../selectors'
import { CreateEditorComponentConsumerProps as ConsumerProps } from '../types'

import CreateStackDeploymentEditor from './CreateStackDeploymentEditor'

import type { ReduxState, ThunkDispatch } from '@/types/redux'

type StateProps = {
  showPrice: boolean
  stackVersions: StackVersionConfig[] | undefined
  createStackDeploymentRequest: AsyncRequestState
  claimInstantStackDeploymentRequest: AsyncRequestState
  profile: ProfileState
  isLaunchdarklyEnabled: boolean
  isUserConsole: boolean
}

interface DispatchProps {
  claimInstantStackDeployment: (params: { payload: DeploymentClaimRequest }) => Promise<any>
  fetchDeployment: (stackDeploymentId: string) => Promise<any>
}

const mapStateToProps = (state: ReduxState, { region }: ConsumerProps): StateProps => {
  const regionId = region ? region.id : null
  const isUserConsole = getConfigForKey(state, `APP_NAME`) === `userconsole`

  const showPrice = isUserConsole && isFeatureActivated(state, Feature.showPrices)

  return {
    showPrice,
    stackVersions: regionId ? getActiveVersionStacks(state, regionId) : undefined,
    createStackDeploymentRequest: createStackDeploymentRequest(state),
    claimInstantStackDeploymentRequest: claimInstantStackDeploymentRequest(state),
    profile: getProfile(state),
    isLaunchdarklyEnabled: isFeatureActivated(state, Feature.launchdarklyId),
    isUserConsole,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  claimInstantStackDeployment: (params) => dispatch(claimInstantStackDeployment(params)),
  fetchDeployment: (deploymentId) =>
    dispatch(refreshSaasCurrentUser()).then(() => dispatch(fetchDeployment({ deploymentId }))),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateStackDeploymentEditor)
