/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { jsx } from '@emotion/react'

import { EuiForm, EuiFormRow, EuiSpacer } from '@elastic/eui'

import { submitButtonStyle } from '@modules/access-management-components/styles'

import { createDeploymentUrl } from '@/lib/urlBuilder'

import PasswordField from '../../../../components/PasswordField'
import SpinButton from '../../../../components/SpinButton'

import ChangePasswordFormErrorCallout from './ErrorCallout'

import type { ReactElement } from 'react'
import type { AllProps as Props } from './types'

interface State {
  newPassword: string
  isValidPassword: boolean
}

class ChangePasswordForm extends PureComponent<Props, State> {
  state = {
    newPassword: '',
    isValidPassword: false,
  }

  render(): ReactElement {
    const {
      loginRequest,
      setInitialPasswordRequest,
      isReset,
      isFlowV2,
      intl: { formatMessage },
    } = this.props
    const { isValidPassword } = this.state

    const fieldName = formatMessage({
      id: 'set-password.new-password',
      defaultMessage: 'New password',
    })

    return (
      <EuiForm data-test-id='set-password-form'>
        <PasswordField
          name='newPassword'
          label={!isFlowV2 && fieldName}
          hidePlaceholder={!isFlowV2}
          placeholder={fieldName}
          onChange={this.onChange}
          hasStrengthIndicator={true}
        />

        <EuiSpacer size={isFlowV2 ? 'm' : 'l'} />

        <EuiFormRow>
          <SpinButton
            onClick={this.onSubmit}
            disabled={!isValidPassword}
            spin={setInitialPasswordRequest.inProgress || loginRequest.inProgress}
            buttonProps={{ fullWidth: true }}
            fill={true}
            data-test-id='update-password'
            className='cloud-landing-page-form-submit-button'
            css={submitButtonStyle}
          >
            {isReset ? (
              <FormattedMessage
                id='set-password.update-password-button'
                defaultMessage='Update password and login'
              />
            ) : (
              <FormattedMessage
                id='set-password.set-password-button'
                defaultMessage='Set password and login'
              />
            )}
          </SpinButton>
        </EuiFormRow>
        <ChangePasswordFormErrorCallout error={setInitialPasswordRequest.error} />
      </EuiForm>
    )
  }

  onChange = (input: HTMLInputElement, { isValidPassword }: { isValidPassword: boolean }): void => {
    this.setState({ newPassword: input.value, isValidPassword })
  }

  onSubmit = (): void => {
    const { email, expires, activationHash } = this.props
    const password = this.state.newPassword

    const redirectTo = this.getRedirectToUrl()

    if (activationHash) {
      this.props.setInitialPassword({ email, expires, hash: activationHash, password, redirectTo })
    }
  }

  getRedirectToUrl(): string | undefined {
    const { isReset, isMarketplaceUser, isGCPUser, isLaunchDarklyActivated } = this.props

    if (isLaunchDarklyActivated && isGCPUser && !isReset) {
      return `${createDeploymentUrl()}?onVerifySignup=true`
    }

    if (isMarketplaceUser) {
      return createDeploymentUrl()
    }

    return
  }
}

export default injectIntl(ChangePasswordForm)
