/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import { applyGoogleTrackingV4FormSubmission } from '@/apps/userconsole/lib/googleTracking'
import { buildFirstSignInRedirectServerlessUrl } from '@/lib/urlUtils'
import AppLoadingRoot from '@/components/AppLoadingRoot'

import { getCreateUserPayload } from '../../CreateAccountForm/lib'
import { formId } from '../Form'
import SignUpPage from '../SignUpPage'

import type { AllProps } from './types'

const ServerlessUserRegistration: React.FC<AllProps> = ({
  createUser,
  loginAndRedirect,
  location,
  googleTrackingIdV4Enabled,
}) => {
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  const { marketplaceSignupEnabled: isMarketplaceSignupEnabled } = flags

  if (!isFlagUsable) {
    return <AppLoadingRoot />
  }

  const { search } = location
  const redirectTo = buildFirstSignInRedirectServerlessUrl(search)

  return (
    <SignUpPage
      createUser={handleCreateUser}
      isServerlessUserRegistration={true}
      isMarketplaceSignupEnabled={isMarketplaceSignupEnabled}
      redirectTo={redirectTo}
    />
  )

  function login({ username, password }) {
    return loginAndRedirect({
      oktaRedirectUrl: redirectTo,
      redirectTo,
      email: username,
      password,
    })
  }

  async function handleCreateUser({ email, password, captcha_token }) {
    await createUser({
      password,
      email,
      captcha_token,
      ...getCreateUserPayload(search),
    })

    if (googleTrackingIdV4Enabled) {
      applyGoogleTrackingV4FormSubmission({
        email,
        formId,
      })
    }

    return login({ username: email, password })
  }
}

export default ServerlessUserRegistration
