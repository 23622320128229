/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React from 'react'

import { EuiButton, EuiFlexGroup } from '@elastic/eui'

import { useResendVerificationEmailMutation } from '@modules/cloud-lib/users/hooks'

import type { FC } from 'react'

interface ResendLinkButtonProps {
  email: string
  onSuccess?: () => Promise<void> | void
  onError?: () => Promise<void> | void
}

const ResendVerificationLinkButton: FC<ResendLinkButtonProps> = ({ email, onSuccess, onError }) => {
  const { mutate: resetPassword, isLoading } = useResendVerificationEmailMutation({
    onSuccess,
    onError,
  })

  return (
    <EuiFlexGroup justifyContent='center'>
      <EuiButton fill={true} onClick={() => resetPassword({ email })} isLoading={isLoading}>
        <FormattedMessage
          id='password-update-via-email.resend-link'
          defaultMessage='Resend verification link'
        />
      </EuiButton>
    </EuiFlexGroup>
  )
}

export default ResendVerificationLinkButton
