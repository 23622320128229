/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

export const UNKNOWN_ERROR = 'unknownError'

export const messages = defineMessages({
  addDomain: {
    id: `organization.security.add-domain-flyout.add-domain`,
    defaultMessage: `Add domain`,
  },
  createDnsRecord: {
    id: `organization.security.add-domain-flyout.create-dns-record`,
    defaultMessage: `Create a DNS record`,
  },
})

export const verificationErrorMessage = defineMessages({
  '449.title': {
    id: `organization.security.add-domain-flyout.verification-error.449.title`,
    defaultMessage: `DNS record not verified`,
  },
  '449.description': {
    id: `organization.security.add-domain-flyout.verification-error.449.description`,
    defaultMessage: `DNS propagation can take some time, so it may not be immediately visible. Check your DNS record's configuration and try again in a few minutes.`,
  },
  'org.domain_claim.already_exists.title': {
    id: `organization.security.add-domain-flyout.org.domain_claim.already_exists.title`,
    defaultMessage: `Domain name has already been claimed`,
  },
  'org.domain_claim.already_exists.description': {
    id: `organization.security.add-domain-flyout.org.domain_claim.already_exists.description`,
    defaultMessage: `If you've previously verified this domain, there's no need to claim it again.`,
  },
  [`${UNKNOWN_ERROR}.title`]: {
    id: `organization.security.add-domain-flyout.unknown-error.title`,
    defaultMessage: `An unknown error has occurred`,
  },
  [`${UNKNOWN_ERROR}.description`]: {
    id: `organization.security.add-domain-flyout.unknown-error.description`,
    defaultMessage: `Try again later.`,
  },
})
