/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { runPhoneHomeIfNeeded } from '@/actions/phoneHome'
import { isPhoneHomeDisabled } from '@/reducers/phoneHomeDisabled'
import { isFeatureActivated } from '@/selectors'

import PhoneHomeData from './Data'

interface StateProps {
  phoneHomeActivated: boolean
  isPhoneHomeDisabled: boolean
}

interface DispatchProps {
  runPhoneHomeIfNeeded: () => void
}

interface ConsumerProps {}

const mapStateToProps = (state): StateProps => ({
  phoneHomeActivated: isFeatureActivated(state, Feature.phoneHome),
  isPhoneHomeDisabled: isPhoneHomeDisabled(state),
})

const mapDispatchToProps: DispatchProps = {
  runPhoneHomeIfNeeded,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false },
)(PhoneHomeData)
