/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiComboBox, EuiFormRow } from '@elastic/eui'
import type { EuiComboBoxOptionOption } from '@elastic/eui'

import { ViewBy } from '../../../types'

import type { FunctionComponent } from 'react'
import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

const ViewBySelect: FunctionComponent<Props & WrappedComponentProps> = ({
  onChange,
  isLoading,
  intl: { formatMessage },
  value,
}) => {
  const byDay = {
    label: formatMessage({
      id: 'billing-usage.filters.view-by.day',
      defaultMessage: 'Day',
    }),
    value: ViewBy.DAY,
  }
  const byMonth = {
    label: formatMessage({
      id: 'billing-usage.filters.view-by.month',
      defaultMessage: 'Month',
    }),
    value: ViewBy.MONTH,
  }

  const options: Array<EuiComboBoxOptionOption<ViewBy>> = [byDay, byMonth]

  const selectedOption = options.find((option) => option.value === value) ?? byDay

  return (
    <EuiFormRow
      fullWidth={true}
      label={formatMessage({
        id: 'billing-usage.filters.viewBy',
        defaultMessage: 'View by',
      })}
    >
      <EuiComboBox
        aria-label={formatMessage({
          id: 'billing-usage.filters.viewBy',
          defaultMessage: 'View by',
        })}
        options={options}
        selectedOptions={[selectedOption]}
        singleSelection={{ asPlainText: true }}
        onChange={(values) =>
          onChange({
            // `values[0].value` cannot be undefined
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            viewBy: values[0]!.value!,
          })
        }
        isClearable={false}
        isDisabled={isLoading}
        fullWidth={true}
      />
    </EuiFormRow>
  )
}

export default injectIntl(ViewBySelect)
