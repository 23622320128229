/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withTransaction } from '@elastic/apm-rum-react'

import { getDeploymentHealthProblems } from '@/lib/healthProblems'
import { getDeploymentHealth } from '@/reducers/deploymentHealth'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { getEolStatus } from '@/reducers/eolStatus'
import { fetchDeploymentHealthRequest } from '@/reducers/asyncRequests/registry'

import { withStackDeploymentRouteParams } from '../../StackDeploymentEditor'

import HealthStatusDetails from './HealthStatusDetails'

import type { StateProps, DispatchProps, OwnProps } from './HealthStatusDetails'

const mapStateToProps = (state: any, { stackDeploymentId }: OwnProps): StateProps => {
  const stackDeployment = getStackDeployment(state, stackDeploymentId)
  const eolStatus = getEolStatus(state)
  const health = getDeploymentHealth(state, stackDeploymentId!)
  const deploymentHealthRequest = fetchDeploymentHealthRequest(state)
  const [problems] = getDeploymentHealthProblems({
    deployment: stackDeployment!,
    eolStatus,
    health,
    deploymentHealthRequest,
  })
  return {
    problems,
  }
}

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(
    withTransaction(`Health status details`, `component`)(HealthStatusDetails),
  ),
)
