/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment, useMemo, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiIcon, EuiTabs, EuiTab, EuiSpacer } from '@elastic/eui'

import type { ProjectType } from '@modules/ui-types/projects'
import { useGetBasePricesV1 } from '@modules/billing-lib/hooks'
import Filters from '@modules/pricing-list-components/Serverless/Filters'
import { useQueryStringParams } from '@modules/utils/qs'

import { filterSchema } from './filterSchema'
import ElasticsearchTab from './ElasticsearchTab'
import SecurityTab from './SecurityTab'
import ObservabilityTab from './ObservabilityTab'
import AddonList from './AddonList'
import { getRegionalPrices, getProvidersAndRegions, getServerlessPrices } from './utils'

const Serverless = () => {
  const {
    queryStringItems: { project, provider, region, timestamp },
    setQueryStringItem,
  } = useQueryStringParams(filterSchema.parse)
  const [selectedProject, setSelectedProject] = useState<ProjectType>(project)
  const [selectedProvider, setSelectedProvider] = useState<string>(provider)
  const [selectedRegion, setSelectedRegion] = useState<string>(region)
  const [selectedTimestamp, setSelectedTimestamp] = useState<string>(timestamp)

  const { data = [], isLoading } = useGetBasePricesV1({
    timestamp: selectedTimestamp,
  })
  const regionalPrices = getRegionalPrices(data, selectedProvider, selectedRegion)
  const providersAndRegions = getProvidersAndRegions(data)
  const providerOptions = Object.keys(providersAndRegions)

  const renderAddonList = useMemo(
    () => <AddonList prices={getServerlessPrices(data)} isLoading={isLoading} />,
    [data, isLoading],
  )

  const tabs = useMemo(
    () => [
      {
        id: 'elasticsearch',
        name: (
          <FormattedMessage
            id='pricing-list.serverless.tab.elasticsearch'
            defaultMessage='Elasticsearch'
          />
        ),
        content: (
          <ElasticsearchTab
            prices={regionalPrices}
            isLoading={isLoading}
            addonList={renderAddonList}
          />
        ),
        prepend: <EuiIcon type='logoElasticsearch' size='l' />,
        ['data-test-subj']: 'elasticsearch-tab',
      },
      {
        id: 'observability',
        name: (
          <FormattedMessage
            id='pricing-list.serverless.tab.observability'
            defaultMessage='Observability'
          />
        ),
        content: (
          <ObservabilityTab
            prices={regionalPrices}
            isLoading={isLoading}
            addonList={renderAddonList}
          />
        ),
        prepend: <EuiIcon type='logoObservability' size='l' />,
        ['data-test-subj']: 'observability-tab',
      },
      {
        id: 'security',
        name: (
          <FormattedMessage id='pricing-list.serverless.tab.security' defaultMessage='Security' />
        ),
        content: (
          <SecurityTab prices={regionalPrices} isLoading={isLoading} addonList={renderAddonList} />
        ),
        prepend: <EuiIcon type='logoSecurity' size='l' />,
        ['data-test-subj']: 'security-tab',
      },
    ],
    [regionalPrices, isLoading, renderAddonList],
  )

  const selectedProjectContent = useMemo(
    () => tabs.find((obj) => obj.id === selectedProject)?.content,
    [selectedProject, tabs],
  )

  const onSelectedSolutionChanged = (project: ProjectType) => {
    setSelectedProject(project)
    setQueryStringItem('project', project)
  }

  const onSelectedProviderChanged = (provider: string) => {
    setSelectedProvider(provider)
    setQueryStringItem('provider', provider)

    const regionsPerProvider = providersAndRegions[provider] || []

    if (!regionsPerProvider.includes(selectedRegion)) {
      setSelectedRegion(regionsPerProvider[0] || '')
    }
  }

  const onSelectedRegionChanged = (region: string) => {
    setSelectedRegion(region)
    setQueryStringItem('region', region)
  }

  const onSelectedTimestampChanged = (timestamp: string) => {
    setSelectedTimestamp(timestamp)
    setQueryStringItem('timestamp', timestamp)
  }

  const renderTabs = () =>
    tabs.map((tab, index) => (
      <EuiTab
        key={index}
        onClick={() => onSelectedSolutionChanged(tab.id as ProjectType)}
        isSelected={tab.id === selectedProject}
        prepend={tab.prepend}
        css={css({ flexGrow: 1, justifyContent: 'center' })}
        data-test-subj={tab['data-test-subj']}
      >
        {tab.name}
      </EuiTab>
    ))

  return (
    <Fragment>
      <Filters
        isLoading={isLoading}
        providers={providerOptions}
        regions={providersAndRegions[selectedProvider] || []}
        provider={selectedProvider}
        region={selectedRegion}
        timestamp={selectedTimestamp}
        onSelectedProviderChange={onSelectedProviderChanged}
        onSelectedRegionChange={onSelectedRegionChanged}
        onSelectedTimestampChange={onSelectedTimestampChanged}
      />

      <EuiSpacer size='l' />

      <EuiTabs size='l'>{renderTabs()}</EuiTabs>

      <EuiSpacer size='xxl' />

      {selectedProjectContent}
    </Fragment>
  )
}

export default Serverless
