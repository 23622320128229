/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import createCache from '@emotion/cache'
import { useDispatch } from 'react-redux'

import { EuiProvider } from '@elastic/eui'

// eslint-disable-next-line import/no-restricted-paths
import { getTheme, setInitialTheme, trackColorSchemePreference } from '@/lib/theme'

import ReduxMiddleware from './ReduxMiddleware'

import type { MiddlewareComponent } from '../types'

const EuiMiddleware: MiddlewareComponent = ({ children }) => {
  const [isReady, setIsReady] = useState(false)

  const dispatcher = useDispatch()

  useEffect(() => {
    setInitialTheme()
    trackColorSchemePreference(dispatcher)
    setIsReady(true)
  }, [dispatcher])

  if (!isReady) {
    return null
  }

  const theme = getTheme()
  const cache = createCache({
    key: 'cui',
    container: document.querySelector(`meta[name="emotion-cache"]`) as HTMLElement,
  })

  cache.compat = true // see: https://github.com/emotion-js/emotion/issues/1178 coming from EUI

  return (
    <EuiProvider colorMode={theme} cache={cache}>
      {children}
    </EuiProvider>
  )
}

EuiMiddleware.middleware = {
  displayName: 'EuiMiddleware',
  dependencies: [ReduxMiddleware],
}

export default EuiMiddleware
