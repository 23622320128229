/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { ElasticV3BrowserShipper } from '@elastic/ebt/shippers/elastic_v3/browser'
import { createAnalytics } from '@elastic/ebt/client'
import type { IAnalyticsClient } from '@elastic/ebt/client/src/analytics_client'
import type {
  BuildShipperUrl,
  BuildShipperUrlOptions,
} from '@elastic/ebt/shippers/elastic_v3/browser'

import type { CloudAppEnv } from '@modules/ui-types'
import { getLocationObservable, trackClicks } from '@modules/ebt/utils'
import { useConfig } from '@modules/cui/ConfigContext'
import { useProfile } from '@modules/profile-lib/hooks'
import type { MiddlewareComponent } from '@modules/app/types'
import ProfileProvider from '@modules/profile-lib/ProfileProvider'

// eslint-disable-next-line import/no-restricted-paths
import AppLoadingRoot from '@/components/AppLoadingRoot'
// eslint-disable-next-line import/no-restricted-paths
import { isElasticStaff } from '@/lib/validateEmail'

import EbtContext from './EbtContext'
import { LoggerFactory } from './logger'
import { EbtAnalytics } from './EbtAnalytics'

const EbtProvider: MiddlewareComponent = ({ children }) => {
  const ebtIsSet = useRef<boolean>(false)
  const [ebtIsUserContextSet, setEbtUserContext] = useState<boolean>(false)
  const [analytics, setAnalytics] = useState<IAnalyticsClient>()
  const [analyticsInstance, setAnalyticsInstance] = useState<EbtAnalytics>()

  const history = useHistory()
  const profile = useProfile()

  const env = useConfig('CLOUD_UI_ENV')
  const channelName = useConfig('EBT_CHANNEL')

  const isDevEnv = (e) => e === 'qa' || e === 'staging'
  const shouldSendEbt = (e) =>
    !isElasticStaff(profile?.email ?? '') && (e === 'production' || e === 'qa' || e === 'staging')

  const logger = LoggerFactory('ebt')
  const _analytics = createAnalytics({
    // Set to `true` when running in developer mode.
    isDev: isDevEnv(env),
    // The application's instrumented logger TODO we have to add a logger
    // @ts-ignore
    logger,
  })

  useEffect(() => {
    const initializeEbt = () => {
      if (shouldSendEbt(env) && channelName) {
        const ebtAnalyticsInstance = new EbtAnalytics(_analytics)
        _analytics.registerShipper(ElasticV3BrowserShipper, {
          channelName,
          version: '0.0.1',
          buildShipperHeaders: () => ({
            'content-type': 'application/x-ndjson',
            'x-elastic-cluster-id': '999',
            'x-elastic-stack-version': '1.2.3',
          }),
          buildShipperUrl: createBuildShipperUrl(env),
        })

        _analytics.optIn({
          global: { enabled: true },
        })

        trackClicks(_analytics, isDevEnv(env))

        ebtAnalyticsInstance.registerAnalyticsContextProvider({
          location$: getLocationObservable(window.location, history),
        })

        setAnalytics(_analytics)
        setAnalyticsInstance(ebtAnalyticsInstance)
      }
    }

    if (!ebtIsSet.current) {
      initializeEbt()
      ebtIsSet.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_analytics, env])

  if (!ebtIsUserContextSet && analyticsInstance && profile) {
    analyticsInstance.registerUserIdContext({
      userId: profile.user_id.toString(),
      orgId: profile.organization_id,
    })

    setEbtUserContext(true)
  }

  if (shouldSendEbt(env) && channelName) {
    if (analytics !== undefined && analyticsInstance !== undefined) {
      return (
        <EbtContext.Provider value={{ analytics, analyticsInstance }}>
          {children}
        </EbtContext.Provider>
      )
    }

    return <AppLoadingRoot />
  }

  return <Fragment>{children}</Fragment>
}

EbtProvider.middleware = {
  displayName: 'EbtProvider',
  dependencies: [ProfileProvider],
}

export default EbtProvider

const createBuildShipperUrl =
  (sendTo: CloudAppEnv | null): BuildShipperUrl =>
  (urlOptions: BuildShipperUrlOptions): string => {
    const { channelName } = urlOptions

    const baseUrl =
      sendTo === 'production'
        ? 'https://telemetry.elastic.co'
        : 'https://telemetry-staging.elastic.co'
    return `${baseUrl}/v3/send/${channelName}`
  }
