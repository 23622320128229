/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { useIntl } from 'react-intl'

import { TrialIntentOptionsType } from '@modules/discovery-questions-lib/utils'
import { StepLayout } from '@modules/cui/Step/StepLayout'

import CheckableCardFieldset from './CheckableCardFieldset'

import type { FunctionComponent } from 'react'

type Props = {
  trialIntent: TrialIntentOptionsType | undefined
  onChangeTrialIntent: (value: TrialIntentOptionsType) => void
  onSubmitTrialIntent: (value: TrialIntentOptionsType) => void
}

const TrialIntent: FunctionComponent<Props> = ({
  trialIntent,
  onChangeTrialIntent,
  onSubmitTrialIntent,
}: Props) => {
  const { formatMessage } = useIntl()

  const options = [
    {
      label: {
        id: 'discovery-questions-trialintent.radio-1',
        defaultMessage: 'Evaluate Elastic for my project or use case',
      },
      value: TrialIntentOptionsType.evaluate,
    },
    {
      label: {
        id: 'discovery-questions-trialintent.radio-2',
        defaultMessage: 'Migrate an existing Elasticsearch environment',
      },
      value: TrialIntentOptionsType.migrate,
    },
    {
      label: {
        id: 'discovery-questions-trialintent.radio-3',
        defaultMessage: 'Get pricing information',
      },
      value: TrialIntentOptionsType.pricing_info,
    },
    {
      label: {
        id: 'discovery-questions-trialintent.radio-4',
        defaultMessage: 'Learn more about Elastic',
      },
      value: 'learn',
    },
    {
      label: { id: 'discovery-questions-trialintent.radio-5', defaultMessage: 'Do something else' },
      value: TrialIntentOptionsType.do_something_else,
    },
  ]

  const title = formatMessage({
    id: `discovery-questions-trialintent.title`,
    defaultMessage: `What's your primary goal during the trial?`,
  })

  return (
    <StepLayout title={title}>
      <CheckableCardFieldset
        data-test-id='signup.discovery-questions.trial-intent'
        selectedValue={trialIntent}
        prefix='trial-intent'
        options={options}
        onChange={onChangeTrialIntent}
        onSubmit={onSubmitTrialIntent}
      />
    </StepLayout>
  )
}

export default TrialIntent
