/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverTitle,
  EuiText,
} from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'

import { getFullDisplayFingerprint, getShortDisplayFingerprint } from '@/lib/fingerprints'

type Props = {
  fingerprint: string | undefined
}

const Fingerprint = ({ fingerprint }: Props) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  if (!fingerprint) {
    return null
  }

  return (
    <EuiFlexGroup gutterSize='xs' justifyContent='flexStart' alignItems='center'>
      <EuiFlexItem style={{ whiteSpace: `nowrap` }}>
        {getShortDisplayFingerprint(fingerprint)}
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiPopover
          anchorPosition='upLeft'
          panelPaddingSize='m'
          button={
            <EuiButtonIcon
              color='primary'
              onClick={togglePopover}
              iconType='iInCircle'
              aria-label='more information'
            />
          }
          isOpen={isPopoverOpen}
          closePopover={() => setPopoverOpen(false)}
        >
          <EuiPopoverTitle>
            <FormattedMessage
              id='fingerprint.popoverHeading'
              defaultMessage='Full certificate fingerprint (SHA-256)'
            />
          </EuiPopoverTitle>
          <EuiText size='s'>{getFullDisplayFingerprint(fingerprint)}</EuiText>
        </EuiPopover>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default Fingerprint
