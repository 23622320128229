/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useCallback } from 'react'

import type { ReactElement, RefObject } from 'react'

type GenericFunction = (...args: unknown[]) => unknown

interface FocusKeeperProps<T = GenericFunction | undefined> {
  act: T
  children: (props: { act: T }) => ReactElement | null
  refObject: RefObject<HTMLDivElement>
  tabIndex?: number
  timeout?: number
}

const SET_FOCUS_TIMEOUT = 75

/**
 * @component
 *
 * The FocusKeeper component ensures that focus remains on a specific position,
 * even when the parent component fully replaces its child elements as part of
 * a side effect (act).
 *
 * How it operates:
 * Within the parent, we establish a reference that undergoes updates during each render cycle.
 * Subsequently, we encapsulate our side effect method (act) with a wrapper. After a customisable
 * period following the completion of the side effect, we direct the focus to the updated element.
 * )
 */
export function FocusKeeper<T = GenericFunction | undefined>({
  act,
  refObject,
  children,
  tabIndex = -1,
  timeout = SET_FOCUS_TIMEOUT,
}: FocusKeeperProps<T>) {
  const wrappedAct = useCallback(
    (...args) => {
      if (typeof act === 'function') {
        act(...args)
      }

      setTimeout(() => {
        refObject.current?.focus()
      }, timeout)
    },
    [act, refObject, timeout],
  )

  return (
    <div ref={refObject} tabIndex={tabIndex}>
      {children({ act: wrappedAct as T })}
    </div>
  )
}
