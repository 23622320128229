/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import type { EuiSelectableOption } from '@elastic/eui'
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiButton,
  useGeneratedHtmlId,
  EuiPanel,
  EuiSpacer,
  EuiFieldText,
  EuiPopover,
  EuiSelectable,
  EuiFormRow,
  EuiCallOut,
} from '@elastic/eui'

import {
  useChallengeSaasCurrentUserMfaFactorMutation,
  useDeleteSaasCurrentUserMfaDeviceMutation,
} from '@modules/cloud-lib/users/hooks/mfa'
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { parseError, useDeleteWebAuthnMutation } from '@modules/cloud-lib/users/hooks/webauthn'

import type { MessageDescriptor } from 'react-intl'

const messages = defineMessages({
  enterCodePlaceholder: {
    id: 'remove-mfa.enter-code.title',
    defaultMessage: '6-digit code',
  },
  enterCodeLabel: {
    id: 'remove-mfa.enter-code.label',
    defaultMessage: 'Enter the 6-digit code that you received',
  },
  deviceSelectorLabel: {
    id: 'remove-mfa.device-selector-label',
    defaultMessage: 'Choose an MFA method',
  },
})

type MessageKeys = 'name' | 'warning' | 'enter-code-description'

const messagesByDeviceType: Record<
  SaasAuthMfaDeviceResponse['device_type'],
  Record<MessageKeys, MessageDescriptor>
> = {
  GOOGLE: defineMessages<MessageKeys>({
    name: {
      id: 'remove-mfa.authenticator-app',
      defaultMessage: 'Authenticator app',
    },
    warning: {
      id: 'remove-mfa.authenticator-app.warning',
      defaultMessage:
        'You will no longer be able to verify your identity using your <strong>Authenticator app</strong>. You can set it up again at any time.',
    },
    'enter-code-description': {
      id: 'remove-mfa.authenticator-app.enter-code-description',
      defaultMessage: 'Enter the 6-digit code from your <strong>Authenticator app</strong>.',
    },
  }),
  EMAIL: defineMessages<MessageKeys>({
    name: {
      id: 'remove-mfa.email',
      defaultMessage: 'Email',
    },
    warning: {
      id: 'remove-mfa.email.warning',
      defaultMessage:
        'You will no longer be able to use your <strong>email</strong> as a multifactor authentication method. You can enable it again at any time.',
    },
    'enter-code-description': {
      id: 'remove-mfa.email.enter-code-description',
      defaultMessage:
        'Enter the 6-digit code sent to <strong>{emailOrPhoneNumber}</strong>. The code is valid for 5 minutes.',
    },
  }),
  SMS: defineMessages<MessageKeys>({
    name: {
      id: 'remove-mfa.sms',
      defaultMessage: 'SMS',
    },
    warning: {
      id: 'remove-mfa.sms.warning',
      defaultMessage:
        'You will no longer be able to use <strong>SMS</strong> as a multifactor authentication method.',
    },
    'enter-code-description': {
      id: 'remove-mfa.sms.enter-code-description',
      defaultMessage:
        'Enter the 6-digit code sent to <strong>{emailOrPhoneNumber}</strong>. The code is valid for 5 minutes.',
    },
  }),
  WEBAUTHN: defineMessages<MessageKeys>({
    name: {
      id: 'remove-mfa.webauthn',
      defaultMessage: 'Security key or biometrics',
    },
    warning: {
      id: 'remove-mfa.webauthn.warning',
      defaultMessage:
        'You will no longer be able to verify your identity using your security key or biometrics. You can set it up again at any time.',
    },
    'enter-code-description': {
      id: 'remove-mfa.webauthn.enter-code-description',
      defaultMessage:
        'We must verify your identity before you can remove this authentication method.',
    },
  }),
}

const RemoveMfa: React.FunctionComponent<{
  device: SaasAuthMfaDeviceResponse
  activeDevices: SaasAuthMfaDeviceResponse[]
  closeModal: () => void
}> = ({ device, activeDevices, closeModal }) => {
  const { formatMessage } = useIntl()

  const modalTitleId = useGeneratedHtmlId()
  const contextMenuPopoverId = useGeneratedHtmlId()

  const [code, setCode] = useState('')
  const [isMfaSelectorPopoverOpen, openMfaSelectorPopover] = useState(false)

  const [options, setOptions] = useState<Array<EuiSelectableOption<SaasAuthMfaDeviceResponse>>>(
    activeDevices.map((activeDevice) => ({
      ...activeDevice,
      label: formatMessage(messagesByDeviceType[activeDevice.device_type].name),
      checked: activeDevice.device_type === device.device_type ? 'on' : undefined,
    })),
  )

  const { mutate: challengeMutate } = useChallengeSaasCurrentUserMfaFactorMutation()

  const deleteSaasCurrentUserMfaDeviceMutation = useDeleteSaasCurrentUserMfaDeviceMutation()
  const { reset } = deleteSaasCurrentUserMfaDeviceMutation

  const challengedDevice = options.find((deviceOptions) => deviceOptions.checked === 'on')

  const deteleWebAuthnMutation = useDeleteWebAuthnMutation({
    deviceId: device.device_id,
    challengedDeviceId: challengedDevice?.device_id ?? '',
  })

  const webAuthnErrorMessage = parseError(deteleWebAuthnMutation.error)

  useEffect(() => {
    if (challengedDevice === undefined) {
      return // sanity, should never happen as there should always be at least one selected device
    }

    reset()

    // There is no need to send a TOTP code to a device when its type is GOOGLE or WEBAUTHN, since
    // challenge happens manually when user opens the authenticator app or the browser automatically
    // opens the WEBAUTHN challenge dialog.
    if (challengedDevice.device_type !== 'GOOGLE' && challengedDevice.device_type !== 'WEBAUTHN') {
      challengeMutate({ deviceId: challengedDevice.device_id })
    }

    return () => {
      reset() // on unmount
    }
  }, [reset, challengeMutate, challengedDevice])

  const onSubmit = () => {
    if (challengedDevice === undefined) {
      return // sanity, should never happen as there should always be at least one selected device
    }

    if (challengedDevice.device_type === 'WEBAUTHN') {
      deteleWebAuthnMutation.mutate(undefined, {
        onSuccess: () => {
          closeModal()
        },
      })

      return
    }

    deleteSaasCurrentUserMfaDeviceMutation.mutate(
      {
        deviceId: device.device_id,
        challengedDeviceId: challengedDevice.device_id,
        challenge: {
          pass_code: code,
        },
      },
      {
        onSuccess: () => {
          closeModal()
        },
      },
    )
  }

  return (
    <EuiModal maxWidth='44rem' aria-labelledby={modalTitleId} onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle id={modalTitleId}>
          <FormattedMessage id='remove-mfa.title' defaultMessage='Remove authentication method?' />
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {formatMessage(messagesByDeviceType[device.device_type].warning, {
          strong: (content) => <strong>{content}</strong>,
        })}

        <EuiSpacer />

        <EuiPanel hasBorder={true}>
          {challengedDevice &&
            formatMessage(
              messagesByDeviceType[challengedDevice.device_type]['enter-code-description'],
              {
                strong: (content) => <strong>{content}</strong>,
                emailOrPhoneNumber: challengedDevice.email_address ?? challengedDevice.phone_number,
              },
            )}

          <EuiSpacer size='m' />

          <EuiFlexGroup direction='column' gutterSize='m'>
            {challengedDevice?.device_type !== 'WEBAUTHN' && (
              <EuiFlexItem>
                <EuiFormRow
                  hasChildLabel={false}
                  isInvalid={deleteSaasCurrentUserMfaDeviceMutation.isError}
                  error={
                    <DeleteError
                      deleteSaasCurrentUserMfaDeviceMutation={
                        deleteSaasCurrentUserMfaDeviceMutation
                      }
                    />
                  }
                >
                  <EuiFieldText
                    placeholder={formatMessage(messages.enterCodePlaceholder)}
                    autoFocus={true}
                    autoComplete='off'
                    icon='lock'
                    aria-label={formatMessage(messages.enterCodeLabel)}
                    style={{ maxWidth: '240px' }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            )}

            {webAuthnErrorMessage && challengedDevice?.device_type === 'WEBAUTHN' && (
              <EuiCallOut
                title={
                  <FormattedMessage
                    id='remove-mfa.webauthn.error'
                    defaultMessage='Could not remove multifactor authentication method'
                  />
                }
                color='danger'
              >
                {webAuthnErrorMessage}
              </EuiCallOut>
            )}

            <EuiFlexItem>
              <EuiPopover
                id={contextMenuPopoverId}
                style={{ width: '240px' }}
                button={
                  <EuiButtonEmpty
                    iconType='arrowDown'
                    iconSide='right'
                    onClick={() => {
                      openMfaSelectorPopover(!isMfaSelectorPopoverOpen)
                    }}
                  >
                    <FormattedMessage
                      id='remove-mfa.verify-with-a-different-method'
                      defaultMessage='Verify with a different method'
                    />
                  </EuiButtonEmpty>
                }
                isOpen={isMfaSelectorPopoverOpen}
                closePopover={() => {
                  openMfaSelectorPopover(false)
                }}
                panelPaddingSize='none'
                anchorPosition='downLeft'
              >
                <EuiSelectable<SaasAuthMfaDeviceResponse>
                  aria-label={formatMessage(messages.deviceSelectorLabel)}
                  singleSelection='always'
                  options={options}
                  onChange={(newOptions) => {
                    setOptions(newOptions)
                    openMfaSelectorPopover(false)
                  }}
                >
                  {(list) => <div style={{ width: 240 }}>{list}</div>}
                </EuiSelectable>
              </EuiPopover>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={closeModal}>
              <FormattedMessage id='remove-mfa.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              fill={true}
              color='danger'
              isDisabled={challengedDevice?.device_type !== 'WEBAUTHN' && code.trim() === ''}
              onClick={onSubmit}
              isLoading={
                deleteSaasCurrentUserMfaDeviceMutation.isLoading || deteleWebAuthnMutation.isLoading
              }
            >
              <FormattedMessage
                id='remove-mfa.remove-authentication-method'
                defaultMessage='Remove authentication method'
              />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}

const DeleteError: React.FunctionComponent<{
  deleteSaasCurrentUserMfaDeviceMutation: ReturnType<
    typeof useDeleteSaasCurrentUserMfaDeviceMutation
  >
}> = ({ deleteSaasCurrentUserMfaDeviceMutation }) => {
  if (!deleteSaasCurrentUserMfaDeviceMutation.error) {
    return null
  }

  const [firstError] = deleteSaasCurrentUserMfaDeviceMutation.error.errors
  const code = firstError?.code

  if (code === 'auth.no_available_mfa_devices') {
    return (
      <FormattedMessage
        id='remove-mfa.no-available-mfa-devices-error'
        defaultMessage='You must have at least one security method enabled. To preserve the security of your account, you will be able to remove this security method only after setting up a different one.'
      />
    )
  }

  return (
    <FormattedMessage
      id='remove-mfa.code-error'
      defaultMessage='This code may be incorrect or expired. Try again or request a new code.'
    />
  )
}

export default RemoveMfa
