/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect } from 'react'
import { useParams } from 'react-router'

import {
  useKibanaDeeplinkForCurrentResource,
  useOnboardingMetadataFromLocalStorage,
} from '@modules/discovery-questions-lib/hooks'
import { useGetDeploymentQuery } from '@modules/deployment-creation-api/hooks'
import { useGetProjectQuery } from '@modules/project-user-lib/hooks/get'
import { makeSsoUrl } from '@modules/project-lib/kibanaLinks'
import { isProjectType } from '@modules/project-creation-lib/types'

// eslint-disable-next-line import/no-restricted-paths
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors/endpoints'

import type { FC } from 'react'

const OpenKibanaRedirectPage: FC = () => {
  const { resourceType, id } = useParams<{ resourceType: string; id: string }>()
  const [_, setOnboardingMetadata] = useOnboardingMetadataFromLocalStorage()

  const isDeployment = resourceType === 'deployment'
  const { data: deployment } = useGetDeploymentQuery(id, { enabled: isDeployment })

  const isProject = isProjectType(resourceType)
  const { data: project } = useGetProjectQuery(
    isProject ? resourceType : 'elasticsearch', // unconditional hook sanity,
    id,
    { enabled: isProject },
  )

  const resource = project || deployment

  const kibanaDeepLink = useKibanaDeeplinkForCurrentResource(resource?.id)

  let href: string | undefined

  if (deployment) {
    href = getDeploymentResourceEndpoint({
      deployment,
      sliderInstanceType: 'kibana',
      getDeepLink: kibanaDeepLink ? () => kibanaDeepLink : undefined,
    })
  }

  if (project) {
    href = makeSsoUrl(project, { kibanaDeepLink })
  }

  useEffect(() => {
    if (href) {
      if (kibanaDeepLink) {
        setOnboardingMetadata({})
      }

      window.location.href = href
    }
  }, [href, kibanaDeepLink])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment />
}

export default OpenKibanaRedirectPage
