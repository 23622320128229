/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import DocLink from '@/components/DocLink'

import featurePackedBoxIllustration from '../../../../files/illustration-feature-packed-box-128.svg'
import CreateDeploymentButton from '../CreateDeploymentButton'

import type { FunctionComponent } from 'react'

type Props = {
  createDisabled: boolean
  disabled?: boolean
  restartTrial: boolean
}

const CreateFirstDeploymentButton: FunctionComponent<Props> = ({
  createDisabled,
  disabled,
  restartTrial,
}) => (
  <Fragment>
    <EuiSpacer size='xl' />
    <EuiFlexGroup direction='column' gutterSize='s' alignItems='center'>
      <EuiFlexItem>
        <EuiImage size='s' src={featurePackedBoxIllustration} alt='' />
      </EuiFlexItem>
      <EuiSpacer size='s' />
      <EuiFlexItem>
        <EuiTitle size='s'>
          <h2>
            <FormattedMessage
              id='deployments.page.list.empty.title'
              defaultMessage='You have no hosted deployments yet'
            />
          </h2>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText textAlign='center' style={{ maxWidth: '40em' }}>
          <FormattedMessage
            id='deployments.page.list.empty.body'
            defaultMessage='Deploy an Elastic Stack with all solutions included. Customize its capacity and performance to your exact needs. <link>Learn more</link>'
            values={{
              link: (content) => (
                <DocLink link='gettingStartedDocLink' showExternalLinkIcon={true}>
                  {content}
                </DocLink>
              ),
            }}
          />
        </EuiText>
      </EuiFlexItem>
      <EuiSpacer size='xs' />
      <EuiFlexItem>
        <CreateDeploymentButton
          data-test-id='create-first-deployment-button'
          createDisabled={createDisabled}
          disabled={disabled}
          restartTrial={restartTrial}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  </Fragment>
)

export default CreateFirstDeploymentButton
