/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import type { StackDeployment } from '@modules/ui-types'
import { StepLayout } from '@modules/cui/Step/StepLayout'

import StackElasticCredentials from '../../StackElasticCredentials'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
  deploymentTemplate: DeploymentTemplateInfoV2
  onContinue: () => void
}

const DeploymentGettingStartedCredentials: FunctionComponent<Props> = ({
  deployment,
  deploymentTemplate,
  onContinue,
}) => {
  const title = (
    <FormattedMessage
      id='deployment-waiting-experience.title.save-creds'
      defaultMessage='Save the deployment credentials'
    />
  )

  return (
    <StepLayout
      title={title}
      description={
        <Fragment>
          <FormattedMessage
            id='deployment-waiting-experience.sub-title-part-one'
            defaultMessage='These root credentials are shown only once.'
          />
          {` `}
          <FormattedMessage
            id='deployment-waiting-experience.sub-title-part-two'
            defaultMessage='They provide super user access to your deployment. Keep them safe.'
          />
        </Fragment>
      }
    >
      <EuiFlexGroup direction='column'>
        <EuiFlexItem>
          <StackElasticCredentials
            onlyShowCredentials={true}
            deploymentTemplate={deploymentTemplate}
            deployment={deployment}
            onDownloadCredentials={() => onContinue()}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </StepLayout>
  )
}

export default DeploymentGettingStartedCredentials
