/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css } from '@emotion/react'

import { EuiCallOut, EuiFormRow, EuiHorizontalRule, EuiSelect, EuiText } from '@elastic/eui'

import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  DeploymentTemplateInfoV2,
  ElasticsearchClusterPlan,
} from '@modules/cloud-api/v1/types'

import {
  setAutoscalingEnabled,
  setAutoscalingDisabled,
  setMLOnlyAutoscalingEnabled,
} from '@/lib/stackDeployments/autoscaling'
import { getEsPlan } from '@/lib/stackDeployments/selectors/stackDeployment'
import { getAutoscalingStatus } from '@/lib/stackDeployments/selectors/autoscaling'
import { getBlankNodeConfigurationPerTemplate } from '@/lib/stackDeployments/templateBlanks'

import DocLink from '../DocLink'

import type { WrappedComponentProps } from 'react-intl'
import type { AutoscalingEnabled } from '@/lib/stackDeployments/autoscaling'

interface Props extends WrappedComponentProps {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  deploymentTemplate?: DeploymentTemplateInfoV2
  onChangeAutoscaling: (deployment: DeploymentCreateRequest | DeploymentUpdateRequest) => void
  showToggle?: boolean
  defaultAutoscalingStatus?: AutoscalingEnabled
}

class AutoscalingToggle extends Component<Props> {
  state = {
    currentAutoscalingToggleStatus:
      this.props.defaultAutoscalingStatus ||
      getAutoscalingStatus({ deployment: this.props.deployment }),
  }

  componentDidUpdate(prevProps): void {
    const { onChangeAutoscaling, deployment, deploymentTemplate } = this.props

    if (this.state.currentAutoscalingToggleStatus !== getAutoscalingStatus({ deployment })) {
      const plan = getEsPlan({ deployment })

      if (plan?.transient?.restore_snapshot) {
        // We don't want to change the autoscaling status if the deployment is being restored from a snapshot because then we cannot have autoscaling
        return
      }

      const blankTemplate = getBlankNodeConfigurationPerTemplate({
        sliderInstanceType: 'elasticsearch',
        deploymentTemplate: deploymentTemplate!,
      }) as ElasticsearchClusterPlan

      if (this.state.currentAutoscalingToggleStatus === `all`) {
        onChangeAutoscaling(
          setAutoscalingEnabled({
            deployment,
            blankTemplate,
          }),
        )
      }

      if (this.state.currentAutoscalingToggleStatus === `ml`) {
        onChangeAutoscaling(
          setMLOnlyAutoscalingEnabled({
            deployment,
            blankTemplate,
          }),
        )
      }
    }

    if (prevProps.defaultAutoscalingStatus !== this.props.defaultAutoscalingStatus) {
      this.setState({
        currentAutoscalingToggleStatus: this.props.defaultAutoscalingStatus,
      })
    }
  }

  render() {
    const { showToggle = true } = this.props

    return (
      <Fragment>
        {showToggle ? this.showAutoscalingOptions() : this.showAutoscalingNotAvailable()}
        <EuiHorizontalRule />
      </Fragment>
    )
  }

  showAutoscalingNotAvailable = () => (
    <EuiCallOut color='warning' data-test-id='autoscaling-not-available-on-snapshot-restore'>
      <FormattedMessage
        id='autoscaling-toggle.non-available'
        defaultMessage='Autoscaling is not available when creating a new deployment from a snapshot restore. Make sure that the deployment is correctly set to handle
      the size of the restored snapshot. You will be able to turn on autoscaling after the snapshot is successfully restored into your new deployment.'
      />
    </EuiCallOut>
  )

  showAutoscalingOptions = () => {
    const {
      intl: { formatMessage },
    } = this.props

    const options = [
      {
        value: 'all',
        text: formatMessage({
          id: 'autoscaling-options.all',
          defaultMessage: `Deployment (Data and Machine Learning)`,
        }),
      },
      {
        value: 'ml',
        text: formatMessage({
          id: 'autoscaling-options.ml',
          defaultMessage: `Machine Learning only`,
        }),
      },
      {
        value: 'none',
        text: formatMessage({
          id: 'autoscaling-options.none',
          defaultMessage: `None`,
        }),
      },
    ]
    const value = this.state.currentAutoscalingToggleStatus

    const label = (
      <FormattedMessage id='autoscaling-toggle.label' defaultMessage='Enable Autoscaling for:' />
    )
    const helpText = (
      <EuiText color='subdued' size='s'>
        <FormattedMessage
          id='autoscaling-toggle.description'
          defaultMessage='Scale the capacity of your data and machine learning nodes automatically. {learnMore}'
          values={{
            learnMore: (
              <DocLink link='autoscalingEnableDocLink'>
                <FormattedMessage
                  id='autoscaling-toggle.description.learn-more'
                  defaultMessage='Learn more'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>
    )

    return (
      <EuiFormRow
        label={label}
        helpText={helpText}
        css={css`
          max-width: 25rem !important;
        `} // honestly this is very frustrating, but the select ends up throwing the down carat symbol to the far right, way outside the boundaries
        // unless I specify a maxWidth. And of course without the `!important` it gets overridden by the EuiFormRow's default max-width
      >
        <EuiSelect
          id='autoscaling-options'
          data-test-id='autoscaling-options'
          options={options}
          value={value}
          onChange={this.onChange}
        />
      </EuiFormRow>
    )
  }

  onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { deployment, deploymentTemplate, onChangeAutoscaling } = this.props

    let deploymentAfterChanges

    const blankTemplate = getBlankNodeConfigurationPerTemplate({
      sliderInstanceType: 'elasticsearch',
      deploymentTemplate: deploymentTemplate!,
    }) as ElasticsearchClusterPlan

    const targetValue = e.target.value

    if (targetValue === 'ml') {
      // this is the special case where we just want to add `autoscaling_tier_override` to the ML tier, but leave all other autoscaling options off
      deploymentAfterChanges = setMLOnlyAutoscalingEnabled({ deployment, blankTemplate })
    } else if (targetValue === 'none') {
      deploymentAfterChanges = setAutoscalingDisabled({ deployment })
    } else {
      deploymentAfterChanges = setAutoscalingEnabled({
        deployment,
        blankTemplate,
      })
    }

    this.setState({ currentAutoscalingToggleStatus: targetValue })

    if (!deploymentAfterChanges.resources || !deploymentAfterChanges.resources.elasticsearch) {
      return
    }

    onChangeAutoscaling(deploymentAfterChanges)
  }
}

export default injectIntl(AutoscalingToggle)
