/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  fetchPrepaidBalanceLineItemsIfNeeded,
  fetchPrepaidBalanceLineItems,
  activateLineItems,
  resetActivateLineItemsRequest,
} from '@/actions/billing'
import {
  activateLineItemsRequest,
  fetchPrepaidBalanceLineItemsRequest,
} from '@/reducers/asyncRequests/registry'
import { getPrepaidBalanceLineItems } from '@/reducers/billing'

import PrepaidAccountDetailsPanel from './PrepaidAccountDetailsPanel'
import { ConsumerProps, StateProps, DispatchProps } from './types'

const mapStateToProps = (state, { profile }: ConsumerProps): StateProps => ({
  profile,
  prepaidBalanceLineItems: getPrepaidBalanceLineItems(state, profile.organization_id!) || [],
  fetchPrepaidBalanceLineItemsRequest: fetchPrepaidBalanceLineItemsRequest(state),
  activateLineItemsRequest: activateLineItemsRequest(state),
})

const mapDispatchToProps: DispatchProps = {
  fetchPrepaidBalanceLineItemsIfNeeded,
  activateLineItems,
  resetActivateLineItemsRequest,
  fetchPrepaidBalanceLineItems,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PrepaidAccountDetailsPanel)
