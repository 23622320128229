/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiToolTip } from '@elastic/eui'

import type {
  RestoreSnapshotConfiguration,
  RestoreSnapshotApiConfiguration,
  ElasticsearchClusterPlan,
} from '@modules/cloud-api/v1/types'

import { searchDeploymentsQuery } from '@/lib/deploymentQuery'
import {
  getFirstEsClusterFromGet,
  getRegionId,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { getFirstEsCluster } from '@/lib/stackDeployments/selectors/stackDeployment'
import {
  sanitizeUpdateRequestBeforeSend,
  createUpdateRequestFromGetResponse,
} from '@/lib/stackDeployments/updates'

import SnapshotRestoreModal from './SnapshotRestoreModal'

import type { ConsumerProps as SelectSnapshotSourceProps } from '../../../StackDeploymentEditor/CreateStackDeploymentEditor/SelectTemplate/SetupDeployment/SetupSnapshots/SelectSnapshotSource'
import type { AllProps, State } from './types'

class SnapshotRestore extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      isModalOpen: false,
      plan: this.makePlan(),
    }
  }

  render(): JSX.Element | null {
    if (!this.state.plan) {
      return null
    }

    return (
      <Fragment>
        <EuiToolTip
          content={
            <FormattedMessage
              id='snapshot-restore.show-modal-button-description'
              defaultMessage='Choose an snapshot in another deployment to restore on this deployment'
            />
          }
        >
          <EuiButton
            onClick={this.openModal}
            iconType='refresh'
            data-test-id='es-snapshot-restore-from-another'
          >
            <FormattedMessage
              id='snapshot-restore.show-modal-button'
              defaultMessage='Restore from another deployment'
            />
          </EuiButton>
        </EuiToolTip>

        {this.state.isModalOpen && (
          <SnapshotRestoreModal
            searchDeployments={this.searchDeployments}
            plan={this.state.plan}
            deploymentUnderEdit={this.props.deployment}
            updateDeploymentRequest={this.props.updateDeploymentRequest}
            onChangeSnapshotSource={this.onChangeSnapshotSource}
            onChangeMaintMode={this.onChangeMaintMode}
            onSelectSnapshot={this.onSelectSnapshot}
            onUpdateIndexRestore={this.onUpdateIndexRestore}
            onRestoreSnapshot={this.onRestoreSnapshot}
            onClose={this.closeModal}
          />
        )}
      </Fragment>
    )
  }

  openModal = (): void => {
    this.setState({ isModalOpen: true })
  }

  closeModal = (): void => {
    this.setState({ isModalOpen: false, plan: this.makePlan() })
  }

  searchDeployments = (userInput: string) => {
    const { onSearch, deployment } = this.props
    const { plan } = this.state
    const regionId = getRegionId({ deployment })
    const version = plan?.elasticsearch.version

    if (!regionId || !version) {
      return
    }

    const query = searchDeploymentsQuery({
      version,
      regionId,
      searchValue: userInput,
      targetMajorVersions: (major) => [major, major - 1],
    })

    onSearch(query)
  }

  onChangeSnapshotSource: SelectSnapshotSourceProps['onUpdateSnapshotSource'] = (deployment) => {
    // reset state if the source cluster changes
    if (!deployment) {
      this.setState({ plan: this.makePlan() })
      return
    }

    const firstEs = deployment ? getFirstEsClusterFromGet({ deployment }) : deployment

    const restoreFrom: RestoreSnapshotConfiguration = {
      snapshot_name: `__latest_success__`,
      source_cluster_id: firstEs?.id,
      strategy: `partial`,
    }
    const plan = this.makePlan()
    plan.transient ??= {}
    plan.transient.restore_snapshot = restoreFrom

    this.setState({ plan })
  }

  onChangeMaintMode = (value: boolean) => {
    const plan = this.state.plan
    plan.transient ??= {}
    plan.transient.plan_configuration = {
      extended_maintenance: value,
    }
    this.setState({ plan })
  }

  onSelectSnapshot: SelectSnapshotSourceProps['onSelectSnapshot'] = (
    snapshotDetails,
    sourceClusterId: string,
  ) => {
    const restoreFrom: RestoreSnapshotConfiguration | undefined = snapshotDetails
      ? {
          snapshot_name: snapshotDetails.snapshot,
          source_cluster_id: sourceClusterId,
          strategy: `partial`,
        }
      : undefined

    const plan = this.state.plan
    plan.transient ??= {}
    plan.transient.restore_snapshot = restoreFrom

    this.setState({ plan })
  }

  onUpdateIndexRestore: Exclude<SelectSnapshotSourceProps['onUpdateIndexRestore'], undefined> = (
    payload,
  ) => {
    if (!payload.indices && !(payload.rename_pattern && payload.rename_replacement)) {
      return
    }

    const restorePayload: RestoreSnapshotApiConfiguration = {}

    if (payload.indices) {
      restorePayload.indices = [payload.indices]
    }

    if (payload.rename_pattern && payload.rename_replacement) {
      restorePayload.raw_settings = {
        rename_pattern: payload.rename_pattern,
        rename_replacement: payload.rename_replacement,
      }
    }

    const plan = this.state.plan
    plan.transient ??= {}
    plan.transient.restore_snapshot ??= {} as RestoreSnapshotConfiguration
    plan.transient.restore_snapshot.restore_payload = restorePayload

    this.setState({ plan })
  }

  onRestoreSnapshot: Exclude<SelectSnapshotSourceProps['onRestoreSnapshot'], undefined> = () => {
    const { updateDeployment } = this.props
    const { plan } = this.state

    const deployment = createUpdateRequestFromGetResponse({ deployment: this.props.deployment })
    const cluster = getFirstEsCluster({ deployment })

    if (!cluster || !plan) {
      return // sanity
    }

    cluster.plan = plan

    return updateDeployment(sanitizeUpdateRequestBeforeSend({ deployment }))
  }

  makePlan = (): ElasticsearchClusterPlan => {
    const deploymentUpdateRequest = createUpdateRequestFromGetResponse({
      deployment: this.props.deployment,
    })

    return (
      getFirstEsCluster({ deployment: deploymentUpdateRequest })?.plan ??
      ({} as ElasticsearchClusterPlan)
    )
  }
}

export default SnapshotRestore
