/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import classNames from 'classnames'

import type { EuiTabbedContentTab } from '@elastic/eui'
import { EuiPage, EuiPageBody, EuiPageHeader, EuiTabbedContent, EuiPageSection } from '@elastic/eui'

import type { RoutableBreadcrumb } from '@modules/ui-types'
import history from '@modules/utils/history'
import Breadcrumbs from '@modules/cui/Breadcrumbs'

import type { ReactElement, ReactNode } from 'react'

import './portalPage.scss'
import './portalPageDark.scss'

export interface TabbedContent {
  initialSelectedTab: EuiTabbedContentTab
  tabs: EuiTabbedContentTab[]
}

interface Props {
  breadcrumbs?: RoutableBreadcrumb[]
  children?: ReactNode
  className?: string
  contentHeader?: React.ReactChild
  tabbedContent?: TabbedContent
}

class PortalPage extends PureComponent<Props> {
  render(): ReactElement {
    const { breadcrumbs = [], className, contentHeader, tabbedContent } = this.props

    return (
      <Fragment>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <EuiPage className={classNames(`cloudContent cloud-portal`, className)}>
          <EuiPageBody id='cloudPortalPage' className='cloud-portal-page-body'>
            {contentHeader && (
              <EuiPageHeader className='cloud-portal-header'>{contentHeader}</EuiPageHeader>
            )}

            <EuiPageSection
              paddingSize='none'
              className={classNames('cloud-portal-page-content', {
                'cloud-page-tabbed-content': !!tabbedContent,
              })}
            >
              <div data-app='appContentBody' className='cloud-portal-page-content-body'>
                {this.renderContent()}
              </div>
            </EuiPageSection>
          </EuiPageBody>
        </EuiPage>
      </Fragment>
    )
  }

  renderContent(): React.ReactChild | ReactNode {
    const { children, tabbedContent } = this.props

    if (tabbedContent) {
      const { tabs, initialSelectedTab } = tabbedContent
      return (
        <EuiTabbedContent
          className='cloud-page-tab-panel'
          tabs={tabs}
          initialSelectedTab={initialSelectedTab}
          autoFocus='selected'
          onTabClick={(tab) => {
            history.push(`${tab.id}`)
          }}
        />
      )
    }

    return children
  }
}

export default PortalPage
