/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import type { AnySecurityProject } from '@modules/project-api/types'
import { indexManagementUrl } from '@modules/project-lib/kibanaLinks'
import genericMessages from '@modules/project-lib/genericMessages'

import type { FC } from 'react'

type DataRetentionDescriptionProps = {
  isEditing: boolean
  project: AnySecurityProject
}

const DataRetentionDescription: FC<DataRetentionDescriptionProps> = ({ isEditing, project }) => {
  const { formatMessage } = useIntl()

  return (
    <EuiFlexGroup alignItems='baseline'>
      <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
        <EuiPanel paddingSize='m' hasBorder={true}>
          <EuiIcon type='storage' color='primary' size='xl' />
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={true}>
        <EuiTitle size='xs'>
          <h3>{formatMessage(genericMessages.dataRetention)}</h3>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiText size='s' color='subdued'>
          <p>
            <FormattedMessage
              id='project.security..dataRetention.description.1'
              defaultMessage='
            <strong>Data Retention</strong> policies determine how long your data is retained in the Search AI Lake.'
              values={{ strong: (content) => <strong>{content}</strong> }}
            />
          </p>
          {isEditing && (
            <Fragment>
              <p>
                <FormattedMessage
                  id='project.security..dataRetention.description.2'
                  defaultMessage='
                The Search AI Lake holds <strong>all the data</strong> that you store and access through your project.'
                  values={{ strong: (content) => <strong>{content}</strong> }}
                />{' '}
                <FormattedMessage
                  id='project.security..dataRetention.description.3'
                  defaultMessage='You can specify different retention periods for each data stream configured in
              your project.'
                />
              </p>
              <p>
                <FormattedMessage
                  id='project.security.dataRetention.description.4'
                  defaultMessage='Go to <link>Data Streams</link> to configure data retention for individual data streams.'
                  values={{
                    link: (content) => (
                      <EuiLink href={indexManagementUrl(project)}>{content}</EuiLink>
                    ),
                  }}
                />
              </p>
            </Fragment>
          )}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default DataRetentionDescription
