/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import withBillingDetails from '@modules/billing-lib/billingDetails/withBillingDetails'
import withUsageMode from '@modules/billing-lib/usageMode/withUsageMode'

import { getCommonProfile } from '@/reducers/profile'
import {
  getAccountActivity,
  fetchUpdateBillingLevelRequest,
} from '@/apps/userconsole/reducers/account'

import { resetUpdateBillingLevel } from '../../../../actions/account'

import SelectSubscriptionBody from './SelectSubscriptionBody'

import type { StateProps, DispatchProps, ConsumerProps } from './types'
import type { ThunkDispatch } from '@/types/redux'

const mapStateToProps = (state): StateProps => {
  const profile = getCommonProfile(state)

  return {
    profile,
    activity: getAccountActivity(state)!,
    updateBillingLevelRequest: fetchUpdateBillingLevelRequest(state),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  resetUpdateBillingLevel: () => dispatch(resetUpdateBillingLevel()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withBillingDetails(withUsageMode(SelectSubscriptionBody)))
