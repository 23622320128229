/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { ReduxState } from '@/types/redux'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { fetchBasePricesIfNeeded } from '@/apps/userconsole/actions/pricing'
import { fetchBasePricesRequest } from '@/apps/userconsole/reducers/registry'
import { getBasePrices } from '@/apps/userconsole/reducers/pricing/basePricing'

import UsageSection from './UsageSection'

import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { deployment, level }): StateProps => {
  const regionId = getRegionId({ deployment })!

  return {
    basePrices: getBasePrices(state, regionId, level),
    fetchBasePricesRequest: fetchBasePricesRequest(state, regionId, level),
  }
}

const mapDispatchToProps = (dispatch, { deployment, level }: ConsumerProps): DispatchProps => {
  const regionId = getRegionId({ deployment })!

  return {
    fetchBasePrices: () =>
      dispatch(
        fetchBasePricesIfNeeded({
          regionId,
          level,
        }),
      ),
  }
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UsageSection)
