/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { fetchAsJson } from '@modules/query/helpers'
import { DiscoveryQuestionsPayload } from '@modules/discovery-questions-lib/hooks'

// eslint-disable-next-line import/no-restricted-paths
import { getConfigForKey as getStaticConfigForKey } from '@/store'

export const postCloudAnalytics = async ({
  userId,
  questions,
}: {
  userId: any
  questions: DiscoveryQuestionsPayload
}) => {
  const elasticCloud = 'elastic_cloud'

  const url = getStaticConfigForKey(`TELEMETRY_URL`)

  fetchAsJson(`${url}/v3/send/cloud-onboarding-profiles?debug=true`, {
    method: 'post',
    body: JSON.stringify(questions),
    headers: {
      'X-Sender-Type': 'cloud_user_principal',
      'X-Sender-Id': userId,
      'X-Sender-Version': elasticCloud,
      'X-Elastic-Cluster-ID': elasticCloud,
      'X-Elastic-Stack-Version': elasticCloud,
    },
  })
}
