/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'
import useFlowV2BackgroundDecoration from '@modules/utils/hooks/background'

import elasticLogo from '@/files/elastic-logo-H-color.svg' // eslint-disable-line import/no-restricted-paths
import elasticLogoDark from '@/files/elastic-logo-H-color-dark.svg' // eslint-disable-line import/no-restricted-paths

import type { ReactNode, FunctionComponent } from 'react'

interface Props {
  children: ReactNode
  footer?: ReactNode
}

/**
 * A full-viewport page component that presents modal-like content in the
 * center. Optionally takes a footer component anchored to the bottom of the
 * viewport.
 */
const ModalPage: FunctionComponent<Props> = ({ children, footer }) => {
  const { euiTheme } = useEuiTheme()

  const backgroundDecoration = useFlowV2BackgroundDecoration()

  const containerStyle = css([
    {
      padding: euiTheme.size.xl,
    },
  ])

  return (
    <EuiFlexGroup
      direction='column'
      alignItems='center'
      gutterSize='xl'
      css={[containerStyle, backgroundDecoration]}
    >
      <div
        css={css({
          position: 'absolute',
          paddingLeft: euiTheme.size.xl,
          paddingRight: euiTheme.size.xl,
          right: 0,
          left: 0,
        })}
      >
        <CuiThemedIcon
          size='xxl'
          css={css({ width: 'auto', height: '39px' })}
          lightType={elasticLogo}
          darkType={elasticLogoDark}
        />
      </div>

      <EuiFlexItem grow={true}>
        <EuiFlexGroup direction='column' justifyContent='center' alignItems='stretch'>
          <EuiFlexItem grow={false}>{children}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      {footer && <EuiFlexItem grow={false}>{footer}</EuiFlexItem>}
    </EuiFlexGroup>
  )
}

export default ModalPage
