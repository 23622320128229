/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import { useGetCostsItemsV2 } from '@modules/billing-lib/hooks'
import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import { getSaasLatestTrial } from '@modules/profile-lib/trial'
import type { AnyUserProject } from '@modules/project-user-api/types'

import ProjectCosts from './ProjectCosts'
import { makeProductBlank } from './lib'

type Props = {
  saasUser: SaasUserResponse
  currentProject: AnyUserProject | undefined
}

const Costs: React.FunctionComponent<Props> = ({ saasUser, currentProject }) => {
  const trial = getSaasLatestTrial(saasUser)

  const costsQuery = useGetCostsItemsV2({
    pathParameters: { organization_id: saasUser.user.organization_id },
    queryParameters: { from: trial?.start, to: trial?.end },
  })

  if (costsQuery.isLoading) {
    return <EuiSkeletonText />
  }

  const products = costsQuery.data?.products ?? []

  const getProjectProduct = () => {
    if (!currentProject) {
      return makeProductBlank('elasticsearch') // sanity, to be amended once the unified trial summary modal really is presenting all variants
    }

    const currentProjectProduct = products.find(({ type }) => type === currentProject.type)

    return currentProjectProduct ?? makeProductBlank(currentProject.type)
  }

  const projectProduct = getProjectProduct()

  return <ProjectCosts trial={trial} product={projectProduct} />
}

export default Costs
