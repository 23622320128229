/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import React from 'react'
import { jsx, css } from '@emotion/react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSkeletonText,
  useEuiBackgroundColor,
} from '@elastic/eui'

import { useProjectsMetadata } from '@modules/project-user-components/SupplyProjectsMetadata'
import CreateProjectButton from '@modules/project-user-components/CreateProjectButton'

import ProjectsPortalPanelTitle from './ProjectsPortalPanelTitle'
import ProjectsPortalPanelContent from './ProjectsPortalPanelContent'

const ProjectsPortalPanel: React.FunctionComponent = () => {
  const { projectsLength, isReady } = useProjectsMetadata()
  const hasNoProjects = !projectsLength

  const bgColor = `${useEuiBackgroundColor('success')}`
  const containerStyles = hasNoProjects
    ? css`
        background-color: ${bgColor};
      `
    : ''

  if (!isReady) {
    return (
      <EuiPanel hasShadow={true} grow={false}>
        <EuiSkeletonText />
      </EuiPanel>
    )
  }

  return (
    <EuiPanel hasShadow={true} grow={false} css={css(containerStyles)}>
      <EuiFlexGroup
        gutterSize='s'
        justifyContent='flexStart'
        alignItems='center'
        responsive={false}
      >
        <EuiFlexItem grow={true}>
          <EuiFlexGroup
            gutterSize='s'
            justifyContent='spaceBetween'
            alignItems='baseline'
            responsive={false}
          >
            <EuiFlexItem grow={true}>
              <ProjectsPortalPanelTitle />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <CreateProjectButton hasSuccessColor={hasNoProjects} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <ProjectsPortalPanelContent />
    </EuiPanel>
  )
}

export default ProjectsPortalPanel
