/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'

import elasticLogo from '../../../files/elastic-logo-H-color.svg'
import elasticLogoDark from '../../../files/elastic-logo-H-color-dark.svg'

import { LoginButton, LogoutButton, SignupButton } from './HeaderButton'

import type { ReactElement } from 'react'
import type { LandingPageContext } from '../types'

import './header.scss'

export interface Props {
  locationQueryString: string
  pageContext?: LandingPageContext
  onPageContextSwitch?: (pageContext: LandingPageContext) => void
}

class Header extends PureComponent<Props> {
  render(): ReactElement {
    return (
      <div className='cloud-landing-page-logo'>
        {this.renderButton()}

        <CuiThemedIcon
          size='xxl'
          lightType={elasticLogo}
          darkType={elasticLogoDark}
          className='cloud-landing-page-elastic-logo'
        />
      </div>
    )
  }

  renderButton(): ReactElement | null {
    const { locationQueryString, onPageContextSwitch, pageContext } = this.props

    if (!pageContext) {
      return null
    }

    const onClick = onPageContextSwitch
      ? () => onPageContextSwitch(this.getSwitchedContext())
      : undefined
    const { name: pageContextName, contextSwitchLocationState } = pageContext
    const buttonProps = {
      locationQueryString,
      onClick,
      locationState: contextSwitchLocationState,
    }

    if (pageContextName === 'signup') {
      return <LoginButton {...buttonProps} />
    }

    if (pageContextName === 'loggedIn') {
      return <LogoutButton {...buttonProps} />
    }

    return <SignupButton {...buttonProps} />
  }

  getSwitchedContext(): LandingPageContext {
    const { pageContext } = this.props
    const pageContextName = pageContext?.name

    if (pageContextName === 'signup') {
      return { name: 'login' }
    }

    return { name: 'signup' }
  }
}

export default Header
