/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'querystring'

import moment from 'moment'
import { sha256 } from 'js-sha256'

import { loginUrl } from '@modules/auth/urls'

import { getMarketoTrackingParamsFromCookies } from '@/lib/marketo'
import { isCurrentPath } from '@/lib/urls'
import { registerUrl } from '@/lib/urlBuilder'

interface SocialLoginSubmitEvent {
  event: string
  formId: string
}

type WindowWithDataLayer = Window & {
  dataLayer: Array<Record<string, any>>
}

declare const window: WindowWithDataLayer

export const socialSignUpSubmitEvent: SocialLoginSubmitEvent = {
  event: 'cloud_social_sign_up_submit',
  formId: 'cloud-social-signup-form',
}

export const socialLoginSubmitEvent: SocialLoginSubmitEvent = {
  event: 'cloud_social_login_submit',
  formId: 'cloud-social-login-form',
}

export function applyGoogleTrackingV4() {
  window.dataLayer = window.dataLayer || []

  const { hostname, href, pathname } = location
  const { title } = document
  const { euid } = getMarketoTrackingParamsFromCookies()
  const { userAgent } = window.navigator

  window.dataLayer.push({
    event: 'page_view',
    team: 'Cloud',
    pageCategory: getGoogleTrackingV4PageCategoryName(),
    pageURL: href,
    canonicalTag: href,
    pagePath: pathname,
    pageTitle: title,
    hostname,
    euid,
    userAgent,
    ...getUtmParameters(),
  })
}

export function applyGoogleTrackingV4ForLogin({
  email,
  userId,
}: {
  email: string
  userId: string
}) {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'login',
    timestamp: moment().toISOString(),
    cloudUserId: userId,
    userId: getHashedEmail(email),
  })
}

export function applyGoogleTrackingV4ForSocialLoginSubmitEvent({
  event,
  formId,
}: SocialLoginSubmitEvent) {
  window.dataLayer = window.dataLayer || []

  const { href, pathname } = location

  window.dataLayer.push({
    event,
    timestamp: moment().toISOString(),
    pageURL: href,
    pagePath: pathname,
    formId,
    formName: formId,
  })
}

export function applyGoogleTrackingV4FormSubmission({
  email,
  formId,
}: {
  email: string
  formId: string
}) {
  window.dataLayer = window.dataLayer || []

  const { href } = location

  window.dataLayer.push({
    event: 'form_submission',
    timestamp: moment().toISOString(),
    pageURL: href,
    formId,
    formName: formId,
    userId: getHashedEmail(email),
  })
}

function getHashedEmail(email: string): string {
  if (email) {
    return sha256(email.toString())
  }

  return ''
}

function getGoogleTrackingV4PageCategoryName() {
  if (isCurrentPath(loginUrl())) {
    return 'Cloud-login'
  }

  if (isCurrentPath(registerUrl())) {
    return 'Cloud-signup'
  }

  return
}

function getUtmParameters() {
  const { search } = location
  const query = parse(search.slice(1))
  const keys = Object.keys(query)
  const utmKeys = ['utm_campaign', 'utm_content', 'utm_id', 'utm_medium', 'utm_source', 'utm_term']

  return keys.reduce((acc, key) => {
    if (utmKeys.includes(key)) {
      acc[key] = query[key]
    }

    return acc
  }, {})
}
